import { OrcamentoModel } from "./orcamentoModel";





export class OrcamentoModelInstance {
    public static get(): OrcamentoModel {
        return {
            idOrcamento: 0,
            cpfCnpj: '',
            idLoja: 0,
            idPdv: null,
            idTabelaStatusOrcamento: null!,
            proCpfCnpj: '',
            preCpfCnpj: '',
            valorTotal: 0,
            dataExecucao: new Date(),
            dataPrevistaEntrega: null!,
            dataValidadeOrcamentoPrevenda: new Date(),
            complementoOrcamento: '',
            observacaoInterna: '',
            dataRegistro: null!,
            cpfRegistro: '',
            dataAlteracao: null!,
            cpfAlteracao: '',
            valorTotalDescontoVendaProduto: 0,
            valorTotalFreteVendaProduto: 0,
            valorTotalOutrasDespesasVendaProduto: 0,
            valorTotalProdutoServicoVendaProduto: 0,
            cpfCnpjNavigation: null!,
            idPdvNavigation: null!,
            idTabelaStatusOrcamentoNavigation: null!,
            preCpfCnpjNavigation: null!,
            proCpfCnpjNavigation: null!,
            orcamentoProdutos: [],
            orcamentoSimulacaoPagamentos: null!,
            orcamentoStatusAlterados: null!,
            venda: null!,
            valorTotalSeguroVendaProduto: 0,
            orcamentoAnexos: null!,
            idTabelaPreco: 0,
            idTabelaPrecoNavigation: null!
        };
    }

    public static getArray(): OrcamentoModel[] {
        return [this.get()];
    };
}
