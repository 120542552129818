
  <label class="form-label " [for]="idCampo">{{label}}&nbsp;<i class="fas fa-info-circle " [ngbTooltip]="toolTip"></i></label>
  <!-- <i class="fas fa-info-circle " [ngbTooltip]="toolTip"></i> -->
  <div class="input-group">
    <p-inputMask [mask]="maskCnpj" [disabled]="disabled" styleClass="form-control input-md" [id]="idCampo"
      [name]="idCampo" type="text" [(ngModel)]="value">
    </p-inputMask>
    <a ngbTooltip="Consultar " class="input-group-text btn-outline-secondary" *ngIf="!disabled"
      (click)="modalEmpresaCnpj()">
      <i class="far fa-building mouse-pointer"></i>
    </a>
  </div>
<app-modal-crud-pesquisa-consulta-cnpj (importar)="fcImportarDadosBaseExterna($event)"
  [modalFrontLayer]="modalFrontLayer">
</app-modal-crud-pesquisa-consulta-cnpj>