import { FormaPagamentoModel } from "./formaPagamentoModel";


export class FormaPagamentoModelInstance {
    public static get(): FormaPagamentoModel {
        return {
            idFormaPagamento: 0,
            idTabelaBandeiraOperCartCredDeb: '',
            idContaBancaria: 0,
            idParametrizacaoCondPagamento: 0,
            idTabelaMeioPagamento: '',
            idLoja: 0,
            idCompra: 0,
            idVenda: 0,
            idOrdemServico: 0,
            idOrcamentoSimulacaoPagamento: 0,
            valorSubtotal: 0,
            valorDescontoCondicaoPagamento: 0,
            valorDescontoVendedor: 0,
            valorDespesaCondicaoPagamento: 0,
            valorJurosCondicaoPagamento: 0,
            valorTotal: 0,
            valorPago: 0,
            valorTroco: 0,
            qtdeParcela: 0,
            cnpjInstituicaoPagamento: '',
            numeroAutorizacaoOperacao: '',
            maquineta: '',
            autorizacao: '',
            indicadorMeioPagamento: '',
            tipoIntegracaoPagamento: '',
            situacao: '',
            cpfRegistro: '',
            dataRegistro: null,
            cpfAlteracao: '',
            dataAlteracao: null,
            id: null!,
            idCompraNavigation: null!,
            idContaBancariaNavigation: null!,
            idOrcamentoSimulacaoPagamentoNavigation: null!,
            idOrdemServicoNavigation: null!,
            idTabelaMeioPagamentoNavigation: null!,
            idVendaNavigation: null!,
            controleVales: null!,
            controleValeHistoricos: null!,
            formaPagamentoParcelas: null!,
            vendaVales: null!,
            idPedidoVenda: null,
            idPedidoVendaNavigation: null!,
            valorEmDinheiro: 0
        };
    }



    public static getArray(): FormaPagamentoModel[] {
        return [this.get()];
    };
}
