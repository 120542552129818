import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ComboPdvComponent } from './combo-pdv.component';


@NgModule({
  declarations: [ComboPdvComponent],
  imports: [
    CommonModule,

    SharedModule,
    NgbPopoverModule,
    NgbTooltipModule
  ],
  exports: [
    ComboPdvComponent,
    CommonModule,
   
    SharedModule,
    NgbPopoverModule,
    NgbTooltipModule
  ]
})
export class ComboPdvModule { }