import { NotaFiscalEmitenteModel } from "./notaFiscalEmitenteModel";

export class notaFiscalEmitenteModelInstance {
    public static get(): NotaFiscalEmitenteModel {
        return {
            idNotaFiscal: 0,
            cpfCnpj: '',
            razaoSocialNomeEmitente: '',
            nomeFantasia: '',
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            codigoMunicipio: '',
            nomeMunicipio: '',
            siglaUf: '',
            codigoCep: '',
            codigoPais: '',
            nomePais: '',
            telefone: '',
            inscricaoEstadualEmitente: '',
            ieSubstitutoTributario: '',
            inscricaoMunicipalPrestadorServico: '',
            cnaeFiscal: '',
            codigoRegimeTributario: '',
            codigoRegimeTributarioEspecial: '',
            incentivoCultural: '',
            incentivoFiscal: '',
            tipoBairro: '',
            tipoLogradouro: '',
            email: '',
            identificacaoEstrangeiro: '',
            simplesNacional: '',
            idNotaFiscalNavigation: null!
        };
    }
}
