import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumTipoNotaFiscal } from 'src/app/enum/enumTipoNotaFiscal';
import { NotaFiscalModel } from 'src/app/models/cliente/notaFiscal/notaFiscalModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiNotaFiscalEnvioSistemicoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.NotaFiscal;
    private version = 'api/'
    private controller = 'notaFiscalEnvioSistemico';
    private mock = false;

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }
    
    // emitir(idVenda: number, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean): Observable<NotaFiscalModel> {
    //     return this.gc.post(this.api, `${this.version}${this.controller}/emitir/${idVenda}/${tipoNotaFiscal}`, exibeCarregando);
    // }

    emitirNfceComDiferenteCpf(idVenda: number, cpfCnpj: string, tipoNotaFiscal: enumTipoNotaFiscal, transmitirNfAutomaticamente: boolean, movEstContabilNotaFiscalOrigVenda: string,
        permiteEstoqueVirtual: string, movEstVirtualNotaFiscalOrigVenda: string, permiteEstoqueNegativo: string, exibeCarregando: boolean): Observable<NotaFiscalModel> {
        cpfCnpj = encodeURIComponent(cpfCnpj);
        return this.gc.post(this.api, `${this.version}${this.controller}/emitirNfceComDiferenteCpf?tipoNotaFiscal=${tipoNotaFiscal}&idVenda=${idVenda}&cpfCnpj=${cpfCnpj}&transmitirNfAutomaticamente=${transmitirNfAutomaticamente}&movEstContabilNotaFiscalOrigVenda=${movEstContabilNotaFiscalOrigVenda}&permiteEstoqueVirtual=${permiteEstoqueVirtual}&movEstVirtualNotaFiscalOrigVenda=${movEstVirtualNotaFiscalOrigVenda}&permiteEstoqueNegativo=${permiteEstoqueNegativo}`, exibeCarregando);
    }
    emitirNfseComDiferenteCpf(idVenda: number, cpfCnpj: string, tipoNotaFiscal: enumTipoNotaFiscal, transmitirNfAutomaticamente: boolean, movEstContabilNotaFiscalOrigVenda: string,
        permiteEstoqueVirtual: string, movEstVirtualNotaFiscalOrigVenda: string, permiteEstoqueNegativo: string, exibeCarregando: boolean): Observable<NotaFiscalModel> {
        cpfCnpj = encodeURIComponent(cpfCnpj);
        return this.gc.post(this.api, `${this.version}${this.controller}/emitirNfseComDiferenteCpf?tipoNotaFiscal=${tipoNotaFiscal}&idVenda=${idVenda}&cpfCnpj=${cpfCnpj}&transmitirNfAutomaticamente=${transmitirNfAutomaticamente}&movEstContabilNotaFiscalOrigVenda=${movEstContabilNotaFiscalOrigVenda}&permiteEstoqueVirtual=${permiteEstoqueVirtual}&movEstVirtualNotaFiscalOrigVenda=${movEstVirtualNotaFiscalOrigVenda}&permiteEstoqueNegativo=${permiteEstoqueNegativo}`, exibeCarregando);
    }
    emitirNfeComDiferenteCpf(idVenda: number, cpfCnpj: string, tipoNotaFiscal: enumTipoNotaFiscal, transmitirNfAutomaticamente: boolean, movEstContabilNotaFiscalOrigVenda: string,
        permiteEstoqueVirtual: string, movEstVirtualNotaFiscalOrigVenda: string, permiteEstoqueNegativo: string, exibeCarregando: boolean): Observable<NotaFiscalModel> {
        cpfCnpj = encodeURIComponent(cpfCnpj);
        return this.gc.post(this.api, `${this.version}${this.controller}/emitirNfeComDiferenteCpf?tipoNotaFiscal=${tipoNotaFiscal}&idVenda=${idVenda}&cpfCnpj=${cpfCnpj}&transmitirNfAutomaticamente=${transmitirNfAutomaticamente}&movEstContabilNotaFiscalOrigVenda=${movEstContabilNotaFiscalOrigVenda}&permiteEstoqueVirtual=${permiteEstoqueVirtual}&movEstVirtualNotaFiscalOrigVenda=${movEstVirtualNotaFiscalOrigVenda}&permiteEstoqueNegativo=${permiteEstoqueNegativo}`, exibeCarregando);
    }
}