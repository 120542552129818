<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-ban icon-header-modal"></i>&nbsp;Cancelar Venda</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row pb-3" *ngIf="exibirLoginUsuarioESenha">
            <div class="col-12">
                <label class="form-label text-c-red" >Usuário sem acesso, informe o usuário e senha com permissão para cancelar Venda.</label>
            </div>
        </div>
        <div class="row pt-5 pb-3" *ngIf="exibirLoginUsuarioESenha">
            <div class="col-2">

            </div>
            <div class="col-4">
                <span class="p-float-label">
                    <input id="float-input" type="text" pInputText [(ngModel)]="usuario" (keypress)="keyPress($event)" #elmUsuario>
                    <label for="float-input">Usuário</label>
                </span>
                
            </div>
            <div class="col-4">
                <span class="p-float-label">
                    <input id="float-input" type="password" pInputText [(ngModel)]="senha" (keypress)="keyPress($event)"> 
                    <label for="float-input">Senha</label>
                </span>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row pt-2">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <app-combo-motivo-cancelamento idCampo="idMotivoCancelamento" 
                        [label]="'Motivo do Cancelamento'"
                        [tipoCancelamento]="tipoCancelamento"
                        [(ngModel)]="inputDados.idMotivoCancelamento">
                    </app-combo-motivo-cancelamento>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <app-combo-caixa 
                        idCampo="idCaixa"
                        toolTip="Selecione o Caixa. Obs.: É obrigatório selecionar algum caixa em aberto para fazer o cancelamento, mesmo que não seja o caixa em que esta venda tenha sido realizado."
                        [(ngModel)]="parametrosCancelarVenda.idCaixa"
                        (abriuModalCaixa)="abriuModalCaixa($event)"                        
                        [situacaoCaixa]="situacaoCaixa"
                        [carregamentoManual]="false"
                    >
                    </app-combo-caixa>
                </div>
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <app-combo-tabela-centro-custo                     
                        [(ngModel)]="parametrosCancelarVenda.idTabelaCentroCusto"
                        [tipoCentroCusto]="tipoCentroCusto">
                    </app-combo-tabela-centro-custo>                    
                </div>                
            </div>
            <div class="col-12 col-md-6 ">
                <div class="form-group">
                    <label class="form-label" for="observacao">Observação</label>
                    <input maxlength="30" class="form-control input-md" id="observacao" name="observacao"
                        type="text" placeholder="Digite a observação..."
                        [(ngModel)]="parametrosCancelarVenda.observacao">
                </div>
            </div>
        </div>

    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button" [disabled]="loading"
            class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Voltar
        </button>&nbsp;
        <button type="button" (click)="cliqueCancelarVenda()" #elmCancelarVenda [disabled]="loading"
            class="btn btn-danger inline"><i class="fad fa-ban"></i> Cancelar Venda</button>&nbsp;
    </div>
</app-ui-modal>
