import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { EstoqueModel } from 'src/app/models/cliente/estoque/EstoqueModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { EstoqueMovimentacaoModel } from 'src/app/models/cliente/EstoqueMovimentacao/estoqueMovimentacaoModel';
import { EstoqueControleValidadeProdutoModel } from 'src/app/models/cliente/EstoqueControleValidadeProduto/estoqueControleValidadeProdutoModel';
import { CadastrarEstoqueModel } from 'src/app/models/cliente/estoque/cadastrarEstoqueModel';
import { LancarEstoqueModel } from 'src/app/models/cliente/estoque/lancarEstoqueModel';
import { CustomListarEstoqueMovimentacao } from 'src/app/models/cliente/EstoqueMovimentacao/CustomListarEstoqueMovimentacao';

@Injectable({
    providedIn: 'root'
})
export class ApiEstoqueService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private controller = 'estoque';
    private version = 'api/'

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getByIdProdutoUnico(idProdutoUnico: number, exibirCarregando: boolean): Observable<EstoqueModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getByIdProdutoUnico/${idProdutoUnico}`, exibirCarregando);
    }

    getByIdProdutoVariacao(idProdutoVariacao: number, exibirCarregando: boolean): Observable<EstoqueModel> {
        return this.gc.get(this.api, `api/${this.controller}/getByIdProdutoVariacao/${idProdutoVariacao}`, exibirCarregando);
    }

    getByIdOrigem(origem: string, chave: string, exibirCarregando: boolean): Observable<CustomListarEstoqueMovimentacao[]> {
        return this.gc.get(this.api, `api/${this.controller}/getByIdOrigem/${origem}/${chave}`, exibirCarregando);
    }

    getByIdProdutoUnicoELoja(idProduto: number, exibirCarregando: boolean): Observable<EstoqueModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getByIdProdutoUnicoELoja/${idProduto}`, exibirCarregando);
    }

    getAllByArrayIdProdutoVariacao(idsProdutosVariacoes: number[], exibirCarregando: boolean): Observable<EstoqueModel[]> {
        return this.gc.post(this.api, `api/${this.controller}/getAllByArrayIdProdutoVariacao`, idsProdutosVariacoes, exibirCarregando);
    }

    getAllMovimentacaoByIdProdutoVariacao(idsProdutoVariacao: number, exibirCarregando: boolean): Observable<EstoqueMovimentacaoModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getAllMovimentacaoByIdProdutoVariacao/${idsProdutoVariacao}`, exibirCarregando);
    }

    put(obj: EstoqueModel, exibirCarregando: boolean) {
        return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idLoja}/${obj.idProdutoUnico}`, obj, exibirCarregando);
    }

    adicionarEstoqueManual(obj: CadastrarEstoqueModel, exibirCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/adicionarEstoqueManual`, obj, exibirCarregando);
    }

    adicionarEstoqueManualEmLote(obj: CadastrarEstoqueModel[], exibirCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/adicionarEstoqueManualEmLote`, obj, exibirCarregando);
    }

    lancarEstoque(obj: LancarEstoqueModel, exibirCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/lancarEstoque`, obj, exibirCarregando);
    }

    estornarLancamentoDeEstoque(obj: LancarEstoqueModel, exibirCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/estornarLancamentoDeEstoque`, obj, exibirCarregando);
    }
    adicionarControleValidadeProduto(obj: EstoqueControleValidadeProdutoModel, exibirCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/adicionarControleValidadeProduto`, obj, exibirCarregando);
    }
    getControleValidadeProdutoPorProduto(idProdutoUnico: number, exibirCarregando: boolean): Observable<EstoqueControleValidadeProdutoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getControleValidadeProdutoPorProduto/${idProdutoUnico}`, exibirCarregando);
    }
    removerControleValidadeProdutoPorProduto(idEstoqueControleValidadeProduto: number, exibirCarregando: boolean): Observable<void> {
        return this.gc.delete(this.api, `${this.version}${this.controller}/removerControleValidadeProdutoPorProduto/${idEstoqueControleValidadeProduto}`, exibirCarregando);
    }
}
