import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTabelaEstadoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Dominios;
    private version = 'api/'
    private controller = 'tabelaEstado';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAllEstados() {
        return this.http.get<any>('assets/data/estados.json')
        .pipe(
            map((data: any) => {
                return data;
            })
        );
        //return this.gc.get(this.api, `${this.version}${this.controller}/getAllEstados`, true);
    }
}