<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-lg'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-info-circle"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body modal-detalhe-produto" *ngIf="inputDados != null">
        <!-- <div class="row">
            <div class="col-12">
                <h4>
                    {{descricaoModal}}
                </h4>
            </div>
        </div> -->
       
        <div class="row">
            <div class="col-xl-6 col-md-6 col-sm-12 p-t-5">
                <div class="form-group">
                    <app-combo-profissional [(ngModel)]="inputDados.proCpfCnpj"
                    [label]="'Vendedor'" [style]="'width:100%'"
                    [toolTip]="'Selecione um profissional caso queira atribuir comissão à ele ou apenas para registrar quem realizou a venda'">
                    </app-combo-profissional>
                </div>
            </div>            
            <div class="col-xl-6 col-md-6 col-sm-12 p-t-5">
                <div class="form-group">
                    <app-combo-prestador-servico [(ngModel)]="inputDados.preCpfCnpj" 
                    [label]="'Prestador de Serviço'" [style]="'width:100%'"
                    [toolTip]="'Selecione um prestador de serviço caso queira atribuir comissão à ele ou apenas para registrar quem executou o serviço'">
                    </app-combo-prestador-servico>
                </div>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-12">
                <label class="d-inline form-check-label mouse-pointer form-label" for="observacaoInterna">Observação Interna</label>&nbsp;
                <i class="fas fa-info-circle"
                ngbTooltip="Alguma observação que deseja inserir na venda que será visto internamente."></i>
                <textarea maxlength="300" style="height:fit-content" class="form-control input-md observacaoInterna class-npe" id="observacaoInterna" name="observacaoInterna"
                    type="text"  placeholder="Observação..."
                    [(ngModel)]="inputDados.observacaoInterna"></textarea>
            </div>
        </div>
        <div class="row p-t-5">
            <div class="col-12">
                <label class="d-inline form-check-label mouse-pointer form-label" for="informacaoDadosComplementares">Informações Adicionais</label>&nbsp;
                <i class="fas fa-info-circle"
                ngbTooltip="As informações inseridas neste campo também será adicionado no campo informações adicionais da nota fiscal eletrônica (Caso seja emitida). Para pular linha utilize o caracter | (pipe)."></i>
                <textarea maxlength="5000" style="height:fit-content" class="form-control input-md informacaoDadosComplementares class-npe" id="informacaoDadosComplementares" name="informacaoDadosComplementares"
                    type="text"  placeholder="Informações Adicionais..."
                    [(ngModel)]="inputDados.informacaoDadosComplementares"></textarea>
            </div>
        </div>
    </div>
    
    <div class="app-modal-footer">
        <button [disabled]="false" (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class="fad fa-window-close"></i> Fechar
        </button>&nbsp;
        <button [disabled]="false" (click)="cliqueSalvar()" type="button" *ngIf="inputDados != null && inputDados.idTabelaStatusVenda != statusVendaCancelada && inputDados.idTabelaStatusVenda != statusVendaFinalizada "
            class="btn btn-primary m-r-5 inline"><i class="feather icon-save"></i> Salvar
        </button>
    </div>
</app-ui-modal>