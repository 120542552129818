import swal from 'sweetalert2';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { PrecoModel } from 'src/app/models/cliente/Preco/precoModel';
import { ApiPrecoService } from 'src/app/services/cliente/api-preco.service';
import { SelectOptionService } from 'src/app/shared/services/select/select-option.service';
import { ApiMarcaService } from 'src/app/services/cliente/api-marca.service';
import { ApiTabelaLocalizacaoProdutoService } from 'src/app/services/cliente/api-tabela-localizacao-produto-service';
import { OrcamentoModel } from 'src/app/models/cliente/Orcamento/orcamentoModel';

@Component({
  selector: 'app-modal-informacoes-adicionais',
  templateUrl: './modal-informacoes-adicionais.component.html',
  styleUrls: ['./modal-informacoes-adicionais.component.scss']
})
export class ModalInformacoesAdicionaisComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  inputDados: OrcamentoModel = null!;
  @Output() fechouModal: EventEmitter<OrcamentoModel> = new EventEmitter<OrcamentoModel>();
  @Output() salvouAlteracao: EventEmitter<OrcamentoModel> = new EventEmitter<OrcamentoModel>();

  tituloModal: string = '';
  descricaoModal: string = '';
  minDate: Date = new Date();
  subsConsultar: Subscription = null!

  constructor( 
    private selectOptions: SelectOptionService,
    private showMessageError: ShowMessageError,
    private apiPrecoService: ApiPrecoService,
    private apiMarcaService: ApiMarcaService,
    private apiLocalizacaoProdutoService: ApiTabelaLocalizacaoProdutoService,
    ) { }

  ngOnInit(): void {
    this.setStartObject();
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  cliqueCancelar(){
    this.fecharModal();
  }
  inputDadosPreco: PrecoModel = null!;
  ehProduto: Boolean = true;
  tipoAcaoAbrirFecharCaixa: enumAcaoAbrirFecharCaixa = null!;
  tipoAcaoAbrirCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Abrir;
  tipoAcaoFecharCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Fechar;
  public abrirModal(item: OrcamentoModel) {
    this.tituloModal = 'Informações Adicionais';
    this.descricaoModal = 'Informe detalhes para este Orçamento';
    if (item.dataExecucao != null) item.dataExecucao = new Date(item.dataExecucao);
    if (item.dataPrevistaEntrega != null) item.dataPrevistaEntrega = new Date(item.dataPrevistaEntrega);
    if (item.dataValidadeOrcamentoPrevenda != null) item.dataValidadeOrcamentoPrevenda = new Date(item.dataValidadeOrcamentoPrevenda);
    this.inputDados = item;
    this.modal.show();
  }
  getFormatDecimal(number: number): string{
    if (number == null) return 'n/d';
    return new Intl.NumberFormat('pr-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  fecharModal() {
    this.fechouModal.emit(this.inputDados);
    this.modal.hide();
  }

  bloquearCampos(){  }

  setStartObject(){
    this.inputDados = null!;
  }
  loading: boolean = false;

  cliqueSalvar(){
    this.salvouAlteracao.emit(this.inputDados);
    this.modal.hide();
  }
}
