import { enumSimNao } from "src/app/enum/enumSimNao";
import { enumStatus } from "src/app/enum/enumStatus";
import { TabelaMeioPagamentoModel } from "./tabelaMeioPagamentoModel";



export class TabelaMeioPagamentoModelInstance {
    public static get(): TabelaMeioPagamentoModel {
        return {
            idTabelaMeioPagamento: '',
            descricao: '',
            permiteExclusao: enumSimNao.Nao,
            indicadorFormaPagamento: '',
            padrao: enumSimNao.Nao,
            permitirAbrirGaveta: enumSimNao.Nao,
            status: enumStatus.Ativo,
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            parametrizacaoCondPagamentos: null!,
            descricaoMensagemPix: '',
            formaPagamentos: null!,
            contasAPagRecs: null!,
            caixaSangriaSuprimentos: null!,
            caixaConferencia: null!,
            caixaMovimentos: null!
        };
    }

    public static getArray(): TabelaMeioPagamentoModel[] {
        return [this.get()];
    };
}
