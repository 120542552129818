import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumTipoCentroCusto } from 'src/app/enum/enumTipoCentroCusto';
import { TabelaCentroCustoModel } from 'src/app/models/cliente/TabelaCentroCusto/tabelaCentroCustoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class ApiTabelaCentroCustoService {

  private gc: CommunicationManager;
  private api = AppSettings.API.Cliente;
  private version = 'api/'
  private controller = 'tabelaCentroCusto';

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
  }

  getAll(tipoCentroCusto: enumTipoCentroCusto, exibeCarregando: boolean) {
    return this.gc.get(this.api, `api/${this.controller}/tipoConsulta/${tipoCentroCusto}`, exibeCarregando);
  }

  get(id: string) {
    return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
  }

  put(obj: TabelaCentroCustoModel) {
    return this.gc.put(this.api, `${this.version}${this.controller}`, obj);
  }

  post(obj: TabelaCentroCustoModel){
    return this.gc.post(this.api, `${this.version}${this.controller}`, obj, true);
  }

  delete(id: number){
    return this.gc.delete(this.api, `${this.version}${this.controller}/${id}`);
  }
}