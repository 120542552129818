import { NotaFiscalTotalIcmModel} from "./notaFiscalTotalIcmModel";

export class NotaFiscalTotalIcmModelInstance {
    public static get(): NotaFiscalTotalIcmModel {
        return {
            idNotaFiscal: 0,
            valorBcIcms: 0,
            valorTotalIcms: 0,
            valorTotalIcmsDesonerado: 0,
            valorTotalIcmsRelativoFcpUfDestino: 0,
            valorTotalIcmsInterestadualPUfDest: 0,
            valorTotalIcmsInterestadualPUfRemet: 0,
            valorTotalFcp: 0,
            valorBcIcmsSt: 0,
            valorTotalIcmsSt: 0,
            valorTotalFcpRetidoPorSubstTributaria: 0,
            valorTotalFcpRetidoAntPorSubstTributaria: 0,
            valorTotalProdutosServicos: 0,
            valorTotalFrete: 0,
            valorTotalSeguro: 0,
            valorTotalDesconto: 0,
            valorTotalIi: 0,
            valorTotalIpi: 0,
            valorTotalIpiDevolvido: 0,
            valorPis: 0,
            valorCofins: 0,
            valorOutrasDespesasAcessorias: 0,
            valorTotalNfe: 0,
            valorAproximadoTotalTributosFedEstMun: 0,
            idNotaFiscalNavigation: null!,
            notaFiscalTotalIssqn: null!,
            notaFiscalTotalRetencaoTributo: null!
        };
    }
    public static getArray(): NotaFiscalTotalIcmModel[] {
        return [this.get()];
    }
}
