<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-xl'">

    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header"> <i class="fad fa-shopping-cart icon-header-modal"></i>&nbsp;Vendas
                    Pendentes</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <div class="row pt-2">
            <p-table #dtVendasPendentes [value]="vendasPendentes" dataKey="idVenda" selectionMode="single"
                [scrollHeight]="heightListaVendasPendentes" class="p-table nowrap table-hover " [scrollable]="true"
                [loading]="loading" [(selection)]="inputDados" (onRowSelect)="onRowSelectItemVenda($event)"
                [paginator]="false" #elmListaVendasPendentes
                currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center " style="width:5%">#</th>
                        <th class="text-center " style="width:11%">Tipo</th>
                        <th class="text-center " style="width:11%">Situação</th>
                        <th class="text-center " style="width:20%">Cpf/Cnpj</th>
                        <th class="text-center " style="width:28%">Nome/Razão Social</th>
                        <th class="text-center " style="width:10%">Valor</th>
                        <th class="text-center " style="width:10%">Ações</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center" style="width:5%">
                            {{item.idVenda}}
                        </td>
                        <td class="text-center" style="width:11%">
                            <span *ngIf="item.tipoVenda == tipoVendaServico">Serviço</span>
                            <span *ngIf="item.tipoVenda == tipoVendaProduto">Produto</span>
                            <span *ngIf="item.tipoVenda == tipoVendaUnica">Prod/Serv</span>
                        </td>
                        <td class="text-center" style="width:11%">
                            <span *ngIf="item.idTabelaStatusVenda == 1">Preenchimento</span>
                            <span *ngIf="item.idTabelaStatusVenda == 2">Pagamento</span>
                        </td>
                        <td class="text-center" style="width:20%">
                            <span class="text-center"
                                *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'J' && (item.cpfCnpjNavigation?.informouCpfCnpj == '' || item.cpfCnpjNavigation?.informouCpfCnpj == null || item.cpfCnpjNavigation?.informouCpfCnpj == 'N')">
                                {{item.cpfCnpj | cnpj }}
                            </span>
                            <span class="text-center"
                                *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'J' && item.cpfCnpjNavigation?.informouCpfCnpj == 'S'">
                                {{item.cpfCnpjNavigation.cpfCnpjInformado | cnpj }}
                            </span>
                            <span class="text-center"
                                *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'F' && (item.cpfCnpjNavigation?.informouCpfCnpj == '' || item.cpfCnpjNavigation?.informouCpfCnpj == null || item.cpfCnpjNavigation?.informouCpfCnpj == 'N')">
                                {{item.cpfCnpj | cpf }}
                            </span>
                            <span class="text-center"
                                *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'F' && item.cpfCnpjNavigation?.informouCpfCnpj == 'S'">
                                {{item.cpfCnpjNavigation.cpfCnpjInformado | cpf }}
                            </span>
                            <span class="text-center"
                                *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'E' && (item.cpfCnpjNavigation?.informouCpfCnpj == '' || item.cpfCnpjNavigation?.informouCpfCnpj == null || item.cpfCnpjNavigation?.informouCpfCnpj == 'N')">
                                {{item.cpfCnpj}}
                            </span>
                            <span class="text-center"
                                *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'E' && item.cpfCnpjNavigation?.informouCpfCnpj == 'S'">
                                {{ item.cpfCnpjNavigation.cpfCnpjInformado }}
                            </span>
                        </td>
                        <td style="width:28%">
                            <span
                                *ngIf="item.cpfCnpjNavigation != null">{{item.cpfCnpjNavigation?.razaoSocialNome}}</span>
                            <span *ngIf="item.cpfCnpjNavigation == null">Cliente não informado</span>
                        </td>
                        <td class="text-right" style="width:15%">
                            {{item.valorTotal | currency:'BRL'}}
                        </td>
                        <td class="table-action nowrap text-center" style="width:10%">
                            <a ngbTooltip="Selecionar" class="pr-1" (click)="selecionarVenda(item)">
                                <i class="mouse-pointer fad fa-hand-pointer text-c-blue f-16"></i>
                            </a>
                            <a ngbTooltip="Cancelar Venda" class="" (click)="cancelarVenda(item)"><i
                                class="mouse-pointer fad fa-hand-holding-usd text-c-red fad fa-times-circle f-16"></i>
                            </a>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="6">Não há vendas em andamento</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="app-modal-footer">
        <button (click)="cliqueCancelarTodasVendas()" type="button" #elmBotaoCancelar
            class="btn btn-danger inline"><i class="fad fa-ban"></i> Cancelar Todas
        </button>&nbsp;
        <button (click)="cliqueCancelar()" type="button" #elmBotaoCancelar
            class="btn btn-secondary inline"><i class="fad fa-window-close"></i> Fechar
        </button>
    </div>
</app-ui-modal>
<app-modal-cancelar-venda
    (cancelouVenda)="retornoCancelamentoVenda($event)">
</app-modal-cancelar-venda>