import { Component, OnInit, ViewChild, Input } from '@angular/core';
//import { Dialog } from 'primeng/dialog';
//import {DialogModule} from 'primeng/dialog';
import { UiModalComponent } from '../../modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-lock',
  templateUrl: './app-modal-lock.component.html',
  styleUrls: ['./app-modal-lock.component.scss']
})
export class AppModalLockComponent implements OnInit {

  @ViewChild('modalLock') modalLock!: UiModalComponent;

  @Input() closable: boolean = false;

  mensagem = 'Por favor, aguarde enquanto sua solicitação é executada!';

  constructor() { }

  ngOnInit() {
  }

  exibirModalLock(mensagemModal?: string) {
    if (mensagemModal !== '' && mensagemModal != null) {
      this.mensagem = mensagemModal;
    }
    this.modalLock.show();
    
  }

  esconderModalLock() {
     this.modalLock.hide();
  }
}
