import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ControleValeModel } from 'src/app/models/cliente/ControleVale/controleValeModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiValeService {
    
    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private controller = 'vale';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    pesquisarVales(filtro: string, exibeCarregando: boolean ): Observable<ControleValeModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/pesquisarVale?filtro=${filtro}`, exibeCarregando);
    }
}