<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header uppercase">&nbsp;<i [class]="iconeModal"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <!-- <div class="app-modal-body" *ngIf="passo == 'margem'">
        <div class="row pb-3" >
            <div class="col-12">
                <label class="form-label text-c-blue" >margem</label>
            </div>
        </div>
    </div> -->
    <div class="app-modal-body" *ngIf="passo == 'desconto'">
        <div class="row pb-3" >
            <div class="col-12">
                <label class="form-label text-c-blue" >Informe o desconto que deseja aplicar a esta condição de pagamento</label>
            </div>
        </div>
        <div class="row" >
            <div class="col-12">
                <div class="row">
                    
                    <div class="col-6 text-center" >
                        <div class="form-group text-center" >
                            <label class="form-label-bold" for="valorDescontoVendedor">PERCENTUAL DESCONTO %</label>
                            <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorDescontoPorPercentual" suffix="" prefix="" #elmPercentualDesconto (keypress)="keyPress($event)" suffix=" %" [maxlength]="8"
                                currency="BRL" locale="pt-BR" inputId="valorDescontoPorPercentual" mode="decimal" [minFractionDigits]="2" (ngModelChange)="changeValorDescontoPorPercentual($event)" [min]="0" [max]="condicaoPagamentoSelecionado.descontoMaxPercVendedor" (onFocus)="onFoucus($event)"
                                [maxFractionDigits]="2">
                            </p-inputNumber>
                            <!-- &nbsp;<i class="fas fa-info-circle" [ngbTooltip]="tooolTipValorMaxDescontoVendedor"></i> -->
                        </div>
                    </div>
                    <div class="col-6 text-center" >
                        <div class="form-group text-center" >
                            <label class="form-label-bold" for="valorDescontoVendedor">VALOR DESCONTO R$</label>
                            <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDescontoVendedor" suffix="" prefix="R$ " #elmValorDesconto (keypress)="keyPress($event)" (onFocus)="onFoucus($event)"
                                currency="BRL" locale="pt-BR" inputId="valorDescontoVendedor" mode="decimal" [minFractionDigits]="2" (ngModelChange)="changeValorDesconto($event)" [min]="0" [max]="valorMaxDescontoVendedor"
                                [maxFractionDigits]="2">
                            </p-inputNumber>
                            <!-- &nbsp;<i class="fas fa-info-circle" [ngbTooltip]="tooolTipValorMaxDescontoVendedor"></i> -->
                        </div>
                    </div>
                </div>
                <!-- <div class="row">
                    <label class="mensagem-aviso">{{tooolTipValorMaxDescontoVendedor}}</label>
                </div> -->
            </div>
        </div>
        <div class="row pt-2">
            <div class="col-4 " *ngIf="exibeTaxas">
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Taxas</span>
                        <button class="btn" (click)="changeTaxaDespesa()">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showTaxaDespesa"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showTaxaDespesa"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showTaxaDespesa">
                        <li>
                          Despesa <strong>{{ condicaoPagamentoSelecionado.taxaDespesa | number:'1.2-5' }} %</strong>
                        </li>
                        <li>
                            % Juros <strong>{{ condicaoPagamentoSelecionado.percJuros | number:'1.2-5' }} %</strong>
                        </li>
                        <li>
                            % Desconto <strong>{{ condicaoPagamentoSelecionado.percDescontos | number:'1.2-5' }} %</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-4 " *ngIf="exibeInformacoesParcela">
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Parcelas</span>
                        <button class="btn" (click)="changeInformacoesParcela()">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showInformacoesParcela"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showInformacoesParcela"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showInformacoesParcela">
                        <li>
                                Intervalo de Dias <strong>{{ condicaoPagamentoSelecionado.intervaloDias | number:'1.2-5' }}</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-4 " *ngIf="exibeDescontoVendedor">
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Desconto Vendedor</span>
                        <button class="btn" (click)="changeDescontoVendedor()">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showDescontoVendedor"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showDescontoVendedor"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showDescontoVendedor">
                        <li>
                            Percentual <strong>{{ condicaoPagamentoSelecionado.descontoMaxPercVendedor | number:'1.2-5' }} %</strong>
                        </li>
                        <li *ngIf="condicaoPagamentoSelecionado.descontoMaxValorVendedor == 0 || condicaoPagamentoSelecionado.descontoMaxValorVendedor == null">
                            Valor <strong>{{ valorMaxDescontoVendedor | currency:'BRL' }}</strong>
                        </li>
                        <li *ngIf="condicaoPagamentoSelecionado.descontoMaxValorVendedor != 0 && condicaoPagamentoSelecionado.descontoMaxValorVendedor != null
                            && valorMaxDescontoVendedor > condicaoPagamentoSelecionado.descontoMaxValorVendedor">
                            Valor <strong>{{ condicaoPagamentoSelecionado.descontoMaxValorVendedor | currency:'BRL' }}</strong>
                        </li>
                        <li *ngIf="condicaoPagamentoSelecionado.descontoMaxValorVendedor != 0 && condicaoPagamentoSelecionado.descontoMaxValorVendedor != null
                            && condicaoPagamentoSelecionado.descontoMaxValorVendedor > valorMaxDescontoVendedor">
                            Valor <strong>{{ valorMaxDescontoVendedor | currency:'BRL' }}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
    <div class="app-modal-body" *ngIf="passo == 'pagamento'">
        <div class="row" *ngIf="!exibirQrCode && meioPagamento.idTabelaMeioPagamento != CreditoLoja && meioPagamento.idTabelaMeioPagamento != BoletoBancario && meioPagamento.idTabelaMeioPagamento != Cheque">
            <div class="col-4 text-center" >
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorPendente">PENDENTE&nbsp;<i class="fas fa-info-circle" ngbTooltip="Valor À Pagar não considerando Taxas e Descontos!"></i></label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorPendente" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorPendente" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-4 text-center" >
                <div class="form-group text-center" >
                    <label class="form-label-bold" for="valorAPagar">VALOR À SER PAGO&nbsp;<i class="fas fa-info-circle" ngbTooltip="Valor que deve ser pago, considerando Taxas e Descontos!"></i></label>
                 
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorAPagar" suffix="" prefix="R$ " #elmValorPago (keypress)="keyPress($event)"
                    currency="BRL" locale="pt-BR" inputId="valorAPagar" mode="decimal" [minFractionDigits]="2" (ngModelChange)="changeValorAPagar($event)" 
                    [maxFractionDigits]="2" (onFocus)="onFoucus($event)">
                </p-inputNumber>
                </div>
            </div>
            <div class="col-md-4" >
                <div class="form-group text-center">
                    <label class="form-label-bold uppercase" for="valorFinal">Valor C/ Juros/Desconto</label>
                    <label class="form-label-valor-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotal)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorFinal" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorFinal" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!exibirQrCode && (meioPagamento.idTabelaMeioPagamento == CreditoLoja || meioPagamento.idTabelaMeioPagamento == BoletoBancario || meioPagamento.idTabelaMeioPagamento == Cheque)">
            <div class="col-3 text-center" >
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorPendente">PENDENTE&nbsp;<i class="fas fa-info-circle" ngbTooltip="Valor À Pagar não considerando Taxas e Descontos!"></i></label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorPendente" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorPendente" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-3 text-center" >
                <div class="form-group text-center" >
                    <label class="form-label-bold" for="valorAPagar">VALOR À SER PAGO&nbsp;<i class="fas fa-info-circle" ngbTooltip="Valor que deve ser pago, considerando Taxas e Descontos!"></i></label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorAPagar" suffix="" prefix="R$ " #elmValorPago (keypress)="keyPress($event)"
                    currency="BRL" locale="pt-BR" inputId="valorAPagar" mode="decimal" [minFractionDigits]="2" (ngModelChange)="changeValorAPagar($event)" 
                    [maxFractionDigits]="2" (onFocus)="onFoucus($event)">
                </p-inputNumber>
                </div>
            </div>
            <div class="col-md-3" >
                <div class="form-group text-center">
                    <label class="form-label-bold uppercase" for="valorFinal">Valor C/ Juros/Desc</label>
                    <label class="form-label-valor-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotal)}}
                    </label>
                </div>
            </div>
            <!-- jander  [monthNavigator]="true" [yearNavigator]="true" -->
            <div class="col-md-3" *ngIf="meioPagamento.idTabelaMeioPagamento != Cheque">
                <div class="form-group text-center">
                  <label class="form-label-bold" for="dataPrimeiroVencimento">DATA 1º VENCIMENTO&nbsp;<i class="fas fa-info-circle" ngbTooltip="Será utilizado para o vencimento da primeira parcela e se caso haver mais parcelas, 
                    será calculado a partir desta data + 30 dias(sucessivamente). Caso contrário será calculado de acordo com a parametrização desta condição. Verifique a opção abaixo: PARCELAS."></i></label>
                  <p-calendar [(ngModel)]="dataPrimeiroVencimento" name="dataPrimeiroVencimento" [readonlyInput]="false"
                    dateFormat="dd/mm/yyyy" [showButtonBar]="true" dateFormat="dd/mm/yy"
                    [inputStyleClass]="'form-control'" placeholder="dd/mm/aaaa" id="dataPrimeiroVencimento">
                  </p-calendar>
                </div>
            </div>
        </div>
        <div class="row " *ngIf="condicaoPagamentoSelecionado != null && condicaoPagamentoSelecionado.obrigaInformarNsu == 'S'" >
            <div class="col-3"></div>
            <div class="col-6 text-center" >
                <div class="form-group text-center">
                    <label class="form-label-bold" >Nº AUT. PGTO (NSU)&nbsp;<i class="fas fa-info-circle" ngbTooltip="Informe o número de autorização da operação NSU!" ></i></label>
                    <input  class="form-control" placeholder="" [(ngModel)]="inputDados.numeroAutorizacaoOperacao" maxlength="20">
                </div>
            </div>
        </div>
        <div class="row" *ngIf="meioPagamento.idTabelaMeioPagamento == Cheque" >
            <div class="col-4 text-center" *ngFor=" let item of Cheques; let i = index">
                <div class="form-group text-center" >
                    <!-- <label class="form-label-bold" >Nº CHEQUE {{i+1}}&nbsp;<i class="fas fa-info-circle" ngbTooltip="Informe o Número do Cheque!" ></i></label> -->
                    <label class="form-label-bold" >Nº CHEQUE {{i+1}}&nbsp;<a ngbTooltip="Detalhar Cheque" class="" (click)="detalharCheque(item, i)"><i
                        class="mouse-pointer text-c-blue fad fa-plus-circle f-16"></i></a>
                    </label>
                    <input  class="form-control" placeholder="" [(ngModel)]="item.numeroCheque" maxlength="50">
                </div>
            </div>
        </div>
        <div class="row " *ngIf="exibeValorTroco">
            <div class="col-3"></div>
            <div class="col-3 text-center" *ngIf="exibeValorTroco">
                <div class="form-group text-center" >
                    <label class="form-label-bold" for="elmValorEmDinheiro">DINHEIRO</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorEmDinheiro" suffix="" prefix="R$ " #elmValorEmDinheiro (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="elmValorEmDinheiro" mode="decimal" [minFractionDigits]="2" (ngModelChange)="changeValorDinheiro($event)" (onFocus)="onFoucus($event)"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-3 text-center" *ngIf="exibeValorTroco">
                <div class="form-group text-center" >
                    <label class="form-label-bold" for="valorTroco">TROCO&nbsp;<i class="fas fa-info-circle" ngbTooltip="Valor pago menos o valor final!"></i></label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTroco" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTroco" mode="decimal" [minFractionDigits]="2" 
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-3"></div>
        </div>
        <div class="row text-center" style="max-height: 7px;" *ngIf="exibeValoresPendentes && !exibirQrCode">
            <hr>
        </div>
        <div class="row text-center" *ngIf="exibeValoresPendentes && !exibirQrCode">
            <div class="col-1"></div>
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label uppercase" for="valorDespesaCondicaoPagamento">Despesa</label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDespesaCondicaoPagamento)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDespesaCondicaoPagamento" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorDespesaCondicaoPagamento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label uppercase" for="valorJurosCondicaoPagamento">Juros</label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorJurosCondicaoPagamento)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorJurosCondicaoPagamento" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorJurosCondicaoPagamento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label uppercase label-nowrap" for="valorDescontoCondicaoPagamento">Desconto Pgto</label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDescontoCondicaoPagamento)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDescontoCondicaoPagamento" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorDescontoCondicaoPagamento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label uppercase label-nowrap" for="valorDescontoVendedor">Desc. Vendedor</label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDescontoVendedor)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDescontoVendedor" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorDescontoVendedor" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label uppercase" for="valorRestante">RESTANTE</label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(valorRestante)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorRestante" suffix=""  [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorRestante" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
        </div>
        <div class="row text-center" *ngIf="exibeValoresPendentes && !exibirQrCode">
            <hr>
        </div>
        <div class="row pt-2" *ngIf="exibeCondicoesPagamentos && condicoesDePagamento != null && condicoesDePagamento.length > 0 && meioPagamento.idTabelaMeioPagamento !=  Outros">
            <p-table #dtCondicoesPagamentos [value]="condicoesDePagamento"
                selectionMode="single" [loading]="loading"
                class="p-table nowrap table-hover"  responsiveLayout="scroll"
                dataKey="idParametrizacaoCondPagamento" 
                [scrollHeight]="heightGrid" 
                styleClass=" " [paginator]="false" #elmListaCondicoesPagamentos>
                <ng-template pTemplate="header">
                <tr>
                    <th class="text-center ">Selecione</th>
                    <th class="text-center ">À Vista / Prazo</th>
                    <th class="text-center ">Qtde Parcelas</th>
                    <th class="text-center ">% Despesa</th>
                    <th class="text-center ">% Juros</th>
                    <th class="text-center ">% Desconto</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center">
                            <div class="form-check form-check-inline">
                                <input type="radio" [id]="getIdRadioSelecaoCondicaoPagamento(item.idParametrizacaoCondPagamento)" name="radioSelecaoCondicaoPagamento" class="form-check-input"                                  
                                    (click)="selecionarCondicaoDePagamento(item)" [(ngModel)]="idParametrizacaoCondPagamento" [value]="item.idParametrizacaoCondPagamento"
                                     >
                                <label class="form-check-label" [for]="getIdRadioSelecaoCondicaoPagamento(item.idParametrizacaoCondPagamento)">{{item.descricao}}</label>
                            </div>
                        </td>
                        <td class="text-center" (click)="selecionarCondicaoDePagamento(item)">
                            <span class="text-center" *ngIf="item.aVistaPrazo == '0'">À Vista</span>
                            <span class="text-center" *ngIf="item.aVistaPrazo == '1'">À Prazo</span>
                        </td>
                        <td class="text-center " (click)="selecionarCondicaoDePagamento(item)">
                            {{item.quantParcelas}}
                        </td>
                        <td (click)="selecionarCondicaoDePagamento(item)" class="text-center">
                            {{ item.taxaDespesa | number:'1.2-5' }}%
                        </td>
                        <td (click)="selecionarCondicaoDePagamento(item)" class="text-center">
                            {{ item.percJuros| number:'1.2-5' }}%
                        </td>
                        <td (click)="selecionarCondicaoDePagamento(item)" class="text-center">
                            {{ item.percDescontos| number:'1.2-5' }}%
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="6">Nâo existem condições de pagamento para o meio de pagamento selecionado</td>
                </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="row pt-2" *ngIf="meioPagamento.idTabelaMeioPagamento ==  Outros">
            <div class="col-12">
                <label class="form-label text-c-blue" >Selecione o Vale</label>
            </div>
            <div class="col-8">
                <input maxlength="60" class="form-control " id="pesquisar" name="pesquisar" type="text" (keypress)="keyPress($event)"
                        placeholder="CPF/CNPJ e/ou Nome/Nome Fantasia"  #elmFiltroVale
                        [(ngModel)]="filtroPesquisarVale">
            </div>
            <div class="col-4">
                <button class="btn btn-primary btn-md " (click)="pesquisarVales()">
                    <i class="far fa-file-search"></i>&nbsp;Pesquisar Vales
                </button>
            </div>
        </div>
        <div class="row pt-2" *ngIf="meioPagamento.idTabelaMeioPagamento ==  PagamentoInstantaneo">
            <hr>
            <div class="col-12">
                <div class="row pb-3">
                    <div class="col-12 text-center" *ngIf="!exibirQrCode">
                        <button type="button" (click)="cliqueExibirQrCode()" 
                            class="btn btn-warning inline"><i class="far fa-qrcode"></i> Exibir QR Code</button>
                    </div>
                    <div class="col-12  text-center" *ngIf="exibirQrCode">
                        <button type="button" (click)="cliqueEsconderQrCode()" 
                            class="btn btn-danger inline"><i class="fad fa-qrcode"></i> Esconder QR Code</button>
                    </div>
                </div>
                <div class="row" *ngIf="exibirQrCode">
                    <div class="row text-center" *ngIf="base64Pix != null">
                        <div class="col-2"></div>
                        <div class="col-8"><img  [src]="base64Pix"  /></div>
                        <div class="col-2"></div>
                        
                    </div>
                    <div class="row text-center p-t-50" *ngIf="base64Pix == null">
                        <label class="message-warning">Chave não cadastrada na parametrização, verifique em: Configurações/Condições de Pagamentos.</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row pt-2" *ngIf="meioPagamento.idTabelaMeioPagamento ==  Outros">
            <p-table #dtCondicoesPagamentos [value]="vales" 
                selectionMode="single" [loading]="loading"
                class="p-table nowrap table-hover"  responsiveLayout="scroll"
                dataKey="idParametrizacaoCondPagamento" 
                [scrollHeight]="heightGridVale" 
                styleClass=" " [paginator]="false" #elmListaCondicoesPagamentos>
                <ng-template pTemplate="header">
                <tr>
                    <th class="text-center ">Selecione</th>
                    <th class="text-center ">#</th>
                    <th class="text-center ">CPF/CNPJ</th>
                    <th class="text-center ">Nome</th>
                    <th class="text-center ">Valor Inicial</th>
                    <th class="text-center ">Saldo</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" >
                    <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center">
                            <div class="form-check form-check-inline">
                                <input type="checkbox" name="checkSelecaoVale" class="form-check-input"                                  
                                    [checked]="item.selecionado == 'S'"
                                    (change)="selecionarVale($event, item)" 
                                     >
                            </div>
                        </td>
                        <td class="text-center " >
                            {{ item.idControleVale }}
                        </td>
                        <td class="text-center " >
                            {{ item.cpfCnpjNavigation.cpfCnpj | cpfCnpj }}
                        </td>
                        <td class="text-center">
                            {{ item.cpfCnpjNavigation.razaoSocialNome  }}
                        </td>
                        <td class="text-center">
                            {{ item.valor | currency:'BRL' }}
                        </td>
                        <td class="text-center">
                            {{ item.saldoRestante | currency:'BRL' }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer"> 
                    <tr>
                        <td>Totais
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td>
                        </td>
                        <td class="text-end">
                          {{ totalValorVales | currency:'BRL' }}
                        </td>
                        <td class="text-end">
                          {{ totalSaldoDisponivelVales | currency:'BRL' }}
                        </td>
                    </tr>    
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5" *ngIf="filtroPesquisarVale == ''">Informe um CPF/CNPJ e/ou Nome/Nome Fantasia para pesquisar o Vale</td>
                    <td colspan="5" *ngIf="filtroPesquisarVale != '' && pesquisouVale">Nâo foi identificado Vales para o filtro informado</td>
                    <td colspan="5" *ngIf="filtroPesquisarVale != '' && !pesquisouVale">Clique em Pesquisar Vales para Consultar</td>
                </tr>
                </ng-template>
            </p-table>
        </div>
        
        <div class="row pt-2" *ngIf="!exibirQrCode">
            <div class="col-4 " *ngIf="exibeTaxas">
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Taxas</span>
                        <button class="btn" (click)="changeTaxaDespesa()">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showTaxaDespesa"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showTaxaDespesa"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showTaxaDespesa">
                        <li>
                                Despesa <strong>{{ condicaoPagamentoSelecionado.taxaDespesa | number:'1.2-5' }} %</strong>
                        </li>
                        <li>
                            % Juros <strong>{{ condicaoPagamentoSelecionado.percJuros | number:'1.2-5' }} %</strong>
                        </li>
                        <li>
                            % Desconto <strong>{{ condicaoPagamentoSelecionado.percDescontos | number:'1.2-5' }} %</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-4 " *ngIf="exibeInformacoesParcela">
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Parcelas</span>
                        <button class="btn" (click)="changeInformacoesParcela()">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showInformacoesParcela"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showInformacoesParcela"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showInformacoesParcela">
                        <li>
                                Intervalo de Dias <strong>{{ condicaoPagamentoSelecionado.intervaloDias | number:'1.2-5' }}</strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-4 " *ngIf="exibeDescontoVendedor">
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Desconto Vendedor</span>
                        <button class="btn" (click)="changeDescontoVendedor()">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showDescontoVendedor"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showDescontoVendedor"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showDescontoVendedor">
                        <li>
                                Percentual <strong>{{ condicaoPagamentoSelecionado.descontoMaxPercVendedor | number:'1.2-5' }} %</strong>
                        </li>
                        <li>
                            Valor <strong>{{ condicaoPagamentoSelecionado.descontoMaxValorVendedor | currency:'BRL' }}</strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        
    </div>
    <div class="app-modal-footer">
        <div style="display: FLEX;">
            <div class="grupo-btn-rodape" *ngIf="passo == 'pagamento'" >
                <button  (click)="cliqueCancelar()" type="button" 
                class="btn btn-secondary inline"><i class="feather icon-arrow-left"></i> Cancelar
                </button>&nbsp;
            </div>
            <!-- <div class="grupo-btn-rodape" *ngIf="passo == 'margem'">
                <button  (click)="voltarConsultarMargem()" type="button"
                class="btn btn-secondary inline"><i class="feather icon-arrow-left"></i> Cancelar
                </button>&nbsp;
            </div> -->
            <div class="grupo-btn-rodape" *ngIf="(condicaoPagamentoSelecionado.descontoMaxPercVendedor! > 0 || condicaoPagamentoSelecionado.descontoMaxValorVendedor! > 0) && passo == 'pagamento'">
                <button class="btn btn-warning  btn-md" (click)="exibirAplicarDesconto()"  >
                    <i class="far fa-user-tag"></i>&nbsp;Desconto
                </button>
            </div>
            <!-- <div class="grupo-btn-rodape" *ngIf="passo == 'pagamento'">
                <button class="btn btn-primary  btn-md" (click)="aplicarAcrescimo()"  >
                    <i class="far fa-user-tag"></i>&nbsp;Acréscimo
                </button>
            </div> -->
            <!-- <div class="grupo-btn-rodape " *ngIf="passo == 'desconto'">
                <button  (click)="consultarMargem()" type="button" 
                class="btn btn-danger inline"><i class="far fa-eye"></i> Margem
                </button>&nbsp;
            </div> -->
            <div class="grupo-btn-rodape" *ngIf="passo == 'desconto'" >
                <button type="button" (click)="aplicarDesconto()" 
                class="btn btn-success inline"><i class="far fa-dollar-sign"></i> Aplicar</button>&nbsp;
            </div>
            <div class="grupo-btn-rodape" *ngIf="passo == 'pagamento'" >
                <button type="button" (click)="cliqueConfirmarPagamento()" [disabled]="loading"
                class="btn btn-success inline"><i class="far fa-dollar-sign"></i> Pagar</button>&nbsp;
            </div>
        </div>
        
    </div>
</app-ui-modal>
<app-modal-crud-cheque
    (salvarParcelaCheque)="atualizarChequeNaParcela($event)"
></app-modal-crud-cheque>