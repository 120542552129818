import { CaixaControleCedulaModel } from "./caixaControleCedulaModel";

export class CaixaControleCedulaModelInstance {
    public static get(): CaixaControleCedulaModel {
        return {
            idCaixaControle: 0,
            quantidadeNota200: 0,
            quantidadeNota100: 0,
            quantidadeNota50: 0,
            quantidadeNota20: 0,
            quantidadeNota10: 0,
            quantidadeNota5: 0,
            quantidadeNota2: 0,
            quantidadeMoeda1Real: 0,
            quantidadeMoeda50Centavos: 0,
            quantidadeMoeda25Centavos: 0,
            quantidadeMoeda10Centavos: 0,
            quantidadeMoeda5Centavos: 0,
            quantidadeMoeda1Centavo: 0,
            valorTotalDinheiro: 0,
            valorTotalMoeda: 0,
            valorTotal: 0,
            dataRegistro: null,
            cpfRegistro: '',
        };
    }
}
