import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ContaSistemaModel } from 'src/app/models/controle/ContaSistema/contaSistemaModel';
import { ContaSistemaAplicacaoModel } from 'src/app/models/controle/ContaSistemaAplicacao/contaSistemaAplicacaoModel';
import { PessoaFisicaModel } from 'src/app/models/controle/PessoaFisica/pessoaFisicaModel';
import { PessoaJuridicaModel } from 'src/app/models/controle/PessoaJuridica/pessoaJuridicaModel';
import { ReturnMessage } from 'src/app/models/mensagens/returnMessage';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiContaSistemaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Controle;
    private controller = 'contaSistema';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    put(obj: ContaSistemaModel){
        return this.gc.put(this.api, `api/${this.controller}`, obj, true);
    }

    cadastrarPessoaJuridica(obj: PessoaJuridicaModel): Observable<PessoaJuridicaModel>{
        return this.gc.post(this.api, `api/${this.controller}/cadastrarPessoaJuridica`, obj, true);
    }

    cadastrarPessoaFisica(obj: PessoaFisicaModel){
        return this.gc.post(this.api, `api/${this.controller}/cadastrarPessoaFisica`, obj, true);
    }
    
    getContaSistemaByIdContaSistemaUsuarioLogado(exibeCarregando: boolean): Observable<ContaSistemaModel> {
        return this.gc.get(this.api, `api/${this.controller}/getContaSistemaByIdContaSistemaUsuarioLogado`, exibeCarregando);
    }

    getContaSistemaAplicacaoByIdContaSistemaUsuarioLogado(): Observable<ContaSistemaAplicacaoModel> {
        return this.gc.get(this.api, `api/${this.controller}/getContaSistemaAplicacaoByIdContaSistemaUsuarioLogado`, true);
    }

    getPessoasJuridicasByUsuarioLogado(carregando: boolean): Observable<PessoaJuridicaModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getPessoasJuridicasByUsuarioLogado`, carregando);
    }

    getPessoasFisicasByUsuarioLogado(carregando: boolean): Observable<PessoaFisicaModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getPessoasFisicasByUsuarioLogado`, carregando);
    }

    getPessoaJuridicaByCnpj(cnpj: string, exibeCarregando: boolean): Observable<PessoaJuridicaModel> {
        return this.gc.get(this.api, `api/${this.controller}/getPessoaJuridicaByCnpj/${cnpj}`, exibeCarregando);
    }

    getPessoaFisicaByCpf(cpf: string, exibirCarregando: boolean ): Observable<PessoaFisicaModel> {
        return this.gc.get(this.api, `api/${this.controller}/getPessoaFisicaByCpf/${cpf}`, exibirCarregando);
    }

    removerPessoaJuridicaByCnpj(cnpj: string){
        return this.gc.delete(this.api, `api/${this.controller}/removerPessoaJuridicaByCnpj/${cnpj}`, true);
    }

    removerPessoaFisicaByCpf(cpf: string){
        return this.gc.delete(this.api, `api/${this.controller}/removerPessoaFisicaByCpf/${cpf}`, true);
    }

    cadastrarContaDeExperienciaPasso1(obj: ContaSistemaModel): Observable<ContaSistemaModel>{
        return this.gc.post(this.api, `api/${this.controller}/cadastrarContaDeExperienciaPasso1`, obj, true);
    }

    cadastrarContaDeExperienciaPasso2(obj: ContaSistemaModel): Observable<ContaSistemaModel>{
        return this.gc.post(this.api, `api/${this.controller}/cadastrarContaDeExperienciaPasso2`, obj, true);
    }

    cadastrarContaDeExperienciaPasso3(obj: ContaSistemaModel): Observable<ReturnMessage>{
        return this.gc.post(this.api, `api/${this.controller}/cadastrarContaDeExperienciaPasso3`, obj, true);
    }
    
    importarLogoEmpresa(idContaSistema: string, fileLimit: number, files: File[]){
        const formData: FormData = new FormData();
        formData.append("idContaSistema", JSON.stringify(idContaSistema));
        for (let i = 0; i < files.length; i++) {
            formData.append("name", files[i], files[i].name);
        }
        return this.gc.postFile(this.api, `api/${this.controller}/importarLogoEmpresa`, formData, fileLimit, files, false);
    }
}