import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumStatus } from 'src/app/enum/enumStatus';
import { enumStatusEnvioNotaFiscal } from 'src/app/enum/enumStatusEnvioNotaFiscal';
import { enumTipoNotaFiscal } from 'src/app/enum/enumTipoNotaFiscal';
import { NotaFiscalModel } from 'src/app/models/cliente/notaFiscal/notaFiscalModel';
import { ParametroFiltroNotaFiscalModel } from 'src/app/models/cliente/NotaFiscal/ParametroFiltroNotaFiscalModel';
import { QuantidadeTotalNotaFiscalPorSituacaoModel } from 'src/app/models/cliente/NotaFiscal/QuantidadeTotalNotaFiscalPorSituacaoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiNotaFiscalConsultaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.NotaFiscal;
    private version = 'api/'
    private controller = 'notaFiscalConsulta';
    private mock = false;

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getByIdVenda(idVenda: number, tipoNotaFiscal: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getByIdVenda/${idVenda}/${tipoNotaFiscal}`, exibeCarregando);
    }

    getProdServByIdProdServ(idNotaFiscalProdServ: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getProdServByIdProdServ/${idNotaFiscalProdServ}`, exibeCarregando);
    }
    consultarEnviosDeCorrecaoDeNotas(idNotaFiscal: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarEnviosDeCorrecaoDeNotas/${idNotaFiscal}`, exibeCarregando);
    }
    consultarEnviosDeCancelamentoDeNotas(idNotaFiscal: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarEnviosDeCancelamentoDeNotas/${idNotaFiscal}`, exibeCarregando);
    }
    consultarAnexoNotaFiscal(idNotaFiscal: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarAnexoNotaFiscal/${idNotaFiscal}`, exibeCarregando);
    }
    consultarAnexoNotaFiscalByIdVenda(idNotaFiscal: number, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarAnexoNotaFiscalByIdVenda/${idNotaFiscal}/${tipoNotaFiscal}`, exibeCarregando);
    }
    downloadXmlOuPdf(idNotaFiscal: number, idExterno: string, tipoArquivo: string, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        return this.gc.getFile(this.api, `${this.version}${this.controller}/downloadXmlOuPdf/${idNotaFiscal}/${idExterno}/${tipoArquivo}/${tipoNotaFiscal}`, exibeCarregando);
    }
    downloadXmlOuPdfByIdVenda(idVenda: number, tipoArquivo: string, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        return this.gc.getFile(this.api, `${this.version}${this.controller}/downloadXmlOuPdfByIdVenda/${idVenda}/${tipoArquivo}/${tipoNotaFiscal}`, exibeCarregando);
    }
    downloadXmlCancelamento(idExterno: string, tipoArquivo: string, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        return this.gc.getFile(this.api, `${this.version}${this.controller}/downloadXmlCancelamento/${idExterno}/${tipoArquivo}/${tipoNotaFiscal}`, exibeCarregando);
    }
    downloadXmlOuPdfCorrecao(idExterno: string, tipoArquivo: string, exibeCarregando: boolean) {
        return this.gc.getFile(this.api, `${this.version}${this.controller}/downloadXmlOuPdfCorrecao/${idExterno}/${tipoArquivo}`, exibeCarregando);
    }
    getAllItensByIdNotaFiscal(idNotaFiscal: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllItensByIdNotaFiscal/${idNotaFiscal}`, exibeCarregando);
    }
    getAllByFiltro(parametros: ParametroFiltroNotaFiscalModel, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/getAllByFiltro/${tipoNotaFiscal}`, parametros, exibeCarregando);
    }
    visualizarLogEnvio(idExterno: string, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/visualizarLogEnvio/${idExterno}/${tipoNotaFiscal}`, exibeCarregando);
    }
    verificarStatusEnvio(idExterno: string, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/verificarStatusEnvio/${idExterno}/${tipoNotaFiscal}`, exibeCarregando);
    }
    getQuantidadeTotalNotaFiscalPorSituacao(tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean): Observable<QuantidadeTotalNotaFiscalPorSituacaoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getQuantidadeTotalNotaFiscalPorSituacao/${tipoNotaFiscal}`, exibeCarregando);
    }
    getQuantidadeTotalNotaFiscalPorStatus(tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean): Observable<QuantidadeTotalNotaFiscalPorSituacaoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getQuantidadeTotalNotaFiscalPorStatus/${tipoNotaFiscal}`, exibeCarregando);
    }
    getAllByStatusEnvio(tipoNotaFiscal: enumTipoNotaFiscal, status: enumStatusEnvioNotaFiscal, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatusEnvio/${tipoNotaFiscal}/${status}`, exibeCarregando);
    }   
    getAllByStatus(tipoNotaFiscal: enumTipoNotaFiscal, status: enumStatus, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatus/${tipoNotaFiscal}/${status}`, exibeCarregando);
    }   
    getById(id: number, exibeCarregando: boolean): Observable<NotaFiscalModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getById/${id}`, exibeCarregando);
    }
    getNotaFiscalByIdVendaParaCupomFiscal(idVenda: number, exibeCarregando: boolean): Observable<NotaFiscalModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getNotaFiscalByIdVendaParaCupomFiscal/${idVenda}`, exibeCarregando);
    }
    consultarResumo(id: number, tipoNotaFiscal: enumTipoNotaFiscal, exibeCarregando: boolean) {
        // if (this.mock){
        //   return this.http.get<any>('assets/showcase/data/Nfe/consultarResumo.json');
        // }
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarResumo/${id}/${tipoNotaFiscal}`, exibeCarregando);
    }
}