import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumStatus } from 'src/app/enum/enumStatus';
import { ConsultaPrecoModel } from 'src/app/models/cliente/preco/ConsultaPrecoModel';
import { ParametrosParaCadastrarAlterarPrecoModel } from 'src/app/models/cliente/preco/ParametrosParaCadastrarAlterarPrecoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiPrecoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'preco';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAll() {
        return this.gc.get(this.api, `${this.version}${this.controller}`, true);
    }

    getAllByIdProdutoUnico(idProdutoUnico: number, quantidadeRegistro: number) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdProdutoUnico/${idProdutoUnico}/quantidadeRegistro/${quantidadeRegistro}`, true);
    }

    getAllByIdProdutoUnicoETabelaPreco(idProdutoUnico: number, idTabelaPreco: number, quantidadeRegistro: number) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdProdutoUnicoETabelaPreco/${idProdutoUnico}/quantidadeRegistro/${quantidadeRegistro}/idTabelaPreco/${idTabelaPreco}`, true);
    }

    getPrecoByEanOuNomeProduto(idTabelaPreco: number, filtro: string, tipo: string, status: enumStatus): Observable<ConsultaPrecoModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getPrecoByEanOuNomeProduto?idTabelaPreco=${idTabelaPreco}&filtro=${filtro}&status=${status}&tipo=${tipo}`, true);
    }

    // getByIdProdutoUnico(idProdutoUnico: number, carregando: boolean){
    //     return this.gc.get(this.api, `${this.version}${this.controller}/getByIdProdutoUnico/${idProdutoUnico}`, carregando);
    // }

    getByIdProdutoUnicoETabelaPreco(idProdutoUnico: number, idTabelaPreco: number, carregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/getByIdProdutoUnicoETabelaPreco/${idProdutoUnico}/${idTabelaPreco}`, carregando);
    }

    get(id: number){
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
    }

    post(obj: ParametrosParaCadastrarAlterarPrecoModel){
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj);
    }
    // post(obj: PrecoModel, replicarAjusteNasLojas: boolean){
    //     return this.gc.post(this.api, `${this.version}${this.controller}/${replicarAjusteNasLojas}`, obj);
    // }

    // put(obj: PrecoModel, replicarAjusteNasLojas: boolean){
    //     return this.gc.put(this.api, `${this.version}${this.controller}/${replicarAjusteNasLojas}`, obj);
    // }
    put(obj: ParametrosParaCadastrarAlterarPrecoModel){
        return this.gc.put(this.api, `${this.version}${this.controller}`, obj);
    }

    delete(id: number){
        return this.gc.delete(this.api, `${this.version}${this.controller}/${id}`);
    }
}