import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumTabelaStatusPedidoVenda } from 'src/app/enum/enumTabelaStatusPedidoVenda';
import { CustomFinalizarPedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/CustomFinalizarPedidoVendumModel';
import { FiltroPesquisaPedidoVendaModel } from 'src/app/models/cliente/PedidoVenda/FiltroPesquisaPedidoVendaModel';
import { ParametrosConsultaPedidoVendaModel } from 'src/app/models/cliente/PedidoVenda/ParametrosConsultaPedidoVendaModel';
import { PedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/PedidoVendumModel';
import { PedidoVendaProdutoModel } from 'src/app/models/cliente/PedidoVendaProduto/pedidovendaProdutoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class ApiPedidoVendaService {

  private gc: CommunicationManager;
  private api = AppSettings.API.Pdv;
  private version = 'api/'
  private controller = 'pedidoVenda';

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
  }

  getAllByStatus(status: enumTabelaStatusPedidoVenda, exibeCarregando: boolean | false): Observable<PedidoVendumModel[]> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatus/${status}`, exibeCarregando);
  }
  getAllByFiltroEData(filtro: FiltroPesquisaPedidoVendaModel, exibeCarregando: boolean | false): Observable<PedidoVendumModel[]> {
    return this.gc.post(this.api, `${this.version}${this.controller}/getAllByFiltroEData`, filtro, exibeCarregando);
  }

  getById(idPedidoVenda, exibeCarregando: boolean | false): Observable<PedidoVendumModel> {
    return this.gc.get(this.api, `${this.version}${this.controller}/${idPedidoVenda}`, exibeCarregando);
  }

  post(inputDados: PedidoVendumModel, atualizarItens: boolean, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/salvarPedidoVenda?atualizarItens=${atualizarItens}`, inputDados, exibeCarregando);
  }

  removerItemPedidoVendaProduto(inputDados: PedidoVendaProdutoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/removerItemPedidoVendaProduto`, inputDados, exibeCarregando);
  }

  removerPedidoVenda(inputDados: PedidoVendumModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/removerPedidoVenda`, inputDados, exibeCarregando);
  }

  finalizarPedidoVenda(parametrosSalvarPedidoVenda: CustomFinalizarPedidoVendumModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/finalizarPedidoVenda`, parametrosSalvarPedidoVenda, exibeCarregando);
  }

  cancelarPedidoVenda(inputDados: PedidoVendumModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/cancelarPedidoVenda`, inputDados, exibeCarregando);
  }

  consultarPedidosVendaByIdVenda(idVenda: number, exibeCarregando: boolean | false): Observable<PedidoVendumModel[]> {
    return this.gc.get(this.api, `${this.version}${this.controller}/consultarPedidosVendaByIdVenda/${idVenda}`, exibeCarregando);
  }

  getAllPedidosByStatusParaPdv(status: enumTabelaStatusPedidoVenda, exibeCarregando: boolean | false): Observable<ParametrosConsultaPedidoVendaModel[]> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllPedidosByStatusParaPdv/${status}`, exibeCarregando);
  }
}