export enum enumStatus {
    Ativo = 'A',
    Inativo = 'I',
    Desativado = 'D',
    Pendente = 'P',
    Erro = 'E',
    Cancelado = 'C',
    Entregue = 'F',
    Todos = 'T'
}
