import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../theme/shared/auth/auth.service';
import { environment } from 'src/environments/environment';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

export class CommunicationManager {

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) { }

  private baseUrl: any = environment.apiSettings.baseUrl;

  private contadorEstaCarregando: number = 0;
  // private idCarregandoGlobal: string = '';

  private exibirMensagemLock(exibirCarregando: boolean = true) {
    if (exibirCarregando) {
      this.contadorEstaCarregando = this.contadorEstaCarregando + 1;
      if (this.contadorEstaCarregando === 1) {
        this.mensagemService.exibirLock();
      }
    }
  }

  private esconderMensagemLock(exibirCarregando?: boolean | true) {
    if (exibirCarregando) {
      this.contadorEstaCarregando = this.contadorEstaCarregando - 1;
      if (this.contadorEstaCarregando === 0) {
        this.mensagemService.esconderLock();
      }
    }
  }

  postFile(servico: string, metodo: string, formData: FormData, fileLimit: number, files: File[], exibirCarregando?: boolean | true) {

    if (exibirCarregando) {
      this.exibirMensagemLock(exibirCarregando);
    }

     const header = new HttpHeaders();
    //   .set('Authorization', `Bearer ${this.auth.Token}`);
    return this.http['post'](this.baseUrl[servico] + '/' + metodo, formData, {
      headers: header, reportProgress: true, observe: 'events', withCredentials: false
    });

    // return this.http
    //   .post(this.baseUrl[servico] + '/' + metodo, obj, {
    //     headers: header
    //   })
    //   .pipe(
    //     map((data: any) => {
    //       this.esconderMensagemLock();
    //       return data;
    //     }),
    //     catchError(error => {
    //       this.esconderMensagemLock();
    //       return throwError(error);
    //     })
    //   );
  }



 

  postDownload(servico: string, metodo: string, obj: any, exibirCarregando: boolean = true) {
    this.exibirMensagemLock(exibirCarregando);

    // const idCarregando = uuid.v4();
    // this.idCarregandoGlobal = idCarregando;

    const header = new HttpHeaders({
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
      .set('responseType', 'blob');
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    return this.http
      .post(this.baseUrl[servico] + '/' + metodo, JSON.stringify(obj), {
        headers: header
      })
      .pipe(
        map((data: any) => {
          if (exibirCarregando) {
            this.contadorEstaCarregando = this.contadorEstaCarregando - 1;
            this.esconderMensagemLock(exibirCarregando);
          }
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  postComToken(servico: string, metodo: string, obj: any, token: string, exibirCarregando: boolean = true) {
    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Authorization', `Bearer ${token}`);

    return this.http
      .post(this.baseUrl[servico] + '/' + metodo, JSON.stringify(obj), {
        headers: header
      })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  post(servico: string, metodo: string, obj: any, exibirCarregando: boolean = true) {
    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    return this.http
      .post(this.baseUrl[servico] + '/' + metodo, JSON.stringify(obj), {
        headers: header
      })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  put(servico: string, metodo: string, obj: any, exibirCarregando: boolean = true) {
    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http
      .put(this.baseUrl[servico] + '/' + metodo, JSON.stringify(obj), {
        headers: header
      })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        })
        ,
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }



  getFile(servico: string, metodo: string, exibirCarregando: boolean = true) {

    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders();
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    let retorno = this.http
      .get(this.baseUrl[servico] + '/' + encodeURI(metodo), { headers: header, responseType: 'blob' as 'blob' });

    this.esconderMensagemLock(exibirCarregando);
    return retorno;
  }

  get(servico: string, metodo: string, exibirCarregando: boolean = false) {

    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    return this.http
      .get(this.baseUrl[servico] + '/' + encodeURI(metodo), { headers: header })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  patch(servico: string, metodo: string, obj: any, exibirCarregando: boolean = true) {

    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    return this.http
      .patch(this.baseUrl[servico] + '/' + metodo, obj, { headers: header })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  delete(servico: string, metodo: string, exibirCarregando: boolean = true) {
    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    return this.http
      .delete(this.baseUrl[servico] + '/' + metodo, { headers: header })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  request(servico: string, url: string, metodo: string, body: any, exibirCarregando: boolean = true) {
    this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    return this.http
      .request(metodo, this.baseUrl[servico] + '/' + url, {
        body: body,
        headers: header
      })
      .pipe(
        map((data: any) => {
          this.esconderMensagemLock(exibirCarregando);
          return data;
        }),
        catchError(error => {
          this.esconderMensagemLock(exibirCarregando);
          return throwError(error);
        })
      );
  }

  getFileByPost(servico: string, metodo: string, param: any, exibirCarregando: boolean = true) {

    //this.exibirMensagemLock(exibirCarregando);

    const header = new HttpHeaders();
      //.set('Authorization', `Bearer ${this.auth.Token}`);

    let retorno = this.http
      .post(this.baseUrl[servico] + '/' + encodeURI(metodo), param,{ headers: header, responseType: 'blob' as 'blob' });
    
    //this.esconderMensagemLock(exibirCarregando);
    return retorno;
  }

}
