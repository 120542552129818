import { NotaFiscalTotalIssqnModel } from "./NotaFiscalTotalIssqnModel";

export class NotaFiscalTotalIssqnModelInstance {
    public static get(): NotaFiscalTotalIssqnModel {
        return {
            idNotaFiscal: 0,
            valorTotalServico: 0,
            valorTotalBcIss: 0,
            valorTotalIss: 0,
            valorPis: 0,
            valorCofins: 0,
            dataPrestacaoServico: null,
            valorDeducaoReducaoBc: 0,
            valorTotalOutrasRetencoes: 0,
            valorTotalDescIncondicionado: 0,
            valorTotalDescCondicionado: 0,
            valorTotalRetencaoIss: 0,
            codigoRegimeEspecialTributacao: 0,
            idNotaFiscalNavigation: null!
        };
    }
    public static getArray(): NotaFiscalTotalIssqnModel[] {
        return [this.get()];
    }
}
