import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { TabelaBancoFinanceiroModel } from 'src/app/models/dominios/TabelaBancoFinanceiro/tabelaBancoFinanceiroModel';
import { ApiTabelaBancoFinanceiroService } from 'src/app/services/dominios/api-tabela-banco-financeiro.service';

@Component({
  selector: 'app-combo-banco-financeiro',
  templateUrl: './combo-banco-financeiro.component.html',
  styleUrls: ['./combo-banco-financeiro.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboBancoFinanceiroComponent),
    multi: true
  }]
})
export class ComboBancoFinanceiroComponent implements OnInit {

  @Input() disabled: boolean = false;
  @Input() label: string = "Banco";
  @Output() change: EventEmitter<string> = new EventEmitter();
  val: number = 0 // this is the updated value that the class accesses

  subService: Subscription | null = null;
  itens: Array<IOption> = [];
  @Input() idCampo: string = 'idTabelaBancoFinanceiro';

  constructor(private apiBancoFinanceiro: ApiTabelaBancoFinanceiroService) { }

  ngOnInit(): void {
    this.getItens();
  }

  changeValue() {
    this.change.emit(this.value);
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }

  loading: boolean = false;
  getItens(): void {
    this.loading = true;
    this.subService = this.apiBancoFinanceiro.getAll().subscribe(
      (retorno: Array<TabelaBancoFinanceiroModel>) => {
        this.itens = retorno.map((objeto) => {
          return {
            value: objeto.idTabelaBancoFinanceiro?.toString(),
            label: `${objeto.idTabelaBancoFinanceiro?.toString()} - ${objeto.banco}`
          }
        });
        this.itens.splice(0, 0, this.optionDefault());
        this.loading = false;
      });
  }

  dadosCombo(): Array<IOption> {
    return this.itens;
  }

  private optionDefault() {
    return {
      value: "",
      label: "Selecione"
    };
  }

  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj?.toString();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }
}