import swal from 'sweetalert2';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { CaixaControleModelInstance } from "src/app/models/cliente/CaixaControle/CaixaControleModelInstance";
import { ApiCaixaService } from 'src/app/services/pdv/api-caixa.service';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ConstantsService, ParametrizacaoPdvModel } from '../../constants/constants.service';
import { ErrorResponseCustom } from 'src/app/models/error-response';
import { EnumTypeError } from 'src/app/enum/enumTypeError';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { VendaPorMeioPagamentoModel } from 'src/app/models/cliente/Caixa/VendaPorMeioPagamentoModel';
import { DescricaoMeioPagamento, enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';
import { AbrirFecharCaixaModelInstance } from 'src/app/models/cliente/CaixaControle/AbrirFecharCaixaModel';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { InputNumber } from 'primeng/inputnumber';
import { PdvModel } from 'src/app/models/cliente/Pdv/pdvModel';
import { ComboPdvComponent } from '../combo-pdv/combo-pdv.component';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { Router } from '@angular/router';
import { PdvComponent } from 'src/app/theme/layout/pdv/pdv.component';
import { CaixaModel } from 'src/app/models/cliente/Caixa/caixaModel';
import { PessoaJuridicaModel } from 'src/app/models/controle/PessoaJuridica/pessoaJuridicaModel';
import { ApiContaSistemaService } from 'src/app/services/controle/api-conta-sistema.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { ApiProdutoService } from 'src/app/services/cliente/api-produto.service';
import { ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { CaixaControleCedulaModelInstance } from 'src/app/models/cliente/CaixaControle/caixaControleCedulaModelInstance';
import { CaixaControleCedulaModel } from 'src/app/models/cliente/CaixaControle/caixaControleCedulaModel';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';

@Component({
  selector: 'app-modal-abrir-fechar-caixa',
  templateUrl: './modal-abrir-fechar-caixa.component.html',
  styleUrls: ['./modal-abrir-fechar-caixa.component.scss']
})
export class ModalAbrirFecharCaixaComponent implements OnInit {
  @ViewChild('comboPdvComponent') public comboPdvComponent!: ComboPdvComponent;
  @ViewChild(UiModalComponent) modalCaixa;
  inputDados: CaixaControleModel = null!;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() abriuFechouCaixa: EventEmitter<CaixaControleModel> = new EventEmitter<CaixaControleModel>();

  tituloModal: string = '';
  descricaoModal: string = '';

  subsConsultar: Subscription = null!
  valorSangriaFinal: number | null = null;

  constructor(
    private auth: AuthService,
    private apiProdutoService: ApiProdutoService,
    private readonly mensagemService: AppMensagemService,
    private contaSistemaService: ApiContaSistemaService,
    private router: Router,
    private showMessageError: ShowMessageError,
    private apiCaixaService: ApiCaixaService,
    private apiPdvService: ApiPdvService
    ) { }

  ngOnInit(): void {
    this.setStartObject();
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  @ViewChild('elmTrocoInicial') elmTrocoInicial: InputNumber  = null!;
  onFocusTrocoInicial(){
    setTimeout(()=>{
      if (this.elmTrocoInicial != null){
        this.elmTrocoInicial.input.nativeElement.focus();
      }
    },300);
  }

  @ViewChild('elmTrocoFinal') elmTrocoFinal: InputNumber  = null!;
  onFocusTrocoFinal(){
    setTimeout(()=>{
      if (this.elmTrocoFinal != null){
        this.elmTrocoFinal.input.nativeElement.focus();
      }
    },300);
  }

  cliqueCancelar(){
    this.abriuFechouCaixa.emit(null!);
    this.fecharModal();
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode == 43 || event.keyCode == 27) {
      this.cliqueCancelar();
    }
  }

  @ViewChild('elmQuantidadeNota2') elmQuantidadeNota2: InputNumber  = null!;
  onFocusQuantidadeNota2(){
    setTimeout(()=>{
      if (this.elmQuantidadeNota2 != null){
        this.elmQuantidadeNota2.input.nativeElement.focus();
      }
    },300);
  }
  // (onKeyDown)="onKeyDown($event)"

  tipoAcaoAbrirFecharCaixa: enumAcaoAbrirFecharCaixa = null!;
  tipoAcaoAbrirCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Abrir;
  tipoAcaoFecharCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Fechar;
  tipoAcaoCedula: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Cedula;
  idCaixa: number = 0;
  idPdv: number = 0;
  obrigaFecharCaixa: boolean = false;
  public abrirModal(acao: enumAcaoAbrirFecharCaixa, obrigaFecharCaixa: boolean) {
    this.inputCedula = CaixaControleCedulaModelInstance.get();
    this.obrigaFecharCaixa = obrigaFecharCaixa;
    this.inputDados.idCaixa = ConstantsService.IdCaixa;
    this.idCaixa = ConstantsService.IdCaixa;
    this.tipoAcaoAbrirFecharCaixa = acao;
    this.setStartObject();
    this.modalCaixa.show();

    if (acao == enumAcaoAbrirFecharCaixa.Abrir){
      this.tituloModal = 'Abrir Caixa';
      this.vendasPorMeioPagamento = [];
      this.onFocusTrocoInicial();
      this.descricaoModal = 'Informe o troco inicial para a abertura do caixa';
    }
    else if (acao == enumAcaoAbrirFecharCaixa.Fechar){
      this.tituloModal = 'Fechar Caixa';
      this.onFocusTrocoFinal();
      if (obrigaFecharCaixa){
        this.descricaoModal = 'Por favor, feche o caixa de dia anterior e abra em seguida antes de continuar as vendas';
      }else{
        this.descricaoModal = 'Informe o troco final para o fechamento do caixa';
      }
      this.listarVendasPorMeioPagamento();

    }
    PdvComponent.modalAtiva = true;
  }

  valorTotalTrocoCalculado: number = 0;

  getFormatDecimal(number: number): string{
    if (number == null) return 'R$ 0,00';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  pdvSelecionado: PdvModel = null!;
  changePdv(pdv: PdvModel){
    this.pdvSelecionado = pdv;
  }

  valorTotalLiquido: number = 0;
  subGetCaixaControleByIdCaixaControle: Subscription = null!;
  getCaixaControle() {
    this.subGetCaixaControleByIdCaixaControle = this.apiCaixaService.getCaixaControleByIdCaixaControle(ConstantsService.IdCaixaControle, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.subGetCaixaControleByIdCaixaControle?.unsubscribe();
        if (retorno != null){
          this.inputDados = retorno;
          //jander verificar se precisa dos valores dos produtos
          this.valorTotalLiquido = this.inputDados.valorTotalVendaBruta! - this.inputDados.valorDescontoCondicaoPagamento! - this.inputDados.valorDescontoVendedor! 
            + this.inputDados.valorDespesaCondicaoPagamento! + this.inputDados.valorJurosCondicaoPagamento!;
          let valorTotalDinheiroPorMeioPagamento = this.vendasPorMeioPagamento.filter(f => f.idTabelaMeioPagamento == enumMeioPagamento.Dinheiro).reduce((sum, current) => sum + current.valor!, 0);
          this.valorTotalTrocoCalculado = valorTotalDinheiroPorMeioPagamento + this.inputDados.valorTotalEntrada! + this.inputDados.valorTotalSuprimento! - this.inputDados.valorTotalSangrias!;
        }
      },
        (err) => {
          this.subGetCaixaControleByIdCaixaControle?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  vendasPorMeioPagamento: VendaPorMeioPagamentoModel[] = [];
  subsListarVendasPorMeioPagamento: Subscription = null!
  loadingListarVendaPorMeioPagamento: boolean = false;
  listarVendasPorMeioPagamento() {
    this.loadingListarVendaPorMeioPagamento = true;
    this.subsListarVendasPorMeioPagamento = this.apiCaixaService.listarVendasPorMeioPagamento(ConstantsService.IdCaixaControle, true)
      .subscribe( (retorno: VendaPorMeioPagamentoModel[]) => {
        this.loadingListarVendaPorMeioPagamento = false;
        this.subsListarVendasPorMeioPagamento?.unsubscribe();
        this.vendasPorMeioPagamento = retorno;
        this.getCaixaControle();
      },
        (err) => {
          this.subsListarVendasPorMeioPagamento?.unsubscribe();
          this.loadingListarVendaPorMeioPagamento = false;
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  getDescricaoMeioPagamento(idTabelaMeioPagamento: string){
    return DescricaoMeioPagamento.get(idTabelaMeioPagamento as enumMeioPagamento);
  }
  subsAbrirCaixa: Subscription = null!;

  abrirFecharCaixa = AbrirFecharCaixaModelInstance.get();
  protected abrirCaixa(){
    if (this.idCaixa == 0){
      if (this.pdvSelecionado != null){
        this.abrirFecharCaixa.idPdv = this.pdvSelecionado.idPdv;
        this.abrirFecharCaixa.idCaixa = this.pdvSelecionado.idCaixa;
      }
    }else{
      this.abrirFecharCaixa.idPdv = ConstantsService.IdPdv;
      this.abrirFecharCaixa.idCaixa = ConstantsService.IdCaixa;
    }
    this.abrirFecharCaixa.valorTotalEntrada = this.inputDados.valorTotalEntrada;

    this.subsAbrirCaixa = this.apiCaixaService.abrirCaixa(this.abrirFecharCaixa, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.subsAbrirCaixa?.unsubscribe();
        swal.fire({
          title: "Caixa Aberto!",
          text: 'Se houver outras abas aberto no PDV, atualize a página para identificar o Caixa',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
              if (this.pdvSelecionado != null){
                ConstantsService.CodigoPdv = this.pdvSelecionado.codigo;
                ConstantsService.IdPdv = this.pdvSelecionado.idPdv;
                ConstantsService.IdCaixa = this.pdvSelecionado.idCaixa!;
              }
              ConstantsService.SituacaoCaixa = retorno.situacao;
              ConstantsService.DescricaoTurno = retorno.idCaixaNavigation.descricaoTurno;
              ConstantsService.DataAberturaCaixa = retorno.dataHoraEntrada!;
              ConstantsService.IdCaixaControle = retorno.idCaixaControle;
              let parametrizacaoPdv = ConstantsService.ParametrizacaoPdv;
              this.inputDados = retorno;
              parametrizacaoPdv.permiteCaixaNegativo = (this.inputDados.idCaixaNavigation.permiteCaixaNegativo == enumSimNao.Sim)?true:false;
              parametrizacaoPdv.permiteMultiUsuario = (this.inputDados.idCaixaNavigation.permiteMultiUsuario == enumSimNao.Sim)?true:false;
              parametrizacaoPdv.obrigaFecharCaixaDiario = (this.inputDados.idCaixaNavigation.obrigaFechaCaixaDiario == enumSimNao.Sim)?true:false;
              parametrizacaoPdv.idContaBancaria = this.inputDados.idCaixaNavigation.idContaBancaria!;
              ConstantsService.ParametrizacaoPdv = parametrizacaoPdv;
              this.abriuFechouCaixa.emit(this.inputDados);
              this.fecharModal();
          }
        });
      },
        (err) => {

          this.subsAbrirCaixa?.unsubscribe();
          if (err.error !== null && Object.prototype.hasOwnProperty.call(err.error, 'message')) {
            const erroServidor: ErrorResponseCustom = err.error;
            if (erroServidor.typeMessage == EnumTypeError.Validation) {
                let title = erroServidor.message;
                if(erroServidor.messages.findIndex(f => f.message == 'Já existe um caixa aberto para este pdv') > -1){
                  ConstantsService.IdPdv = this.pdvSelecionado.idPdv;
                  ConstantsService.CodigoPdv = this.pdvSelecionado.codigo;
                  ConstantsService.IdCaixa = parseInt(erroServidor.messages.find(f => f.field == 'IdCaixa')?.message!);
                  swal.fire({title: "Atenção",text: 'Já existe um caixa aberto para este pdv',icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
                    if (result.isConfirmed) {
                        this.selecionarCaixaJaAberto(ConstantsService.IdCaixa);
                    }});
                }else{
                  this.showMessageError.exibirMensagemErro(err);
                }
            }
          }else{
            this.showMessageError.exibirMensagemErro(err);
          }
        });
  }

  onFoucus(event){
    console.log(event);
    setTimeout(()=>{ 
      event.target.select();
    },200);  
    
  }


  subsFecharCaixa: Subscription = null!;
  protected fecharCaixa(){

    this.abrirFecharCaixa.idCaixaControle = this.inputDados.idCaixaControle;
    this.abrirFecharCaixa.idCaixa = this.idCaixa;
    this.abrirFecharCaixa.valorTotalSaida = this.inputDados.valorTotalSaida;
    this.abrirFecharCaixa.valorSangriaFinal = this.valorSangriaFinal;
    this.abrirFecharCaixa.idPdv = ConstantsService.IdPdv;

    this.subsFecharCaixa = this.apiCaixaService.fecharCaixa(this.abrirFecharCaixa, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.subsFecharCaixa?.unsubscribe();
        this.salvarCedula();
        swal.fire({
          title: "Sucesso",
          html: 'Caixa Fechado com Sucesso!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
              ConstantsService.SituacaoCaixa = retorno.situacao;
              ConstantsService.DataAberturaCaixa = retorno.dataHoraEntrada!;
              ConstantsService.IdCaixaControle = retorno.idCaixaControle;
              ConstantsService.IdCaixa = retorno.idCaixa!;
              this.inputDados = retorno;

              if (this.obrigaFecharCaixa){
                this.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
              }else{
                this.abriuFechouCaixa.emit(this.inputDados);
                this.fecharModal();
              }
          }
        });
      },
        (err) => {

          this.subsFecharCaixa?.unsubscribe();

          if (err.error != null && err.error.typeMessage == EnumTypeError.Validation) {
            const errors = err.error.messages;
            let titulo = err.error.message;
            let mensagem = this.showMessageError.getMessage(errors);
            if (mensagem.includes('Este caixa ja foi fechado!')){
              this.showMessageError.exibirMensagemErro(err);
              ConstantsService.SituacaoCaixa = enumSituacaoCaixa.Fechado;
              ConstantsService.DataAberturaCaixa = new Date();
              ConstantsService.IdCaixaControle = 0;
              let caixa: CaixaControleModel = CaixaControleModelInstance.get();
              caixa.situacao = enumSituacaoCaixa.Fechado;
              caixa.dataHoraEntrada = ConstantsService.DataAberturaCaixa;
              this.abriuFechouCaixa.emit(caixa);
              swal.fire({
                title: "Atenção",
                html: mensagem,
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: 'Ok'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.fecharModal();
                }});
            }else{
              this.showMessageError.exibirMensagemErro(err);
            }
          }else{
            this.showMessageError.exibirMensagemErro(err);
          }


        });
  }
  navegarPaginaProdutos(){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/pdv/produto-pagamento'])
    );
    window.open(url, '_self');
  }

  subsSelecionarCaixa: Subscription = null!;
  selecionarCaixaJaAberto(idCaixa: number){
    this.subsSelecionarCaixa = this.apiCaixaService.getCaixaControleAbertoByIdCaixa(idCaixa, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.subsSelecionarCaixa?.unsubscribe();
        if (retorno != null){
          ConstantsService.SituacaoCaixa = retorno.situacao;
          ConstantsService.DataAberturaCaixa = retorno.dataHoraEntrada!;
          ConstantsService.IdCaixaControle = retorno.idCaixaControle;
          ConstantsService.DescricaoTurno = retorno.idCaixaNavigation.descricaoTurno;
          this.inputDados = retorno;

          this.definirParametrizacaoPdv(retorno.idCaixaNavigation);


        }
      },
      (err) => {
        this.subsSelecionarCaixa?.unsubscribe();
        this.showMessageError.exibirMensagemErro(err);
      });
  }

  definirParametrizacaoPdv(caixaSelecionado: CaixaModel) {

    if (this.pdvSelecionado.idParametrizacaoVendaNavigation == null){
      swal.fire({
        title: "Erro",
        html: 'Não existe parametrização de Venda para PDV, cadastre antes de continuar',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
            return;
        }
      });
    }
    else{
      let parametrizacaoPdv: ParametrizacaoPdvModel = null!;

      if (caixaSelecionado != null){
        parametrizacaoPdv = {
          habilitaNfce: (this.pdvSelecionado.habilitaNfce == enumSimNao.Sim)?true:false,
          habilitaNfe: (this.pdvSelecionado.habilitaNfe == enumSimNao.Sim)?true:false,
          habilitaNfse: (this.pdvSelecionado.habilitaNfse == enumSimNao.Sim)?true:false,
          dataUltAbertura: caixaSelecionado.dataUltAbertura!,
          geraNfceAutomaticamente: (this.pdvSelecionado.idParametrizacaoVendaNavigation.geraNfce == enumSimNao.Sim)?true:false,
          geraNfeAutomaticamente: (this.pdvSelecionado.idParametrizacaoVendaNavigation.geraNfe == enumSimNao.Sim)?true:false,
          impressaoDireta: (this.pdvSelecionado.impressaoDireta == enumSimNao.Sim)?true:false,
          impressaoPadrao: (this.pdvSelecionado.impressaoPadrao == enumSimNao.Sim)?true:false,
          serieDocumentoFiscalNfse: (this.pdvSelecionado.idParametrizacaoNfseNavigation != null)?this.pdvSelecionado.idParametrizacaoNfseNavigation.serieDocumentoFiscal:'',
          serieDocumentoFiscalNfce: (this.pdvSelecionado.idParametrizacaoNfceNavigation != null)?this.pdvSelecionado.idParametrizacaoNfceNavigation?.serieDocumentoFiscal:0,
          serieDocumentoFiscalNfe:(this.pdvSelecionado.idParametrizacaoNfeNavigation != null)?this.pdvSelecionado.idParametrizacaoNfeNavigation?.serieDocumentoFiscal:0,
          permiteCaixaNegativo: (caixaSelecionado.permiteCaixaNegativo == enumSimNao.Sim)?true:false,
          permiteMultiUsuario: (caixaSelecionado.permiteMultiUsuario == enumSimNao.Sim)?true:false,
          obrigaFecharCaixaDiario: (caixaSelecionado.obrigaFechaCaixaDiario == enumSimNao.Sim)?true:false,
          idContaBancaria: caixaSelecionado.idContaBancaria!,
          permiteEntrega: (this.pdvSelecionado.idParametrizacaoVendaNavigation.permiteEntrega == enumSimNao.Sim)?true:false,
          transmitirNfAutomaticamente: (this.pdvSelecionado.transmissaoAutomaticaNf == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
          permiteAlterarPrecoVenda: (this.pdvSelecionado.idParametrizacaoVendaNavigation.permiteAlterarPrecoVenda == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
        }
      }else{
        parametrizacaoPdv = {
          habilitaNfce: (this.pdvSelecionado.habilitaNfce == enumSimNao.Sim)?true:false,
          habilitaNfe: (this.pdvSelecionado.habilitaNfe == enumSimNao.Sim)?true:false,
          habilitaNfse: (this.pdvSelecionado.habilitaNfse == enumSimNao.Sim)?true:false,
          dataUltAbertura: null!,
          geraNfceAutomaticamente: (this.pdvSelecionado.idParametrizacaoVendaNavigation.geraNfce == enumSimNao.Sim)?true:false,
          geraNfeAutomaticamente: (this.pdvSelecionado.idParametrizacaoVendaNavigation.geraNfe == enumSimNao.Sim)?true:false,
          impressaoDireta: (this.pdvSelecionado.impressaoDireta == enumSimNao.Sim)?true:false,
          impressaoPadrao: (this.pdvSelecionado.impressaoPadrao == enumSimNao.Sim)?true:false,
          serieDocumentoFiscalNfse: (this.pdvSelecionado.idParametrizacaoNfseNavigation != null)?this.pdvSelecionado.idParametrizacaoNfseNavigation.serieDocumentoFiscal:'',
          serieDocumentoFiscalNfce: (this.pdvSelecionado.idParametrizacaoNfceNavigation != null)?this.pdvSelecionado.idParametrizacaoNfceNavigation?.serieDocumentoFiscal:0,
          serieDocumentoFiscalNfe:(this.pdvSelecionado.idParametrizacaoNfeNavigation != null)?this.pdvSelecionado.idParametrizacaoNfeNavigation?.serieDocumentoFiscal:0,
          permiteCaixaNegativo: false,
          permiteMultiUsuario: false,
          obrigaFecharCaixaDiario: false,
          idContaBancaria: null!,
          permiteEntrega: (this.pdvSelecionado.idParametrizacaoVendaNavigation.permiteEntrega == enumSimNao.Sim)?true:false,
          transmitirNfAutomaticamente: (this.pdvSelecionado.transmissaoAutomaticaNf == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
          permiteAlterarPrecoVenda: (this.pdvSelecionado.idParametrizacaoVendaNavigation.permiteAlterarPrecoVenda == enumSimNao.Sim)? enumSimNao.Sim : enumSimNao.Nao,
        }
      }
      ConstantsService.ParametrizacaoPdv = parametrizacaoPdv;
      this.getPessoaJuridicaByCnpj(ConstantsService.Cnpj);

    }
  }

  subGetPessoaJuridicaByCnpj: Subscription | null = null;
  getPessoaJuridicaByCnpj(cpfCnpj: string) {
    this.subGetPessoaJuridicaByCnpj = this.contaSistemaService.getPessoaJuridicaByCnpj(cpfCnpj, false).subscribe(
      (retorno: PessoaJuridicaModel) => {
        //retorno
        ConstantsService.PessoaJuridica = retorno;
        this.atualizarProdutos();
        this.atualizarServicos();
      },
      (err) => {
        this.mensagemService.esconderLock();
        this.subGetPessoaJuridicaByCnpj?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  //atualizar lista de produtos
  subProdutoService: Subscription = null!;
  atualizarProdutos(){
    this.subProdutoService = this.apiProdutoService.getAllProdutoParaPdv(ConstantsService.TabelaPreco.idTabelaPreco, true).subscribe(
      (retorno: Array<ProdutoParaPdvModel>) =>{
        this.subProdutoService?.unsubscribe();
        if (retorno != undefined) {
          ConstantsService.ListaProdutosCadastrados = retorno;
          ConstantsService.DataAtualizacaoProduto = new Date();
          this.abriuFechouCaixa.emit(this.inputDados);
          this.navegarPaginaProdutos();
        }
      },
      (err) => {
        this.subProdutoService?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  //atualizar lista de Serviços
  subServicoService: Subscription = null!;
  atualizarServicos(){
    this.subServicoService = this.apiProdutoService.getAllServicoParaPdv(ConstantsService.TabelaPreco.idTabelaPreco, true).subscribe(
      (retorno: Array<ProdutoParaPdvModel>) =>{
        this.subServicoService?.unsubscribe();
        if (retorno != undefined) {
          ConstantsService.ListaServicosCadastrados = retorno;
          ConstantsService.DataAtualizacaoProduto = new Date();
          this.abriuFechouCaixa.emit(this.inputDados);
          this.navegarPaginaProdutos();
        }
      },
      (err) => {
        this.subServicoService?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  fecharModal() {
    this.fechouModal.emit(null);
    PdvComponent.modalAtiva = false;
    this.modalCaixa.hide();
  }

  inputCedula: CaixaControleCedulaModel = CaixaControleCedulaModelInstance.get();
  contarCedula(){
    this.tipoAcaoAbrirFecharCaixa = this.tipoAcaoCedula;
    this.onFocusQuantidadeNota2();
  }

  atualizarSaldo(){
    this.inputCedula.valorTotalDinheiro = (this.inputCedula.quantidadeNota2 * 2) +
                                          (this.inputCedula.quantidadeNota5 * 5) +
                                          (this.inputCedula.quantidadeNota10 * 10) +
                                          (this.inputCedula.quantidadeNota20 * 20) +
                                          (this.inputCedula.quantidadeNota50 * 50) +
                                          (this.inputCedula.quantidadeNota100 * 100) +
                                          (this.inputCedula.quantidadeNota200 * 200);

    this.inputCedula.valorTotalMoeda = (this.inputCedula.quantidadeMoeda1Centavo * 0.01) +
                                        (this.inputCedula.quantidadeMoeda5Centavos * 0.05) +
                                        (this.inputCedula.quantidadeMoeda10Centavos * 0.1) +
                                        (this.inputCedula.quantidadeMoeda25Centavos * 0.25) +
                                        (this.inputCedula.quantidadeMoeda50Centavos * 0.5) +
                                        this.inputCedula.quantidadeMoeda1Real;

    this.inputCedula.valorTotal = this.inputCedula.valorTotalDinheiro + this.inputCedula.valorTotalMoeda;
  }

  voltarCedula(){
    this.onFocusTrocoFinal();
    this.tipoAcaoAbrirFecharCaixa = this.tipoAcaoFecharCaixa;
    this.inputDados.valorTotalSaida = this.inputCedula.valorTotal;
    this.valorSangriaFinal = null;
    // if(this.inputDados.valorTotalSaida >= 0){
    //   this.valorSangriaFinal = this.valorTotalTrocoCalculado - this.inputDados.valorTotalSaida;
    // }
  }

  subLogCedulas: Subscription = null!;
  protected salvarCedula(){
    this.inputCedula.idCaixaControle = this.inputDados.idCaixaControle;

    this.subLogCedulas = this.apiCaixaService.contagemCedulasLog(this.inputCedula, false).subscribe({
      error: erro => this.showMessageError.exibirMensagemErro(erro),
      complete: () => this.subLogCedulas.unsubscribe()
    });
  }

  keypress(valor: KeyboardEvent){
    if (valor.keyCode == 13){ //enter
      if (this.tipoAcaoAbrirFecharCaixa == this.tipoAcaoAbrirCaixa){
        this.abrirCaixa();
      }else if (this.tipoAcaoAbrirFecharCaixa == this.tipoAcaoFecharCaixa){
        this.fecharCaixa();
      }
    }
  }

  bloquearCampos(){  }

  setStartObject(){
    this.inputDados = CaixaControleModelInstance.get();
    this.inputDados.valorTotalEntrada = null;
    this.inputDados.valorTotalSaida = null;
    this.abrirFecharCaixa.observacao = '';
    this.valorSangriaFinal = null;
  };

  changeTrocoProxCaixa(valor: number){
    if(valor >= 0){
      if (this.inputCedula.valorTotal != null && this.inputCedula.valorTotal != 0){
        this.valorSangriaFinal = this.inputCedula.valorTotal - valor;
      }else{
        this.valorSangriaFinal = this.valorTotalTrocoCalculado - valor;
      }
    }
  }
}
