<app-ui-modal #modal [containerClick]="false" (close)="cliqueFechar()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-comments-alt-dollar icon-header-modal"></i>&nbsp;Simulações de Pagamentos para este Orçamento</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row" >
            <p-table  #dtOrcamento [value]="orcamentosSimulacoesPagamentos" dataKey="idOrcamentoSimulacaoPagamento" 
                [scrollHeight]="heightLista" responsiveLayout="scroll"
                selectionMode="single"
                class="p-table nowrap table-hover " [loading]="loading" [(selection)]="orcamentoSimulacaoProduto" 
                (onRowSelect)="onRowSelectItemSimulacaoOrcamentoPagamento($event)" 
                [paginator]="false" 
                currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros"
                >
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center ">#</th>
                    <th  class="text-center">Descrição</th>
                    <th  class="text-center">Despesa Cond. Pgto</th>
                    <th  class="text-center">Juros</th>
                    <th class="text-center ">Desc. Cond. Pgto</th>
                    <th class="text-center ">Desc. Vend.</th>
                    <th class="text-center ">Total Pgto</th>
                    <th class="text-center ">Total Pendente</th>
                    <th class="text-center p-r-10">Ações</th>
                    </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr  [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex"  class="mouse-pointer">
                        <td class="text-center" >
                            <div class="form-check form-check-inline" >
                                <input type="radio" [id]="getIdRadioSelecaoPagamento(item.idOrcamentoSimulacaoPagamento)" name="radioSelecaoFormaPagamento" class="form-check-input"                                  
                                    (click)="selecionarFormaPagamento(item, rowIndex)"  [value]="item.selecionado" [checked]="item.selecionado == 'S'"
                                    >
                            </div>
                        </td>
                        <td>
                        {{ item.descricao }} 
                        </td>
                        <td  class="text-center">
                            {{ item.valorDespesaCondicaoPagamento | currency:'BRL'}}
                        </td>
                        <td class="text-center">
                            {{ item.valorJurosCondicaoPagamento | currency:'BRL'}}
                        </td>
                        <td class="text-center">
                            {{ item.valorDescontoCondicaoPagamento | currency:'BRL'}}
                        </td>
                        <td class="text-center">
                            {{ item.valorDescontoVendedor | currency:'BRL'}}
                        </td>
                        <td class="text-center">
                            {{ item.valorTotalPagamento | currency:'BRL'}}
                        </td>
                        <td class="text-center">
                            {{ item.valorPendentePagamento | currency:'BRL'}}
                        </td>
                        <td class="table-action nowrap text-center">
                        <a ngbTooltip="Selecionar" class=" " (click)="alterarItem(item, rowIndex)">
                            <i class="mouse-pointer fad fa-edit text-c-blue f-16 m-r-15"></i></a>&nbsp;
                        <a ngbTooltip="Remover" class=" " (click)="removerItem(item, rowIndex)">
                            <i class="mouse-pointer text-c-red fad fa-trash-alt f-16 m-r-15"></i></a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="9">Não há simulações de pagamentos(s) registrado(s)</td>
            </tr>
            </ng-template>
        </p-table>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" (click)="cliqueFechar()" 
            class="btn btn-secondary inline"><i class="fad fa-window-close"></i> Fechar</button>&nbsp;
    </div>
</app-ui-modal>
