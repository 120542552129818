import swal from 'sweetalert2';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription, interval } from 'rxjs';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ProdutoPesquisaModel } from './ProdutoPesquisaModel';
import { ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { InputNumber } from 'primeng/inputnumber';
import { AutoComplete } from 'primeng/autocomplete';
import { PdvComponent } from 'src/app/theme/layout/pdv/pdv.component';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { AppSettings } from 'src/app/app-settings';
import { environment } from 'src/environments/environment';
import { ServicoPesquisaModel } from './ServicoPesquisaModel';
import { enumSimNao } from 'src/app/enum/enumSimNao';

export interface ProdutoSelecionado{
  value: string;
  label: string;
  preco: string;
  thumbnailImageSrc: string;
}

export interface ServicoSelecionado{
  value: string;
  label: string;
  preco: string;
}

@Component({
  selector: 'app-pesquisa-por-produto',
  templateUrl: './pesquisa-por-produto.component.html',
  styleUrls: ['./pesquisa-por-produto.component.css']
})
export class PesquisaPorProdutoComponent implements OnInit ,  AfterViewInit, OnDestroy{

  @Output() iniciar: EventEmitter<ProdutoPesquisaModel> = new EventEmitter();
  @Output() adicionarProduto: EventEmitter<ProdutoPesquisaModel> = new EventEmitter();
  @Input() inputDados: any = null;
  @Input() disabled: boolean = false;
  @Input() habilitaProdutoServico: string = 'T'; /* T para todos, S somente serviço e P somente produto */

  onChangeTipoSelecao(event:any){
    console.log(event);
    this.tipoSelecionado = event.value;
    if (this.tipoSelecionado.value == 'P'){
      this.selecionarTipo(enumTipoProduto.Produto);
    }
    if (this.tipoSelecionado.value == 'S'){
      this.selecionarTipo(enumTipoProduto.Servico);
    }
  }

  tiposSelecao: any[] = [
    {icon: 'fad fa-shopping-cart f-16 text-c-black ', justify: 'Left', value: 'P', toolTip: 'Pesquisar por Produto'},
    {icon: 'fad fa-wrench f-16 text-c-black ', justify: 'Right', value: 'S' , toolTip: 'Pesquisar por serviço'},
  ];
  tipoSelecionado: any = {icon: 'fad fa-wrench f-24 text-c-white ', justify: 'Left', value: 'P' , toolTip: 'Pesquisar por Produto'};

  static onKeyUpAutoCompleteOn: boolean;

  permiteCadProduto: string = enumSimNao.Sim;
  permiteCadServico: string = enumSimNao.Sim;

  constructor() { }
  ngAfterViewInit(): void {
    this.startTimer();
    if (this.habilitaProdutoServico == 'S'){
      this.tipoProdServ = this.tipoServico;
    }
    if (this.habilitaProdutoServico == 'P' || this.habilitaProdutoServico == 'T'){
      this.tipoProdServ = this.tipoProduto;
    }
  }
  ngOnDestroy(): void {
    this.pauseTimer();
  }

  ngOnInit(): void {

    if (ConstantsService.ParametrizacaoCadastro != null){
      if (ConstantsService.ParametrizacaoCadastro.permiteCadastroProduto != null){
        this.permiteCadProduto = ConstantsService.ParametrizacaoCadastro.permiteCadastroProduto;
      }
      if (ConstantsService.ParametrizacaoCadastro.permiteCadastroServico != null){
        this.permiteCadServico = ConstantsService.ParametrizacaoCadastro.permiteCadastroServico;
      }
    }

    //console.log(this.produtos);
    if(this.permiteCadProduto == enumSimNao.Sim && this.permiteCadServico == enumSimNao.Sim){
      if(this.habilitaProdutoServico == enumTipoProduto.Todos){
        this.habilitaProdutoServico = enumTipoProduto.Todos;
      }
      this.tipoProdServ = enumTipoProduto.Produto;
    }
    else
    if(this.permiteCadProduto == enumSimNao.Sim)
    {
      this.habilitaProdutoServico = enumTipoProduto.Produto;
      this.tipoProdServ = enumTipoProduto.Produto;
    }
    else
    if(this.permiteCadServico == enumSimNao.Sim)
    {
      this.habilitaProdutoServico = enumTipoProduto.Servico;
      this.tipoProdServ = enumTipoProduto.Servico;
    }
  }

  tipoProduto: enumTipoProduto = enumTipoProduto.Produto;
  tipoServico: enumTipoProduto = enumTipoProduto.Servico;
  tipoProdServ: enumTipoProduto = enumTipoProduto.Produto;
  habilitaRadTipoProduto: boolean = true;
  habilitaRadTipoServico: boolean = false;
  selecionarTipo(tipoProdServ: enumTipoProduto) {
    if (tipoProdServ == this.tipoServico) {
      this.habilitaRadTipoProduto = false;
      this.habilitaRadTipoServico = true;
    } else {
      this.habilitaRadTipoProduto = true;
      this.habilitaRadTipoServico = false;
    }
    this.tipoProdServ = tipoProdServ;
  }


   /*tratamento para cadastro de produtos*/
   produtos: Array<ProdutoSelecionado> = [];
   subProdutoService: Subscription = null!;

   servicos: Array<ServicoSelecionado> = [];
   servicoSelecionado: ServicoSelecionado = null!;

   produtoSelecionado: ProdutoSelecionado = null!;
   //quantidadeSelecionada: number = 1;
   campoPesquisa: any;
   precoProduto: number = 0;
   quantidadeSelecionada: number = 0;
   subTotal: number = 0;
   total: number = 0;
   quantidade: number = 1;
   gtinProdutoSelecionado: string = '';
   skuProdutoSelecionado: string = '';
   nomeProdutoSelecionado: string = '';
   unidadeMedida: string = '';
   autofocus: boolean = false;

  pesquisarProdutos(event) {
    if (PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn) return;
    if (event.query == null || event.query == '') return;
    const onlyNumbers = new RegExp('^[0-9]+$');
    let somenteNumero = onlyNumbers.test(this.campoPesquisa);
    this.confirmarPesquisaProduto(event.query, somenteNumero, false);
  }

  pesquisarServicos(event) {
    if (PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn) return;
    if (event.query == null || event.query == '') return;
    const onlyNumbers = new RegExp('^[0-9]+$');
    let somenteNumero = onlyNumbers.test(this.campoPesquisa);
    this.confirmarPesquisaServico(event.query, somenteNumero, false);
  }


  getFormatDecimal(number: number): string{
    if (number == null) return '';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  handleChangeQuantidade(quantidade: number){
    // this.quantidade = quantidade;
    // if (this.produtoSelecionado == null) return;
    // let produtoSelecionado = ConstantsService.ListaProdutosCadastrados.find(f => f.idProdutoVariacao.toString() == this.produtoSelecionado.value)!;
    // if (produtoSelecionado == null || produtoSelecionado == undefined) return;
    // this.precoProduto = (produtoSelecionado.precoVenda??0);
    // this.subTotal = this.quantidade * (produtoSelecionado.precoVenda??0)
  }

  onKeyDownQuantidade(event:any){
    if (event.keyCode == 13) {
      this.onFocusProduto();
    }
  }

  server: string = environment.apiSettings.baseUrl[AppSettings.API.File];
  focusQuantidade: boolean = false;
  focusProduto: boolean = true;
  focusServico: boolean = true;
  cliqueFocusQuantidade(event:any){
     this.focusQuantidade = true;
     this.focusProduto = false;
     setTimeout(()=>{ 
      event.target.select();
    },200);
  }

  cliqueFocusProduto(event:any){
    this.focusQuantidade = false;
    this.focusProduto = true;
  }

  cliqueFocusServico(event:any){
    this.focusQuantidade = false;
    this.focusServico = true;
  }

  produtoNaoEncontrado(){
    this.campoPesquisa = '';
  }

  limparInformacoesProdutoVendaParaEsc(){
    this.gtinProdutoSelecionado = '';
    this.skuProdutoSelecionado = '';
    this.nomeProdutoSelecionado = '';
    this.unidadeMedida = '';
    this.precoProduto = 0;
    this.subTotal = 0;
  }

  confirmarProdutoSelecionado(produtoSelecionado: ProdutoPesquisaModel){}

  confirmarPesquisaProduto(valorInformado: string, somenteNumero: boolean, pressionouEnter: boolean) {
    //console.log('confirmarPesquisaProduto');
    //console.log(valorInformado);
    //console.log(somenteNumero);
    //console.log(pressionouEnter);
    let produtoSelecionado: ProdutoParaPdvModel = null!;

    var valorCodigoNumero = 0;
    if (valorInformado.length == 13){
      let valorCodigo = valorInformado.substr(1,ConstantsService.TamanhoCampoCodigoLeituraCodBarra);
      valorCodigoNumero =  parseInt(valorCodigo, 10);
    }

    if (somenteNumero && (valorInformado.length == 13 && valorInformado.substr(0,1) == '2') || //ean pesável
    (valorInformado.length == 12 && valorInformado.substr(0,1) == '0' && valorCodigoNumero == 0)){ //ean unidade
        //produto pesável
        //procura o produto na lista

        // let tamanhoCodigoBarra  = ConstantsService.TamanhoCampoValorPesoLeituraCodBarra;
        // if (tamanhoCodigoBarra == 0){
        //   swal.fire({title: "Atenção",html: `Você informou um produto pesável, porém o tamanho do código de barra esta zerado.
        //   Acesse o Regatuarda na Rota<b> Configurações </b>-> <b>Balanças</b> e corrija`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
        //     if (result.isConfirmed) {
        //         this.produtoNaoEncontrado();
        //         PdvComponent.modalAtiva = false;
        //         return;
        //     }});
        //   return;
        // }
        let tamanhoCampoCodigoLeituraCodBarra = ConstantsService.TamanhoCampoCodigoLeituraCodBarra;
        let tamanhoCampoValorPesoLeituraCodBarra = ConstantsService.TamanhoCampoValorPesoLeituraCodBarra;

        let gtinParaPesquisaParte1: string = "";
        let gtinParaPesquisaParte2: string = "";
        let gtinParaPesquisa: string = "";
        
        if (valorInformado.length == 12){
          let stringWithoutLastChar: string = valorInformado.slice(0, -1);
          gtinParaPesquisaParte1 = stringWithoutLastChar.slice(-tamanhoCampoCodigoLeituraCodBarra);
          gtinParaPesquisaParte2 = "".padStart(tamanhoCampoValorPesoLeituraCodBarra, '0');
          gtinParaPesquisa = "2" + gtinParaPesquisaParte1 + gtinParaPesquisaParte2;
        }
        else if (valorInformado.length == 13){
          gtinParaPesquisaParte2 = "".padStart(tamanhoCampoValorPesoLeituraCodBarra, '0')
          gtinParaPesquisa = valorInformado.substr(0,tamanhoCampoCodigoLeituraCodBarra + 1) + gtinParaPesquisaParte2;
        }

        produtoSelecionado = ConstantsService.ListaProdutosCadastrados.find(f => 
          f.gtin.length == 13 && f.gtin.substr(0,12) == gtinParaPesquisa)!;
  
        if (produtoSelecionado == null){
          //jander - Caso em que o produto não é pesavel e possui Ean de 12 caracteres. Ex: 088381176538
          produtoSelecionado = ConstantsService.ListaProdutosCadastrados.find(f => f.gtin?.toString() == valorInformado)!;
          //
          if (produtoSelecionado == null){
            PdvComponent.modalAtiva = true;
            PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
            swal.fire({title: "Atenção",html: `Produto com GTIN(EAN) <b>"${gtinParaPesquisa}"</b> não encontrado`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
              if (result.isConfirmed) {
                  this.produtoNaoEncontrado();
                  PdvComponent.modalAtiva = false;
                  return;
              }});
            return;
          }
        }
  
        if (produtoSelecionado.tipoEanBalanca == '1'){ //peso
          if (produtoSelecionado.unidadeMedida == enumUnidadeComercial.Quilograma){
            //pegar o valor do peso, dividir por 1000 e multiplicar pelo 
            this.quantidade = Number.parseFloat(valorInformado.substr(ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1,ConstantsService.TamanhoCampoValorPesoLeituraCodBarra))/1000;
  
          }else if (produtoSelecionado.unidadeMedida == enumUnidadeComercial.Grama){
            //pegar o valor do peso, dividir por 1000 e multiplicar pelo 
            let valor = Number.parseFloat(valorInformado.substr(ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1,ConstantsService.TamanhoCampoValorPesoLeituraCodBarra));
            if (valor == 0) this.quantidade = 0; else{
              this.quantidade = valor / produtoSelecionado.precoVenda!;
            }
          }
        }else if (produtoSelecionado.tipoEanBalanca == '2'){ //valor 
          let valor = Number.parseFloat(valorInformado.substr(ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1,ConstantsService.TamanhoCampoValorPesoLeituraCodBarra)) / 100;
          if (valor == 0) this.quantidade = 0; else{
            this.quantidade = valor / produtoSelecionado.precoVenda!;
          }
        }else if (produtoSelecionado.tipoEanBalanca == '3'){ // unidade
          this.quantidade = 1;
        }
        this.quantidade = Number.parseFloat(this.quantidade?.toFixed(3)!);
  
         //selecionar produto
         this.nomeProdutoSelecionado = produtoSelecionado.nome;
         this.gtinProdutoSelecionado = produtoSelecionado.gtin;
         this.skuProdutoSelecionado = produtoSelecionado.sku;
         this.unidadeMedida = produtoSelecionado.unidadeMedida;
         
         this.produtoSelecionado = {
           value: produtoSelecionado.idProdutoVariacao?.toString(),
           label: produtoSelecionado.gtin + '-' + produtoSelecionado.nome,
           preco: this.getFormatDecimal(produtoSelecionado.precoVenda!),
           thumbnailImageSrc: produtoSelecionado.thumbnailImageSrc
         };
         this.selecionouProduto(null);
         return;
  
    }else if (somenteNumero){ //eh gtin mas não de balança
        //fluxo normal
  
        let retornoProdutosEncontrados = ConstantsService.ListaProdutosCadastrados.filter(f => f.gtin?.toString() == valorInformado)!;
        if (retornoProdutosEncontrados == null || retornoProdutosEncontrados.length == 0 && (valorInformado.length >= 13 || pressionouEnter)){
          
          //verifica se também não encontrou o sku
          retornoProdutosEncontrados = ConstantsService.ListaProdutosCadastrados.filter(f => f.sku?.toString() == valorInformado)!;
          if (retornoProdutosEncontrados == null || retornoProdutosEncontrados.length == 0){
            PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
            PdvComponent.modalAtiva = true;
            swal.fire({title: "Atenção",html: `Produto com GTIN(EAN) <b>"${valorInformado}"</b> não encontrado`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
              if (result.isConfirmed) {
                this.produtoNaoEncontrado();
                PdvComponent.modalAtiva = false;
                  return;
              }});
            return;
          }
        }
        if (retornoProdutosEncontrados == null || retornoProdutosEncontrados.length == 0 && valorInformado.length < 13){
          PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
          return;
        }
  
        if (retornoProdutosEncontrados.length > 1){
          this.produtos =  retornoProdutosEncontrados.map((m) => { return {
            value: m.idProdutoVariacao?.toString(),
              label: m.gtin + '-' + m.nome,
              preco: this.getFormatDecimal(m.precoVenda!),
              thumbnailImageSrc: m.thumbnailImageSrc
            } });
            PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
          return;
        }
        produtoSelecionado = retornoProdutosEncontrados[0];
         //selecionar produto
        this.nomeProdutoSelecionado = produtoSelecionado.nome;
        this.gtinProdutoSelecionado = produtoSelecionado.gtin;
        this.skuProdutoSelecionado = produtoSelecionado.sku;
        this.unidadeMedida = produtoSelecionado.unidadeMedida;
        
        this.produtoSelecionado = {
          value: produtoSelecionado.idProdutoVariacao?.toString(),
          label: produtoSelecionado.gtin + '-' + produtoSelecionado.nome,
          preco: this.getFormatDecimal(produtoSelecionado.precoVenda!),
          thumbnailImageSrc: produtoSelecionado.thumbnailImageSrc
        } ;
        
        this.selecionouProduto(null);
        return;
    }else{ //pesquisando pelo nome do produto
        let retornoProdutosEncontrados = ConstantsService.ListaProdutosCadastrados.filter(f => f.gtin == valorInformado);
        if (retornoProdutosEncontrados.length == 1){
          this.produtos =  retornoProdutosEncontrados.map((m) => { return {
            value: m.idProdutoVariacao.toString(),
              label: m.gtin + '-' + m.nome,
              preco: this.getFormatDecimal(m.precoVenda!),
              thumbnailImageSrc: m.thumbnailImageSrc
            } });
            this.nomeProdutoSelecionado = retornoProdutosEncontrados[0].nome;
            this.gtinProdutoSelecionado = retornoProdutosEncontrados[0].gtin;
            this.skuProdutoSelecionado = retornoProdutosEncontrados[0].sku;
            this.unidadeMedida = retornoProdutosEncontrados[0].unidadeMedida;
            this.produtoSelecionado = this.produtos[0];
            this.selecionouProduto(null);
            return;
        }
         retornoProdutosEncontrados = ConstantsService.ListaProdutosCadastrados.filter(f => f.sku == valorInformado);
        if (retornoProdutosEncontrados.length == 1){
          this.produtos =  retornoProdutosEncontrados.map((m) => { return {
            value: m.idProdutoVariacao.toString(),
              label: m.gtin + '-' + m.nome,
              preco: this.getFormatDecimal(m.precoVenda!),
              thumbnailImageSrc: m.thumbnailImageSrc
            } });
            this.nomeProdutoSelecionado = retornoProdutosEncontrados[0].nome;
            this.gtinProdutoSelecionado = retornoProdutosEncontrados[0].gtin;
            this.skuProdutoSelecionado = retornoProdutosEncontrados[0].gtin;
            this.unidadeMedida = retornoProdutosEncontrados[0].unidadeMedida;
            this.produtoSelecionado = this.produtos[0];
            this.selecionouProduto(null);
            return;
        }
        if (valorInformado == null || valorInformado == '' || valorInformado == undefined) return;
        retornoProdutosEncontrados = ConstantsService.ListaProdutosCadastrados.filter(f => 
            f.nome.toUpperCase().indexOf(valorInformado!.toString().toUpperCase()) > -1 
            || (f.gtin != null && f.gtin != undefined && f.gtin != '' && f.gtin.toUpperCase().indexOf(valorInformado!.toString().toUpperCase()) > -1)
            || (f.sku != null && f.sku != undefined && f.sku != '' && f.sku.toUpperCase().indexOf(valorInformado!.toString().toUpperCase()) > -1)
            );
        if (retornoProdutosEncontrados.length > 0){
          this.produtos =  retornoProdutosEncontrados.map((m) => { return {
            value: m.idProdutoVariacao.toString(),
              label: m.nome,
              preco: this.getFormatDecimal(m.precoVenda!),
              thumbnailImageSrc: m.thumbnailImageSrc
            } });
            PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
            return;
        }
        if (retornoProdutosEncontrados.length == 0 && pressionouEnter){
          PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
          PdvComponent.modalAtiva = true;
          swal.fire({title: "Atenção",html: `Produto <b>"${valorInformado}"</b> não encontrado`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
            if (result.isConfirmed) {
              this.produtoNaoEncontrado();
              PdvComponent.modalAtiva = false;
                return;
            }});
          return;
        }
    }
  }

  selecionouProduto(event: any) {
    if (this.produtoSelecionado == null) return;
    ////console.log('selecionouProduto true');
    let produtoSelecionado = ConstantsService.ListaProdutosCadastrados.find(f => f.idProdutoVariacao.toString() == this.produtoSelecionado.value)!;
    if (produtoSelecionado != null){

      if (produtoSelecionado.unidadeMedida == enumUnidadeComercial.Unidade && this.quantidade % 1 != 0 && !isNaN(this.quantidade % 1)){
        swal.fire({title: "Atenção",html: `Este produto não aceita quantidade fracionada, valor informado <b>"${this.quantidade}</b>"`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
          if (result.isConfirmed) {
            this.produtoNaoEncontrado();
              return;
          }});
        return;
      }

      this.precoProduto = (produtoSelecionado.precoVenda??0);
      this.subTotal = this.quantidade * (produtoSelecionado.precoVenda??0);
      this.nomeProdutoSelecionado = produtoSelecionado.nome;
      this.gtinProdutoSelecionado = produtoSelecionado.gtin;
      this.skuProdutoSelecionado = produtoSelecionado.sku;
      this.unidadeMedida = produtoSelecionado.unidadeMedida;

      if (this.inputDados == null){
        //selecionar produto
        let produtoPesquisado: ProdutoPesquisaModel = produtoSelecionado as ProdutoPesquisaModel;
        produtoPesquisado.quantidade = this.quantidade;
        this.iniciar.emit(produtoPesquisado);
        this.onFocusProduto();
        this.campoPesquisa = '';
      }else{
        let produtoPesquisado: ProdutoPesquisaModel = produtoSelecionado as ProdutoPesquisaModel;
        produtoPesquisado.quantidade = this.quantidade;
        this.quantidadeSelecionada = this.quantidade;
        this.adicionarProduto.emit(produtoPesquisado);
        this.onFocusProduto();
        this.campoPesquisa = '';
      }
      this.quantidade = 1;
    }
  }

  onKeyUpAutoCompleteProduto( event: any){
    // console.log('onKeyUpAutoComplete');
    // console.log(this.campoPesquisa);
    // console.log(event.key);

    if (this.campoPesquisa == undefined) return;
    
    const onlyNumbers = new RegExp('^[0-9]+$');
    
    if (event.key == 'Escape'){
      this.limparInformacoesProdutoVendaParaEsc();
    }
    if (event.key == 'Enter'){
      let valorInformado = this.campoPesquisa;
      //console.log('pressionou enter')
      //console.log(valorInformado);
      //console.log(valorInformado.label);
      this.produtoSelecionado = null!;
      //this.nomeProdutoSelecionado = '';
      //this.gtinProdutoSelecionado = '';
      // this.precoProduto = 0;
      // this.subTotal = 0;
      this.produtos = [];

      PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = true;

      let somenteNumero = onlyNumbers.test(this.campoPesquisa);
      if (this.campoPesquisa == '') {
        PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        return;
      } 
      if (valorInformado != null && valorInformado.label != undefined && valorInformado.label != '') valorInformado = valorInformado.label;
      this.confirmarPesquisaProduto(valorInformado, somenteNumero, true);
    }
  }
   /*fim tratamento de cadastro de produto*/

   onKeyUpAutoCompleteService( event: any){
    if (this.campoPesquisa == undefined) return;
    
    const onlyNumbers = new RegExp('^[0-9]+$');
    
    if (event.key == 'Escape'){
      this.limparInformacoesProdutoVendaParaEsc();
    }
    if (event.key == 'Enter'){
      let valorInformado = this.campoPesquisa;
      this.servicoSelecionado = null!;
      this.servicos = [];

      PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = true;

      let somenteNumero = onlyNumbers.test(this.campoPesquisa);
      if (this.campoPesquisa == '') {
        PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        return;
      } 
      if (valorInformado.label != '' && valorInformado.label != undefined) valorInformado = valorInformado.label;
      this.confirmarPesquisaServico(valorInformado, somenteNumero, true);
    }
  }
  /*fim tratamento de cadastro de produto*/
  /*inicio tratamento de servico*/

  onKeyUpAutoCompleteServico( event: any){
    if (this.campoPesquisa == undefined) return;
    
    const onlyNumbers = new RegExp('^[0-9]+$');
    
    if (event.key == 'Escape'){
      this.limparInformacoesProdutoVendaParaEsc();
    }
    if (event.key == 'Enter'){
      let valorInformado = this.campoPesquisa;
      this.servicoSelecionado = null!;
      this.servicos = [];

      PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = true;

      let somenteNumero = onlyNumbers.test(this.campoPesquisa);
      if (this.campoPesquisa == '') {
        PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        return;
      } 
      if (valorInformado.label != '' && valorInformado.label != undefined) valorInformado = valorInformado.label;
      this.confirmarPesquisaServico(valorInformado, somenteNumero, true);
    }
  }

  servicoNaoEncontrado(){
    this.campoPesquisa = '';
  }

  confirmarPesquisaServico(valorInformado: string, somenteNumero: boolean, pressionouEnter: boolean) {
  let servicoSelecionado: ProdutoParaPdvModel = null!;

  if (somenteNumero && valorInformado.length == 13 && valorInformado.substr(0,1) == '2'){ //ean pesável
      //produto pesável
      //procura o produto na lista
      let gtinParaPesquisaParte2 = "".padStart(ConstantsService.TamanhoCampoValorPesoLeituraCodBarra, '0')
      let gtinParaPesquisa = valorInformado.substr(0,ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1) + gtinParaPesquisaParte2;
      servicoSelecionado = ConstantsService.ListaServicosCadastrados.find(f => 
        f.gtin.length == 13 && f.gtin.substr(0,12) == gtinParaPesquisa)!;

      if (servicoSelecionado == null){
        PdvComponent.modalAtiva = true;
        PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        swal.fire({title: "Atenção",html: `Produto com GTIN(EAN) <b>"${gtinParaPesquisa}"</b> não encontrado`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
          if (result.isConfirmed) {
              this.servicoNaoEncontrado();
              PdvComponent.modalAtiva = false;
              return;
          }});
        return;
      }

      if (servicoSelecionado.tipoEanBalanca == '1'){ //peso
        if (servicoSelecionado.unidadeMedida == enumUnidadeComercial.Quilograma){
          //pegar o valor do peso, dividir por 1000 e multiplicar pelo 
          this.quantidade = Number.parseFloat(valorInformado.substr(ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1,ConstantsService.TamanhoCampoValorPesoLeituraCodBarra))/1000;

        }else if (servicoSelecionado.unidadeMedida == enumUnidadeComercial.Grama){
          //pegar o valor do peso, dividir por 1000 e multiplicar pelo 
          let valor = Number.parseFloat(valorInformado.substr(ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1,ConstantsService.TamanhoCampoValorPesoLeituraCodBarra));
          if (valor == 0) this.quantidade = 0; else{
            this.quantidade = valor / servicoSelecionado.precoVenda!;
          }
        }
      }else if (servicoSelecionado.tipoEanBalanca == '2' || servicoSelecionado.tipoEanBalanca == '3'){ //valor ou unidade
        let valor = Number.parseFloat(valorInformado.substr(ConstantsService.TamanhoCampoCodigoLeituraCodBarra + 1,ConstantsService.TamanhoCampoValorPesoLeituraCodBarra)) / 100;
        if (valor == 0) this.quantidade = 0; else{
          this.quantidade = valor / servicoSelecionado.precoVenda!;
        }
      }
      this.quantidade = Number.parseFloat(this.quantidade?.toFixed(3)!);

       //selecionar produto
       this.nomeProdutoSelecionado = servicoSelecionado.nome;
       this.gtinProdutoSelecionado = servicoSelecionado.gtin;
       this.skuProdutoSelecionado = servicoSelecionado.sku;
       this.unidadeMedida = servicoSelecionado.unidadeMedida;
       
       this.servicoSelecionado = {
         value: servicoSelecionado.idProdutoVariacao.toString(),
         label: servicoSelecionado.gtin + '-' + servicoSelecionado.nome,
         preco: this.getFormatDecimal(servicoSelecionado.precoVenda!)
       } ;
       this.selecionouServico(null);
       return;

  }else if (somenteNumero){ //eh gtin mas não de balança
      //fluxo normal
      // let retornoServicosEncontrados = ConstantsService.ListaServicosCadastrados.filter(f => f.gtin.toString() == valorInformado)!;
      // if (retornoServicosEncontrados == null || retornoServicosEncontrados.length == 0 && (valorInformado.length >= 13 || pressionouEnter)){
        
        //verifica se também não encontrou o sku
        let retornoServicosEncontrados = ConstantsService.ListaServicosCadastrados.filter(f => f.sku?.toString() == valorInformado)!;
        if (retornoServicosEncontrados == null || retornoServicosEncontrados.length == 0){
          PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
          PdvComponent.modalAtiva = true;
          swal.fire({title: "Atenção",html: `Serviço com código <b>"${valorInformado}"</b> não encontrado`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
            if (result.isConfirmed) {
              this.servicoNaoEncontrado();
              PdvComponent.modalAtiva = false;
                return;
            }});
          return;
        //}
      }
      if (retornoServicosEncontrados == null || retornoServicosEncontrados.length == 0 && valorInformado.length < 13){
        PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        return;
      }

      if (retornoServicosEncontrados.length > 1){
        this.servicos =  retornoServicosEncontrados.map((m) => { return {
          value: m.idProdutoVariacao.toString(),
            label: m.gtin + '-' + m.nome,
            preco: this.getFormatDecimal(m.precoVenda!)
          } });
          PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        return;
      }
      servicoSelecionado = retornoServicosEncontrados[0];
       //selecionar servico
      this.nomeProdutoSelecionado = servicoSelecionado.nome;
      this.gtinProdutoSelecionado = servicoSelecionado.gtin;
      this.skuProdutoSelecionado = servicoSelecionado.sku;
      this.unidadeMedida = servicoSelecionado.unidadeMedida;
      
      this.servicoSelecionado = {
        value: servicoSelecionado.idProdutoVariacao.toString(),
        label: servicoSelecionado.gtin + '-' + servicoSelecionado.nome,
        preco: this.getFormatDecimal(servicoSelecionado.precoVenda!)
      };
      
      this.selecionouServico(null);
      return;
  }else{ //pesquisando pelo nome do servico
      let retornoServicosEncontrados = ConstantsService.ListaServicosCadastrados.filter(f => f.gtin == valorInformado);
      if (retornoServicosEncontrados.length == 1){
        this.servicos =  retornoServicosEncontrados.map((m) => { return {
          value: m.idProdutoVariacao.toString(),
            label: m.gtin + '-' + m.nome,
            preco: this.getFormatDecimal(m.precoVenda!)
          } });
          this.nomeProdutoSelecionado = retornoServicosEncontrados[0].nome;
          this.gtinProdutoSelecionado = retornoServicosEncontrados[0].gtin;
          this.skuProdutoSelecionado = retornoServicosEncontrados[0].sku;
          this.unidadeMedida = retornoServicosEncontrados[0].unidadeMedida;
          this.servicoSelecionado = this.servicos[0];
          this.selecionouServico(null);
          return;
      }
      retornoServicosEncontrados = ConstantsService.ListaServicosCadastrados.filter(f => f.sku == valorInformado);
      if (retornoServicosEncontrados.length == 1){
        this.servicos =  retornoServicosEncontrados.map((m) => { return {
          value: m.idProdutoVariacao.toString(),
            label: m.gtin + '-' + m.nome,
            preco: this.getFormatDecimal(m.precoVenda!)
          } });
          this.nomeProdutoSelecionado = retornoServicosEncontrados[0].nome;
          this.gtinProdutoSelecionado = retornoServicosEncontrados[0].gtin;
          this.skuProdutoSelecionado = retornoServicosEncontrados[0].gtin;
          this.unidadeMedida = retornoServicosEncontrados[0].unidadeMedida;
          this.servicoSelecionado = this.servicos[0];
          this.selecionouServico(null);
          return;
      }
      if (valorInformado == null || valorInformado == '' || valorInformado == undefined) return;
      retornoServicosEncontrados = ConstantsService.ListaServicosCadastrados.filter(f => 
          f.nome.toUpperCase().indexOf(valorInformado!.toString().toUpperCase()) > -1 
          || (f.gtin != null && f.gtin != undefined && f.gtin != '' && f.gtin.toUpperCase().indexOf(valorInformado!.toString().toUpperCase()) > -1)
          || (f.sku != null && f.sku != undefined && f.sku != '' && f.sku.toUpperCase().indexOf(valorInformado!.toString().toUpperCase()) > -1)
          );
      if (retornoServicosEncontrados.length > 0){
        this.servicos =  retornoServicosEncontrados.map((m) => { return {
          value: m.idProdutoVariacao.toString(),
            label: m.nome,
            preco: this.getFormatDecimal(m.precoVenda!)
          } });
          PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
          return;
      }
      if (retornoServicosEncontrados.length == 0 && pressionouEnter){
        PesquisaPorProdutoComponent.onKeyUpAutoCompleteOn = false;
        PdvComponent.modalAtiva = true;
        swal.fire({title: "Atenção",html: `Serviço <b>"${valorInformado}"</b> não encontrado`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
          if (result.isConfirmed) {
            this.servicoNaoEncontrado();
            PdvComponent.modalAtiva = false;
              return;
          }});
        return;
      }
  }
}

selecionouServico(event: any) {
  if (this.servicoSelecionado == null) return;
  let servicoSelecionado = ConstantsService.ListaServicosCadastrados.find(f => f.idProdutoVariacao.toString() == this.servicoSelecionado.value)!;
  if (servicoSelecionado != null){
    if (servicoSelecionado.unidadeMedida == enumUnidadeComercial.Unidade && this.quantidade % 1 != 0 && !isNaN(this.quantidade % 1)){
      swal.fire({title: "Atenção",
      html: `Este serviço não aceita quantidade fracionada, valor informado <b>"${this.quantidade}</b>"`,icon: TiposMensagem.info ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
        if (result.isConfirmed) {
          this.servicoNaoEncontrado();
            return;
        }});
      return;
    }

    this.precoProduto = (servicoSelecionado.precoVenda??0);
    this.subTotal = this.quantidade * (servicoSelecionado.precoVenda??0);
    this.nomeProdutoSelecionado = servicoSelecionado.nome;
    this.gtinProdutoSelecionado = servicoSelecionado.gtin;
    this.skuProdutoSelecionado = servicoSelecionado.sku;
    this.unidadeMedida = servicoSelecionado.unidadeMedida;

    if (this.inputDados == null){
      //selecionar servico
      let servicoPesquisado: ServicoPesquisaModel = servicoSelecionado as ServicoPesquisaModel;
      servicoPesquisado.quantidade = this.quantidade;
      this.iniciar.emit(servicoPesquisado);
      this.campoPesquisa = '';
    }else{
      let servicoPesquisado: ServicoPesquisaModel = servicoSelecionado as ServicoPesquisaModel;
      servicoPesquisado.quantidade = this.quantidade;
      this.quantidadeSelecionada = this.quantidade;
      this.adicionarProduto.emit(servicoPesquisado);
      this.campoPesquisa = '';
    }
  }
}
  /*fim tratamento de serviço*/ 
  
  @ViewChild('elmPesquisarServico') elmPesquisarServico!: AutoComplete;
  onFocusServico(){
    setTimeout(()=>{ 
        if (this.elmPesquisarServico != null){
          this.elmPesquisarServico.focusInput();
        }
      },200);    
  }

  @ViewChild('elmQuantidadeServico') elmQuantidadeServico: InputNumber = null!;
  onFocusQuantidadeServico(){
    setTimeout(()=>{ 
      if (this.elmQuantidadeServico != null){
        this.elmQuantidadeServico.input.nativeElement.focus();
      }
    },60);    
  }

  @ViewChild('elmPesquisarProduto') elmPesquisarProduto!: AutoComplete ;
  onFocusProduto(){
    setTimeout(()=>{ 

        if (this.elmPesquisarProduto != null){
          this.elmPesquisarProduto.focusInput();
        }
      },200);    
  }

  @ViewChild('elmQuantidadeProduto') elmQuantidadeProduto: InputNumber  = null!;
  onFocusQuantidadeProduto(){
    setTimeout(()=>{ 
      if (this.elmQuantidadeProduto != null){
        this.elmQuantidadeProduto.input.nativeElement.focus();
      }
    },60);    
  }
  
   timeLeft: number = 0;
   interval;
   
   private startTimer() {
     this.interval = setInterval(() => {
       if (this.focusProduto && this.tipoProdServ == this.tipoProduto && !PdvComponent.modalAtiva){
         this.onFocusProduto();
       }else if (this.focusProduto && this.tipoProdServ == this.tipoServico && !PdvComponent.modalAtiva){
        this.onFocusServico();
       }
       else if (this.focusQuantidade  && !PdvComponent.modalAtiva){
         this.onFocusQuantidadeProduto();
       }
 
     }, 2000)
   }
 
  private pauseTimer() {
    clearInterval(this.interval);
  }
}