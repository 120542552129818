<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-xl'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-gift-card"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="inputDados != null">
        <!-- <div class="row pb-3" >
            <div class="col-12">
                <h6>Informações do Cartão</h6>
            </div>
        </div> -->
        <div class="row">
            <!-- <div class="col-12 col-md-2">
                <div class="form-group">
                    <label class="form-label uppercase pb-1" >Código <i class="fas fa-info-circle" ngbTooltip="Código do Cartão"></i></label>
                    <label class="form-label-value uppercase pb-1" >{{ inputDados.cartao.idCartaoFidelidade }}</label>
                </div>
            </div> -->
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label class="form-label uppercase pb-1" >CPF/CNPJ</label>
                    <!-- <label class="form-label-value uppercase pb-1" >{{ inputDados.cartao.cpfCnpj | cpfCnpj }}</label> -->
                    <label class="form-label-value uppercase pb-1" >{{ cpfCnpj | cpfCnpj }}</label>
                </div>
            </div>
            <!-- <div class="col-12 col-md-3">
                <div class="form-group">
                    <label class="form-label uppercase pb-1" >Data de Emissão <i class="fas fa-info-circle" ngbTooltip="Código do Cartão"></i></label>
                    <label class="form-label-value uppercase pb-1" >{{ inputDados.cartao.dataEmissao | date: 'dd/MM/yyyy' }}</label>
                </div>
            </div> -->
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label class="form-label uppercase pb-1" >Pontos Acumulados<i class="fas fa-info-circle" ngbTooltip="Total de pontos acumulados(válidos) nas transações"></i></label>
                    <!-- <label class="form-label-value uppercase pb-1" >{{ inputDados.cartao.pontosAcumulados }}</label> -->
                    <label class="form-label-value uppercase pb-1" >{{ totalPontos }}</label>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group"> 
                    <label class="form-label uppercase pb-1" >Valor Total <i class="fas fa-info-circle" ngbTooltip="total disponível para ser resgatado em vale"></i></label>
                    <!-- <label class="form-label-value uppercase pb-1" >{{ inputDados.cartao.valorEmVale | currency:'BRL' }}</label> -->
                    <label class="form-label-value uppercase pb-1" >{{ totalEmVale | currency:'BRL' }}</label>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group"> 
                    <label class="form-label uppercase pb-1" >Status</label>
                    <!-- <label class="form-label-value uppercase pb-1" >{{ inputDados.cartao.status | status }}</label> -->
                    <label class="form-label-value uppercase pb-1" >{{ status | status }}</label>
                </div>
            </div>
        </div>
        <hr>
        <!-- <div class="row pb-3" >
            <div class="col-12">
                <h6>Recompensas</h6>
            </div>
        </div> -->
        <!-- Lista de Recompensas -->
        <p-table #dtRecompensas [value]="inputDados.recompensas" dataKey="idCartaoFidelidadeRecompensa" 
                  responsiveLayout="scroll"  
                 class="p-table nowrap table-hover" [loading]="loading" [paginator]="true" 
                 [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true" [rows]="5"
                 currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center"># <i class="fas fa-info-circle" ngbTooltip="Se existir cartões registrados e com pontos e/ou valor disponível para resgate, basta selecionar e clicar no botão [Resgatar]."></i></th>
                    <th class="text-center">Nome</th>
                    <th class="text-center">Tipo Cálculo</th>
                    <th class="text-center">Tipo Recompensa</th>
                    <th class="text-center">Recompensa</th>
                    <th class="text-center">Pontos Disponíveis</th>
                    <th class="text-center">Disponível P/ Vale</th>
                    <th class="text-center">Brindes Disponíveis</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr>
                    <td class="text-center" *ngIf="item.pontosASerUtilizado > 0 || item.valorVale > 0">
                        <input type="radio" class="mouse-pointer" name="recompensa" [id]="'recompensa_'+rowIndex" [value]="item.idCartaoFidelidadeRecompensa" (click)="handleChangeCartaoFidelidade($event, rowIndex)">
                    </td>
                    <td *ngIf="(item.pontosASerUtilizado <= 0 && (item.tipoRecompensa == enumTipoRecompensaCartaoFidelidadeBrinde)) || (item.valorVale <= 0 && (item.tipoRecompensa == enumTipoRecompensaCartaoFidelidadeCashback || item.tipoRecompensa == enumTipoRecompensaCartaoFidelidadeDescontoEmValorProximaCompra))"></td>
                    <td class="text-center"><label class="mouse-pointer" [for]="'recompensa_'+rowIndex" >{{ item.nomeAcumuloPontos }}</label></td>
                    <td class="text-center">
                        <label class="mouse-pointer" [for]="'recompensa_'+rowIndex" *ngIf="item.tipoCalculo == enumCalculoPontoCartaoFidelidadeCashback">Cash Back</label>
                        <label class="mouse-pointer" [for]="'recompensa_'+rowIndex" *ngIf="item.tipoCalculo == enumCalculoPontoCartaoFidelidadePorValor">Por Valor</label>
                        <label class="mouse-pointer" [for]="'recompensa_'+rowIndex" *ngIf="item.tipoCalculo == enumCalculoPontoCartaoFidelidadePorQuantidadeDeProduto">Quantidade Produto</label>
                    </td>
                    <td class="text-center"><label class="mouse-pointer" [for]="'recompensa_'+rowIndex" >{{ item.nomeRecompensa }}</label></td>
                    <td class="text-center">
                        <label class="mouse-pointer" [for]="'recompensa_'+rowIndex" *ngIf="item.tipoRecompensa == enumTipoRecompensaCartaoFidelidadeDescontoEmValorProximaCompra">Desconto</label>
                        <label class="mouse-pointer" [for]="'recompensa_'+rowIndex" *ngIf="item.tipoRecompensa == enumTipoRecompensaCartaoFidelidadeBrinde">Brinde</label>
                        <label class="mouse-pointer" [for]="'recompensa_'+rowIndex" *ngIf="item.tipoRecompensa == enumTipoRecompensaCartaoFidelidadeCashback">Cash Back</label>
                    </td>
                    <td class="text-center">{{ item.pontosASerUtilizado  }}</td>
                    <td class="text-center">
                        {{ item.valorVale | currency:'BRL' }}
                    </td>
                    <td class="text-center">{{ item.totalBrinde }}</td>
                  
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Não há recompensas cadastradas</td>
                </tr>
            </ng-template>
        </p-table>
        <hr>
        <!-- Lista de Transações -->
        <!-- <div class="col-12">
            <label class="form-label uppercase pb-1" >Transações</label>
        </div> -->
        <p-table #dtTransacoes [value]="inputDados.transacoes" dataKey="idCartaoFidelidadeTransacao" 
                  responsiveLayout="scroll" 
                 class="p-table nowrap table-hover" [loading]="loading" [paginator]="true" 
                 [rowsPerPageOptions]="[5,10,25,50]" [showCurrentPageReport]="true" [rows]="5"
                 currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Data Transação</th>
                    <th class="text-center">Pontos Gerados</th>
                    <th class="text-center">Venda Origem</th>
                    <th class="text-center">Status</th>
                    <th class="text-center">Valor em Vale</th>
                    <th class="text-center">Data Validade</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr>
                    <td class="text-center">{{ item.idCartaoFidelidadeTransacao }}</td>
                    <td class="text-center">{{ item.dataTransacao | date: 'dd/MM/yyyy' }}</td>
                    <td class="text-center">{{ item.pontosGerados }}</td>
                    <td class="text-center">{{ item.idVenda }}</td>
                    <td class="text-center">
                        <span *ngIf="item.status == 'C'" class="text-c-red">{{ item.status | status }}</span>
                        <span *ngIf="item.status != 'C'">{{ item.status | status }}</span>
                    </td>
                    <td class="text-center">{{ item.valorEmVale | currency:'BRL' }}</td>
                    <td class="text-center">{{ item.dataValidade | date: 'dd/MM/yyyy' }}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="7">Não há transações cadastradas</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    
    
    <div class="app-modal-footer">
        <button [disabled]="false" (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class="fad fa-window-close"></i> Fechar
        </button>&nbsp;
        <button [disabled]="false" (click)="cliqueResgatar()" type="button" 
            class="btn btn-primary m-r-5 inline"><i class="fad fa-smile"></i> Resgatar
        </button>
    </div>
</app-ui-modal>