
export interface FiltroDeclaracao {
    cpfCnpj: string;
    idContasAPagRec: number | null;
    idVenda: number | null;
    tipoImpressao: string;
    tipoDeclaracao: string;
}

export class FiltroDeclaracaoInstance {
    public static get(): FiltroDeclaracao {
        return {
            cpfCnpj: '',
            idContasAPagRec: null,
            idVenda: null,
            tipoImpressao: 'A4',
            tipoDeclaracao: 'U'
        };
    }
}