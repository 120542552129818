import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MensagemModal } from 'src/app/models/mensagem-modal';

@Component({
  selector: 'app-modal-alerta',
  templateUrl: './app-modal-alerta.component.html',
  styleUrls: ['./app-modal-alerta.component.scss']
})
export class AppModalAlertaComponent implements OnInit {

  @ViewChild('modalAlerta') modalAlerta!: Object;
  mensagem: MensagemModal = {
    exibir: false,
    titulo: '',
    mensagem: '',
    textoBtnNao: 'Não',
    textoBtnSim: 'Ok',
    funcaoSim: null
  };
  @Input() closable: boolean = false;
  @Input() label: string = '';
  @Input() value: string = '';

  constructor() { }

  ngOnInit() {
  }

  exibirModalAlerta(mensagemModal: MensagemModal) {
    this.mensagem = {
      exibir: mensagemModal.exibir,
      titulo: mensagemModal.titulo,
      mensagem: mensagemModal.mensagem,
      textoBtnNao: mensagemModal.textoBtnNao,
      textoBtnSim: mensagemModal.textoBtnSim,
      funcaoSim: mensagemModal.funcaoSim,
    };

    // this.modalAlerta.visible = this.mensagem.exibir;
  }

  buttonCancelar() {
    // this.modalAlerta.visible = false;
  }

  buttonOk() {
    if (this.mensagem.funcaoSim != null){
      this.mensagem.funcaoSim();
    }
    // this.modalAlerta.visible = false;
  }
}
