<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-search-dollar icon-header-modal"></i>&nbsp;Selecionar Orçamento</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row justify-content-center ">
            <div class="col-4"></div>
            <div class="col-4 wrap-form big-input">
                <input (keypress)="keyPressCodigoOrcamento($event)" class="form-control" placeholder="" [(ngModel)]="codigoOrcamento" #elmCodigoOrcamento>
            </div>
            <div class="col-4"></div>
        </div>
        <div class="row scroll-miolo" >
            <!-- <div class="col-12 text-center " *ngIf="orcamentosFilter.length == 0  && !loading">
                Não há Ordens de Serviço pendentes de pagamento, para cadastrar acesse este link <b><a href="https://erp.invare.com.br/vendas/ordem-servico" target="_blank">Ordens de Serviço</a></b>
            </div> -->
            <div class="col-12 " *ngIf="orcamentosFilter.length > 0">
                <p-table  #dtVendasPendentes [value]="orcamentosFilter" dataKey="cpfCnpj" 
                    selectionMode="single"
                    class="p-table nowrap table-hover " [loading]="loading" [(selection)]="inputDados" 
                    (onRowSelect)="onRowSelectItemOrcamento($event)" 
                    [paginator]="false" #elmListaOrcamento
                    currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center " style="width:5%">#</th>
                        <th class="text-center " style="width:25%">Cpf/Cnpj</th>
                        <th class="text-center " style="width:40%">Nome</th>
                        <th class="text-center " style="width:20%">Valor</th>
                        <th class="text-center " style="width:10%">Ações</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                            <td class="text-center" >
                                {{item.idOrcamento}}
                            </td>
                            <td class="text-center"  >
                                <span class="text-center " *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'J'">{{ item.cpfCnpj | cnpj }}</span>
                                <span class="text-center" *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'F'">{{ item.cpfCnpj | cpf }}</span>
                                <span class="text-center" *ngIf="item.cpfCnpjNavigation?.tipoPessoa == 'E'">{{ item.cpfCnpj }}</span>
                                <span class="text-center" *ngIf="item.cpfCnpjNavigation == null"></span>
                            </td>
                            <td >
                                <span *ngIf="item.cpfCnpjNavigation != null">{{item.cpfCnpjNavigation?.razaoSocialNome}}</span>
                                <span *ngIf="item.cpfCnpjNavigation == null">Cliente não informado</span>
                            </td>
                            <td class="text-right" >
                                {{item.valorTotal | currency:'BRL'}}
                            </td>
                            <td class="table-action nowrap text-center" >
                                <a ngbTooltip="Selecionar" class=" " (click)="realizarPagamento(item)">
                                    <i class="mouse-pointer fad fa-check text-c-blue f-16 ms-3"></i></a>
                            
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">Não há orçamentos</td>
                    </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Cancelar
        </button>
    </div>
</app-ui-modal>