import swal from 'sweetalert2';
import { AfterViewInit, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { PedidoVendaProdutoModel } from 'src/app/models/cliente/PedidoVendaProduto/pedidovendaProdutoModel';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { ProdutoVariacaoModel } from 'src/app/models/cliente/ProdutoVariacao/produtoVariacaoModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ModalSelecionarClienteComponent } from 'src/app/shared/business-component/modal-selecionar-cliente/modal-selecionar-cliente.component';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ModalInformacoesAdicionaisComponent } from './modal-informacoes-adicionais/modal-informacoes-adicionais.component';
import { ProdutoPesquisaModel } from 'src/app/shared/business-component/pesquisa-por-produto/ProdutoPesquisaModel';
import { ModalSituacaoClienteComponent } from 'src/app/shared/business-component/modal-situacao-cliente/modal-situacao-cliente.component';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { ProdutoModelInstance } from 'src/app/models/cliente/Produto/ProdutoModelInstance';
import { ApiEstoqueService } from 'src/app/services/cliente/api-estoque.service';
import { EstoqueModel } from 'src/app/models/cliente/estoque/estoqueModel';
import { EstoqueModelInstance } from 'src/app/models/cliente/estoque/EstoqueModelInstance';
import { PrecoModelInstance } from 'src/app/models/cliente/preco/PrecoModelInstance';
import { PdvComponent } from 'src/app/theme/layout/pdv/pdv.component';
import { ModalAlterarItemPedidoVendaComponent } from './modal-alterar-item-pedido-venda/modal-alterar-item-pedido-venda.component';
import { ModalPedidoVendaEstoqueComponent } from './modal-pedido-venda-estoque/modal-pedido-venda-estoque.component';
import { PedidoVendumModelInstance } from 'src/app/models/cliente/PedidoVenda/PedidoVendaModelModelInstance';
import { ApiPedidoVendaService } from 'src/app/services/pdv/api-pedido-venda-service';
import { PedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/PedidoVendumModel';
import { ProdutoVariacaoModelInstance } from 'src/app/models/cliente/ProdutoVariacao/ProdutoVariacaoModelInstance';
import { enumTabelaStatusPedidoVenda } from 'src/app/enum/enumTabelaStatusPedidoVenda';
import { CustomFinalizarPedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/CustomFinalizarPedidoVendumModel';
import { CustomFinalizarPedidoVendumModelInstance } from 'src/app/models/cliente/PedidoVenda/CustomFinalizarPedidoVendumModelInstance';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { enumStatus } from 'src/app/enum/enumStatus';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { enumSituacaoVendaProduto } from 'src/app/enum/enumSituacaoVendaProduto';
import { ItemPedidoVendaProdutoPdvModel, ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { PedidoVendaProdutoModelInstance } from 'src/app/models/cliente/PedidoVendaProduto/PedidoVendaProdutoModelInstance';
import { PesquisaPorProdutoComponent, ProdutoSelecionado } from 'src/app/shared/business-component/pesquisa-por-produto/pesquisa-por-produto.component';
import { ModalAbrirFecharCaixaComponent } from 'src/app/shared/business-component/modal-abrir-fechar-caixa/modal-abrir-fechar-caixa.component';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';
import { ApiTabelaPrecoService } from 'src/app/services/cliente/api-tabela-preco.service';
import { enumFormatoImpressao } from 'src/app/enum/enumFormatoImpressao';
import { ApiGeraRelatorioPedidoVendaService } from 'src/app/services/relatorio/api-gera-relatorio-pedido-venda.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { CustomFormatoImpressao } from 'src/app/models/relatorios/CustomFormatoImpressao';
import { ModalImpressaoComponent } from 'src/app/shared/business-component/modal-impressao/modal-impressao.component';

export enum enumAcaoFuncionalidadePedidoVenda {
  Nenhuma,
  NovoPedido,
  AtualizarTabelaPreco,
  FinaliarPedidoVenda,
  Imprimir,
  Vender
}

@Component({
  selector: 'app-pedido-venda-cadastro',
  templateUrl: './pedido-venda-cadastro.component.html',
  styleUrls: ['./pedido-venda-cadastro.component.css']
})
export class PedidoVendaCadastroComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(PesquisaPorProdutoComponent, { static: false }) pesquisaPorProduto!: PesquisaPorProdutoComponent;
  
  @ViewChild(ModalSelecionarClienteComponent, { static: false }) modalSelecionarClienteComponent!: ModalSelecionarClienteComponent;
  @ViewChild(ModalInformacoesAdicionaisComponent, { static: false }) modalInformacoesAdicionaisComponent!: ModalInformacoesAdicionaisComponent;
  @ViewChild(ModalSituacaoClienteComponent, { static: false }) modalSituacaoClienteComponent!: ModalSituacaoClienteComponent;
  @ViewChild(ModalAlterarItemPedidoVendaComponent, { static: false }) modalAlterarItemPedidoVendaComponent!: ModalAlterarItemPedidoVendaComponent;
  @ViewChild(ModalPedidoVendaEstoqueComponent, { static: false }) modalPedidoVendaEstoqueComponent!: ModalPedidoVendaEstoqueComponent;
  @ViewChild(ModalAbrirFecharCaixaComponent, { static: false }) modalAbrirFecharCaixaComponent!: ModalAbrirFecharCaixaComponent;
  @ViewChild(ModalImpressaoComponent) modalImpressaoComponent!: ModalImpressaoComponent;
  @Output() atualizarTabelaPreco: EventEmitter<TabelaPrecoModel> = new EventEmitter();
  @Output() atualizarTextoCabecalhoSituacaoCaixa: EventEmitter<CaixaControleModel> = new EventEmitter();

  inputDados: PedidoVendumModel = null!;
  pedidovendaProduto: PedidoVendaProdutoModel = null!;
  parametrosSalvarPedidoVenda: CustomFinalizarPedidoVendumModel = CustomFinalizarPedidoVendumModelInstance.get();
  loading:boolean= false;
  autofocus: boolean = false;
  precoProduto: number = 0;
  subTotal: number = 0;
  total: number = 0;
  quantidadeProdutoSalvo:number = 0;
  subsSalvarPedidoVendaTemporario: Subscription = null!;
  subsRemoverItemPedidoVendaProduto: Subscription = null!;
  subsCancelarPedidoVendaProduto: Subscription = null!;
  subsFinalizarPedidoVenda: Subscription = null!;
  subsImportarPedidoDeVendaParaPagamento: Subscription = null!;
  subsGetByIdProdutoVariacao: Subscription = null!;
  subsGetPesquisar: Subscription = null!;
  indexItemSelecionado: number = 0;
  tipoProduto: enumTipoProduto = enumTipoProduto.Produto;
  tipoServico: enumTipoProduto = enumTipoProduto.Servico;
  
  situacaoEmPreenchimento: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.EmPreenchimento;
  situacaoFinalizado: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.Finalizado;
  situacaoCancelado: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.Cancelado;
  situacaoVendido: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.Vendido;

  unMedMetro: enumUnidadeComercial = enumUnidadeComercial.Metro;
  unMedMetroCubico: enumUnidadeComercial = enumUnidadeComercial.MetroCcbico;
  unMedMetroQuadrado: enumUnidadeComercial = enumUnidadeComercial.MetroQuadrado;
  unTipoMedidaAltura: number | null = null;
  unTipoMedidaComprimento: number | null = null;
  unTipoMedidaLargura: number | null = null;
  unQuantItem: number | null = null;

  nomeArquivo: string = '';
  subsGerarExtratoPedidoVenda: Subscription = null!;
  Cupom80mm: enumFormatoImpressao = enumFormatoImpressao.Cupom80mm;
  A4: enumFormatoImpressao = enumFormatoImpressao.A4;

  constructor(
    private router: Router,
    private appCustomToastService: AppCustomToastService,
    private showMessageError: ShowMessageError,
    private readonly apiPedidoVendaService: ApiPedidoVendaService,
    private readonly apiEstoqueService: ApiEstoqueService,
    private readonly apiVendaService: ApiVendaService,
    private apiTabelaPrecoService: ApiTabelaPrecoService,
    private apiPdvService: ApiPdvService,
    private readonly apiGeraRelatorioPedidoVendaService: ApiGeraRelatorioPedidoVendaService,
    private route: ActivatedRoute,
    private readonly mensagemService: AppMensagemService,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    ) {
      this.translateService.use("pt");
      this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  ngOnDestroy(): void {
    //this.pauseTimer();
    this.LimparListaProdutosPdv();
  }
  
  ngAfterViewInit(): void {
    this.screenHeight = window.innerHeight;

    if (ConstantsService.IdPdv == 0){
      //neste caso não selecionou pdv, pois veio do retaguarda, sendo assim é obrigatório
      //selecionar
      PdvComponent.modalAtiva = true;
      this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
      return;
    }
    
    if (ConstantsService.SituacaoCaixa != enumSituacaoCaixa.Aberto){
      PdvComponent.modalAtiva = true;
      this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
    }else{

      if (ConstantsService.ParametrizacaoPdv != null && ConstantsService.ParametrizacaoPdv.obrigaFecharCaixaDiario
          && (ConstantsService.DataAberturaCaixa != null &&
            ApiUtilService.getDateString(ConstantsService.DataAberturaCaixa) < ApiUtilService.getDateString(new Date())
            )
            ){
            PdvComponent.modalAtiva = true;
            this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Fechar, true);
      }else{
        //verifica se existe venda em preenchimento para este pdv, se existir, abrir a venda
        //se existir mais de um apresentar lista para selecionar, caso tenha apenas uma, exibir esta.
        //somente se abrir que irá dar focus no input de produto
        if (this.idPedidoVenda != 0){
          this.pesquisarPedidoVenda(this.idPedidoVenda);
        }else{
          PdvComponent.modalAtiva = true;
          this.modalSelecionarClienteComponent.abrirModal(null!, false, false);
        }
      }
    }
    //this.startTimer();
  }

  acaoNenhuma: enumAcaoFuncionalidadePedidoVenda = enumAcaoFuncionalidadePedidoVenda.Nenhuma;
  clickSalvarVenda(exibirMensagem: boolean, acao: enumAcaoFuncionalidadePedidoVenda, produtoSelecionado: ItemPedidoVendaProdutoPdvModel){
    this.salvarPedidoVenda(exibirMensagem, acao, produtoSelecionado);
  }

  // private startTimer() {
  //   this.interval = setInterval(() => {
  //     if (this.inputDados == null) return;
  //     if (this.inputDados.idPedidoVenda == 0) return;
  //     if (this.inputDados.pedidoVendaProdutos.length != this.quantidadeProdutoSalvo)
  //     {
  //       //salva o produto
  //       this.salvarPedidoVendaTemporario();
  //     }
  //   }, 20000)
  // }

  //idPedidoVendaAtiva
  private static constIdPedidoVendaAtiva: string = "a6505d65";
  public static set IdPedidoVendaAtiva(valor: number) {
    localStorage.setItem(this.constIdPedidoVendaAtiva, JSON.stringify(valor));
  }
  public static get IdPedidoVendaAtiva(): number {
    if (localStorage.getItem(this.constIdPedidoVendaAtiva)) {
      return JSON.parse(localStorage.getItem(this.constIdPedidoVendaAtiva)!?.toString()) as number;
    }
    return null!;
  }

  idPedidoVenda: number = 0;
  getParameters() {
    if (this.route.snapshot.params.idPedidoVenda !== undefined) {
      this.idPedidoVenda =  parseInt(this.route.snapshot.params.idPedidoVenda);
    }else{
      if (PedidoVendaCadastroComponent.IdPedidoVendaAtiva != null && PedidoVendaCadastroComponent.IdPedidoVendaAtiva != 0){
        this.idPedidoVenda = PedidoVendaCadastroComponent.IdPedidoVendaAtiva;
      }
    }
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightLista(): string{
    let size: number = this.screenHeight - 220;
    return `${size}px`
  }  

  ngOnInit(): void {    
    this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
    this.getParameters();
    this.consultarTabelaPrecos();
  }  
  
  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      
    }
    if (event.keyCode == 13) {
      //this.pesquisar();
    }
  }

  iniciarNovoPedidoVenda(produtoSelecionado: ProdutoParaPdvModel){
    if (this.inputDados != null && this.inputDados.idPedidoVenda != 0 && this.inputDados.idPedidoVenda != null && this.inputDados.idTabelaStatusPedidoVenda == this.situacaoEmPreenchimento){
      this.salvarPedidoVenda(true, enumAcaoFuncionalidadePedidoVenda.NovoPedido, null!);
    }else{
      this.iniciarPedidoVendaApi(produtoSelecionado);
    }
  }

  subsIniciarVenda: Subscription = null!;
  iniciarPedidoVendaApi(produtoSelecionado){
    this.loading = true;
    this.quantidadeProdutoSalvo = 0;
    this.limparSelecaoProduto(true);
    this.InstanciarObjetoInputDados();
    PedidoVendaCadastroComponent.IdPedidoVendaAtiva = 0;
    this.calcularTotais();
    this.subsIniciarVenda = this.apiPedidoVendaService.post(this.inputDados, true, true).subscribe(
      (retorno: PedidoVendumModel) =>{
        PedidoVendaCadastroComponent.IdPedidoVendaAtiva = retorno.idPedidoVenda;
        if (retorno != undefined) {
          this.tratarRetornoPedidoVenda(retorno);
          
          if (produtoSelecionado != null!)
          {
            this.atualizarVendaProdutoParaListaProdutoPdv();
            this.ListaProdutosPdv = [];
            this.adicionarProduto(produtoSelecionado);
          }else{
            this.router.navigate([`/pedido-venda/alterar/${this.inputDados.idPedidoVenda}`]);
          }
        }else{
          this.inputDados = null!;
          this.limparSelecaoProduto(true);
        }
        this.subsIniciarVenda?.unsubscribe();
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.inputDados = null!;
        this.limparSelecaoProduto(true);
        this.subsIniciarVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  private InstanciarObjetoInputDados() {
    this.inputDados = PedidoVendumModelInstance.get();
    this.inputDados.idTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.EmPreenchimento;
    this.inputDados.idTabelaPreco = ConstantsService.TabelaPreco.idTabelaPreco;
    this.inputDados.idPdv = ConstantsService.IdPdv;
    this.inputDados.idCaixa = ConstantsService.IdCaixa;
    this.ListaProdutosPdv = [];
  }

  cliqueVender(){
    this.loading = true;
    let listaProdutos = this.ListaProdutosPdv;
    let indexItemNaoSalvo = listaProdutos.findIndex(f => f.idPedidoVendaProduto == 0);
    if (indexItemNaoSalvo > -1) 
    {
      this.salvarPedidoVenda(false, enumAcaoFuncionalidadePedidoVenda.Vender, null!);
    }
    else{
      this.venderApi();
    }
  }
  venderApi(){
    if (this.quantidadeProdutoSalvo == this.inputDados.pedidoVendaProdutos.length){
      this.cliqueVenderPasso2();
      return;
    }
    this.loading = true;
     this.quantidadeProdutoSalvo = this.inputDados.pedidoVendaProdutos.length;
     this.calcularTotais();
     this.subsSalvarPedidoVendaTemporario = this.apiPedidoVendaService.post(this.inputDados, true, false).subscribe(
      (retorno: PedidoVendumModel) =>{
        this.subsSalvarPedidoVendaTemporario?.unsubscribe();
        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido salvo'], "Sucesso");
        this.cliqueVenderPasso2();
      }
      ,
      (err) => {
        this.loading = false;
        this.subsSalvarPedidoVendaTemporario?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        //this.pauseTimer();
      });
  }
  
  cliqueVenderPasso2(){
    swal.fire({
      title: "Olá",
      html: `Vamos agora gerar uma venda para este(s) Pedido(s) de Venda?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'question'
    })
      .then((res) => {
        if (res.isConfirmed) {
          this.loading = true;
          this.subsImportarPedidoDeVendaParaPagamento = this.apiVendaService.importarPedidoVendaParaPagamento([this.inputDados.idPedidoVenda], ConstantsService.IdCaixa, ConstantsService.IdPdv, true)?.subscribe(
            (retorno: number) => {
              this.subsImportarPedidoDeVendaParaPagamento?.unsubscribe();
              this.loading = false;
              swal.fire({
                title: `Venda criada! Código [${retorno}]`,
                text: "Vamos realizar o pagamento?",
                icon: 'success',
                showCancelButton: true,
                // confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, Pagar!',
                cancelButtonText: 'Não'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate([`/pdv/produto-pagamento/${retorno}`]);
                }
                if (result.isDismissed) {
                  
                }
              });

            },
            (err) => {
              this.loading = false;
              this.subsImportarPedidoDeVendaParaPagamento?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
          );
        }else{
          this.loading = false;
        }
      });
  }

  blinkIndexRed: number | null = null;
  onPiscar(index: number): void {
    this.blinkIndexRed = index;
    setTimeout(() => {
      this.blinkIndexRed = null;
    }, 1000); // Remove a classe após 1 segundo
  }
  
  adicionarProduto(produtoSelecionadoProdutoParaPdv: ProdutoPesquisaModel) {
    this.unTipoMedidaComprimento = null;
    this.unTipoMedidaLargura = null;
    this.unTipoMedidaAltura = null;
    this.unQuantItem = null;

    if (produtoSelecionadoProdutoParaPdv == null) return;
    let listaProdutosPdv = this.ListaProdutosPdv;

    let numeroItem = 1;
    if (listaProdutosPdv.length > 0){
      numeroItem =  listaProdutosPdv[0].item + 1;
    }

    if (this.inputDados.pedidoVendaProdutos == null) this.inputDados.pedidoVendaProdutos = [];

    //tratar campos quando for metro, metro 3, metro 2
    //se estiver no cadastro do produto para calcular, preencher campos.
    if(produtoSelecionadoProdutoParaPdv.habilitaCalculoUnidadeMedida == enumSimNao.Sim 
      &&
      (produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetro || produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroCubico ||
       produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroQuadrado
      )
    ){
      if(produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetro){
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaComprimento = 1;
      }
      else if(produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroCubico)      
      {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaAltura = 1;
        this.unTipoMedidaComprimento = 1;
        this.unTipoMedidaLargura = 1;
      }
      else if(produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroQuadrado)      
      {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaComprimento = 1;
        this.unTipoMedidaLargura = 1;
      }
    }
    //fim tratamento campos metro

    let itemProdutoPedidoVendaModel: ItemPedidoVendaProdutoPdvModel = {
      idPedidoVendaProduto: 0,
      tipoEanBalanca: produtoSelecionadoProdutoParaPdv.tipoEanBalanca,
      unidadeMedida: produtoSelecionadoProdutoParaPdv.unidadeMedida,
      idPedidoVenda: this.inputDados.idPedidoVenda,
      idProdutoUnico: produtoSelecionadoProdutoParaPdv.idProdutoUnico,
      idProdutoVariacao: produtoSelecionadoProdutoParaPdv.idProdutoVariacao,
      nome: produtoSelecionadoProdutoParaPdv.nome,
      quantidade: produtoSelecionadoProdutoParaPdv.quantidade,
      precoVenda: produtoSelecionadoProdutoParaPdv.precoVenda??0,
      gtin : produtoSelecionadoProdutoParaPdv.gtin,
      sku : produtoSelecionadoProdutoParaPdv.sku,
      item: numeroItem,
      percMaxDesconto: produtoSelecionadoProdutoParaPdv.percMaxDesconto??0,
      quantidadeEntregueNoAto: produtoSelecionadoProdutoParaPdv.quantidade,
      dataPrevistaEntregaAgendada: null,
      dataPrevistaParaEntregaCrossdocking: null,
      indicaValorItemValorTot: enumSimNao.Sim,
      numeroPedidoCompra: '',
      quantidadeParaEntregaAgendada: 0,
      quantidadeParaEntregaCrossdoking: 0,
      valorDesconto: 0,
      valorOutrasDespesas: 0,
      valorTotalFrete: 0,
      precoMargemZero: (produtoSelecionadoProdutoParaPdv.precoMargemZero??0),
      custoUnitario: (produtoSelecionadoProdutoParaPdv.precoMargemZero??0),
      custoTotal: this.pesquisaPorProduto.quantidade * (produtoSelecionadoProdutoParaPdv.precoMargemZero??0),
      valorSeguro: produtoSelecionadoProdutoParaPdv.valorSeguro??0,
      valorTotalSeguro: this.pesquisaPorProduto.quantidade * (produtoSelecionadoProdutoParaPdv.valorSeguro??0),
      subTotal: produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0),
      valorTotal: (produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0)) + (this.pesquisaPorProduto.quantidade * (produtoSelecionadoProdutoParaPdv.valorSeguro??0)),
      observacao: '',
      alterouPrecoUnitario: enumSimNao.Nao,
      precoUnitarioBase: produtoSelecionadoProdutoParaPdv.precoVenda??0,
      tipo: produtoSelecionadoProdutoParaPdv.tipo,
      quantidadeEstoqueCondicional: 0,
      quantidadeEstoqueContabil: 0,
      quantidadeEstoqueReservado: 0,
      quantidadeEstoqueVirtual: 0,
      cpfAlteracao: '',
      cpfRegistro: '',
      dataAlteracao: null,
      dataRegistro: null,
      thumbnailImageSrc: produtoSelecionadoProdutoParaPdv.thumbnailImageSrc,
      habilitaCalculoUnidadeMedida: produtoSelecionadoProdutoParaPdv.habilitaCalculoUnidadeMedida,
      unidadeTipoMedidaAltura: this.unTipoMedidaAltura,
      unidadeTipoMedidaComprimento: this.unTipoMedidaComprimento,
      unidadeTipoMedidaLargura: this.unTipoMedidaLargura,
      unidadeQuantidadeItem: this.unQuantItem,
    };

    listaProdutosPdv.splice(0, 0, itemProdutoPedidoVendaModel);

    this.ListaProdutosPdv = listaProdutosPdv;
    this.calcularTotais();
    this.produtoSelecionado = null!;
    this.pesquisaPorProduto.quantidade = 1;
    this.produtos = [];
    this.salvarPedidoVendaTemporarioSemUpdateNosItens(itemProdutoPedidoVendaModel.idProdutoVariacao);
    this.limparSelecaoProduto(false);
  }
  produtos: Array<ProdutoSelecionado> = [];
  produtoSelecionado: ProdutoSelecionado = null!;

  limparSelecaoProduto(limparLabelSuperior: boolean){
    this.produtoSelecionado = null!;
    this.produtos = [];
  }
  
  consultarTotalEstoque(idProdutoVariacao: number, indexRegistro: number){
    this.subsGetByIdProdutoVariacao = this.apiEstoqueService.getByIdProdutoVariacao(idProdutoVariacao, false).subscribe(
      (retorno: EstoqueModel) =>{
        if (retorno != null){
          let tempListaProdutosPdv = this.ListaProdutosPdv;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueContabil = retorno.quantidadeContabil;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueVirtual = retorno.quantidadeVirtual??0;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueReservado = retorno.quantidadeReservado??0;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueCondicional = retorno.quantidadeCondicional??0;
          this.ListaProdutosPdv = tempListaProdutosPdv;

          if(tempListaProdutosPdv[indexRegistro].quantidadeEstoqueContabil <= 0){
            this.onPiscar(indexRegistro);
          }
        }
        this.subsGetByIdProdutoVariacao?.unsubscribe();
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetByIdProdutoVariacao?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  calcularTotais(){
    this.total = 0;
    this.subTotal = 0;
    this.ListaProdutosPdv.forEach(f => {
      this.subTotal += (f.quantidade * f.precoVenda!);
      this.total += (f.quantidade * f.precoVenda!) - f.valorDesconto! + f.valorTotalFrete! + f.valorOutrasDespesas!
      + (f.quantidade * f.valorSeguro!);
    });
  }

  salvarPedidoVenda(exibirMensagem: boolean, acao: enumAcaoFuncionalidadePedidoVenda, produtoSelecionado: ItemPedidoVendaProdutoPdvModel){
     this.loading = true;
     this.calcularTotais();
     this.atualizarListaProdutoPdvParaVendaProduto();
     this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
     this.subsSalvarPedidoVendaTemporario = this.apiPedidoVendaService.post(this.inputDados, true, false).subscribe(
      (retorno: PedidoVendumModel) =>{
        
        this.subsSalvarPedidoVendaTemporario?.unsubscribe();
        if (exibirMensagem){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido de Venda salvo'], "Sucesso");
        }
        
        if (this.inputDados.idPedidoVenda == 0){
          this.router.navigate([`/pedido-venda/alterar/${retorno.idPedidoVenda}`]);
          return;
        }
        if (retorno != undefined) {
          this.tratarRetornoPedidoVenda(retorno);
          this.atualizarVendaProdutoParaListaProdutoPdv();
        
          switch(acao){
            case enumAcaoFuncionalidadePedidoVenda.AtualizarTabelaPreco:
              this.alterarTabelaPrecoNaVendaApi(this.tabelaPrecoSelecionada);
              break;
            case enumAcaoFuncionalidadePedidoVenda.NovoPedido:
              this.iniciarPedidoVendaApi(produtoSelecionado);
              break;
            case enumAcaoFuncionalidadePedidoVenda.Nenhuma:
              this.loading = false;
              break;
            case enumAcaoFuncionalidadePedidoVenda.FinaliarPedidoVenda:
              this.finalizarPedidoVendaApi();
              break;
            case enumAcaoFuncionalidadePedidoVenda.Imprimir:
              this.loading = false;
              //this.modalFormatoImpressaoComponent.abrirModal(this.inputDados.idPedidoVenda);
              this.imprimir(this.inputDados.idPedidoVenda);
              break;
            case enumAcaoFuncionalidadePedidoVenda.Vender:
                this.venderApi();
                break;
          }
        }
      }
      ,
      (err) => {
        this.loading = false;
        this.subsSalvarPedidoVendaTemporario?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        //this.pauseTimer();
      });
  }

  salvarPedidoVendaTemporario(){
    if (this.loading) return;

    if (this.inputDados.pedidoVendaProdutos.length > this.quantidadeProdutoSalvo + 10){
        this.loading = true;
        this.quantidadeProdutoSalvo = this.inputDados.pedidoVendaProdutos.length;
        this.calcularTotais();
        this.atualizarListaProdutoPdvParaVendaProduto();
        this.subsSalvarPedidoVendaTemporario = this.apiPedidoVendaService.post(this.inputDados, true, false).subscribe(
          (retorno: PedidoVendumModel) =>{
            this.subsSalvarPedidoVendaTemporario?.unsubscribe();
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido de venda salvo'], "Sucesso");
            if (retorno != undefined) {
                this.tratarRetornoPedidoVenda(retorno);
                this.atualizarVendaProdutoParaListaProdutoPdv();
            }
            this.loading = false;
          }
          ,
          (err) => {
            this.loading = false;
            this.subsSalvarPedidoVendaTemporario?.unsubscribe();
            this.showMessageError.exibirMensagemValidacaoTela(err);
            //this.pauseTimer();
          });
    }
  }

  salvarPedidoVendaTemporarioSemUpdateNosItens(idProdutoVariacao: number){
    if (this.loading) return;
    this.loading = true;
    this.quantidadeProdutoSalvo = this.inputDados.pedidoVendaProdutos.length;
    this.calcularTotais();
    this.atualizarListaProdutoPdvParaVendaProduto();
    this.subsSalvarPedidoVendaTemporario = this.apiPedidoVendaService.post(this.inputDados, false, false).subscribe(
      (retorno: PedidoVendumModel) =>{
        this.subsSalvarPedidoVendaTemporario?.unsubscribe();
        //this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido de venda salvo'], "Sucesso");
        if (retorno != undefined) {
            this.tratarRetornoPedidoVenda(retorno);
            this.atualizarVendaProdutoParaListaProdutoPdv();
            this.consultarTotalEstoque(idProdutoVariacao, 0);
        }
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.subsSalvarPedidoVendaTemporario?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        //this.pauseTimer();
      });
  }

  // private pauseTimer() {
  //   clearInterval(this.interval);
  // }
  // interval;

  iniciar(item: ProdutoPesquisaModel){
    this.iniciarNovoPedidoVenda(item);
  }

  getFormatDecimal(number: number): string{
    if (number == null) return '';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }
  
  cancelarPedidoVenda(){
    swal.fire({
      title: "Alerta",
      html: `Tem certeza que deseja cancelar o pedido: <b>${this.inputDados.idPedidoVenda}</b>?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        if (res.isConfirmed) {
          this.loading = true;
          this.subsCancelarPedidoVendaProduto = this.apiPedidoVendaService.cancelarPedidoVenda(this.inputDados, true)?.subscribe(
            () => {
              this.loading = false;
              this.subsCancelarPedidoVendaProduto?.unsubscribe();
              this.showMessageError.limparMensagensAlerta();
              this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido de venda cancelado'], "Sucesso");
              PedidoVendaCadastroComponent.IdPedidoVendaAtiva = 0;
              this.idPedidoVenda = 0;
              this.inputDados = null!;
              PdvComponent.modalAtiva = true;
              this.modalSelecionarClienteComponent.abrirModal(null!, false, false);
            },
            (err) => {
              this.loading = false;
              this.subsCancelarPedidoVendaProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
          );
        }
      });
  }
 
  getProdutoVariacal(item: ProdutoVariacaoModel){
    if (item.idProdutoUnicoNavigation.tipoProduto == enumTipoProduto.Variacao){
      return `${item.idProdutoUnicoNavigation.nome} - ${item.nomeVariacao}`
    }
    return `${item.idProdutoUnicoNavigation.nome}`;
  }

  getTipoServico(tipo: string): string{
    if (tipo == enumTipoProduto.Servico) return "Serviço";
    return "Produto";
  }
  
  tratarRetornoPedidoVenda(retorno: PedidoVendumModel){
    this.inputDados = retorno;   
    this.quantidadeProdutoSalvo = this.inputDados.pedidoVendaProdutos.length;
    if (this.inputDados.idTabelaStatusPedidoVenda == null || this.inputDados.idTabelaStatusPedidoVenda == 0){
      this.inputDados.idTabelaStatusPedidoVenda = 1;
    }
    this.inputDados.idPdv = ConstantsService.IdPdv;
    this.inputDados.idCaixa = ConstantsService.IdCaixa;
  }

  pesquisarPedidoVenda(idPedidoVenda: number){    
    this.loading = true;
    this.subsGetPesquisar = this.apiPedidoVendaService.getById(idPedidoVenda, true).subscribe(
      (retorno: PedidoVendumModel) =>{
        this.loading = false;   
        if (retorno != undefined) {
         this.tratarRetornoPedidoVenda(retorno);
         this.limparSelecaoProduto(true);
         this.atualizarVendaProdutoParaListaProdutoPdv(); 
         this.calcularTotais();
        }
        this.loading = false;
        this.subsGetPesquisar?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetPesquisar?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  informacoesAdicionais(){
    PdvComponent.modalAtiva = true;
    this.modalInformacoesAdicionaisComponent.abrirModal(this.inputDados);
  }

  adicionarCliente() {
    this.autofocus = false;
    PdvComponent.modalAtiva = true;
    this.modalSelecionarClienteComponent.abrirModal(this.inputDados.cpfCnpjNavigation, false, false);
  }

  selecionouCliente(param: PessoaFisicaJuridicaModel){
    PdvComponent.modalAtiva = false;
    if(this.inputDados == null || this.inputDados == undefined){
      this.InstanciarObjetoInputDados();
    }
    if (param != null){
      this.inputDados.cpfCnpjNavigation = param;
      this.inputDados.cpfCnpj = param.cpfCnpj;
      this.salvarPedidoVenda(true, enumAcaoFuncionalidadePedidoVenda.Nenhuma, null!);
    }else{
      this.inputDados.cpfCnpjNavigation = param;
      this.inputDados.cpfCnpj = null!;
      this.salvarPedidoVenda(true, enumAcaoFuncionalidadePedidoVenda.Nenhuma, null!);
    }
  }

  voltar(){
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/pedido-venda'])
    );
    window.open(url, '_self');
  }

  salvouAlteracaoCliente(param: PedidoVendumModel){
  }

  salvouAlteracao(param: PedidoVendumModel){
    PdvComponent.modalAtiva = false;
    this.inputDados = param;
    this.salvarPedidoVenda(true, enumAcaoFuncionalidadePedidoVenda.Nenhuma, null!);
  }

  exibirInformacoesCliente(){
    PdvComponent.modalAtiva = true;
    this.modalSituacaoClienteComponent.abrirModal(this.inputDados.cpfCnpj);    
  }
  
  alterouItensDePedidoVenda(item: PedidoVendaProdutoModel){
    PdvComponent.modalAtiva = false;
    this.inputDados.pedidoVendaProdutos[this.indexItemSelecionado] = item;
    this.atualizarItemVendaProdutoParaListaProdutoPdv(item);
    this.salvarPedidoVenda(true, enumAcaoFuncionalidadePedidoVenda.Nenhuma, null!);
  }

  onRowSelectItemPedidoVenda(item: any) {
    this.alterarItem(item.data, item.index);
  }

  exibirEstoque(produtoSelecionadoProdutoParaPdv: ItemPedidoVendaProdutoPdvModel, index: number){
    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;
    this.atualizarListaProdutoPdvParaVendaProduto();
    let rowIndex = this.inputDados.pedidoVendaProdutos.findIndex(f => f.numeroItem == produtoSelecionadoProdutoParaPdv.item);
    let pedidoVendaProduto = this.inputDados.pedidoVendaProdutos[rowIndex];
    this.modalPedidoVendaEstoqueComponent.abrirModal(pedidoVendaProduto);
  }
  

  alterarItem(produtoSelecionadoProdutoParaPdv: ItemPedidoVendaProdutoPdvModel, index: number){
    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;

    this.atualizarListaProdutoPdvParaVendaProduto();
    let rowIndex = this.inputDados.pedidoVendaProdutos.findIndex(f => f.numeroItem == produtoSelecionadoProdutoParaPdv.item);
    let itemProdutoPedidoVendaModel = this.inputDados.pedidoVendaProdutos[rowIndex];

    
    this.modalAlterarItemPedidoVendaComponent.abrirModal(itemProdutoPedidoVendaModel, this.inputDados.idTabelaStatusPedidoVenda!);
  }

  removerItem(produtoSelecionadoProdutoParaPdv: ItemPedidoVendaProdutoPdvModel, index: number){

    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;
    this.atualizarListaProdutoPdvParaVendaProduto();
    let rowIndex = this.inputDados.pedidoVendaProdutos.findIndex(f => f.numeroItem == produtoSelecionadoProdutoParaPdv.item);
    let pedidoVendaProduto = this.inputDados.pedidoVendaProdutos[rowIndex];

    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;
    this.removerItemPedido(pedidoVendaProduto);
  }
  
  removerItemPedido(itemSelecionado: PedidoVendaProdutoModel) {
    swal.fire({
      title: "Alerta",
      html: `Tem certeza que deseja excluir este item [${itemSelecionado.detalhe}]?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        if (res.isConfirmed) {
          this.loading = true;
          this.subsRemoverItemPedidoVendaProduto = this.apiPedidoVendaService.removerItemPedidoVendaProduto(itemSelecionado, true)?.subscribe(
            () => {
              this.loading = false;
              this.subsRemoverItemPedidoVendaProduto?.unsubscribe();
              this.showMessageError.limparMensagensAlerta();
              let tempListaProdutosPdv = this.ListaProdutosPdv;
              tempListaProdutosPdv.splice(this.indexItemSelecionado, 1);
              this.ListaProdutosPdv = tempListaProdutosPdv;
              this.calcularTotais();
            },
            (err) => {
              this.loading = false;
              this.subsRemoverItemPedidoVendaProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
          );
        }
      });
  }

  fechouModal(event:any){
    PdvComponent.modalAtiva = false;
  }
  recarregarSimulacaoPagamento(idPedidoVendaSimulacaoPagamento: number){
    this.router.navigate([`/pedidovenda/escolher-pagamento/${this.inputDados.idPedidoVenda}/${idPedidoVendaSimulacaoPagamento}`]);
  }

  onAbriuModalConfiguracao(event:any){
    PdvComponent.modalAtiva = true;
  }
  onFechouModalAlteracao(event:any){
    PdvComponent.modalAtiva = false;
  }
   
  formatoImpressao: enumFormatoImpressao = null!;
  cliqueImprimir(formatoImpressao: enumFormatoImpressao){
    this.loading = true;
    this.formatoImpressao = formatoImpressao;
    let listaProdutos = this.ListaProdutosPdv;
    let indexItemNaoSalvo = listaProdutos.findIndex(f => f.idPedidoVendaProduto == 0);

    if (indexItemNaoSalvo > -1) 
    {
      this.salvarPedidoVenda(false, enumAcaoFuncionalidadePedidoVenda.Imprimir, null!);
    }
    else{
      //this.loading = false;
      //this.modalFormatoImpressaoComponent.abrirModal(this.inputDados.idPedidoVenda);
      this.imprimir(this.inputDados.idPedidoVenda);
    }
  }

  imprimir(idPedidoVenda: number){
    this.loading = true;
    let parametrosImpressao: CustomFormatoImpressao = {
      id: idPedidoVenda,
      layoutImpressao: this.formatoImpressao,
      tipoComanda: ''
    };
    var dataAtual = new Date;
    this.nomeArquivo = `extrato-pedido-venda-${idPedidoVenda}-${dataAtual.valueOf()}.pdf`;
    this.mensagemService.exibirLock();
    this.subsGerarExtratoPedidoVenda = this.apiGeraRelatorioPedidoVendaService.imprimirExtratoPedidoVenda(parametrosImpressao, true)
      .subscribe((fileData: any) => {
        this.subsGerarExtratoPedidoVenda?.unsubscribe();
        // Agora o pai pode chamar uma função no componente filho
        this.modalImpressaoComponent.imprimir("08", fileData, 'application/pdf');
        //
        //  const blob: any = new Blob([fileData], { type: 'application/pdf' });
        //  var pdfUrl = URL.createObjectURL(blob);
        //  printJs(pdfUrl);
        this.loading = false;
        this.mensagemService.esconderLock();
      },
      (err) => {
         this.subsGerarExtratoPedidoVenda?.unsubscribe();
         this.showMessageError.exibirMensagemValidacaoTela(err);
         this.mensagemService.esconderLock();
         this.loading = false;
      }
    );
  }

  cliqueFinalizarPedidoVendaApi(){
    this.loading = true;
    let listaProdutos = this.ListaProdutosPdv;
    let indexItemNaoSalvo = listaProdutos.findIndex(f => f.idPedidoVendaProduto == 0);
    if (indexItemNaoSalvo > -1) 
    {
      this.salvarPedidoVenda(false, enumAcaoFuncionalidadePedidoVenda.FinaliarPedidoVenda, null!);
    }
    else{
      this.finalizarPedidoVendaApi();
    }
  }

  finalizarPedidoVendaApi(){   
    this.parametrosSalvarPedidoVenda.pedidoVendumModel = this.inputDados;
    this.parametrosSalvarPedidoVenda.permiteEstoqueVirtual = ConstantsService.PermiteEstoqueVirtual;
    this.parametrosSalvarPedidoVenda.permiteEstoqueCondicional = ConstantsService.PermiteEstoqueCondicional;
    this.parametrosSalvarPedidoVenda.permiteEstoqueNegativo = ConstantsService.PermiteEstoqueNegativo;
    this.parametrosSalvarPedidoVenda.permiteEstoqueReservado = ConstantsService.PermiteEstoqueReservado;
    this.parametrosSalvarPedidoVenda.permiteEstoqueCrossDocking = ConstantsService.PermiteEstoqueCrossDocking;
    this.parametrosSalvarPedidoVenda.movEstContabilPedidoVenda = ConstantsService.MovEstContabilPedidoVenda;
    this.parametrosSalvarPedidoVenda.movEstVirtualPedidoVenda = ConstantsService.MovEstVirtualPedidoVenda;
    this.subsFinalizarPedidoVenda = this.apiPedidoVendaService.finalizarPedidoVenda(this.parametrosSalvarPedidoVenda, false).subscribe(
     (retorno: PedidoVendumModel) =>{
       this.subsFinalizarPedidoVenda?.unsubscribe();
       this.loading = false;
       this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido de venda finalizado'], "Sucesso");
       if (retorno != undefined) {
           this.tratarRetornoPedidoVenda(retorno);
       }
     }
     ,
     (err) => {
       this.loading = false;
       this.subsFinalizarPedidoVenda?.unsubscribe();
       this.showMessageError.exibirMensagemValidacaoTela(err);
       //this.pauseTimer();
     });
  }

   /*gestão preço a aprazo e a vista*/
   tabelaPrecoSelecionada: TabelaPrecoModel = ConstantsService.TabelaPreco;
   alterarTabelaPrecoNaVenda(item: TabelaPrecoModel){
     this.tabelaPrecoSelecionada = item;
     if (ConstantsService.TabelaPreco == null || item.idTabelaPreco == ConstantsService.TabelaPreco.idTabelaPreco) return;
 
     //let listaProdutos = this.ListaProdutosPdv;
     let listaProdutos = this.ListaProdutosPdv;
     let indexItemNaoSalvo = listaProdutos.findIndex(f => f.idPedidoVendaProduto == 0);
 
     if (indexItemNaoSalvo > -1) //existe item não salvo, neste caso salva antes de alterar a tabela de preço
     {
       this.salvarPedidoVenda(false, enumAcaoFuncionalidadePedidoVenda.AtualizarTabelaPreco, null!);
     }
     else{
       this.alterarTabelaPrecoNaVendaApi(this.tabelaPrecoSelecionada);
     }
   }
 
  alterarTabelaPrecoNaVendaApi(item: TabelaPrecoModel){
     this.loading = true;
     this.subGetAllTabelaPreco = this.apiPdvService.alterarTabelaPrecoNoPedidoVenda(this.inputDados.idPedidoVenda, item.idTabelaPreco, true).subscribe(
       (retorno: PedidoVendumModel) =>{
         if (retorno != undefined) {
           this.inputDados = retorno;
           //this.limparSelecaoProduto(true);
           this.atualizarVendaProdutoParaListaProdutoPdv();
           this.quantidadeProdutoSalvo = this.inputDados.pedidoVendaProdutos.length;
           this.calcularTotais();
           ConstantsService.TabelaPreco = item;
           this.atualizarTabelaPreco.emit(item);
           this.loading = false;
         }
         this.subGetAllTabelaPreco?.unsubscribe();
       }
       ,
       (err) => {
         this.loading = false;
         this.subGetAllTabelaPreco?.unsubscribe();
         this.showMessageError.exibirMensagemValidacaoTela(err);
       });
  }

  subGetAllTabelaPreco: Subscription = null!;
  ListaTabelaDePreco: TabelaPrecoModel[] = [];
  consultarTabelaPrecos() {
    this.ListaTabelaDePreco = ConstantsService.ListaTabelaPreco;
    if (this.ListaTabelaDePreco != null && this.ListaTabelaDePreco.length > 0) return;

    this.loading = true;
    this.subGetAllTabelaPreco = this.apiTabelaPrecoService.getAllByStatus(enumStatus.Ativo, false).subscribe(
      (retorno: Array<TabelaPrecoModel>) =>{
        if (retorno != undefined) {
          ConstantsService.ListaTabelaPreco = retorno;
          this.ListaTabelaDePreco = retorno;
          this.loading = false;
        }
        this.subGetAllTabelaPreco?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAllTabelaPreco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  /*ajustes manter itens salvo na tela*/
  LimparListaProdutosPdv(){
    localStorage.removeItem(`${this.constListaProdutosPdv}_${this.inputDados.idPedidoVenda}`);  
  }

  constListaProdutosPdv: string = "as5d405c1d5";
  set ListaProdutosPdv(valor: ItemPedidoVendaProdutoPdvModel[]) {
    localStorage.setItem(`${this.constListaProdutosPdv}_${this.inputDados.idPedidoVenda}`, JSON.stringify(valor));  
    this._listaProdutosPdv = valor;
  }
  _listaProdutosPdv: ItemPedidoVendaProdutoPdvModel[] = [];
  get ListaProdutosPdv(): ItemPedidoVendaProdutoPdvModel[] {
    if (this.inputDados == null) 
    {
      this._listaProdutosPdv = [];
      return [];
    }
    if (this.inputDados.idPedidoVenda == 0) 
    {
      this._listaProdutosPdv = [];
      return [];
    }
    if (this._listaProdutosPdv != null && this._listaProdutosPdv.length > 0){
      return this._listaProdutosPdv;
    }else{
      let temp = localStorage.getItem(`${this.constListaProdutosPdv}_${this.inputDados.idPedidoVenda}`);
      if (temp != '' && temp != null){
        this._listaProdutosPdv = JSON.parse(temp) as ItemPedidoVendaProdutoPdvModel[];
        return this._listaProdutosPdv;
      }
    }
    this._listaProdutosPdv = [];
    return [];
  }
  
  atualizarListaProdutoPdvParaVendaProduto(){
    this.inputDados.pedidoVendaProdutos = [];
    let listaProdutos = this.ListaProdutosPdv;
    listaProdutos.forEach(f => {
      let pedidoVendaProduto = PedidoVendaProdutoModelInstance.get();
      pedidoVendaProduto.idPedidoVenda = this.inputDados.idPedidoVenda;
      pedidoVendaProduto.idPedidoVendaProduto = f.idPedidoVendaProduto;
      pedidoVendaProduto.idProdutoUnico = f.idProdutoUnico;
      pedidoVendaProduto.idProdutoVariacao = f.idProdutoVariacao;
      pedidoVendaProduto.detalhe = f.nome;
      pedidoVendaProduto.precoUnitario = f.precoVenda!;
      pedidoVendaProduto.numeroItem = f.item;
      pedidoVendaProduto.quantidade = f.quantidade;
      pedidoVendaProduto.valorProdutoServico = f.subTotal;
      pedidoVendaProduto.dataPrevistaEntregaAgendada = f.dataPrevistaEntregaAgendada;
      pedidoVendaProduto.dataPrevistaParaEntregaCrossdoking = f.dataPrevistaParaEntregaCrossdocking;
      pedidoVendaProduto.indicaValorItemValorTot = f.indicaValorItemValorTot;
      pedidoVendaProduto.numeroPedidoCompra = f.numeroPedidoCompra;
      pedidoVendaProduto.quantidadeEntregueNoAto = f.quantidadeEntregueNoAto;
      pedidoVendaProduto.quantidadeParaEntregaAgendada = f.quantidadeParaEntregaAgendada;
      pedidoVendaProduto.quantidadeParaEntregaCrossdoking = f.quantidadeParaEntregaCrossdoking!;
      pedidoVendaProduto.valorCustoUnitario = f.precoMargemZero!;
      pedidoVendaProduto.valorCustoTotal = f.quantidade * f.precoMargemZero!;
      pedidoVendaProduto.valorTotal = f.valorTotal;
      pedidoVendaProduto.valorDesconto = f.valorDesconto;
      pedidoVendaProduto.valorOutrasDespesas = f.valorOutrasDespesas;
      pedidoVendaProduto.valorTotalFrete = f.valorTotalFrete;
      pedidoVendaProduto.unidadeMedidaComercial = f.unidadeMedida;
      pedidoVendaProduto.valorSeguro = f.valorSeguro!;
      pedidoVendaProduto.valorTotalSeguro = f.valorTotalSeguro!;
      pedidoVendaProduto.detalheImpressao = f.observacao;
      pedidoVendaProduto.precoUnitarioBase = f.precoUnitarioBase;
      pedidoVendaProduto.alterouPrecoUnitario = f.alterouPrecoUnitario;
      pedidoVendaProduto.unidadeTipoMedidaComprimento = f.unidadeTipoMedidaComprimento;
      pedidoVendaProduto.unidadeTipoMedidaLargura = f.unidadeTipoMedidaLargura;
      pedidoVendaProduto.unidadeTipoMedidaAltura = f.unidadeTipoMedidaAltura;
      pedidoVendaProduto.unidadeQuantidadeItem = f.unidadeQuantidadeItem;

      pedidoVendaProduto.idProduto = ProdutoVariacaoModelInstance.get();
      pedidoVendaProduto.idProduto.idProdutoUnico = f.idProdutoUnico;
      pedidoVendaProduto.idProduto.idProdutoVariacao = f.idProdutoVariacao;
      pedidoVendaProduto.idProduto.estoques = [];
      pedidoVendaProduto.idProduto.estoques.push(EstoqueModelInstance.get());
      pedidoVendaProduto.idProduto.estoques[0].quantidadeContabil = f.quantidadeEstoqueContabil;
      pedidoVendaProduto.idProduto.estoques[0].quantidadeVirtual = f.quantidadeEstoqueVirtual;
      pedidoVendaProduto.idProduto.estoques[0].quantidadeReservado = f.quantidadeEstoqueReservado;
      pedidoVendaProduto.idProduto.estoques[0].quantidadeCondicional = f.quantidadeEstoqueCondicional;
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation = ProdutoModelInstance.get();
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.precos = [];
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.precos.push(PrecoModelInstance.get());
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.precos[0].precoVenda = f.precoVenda;
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.precos[0].precoMargemZero = f.precoMargemZero;
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.nome = f.nome;
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.gtin = f.gtin;
      pedidoVendaProduto.idProduto.idProdutoUnicoNavigation.sku = f.sku;

      pedidoVendaProduto.idProdutoUnicoNavigation = ProdutoModelInstance.get();
      pedidoVendaProduto.idProdutoUnicoNavigation.tipoProduto = f.tipo;
      pedidoVendaProduto.idProdutoUnicoNavigation.percMaxDesconto = f.percMaxDesconto!;
      pedidoVendaProduto.idProdutoUnicoNavigation.nome = f.nome;
      pedidoVendaProduto.idProdutoUnicoNavigation.gtin = f.gtin;
      pedidoVendaProduto.idProdutoUnicoNavigation.sku = f.sku;
      pedidoVendaProduto.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida = f.habilitaCalculoUnidadeMedida;
      pedidoVendaProduto.idProdutoUnicoNavigation.unidadeMedidaComercial = f.unidadeMedida;

      this.inputDados.pedidoVendaProdutos.push(pedidoVendaProduto);
    });
  }

  atualizarItemVendaProdutoParaListaProdutoPdv(item: PedidoVendaProdutoModel) {

    let produtoPdv: ItemPedidoVendaProdutoPdvModel = {
      idPedidoVenda : item.idPedidoVenda!,
      idPedidoVendaProduto : item.idPedidoVendaProduto,
      idProdutoUnico : item.idProdutoUnico,
      idProdutoVariacao : item.idProdutoVariacao!,
      nome : item.detalhe,
      precoVenda : item.precoUnitario,
      item : item.numeroItem!,
      tipoEanBalanca: item.idProdutoUnicoNavigation.tipoEanBalanca,
      unidadeMedida: item.idProdutoUnicoNavigation.unidadeMedidaComercial,
      quantidade : item.quantidade!,
      subTotal : item.valorProdutoServico!,
      valorTotal : item.valorTotal!,
      gtin: item.idProdutoUnicoNavigation.gtin,
      sku: item.idProdutoUnicoNavigation.sku,
      percMaxDesconto: null,
      dataPrevistaEntregaAgendada: item.dataPrevistaEntregaAgendada,
      dataPrevistaParaEntregaCrossdocking: item.dataPrevistaParaEntregaCrossdoking,
      indicaValorItemValorTot: item.indicaValorItemValorTot,
      numeroPedidoCompra: item.numeroPedidoCompra,
      quantidadeEntregueNoAto: item.quantidadeEntregueNoAto,
      quantidadeParaEntregaAgendada: item.quantidadeParaEntregaAgendada,
      quantidadeParaEntregaCrossdoking: item.quantidadeParaEntregaCrossdoking!,
      valorDesconto: item.valorDesconto,
      valorOutrasDespesas: item.valorOutrasDespesas,
      valorTotalFrete: item.valorTotalFrete,
      precoMargemZero: item.valorCustoUnitario,
      custoUnitario: item.valorCustoUnitario,
      custoTotal: item.quantidade! * item.valorCustoUnitario!,
      valorSeguro: item.valorSeguro!,
      valorTotalSeguro: item.valorTotalSeguro!,
      observacao: item.detalheImpressao,
      alterouPrecoUnitario: item.alterouPrecoUnitario,
      precoUnitarioBase: item.precoUnitarioBase,
      tipo: item.idProdutoUnicoNavigation.tipoProduto,
      quantidadeEstoqueContabil: 0,
      quantidadeEstoqueVirtual: 0,
      quantidadeEstoqueReservado: 0,
      quantidadeEstoqueCondicional: 0,
      cpfAlteracao: item.cpfAlteracao,
      cpfRegistro: item.cpfRegistro,
      dataAlteracao: item.dataAlteracao,
      dataRegistro: item.dataRegistro,
      thumbnailImageSrc: '',
      habilitaCalculoUnidadeMedida: item.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida,
      unidadeTipoMedidaAltura: item.unidadeTipoMedidaAltura,
      unidadeTipoMedidaComprimento: item.unidadeTipoMedidaComprimento,
      unidadeTipoMedidaLargura: item.unidadeTipoMedidaLargura,
      unidadeQuantidadeItem: item.unidadeQuantidadeItem,
    }
    if (item.idProduto?.estoques != null && item.idProduto?.estoques.length > 0) {
      produtoPdv.quantidadeEstoqueContabil = item.idProduto?.estoques[0].quantidadeContabil ?? 0;
      produtoPdv.quantidadeEstoqueVirtual = item.idProduto?.estoques[0].quantidadeVirtual ?? 0;
      produtoPdv.quantidadeEstoqueReservado = item.idProduto?.estoques[0].quantidadeReservado ?? 0;
      produtoPdv.quantidadeEstoqueCondicional = item.idProduto?.estoques[0].quantidadeCondicional ?? 0;
    }
    let index = this.ListaProdutosPdv.findIndex(f => f.item == item.numeroItem);
    if (index != -1) {
      let tempListaProduto = this.ListaProdutosPdv;
      tempListaProduto[index] = produtoPdv;
      this.ListaProdutosPdv = tempListaProduto;
    }
  }

  atualizarVendaProdutoParaListaProdutoPdv(){
    let listaProdutosPdv: ItemPedidoVendaProdutoPdvModel[] = [];
    this.inputDados.pedidoVendaProdutos.sort((a,b) => b.numeroItem! - a.numeroItem!).filter(f => f.situacao == enumSituacaoVendaProduto.Ativo).forEach(f => {
      let produtoPdv: ItemPedidoVendaProdutoPdvModel = {
        idPedidoVenda : f.idPedidoVenda!,
        idPedidoVendaProduto : f.idPedidoVendaProduto,
        idProdutoUnico : f.idProdutoUnico,
        idProdutoVariacao : f.idProdutoVariacao!,
        nome : f.detalhe,
        precoVenda : f.precoUnitario,
        item : f.numeroItem!,
        tipoEanBalanca: f.idProdutoUnicoNavigation.tipoEanBalanca,
        unidadeMedida: f.idProdutoUnicoNavigation.unidadeMedidaComercial,
        quantidade : f.quantidade!,
        subTotal : f.valorProdutoServico!,
        valorTotal : f.valorTotal!,
        gtin: f.idProdutoUnicoNavigation.gtin,
        sku: f.idProdutoUnicoNavigation.sku,
        percMaxDesconto: null,
        dataPrevistaEntregaAgendada: f.dataPrevistaEntregaAgendada,
        dataPrevistaParaEntregaCrossdocking: f.dataPrevistaParaEntregaCrossdoking,
        indicaValorItemValorTot: f.indicaValorItemValorTot,
        numeroPedidoCompra: f.numeroPedidoCompra,
        quantidadeEntregueNoAto: f.quantidadeEntregueNoAto,
        quantidadeParaEntregaAgendada: f.quantidadeParaEntregaAgendada,
        quantidadeParaEntregaCrossdoking: f.quantidadeParaEntregaCrossdoking!,
        valorDesconto: f.valorDesconto,
        valorOutrasDespesas: f.valorOutrasDespesas,
        valorTotalFrete: f.valorTotalFrete,
        precoMargemZero: f.valorCustoUnitario,
        custoUnitario: f.valorCustoUnitario,
        custoTotal: f.quantidade! * f.valorCustoUnitario!,
        valorSeguro: f.valorSeguro!,
        valorTotalSeguro: f.valorTotalSeguro!,
        observacao: f.detalheImpressao,
        alterouPrecoUnitario: f.alterouPrecoUnitario,
        precoUnitarioBase: f.precoUnitarioBase,
        tipo: f.idProdutoUnicoNavigation.tipoProduto,
        quantidadeEstoqueContabil: 0,
        quantidadeEstoqueVirtual: 0,
        quantidadeEstoqueReservado: 0,
        quantidadeEstoqueCondicional: 0,
        cpfAlteracao: f.cpfAlteracao,
        cpfRegistro: f.cpfRegistro,
        dataAlteracao: f.dataAlteracao,
        dataRegistro: f.dataRegistro,
        thumbnailImageSrc: '',
        habilitaCalculoUnidadeMedida: f.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida,
        unidadeTipoMedidaAltura: f.unidadeTipoMedidaAltura,
        unidadeTipoMedidaComprimento: f.unidadeTipoMedidaComprimento,
        unidadeTipoMedidaLargura: f.unidadeTipoMedidaLargura,
        unidadeQuantidadeItem: f.unidadeQuantidadeItem,
      }
      if (f.idProduto?.estoques != null && f.idProduto?.estoques.length > 0){
        produtoPdv.quantidadeEstoqueContabil = f.idProduto?.estoques[0].quantidadeContabil??0;
        produtoPdv.quantidadeEstoqueVirtual = f.idProduto?.estoques[0].quantidadeVirtual??0;
        produtoPdv.quantidadeEstoqueReservado = f.idProduto?.estoques[0].quantidadeReservado??0;
        produtoPdv.quantidadeEstoqueCondicional = f.idProduto?.estoques[0].quantidadeCondicional??0;
      } 
      
      listaProdutosPdv.push(produtoPdv);
    });
    
    let tempItensSemSerSalvo = this.ListaProdutosPdv.filter(f => f.idPedidoVendaProduto == 0
      && f.idPedidoVenda == this.idPedidoVenda);
    
    let itensSemSerSalvo: boolean = false;
    if (tempItensSemSerSalvo != null && tempItensSemSerSalvo.length > 0){
      tempItensSemSerSalvo.forEach(f => {
        if (listaProdutosPdv.findIndex(fi => fi.item == f.item) == -1){
          listaProdutosPdv.push(f);
          itensSemSerSalvo = true;
        }
      });
    }
    this.ListaProdutosPdv = listaProdutosPdv.sort(s => s.item);
    if (itensSemSerSalvo){
      this.salvarPedidoVenda(false, enumAcaoFuncionalidadePedidoVenda.Nenhuma, null!);
    }
  }

  abriuFechouCaixa(caixaControle: CaixaControleModel){
    PdvComponent.modalAtiva = false;
    if (caixaControle != null){
      this.atualizarTextoCabecalhoSituacaoCaixa.emit(caixaControle);
    }else{
      if (ConstantsService.SituacaoCaixa == enumSituacaoCaixa.Fechado || ConstantsService.SituacaoCaixa == null) 
      this.cancelouSelecaoCaixa();
    }
  }

  cancelouSelecaoCaixa(){
    swal.fire({
      title: "Alerta",
      html: 'É obrigatório abrir um caixa para realizar vendas no PDV',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
          this.navegarPaginaMenuPdv();
      }
    });
  }

  navegarPaginaMenuPdv() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/menu/default'])
    );
    window.open(url, '_self');
  }
}