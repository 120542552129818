<div class="row p-2 div-pesquisa-por-produto">
    <div class="col-1  inline" *ngIf="habilitaProdutoServico == 'T'">
        <p-selectButton [options]="tiposSelecao" [(ngModel)]="tipoSelecionado" optionLabel="icon" styleClass="icon-button-tipo"
        (onChange)="onChangeTipoSelecao($event)"
        >
            <ng-template let-item>
                <span class="p-1 icon-span-button-tipo " [title]="item.toolTip">
                    <i [class]="item.icon" ></i>

                </span>
            </ng-template>
        </p-selectButton>
        <!-- {{tipoSelecionado.value}}
        <div class="form-check">
            <input class="form-check-input" type="radio" name="radTipoProduto" id="radProduto" (click)="selecionarTipo(tipoProduto)" 
            [checked]="habilitaRadTipoProduto">
            <label class="form-check-label mouse-pointer" for="radProduto">
                Produto
            </label>
        </div>
        <div class="form-check">
            <input class="form-check-input" type="radio" name="radTipoProduto" id="radServico" (click)="selecionarTipo(tipoServico)" 
            [checked]="habilitaRadTipoServico">
            <label class="form-check-label mouse-pointer" for="radServico">
                Serviço
            </label>
        </div> -->
    </div>
    <div class="col-4 ">
        <span class="p-fluid " *ngIf="tipoProdServ == tipoServico">
            <p-autoComplete [(ngModel)]="servicoSelecionado" [minLength]="3" (onKeyUp)="onKeyUpAutoCompleteServico($event)"
                [(ngModel)]="campoPesquisa" [disabled]="disabled"
                [emptyMessage]="'Informe a Descrição do Serviço'" placeholder="SERVIÇO OU CÓD. INTERNO + ENTER"
                [size]="13" [showEmptyMessage]="false" field="label" #elmPesquisarServico [maxlength]="13"
                [forceSelection]="false" [showClear]="true" (onSelect)="selecionouServico($event)"
                [completeOnFocus]="true" [autofocus]="autofocus" [showTransitionOptions]="'0ms'" [inputStyle]="{'height':'30px'}"
                [hideTransitionOptions]="'0ms'" [unique]="true" [autoHighlight]="true" [suggestions]="servicos"
                (onFocus)="cliqueFocusServico($event)" (completeMethod)="pesquisarServicos($event)">
                <ng-template let-produto pTemplate="item">
                    <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-'" style="width: 18px"/> -->
                    <div>{{ produto.value }} - {{ produto.label }} - <span class="text-c-blue"><b>{{ produto.preco }}</b></span></div>
                </ng-template>
            </p-autoComplete>
        </span>
        <span class="p-fluid " *ngIf="tipoProdServ == tipoProduto">
            <p-autoComplete [(ngModel)]="produtoSelecionado" [minLength]="3" (onKeyUp)="onKeyUpAutoCompleteProduto($event)"
                [(ngModel)]="campoPesquisa" [disabled]="disabled"
                [emptyMessage]="'Informe o EAN ou Descrição do Produto'" placeholder="EAN OU PRODUTO OU SKU + ENTER"
                [size]="13" [showEmptyMessage]="false" field="label" #elmPesquisarProduto [maxlength]="13"
                [forceSelection]="false" [showClear]="true" (onSelect)="selecionouProduto($event)"
                [completeOnFocus]="true" [autofocus]="autofocus" [showTransitionOptions]="'0ms'" [inputStyle]="{'height':'30px'}"
                [hideTransitionOptions]="'0ms'" [unique]="true" [autoHighlight]="true" [suggestions]="produtos"
                (onFocus)="cliqueFocusProduto($event)" (completeMethod)="pesquisarProdutos($event)">
                <ng-template let-produto pTemplate="item">
                    <!-- <img src="https://primefaces.org/cdn/primeng/images/demo/flag/flag_placeholder.png" [class]="'flag flag-'" style="width: 18px"/> -->
                    <div class="row" style="min-width: 600px;max-height: 100px;">
                        <div class="col-8">{{ produto.label }}</div>
                        <div class="col-2 text-center"><span class="text-c-blue"><b>{{ produto.preco }}</b></span></div>
                        <div class="col-2 text-center"><img *ngIf="produto.thumbnailImageSrc != ''" [src]="server + produto.thumbnailImageSrc  | secure | async" style="display: block;"/></div>
                    </div>
                    <!-- <div>{{ produto.label }} - <span class="text-c-blue"><b>{{ produto.preco }}</b></span></div> -->
                </ng-template>
            </p-autoComplete>
        </span>
    </div>
    <div class="col-2 text-center">
        <p-inputNumber inputStyleClass="quantidade-produto" [maxlength]="13" [(ngModel)]="quantidade" prefix=""
            [disabled]="disabled" currency="BRL" suffix=" un" styleClass="input-qtde" #elmQuantidadeProduto
            locale="pt-BR" (onKeyDown)="onKeyDownQuantidade($event)" inputId="quantidade" mode="decimal"
            [minFractionDigits]="3" (onFocus)="cliqueFocusQuantidade($event)" [min]="1"
            (onInput)="handleChangeQuantidade($event.value)" [size]="10" [maxFractionDigits]="3">
        </p-inputNumber>&nbsp;<i class="fas fa-info-circle" ngbTooltip="Pressione [enter] para voltar ao produto e [seta] para cima ou para baixo para alterar a quantidade"></i>
       
    </div>
    <div class="col-7 inline" *ngIf="nomeProdutoSelecionado != ''">

        <label class="label-selecao-item-pdv">
            <b>EAN</b>&nbsp;{{gtinProdutoSelecionado}}</label>
            &nbsp;&nbsp;<label class="label-selecao-item-pdv"><b>SKU</b>&nbsp;{{skuProdutoSelecionado}}</label>
        &nbsp;&nbsp;&nbsp;<label class="label-selecao-item-pdv" for="quantidade"><b>{{quantidadeSelecionada}} {{unidadeMedida}}</b> x
            {{getFormatDecimal(precoProduto)}} =
            <b> {{getFormatDecimal(subTotal)}}</b></label>
        <br>
        <label class="label-selecao-item-pdv">{{nomeProdutoSelecionado}}</label>&nbsp;&nbsp;


    </div>
</div>