<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="far fa-barcode-alt icon-header-modal"></i>&nbsp;{{titulo}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="acao == AbrirComanda">
        <div class="row margin-top-1 margin-bottom-1" *ngIf="parametrizacaoComanda != null">
            <div class="col-xl-12 col-md-12 col-sm-12 text-center " >
                <div class="form-check form-check-inline" *ngIf="parametrizacaoComanda.permiteComandaMesa == 'S'">
                    <input type="radio" id="tipoComanda1" name="tipoComanda" class="form-check-input" [disabled]="!habilitaTipoComanda"
                        [checked]="tipoComanda == 'M'"
                        (change)="handleTipoComanda('M')">
                    <label class="form-check-label" for="tipoComanda1">Mesa</label>
                </div>
                <div class="form-check form-check-inline" *ngIf="parametrizacaoComanda.permiteComandaFixa == 'S'">
                    <input type="radio" id="tipoComanda2" name="tipoComanda" class="form-check-input" [disabled]="!habilitaTipoComanda"
                        [checked]="tipoComanda == 'F'"
                        (change)="handleTipoComanda('F')">
                    <label class="form-check-label" for="tipoComanda2">Fixa</label>
                </div>
                <!-- <div class="form-check form-check-inline" *ngIf="parametrizacaoComanda.permiteComandaSequencial == 'S'">
                    <input type="radio" id="tipoComanda3" name="tipoComanda" class="form-check-input" [disabled]="!habilitaTipoComanda"
                        [checked]="tipoComanda == 'S'"
                        (change)="handleTipoComanda('S')">
                    <label class="form-check-label" for="tipoComanda3">Sequencial</label>
                </div> -->
            </div>
        </div>
        <div class="row">
            <div class="row" *ngIf="tipoComanda != 'S'">
                <div class="row justify-content-center mb-3 mt-3">
                    <div class="col-12 col-md-4 wrap-form big-input" >
                        <input (keypress)="keyPress($event)" class="form-control" placeholder="" [(ngModel)]="codigoComanda" #elmCodigoComanda [disabled]="disabledCodigoComanda">
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="tipoComanda == 'S'">
                <div class="row justify-content-center mb-3 mt-3">
                    <div class="col-12 col-md-4 wrap-form big-input">
                        <input class="form-control" placeholder="" [(ngModel)]="codigoComanda" #elmCodigoComanda disabled>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-4 col-md-4 wrap-form" *ngIf="parametrizacaoComanda != null && parametrizacaoComanda.exigeCpfcnpj == 'S'">
                    <label for="data1">CPF</label>
                    <p-inputMask [mask]="maskCpf" #elmCpf
                        styleClass="form-control input-md" id="cpfCnpj" name="cpfCnpj"  placeholder="Digite o Cpf" (keypress)="keyPress($event)"
                             [(ngModel)]="gerarComanda.cpfCnpj"></p-inputMask>
                </div>
                <div class="col-4 col-md-4" *ngIf="parametrizacaoComanda != null && parametrizacaoComanda.exigeRazaoSocialNome == 'S'">
                    <label class="wrap-form" for="data1">Razão Social/Nome</label>
                    <input class="form-control input-md" maxlength="60" placeholder="Razão Social/Nome" [(ngModel)]="gerarComanda.razaoSocialNome" (keypress)="keyPress($event)" #elmRazaoSocialNome>
                </div>
                <div class="col-4 col-md-4 wrap-form" *ngIf="parametrizacaoComanda != null && parametrizacaoComanda.exibeRg == 'S'">
                    <label for="data1">RG</label>
                    <p-inputMask [mask]="maskRg" #elmRg
                        styleClass="form-control input-md" id="rg" name="rg"  placeholder="Digite o RG" (keypress)="keyPress($event)"
                            [(ngModel)]="gerarComanda.rg"></p-inputMask>
                </div>
                <div class="col-4 col-md-4 wrap-form" *ngIf="parametrizacaoComanda != null && parametrizacaoComanda.exigeDataNascimento == 'S'">
                    <label for="data1">Data de Nascimento</label>
                    <p-calendar [(ngModel)]="gerarComanda.dataNascimento" name="dataNascimento" [readonlyInput]="false" #elmDataNascimento  inputId="elmDataNascimento"
                        dateFormat="dd/mm/yyyy" [showButtonBar]="true" [maxDate]="maxDate" dateFormat="dd/mm/yy" [inputStyleClass]="'form-control'"
                        id="dataNascimento" [monthNavigator]="true" [yearNavigator]="true" >
                    </p-calendar>
                </div>
                <div class="col-4 col-md-4 wrap-form" *ngIf="parametrizacaoComanda != null && parametrizacaoComanda.exibeTelefoneCelular == 'S'">
                    <label for="data1">Telefone Celular</label>
                    <p-inputMask [mask]="maskTeleAreaCel" #elmTelefoneCelular [autoClear]="false"
                        styleClass="form-control input-md" id="telefoneCelular" name="telefoneCelular"  placeholder="Digite o Celular" (keypress)="keyPress($event)"
                            [(ngModel)]="gerarComanda.telefoneCelular"></p-inputMask>
                </div>
                <div class="col-4 col-md-4 wrap-form" *ngIf="parametrizacaoComanda != null && parametrizacaoComanda.exibeTelefoneContato == 'S'">
                    <label for="data1">Telefone Contato</label>
                    <p-inputMask [mask]="maskTeleAreaCel" #elmTelefoneContato  [autoClear]="false"
                        styleClass="form-control input-md" id="telefoneContato" name="telefoneContato"  placeholder="Digite o Celular" (keypress)="keyPress($event)"
                            [(ngModel)]="gerarComanda.telefoneContato"></p-inputMask>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="acao == ConsultarComanda && parametrizacaoComanda != null">
        
        <div class="row margin-top-1 margin-bottom-1" >
            <div class="col-3"></div>
            <div class="col-6 text-center " >
                <div class="form-check form-check-inline" *ngIf="parametrizacaoComanda.permiteComandaMesa == 'S'">
                    <input type="radio" id="tipoComanda1" name="tipoComanda" class="form-check-input" [disabled]="!habilitaTipoComanda"
                        [checked]="tipoComanda == 'M'"
                        (change)="handleTipoComanda('M')">
                    <label class="form-check-label" for="tipoComanda1">Mesa</label>
                </div>
                <div class="form-check form-check-inline" *ngIf="parametrizacaoComanda.permiteComandaFixa == 'S'">
                    <input type="radio" id="tipoComanda2" name="tipoComanda" class="form-check-input" [disabled]="!habilitaTipoComanda"
                        [checked]="tipoComanda == 'F'"
                        (change)="handleTipoComanda('F')">
                    <label class="form-check-label" for="tipoComanda2">Fixa</label>
                </div>
                <!-- <div class="form-check form-check-inline" *ngIf="parametrizacaoComanda.permiteComandaSequencial == 'S'">
                    <input type="radio" id="tipoComanda3" name="tipoComanda" class="form-check-input" [disabled]="!habilitaTipoComanda"
                        [checked]="tipoComanda == 'S'"
                        (change)="handleTipoComanda('S')">
                    <label class="form-check-label" for="tipoComanda3">Sequencial</label>
                </div> -->
            </div>
            <div class="col-3"></div>
        </div>
        <div class="row justify-content-center mb-3 mt-3">
            <div class="col-4"></div>
            <div class="col-4 wrap-form big-input">
                <input (keypress)="keyPress($event)" class="form-control" placeholder="" [(ngModel)]="codigoComanda" #elmCodigoComanda>
            </div>
            <div class="col-4"></div>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="acao == AdicionarProduto || acao == VisualizarProdutos">
        <div class="row margin-top-1 margin-bottom-1 " *ngIf="usuarioComanda != ''">
            <div class="col-12 mb-2">
                <label style="color: #0046CF;display: flex;">Cliente&nbsp;<li>{{usuarioComanda}}</li></label>
            </div>
        </div>
        <div class="row" *ngIf="acao == AdicionarProduto">
            <app-pesquisa-por-produto [disabled]="loading"
                (iniciar)="iniciar($event)"
                #pesquisaPorProduto
                (adicionarProduto)="selecionouProduto($event)" 
                [inputDados]="gerarComanda" [habilitaProdutoServico]="habilitaProdutoServico"
                >
            </app-pesquisa-por-produto>
        </div>
        <div class="row">
            <div class="resumo" *ngIf="resumoComanda != null">
                <!-- Cabeçalho -->
                <!-- <div class="row header  text-small">
                    <div class="col-item text-center">
                        Item
                    </div>
                    <div class="col-produto text-center">
                        Produto
                    </div>
                    <div class="col-quantidade text-center">
                        Quantidade
                    </div>
                    <div class="col-valor-unitario text-center">
                        Valor
                    </div>
                    <div class="col-subtotal text-center">
                        Subtotal
                    </div>
                    <div class="col-acao text-center">
                        
                    </div>
                </div> -->
        
                <!-- Itens -->
                <div class="row resumo-max-height overflow-auto">
                    <div class="col-12">
                        <div class="row item-resumo text-small" *ngFor="let item of resumoComanda.produtos; let i = index">
                            <div class="col-item flex-grow-1">
                                <span [class]="getClassProdutoRemovido(item)">
                                    {{item.numeroItem}}
                                </span>
                            </div>
                            <div class="col-produto flex-grow-1">
                                <span [class]="getClassProdutoRemovido(item)">
                                    {{item.detalhe}}
                                </span>
                                <span *ngIf="(habilitaObservacaoProd && i == indexObservacaoProd) || (item.observacao != '' && item.observacao != null)" style="display: block; width: 100%;">
                                    <input [(ngModel)]="item.observacao" type="text" maxlength="60" role="textbox" class="p-component col-10">
                                    <button (click)="cancelarAlterarObservacaoProd()" type="button" class="btn inline mouse-pointer" ngbTooltip="Ocultar"><i class="text-c-blue fad fa-comment-slash"></i>
                                    </button>
                                    <button *ngIf="item.observacao != '' && item.observacao != null" (click)="exibirObservacao(item, i)" 
                                        type="button" class="btn inline mouse-pointer" ngbTooltip="Alterar"><i class="text-c-blue fad fa-edit"></i>
                                    </button>
                                    <button (click)="salvarObservacaoProd(item)" type="button" class="btn inline mouse-pointer" ngbTooltip="Salvar"><i class="text-c-green fad fa-save"></i>
                                    </button>
                                </span>
                            </div>
                            <div class="col-quantidade">
                                <span [class]="getClassProdutoRemovido(item)">{{item.quantidade}}x</span>
                            </div>
                            <div class="col-valor-unitario text-end">
                                <span [class]="getClassProdutoRemovido(item)">
                                    {{getFormatDecimal(item.valorUnitario!)}}
                                </span>
                            </div>
                            <div class="col-subtotal text-end">
                                <span [class]="getClassProdutoRemovido(item)">
                                    {{getFormatDecimal(item.valorTotal!)}}
                                </span>
                            </div>
                            <div class="col-situacao text-center">
                                <span class="text-center" *ngIf="item.situacao == enumSituacaoComandaProdutoLancada"><label
                                    class="badge bg-secondary text-c-white ">  Lançada</label></span>    
                                <span class="text-center" *ngIf="item.situacao == enumSituacaoComandaProdutoImportada"><label
                                    class="badge bg-primary text-c-white ">  Importada</label></span>    
                                <span class="text-center" *ngIf="item.situacao == enumSituacaoComandaProdutoFinalizada"><label
                                    class="badge bg-success text-c-white ">  Finalizada</label></span>    
                                <span class="text-center" *ngIf="item.situacao == enumSituacaoComandaProdutoCancelada"><label
                                    class="badge bg-danger text-c-white ">  Cancelada</label></span>     
                            </div>
                            <div class="col-acao " *ngIf="acao == AdicionarProduto">
                                <a ngbTooltip="Observação" class="pr-1" (click)="exibirObservacao(item, i)" *ngIf="item.status != 'C' && !loading">
                                    <i class="mouse-pointer text-c-blue fad fa-comment-plus f-16"></i>
                                </a>
                                <app-modal-impressao #modalImpressaoComponent 
                                    (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                                    (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                                    [modalFrontLayer]="false"
                                    [codigoDocumento]="'06'"
                                    [show]="item.status != 'C' && !loading"
                                    [textButton]="''"
                                    [ngbTooltip]="'Preparar'"
                                    [typeButton]="'icon'"
                                    [icon]="'fad fa-box-full '"
                                    (onImprimirFormatoCupomFiscal)="prepararItem(item, true)">
                                </app-modal-impressao>
                                <!-- <a ngbTooltip="Preparar" class="pr-1" (click)="prepararItem(item, true)" *ngIf="item.status != 'C' && !loading">
                                    <i class="mouse-pointer text-c-blue fad fa-box-full f-16"></i>
                                </a> -->
                                <a ngbTooltip="Remover Produto" class="" (click)="removerComandaProduto(item)" *ngIf="item.status != 'C' && !loading">
                                    <i class="mouse-pointer text-c-red fad fa-minus-circle f-16"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>        
                <div class="row">
                    <div class="col-12"><hr></div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row item-resumo text-small">
                            <div class="col-item text-center">
                            </div>
                            <div class="col-produto text-center">
                            </div>
                            <div class="col-quantidade text-center">
                            </div>
                            <div class="col-valor-unitario text-center">
                            </div>
                            <div class="col-subtotal text-end text-right">
                                <b>{{getFormatDecimal(getTotal())}}</b>
                            </div>
                            <div class="col-situacao text-center">

                            </div>
                            <div class="col-acao text-center">
                                
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-7"></div>
                    <div class="col-2"><b>TOTAL:</b></div>
                    <div class="col-2 text-end"><b>{{getFormatDecimal(getTotal())}}</b></div>
                    <div class="col-1"></div> -->
                </div>
            </div>
        </div>
        
    </div>
    <div class="app-modal-footer" style="flex-wrap: wrap;display: flex !important;gap: 8px;">
        <div *ngIf="acao == VisualizarProdutos">
            <button (click)="cliqueCancelar()" type="button" 
                class="btn largura-buttom-modal-comanda btn-secondary inline mouse-pointer">
                <i class="fad fa-window-close"></i> Fechar
            </button>
        </div>
        
        <div *ngIf="acao == AdicionarProduto">
            <button (click)="fecharModalAdicicaoProdutos()" type="button" 
                class="btn largura-buttom-modal-comanda btn-secondary inline mouse-pointer">
                <i class="fad fa-arrow-left"></i> Fechar
            </button>
        </div>
        
        <div *ngIf="tipoComanda == 'S' && acao == AbrirComanda">
            <button (click)="cliqueCancelar()" type="button" 
                class="btn largura-buttom-modal-comanda btn-secondary inline mouse-pointer">
                <i class="fad fa-arrow-left"></i> Cancelar
            </button>
        </div>
        
        <div *ngIf="(acao == AbrirComanda || acao == ConsultarComanda) && tipoComanda != 'S'">
            <button (click)="cliqueCancelar()" type="button" 
                class="btn largura-buttom-modal-comanda btn-secondary inline mouse-pointer">
                <i class="fad fa-arrow-left"></i> Cancelar
            </button>
        </div>
        
        <!-- <div *ngIf="acao == AdicionarProduto && (resumoComanda?.produtos?.length > 0)">
            <button type="button" (click)="imprimir(formatoA4)" 
                class="btn largura-buttom-modal-comanda btn-primary inline mouse-pointer">
                <i class="fad fa-print"></i> Imprimir A4
            </button>
        </div>
        
        <div *ngIf="acao == AdicionarProduto && (resumoComanda?.produtos?.length > 0)">
            <button type="button" (click)="imprimir(formatoCupom80mm)" 
                class="btn largura-buttom-modal-comanda btn-info inline mouse-pointer">
                <i class="fad fa-toilet-paper"></i> Imprimir Cupom
            </button>
        </div>
        
        <div *ngIf="acao != AbrirComanda && (resumoComanda?.produtos?.length > 0)">
            <button type="button" (click)="enviarParaPreparar(null, false)" 
                class="btn largura-buttom-modal-comanda btn-success inline mouse-pointer">
                <i class="fad fa-box-full"></i> Preparar
            </button>
        </div> -->

        <div *ngIf="acao != AbrirComanda && (resumoComanda?.produtos?.length > 0)">
            <app-modal-impressao #modalImpressaoComponent
                (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                [modalFrontLayer]="true"
                [codigoDocumento]="'06'"
                [textButton]="'Preparar'"
                [typeButton]="'default'"
                [icon]="'fad fa-box-full ps-2'"
                (onImprimirFormatoCupomFiscal)="enviarParaPreparar(null, false)"
                >
            </app-modal-impressao>
        </div>
        <div *ngIf="acao == AdicionarProduto && (resumoComanda?.produtos?.length > 0)">
            <app-modal-impressao #modalImpressaoComponent2 
                (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                [modalFrontLayer]="true"
                [codigoDocumento]="'05'"
                [textButton]="'Imprimir'"
                (onImprimirFormatoA4)="onImprimirformatoA4()"
                (onImprimirFormatoCupomFiscal)="onImprimirformatoCupom80mm()"
                >
            </app-modal-impressao>
        </div>
       
        
        
        <div *ngIf="acao == AbrirComanda && tipoComanda != 'S'">
            <button type="button" (click)="cliqueAbrirComandaEAdicionarProduto()" [disabled]="loading"
                class="btn btn-success inline mouse-pointer">
                <i class="fad fa-shopping-basket"></i> Abrir e Ad. Produto
            </button>
        </div>
        
        <div *ngIf="acao == AbrirComanda && tipoComanda != 'S'">
            <button type="button" (click)="cliqueAbrirComanda()" [disabled]="loading"
                class="btn btn-success inline mouse-pointer">
                <i class="fad fa-barcode-read"></i> Abrir
            </button>
        </div>
        
        <div *ngIf="(resumoComanda?.produtos?.length > 0) && exibeBotaoPagamento">
            <button type="button" (click)="realizarPagamento()" [disabled]="loading"
                class="btn largura-buttom-modal-comanda btn-warning inline mouse-pointer">
                <i class="fad fa-dollar-sign"></i> Pagamento
            </button>
        </div>
        
        <div *ngIf="acao == ConsultarComanda">
            <button type="button" (click)="cliqueConsultarResumoComanda()" [disabled]="loading"
                class="btn btn-success inline mouse-pointer">
                <i class="fad fa-search"></i> Buscar
            </button>
        </div>
        
        <div *ngIf="tipoComanda == 'S' && acao == AbrirComanda && (!comanda || (comanda?.idComanda == 0))">
            <button (click)="gerarSequencial()" type="button" [disabled]="loading"
                class="btn btn-success inline mouse-pointer">
                <i class="fad fa-scanner-keyboard"></i> Gerar Sequencial
            </button>
        </div>
        
        <div *ngIf="tipoComanda == 'S' && acao == AbrirComanda && (comanda?.idComanda != 0)">
            <button (click)="gerarSequencial()" type="button" [disabled]="loading"
                class="btn btn-success inline mouse-pointer">
                <i class="fad fa-scanner-keyboard"></i> Abrir
            </button>
        </div>
        
        <div *ngIf="acao != AbrirComanda && acao != ConsultarComanda">
            <button (click)="cliqueLiberarComanda()" type="button" [disabled]="loading"
                class="btn largura-buttom-modal-comanda btn-secondary inline mouse-pointer">
                <i class="fad fa-barcode-read"></i> Liberar Comanda
            </button>
        </div>
    </div>
    
</app-ui-modal>
