import { IOption } from 'ng-select';
import { enumCombustivelVeiculo } from 'src/app/enum/enumCombustivelVeiculo';
import { enumTipoImpressao } from 'src/app/enum/enumTipoImpressao';
//import * as moment from 'moment-timezone';

export class UtilService {

  timeZone = 'America/Sao_Paulo';


  constructor() { }

  public static formatarCampoCpfCnpj(campoTexto): string {
    if (!campoTexto) return '';
    if (campoTexto.length <= 11) {
      return this.mascaraCpf(campoTexto);
    } else {
      return this.mascaraCnpj(campoTexto);
    }
  }
  public static retirarFormatacao(campoTexto): string {
    if (!campoTexto) return '';
    return campoTexto.replace(/(\.|\/|\-)/g, "");
  }
  public static mascaraCpf(valor): string {
    if (!valor) return '';
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
  }
  public static mascaraCnpj(valor): string {
    if (!valor) return '';
    return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
  }

  public static getContentType(tipo: string) {
    if (tipo == 'xml') {
      return 'application/xml';
    } else
      if (tipo == 'pdf') {
        return 'application/pdf';
      } else
        if (tipo == 'zip') {
          return 'application/zip';
        } else
          if (tipo == 'txt') { // Adicionando a condição para arquivos de texto
            return 'text/plain';
          } else
            return 'application/octet-stream';
  }

  public static getTiposCombustivel(): IOption[]{
    return [{label : 'Alcool/Gasolina', value: enumCombustivelVeiculo.AlcooleGasolina},{label : 'Gasolina', value: enumCombustivelVeiculo.Gasolina},
      {label: 'Alcool', value: enumCombustivelVeiculo.Alcool},{label: 'Diesel', value: enumCombustivelVeiculo.Diesel},{label: 'Hibrido', value: enumCombustivelVeiculo.Hibrido},
      {label: 'Elétrico', value: enumCombustivelVeiculo.Eletrico}];
  }
  
  public static getTiposImpressoes(): IOption[]{
    return [{label : 'Direta', value: enumTipoImpressao.Direta},{label : 'Download', value: enumTipoImpressao.Download},
      {label: 'Preview', value: enumTipoImpressao.Preview}];
  }

  public static getBrazilTimeToDateTime(date: Date) {
    //let zonedDate = utcToZonedTime(date, this.timeZone);
    //return moment(date).tz('America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss');
    //return format(zonedDate, 'yyyy-MM-dd HH:mm:ssXXX', { this.timeZone });
    //let offset: number = -3;
    let offset: number = 0;
    let retorno = new Date(date.getTime() + offset * 60 * 60 * 1000);
    return retorno;
    //return result.toLocaleString('pt-BR', { timeZone: 'UTC' });
  }
  public static getBrazilTimeToStringUtc(date: Date) {
    let offset: number = -3;
    let result = new Date(date.getTime() + offset * 60 * 60 * 1000);
    return this.formatDateToUtc(result);
  }

  public static getBrazilTimeToStringUtcOnDate(date: Date) {
    let offset: number = -3;
    let result = new Date(date.getTime() + offset * 60 * 60 * 1000);
    return this.formatDateToUtcOnlyString(result);
  }

  public static formatDateToUtcOnlyString(date: Date): string { //YYYY-MM-DD
    const pad = (num: number) => num < 10 ? `0${num}` : num;

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());

    return `${year}-${month}-${day}`;
  }

  // setDate(date: Date): Date{
  //   return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), 0));
  // }

  public static calculateDurationInHoursAndMinutes(startDate: Date, endDate: Date): string { //Saída: "01:30"
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInMinutes = Math.floor(differenceInMilliseconds / 60000);
    const hours = Math.floor(differenceInMinutes / 60);
    const minutes = differenceInMinutes % 60;
  
    // Formatar horas e minutos para o formato "HH:MM"
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
  
    return `${formattedHours}:${formattedMinutes}`;
  }

  public static calculateDurationInMinutes(startDate: Date, endDate: Date): number { //retorna o numero de minutos da diferença de hora
    const differenceInMilliseconds = endDate.getTime() - startDate.getTime();
    const differenceInMinutes = differenceInMilliseconds / 60000;
    return differenceInMinutes;
  }

  public static isNumeric(value: string): boolean {
    return !isNaN(Number(value)) && value.trim() !== '';
  }

  public static formatDateTimeToUtcOnlyString(date: Date): string { //YYYY-MM-DD:HH:MM:SS
    const pad = (num: number) => num < 10 ? `0${num}` : num;

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hour = pad(date.getHours());
    const minute = pad(date.getMinutes() + 1);
    const second = pad(date.getSeconds());

    return `${year}-${month}-${day}T${hour}${minute}${second}`;
  }

  public static formatDateToUtc(date: Date): string {
    const pad = (num: number) => num < 10 ? `0${num}` : num;

    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1);
    const day = pad(date.getDate());
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());
    const seconds = pad(date.getSeconds());
    const timezoneOffset = -date.getTimezoneOffset();
    const sign = timezoneOffset >= 0 ? '+' : '-';
    const offsetHours = pad(Math.floor(Math.abs(timezoneOffset) / 60));
    const offsetMinutes = pad(Math.abs(timezoneOffset) % 60);

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}${sign}${offsetHours}:${offsetMinutes}`;
  }

  public static replaceSpecialChars(str): string {
    str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
    str = str.replace(/[àáâãäå]/, "a");
    str = str.replace(/[ÈÉÊË]/, "E");
    str = str.replace(/[Ç]/, "C");
    str = str.replace(/[ç]/, "c");
    return str;
    // adicionar mais regras caso necessário
    // OBS: são necessárias regras tanto para caracteres minísculos e maiúsculos
    //return str.replace(/[^a-z]/gi,'');  // remove caracteres que não são letras
  }

  public static getFormatCpf(value: string) {
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  public static getDateString(data: Date) {
    return `${data.getFullYear().toString()}${data.getMonth().toString().padStart(2, '0')}${data.getDate().toString().padStart(2, '0')}`;
  }

  public static toDate(dateString): Date | null {
    if (!dateString) {
      return null;
    }

    let date: Date = null!;

    // Verificar o formato YYYY-MM-DD
    const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (isoFormat.test(dateString)) {
      date = new Date(dateString);
    }

    // Verificar o formato DD/MM/YYYY
    const brFormat = /^\d{2}\/\d{2}\/\d{4}$/;
    if (brFormat.test(dateString)) {
      const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
      date = new Date(year, month - 1, day);
    }

    // Retornar null se a data for inválida
    return isNaN(date.getTime()) ? null : date;
  }

  public static getDateFormatString(data: Date): string {
    if (data == null) return '';
    return `${(data.getDate()).toString().padStart(2, '0')}/${(data.getMonth()+1).toString().padStart(2, '0')}/${data.getFullYear().toString()}`;
  }

  public static addHours(date: Date, hours: number): Date {
    const newDate = new Date(date);
    newDate.setHours(newDate.getHours() + hours);
    return newDate;
  }
  
  public static addMinutes(date: Date, minutes: number): Date {
    const newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + minutes);
    return newDate;
  }
  
  public static addDays(date: Date, days: number): Date {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  }
  
  public static addMonths(date: Date, months: number): Date {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
  }
  
  public static addWeeks(date: Date, weeks: number): Date {
    return this.addDays(date, weeks * 7);
  }
  
  public static addSeconds(date: Date, seconds: number): Date {
    const newDate = new Date(date);
    newDate.setSeconds(newDate.getSeconds() + seconds);
    return newDate;
  }
  

}