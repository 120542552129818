<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'">
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-tag icon-header-modal"></i>&nbsp;Selecionar Pedido de Venda</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row justify-content-center ">
            <div class="col-4"></div>
            <div class="col-4 wrap-form big-input">
                <input (keypress)="keyPressCodigoPedidoVenda($event)" class="form-control" placeholder="" [(ngModel)]="codigoPedidoDeVenda" #elmCodigoPedidoVenda>
            </div>
            <div class="col-4"></div>
        </div>
        <div class="row scroll-miolo" >
            <div class="col-12 " *ngIf="pedidosDeVendaFilter.length > 0">
                <p-table  #dtVendasPendentes [value]="pedidosDeVendaFilter" dataKey="cpfCnpj" 
                    selectionMode="single"
                    class="p-table nowrap table-hover " [loading]="loading"  
                    [paginator]="false" 
                    currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center "> <div class="chk-option">
                            <input placeholder="Todos" type="checkbox" class="form-check-input mouse-pointer"
                            (change)="changeSelecionarTodosPedidosVenda($event)" id="chkTodosPedidosVenda">
                            <label ngbTooltip="Selecionar todas" for="chkTodosPedidosVenda" class="form-check-label  mouse-pointer">
                            </label>
                        </div></th>
                        <th class="text-center " >Cpf/Cnpj</th>
                        <th class="text-center " >Nome</th>
                        <th class="text-center " >Valor</th>
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <tr>
                            <td  class="text-center">
                                <input type="checkbox" class="form-check-input mouse-pointer"
                                    (change)="onChangeSelecionarPedidoVenda($event, item.idPedidoVenda)" 
                                    [checked]="getPedidoVendaSelecionado(item.idPedidoVenda)"
                                    [id]="'chkIdPedidoVenda_'+item.idPedidoVenda"
                                >
                                <label class="form-check-label mouse-pointer" [for]="'chkIdPedidoVenda_'+item.idPedidoVenda">&nbsp;{{ item.idPedidoVenda }}&nbsp;<i class="fas fa-info-circle"
                                    ngbTooltip="Selecione um ou mais pedido(s) de venda para gerar uma Venda"></i></label>
                            </td> 
                            <td class="text-center" [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                                <span class="text-center " *ngIf="item.tipoPessoa == 'J'">{{ item.cpfCnpj | cnpj }}</span>
                                <span class="text-center" *ngIf="item.tipoPessoa == 'F'">{{ item.cpfCnpj | cpf }}</span>
                                <span class="text-center" *ngIf="item.tipoPessoa == 'E'">{{ item.cpfCnpj }}</span>
                                <span class="text-center" *ngIf="item.cpfCnpj == null"></span>
                            </td>
                            <td [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                                <span *ngIf="item.razaoSocialNome != null">{{item.razaoSocialNome}}</span>
                                <span *ngIf="item.razaoSocialNome == null">Cliente não informado</span>
                            </td>
                            <td class="text-center" [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                                {{item.valorTotal | currency:'BRL'}}
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">Não há Pedidos de Vendas com situação finalizado</td>
                    </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Cancelar
        </button>&nbsp;
        <button  (click)="realizarAVenda()" type="button"
            class="btn btn-primary inline"><i class="fad fa-shopping-bag"></i> Vender
        </button>
    </div>
</app-ui-modal>