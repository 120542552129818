<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'">
  <div class="app-modal-header col-12" *ngIf="templateTela == cadastrar">
    <div class=" row">

      <div class="col-md-12 text-left">
        <h4 class="m-0 modal-title-header" *ngIf="pessoaFisicaJuridica == null">&nbsp;<i
            class="fad fa-user-plus icon-header-modal"></i>&nbsp;Novo Cliente</h4>
        <h4 class="m-0 modal-title-header" *ngIf="pessoaFisicaJuridica != null">&nbsp;<i
            class="fad fa-user-edit icon-header-modal"></i>&nbsp;Alterar Cliente</h4>
      </div>
    </div>
  </div>
  <div class="app-modal-body" *ngIf="templateTela == cadastrar">
    <div class="row">
      <div class="col-xl-2 col-md-3 col-sm-12" *ngIf="permiteCadPJ == 'S'">
        <div class="form-check form-check-inline ">
          <input type="radio" id="customRadioInline2" name="customRadioInline1" class="form-check-input  mouse-pointer"
            [checked]="inputDados.tipoPessoa == 'J'" (change)="handleChangePJ($event)"
            [disabled]="pessoaFisicaJuridica != null" [attr.aria-expanded]="!isCollapsedPJ"
            aria-controls="collapseImportarPJ">
          <label class="form-check-label  mouse-pointer" for="customRadioInline2">Pessoa Jurídica</label>
        </div>
      </div>
      <div class="col-xl-2 col-md-3 col-sm-12">
        <div class="form-check form-check-inline" *ngIf="permiteCadPF == 'S'">
          <input type="radio" id="customRadioInline1" name="customRadioInline1" class="form-check-input  mouse-pointer"
            [checked]="inputDados.tipoPessoa == 'F'" (change)="handleChangePF($event)"
            [disabled]="pessoaFisicaJuridica != null" [attr.aria-expanded]="!isCollapsedPF"
            aria-controls="collapseImportarPF">
          <label class="form-check-label  mouse-pointer" for="customRadioInline1">Pessoa Física</label>
        </div>
      </div>
      <div class="col-xl-2 col-md-3 col-sm-12">
        <div class="form-check form-check-inline">
          <input type="radio" id="customRadioInline3" name="customRadioInline1" class="form-check-input  mouse-pointer"
            [checked]="inputDados.tipoPessoa == 'E'" (change)="handleChangePE($event)"
            [disabled]="pessoaFisicaJuridica != null" [attr.aria-expanded]="!isCollapsedPE"
            aria-controls="collapseImportarPE">
          <label class="form-check-label  mouse-pointer" for="customRadioInline3">Pessoa Estrangeira</label>
        </div>
      </div>
    </div>
    <div id="isCollapsedPF" [ngbCollapse]="isCollapsedPF">
      <div class="row mt-1">
        <div class="col-2 ">
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input  mouse-pointer"
                [disabled]="inputDados.cpfCnpjInformado != ''" [checked]="inputDados.informouCpfCnpj == 'N'"
                (change)="onChangeCheckboxNaoInformarCpfCnpj($event)" id="informouCpfCnpj">
              <label class="form-check-label  mouse-pointer" for="informouCpfCnpj">Não informar Cpf&nbsp;</label>
              <i class="fas fa-info-circle" ngbTooltip="Selecione se não deseja informar o CPF"></i>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <div class="form-group">
            <label class="form-label" for="cpfCnpj">Cpf</label>
            <p-inputMask [mask]="maskCpf" [inputId]="'cpf'" [autoClear]="false" styleClass="form-control input-md"
              autocomplete="new-password"
              [disabled]="inputDados.informouCpfCnpj == 'N' || (inputDados.informouCpfCnpj == 'S' && cpfCnpjInformado != '' && modoTela == modoTelaAlteracao)"
              name="cpfCnpj" type="text" placeholder="Digite o Cpf" #elmCpfCnpj
              [(ngModel)]="inputDados.cpfCnpjInformado">
            </p-inputMask>

            <!-- <app-combo-cpf  (change)="changeCpfCnpj($event)" [idCampo]="'cpf'" 
                    #comboCpf [toolTip]="'Informe o CPF'" [(ngModel)]="inputDados.cpfCnpj">
                  </app-combo-cpf> -->
          </div>
        </div>
        <div class="col-5">
          <div class="form-group">
            <label class="form-label" for="nome">Nome</label>
            <input maxlength="60" class="form-control " id="nome" name="nome" type="text" autocomplete="new-password"
              placeholder="Digite o Nome" [(ngModel)]="inputDados.razaoSocialNome">
          </div>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <div class="form-group">
            <label class="form-label" for="celular">Telefone Celular</label>
            <p-inputMask [mask]="maskTeleAreaCel" [styleClass]="'form-control input-md'" [inputId]="'celular'"
              autocomplete="new-password" name="celular" placeholder="Dig. o Celular" [(ngModel)]="inputDados.celular"
              [autoClear]="false">
            </p-inputMask>
          </div>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <div class="form-group">
            <label class="form-label" for="rg">Rg</label>
            <!-- <p-inputMask [mask]="maskRg" class=" " id="rg" styleClass="form-control input-md" name="rg"
              placeholder="Digite o Rg" [autoClear]="false" [(ngModel)]="inputDados.rg"></p-inputMask> -->
            <input maxlength="13" class="form-control " id="rg" name="rg" type="text" placeholder="Digite o Rg"
              autocomplete="new-password" [(ngModel)]="inputDados.rg">
          </div>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <div class="form-group">
            <label class="form-label" for="dataNascimento">Data Nascimento</label>
            <p-calendar [(ngModel)]="inputDados.dataNascimento" name="dataNascimento" [readonlyInput]="false"
              dateFormat="dd/mm/yyyy" [maxDate]="maxDate" dateFormat="dd/mm/yy" [inputStyleClass]="'form-control'"
              placeholder="dd/mm/aaaa" id="dataNascimento" [monthNavigator]="true" [yearNavigator]="true"
              [showButtonBar]="true" [showIcon]="true" [showOnFocus]="false">
            </p-calendar>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="form-group">
            <label class="form-label" for="nomeDaMae">Nome da Mãe</label>
            <input maxlength="60" class="form-control " id="nomeDaMae" name="nomeDaMae" type="text" autocomplete="new-password"
              placeholder="Digite o Nome da Mãe" [(ngModel)]="inputDados.nomeDaMae">
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="form-group">
            <label class="form-label" for="email">Email</label>
            <input maxlength="60" class="form-control " id="email" name="email" type="text" autocomplete="new-password"
              placeholder="Digite um email" #email="ngModel" [(ngModel)]="inputDados.email">
          </div>
        </div>
      </div>
    </div>
    <div id="isCollapsedPJ" [ngbCollapse]="isCollapsedPJ">
      <div class="row mt-1">
        <div class="col-2 ">
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input  mouse-pointer"
                [disabled]="inputDados.cpfCnpjInformado != ''" [checked]="inputDados.informouCpfCnpj == 'N'"
                (change)="onChangeCheckboxNaoInformarCpfCnpj($event)" id="informouCpfCnpj">
              <label class="form-check-label  mouse-pointer" for="informouCpfCnpj">Não informar Cnpj&nbsp;</label>
              <i class="fas fa-info-circle" ngbTooltip="Selecione se não deseja informar o CNPJ"></i>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4 ">

          <!-- <label class="form-label" for="cpfCnpj">Cnpj</label> -->
          <!-- <p-inputMask [mask]="maskCnpj" [autoClear]="false" styleClass="form-control input-md" [inputId]="'cnpj'"  autocomplete="new-password"
              [disabled]="inputDados.informouCpfCnpj == 'N' || (inputDados.informouCpfCnpj == 'S' && cpfCnpjInformado != '' && modoTela == modoTelaAlteracao)"
              name="cpfCnpj" type="text" placeholder="Digite o Cnpj" [(ngModel)]="inputDados.cpfCnpjInformado">
            </p-inputMask> -->
          <div class="form-group">
            <app-text-box-cnpj
              [disabled]="inputDados.informouCpfCnpj == 'N' || (inputDados.informouCpfCnpj == 'S' && cpfCnpjInformado != '' && modoTela == modoTelaAlteracao)"
              [idCampo]="'cnpj'" #comboCnpj [toolTip]="'Para não informar seleciona a opção na esquerda ao lado'"
              [(ngModel)]="inputDados.cpfCnpjInformado" (importarDadosBaseExterna)="importarDadosBaseExterna($event)">
            </app-text-box-cnpj>
          </div>
          <!-- <app-combo-cnpj  (change)="changeCpfCnpj($event)" [idCampo]="'cnpj'"
                    #comboCnpj [(ngModel)]="inputDados.cpfCnpj" (importarDadosBaseExterna)="importarDadosBaseExterna($event)">
                  </app-combo-cnpj> -->
        </div>
        <div class="col-5 ">
          <div class="form-group">
            <label class="form-label" for="razaoSocial">Razão Social</label>
            <input maxlength="60" class="form-control " id="razaoSocial" name="razaoSocial" type="text"
              autocomplete="new-password" placeholder="Digite a Razão Social" #razaoSocial="ngModel"
              [(ngModel)]="inputDados.razaoSocialNome">
          </div>
        </div>
        <div class="col-5 ">
          <div class="form-group">
            <label class="form-label" for="nomeFantasia">Nome Fantasia</label>
            <input maxlength="60" class="form-control nomeFantasia" id="nomeFantasia" name="nomeFantasia" type="text"
              autocomplete="new-password" placeholder="Digite o Nome Fantasia" #nomeFantasia="ngModel"
              [(ngModel)]="inputDados.nomeFantasia">
          </div>
        </div>
        <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <div class="form-group">
            <label class="form-label" for="celular">Telefone Celular</label>
            <p-inputMask [mask]="maskTeleAreaCel" [styleClass]="'form-control input-md'" [inputId]="'celular'"
              autocomplete="new-password" name="celular" placeholder="Dig. o Celular" [(ngModel)]="inputDados.celular"
              [autoClear]="false">
            </p-inputMask>
          </div>
        </div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <div class="form-group">
            <label class="form-label" for="email">Email</label>
            <input maxlength="60" class="form-control " id="email" name="email" type="text" autocomplete="new-password"
              placeholder="Digite um email" #email="ngModel" [(ngModel)]="inputDados.email">
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="form-label" for="inscricaoSuframa">Inscrição Suframa</label>
            <input maxlength="15" class="form-control inscricaoSuframa" id="inscricaoSuframa" name="inscricaoSuframa"
              autocomplete="new-password" type="text" placeholder="Inscrição Suframa" #inscricaoSuframa="ngModel"
              [(ngModel)]="inputDados.inscricaoSuframa">
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="form-label" for="inscricaoEstadual">Inscrição Estadual</label>
            <input [maxlength]="15" class="form-control input-md inscricaoEstadual" id="inscricaoEstadual"
              autocomplete="new-password" name="inscricaoEstadual" type="text" placeholder="Digite a IE"
              [(ngModel)]="inputDados.inscricaoEstadual">
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="form-label" for="inscricaoMunicipal">Inscrição Municipal</label>
            <input maxlength="15" class="form-control inscricaoMunicipal" id="inscricaoMunicipal" autocomplete="new-password"
              name="inscricaoMunicipal" type="text" placeholder="Inscrição Municipal" #inscricaoMunicipal="ngModel"
              [(ngModel)]="inputDados.inscricaoMunicipal">
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="form-label" for="codigoRegimeTributario">Regime Tributário</label>
            <select id="codigoRegimeTributario" class="form-control-select" #codigoRegimeTributario="ngModel"
              autocomplete="new-password" [ngModelOptions]="{standalone: true}" [(ngModel)]="inputDados.codigoRegimeTributario">
              <option *ngFor="let item of itensCodigoRegimeTributario;" value="{{item.value}}">{{
                item.label }}</option>
            </select>
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12">
          <div class="form-group">
            <label class="form-label" for="dataAberturaEmpresa">Abertura da Empresa</label>
            <p-calendar [(ngModel)]="inputDados.dataAberturaEmpresa" name="dataAberturaEmpresa" [readonlyInput]="false"
              autocomplete="new-password" [maxDate]="maxDate" dateFormat="dd/mm/yy" id="dataAberturaEmpresa"
              placeholder="dd/mm/aaaa" [inputStyleClass]="'form-control'" placeholder="dd/mm/aaaa"
              [monthNavigator]="true" [yearNavigator]="true"></p-calendar>
          </div>
        </div>
        <div class="col-xl-2 col-md-3 col-sm-12">
          <div class="form-group">
            <div class="form-check">
              <input type="checkbox" class="form-check-input  mouse-pointer" autocomplete="new-password"
                [checked]="inputDados.tomadorOrgaoPublico == 'S'" (change)="onChangeCheckboxOrgPublico($event)"
                id="checkOrgPublico">
              <label class="form-check-label  mouse-pointer" for="checkOrgPublico">Orgão Público</label>&nbsp;
              <i class="fas fa-info-circle" ngbTooltip="Informe se é orgão público"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="isCollapsedPE" [ngbCollapse]="isCollapsedPE">
      <div class="row margin-top-1">
        <div class="col-md-3 ">
          <div class="form-group">
            <label class="form-label" for="cpfCnpj">Identificação
              Estrangeiro</label>
            <input maxlength="20" class="form-control " id="cpfCnpj" name="cpfCnpj" type="text" autocomplete="new-password"
              placeholder="Digite a identificação destinatário estrangeiro" #estrangeiro="ngModel"
              [(ngModel)]="inputDados.cpfCnpjInformado">
          </div>
        </div>
        <div class="col-3 ">
          <div class="form-group">
            <label class="form-label" for="razaoSocialNome">Nome / Razão Social</label>
            <input maxlength="20" class="form-control razaoSocialNome" id="razaoSocialNome" name="razaoSocialNome"
              autocomplete="new-password" type="text" placeholder="Digite o Nome ou Razão Social"
              [(ngModel)]="inputDados.razaoSocialNome">

          </div>
        </div>
        <div class="col-3 ">
          <div class="form-group">
            <label class="form-label" for="codigoPais">Código do País</label>
            <input maxlength="4" class="form-control codigoPais" id="codigoPais" name="codigoPais" type="text"
              autocomplete="new-password" placeholder="Digite o código do país" #codigoPais="ngModel"
              [(ngModel)]="inputDados.codigoPais">
          </div>
        </div>
        <div class="col-3 ">
          <div class="form-group">
            <label class="form-label" for="nomePais">Nome do País</label>
            <input maxlength="60" class="form-control nomePais" id="nomePais" name="inscricaoEstadual" type="text"
              autocomplete="new-password" placeholder="Digite o nome do país" #nomePais="ngModel"
              [(ngModel)]="inputDados.nomePais">
          </div>
        </div>
      </div>
    </div>
    <div class="row m-t-10">
      <div class="col-md-3 text-left">
        <h6>Informações de Endereço</h6>
        <span *ngIf="!existeEnderecoSalvo()">
          <input type="checkbox" class="form-check-input mouse-pointer" (change)="changeNaoInformarEndereco($event)"
            [(ngModel)]="naoInformarEndereco" id="naoInformarEndereco">
          <label class="form-check-label mouse-pointer ps-1" for="naoInformarEndereco">Não informar endereço</label>
        </span>
      </div>
      <div class="col-md-9 text-left" *ngIf="!naoInformarEndereco">
        <label class="form-check-label mouse-pointer"><mark>Selecione o Endereço Principal ou cadastre um novo clicando
            <b><a class="link text-decoration-underline"
                (click)="cadastrarNovoEndereco()"><strong>aqui</strong></a></b>:</mark>&nbsp;
          <i class="fas fa-info-circle"
            ngbTooltip="O endereço selecionado será para impressão do recibo, entrega e/ou envio de Nota Fiscal. Também será definido como principal e de entrega."></i>
          &nbsp;
        </label>
        <br>
        <div class="form-check form-check-inline" *ngFor="let item of inputDados.enderecos; let i = index">
          <input class="form-check-input" type="radio" name="radEnderecoPrincipal"
            [id]="'radEnderecoPrincipal_'+item.idEndereco" (click)="selecionarEndereco(item, i)"
            [checked]="item.idEndereco == idEnderecoSelecionado">
          <label class="form-check-label mouse-pointer text-c-black" [for]="'radEnderecoPrincipal_'+item.idEndereco">
            <b>{{item.cep | cep}}</b></label> <i class="fad fa-map-marker-alt-slash text-c-red f-16 mouse-pointer"
            (click)="removerEndereco(item)" ngbTooltip="Remover este endereço?"></i>
          &nbsp;<i class="fad fa-save text-c-green f-16 mouse-pointer" (click)="salvarEndereco(item)"
            ngbTooltip="Salvar este endereço"></i>

        </div>
      </div>
    </div>
    <div class="row" *ngIf="!naoInformarEndereco">
      <hr>
    </div>
    <div class="row" *ngIf="!naoInformarEndereco">
      <div class="col-md-3 text-left">
        <div class="form-group">
          <label class="form-label" for="cep">Cep</label>
          <p-inputMask [mask]="maskCep" (onBlur)="onBlurConsultaCep($event)" [styleClass]="'form-control '"
            [inputId]="'cep'" name="cep" type="text" placeholder="Digite o Cep" autocomplete="new-password"
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].cep"></p-inputMask>

        </div>
      </div>
      <div class="col-md-2 text-left">
        <div class="form-group">
          <label class="form-label" for="tipoLogradouro">Tipo</label>
          <select id="tipoLogradouro" class="form-control-select" [ngModelOptions]="{standalone: true}" autocomplete="new-password"
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].tipoLogradouro">
            <option *ngFor="let item of itensTipoLogradouro;" value="{{item.value}}">{{
              item.label }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-5 text-left">
        <div class="form-group">
          <label class="form-label" for="rua">Rua/Av/Logradouro</label>
          <input maxlength="50" class="form-control input-md" id="rua" name="rua" autocomplete="new-password"
            placeholder="Digite a Rua/Av/Logradouro" type="text"
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].rua">
        </div>
      </div>
      <div class="col-md-2 text-left">
        <div class="form-group">
          <label class="form-label" for="numero">Número</label>
          <input maxlength="8" class="form-control input-md" id="numero" name="numero" placeholder="Número" type="text"
            autocomplete="new-password" placeholder="" [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].numero">
        </div>
      </div>


    </div>
    <div class="row" *ngIf="!naoInformarEndereco">
      <div class="col-md-3 text-left">
        <div class="form-group">
          <label class="form-label" for="tipoLogradouro">Tipo</label>
          <select id="tipoBairro" class="form-control-select" [ngModelOptions]="{standalone: true}" autocomplete="new-password"
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].tipoBairro">
            <option *ngFor="let item of itensTipoBairro;" value="{{item.value}}">{{item.label }}</option>
          </select>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label class="form-label" for="bairro">Bairro</label>
          <input maxlength="50" class="form-control input-md" id="bairro" name="bairro" type="text" autocomplete="new-password"
            placeholder="Digite o Bairro" [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].bairro">
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <label class="form-label" for="complemento">Complemento</label>
          <input maxlength="20" class="form-control input-md" id="complemento" name="complemento" type="text"
            autocomplete="new-password" placeholder="Digite o Complemento "
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].complemento">
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!naoInformarEndereco">
      <div class="col-md-3">
        <div class="form-group">
          <label class="form-label" for="inscricaoEstadual">Inscrição Estadual</label>
          <input maxlength="15" class="form-control input-md" id="inscricaoEstadual" name="inscricaoEstadual"
            autocomplete="new-password" type="text" placeholder="Digite a Inscrição Estadual "
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].inscricaoEstadual">
        </div>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" *ngIf="estados != null">
        <label class="form-label" for="idEstado">Estado</label>
        <div class="form-group form-primary mb-0">
          <select (change)="onChangeEstado($event)" name="idEstado" autocomplete="new-password"
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].siglaUf" class="form-control-select">
            <!-- <option value="">Selecione um Estado</option> -->
            <option *ngFor="let item of estados;" value="{{item.siglaUf}}">{{item.nome}}</option>
          </select>
        </div>
      </div>
      <div class="col-6">
        <label class="form-label" for="codigoMunicipioIbge">Cidade</label>
        <div class="form-group form-primary mb-0">
          <select [disabled]="cidades == null" (change)="onChangeCidade($event)" name="codigoMunicipioIbge"
            autocomplete="new-password" id="codigoMunicipioIbge"
            [(ngModel)]="inputDados.enderecos[indexEnderecoSelecionado].codigoMunicipioIbge"
            class="form-control-select">
            <option *ngIf="cidades == null" value="">Selecione primeiro um estado</option>
            <option *ngIf="cidades != null" value="">Selecione uma Cidade</option>
            <option *ngFor="let item of cidades;" value="{{item.codigoMunicipioIbge}}">
              {{item.nome}}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="app-modal-header col-12" *ngIf="templateTela == listar">
    <div class=" row">
      <div class="col-md-12 text-left">
        <h4 class="m-0 modal-title-header"> <i class="fad fa-users icon-header-modal"></i>&nbsp;Pesquisar por Cliente
        </h4>
      </div>
    </div>
  </div>
  <div class="app-modal-body" *ngIf="templateTela == listar">
    <div class="row">
      <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
        <input maxlength="36" class="form-control " id="codigoCliente" name="codigoCliente" type="text"
          placeholder="Código do Cliente" (keypress)="keyPress($event)" aria-label="Código do Cliente"
          autocomplete="new-password" [(ngModel)]="codigoCliente">
      </div>
      <div class="col-7">
        <input maxlength="60" class="form-control " id="pesquisar" name="pesquisar" type="text"
          placeholder="CPF / CNPJ / Nome ou Nome Fantasia" (keypress)="keyPress($event)" autocomplete="new-password"
          aria-label="CPF / CNPJ / Nome ou Nome Fantasia" #descricao="ngModel" #elmPesquisarCliente
          [(ngModel)]="filtro">
      </div>
      <div class="col-3 text-end btn-pesquisar-cliente">
        <button class="btn btn-primary btn-md " (click)="pesquisar()" [disabled]="loadingPesquisar">
          <i class="fad fa-file-search"></i>&nbsp;Pesquisar&nbsp;
        </button>
      </div>
    </div>
    <div class="row pt-2">
      <p-table #dtClientes [value]="listaClientes" dataKey="cpfCnpj" selectionMode="single"
        class="p-table nowrap table-hover" [loading]="loadingPesquisar" [(selection)]="pessoaSelecionada"
        responsiveLayout="scroll" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
        [scrollHeight]="heightListaCliente" styleClass="p-datatable-cadastro-comercial " [paginator]="false"
        #elmListaClientes currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
        <ng-template pTemplate="header">
          <tr>
            <th class="text-center p-r-10">Código</th>
            <th class="text-center p-r-10">Cpf/Cnpj</th>
            <th class="text-center p-r-10">Tipo Pessoa</th>
            <th class="text-center p-r-10">Nome/Razão Social</th>
            <th class="text-center p-r-10">Nome Fantasia</th>
            <th *ngIf="!somenteConsulta" class="text-center p-r-10">Ações</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
          <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
            <td class="text-center">
              {{item.codigoSistemaLegado}}
            </td>
            <td class="text-center">
              <span class="text-center" *ngIf="item.tipoPessoa == 'J' && item.informouCpfCnpj == 'S'">{{
                item.cpfCnpjInformado | cnpj }}</span>
              <span class="text-center"
                *ngIf="item.tipoPessoa == 'J' && (item.informouCpfCnpj == '' || item.informouCpfCnpj == null || item.informouCpfCnpj == 'N')">
                <!-- {{ item.cpfCnpj | cnpj }} -->
                Não informado
              </span>
              <span class="text-center" *ngIf="item.tipoPessoa == 'F' && item.informouCpfCnpj == 'S'">{{
                item.cpfCnpjInformado | cpf }}</span>
              <span class="text-center"
                *ngIf="item.tipoPessoa == 'F' && (item.informouCpfCnpj == '' || item.informouCpfCnpj == null || item.informouCpfCnpj == 'N')">
                <!-- {{ item.cpfCnpj | cpf }} -->
                Não informado
              </span>
              <span class="text-center" *ngIf="item.tipoPessoa == 'E'">{{ item.cpfCnpj }}</span>
            </td>
            <td class="text-center ">
              <span class="text-center" *ngIf="item.tipoPessoa == 'E'">Estrangeiro</span>
              <span class="text-center" *ngIf="item.tipoPessoa == 'F'">Fisica</span>
              <span class="text-center" *ngIf="item.tipoPessoa == 'J'">Jurídica</span>
            </td>
            <td>
              {{item.razaoSocialNome}}
            </td>
            <td>
              <span class="text-center" *ngIf="item.tipoPessoa == 'J'">{{item.nomeFantasia}}</span>
              <span class="text-center" *ngIf="item.tipoPessoa != 'J'">Não Possui</span>          
            </td>
            <td class="table-action nowrap text-center" *ngIf="!somenteConsulta">
              <a ngbTooltip="Selecionar" class=" " #itemClientePesquisado (click)="onRowIconSelect(item)">
                <i class="mouse-pointer fad fa-user-check text-c-blue f-16 m-r-15"></i>
              </a>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">{{emptymessage}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="app-modal-footer">
    <button (click)="cliqueVoltar()" type="button" *ngIf="templateTela == cadastrar && !somenteConsulta"
      class="btn btn-secondary btn-modal inline"><i class="fad fa-arrow-left"></i> {{textoBotaoVoltar}}
    </button>&nbsp;
    <button (click)="cliqueCancelar()" type="button" class="btn btn-secondary btn-modal inline mouse-pointer"><i
        class="fad fa-times pr-5"></i> Cancelar
    </button>&nbsp;

    <button type="button" (click)="cliqueNovoCliente()"
      *ngIf="templateTela == listar && !somenteConsulta && (permiteCadPF == 'S' || permiteCadPJ == 'S')"
      class="btn btn-primary btn-modal inline  mouse-pointer"><i class="fad fa-user-plus"></i> Novo
      Cliente</button>&nbsp;
    <!-- <button type="button" (click)="cliqueCadastroCompleto()"  *ngIf="templateTela == enumTemplateTelaSelecionarClienteCadastrar"
            class="btn btn-success inline"><i class="fad fa-user-plus"></i> Cadastro Completo</button>&nbsp; -->
    <button type="button" (click)="cliqueNaoInformarCliente()" *ngIf="permitirNaoInformarCliente && !somenteConsulta"
      class="btn btn-warning btn-modal  inline"><i class="fad fa-user-alt-slash"></i> Não Informar</button>&nbsp;
    <button type="button" (click)="cliqueCadastrar()" *ngIf="templateTela == cadastrar && !somenteConsulta"
      class="btn btn-primary btn-modal inline"><i class="fad fa-save"></i> Salvar</button>&nbsp;

  </div>
</app-ui-modal>