import { enumSimNao } from "src/app/enum/enumSimNao";
import { EnderecoModel } from "./enderecoModel";



export class EnderecoModelInstance {
    public static get(): EnderecoModel {
        return {
            idEndereco: 0,
            codigoMunicipioIbge: '',
            cep: '',
            rua: '',
            numero: '',
            bairro: '',
            complemento: '',
            cidade: '',
            tipoBairro: 'Bairro',
            tipoLogradouro: 'Rua',
            siglaUf: '',
            cpfAlteracao: '',
            cpfRegistro: '',
            dataAlteracao: null,
            dataRegistro: null,
            cpfCnpj: '',
            enderecoEntrega: enumSimNao.Sim,
            enderecoPrincipal: enumSimNao.Sim,
            localEntregaVenda: null!,
            cpfCnpjNavigation: null!,
            vendaEntregas: null!,
            inscricaoEstadual: ''
        };
    }
    public static getArray(): EnderecoModel[] {
        return [this.get()];
    };
}
