<label *ngIf="label != ''" [for]="idCampo" class="form-label">{{label}}&nbsp;<i *ngIf="toolTip !=  ''" class="fas fa-info-circle inline" 
    [ngbTooltip]="toolTip"></i></label>
<div class="input-group">
<ng-select  
    [id]="idCampo"
    [name]="idCampo"
    [ngModelOptions]="{standalone: true}" 
    class="ng-select" 
    [items]="itens"
    bindLabel="label" 
    bindValue="value"
    loadingText="Carregando..."
    notFoundText="Sem registro"
    [loading]="loading"
    [readonly]="disabled"
    (change)="changeValue()"
    [(ngModel)]="value"></ng-select>
</div>