<app-ui-modal #modal [containerClick]="false" (close)="cliqueFechar()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="far fa-edit icon-header-modal"></i>&nbsp;Alterar Item [{{descricaoProdutoServico}}]</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row"  *ngIf="selecionouItem">
            <div class="col-9">
                <!-- <h4 class="sub-titulo">
                    {{inputDados.gtin}} - {{inputDados.nome}}
                </h4> -->
                <div class="form-group">
                    <input maxlength="120" class="form-control"
                    id="nome" name="nome" type="text"
                    placeholder="Digite o Nome do Item" [(ngModel)]="inputDados.nome">                    
                    <!-- <i class="fas fa-info-circle inline" ngbTooltip="Se ajustado o nome, esse será o impresso no recibo, na venda, nota fiscal(caso for emitir). Não será alterado no cadastro. Máximo 120 caracteres."></i> -->
                </div>
            </div>
        </div>
        <div class="row ">
            <hr>
        </div>
        <div class="row"  *ngIf="selecionouItem && (inputDados.habilitaCalculoUnidadeMedida == 'N' || inputDados.habilitaCalculoUnidadeMedida == null)">
            <div class="col-3">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidade">Quantidade
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i>
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidade"
                      (ngModelChange)="changeQuantidade($event)" #elmQuantidade
                      (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="quantidade" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-label uppercase" for="precoVenda">Valor Unitário
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.precoVenda" suffix="" prefix="R$ " 
                        currency="BRL" locale="pt-BR" inputId="precoVenda" mode="decimal" [minFractionDigits]="2"
                        (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                        [maxFractionDigits]="2" [disabled]="!permiteAlterarPrecoVenda">
                    </p-inputNumber>
                </div>
            </div>
            <!-- <div class="col-3">
                <h4 class="sub-titulo">
                    Preço Unitário <b>{{inputDados.precoVenda | currency:'BRL' }}</b>
                </h4>
            </div> -->
            <div class="col-3">
                <h4 class="sub-titulo">
                    Sub Total <b>{{(inputDados.quantidade * inputDados.precoVenda!) | currency:'BRL' }}</b>
                </h4>
            </div>
            <div class="col-3">
                <h4 class="sub-titulo">
                    Total <b>{{(inputDados.quantidade * inputDados.precoVenda!) + (inputDados.valorTotalSeguro!) + (inputDados.valorTotalFrete!)
                        + (inputDados.valorOutrasDespesas!) - (inputDados.valorDesconto!) | currency:'BRL' }}</b>
                </h4>
            </div>
        </div>
        <div class="row" *ngIf="selecionouItem && inputDados.habilitaCalculoUnidadeMedida == 'S'">
            <div class="col-2" *ngIf="inputDados.unidadeMedida == unMedMetro || inputDados.unidadeMedida == unMedMetroQuadrado || inputDados.unidadeMedida == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeQuantidadeItem">Quantidade
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeQuantidadeItem"
                      (ngModelChange)="changeQtdeItemPorMetro($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeQuantidadeItem" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2" *ngIf="inputDados.unidadeMedida == unMedMetro || inputDados.unidadeMedida == unMedMetroQuadrado || inputDados.unidadeMedida == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeTipoMedidaComprimento">Comprimento
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeTipoMedidaComprimento"
                      (ngModelChange)="changeComprimento($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeTipoMedidaComprimento" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2" *ngIf="inputDados.unidadeMedida == unMedMetroQuadrado || inputDados.unidadeMedida == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeTipoMedidaLargura">Largura
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeTipoMedidaLargura"
                      (ngModelChange)="changeLargura($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeTipoMedidaLargura" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2" *ngIf="inputDados.unidadeMedida == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeTipoMedidaAltura">Altura
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeTipoMedidaAltura"
                      (ngModelChange)="changeAltura($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeTipoMedidaAltura" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidade">Total em Metros
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total em metros"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidade"
                      (ngModelChange)="changeQuantidade($event)" #elmQuantidade
                      (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="quantidade" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="precoVenda">Valor Unitário
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.precoVenda" suffix="" prefix="R$ " 
                        currency="BRL" locale="pt-BR" inputId="precoVenda" mode="decimal" [minFractionDigits]="2"
                        (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                        [maxFractionDigits]="2" [disabled]="!permiteAlterarPrecoVenda">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <h4 class="sub-titulo">
                    Sub Total <b>{{(inputDados.quantidade * inputDados.precoVenda!) | currency:'BRL' }}</b>
                </h4>
            </div>
            <div class="col-2">
                <h4 class="sub-titulo">
                    Total <b>{{(inputDados.quantidade * inputDados.precoVenda!) + (inputDados.valorTotalSeguro!) + (inputDados.valorTotalFrete!)
                        + (inputDados.valorOutrasDespesas!) - (inputDados.valorDesconto!) | currency:'BRL' }}</b>
                </h4>
            </div>            
        </div>
        <div class="row " *ngIf="selecionouItem">
            <hr>
        </div>
        <div class="row" *ngIf="selecionouItem">
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidadeEntregueNoAto">Qtde. Entr. no Ato
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade Entregue no Ato"></i>
                    </label>
                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidadeEntregueNoAto"
                      locale="pt-BR" inputId="quantidadeEntregueNoAto" mode="decimal" [minFractionDigits]="3" [disabled]="true"
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidadeParaEntregaAgendada">Qtde. Agendada
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade agendada para ser entregue posteriormente"></i>
                    </label>
                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidadeParaEntregaAgendada" (ngModelChange)="changeQuantidadeEntregueAgendada($event)"
                      locale="pt-BR" inputId="quantidadeParaEntregaAgendada" mode="decimal" [minFractionDigits]="2"
                      (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      [maxFractionDigits]="2" [disabled]="!permiteVendaEntrega">
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="dataPrevistaEntregaAgendada">Data Entr. Agendada
                        <i class="fas fa-info-circle" ngbTooltip="Data previsto para a Entrega do Produto"></i>
                    </label>
                    
                    <p-calendar [(ngModel)]="inputDados.dataPrevistaEntregaAgendada" name="dataPrevistaEntregaAgendada" [readonlyInput]="false"
                    dateFormat="dd/mm/yyyy" [showButtonBar]="true" dateFormat="dd/mm/yy" [disabled]="inputDados.quantidadeParaEntregaAgendada <= 0"
                    id="dataPrevistaEntregaAgendada" [monthNavigator]="true" [yearNavigator]="true" >
                    </p-calendar>
                </div>
            </div>
            <!-- <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidadeParaEntregaCrossdoking">Qtde. Crossdocking
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade disponível pelo seu fornecedor para poder comercializar sem estar em seu estoque físico."></i>
                    </label>
                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidadeParaEntregaCrossdoking"  (ngModelChange)="changeQuantidadeCrossDocking($event)"
                      locale="pt-BR" inputId="quantidadeParaEntregaCrossdoking" mode="decimal" [minFractionDigits]="2"
                      [maxFractionDigits]="2" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="dataPrevistaParaEntregaCrossdocking">Dt. Entr. Crossdock.
                        <i class="fas fa-info-circle" ngbTooltip="Data previsto para a Entrega do produto para estoque Crossdocking."></i>
                    </label>
                    <p-calendar [(ngModel)]="inputDados.dataPrevistaParaEntregaCrossdocking" name="dataPrevistaParaEntregaCrossdocking" [readonlyInput]="false"
                    dateFormat="dd/mm/yyyy" [showButtonBar]="true" dateFormat="dd/mm/yy" [disabled]="inputDados.quantidadeParaEntregaCrossdoking <= 0"
                    id="dataPrevistaParaEntregaCrossdocking" [monthNavigator]="true" [yearNavigator]="true" >
                    </p-calendar>
                </div>
            </div> -->
            <div class="col-md-2" *ngIf="permiteValorSeguro == 'S'">
                <div class="form-group text-left">
                    <label class="form-label uppercase" for="valorSeguro">Valor Seguro
                        <i class="fas fa-info-circle" ngbTooltip="valor determinado no cadastro de produto na parte de preços"></i>
                    </label>
                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorSeguro" suffix="" prefix="R$ " 
                        currency="BRL" locale="pt-BR" inputId="valorSeguro" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2" [disabled]="true">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-md-2" *ngIf="permiteValorSeguro == 'S'">
                <div class="form-group text-left">
                    <label class="form-label uppercase" for="valorTotalSeguro">Valor Total Seguro</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalSeguro" suffix="" prefix="R$ " 
                    (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSeguro" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group text-left">
                    <label class="form-label uppercase" for="valorTotalFrete">Valor Frete</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalFrete" suffix="" prefix="R$ " 
                    (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorTotalFrete" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>           
        <!-- </div>
        <div class="row" *ngIf="selecionouItem"> -->
            <div class="col-md-2" >
                <div class="form-group text-left">
                    <label class="form-label uppercase" for="valorOutrasDespesas">Outras Despesas</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorOutrasDespesas" suffix="" prefix="R$ " 
                    (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorOutrasDespesas" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <!-- <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="valorDesconto">Desconto Promoção</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalPromocaoConcedido" suffix="" prefix="R$ "                         
                        [disabled]="true" currency="BRL" locale="pt-BR" inputId="valorDesconto" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div> -->
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="vDescontoMaximo">Desc. Máx. Permitido</label>
                    <div class="input-group ">
                        <input type="text" class="form-control" disabled="true" placeholder="?"  
                            [(ngModel)]="vDescontoMaximo">
                        <span class="input-group-text" id="basic-addon2">
                            <i class="mouse-pointer text-c-blue fad fa-eye f-20 " *ngIf="!exibirDescontoMaximo" (click)="descontoMaximo()"></i>
                            <i class="mouse-pointer text-c-blue fad fa-eye-slash f-20" *ngIf="exibirDescontoMaximo" (click)="descontoMaximo()"></i>
                        </span>
                  </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="valorDesconto">Desconto Total</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDesconto" suffix="" prefix="R$ " 
                         [max]="valorDesconto" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorDesconto" mode="decimal" [minFractionDigits]="2" (onBlur)="atualizarCalculos()"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>            
            <div class="col-md-3">
                <div class="form-group ">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input mouse-pointer"
                        (change)="onChangeIndicaValorItemValorTot($event)" [checked]="inputDados.indicaValorItemValorTot == 'S'"
                        id="indicaValorItemValorTot">
                        <label class="form-check-label  mouse-pointer" for="indicaValorItemValorTot">Contabilizado Total <i class="fas fa-info-circle"
                            ngbTooltip="Contabilizado no Total da Nota Fiscal? Obs.: é obrigatório estar selecionado caso for emitir notas do tipo NFC-e e NFS-e, e o valor total deste item não será contabilizado no pagamento desta venda."></i></label>
                    </div>
                    <div *ngIf="mensagemIndicaValorItemValorTot != ''">
                        <label class="form-label  text-c-red">{{mensagemIndicaValorItemValorTot}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="selecionouItem">
            <div class="col-md-12">
                <label class="d-inline form-check-label mouse-pointer form-label" for="observacao">Observação</label>&nbsp;
                <i class="fas fa-info-circle"
                ngbTooltip="Alguma observação que deseja inserir para este item. Poderá ser impresso caso deseja, basta permitir na configuração de impressão."></i>
                <textarea maxlength="300" style="height:fit-content" class="form-control input-md observacao class-npe" id="observacao" name="observacao"
                    type="text"  placeholder="Observação..." [rows]="1"
                    [(ngModel)]="inputDados.observacao"></textarea>
            </div>
        </div>
        <div class="row pt-2" *ngIf="selecionouItem">
            <div class="col-6 " >
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Custos</span>
                        <button class="btn" (click)="showTotais = !showTotais">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showTotais"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showTotais"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showTotais">
                        <li>
                            Custo Unitário <strong>R$ {{ inputDados.custoUnitario! | number:'1.2-5' }}</strong>
                        </li>
                        <li>
                            Custo Unitário com Frete/Seguro/Despesas/Descontos <strong>R$ {{ inputDados.custoUnitario! + inputDados.valorTotalFrete!/inputDados.quantidade! 
                                + inputDados.valorTotalSeguro!/inputDados.quantidade! + inputDados.valorOutrasDespesas!/inputDados.quantidade! 
                                - inputDados.valorDesconto!/inputDados.quantidade! | number:'1.2-5' }}</strong>
                        </li>
                        <li>
                            Custo Total <strong>R$ {{ inputDados.quantidade! * inputDados.custoUnitario! | number:'1.2-5' }}</strong>
                        </li>
                        <li>
                            Custo Total com Frete/Seguro/Despesas/Descontos <strong>R$ {{ ((inputDados.quantidade! * inputDados.precoMargemZero!) + inputDados.valorOutrasDespesas! 
                                + inputDados.valorTotalFrete!
                                + inputDados.valorTotalSeguro!
                                - inputDados.valorDesconto!)  | number:'1.2-5' }} </strong>
                        </li>                        
                    </ul>
                </div>
            </div>
            <div class="col-6" >
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Margem</span>
                        <button class="btn" (click)="showMargem = !showMargem">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showMargem"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showMargem"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showMargem">
                        <li>
                            Margem Sobre o Custo Sem Desconto 
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i> -->
                                &nbsp; <strong >{{ vMargemSobreCustoSemDesconto }} </strong>
                        </li>
                        <li>
                            Margem Sobre o Custo Com Desconto 
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i> -->
                                &nbsp; <strong >{{ vMargemSobreCustoComDesconto }} </strong>
                        </li>
                    </ul>
                </div>
            </div>
            
        </div>
        <div class="row" *ngIf="!selecionouItem">
            <div class="col-xl-12 col-md-12 col-sm-12" >
                <div class="form-group text-center">
                    <label class="form-label-bold text-center" for="numeroItem">NUMERO DO ITEM</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="4" [(ngModel)]="numeroItem" suffix="" prefix="" #elmNumeroItem (keypress)="keyPressNumeroItem($event)" 
                    currency="BRL" locale="pt-BR"  mode="decimal" [minFractionDigits]="0"
                    [maxFractionDigits]="0">
                </p-inputNumber>
                </div>
            </div>
        </div>
        <p-table #elmProdutos [value]="itens" dataKey="idTabelaMeioPagamento" *ngIf="!selecionouItem"
            selectionMode="single" [(selection)]="inputDados"
            class="p-table nowrap table-hover " [loading]="loadingListarItens"  responsiveLayout="scroll"
            dataKey="item" 
            (onRowSelect)="onRowSelect($event)"
            [scrollHeight]="heightListaCliente" 
            styleClass=" " [paginator]="false" >
            <ng-template pTemplate="header">
            <tr>
                <th class="text-center ">Item</th>
                <th class="text-center ">Sku</th>
                <th class="text-center ">Gtin</th>
                <th class="text-center ">Produto</th>
                <th class="text-center ">Quantidade</th>
                <th class="text-center ">Valor Unit.</th>
                <th class="text-center ">Sub Total</th>
                <th class="text-center ">Total</th>
                <th class="text-center p-r-10">Ações</th>
              
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                    <td class="text-center" >{{ item.item }}</td>
                    <td class="text-center" >{{ item.sku }}</td>
                    <td class="text-center" >{{ item.gtin }}</td>
                    <td class="text-left" >{{ item.nome }}</td>
                    <td class="text-center" >{{ item.quantidade }}</td>
                    <td class="text-center" >{{ item.precoVenda | currency:'BRL' }}</td>
                    <td class="text-center" >{{ item.subTotal | currency:'BRL' }}</td>
                    <td class="text-center" >{{ item.valorTotal | currency:'BRL' }}</td>
                    <td class="table-action nowrap text-center">
                        <a ngbTooltip="Selecionar" class=" " (click)="onRowIconSelect(item, rowIndex)">
                            <i class="mouse-pointer fad fa-edit text-c-blue f-16 m-r-15"></i></a>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
            <tr>
                <td colspan="9">Não há produto(s) registrado(s) para este caixa</td>
            </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="app-modal-footer">
        <button type="button" (click)="cliqueFechar()" #elmBotaoCancelar
            class="btn btn-secondary inline"><i class="fad fa-window-close"></i> Fechar</button>&nbsp;

        <button  (click)="cliqueVoltar()" type="button" *ngIf="selecionouItem"
            class="btn btn-primary inline"><i class="fad fa-step-backward"></i> Voltar
        </button>&nbsp;

        <button type="button" (click)="cliqueAlterarItem()" *ngIf="selecionouItem"
            class="btn btn-success inline"><i class="fad fa-edit"></i> Alterar</button>&nbsp;
    </div>
</app-ui-modal>
