import { CaixaControleModel } from "../CaixaControle/caixaControleModel";
import { ContaBancariaMovimentacaoModel } from "../ContaBancariaMovimentacao/contaBancariaMovimentacaoModel";
import { PdvModel } from "../Pdv/pdvModel";
import { TabelaCentroCustoModel } from "../TabelaCentroCusto/tabelaCentroCustoModel";
import { TabelaMeioPagamentoModel } from "../TabelaMeioPagamento/tabelaMeioPagamentoModel";

export interface CaixaSangriaSuprimentoModel {
    idCaixaSangriaSuprimento: number;
    idCaixaControle: number | null;
    idTabelaMeioPagamento: string;
    idTabelaCentroCusto: number | null;
    tipoMovimento: string;
    dataHora: Date | null;
    valor: number | null;
    observacao: string;
    dataRegistro: Date | null;
    cpfRegistro: string;
    idLoja: number;
    idPdv: number | null;
    idCaixaControleNavigation: CaixaControleModel;
    idPdvNavigation: PdvModel;
    idTabelaCentroCustoNavigation: TabelaCentroCustoModel;
    idTabelaMeioPagamentoNavigation: TabelaMeioPagamentoModel;
    contaBancariaMovimentacaos: ContaBancariaMovimentacaoModel[];
}

export class CaixaSangriaSuprimentoModelInstance {
    public static get(): CaixaSangriaSuprimentoModel {
        return {
            idCaixaSangriaSuprimento: 0,
            idCaixaControle: 0,
            idTabelaMeioPagamento: '',
            idTabelaCentroCusto: 0,      
            idPdv: 0, 
            tipoMovimento: '',
            dataHora: null,
            valor: 0,
            idLoja: 0,
            observacao: '',
            dataRegistro: null,
            cpfRegistro: '',
            idPdvNavigation: null!,
            idCaixaControleNavigation: null!,
            idTabelaCentroCustoNavigation: null!,            
            idTabelaMeioPagamentoNavigation: null!,
            contaBancariaMovimentacaos: null!,  
        };
    }
}