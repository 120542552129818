export enum enumStatusMovimentacaoCheque {
    Recebido = '1',
    Depositado = '2',
    Compensado = '3', //não permite alterar
    Devolvido = '4',
    Cancelado = '5',
    Endosso = '6',
    Baixado = '7',
    Consulta = '8',
}

// recebimento - Registro do recebimento de um cheque como forma de pagamento de um cliente.
// deposito - Registro do depósito de um cheque recebido em uma conta bancária.
// compensacao - Registro da compensação de um cheque no banco, confirmando que o valor foi creditado na conta.
// devolucao - Registro da devolução de um cheque pelo banco, geralmente por falta de fundos ou outros motivos (ex.: cheque sustado).
// cancelamento - Registro do cancelamento de um cheque emitido, antes de ser apresentado ao banco.
// endosso - Registro da transferência de um cheque para outra pessoa ou entidade por meio de endosso
// baixa do cheque - Registro da baixa de um cheque, indicando que ele foi processado e não está mais pendente.
// consulta - consulta do cheque no banco