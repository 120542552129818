import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiPixService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'pix';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getQrcodeFromParametrizacaoCondPgto(idParametrizacaoCondPagamento: number, exibeCarregando?: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getQrcodeFromParametrizacaoCondPgto/${idParametrizacaoCondPagamento}`, exibeCarregando);
    }    
}