import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ApiPrecoService } from 'src/app/services/cliente/api-preco.service';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { enumStatus } from 'src/app/enum/enumStatus';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ConsultaPrecoModel } from 'src/app/models/cliente/preco/ConsultaPrecoModel';
import { ConstantsService } from '../../constants/constants.service';

@Component({
  selector: 'app-modal-consultar-preco',
  templateUrl: './modal-consultar-preco.component.html',
  styleUrls: ['./modal-consultar-preco.component.scss']
})
export class ModalConsultarPrecoComponent implements OnInit {

  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @ViewChild(UiModalComponent) modalCaixa;

  loading: boolean = true;

  constructor( 
    private showMessageError: ShowMessageError,
    private apiPrecoService: ApiPrecoService
    ) { }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }
  
  ngOnInit(): void {
    this.setStartObject();
    this.screenHeight = window.innerHeight;
  }

  cliqueLimpar(){
    this.filtro = '';
    this.onFocusPesquisar();
  }

  cliqueCancelar(){
    this.fecharModal();
  }

  cliqueImportar(){
    this.modalCaixa.hide();
  }
  modalAtiva: boolean = false;
  keyPress(event: KeyboardEvent) {
    if (this.modalAtiva){
      if (event.keyCode == 43) {
        this.cliqueCancelar();
      }
      if (event.keyCode == 13) {
        this.pesquisar();
      }
    }
  }

  @ViewChild('elmPesquisar') elmPesquisar: ElementRef = null!;
  onFocusPesquisar(){
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      if (this.elmPesquisar != null){
        this.elmPesquisar.nativeElement.focus();
      }
    },0);    
  }


  listarPrecos: ConsultaPrecoModel[] = [];
  subsGestPreco: Subscription = null!;
  filtro: string = '';
  loadingPesquisar: boolean = false;
  pesquisar() {
    this.loadingPesquisar = true;
    this.subsGestPreco = this.apiPrecoService.getPrecoByEanOuNomeProduto(ConstantsService.TabelaPreco.idTabelaPreco, this.filtro, enumTipoProduto.TodosProdutos, enumStatus.Ativo).subscribe(
      (retorno: ConsultaPrecoModel[]) => {
        this.loadingPesquisar = false;
        this.subsGestPreco?.unsubscribe();
        if (retorno != null) {
          this.listarPrecos = retorno;
        } else {
          this.showMessageError.exibirMensagensCustomizadas("Erro", ["Produto não possuí preço de venda cadastrado ou produto não eta ativo"], TiposMensagem.erro);
        }
      },
      (err) => {
        this.loadingPesquisar = false;
        this.subsGestPreco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  public abrirModal() {
    this.modalAtiva = true;
    this.filtro = '';
    this.setStartObject();
    this.modalCaixa.show();
    this.onFocusPesquisar();
  }

  fecharModal() {
    this.fechouModal.emit(null);
    this.modalCaixa.hide();
    this.modalAtiva = false;
  }

  bloquearCampos(){  }

  setStartObject(){
  }

}
