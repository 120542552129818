import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { AppMensagemService } from '../components/app-mensagem/app-mensagem.service';
import { ChangePasswordModel } from 'src/app/models/controle/Autenticacao/changePasswordModel';
import { CodeChangePasswordModel } from 'src/app/models/controle/Autenticacao/codeChangePassworkdModel';
import { ComplexidadeSenhaModel } from 'src/app/models/controle/Autenticacao/ComplexidadeSenhaModel';
import { LoginModel } from 'src/app/models/controle/Autenticacao/loginModel';
import { MessageChangePasswordModel } from 'src/app/models/controle/Autenticacao/messageChangePasswordModel';
import { SendCodeChangePasswordModel } from 'src/app/models/controle/Autenticacao/sendCodeChangePasswordModel';
import { SendMailForSendCodeModel } from 'src/app/models/controle/Autenticacao/sendMailForSendCodeModel';
import { TokenModel } from 'src/app/models/controle/Autenticacao/TokenModel';
import { TokenAcessoModel } from 'src/app/models/controle/TokenAcesso/tokenAcessoModel';
import { RefreshTokenLojaModel } from 'src/app/models/controle/loja/RefreshTokenLojaModel';
import { ConstantsService } from 'src/app/shared/constants/constants.service';

@Injectable()
export class AuthService {
  private controller: string = "autenticacao";
  private versao: string = "/api/";

  constructor(
    private http: HttpClient,
    private readonly mensagemService: AppMensagemService
  ) { }

  logout() {

    this.mensagemService.exibirLock();

    

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')

    return this.http
    .get(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/logout`, { headers: header })
    .pipe(map((data: any) => {
        this.mensagemService.esconderLock();

        ConstantsService.removeToken();
        localStorage.clear();

        return data;
      }),
      catchError(error => {
        this.mensagemService.esconderLock();

        ConstantsService.removeToken();

        return throwError(error);
      }))
      .pipe(err => {
      //this.mensagemService.esconderLock();
      return err;
    });
  }

  sendMailChangePassword(sendMailForSendCode: SendMailForSendCodeModel) {

    this.mensagemService.exibirLock();

    return this.http.post(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/sendMailChangePassword`, sendMailForSendCode,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    )
    .pipe(map((data: any) => {
          this.mensagemService.esconderLock();
          return data as CodeChangePasswordModel;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  }

  sendCodeChangePassword(sendCodeChangePassword: SendCodeChangePasswordModel) {

    this.mensagemService.exibirLock();

    return this.http.post(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/sendCodeChangePassword`, sendCodeChangePassword,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    )
    .pipe(map((data: any) => {
          this.mensagemService.esconderLock();
          return data;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  }

  changePassword(changePassword: ChangePasswordModel) {

    this.mensagemService.exibirLock();

    return this.http.post(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/changePassword`, changePassword,
      { headers: new HttpHeaders().set('Content-Type', 'application/json')
          //.set('Authorization', `Bearer ${this.Token}`) 
        }
    )
    .pipe(map((data: any) => {
          this.mensagemService.esconderLock();
          return data as MessageChangePasswordModel;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  }

  verificarComplexidadeSenha(complexidade: ComplexidadeSenhaModel){
    
    return this.http.post(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/verificarComplexidadeSenha`, complexidade,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    )
    .pipe(map((data: any) => {
          this.mensagemService.esconderLock();
          return data as ComplexidadeSenhaModel;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  
  }

  login(login: LoginModel) {

    this.mensagemService.exibirLock();

    return this.http.post(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/login`, login,
      { headers: new HttpHeaders().set('Content-Type', 'application/json') }
    )
    .pipe(map((data: any) => {
          this.mensagemService.esconderLock();
          return data as TokenModel;
        }));
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    //this.mensagemService.esconderLock();
    // just a test ... more could would go here
    return throwError(() => err);
  }

  refreshTokenComLoja(refreshTokenComLoja: RefreshTokenLojaModel, exibeCarregando: boolean | false) {
    
    if (exibeCarregando)
      this.mensagemService.exibirLock();

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json')
      //.set('Authorization', `Bearer ${this.Token}`);

    return this.http.post(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/refreshTokenComLoja`, refreshTokenComLoja,
      { headers: header }
    )
    .pipe(map((data: any ) => {
          if (exibeCarregando)
            this.mensagemService.esconderLock();
          return data as any;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  }

  refreshToken() {
    this.mensagemService.exibirLock();

    return this.http.get(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/refreshToken`, 
      { headers: new HttpHeaders().set('Content-Type', 'application/json')
          //.set('Authorization', `Bearer ${this.Token}`) 
        }
    )
    .pipe(map((data: any) => {
          this.mensagemService.esconderLock();
          return data as TokenAcessoModel;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  }

  solicitarToken(token: string, exibeCarregando: boolean) {
    
    if (exibeCarregando)
      this.mensagemService.exibirLock();

    ConstantsService.Token = token;

    const header = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.http.get(
      environment.apiSettings.baseUrl.webApiCtrl + `${this.versao}${this.controller}/solicitarToken`,
      { headers: header }
    )
    .pipe(map((data: any ) => {
          if (exibeCarregando)
            this.mensagemService.esconderLock();
          return data as any;
        }),
        catchError(error => {
          this.mensagemService.esconderLock();
          return throwError(error);
        }));
  }




  isAuthenticated() {
    if (environment.autenticar) {
      if (ConstantsService.Token != null && ConstantsService.Token != '') {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  
}