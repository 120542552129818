import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AdicionarPagamentoPdvModel } from 'src/app/models/cliente/FormaPagamento/AdicionarPagamentoPdvModel';
import { FormaPagamentoModel } from 'src/app/models/cliente/FormaPagamento/formaPagamentoModel';
import { OrcamentoSimulacaoPagamentoModel } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/orcamentoSimulacaoPagamentoModel';
import { FinalizarPagamentoVendaPdvModel } from 'src/app/models/cliente/Vendum/FinalizarPagamentoVendaPdvModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiPagamentoService {
    
    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private controller = 'pagamento';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }
    getOrcamentoSimulacaoPagamentoById(idOrcamentoSimulacaoPagamento: number, exibeCarregando: boolean ): Observable<OrcamentoSimulacaoPagamentoModel> {
        return this.gc.get(this.api, `api/${this.controller}/getOrcamentoSimulacaoPagamentoById/${idOrcamentoSimulacaoPagamento}`, exibeCarregando);
    }  
    cancelarOrcamentoSimulacaoPagamento(idOrcamentoSimulacaoPagamento: number, exibeCarregando: boolean ): Observable<OrcamentoSimulacaoPagamentoModel> {
        return this.gc.post(this.api, `api/${this.controller}/cancelarOrcamentoSimulacaoPagamento`, idOrcamentoSimulacaoPagamento, exibeCarregando);
    } 
    salvarOrcamentoSimulacaoPagamento(orcamentoSimulacaoPagamentoModel: OrcamentoSimulacaoPagamentoModel, exibeCarregando: boolean ): Observable<OrcamentoSimulacaoPagamentoModel> {
        return this.gc.post(this.api, `api/${this.controller}/salvarOrcamentoSimulacaoPagamento`, orcamentoSimulacaoPagamentoModel, exibeCarregando);
    }
    selecionarOrcamentoSimulacaoPagamento(orcamentoSimulacaoPagamentoModel: OrcamentoSimulacaoPagamentoModel, exibeCarregando: boolean ): Observable<OrcamentoSimulacaoPagamentoModel> {
        return this.gc.post(this.api, `api/${this.controller}/selecionarOrcamentoSimulacaoPagamento`, orcamentoSimulacaoPagamentoModel, exibeCarregando);
    }
    iniciarPagamento(venda: VendumModel, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `api/${this.controller}/iniciarPagamento`, venda, exibeCarregando);
    }
    adicionarPagamentoPdv(adicinarFormaPagamento: AdicionarPagamentoPdvModel, exibeCarregando: boolean ): Observable<FormaPagamentoModel> {
        return this.gc.post(this.api, `api/${this.controller}/adicionarPagamentoPdv`, adicinarFormaPagamento, exibeCarregando);
    }
    removerPagamentoPdv(adicinarFormaPagamento: AdicionarPagamentoPdvModel, exibeCarregando: boolean ): Observable<FormaPagamentoModel> {
        return this.gc.post(this.api, `api/${this.controller}/removerPagamentoPdv`, adicinarFormaPagamento, exibeCarregando);
    }
    // finalizarPagamento(venda: VendumModel, idPdv: number, idCaixaControle: number, exibeCarregando: boolean ): Observable<VendumModel> {
    //     return this.gc.post(this.api, `api/${this.controller}/finalizarPagamento/${idPdv}/${idCaixaControle}`, venda, exibeCarregando);
    // }
    finalizarPagamento(parametrosFinalizarVenda: FinalizarPagamentoVendaPdvModel, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `api/${this.controller}/finalizarPagamento/${parametrosFinalizarVenda.idPdv}/${parametrosFinalizarVenda.idCaixaControle}`, parametrosFinalizarVenda, exibeCarregando);
    }
}
