import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (environment.autenticar) {
      if (!this.authService.isAuthenticated()) {
      //if (false) {
        this.router.navigate(['/login'], {fragment: 'naoAutorizado'});
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
