import swal from 'sweetalert2';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { ApiCaixaService } from 'src/app/services/pdv/api-caixa.service';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ConstantsService } from '../../constants/constants.service';
import { CaixaModel } from 'src/app/models/cliente/Caixa/caixaModel';
import { enumAcaoSangriaSuprimento } from 'src/app/enum/enumAcaoSangriaSuprimento';
import { CaixaSangriaSuprimentoModel, CaixaSangriaSuprimentoModelInstance } from 'src/app/models/cliente/CaixaSangriaSuprimento/caixaSangriaSuprimentoModel';
import { enumTipoConsultaMeioPagamento } from 'src/app/enum/enumTipoConsultaMeioPagamento';
import { enumIndicadorMeioPagamento } from 'src/app/enum/enumIndicadorMeioPagamento';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { ComboTabelaCentroCustoComponent } from '../combo-tabela-centro-custo/combo-tabela-centro-custo.component';
import { ComboTabelaMeioPagamentoComponent } from '../combo-tabela-meio-pagamento/combo-tabela-meio-pagamento.component';
import { TabelaMeioPagamentoModel } from 'src/app/models/cliente/TabelaMeioPagamento/tabelaMeioPagamentoModel';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { InputNumber } from 'primeng/inputnumber';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { enumFormatoImpressao } from 'src/app/enum/enumFormatoImpressao';
import { ModalImpressaoComponent } from '../modal-impressao/modal-impressao.component';
import { ApiGeraRelatorioConferenciaCaixaService } from 'src/app/services/relatorio/api-gera-relatorio-conferencia-caixa.service';
import { FiltroRelatorioConferenciaCaixa } from 'src/app/models/relatorios/FiltroRelatorioConferenciaCaixa';

@Component({
  selector: 'app-modal-sangria-suprimento',
  templateUrl: './modal-sangria-suprimento.component.html',
  styleUrls: ['./modal-sangria-suprimento.component.scss']
})
export class ModalSangriaSuprimentoComponent implements OnInit {

  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @ViewChild(UiModalComponent) modal;
  @ViewChild('comboTabelaMeioPagamentoComponent') public comboTabelaMeioPagamentoComponent!: ComboTabelaMeioPagamentoComponent;
  @ViewChild('comboTabelaCentroCustoComponent') public comboTabelaCentroCustoComponent!: ComboTabelaCentroCustoComponent;
  @ViewChild(ModalImpressaoComponent) modalImpressaoComponent!: ModalImpressaoComponent;
  inputDados: CaixaSangriaSuprimentoModel = null!;
  listaCaixas: CaixaModel[] = [];
  tipoConsultaMeioPagamento: enumTipoConsultaMeioPagamento = enumTipoConsultaMeioPagamento.ParamSangriaSuprimento;
  indicadorMeioPagamento: enumIndicadorMeioPagamento = enumIndicadorMeioPagamento.PagamentoAVista;

  @Output() executouSangriaSuprimento: EventEmitter<CaixaSangriaSuprimentoModel> = new EventEmitter<CaixaSangriaSuprimentoModel>();

  loading: boolean = true;
  subsConsultar: Subscription = null!
  tipoAcao: enumAcaoSangriaSuprimento = null!;
  sangria: enumAcaoSangriaSuprimento = enumAcaoSangriaSuprimento.Sangria;
  suprimento: enumAcaoSangriaSuprimento = enumAcaoSangriaSuprimento.Suprimento;
  quitado: boolean = false;
  enumMeioPagCheque : enumMeioPagamento = enumMeioPagamento.Cheque;

  subsGerarExtrato: Subscription | null = null;
  nomeArquivo: string = '';
  Cupom80mm: enumFormatoImpressao = enumFormatoImpressao.Cupom80mm;
  habilitaBotaoImpressao: boolean = false;

  constructor( 
    private apiCaixaService: ApiCaixaService,
    private apiGeraRelatorioConferenciaCaixa: ApiGeraRelatorioConferenciaCaixaService,
    private showMessageError: ShowMessageError,
    private readonly mensagemService: AppMensagemService
    ) { }

  ngOnInit(): void {    
  }

  cliqueCancelar(){    
    this.fecharModal();
  }

  @ViewChild('valorSuprimento') elmValorSuprimento: InputNumber  = null!;
  onFOcusValorSuprimento(){
    setTimeout(()=>{ 
      if (this.elmValorSuprimento != null){
        this.elmValorSuprimento.input.nativeElement.focus();
      }
    },120);    
  }

  cliqueImportar(){
    this.modal.hide();
    this.executouSangriaSuprimento.emit(this.inputDados);
  }

  changeTabelaMeioPagamento(valor: TabelaMeioPagamentoModel) {
    if (valor.idTabelaMeioPagamento != enumMeioPagamento.Dinheiro && valor.idTabelaMeioPagamento != enumMeioPagamento.Cheque) {
      //Exibe informações de cartão
      swal.fire('Atenção', 'Sangria pode ser executada apenas por Meio de Pagamento "Dinheiro" ou "Cheque"', TiposMensagem.info);
    } else {
      if (valor.idTabelaMeioPagamento == enumMeioPagamento.Dinheiro){
        this.quitado = false;
      }
      this.consultarSaldoAtual(valor.idTabelaMeioPagamento);
    }
  }

  tituloModal: string = '';
  public abrirModal(tipoAcao: enumAcaoSangriaSuprimento) {
    this.setStartObject();
    if (ConstantsService.SituacaoCaixa != enumSituacaoCaixa.Aberto){
      swal.fire({
        title: "Erro",
        text: "Caixa deve estar aberto para realizar a Sangria ou Suprimento",
        showCancelButton: false,
        icon: 'error'
      })
      .then((res) => {
        if (res.value) {
          return;
        }
      });
    }else{
      // this.comboTabelaCentroCustoComponent.getLista(null!);
      // this.comboTabelaMeioPagamentoComponent.getItens();
      this.tipoAcao = tipoAcao;
      switch(tipoAcao){
        case enumAcaoSangriaSuprimento.Sangria:
          this.tituloModal = "Sangria";
        break;
        case enumAcaoSangriaSuprimento.Suprimento:
          this.tituloModal = "Suprimento";
        break;
      }

      this.onFOcusValorSuprimento();
      this.inputDados.valor = null;
      this.inputDados.idTabelaMeioPagamento = enumMeioPagamento.Dinheiro; //jander
      this.consultarSaldoAtual(enumMeioPagamento.Dinheiro);
    }
  }

  keypress(valor: KeyboardEvent){
    if (valor.keyCode == 13){ //enter
     this.realizarSangriaSuprimento();
    }
  }

  saldoAtual: number = 0;
  novoSaldoAtual: number = 0;
  subsGetTotalMovimentoByIdCaixaControleEMeioPagamento: Subscription = null!;
  consultarSaldoAtual(idTabelaMeioPagamento: enumMeioPagamento){
    this.loading = true;
    this.subsGetTotalMovimentoByIdCaixaControleEMeioPagamento = this.apiCaixaService.getCaixaControleById(ConstantsService.IdCaixaControle, true)
      .subscribe( (retorno: CaixaControleModel) => {
        this.loading = false;
        this.subsGetTotalMovimentoByIdCaixaControleEMeioPagamento?.unsubscribe();
        
        if(idTabelaMeioPagamento == enumMeioPagamento.Dinheiro){
          this.saldoAtual = retorno.valorTotalDinheiro!;
        }else{ //cheque
          this.saldoAtual = retorno.valorTotalCheque!;
        }

        if (this.tipoAcao == enumAcaoSangriaSuprimento.Sangria){
          this.novoSaldoAtual = this.saldoAtual - this.inputDados.valor!;
        }else if (this.tipoAcao == enumAcaoSangriaSuprimento.Suprimento){
          this.novoSaldoAtual = this.saldoAtual + this.inputDados.valor!;
        }
        this.modal.show();
      },
        (err) => {
          this.subsGetTotalMovimentoByIdCaixaControleEMeioPagamento?.unsubscribe();
          this.loading = false;
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  ngModelChangeValor(valor: number){
    if (this.tipoAcao == enumAcaoSangriaSuprimento.Sangria){
      this.novoSaldoAtual = this.saldoAtual - valor;
    }else if (this.tipoAcao == enumAcaoSangriaSuprimento.Suprimento){
      this.novoSaldoAtual = this.saldoAtual + valor;
    }
  }

  subsAbrirCaixa: Subscription = null!;
  protected realizarSangriaSuprimento(){
    this.showMessageError.limparMensagensAlerta();
    this.inputDados.idCaixaControle = ConstantsService.IdCaixaControle;
    this.inputDados.tipoMovimento = this.tipoAcao;
    this.inputDados.idPdv = ConstantsService.IdPdv;

    let mensagemSucesso:string = '';
    if (this.tipoAcao == enumAcaoSangriaSuprimento.Sangria)
    {
      mensagemSucesso = 'Sangria executada com sucesso!<br>Deseja imprimir?';
    }
    if (this.tipoAcao == enumAcaoSangriaSuprimento.Suprimento)
    {
      mensagemSucesso = 'Suprimento executado com sucesso!<br>Deseja imprimir?';
    }

    this.subsAbrirCaixa = this.apiCaixaService.executarSangriaSuprimento(this.inputDados, this.quitado, true)
      .subscribe( (retorno: CaixaSangriaSuprimentoModel) => {
        this.loading = false;
        this.subsAbrirCaixa?.unsubscribe();
        swal.fire({
          title: "Sucesso",
          html: mensagemSucesso,
          icon: 'success',
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then((result) => {
          if (result.isConfirmed) {
            this.inputDados = retorno;
            this.habilitaBotaoImpressao = true;
            this.imprimir(this.Cupom80mm);
          }else{
            this.inputDados = retorno;
            this.executouSangriaSuprimento.emit(this.inputDados);
            this.fecharModal();
          }
        });
      },
        (err) => {
          this.subsAbrirCaixa?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  onChangeQuitado(event: any) {
    if (event.target.checked) {
      this.quitado = true;
    } else {
      this.quitado = false;
    }
  }
  
  fecharModal() {
    this.fechouModal.emit(null);
    this.showMessageError.limparMensagensAlerta();
    this.quitado = false;
    this.habilitaBotaoImpressao = false;
    this.modal.hide();
  }

  bloquearCampos(){  }

  setStartObject(){
    this.inputDados = CaixaSangriaSuprimentoModelInstance.get();
    this.inputDados.idTabelaMeioPagamento = enumMeioPagamento.Dinheiro;
  };

  onAbriuModalConfiguracao(event:any){
    //PdvComponent.modalAtiva = true;
  }
  onFechouModalAlteracao(event:any){
    //PdvComponent.modalAtiva = false;
  }

  imprimir(formatoImpressao: string){
    this.loading = true;
    let parametrosImpressao: FiltroRelatorioConferenciaCaixa = {
      customizacao: {
        alturaLogo: 100,
        larguraLogo: 100,
        exibeLogo: false,
        exibePaginacao: true,
        layout: formatoImpressao,
        margemSuperior: 30,
        margemInferior: 30,
        margemEsquerda: 30,
        margemDireita: 30
      },
      idLoja: null,
      idCaixa: null,
      tipoRelatorio: '',
      situacao: '',
      dataAbertura: null,
      idCaixaSangriaSuprimento: this.inputDados.idCaixaSangriaSuprimento
    };

    var dataAtual = new Date;
    this.nomeArquivo = `extrato-sangria-suprimento-${this.inputDados.idCaixaSangriaSuprimento}-${dataAtual.valueOf()}.pdf`;
    this.mensagemService.exibirLock();
    this.subsGerarExtrato = this.apiGeraRelatorioConferenciaCaixa.gerarExtratoSangriaSuprimento(parametrosImpressao, true)
      .subscribe((fileData: any) => {
        this.subsGerarExtrato?.unsubscribe();
        // Agora o pai pode chamar uma função no componente filho
        this.modalImpressaoComponent.imprimir("29", fileData, 'application/pdf');                
        this.loading = false;
        this.mensagemService.esconderLock();
      },
      (err) => {
         this.subsGerarExtrato?.unsubscribe();
         this.showMessageError.exibirMensagemValidacaoTela(err);
         this.mensagemService.esconderLock();
         this.loading = false;
      }
    );
  }
}