import { enumSimNao } from "src/app/enum/enumSimNao";
import { enumStatus } from "src/app/enum/enumStatus";
import { PedidoVendaProdutoModel } from "./PedidoVendaProdutoModel";

export class PedidoVendaProdutoModelInstance {
    public static get(): PedidoVendaProdutoModel {
        return {
            idPedidoVendaProduto: 0,
            idPedidoVenda: null,
            idProdutoUnico: 0,
            idProdutoVariacao: null,
            detalhe: '',
            detalheImpressao: '',
            quantidade: null,
            precoUnitario: 0,
            valorTotalFrete: 0,
            valorDesconto: 0,
            valorOutrasDespesas: 0,
            valorProdutoServico: 0,
            valorTotal: 0,
            numeroPedidoCompra: '',
            indicaValorItemValorTot: '',
            dataPrevistaEntregaAgendada: null,
            dataPrevistaParaEntregaCrossdoking: null,
            quantidadeEntregueNoAto: 0,
            quantidadeParaEntregaAgendada: null,
            quantidadeParaEntregaCrossdoking: null,
            numeroItem: null,
            valorCustoUnitario: 0,
            valorCustoTotal: 0,
            movimentouEstoque: enumSimNao.Nao,
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            valorSeguro: 0,
            emitiuNf: enumSimNao.Nao,
            numeroItemOrigem: null,
            itemPedidoCompra: null,
            unidadeMedidaComercial: '',
            unidadeMedidaTributavel: '',
            situacao: enumStatus.Ativo,
            valorDescontoCondicionado: null,
            valorDescontoIncondicionado: null,
            idTabelaCfop: null!,
            quantidadeASerDevolvido: 0,
            quantidadeJaDevolvido: 0,
            movimentouEstoqueVirtualSaida: enumSimNao.Nao,
            valorTotalSeguro: 0,
            idPedidoVendaNavigation: null!,
            idProduto: null!,
            idProdutoUnicoNavigation: null!,
            precoUnitarioBase: null,
            alterouPrecoUnitario: enumSimNao.Nao,
            unidadeTipoMedidaAltura: null,
            unidadeTipoMedidaComprimento: null,
            unidadeTipoMedidaLargura: null,
            unidadeQuantidadeItem: null,
        };
    }
    public static getArray(): PedidoVendaProdutoModel[] {
        return [this.get()];
    }
}
