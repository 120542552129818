import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ComboTabelaCentroCustoComponent } from './combo-tabela-centro-custo.component';

@NgModule({
  declarations: [ComboTabelaCentroCustoComponent],
  imports: [
    CommonModule,
   
    SharedModule,  NgbPopoverModule, NgbTooltipModule
  ],
  exports:[
    ComboTabelaCentroCustoComponent, CommonModule, SharedModule,  NgbPopoverModule, NgbTooltipModule
  ]
})

export class ComboTabelaCentroCustoModule { }
