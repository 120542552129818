import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumTabelaStatusPedidoVenda } from 'src/app/enum/enumTabelaStatusPedidoVenda';
import { ParametrosConsultaPedidoVendaModel } from 'src/app/models/cliente/PedidoVenda/ParametrosConsultaPedidoVendaModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiPedidoVendaService } from 'src/app/services/pdv/api-pedido-venda-service';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-selecionar-pedido-venda-para-venda',
  templateUrl: './modal-selecionar-pedido-venda-para-venda.component.html',
  styleUrls: ['./modal-selecionar-pedido-venda-para-venda.component.css']
})
export class ModalSelecionarPedidoVendaParaVendaComponent implements OnInit, OnDestroy {

  loading: boolean = false;
  @ViewChild(UiModalComponent) modal;
  @Output() selecionouPedidoVenda: EventEmitter<number> = new EventEmitter();
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();

  codigoPedidoDeVenda: string = '';
  pedidosVenda: ParametrosConsultaPedidoVendaModel[] = [];
  pedidosDeVendaFilter: ParametrosConsultaPedidoVendaModel[] = [];
  subsListarPedidoVendaByStatus: Subscription = null!;
  subsImportarPedidoParaPagamento: Subscription = null!;
  idsPedidosVendaSelecionado: number[] = [];
  venda: VendumModel = null!;

  constructor(private apiPedidoVenda: ApiPedidoVendaService,
    private apiVendaServico: ApiVendaService,
    private appCustomToastService: AppCustomToastService,
    private showMessageError: ShowMessageError,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subsListarPedidoVendaByStatus?.unsubscribe();
    this.subsImportarPedidoParaPagamento?.unsubscribe();
  }

  @ViewChild('elmCodigoPedidoVenda') elmCodigoPedidoVenda: ElementRef  = null!;
  onFocusCodigoPedidoDeVenda(){
    setTimeout(()=>{ 
      if (this.elmCodigoPedidoVenda != null && this.elmCodigoPedidoVenda.nativeElement != null){
        this.elmCodigoPedidoVenda.nativeElement!.focus();
      }
    },200);    
  }
  
  keyPressCodigoPedidoVenda(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13) {
      let number = !isNaN(Number.parseInt(this.codigoPedidoDeVenda));
      if (this.codigoPedidoDeVenda != '' && number){
        let pedidosDeVenda = this.pedidosVenda.filter(f => f.idPedidoVenda == Number.parseInt(this.codigoPedidoDeVenda));
        if (pedidosDeVenda.length == 0){
          this.appCustomToastService.toast(TiposMensagem.info, ['Pedido de venda não encontrado'], "Atenção");
        }else{
          if (this.idsPedidosVendaSelecionado.findIndex(f => f == pedidosDeVenda[0].idPedidoVenda) == -1){
            this.idsPedidosVendaSelecionado.push(pedidosDeVenda[0].idPedidoVenda!);
          }
        }
      }else{
        this.pedidosDeVendaFilter = this.pedidosVenda
      }
      this.codigoPedidoDeVenda = '';
    }
  }

  cliqueCancelar(){
    this.fecharModal();
  }
    
  abrirModal(venda: VendumModel) {
    this.idsPedidosVendaSelecionado = [];
    this.venda = venda;
    this.listarPedidoDeVendaByStatus();
    this.onFocusCodigoPedidoDeVenda();
    this.modal.show();
  }

  fecharModal(){
    this.fechouModal.emit(null!);
    this.codigoPedidoDeVenda = '';
    this.modal.hide();
  }

  listarPedidoDeVendaByStatus(){
    this.loading = true;
    this.subsListarPedidoVendaByStatus = this.apiPedidoVenda.getAllPedidosByStatusParaPdv(enumTabelaStatusPedidoVenda.Finalizado, false)
      .subscribe( (retorno: ParametrosConsultaPedidoVendaModel[]) => {
        this.loading = false;
        this.subsListarPedidoVendaByStatus?.unsubscribe();
        if (retorno != null){
          this.pedidosVenda = retorno;
        }else{
          this.pedidosVenda = [];
        }
        this.pedidosDeVendaFilter = this.pedidosVenda;
      },
        (err) => {
          this.loading = false;
          this.subsListarPedidoVendaByStatus?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  changeSelecionarTodosPedidosVenda(event){
    let selecionado = event.target.checked;
    if (selecionado && this.pedidosDeVendaFilter.length >= 1){
      this.pedidosDeVendaFilter.forEach(f =>{
        this.idsPedidosVendaSelecionado.push(f.idPedidoVenda!);
      })
    }else{
      this.idsPedidosVendaSelecionado = [];
    }
  }

  onChangeSelecionarPedidoVenda(event: any, idPedidoVenda: number){
    let selecionado = event.target.checked;
    if (selecionado && this.idsPedidosVendaSelecionado.indexOf(idPedidoVenda) == -1){
      this.idsPedidosVendaSelecionado.push(idPedidoVenda);
    }else if(this.idsPedidosVendaSelecionado.indexOf(idPedidoVenda) > -1){
      let index = this.idsPedidosVendaSelecionado.indexOf(idPedidoVenda);
      this.idsPedidosVendaSelecionado.splice(index, 1);
    }
  }
  
  getPedidoVendaSelecionado(idPedidoVenda: number): boolean{
    if (this.idsPedidosVendaSelecionado.indexOf(idPedidoVenda) > -1) return true;
    return false;
  }


  realizarAVenda(){
    if (this.idsPedidosVendaSelecionado.length == 0){
      Swal.fire({
        title: "Atenção",
        html: "Selecione ao menos um pedido para continuar!",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
        });
      return;
    }else{
      this.importarPedido();
    }
  }

  importarPedido(){
    this.loading = true;
    this.subsImportarPedidoParaPagamento = this.apiVendaServico.importarPedidoVendaParaPagamento(this.idsPedidosVendaSelecionado!, ConstantsService.IdCaixa, ConstantsService.IdPdv, true)
      .subscribe((retorno: number) => {
        this.loading = false;
        this.subsImportarPedidoParaPagamento?.unsubscribe();
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Pedido(s) de venda importado(s)'], "Sucesso");
          this.selecionouPedidoVenda.emit(retorno);
          this.fecharModal();
        }
      },
      (err) => {
        this.loading = false;
        this.subsImportarPedidoParaPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
}