import { enumSimNao } from "src/app/enum/enumSimNao";
import { OrcamentoSimulacaoPagamentoModel } from "./orcamentoSimulacaoPagamentoModel";


export class OrcamentoSimulacaoPagamentoModelInstance {
    public static get(): OrcamentoSimulacaoPagamentoModel {
        return {
            idOrcamentoSimulacaoPagamento: 0,
            idOrcamento: null,
            descricao: '',
            selecionado: enumSimNao.Nao,
            valorDespesaCondicaoPagamento: null,
            valorJurosCondicaoPagamento: null,
            valorDescontoCondicaoPagamento: null,
            valorDescontoVendedor: null,
            idOrcamentoNavigation: null!,
            formaPagamentos: [],
            numeroItem: 0,
            valorTotal: 0
        };
    }
}
