import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ExtratoCartaoFidelidadeModel } from 'src/app/models/cliente/CartaoFidelidade/ExtratoCartaoFidelidadeModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiCartaoFidelidadeService {

    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private controller = 'cartaoFidelidade';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }


    extratoCartaoFidelidade(cpfCnpj: string, exibeCarregando: boolean): Observable<ExtratoCartaoFidelidadeModel> {
        return this.gc.get(this.api, `api/${this.controller}/extratoCartaoFidelidade/${cpfCnpj}`, exibeCarregando);
    }

    resgatarRecompensa(idCartaoFidelidadeAcumuloPontos: number, 
        idCartaoFidelidadeRecompensa: number, 
        idCartaoFidelidade: number, 
        cpfCnpj: string, 
        exibeCarregando: boolean ): Observable<void> {
        return this.gc.post(this.api, 
            `api/${this.controller}/resgatarRecompensa?idCartaoFidelidadeAcumuloPontos=${idCartaoFidelidadeAcumuloPontos}&idCartaoFidelidadeRecompensa=${idCartaoFidelidadeRecompensa}&idCartaoFidelidade=${idCartaoFidelidade}&cpfCnpj=${cpfCnpj}`, 
            exibeCarregando);
    }

}
