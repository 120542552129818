<div class="topo-miolo-orcamento" >
    <div class="col-2 ps-2">
        <span class="title-topo-miolo">
            <i class="far fa-file-invoice-dollar text-c-white icon-topo-miolo"></i> Orçamento
        </span>
    </div>
    <div class="text-center col-1" *ngIf="inputDados == null">
        <b>
            <span class="text-center" ><label
                class="badge bg-warning text-c-white fs-7">Em Preenchimento</label></span>
        </b>
    </div>
    <div class="text-center col-1" *ngIf="inputDados != null">
       
        <b>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusOrcamento == 1"><label
                class="badge bg-warning text-c-white fs-7">Em Preenchimento</label></span>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusOrcamento == 2"><label
                class="badge bg-warning text-c-white fs-7">Em Orçamento</label></span>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusOrcamento == 3"><label
                class="badge bg-success text-c-white fs-7">Finalizada</label></span>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusOrcamento == 4"><label
                class="badge bg-danger text-c-white fs-7">Cancelada</label></span>    
        </b>
          
    </div>
    <div class="text-center col-1" *ngIf="inputDados == null">
        <div class="row">
            <div class="col-12">
                Código#<br><b>0</b>
            </div>
        </div>
    </div>
    <div class="text-center col-1" *ngIf="inputDados != null">
        <div class="row">
            <div class="col-12">
                Código#<br><b>{{inputDados.idOrcamento}}</b>
            </div>
        </div>
    </div>
    <div *ngIf="inputDados == null " class="col-1">
        Execução
    </div>
    <div *ngIf="inputDados != null " class="col-1">
        Execução
        {{inputDados.dataExecucao | date: 'dd/MM/yyyy' }}
    </div>
    <div *ngIf="inputDados == null || ( inputDados != null && inputDados.dataPrevistaEntrega == null)" class="col-1">
        Previsto<br>N/D
    </div>
    <div *ngIf="inputDados != null && inputDados.dataPrevistaEntrega != null" class="col-1">
        Previsto
        {{inputDados.dataPrevistaEntrega | date: 'dd/MM/yyyy' }}
    </div>
    <div *ngIf="inputDados == null " class="col-1">
        Validade
    </div>
    <div *ngIf="inputDados != null " class="col-1">
        Validade
        {{inputDados.dataValidadeOrcamentoPrevenda | date: 'dd/MM/yyyy' }}
    </div>
    <div *ngIf="inputDados != null && inputDados.cpfCnpjNavigation != null" class="col-2">
        Cliente<br>
        <a (click)="exibirInformacoesCliente()" class="mouse-pointer text-c-white text-decoration-underline" >
            {{NomeCliente}}</a>
    </div>
    <div *ngIf="inputDados == null || (inputDados != null && inputDados.cpfCnpjNavigation == null)" class="col-2">
        <li>Cliente não informado</li>
    </div>
    <div class="col-3 actns text-center">
        <div class="row row-icone-atalho-header">
            <div class="col-2">
                <li>
                    <span (click)="iniciarOrcamento(null!)" class="mouse-pointer   " ngbTooltip="Novo Orçamento" *ngIf="!loading">
                        <i class="fad fa-plus  f-28 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Iniciar uma Novo Orçamento" *ngIf="loading">
                        <i class="fad fa-plus  f-28 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2">
                <li>
                    <span (click)="listarSimulacoesPendentes()" class="mouse-pointer" ngbTooltip="Simulações de Pagamento" *ngIf="!loading">
                        <i class="fad fa-list f-28 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Visualizar as Simulações de Pagamento" *ngIf="loading">
                        <i class="fad fa-list f-28 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null">
                <li>
                    <span (click)="removerOrcamento()" class="mouse-pointer" ngbTooltip="Excluir Orçamento" *ngIf="!loading">
                        <i class="fad fa-trash-alt f-28 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Excluir" *ngIf="loading">
                        <i class="fad fa-trash-alt f-28 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null">
                <li>
                    <span (click)="clickSalvarOrcamento()" class="mouse-pointer" ngbTooltip="Salvar" *ngIf="!loading">
                        <i class="fad fa-save f-28 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Salvar" *ngIf="loading">
                        <i class="fad fa-save f-28 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null">
                <li>
                    <span (click)="clickSAnexarDocumentos()" class="mouse-pointer" ngbTooltip="Anexar Documento(s)" *ngIf="!loading">
                        <i class="fas fa-paperclip f-28 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Anexar Documento(s)" *ngIf="loading">
                        <i class="fad fa-paperclip f-28 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null && tabelaPrecoSelecionada != null">
                <li>
                    <div *ngIf="!loading" class="btn-group mouse-pointer " ngbDropdown [placement]="'bottom-right'" >
                        <i class="fal fa-usd-circle f-28 text-c-white pe-1" ngbDropdownToggle></i>
                        <div ngbDropdownMenu>
                            <a  class="dropdown-item mouse-pointer" 
                            href="javascript:" 
                            (click)="alterarTabelaPrecoNaVenda(item)"
                            *ngFor="let item of ListaTabelaDePreco; let i = index">{{item.nome}}
                            <i class="fad fa-check f-28 " *ngIf="item.idTabelaPreco == tabelaPrecoSelecionada.idTabelaPreco"></i>
                        </a>
                            
                        </div>
                    </div>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para alterar a tabela de preço" *ngIf="loading">
                        <i class="fal fa-usd-circle f-28 text-c-white"></i>
                    </span>
                </li>
            </div>
        </div>
    </div>
</div>
<div class="scroll-miolo btnsmiolo">
    <div class="scroll-interno" >
        <div class="ps-2 pe-2 pt-1 table-wrap" >
            <div class="row">
                <div class="col-12">
                    <app-pesquisa-por-produto 
                        [disabled]="loading"
                        (iniciar)="iniciar($event)"
                        #pesquisaPorProduto
                        (adicionarProduto)="adicionarProduto($event)" 
                        [inputDados]="inputDados" 
                        >
                    </app-pesquisa-por-produto>
                   
                </div>
            </div>
            <div class="row">
            <div class="col-12">
            <p-table  #dtOrcamento [value]="ListaProdutosPdv" dataKey="item" 
                    [scrollHeight]="heightLista" responsiveLayout="scroll"
                    selectionMode="single"
                    (onRowSelect)="onRowSelectItemOrcamento($event)" 
                    class="p-table nowrap table-hover " [loading]="loading" [(selection)]="orcamentoProduto" 
                    [paginator]="false" 
                    currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros"
                >
                <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center ">#</th>
                        <th  class="text-center">Descrição</th>
                        <th  class="text-center">Quantidade</th>
                        <th  class="text-center">Estoques &nbsp;<i class="fas fa-info-circle" ngbTooltip="Estoques (Contábil | Virtual | Reservado | Condicional)"></i></th>
                        <th  class="text-center">Valor Unitário</th>
                        <th class="text-center ">Sub Total</th>
                        <th class="text-center ">Total</th>
                        <th class="text-center p-r-10">Ações</th>
                        </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr [ngClass]="{'blink-red': blinkIndexRed === rowIndex}" [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center">{{ item.item }}</td>
                        <td>
                        {{ item.nome }} 
                        </td>
                        <td class="text-center">
                        {{ item.quantidade }}
                        </td>
                        <td class="text-center" >
                            <span>
                                {{ item.quantidadeEstoqueContabil }} | 
                                {{ item.quantidadeEstoqueVirtual }} | 
                                {{ item.quantidadeEstoqueReservado }} | 
                                {{ item.quantidadeEstoqueCondicional }}
                            </span>
                        </td>
                        <td class="text-end">
                        {{ item.precoVenda | currency:'BRL' }}
                        </td>
                        <td  class="text-end">
                        {{ item.subTotal | currency:'BRL' }}
                        </td>
                        <td class="text-center" >{{ item.valorTotal | currency:'BRL' }}</td>
                        <td class="table-action nowrap text-center">
                            <a ngbTooltip="Alterar" class=" " (click)="alterarItem(item, rowIndex)">
                                <i class="mouse-pointer fad fa-edit text-c-blue f-16 me-2"></i></a>
                            <a ngbTooltip="Estoque" class=" " (click)="exibirEstoque(item, rowIndex)"  *ngIf="item.quantidadeEstoqueContabil > 0">
                                <i class="mouse-pointer fad fa-box-full text-c-blue f-16 me-2"></i></a>
                            <a ngbTooltip="Estoque" class=" " (click)="exibirEstoque(item, rowIndex)" *ngIf="!(item.quantidadeEstoqueContabil > 0)">
                                <i class="mouse-pointer fad fa-box-open text-c-red f-16 me-2"></i></a>
                            <a ngbTooltip="Imagem" class=" " (click)="visualizarImagem(item, rowIndex)">
                                <i class="mouse-pointer text-c-gray fad fa-camera f-16 f-16 me-2"></i></a>
                            <a ngbTooltip="Remover" class=" " (click)="removerItem(item, rowIndex)">
                                <i class="mouse-pointer text-c-red fad fa-trash-alt f-16 f-16 me-2"></i></a>
                        </td>
                        <!-- <td class="">
                            <figure>
                                <img *ngIf="item.thumbnailImageSrc != ''" [src]="server + item.thumbnailImageSrc  | secure | async">
                            </figure>
                        </td> -->
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer"> 
                    <tr>
                        <td>TOTAL</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="text-end">
                            {{ subTotal | currency:'BRL' }}
                        </td>
                        <td class="text-end">
                            {{ total | currency:'BRL' }}
                        </td>
                        <td></td>
                    </tr>    
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="8">Não há produto(s)/serviço(s) registrado(s) para este orçamento</td>
                </tr>
                </ng-template>
            </p-table>
            </div>
        </div>
        </div>
    </div>
    <!-- fim aqui -->
    <div class="row" >
        <div class="col-11">
            
        </div>

    </div>
    <div class="table-wrap">

        <div class="col-12">
            
        </div>
    </div>
    <!-- REMOVER A BTNS WRAP DE LA DE CIMA E COLOCAR AQUI -->
    <div class="btns-wrap" >
        <div class="grupo-btn-rodape">
            <button class="btn btn-secondary  btn-md label-btn-nowrap" (click)="voltar()" [disabled]="loading">
                <i class="fad fa-arrow-left"></i>&nbsp;Voltar
            </button>
        </div>
        <div class="grupo-btn-rodape">
            <button class="btn btn-primary  btn-md label-btn-nowrap" (click)="adicionarCliente()" [disabled]="loading">
                <i class="fad fa-user-plus"></i>&nbsp;Cliente
            </button>
        </div>
        <div class="grupo-btn-rodape">
            <button class="btn btn-primary  btn-md label-btn-nowrap" (click)="informacoesAdicionais()" [disabled]="loading">
                <i class="fad fa-info-circle"></i>&nbsp;Detalhar
            </button>
        </div>
        <div class="grupo-btn-rodape" >
            <button class="btn btn-primary  btn-md label-btn-nowrap" (click)="simularPagamento()" [disabled]="loading">
                <i class="fad fa-comments-alt-dollar"></i>&nbsp;Simular Pgto
            </button>
        </div>
        <div class="grupo-btn-rodape" >
            <button class="btn btn-success  btn-md label-btn-nowrap" (click)="cliqueVender()" [disabled]="loading">
                <i class="fad fa-shopping-basket"></i>&nbsp;Vender 
            </button>
        </div>
        <div class="grupo-btn-rodape">
            <app-modal-impressao #modalImpressaoComponent class="text-start"
            (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
            (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
            [modalFrontLayer]="true"
            [codigoDocumento]="'09'"
            [textButton]="'Imprimir'"
            [typeButton]="'default'"
            [icon]="'fad fa-print ps-2'"
            (onImprimirFormatoA4)="cliqueImprimir(A4)"
            (onImprimirFormatoCupomFiscal)="cliqueImprimir(Cupom80mm)"
            >
            </app-modal-impressao>
        </div>
        <!-- <div class="grupo-btn-rodape" >
            <button class="btn btn-warning  btn-md label-btn-nowrap" (click)="cliqueImprimir()" [disabled]="loading">
                <i class="fad fa-print"></i>&nbsp;Imprimir 
            </button>
        </div> -->
    </div>
</div>
<app-modal-exibir-imagens #modalExibirImagensComponent
    (fechouModal)="fechouModal($event)"
></app-modal-exibir-imagens>
<app-lista-anexo [modoTela]="modoTela" #listaAnexo [modulo]="moduloListaAnexo" (fechouModal)="fechouModal($event)" *ngIf="inputDados != null && inputDados.idOrcamento != 0"
                [chave]="inputDados.idOrcamento.toString()" [tipoArquivo]="tipoArquivoAnexo" [titulo]="'Anexos'"
                [descricao]="'Informe anexo para o orçamento(s)'"
                (atualizarLista)="atualizarListaAnexo($event)"></app-lista-anexo>
<app-modal-informacoes-adicionais
    (salvouAlteracao)="salvouAlteracao($event)"
    (fechouModal)="fechouModal($event)"
></app-modal-informacoes-adicionais>
<app-modal-selecionar-cliente
    (selecionouCliente)="selecionouCliente($event)"
    (fechouModal)="fechouModal($event)"
    #modalSelecionarClienteComponent
></app-modal-selecionar-cliente>
<app-modal-situacao-cliente
    (fechouModal)="fechouModal($event)"
></app-modal-situacao-cliente>
<app-modal-alterar-item-orcamento
    (alterouItens)="alterouItensDeOrcamento($event)"
    #modalAlterarItemOrcamentoComponent
></app-modal-alterar-item-orcamento>
<app-modal-orcamento-estoque
    (fechouModal)="fechouModal($event)"
    #modalOrcamentoEstoqueComponent
></app-modal-orcamento-estoque>
<app-modal-lista-simulacoes
    (fechouModal)="fechouModal($event)"
    (alterouItens)="recarregarSimulacaoPagamento($event)"
    #modalListaSimulacoesComponent
></app-modal-lista-simulacoes>
<app-modal-abrir-fechar-caixa (abriuFechouCaixa)="abriuFechouCaixa($event)" (fechouModal)="fechouModal($event)" #modalSelecaoCaixaComponent></app-modal-abrir-fechar-caixa>