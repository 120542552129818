import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { FormaPagamentoParcelaModel } from 'src/app/models/cliente/FormaPagamentoParcela/formaPagamentoParcelaModel';
import { ApiFormaPagamentoService } from 'src/app/services/financeiro/api-forma-pagamento.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-forma-pagamento-parcela',
  templateUrl: './modal-forma-pagamento-parcela.component.html',
  styleUrls: ['./modal-forma-pagamento-parcela.component.css']
})
export class ModalFormaPagamentoParcelaComponent implements OnInit, OnDestroy {

  @ViewChild(UiModalComponent) modal;
  formaPagamentoParcelas: FormaPagamentoParcelaModel[] = [];
  subsGetAllParcelas: Subscription | null = null;
  loadingLista: boolean = false;

  constructor(private apiFormaPagamento: ApiFormaPagamentoService,
    private showMessageError: ShowMessageError) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subsGetAllParcelas?.unsubscribe();
  }

  public abrirModal(idFormaPagamento: number) {
    this.pesquisarParcelas(idFormaPagamento);
    this.modal.show();
  }

  cliqueCancelarCadastrar(){
    this.formaPagamentoParcelas = null!;
    this.modal.hide();
  }

  pesquisarParcelas(idFormaPagamento: number){
    this.loadingLista = true;
    this.subsGetAllParcelas = this.apiFormaPagamento.getAllParcelasByIdFormaPagamento(idFormaPagamento).subscribe(
      (retorno: FormaPagamentoParcelaModel[]) => {
        if(retorno != null){
          // retorno.forEach(f => {
          //   if (f.dataVencimento != null) f.dataVencimento = new Date(f.dataVencimento);
          // })!;
          this.formaPagamentoParcelas = retorno;
        }
        this.subsGetAllParcelas?.unsubscribe();
        this.loadingLista = false;        
      },
      (err) => {
        this.loadingLista = false;
        this.subsGetAllParcelas?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
}