import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "src/app/app-settings";
import { CommunicationManager } from "src/app/core/communication-manager";
import { ListaFixaImpressaoModel } from "src/app/models/cliente/ParametrizacaoEnvioImpressora/ListaFixaImpressaoModel";
import { ParametrizacaoEnvioImpressoraModel } from "src/app/models/cliente/ParametrizacaoEnvioImpressora/ParametrizacaoEnvioImpressoraModel";
import { AuthService } from "src/app/theme/shared/auth/auth.service";
import { AppMensagemService } from "src/app/theme/shared/components/app-mensagem/app-mensagem.service";

@Injectable({
    providedIn: 'root'
})
export class ApiTiposRelatoriosImpressoesService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'tiposRelatoriosImpressoes';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAllTiposImpressoes() {
      return this.gc.get(this.api, `${this.version}${this.controller}/getAllTiposImpressoes`, true);
    }
    getAllByIdPdvTiposImpressoes(idPdv: number) {
      return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdPdvTiposImpressoes/${idPdv}`, true);
    }
    alterarParametrizacaoEnvioImpressora(obj: ParametrizacaoEnvioImpressoraModel){
      return this.gc.put(this.api, `${this.version}${this.controller}/alterarParametrizacaoEnvioImpressora/${obj.codigoDocumentoImpresso}`, obj);
    }
    redefinirPadraoInicial(){
      return this.gc.put(this.api, `${this.version}${this.controller}/redefinirPadraoInicial`, true);
    }
    alterarParametrizacaoEmMassa(obj: ListaFixaImpressaoModel){
      return this.gc.put(this.api, `${this.version}${this.controller}/alterarParametrizacaoEmMassa`, obj, true);
    }
}