<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-sharp fa-solid fa-scanner-touchscreen icon-header-modal"></i>&nbsp;Selecionar Comanda</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row justify-content-center ">
            <div class="col-5"></div>
            <div class="col-2 wrap-form big-input">
                <input (keypress)="keyPressCodigoComanda($event)" class="form-control" placeholder="" [(ngModel)]="codigoComanda" #elmCodigoComanda>
            </div>
            <div class="col-5"></div>
        </div>
        <div class="row pt-3 pb-3 ">
            <div class="btns-tipo-mesa d-flex justify-content-center">
                    <button class="btn btn-primary   btn-md btn-comanda" (click)="selecionarComandaMesa()" [disabled]="loading">
                        <i class="fad fa-tablet-rugged"></i>&nbsp;Mesa
                    </button>
                    &nbsp;
                    <button class="btn btn-primary  btn-md btn-comanda" (click)="selecionarComandaFixa()" [disabled]="loading">
                        <i class="fad fa-barcode-alt"></i>&nbsp;Fixa
                    </button>
                    <!-- &nbsp;
                    <button class="btn btn-primary  btn-md btn-comanda" (click)="selecionarComandaSequencial()" [disabled]="loading">
                        <i class="fad fa-receipt"></i> &nbsp;Sequencial
                    </button> -->
                    &nbsp;
                    <button class="btn btn-danger  btn-md btn-comanda" (click)="selecionarComandasOcupadas()" [disabled]="loading">
                        <i class="fad fa-layer-minus"></i> &nbsp;Ocupadas
                    </button>
                    
            </div>
        </div>
        <div class="row" *ngIf="naoExisteParametrizacaoComanda && !loadingParametrizacaoComanda">
            <div class="col-12 text-center text-c-red">
                Não há comandas parametrizadas, para cadastrar acesse este link <b><a href="https://erp.invare.com.br/configuracao/comanda" target="_blank">Configuração Comanda</a></b>
            </div>
        </div>
        <div class="row scroll-miolo" *ngIf="!naoExisteParametrizacaoComanda ">
            <div class="col-12 text-center " *ngIf="comandas.length == 0  && !loading">
                {{mensagemPesquisa}}
            </div>
            <div class="col-12 col-md-12 row-comandas comanda-col5 d-flex justify-content-center" >
                    <div [class]="getClassComanda(item)"  *ngFor="let item of comandasFilter; let i = index">
                        <div *ngIf="item.situacao == 'L'" >
                            <div class="row" >
                                <h6 class="text-c-blue pt-1 "  >
                                    &nbsp;
                                    <!-- <i class="fad fa-list-ol icon-comanda" *ngIf="item.tipoComanda == 'S'"></i>
                                    <i class="fad fa-tablet-rugged icon-comanda" *ngIf="item.tipoComanda == 'M'"></i>
                                    <i class="fad fa-barcode-alt icon-comanda" *ngIf="item.tipoComanda == 'F'"></i>
                                    {{item.idComanda}}</h4> -->
                                    <i class="" *ngIf="item.tipoComanda == 'S'"><strong>S-</strong></i>
                                    <i class="" *ngIf="item.tipoComanda == 'M'"><strong>M-</strong></i>
                                    <i class="" *ngIf="item.tipoComanda == 'F'"><strong>F-</strong></i>
                                    <strong>{{item.idComanda}}</strong></h6>
                                <!-- <span class="text-c-blue " >Comanda <br>Livre</span> -->
                                <span class="text-c-blue mouse-pointer small " (click)="realizarPagamento(item)">{{getTextoComanda(item)}}</span>
                            </div>
                            <!-- <div class="row ps-3">
                                
                            </div> -->
                        </div>
                        <div *ngIf="item.situacao == 'O'" >
                            <div class="row" [ngbTooltip]="getUsuarioComanda(item)">
                                <h6 class="text-c-blue pt-1 mouse-pointer"  (click)="realizarPagamento(item)">
                                    &nbsp;
                                    <!-- <i class="fad fa-list-ol icon-comanda" *ngIf="item.tipoComanda == 'S'"></i>
                                    <i class="fad fa-tablet-rugged icon-comanda" *ngIf="item.tipoComanda == 'M'"></i>
                                    <i class="fad fa-barcode-alt icon-comanda" *ngIf="item.tipoComanda == 'F'"></i>
                                    {{item.idComanda}}</h4> -->
                                    <i class="" *ngIf="item.tipoComanda == 'S'"><strong>S-</strong></i>
                                    <i class="" *ngIf="item.tipoComanda == 'M'"><strong>M-</strong></i>
                                    <i class="" *ngIf="item.tipoComanda == 'F'"><strong>F-</strong></i>
                                    <strong>{{item.idComanda}}</strong></h6>
                                <!-- <span class="text-c-blue mouse-pointer" (click)="realizarPagamento(item)">Comanda <br>Ocupada</span> -->
                                <span class="text-c-blue mouse-pointer small " (click)="realizarPagamento(item)">{{getTextoComanda(item)}}</span>
                            </div>
                            <!-- <div class="row ps-3">
                                <div class="col-3" ><i (click)="visualizarProdutos(item)" ngbTooltip="Visualizar Resumo" class="text-c-blue mouse-pointer fad fa-scroll-old"></i></div>
                                <div class="col-3" ><i (click)="cliqueLiberarComanda(item, i)" ngbTooltip="Liberar Comanda" class="text-c-blue mouse-pointer fad fa-sharp fa-solid fa-scanner-touchscreen"></i></div>
                                <div class="col-3" ><i (click)="realizarPagamento(item)" ngbTooltip="Importar Comanda" class="text-c-blue mouse-pointer fad fa-cash-register"></i></div>
                            </div> -->
                        </div>
                    </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Voltar
        </button>
        <!-- &nbsp;
        <button type="button" (click)="cliqueSelecionarComanda()" 
            class="btn btn-warning inline"><i class="far fa-sharp fa-solid fa-scanner-touchscreen"></i> Selecionar</button>&nbsp; -->
    </div>
</app-ui-modal>
<app-modal-selecionar-comanda 
    (LiberouComanda)="liberouComanda($event)"
    #modalSelecionarComandaComponent>
</app-modal-selecionar-comanda>