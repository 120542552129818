import { FiltroCustomizacao } from "./FiltroCustomizacao";

export interface FiltroExtratoVenda {
    idVenda: number | null;
    //tipoImpressao: string;
    observacaoParaEntrega: string;
    customizacao: FiltroCustomizacao;
}


export class FiltroExtratoVendaInstance {
    public static get(): FiltroExtratoVenda {
        return {
            idVenda: null,
            //tipoImpressao: 'A4',
            observacaoParaEntrega: '',
            customizacao: {
                alturaLogo: 0,
                larguraLogo: 0,
                exibeLogo: false,
                exibePaginacao: false,
                layout: 'A4',
                margemSuperior: 0,
                margemInferior: 0,
                margemEsquerda: 0,
                margemDireita: 0
            },
        };
    }
}