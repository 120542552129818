<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0  modal-title-header">&nbsp;<i class="fad fa-ban icon-header-modal"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row pb-3" *ngIf="exibirLoginUsuarioESenha">
            <div class="col-12">
                <label class="form-label text-c-red" >Usuário sem acesso, informe o usúario e senha com permissão para cancelar item de compra. Item: {{numeroItem}}</label>
            </div>
        </div>
        <div class="row pt-5 pb-3" *ngIf="exibirLoginUsuarioESenha">
            <div class="col-2">

            </div>
            <div class="col-4">
                <span class="p-float-label">
                    <input id="float-input" type="text" pInputText [(ngModel)]="usuario" (keypress)="keyPress($event)" #elmUsuario>
                    <label for="float-input">Usuário</label>
                </span>
                
            </div>
            <div class="col-4">
                <span class="p-float-label">
                    <input id="float-input" type="password" pInputText [(ngModel)]="senha" (keypress)="keyPress($event)" > 
                    <label for="float-input">Senha</label>
                </span>
            </div>
            <div class="col-2"></div>
        </div>
        <div class="row  p-t-5" *ngIf="!exibirLoginUsuarioESenha">
            <div class="col-md-2">
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12" >
                <div class="form-group text-center">
                    <label class="form-label-bold text-center" for="numeroItem">NUMERO DO ITEM</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="4" [(ngModel)]="numeroItem" suffix="" prefix="" #elmNumeroItem (keypress)="keyPress($event)" [disabled]="ultimoItem"
                        currency="BRL" locale="pt-BR"  mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-md-4 ">
                <div class="form-group">
                    <label class="form-label-bold text-center" for="numeroItem">MOTIVO </label>
                    <app-combo-motivo-cancelamento 
                        idCampo="idMotivoCancelamento"
                        [tipoCancelamento]="tipoCancelamento" 
                        [label]="''" 
                        [(ngModel)]="idMotivoCancelamento">
                    </app-combo-motivo-cancelamento>
                </div>
            </div>
            <div class="col-md-2"></div>
        </div>
        <p-table #elmProdutos [value]="itensGrid" dataKey="item"  *ngIf="!exibirLoginUsuarioESenha"
            selectionMode="single"
            [(selection)]="itemSelecionado" (onRowSelect)="onRowSelecionarItem($event)"
            class="p-table nowrap table-hover " [loading]="loadingListarItens"  responsiveLayout="scroll"
            
            [scrollHeight]="heightListaCliente" 
            styleClass=" " [paginator]="false" >
        <ng-template pTemplate="header">
        <tr>
            <th class="text-center p-r-10">Item</th>
            <th class="text-center p-r-10">Gtin</th>
            <th class="text-center p-r-10">Produto</th>
            <th class="text-center p-r-10">Quantidade</th>
            <th class="text-center p-r-10">Valor Unit.</th>
            <th class="text-center p-r-10">Sub Total</th>
        </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                <td class="text-center" >{{ item.item }}</td>
                <td class="text-center" >{{ item.gtin }}</td>
                <td class="text-center" >{{ item.nome }}</td>
                <td class="text-center" >{{ item.quantidade }}</td>
                <td class="text-center" >{{ item.precoVenda | currency:'BRL' }}</td>
                <td class="text-center" >{{ item.subTotal | currency:'BRL' }}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
        <tr>
            <td colspan="6">Não há produto(s) registrado(s) para este caixa</td>
        </tr>
        </ng-template>
    </p-table>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button" 
            class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Voltar
        </button>&nbsp;
        <button type="button" (click)="cliqueCancelarItem()" #elmCancelarItem *ngIf="!exibirLoginUsuarioESenha"
            class="btn btn-danger inline"><i class="fad fa-ban"></i> Cancelar Item</button>&nbsp;
        <button type="button" (click)="confirmarUsuarioESenha()" *ngIf="exibirLoginUsuarioESenha"
            class="btn btn-danger inline"><i class="fad fa-ban"></i> Cancelar Item</button>&nbsp;
    </div>
</app-ui-modal>

