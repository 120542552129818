import swal from 'sweetalert2';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { enumModoTela } from 'src/app/enum/enumModoTela';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { FileUpload } from 'primeng/fileupload';
import { Subscription } from 'rxjs';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { Router } from '@angular/router';
import { enumModuloAnexo } from 'src/app/enum/enumModuloAnexo';
import { enumListaAnexoTipoArquivo } from 'src/app/enum/enumListaAnexoTipoArquivo';
import { ApiFileService } from 'src/app/services/file/api-file.service';
import { ItemUploadFileModel } from 'src/app/models/file/itemUploadFileModel';
import { AnexoArquivoModel } from 'src/app/models/cliente/AnexoArquivo/anexoArquivoModel';
import { ProdutoImagemModel } from 'src/app/models/cliente/ProdutoImagem/produtoImagemModel';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';


@Component({
  selector: 'app-lista-anexo',
  templateUrl: './lista-anexo.component.html',
  styleUrls: ['./lista-anexo.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListaAnexoComponent implements OnInit, OnDestroy {

  @ViewChild(UiModalComponent) modal;
  
  @Input() exibePesquisa: boolean = true;
  @Input() mode: string = 'advanced';
  @Input() modoTela: enumModoTela = enumModoTela.Cadastrar;
  @Input() modulo!: enumModuloAnexo;
  @Input() chave: string = '';
  @Input() tipoArquivo!: enumListaAnexoTipoArquivo;
  @Input() titulo: string = 'Arquivos';
  @Input() subTitulo: string = 'Lista de Anexos';
  @Input() descricao: string = 'Upload de arquivos'
  @Input() exibirSomenteSeExistirArquivo: boolean = false;
  @Output() fechouModal: EventEmitter<void> = new EventEmitter<void>();
  public exibirComponente: boolean = true;
  @ViewChild(FileUpload, { static: false }) fileUPload!: FileUpload;
  @Output() atualizarLista: EventEmitter<any> = new EventEmitter();

  filesUpload: File[] = [];
  subsExcluir: Subscription | null = null;
  subsUpload: Subscription | null = null;
  subsListar: Subscription | null = null;
  subsDownload: Subscription | null = null;
  retornoUpload: AnexoArquivoModel[] = [];
  arquivos: AnexoArquivoModel[] = [];
  loading: boolean = false;

  constructor(private appCustomToastService: AppCustomToastService,
    private appMensagemService: AppMensagemService,
    private showMessageError: ShowMessageError,
    private router: Router,
    private apiFileService: ApiFileService) { }


  ngOnInit(): void {
    this.listarArquivos();
  }

  mensagemUpload: string = '';
  porcentagemUpload: number = 0;
  limiteMaximoArquivo = 2;
  uploading: boolean = false;

  uploadFileToBackEnd(files: File[]) {

    let itemUploadFileModel: ItemUploadFileModel = {
      modulo: this.modulo,
      chave: this.chave
    };
    //this.appMensagemService.exibirLock("Importando arquivos, este processo pode demorar, por favor aguarde...");
    this.subsUpload = this.apiFileService.upload('upload', this.limiteMaximoArquivo, files, itemUploadFileModel).subscribe((event: HttpEvent<any>) => {
      this.retornoUpload = [];
      switch (event.type) {
        case HttpEventType.Sent:
          this.mensagemUpload = 'por favor aguarde...';
          this.uploading = true;
          break;
        case HttpEventType.Response:
          this.uploading = false;
          this.porcentagemUpload = 25;

          if (event['status'] >= 200 && event['status'] < 300) {
            if (this.limiteMaximoArquivo != 0) {
              //this.limiteMaximoArquivo += files.length;
            }
          } else {
            // console.log('event.body');
            // console.log(event.body);
          }
          this.mensagemUpload = '';
          this.fileUPload.clear();
          this.retornoUpload = event.body as AnexoArquivoModel[];
          this.atualizarLista.emit(null);
          this.listarArquivos();
          this.appMensagemService.esconderLock();
          break;
        case HttpEventType.UploadProgress: {
          if (event['loaded']) {
            this.porcentagemUpload = Math.round((event['loaded'] * 100) / event['total']!);
          }
          break;
        }
      }
    },
      error => {
        this.uploading = false;
        this.appMensagemService.esconderLock();
        this.showMessageError.exibirMensagemValidacaoTela(error);
        this.habilitarBotaoSelecionar();
      });
  }

  onClear(event: any) {
    this.habilitarBotaoSelecionar();
  }

  ngModelChange(event: any) {

  }


  getItemPadrao: boolean = false;

  habilitarBotaoSelecionar() {
    var elements = document.getElementsByClassName("p-fileupload-choose");
    var inputElements = Array.prototype.filter.call(elements, function (el) {
      return true;
    });
    inputElements.map(function (input, i) {
      input.className = input.className.replace(" p-disabled", "");
    });
  }


  files: File[] = [];
  arquivosJaCadastrados: ProdutoImagemModel[] = [];

  onUpload(event) {
  }

  uploadHandler(event) {
    let files: File[] = [];
    for (let file of event.files) {
      files.push(file);
    }
    this.uploadFileToBackEnd(files);
    return false;
  }

  onSelect(event) {
  }
  onRemove(event) {
  }
  onBeforeUpload(event) {
  }

  imagensProduto: ProdutoImagemModel[] = [];

  ngOnDestroy(): void {
    this.subsExcluir?.unsubscribe();
    this.subsUpload?.unsubscribe();
    this.subsListar?.unsubscribe();
    this.subsDownload?.unsubscribe();
  }

  bloquearCampos(): boolean {
    return this.modoTela == enumModoTela.Visualizar;
  } 

  listarArquivos() {
    if (this.chave != '0' && this.chave != ''){
      this.loading = true;
      this.subsListar = this.apiFileService.getFilesByChave(this.modulo, this.chave, false)
        .subscribe(retorno => {
          this.subsListar?.unsubscribe();          
          this.arquivos = retorno as AnexoArquivoModel[];
          if (this.exibirSomenteSeExistirArquivo && this.modoTela == enumModoTela.Visualizar
            && this.arquivos.length == 0){
              this.exibirComponente = false;
          }
          this.loading = false;
        },
          (err) => {
            this.subsListar?.unsubscribe();
            this.loading = false;
            this.showMessageError.exibirMensagemValidacaoTela(err);
          });
    }
  }

  delete(fileName: string){
    swal.fire({
      title: "Alerta",
      text: `Tem certeza que deseja excluir o anexo '${fileName}'?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        if (res.isConfirmed) {

          this.loading = true;
            this.subsListar = this.apiFileService.delete(this.modulo, this.chave, fileName, false)
              .subscribe(retorno => {
                this.subsListar?.unsubscribe();
                this.loading = false;
                this.appCustomToastService.toast(TiposMensagem.sucesso, ['Anexo excluído com sucesso'], "Sucesso");
                this.listarArquivos();
              },
                (err) => {
                  this.subsListar?.unsubscribe();
                  this.loading = false;
                  this.showMessageError.exibirMensagemValidacaoTela(err);
                });
        }
      });

    
  }

  showFiles: Boolean = true;
  exibirArquivos(show: Boolean){
    this.showFiles = show;
    if (show){

    }
    if (!show){

    }
  }

  download(fileName: string, contentType: string){
    this.loading = true;
    this.subsDownload = this.apiFileService.download(this.modulo, this.chave, fileName, true)
      .subscribe((fileData: any) => {

        this.subsDownload?.unsubscribe();
        this.loading = false;
        const blob: any = new Blob([fileData], { type: contentType });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
       
        
      },
        (err) => {
          this.subsDownload?.unsubscribe();
          this.loading = false;
          this.appMensagemService.esconderLock();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
  }

  cliqueFechar(){
    this.fecharModal();
  }

  fecharModal(){
    this.fechouModal.emit();
    this.modal.hide();
  }

  abrirModal(){
    this.modal.show();
  }
}
