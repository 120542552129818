import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ShowMessageError } from "../ShowMessageError";
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector,
        private showMessageError: ShowMessageError) {}

    handleError(error: any): void {
        this.esconderMensagemLock();
        this.showMessageError.exibirMensagemErro(error);

    //     setTimeout(() => {
    //         this.esconderMensagemLock();
    //    }, 3000);

        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        const message = error.message ? error.message : error.toString();
    }

    private esconderMensagemLock() {
        const mensagemService = this.injector.get(AppMensagemService);
        mensagemService.esconderLock();
    }

    private validaJSON(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}

interface ClientError {
    code: string;
    description: string;
}