import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { FormaPagamentoModel } from 'src/app/models/cliente/FormaPagamento/formaPagamentoModel';

@Injectable({
  providedIn: 'root'
})
export class ApiFormaPagamentoService {

  private gc: CommunicationManager;
  private api = AppSettings.API.Financeiro;
  private version = 'api/';
  private controller = 'formaPagamento';

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
  }

  getAllByIdPagamentoOrdemServico(idOrdemServico: number) {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdPagamentoOrdemServico/${idOrdemServico}`, true);
  }

  cadastrarPagamentoOrdemServico(formaPagamento: FormaPagamentoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/cadastrarPagamentoOrdemServico`, formaPagamento, exibeCarregando);
  }

  getAllByIdCompra(id: number): Observable<any> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdCompra/${id}`, true);
  }

  getAllByIdVenda(id: number): Observable<any> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdVenda/${id}`, true);
  }

  getFormaPagamentoByIdFormaPagamentoParcela(idFormaPagamentoParcela: number, exibeCarregando: boolean) {
    return this.gc.get(this.api, `${this.version}${this.controller}/getFormaPagamentoByIdFormaPagamentoParcela/${idFormaPagamentoParcela}`, exibeCarregando);
  }

  getAll() {
    return this.gc.get(this.api, `${this.version}${this.controller}`, true);
  }

  get(id: number) {
    return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
  }

  getContaBancariaFromParametrizacaoCompra(idCompra: number,  exibeCarregando: boolean){
    return this.gc.get(this.api, `${this.version}${this.controller}/getContaBancariaFromParametrizacaoCompra/${idCompra}`, exibeCarregando);
  }

  salvarPagamentoParaCompra(formaPagamento: FormaPagamentoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/cadastrarFormaPagamentoParaCompra`, formaPagamento, exibeCarregando);
  }

  salvarPagamentoParaVenda(formaPagamento: FormaPagamentoModel, exibeCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}/cadastrarFormaPagamentoParaVenda`, formaPagamento, exibeCarregando);
  }

  post(obj: FormaPagamentoModel, exibirCarregando: boolean) {
    return this.gc.post(this.api, `${this.version}${this.controller}`, obj, exibirCarregando);
  }

  alterarFormaPagamentoOS(obj: FormaPagamentoModel, exibirCarregando: boolean) {
    return this.gc.put(this.api, `${this.version}${this.controller}/alterarFormaPagamentoOS/${obj.idFormaPagamento}`, obj, exibirCarregando);
  }

  delete(id: number) {
    return this.gc.delete(this.api, `${this.version}${this.controller}/${id}`, true);
  }

  getAllParcelasByIdFormaPagamento(idFormaPagamento: number) {
    return this.gc.get(this.api, `${this.version}${this.controller}/getAllParcelasByIdFormaPagamento/${idFormaPagamento}`, true);
  }
}