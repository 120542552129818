<label *ngIf="label != ''" class="form-label" [for]="idCampo">{{label}}</label>
    <ng-select  
    [id]="idCampo"
    [name]="idCampo"
    [ngModelOptions]="{standalone: true}" 
    class="ng-select" 
    [items]="itens"
    bindLabel="label" 
    bindValue="value" 
    (clear)="clear($event)"
    loadingText="Carregando..."
    notFoundText="Sem registro"
    [loading]="loading"
    [readonly]="disabled"
    (change)="changeValue($event)"
    [(ngModel)]="value"></ng-select>
