import swal from 'sweetalert2';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { VendaModelInstance } from 'src/app/models/cliente/Vendum/VendaModelInstance';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { NotaFiscalModel } from 'src/app/models/cliente/notaFiscal/notaFiscalModel';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { enumTipoNotaFiscal } from 'src/app/enum/enumTipoNotaFiscal';
import { ModalVerificaAtualizacaoSefazComponent } from 'src/app/shared/business-component/modal-verifica-atualizacao-sefaz/modal-verifica-atualizacao-sefaz.component';
import { ConstantsService, ParametrizacaoPdvModel } from 'src/app/shared/constants/constants.service';
import { AppSettings } from 'src/app/app-settings';
import { enumTipoPessoa } from 'src/app/enum/enumTipoPessoa';
import { InputMask } from 'primeng/inputmask';
import { ApiNotaFiscalConsultaService } from 'src/app/services/notaFiscal/api-nota-fiscal-consulta.service';
import { Subscription } from 'rxjs';
import { NotaFiscalAnexoArquivoModel } from 'src/app/models/cliente/NotaFiscalAnexoArquivo/notaFiscalAnexoArquivoModel';
import { enumModuloAnexo } from 'src/app/enum/enumModuloAnexo';
import { ApiFileService } from 'src/app/services/file/api-file.service';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { CupomNaoFiscalModel } from 'src/app/models/config/cupomNaoFiscalModel';
import { ItensModel } from 'src/app/models/config/itensModel';
import { FormaPagamentoModel } from 'src/app/models/config/formaPagamentoModel';
import { DescricaoMeioPagamento, enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';
import { enumSituacaoVendaProduto } from 'src/app/enum/enumSituacaoVendaProduto';
import { NotaFiscalModelInstance } from 'src/app/models/cliente/notaFiscal/notaFiscalModelInstance';
import { PessoaFisicaModel } from 'src/app/models/config/pessoaFisicaModel';
import { EstrangeiroModel } from 'src/app/models/config/estrangeiroModel';
import { PessoaJuridicaModel } from 'src/app/models/config/pessoaJuridicaModel';
import { EnderecoModel } from 'src/app/models/config/enderecoModel';
import { ApiImpressaoCupomFiscalService } from 'src/app/services/config/api-impressao-cupom-fiscal.service';
import { enumFormatoImpressao } from 'src/app/enum/enumFormatoImpressao';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { ApiImpressaoReciboService } from 'src/app/services/config/api-impressao-recibo.service';
import { ApiGeraRelatorioVendaService } from 'src/app/services/relatorio/api-gera-relatorio-venda.service';
import { FiltroExtratoVenda, FiltroExtratoVendaInstance } from 'src/app/models/relatorios/FiltroExtratoVenda';
import { enumStatusEnvioNotaFiscal } from 'src/app/enum/enumStatusEnvioNotaFiscal';
import { FiltroDeclaracao, FiltroDeclaracaoInstance } from 'src/app/models/relatorios/FiltroDeclaracao';
import { enumSituacaoFormaPagamento } from 'src/app/enum/enumSituacaoFormaPagamento';
import * as printJs from 'print-js';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { ApiGeraPromissoriaCrediarioService } from 'src/app/services/relatorio/api-gera-promissoria-crediario.service';
import { PdvProdutoPagamentoComponent } from '../pdv-produto-pagamento.component';
import { BoletoModel } from 'src/app/models/cliente/Boleto/boletoModel';
import { enumIndicadorMeioPagamento } from 'src/app/enum/enumIndicadorMeioPagamento';
import { ModalImpressaoComponent } from 'src/app/shared/business-component/modal-impressao/modal-impressao.component';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Component({
  selector: 'modal-gera-nota-fiscal',
  templateUrl: './modal-gera-nota-fiscal.component.html',
  styleUrls: ['./modal-gera-nota-fiscal.component.css'],
  host: {'(window:error.file)':'onExibirMensagemErro($event)'}
})
export class ModalGeraNotaFiscalComponent implements OnInit, AfterViewInit {

  @ViewChild(ModalVerificaAtualizacaoSefazComponent, { static: false }) modalVerificaAtualizacaoSefazComponent!: ModalVerificaAtualizacaoSefazComponent;
  @ViewChild(ModalImpressaoComponent) modalImpressaoComponent!: ModalImpressaoComponent;
  @ViewChild(UiModalComponent) modal;
  @Output() caixaLivre: EventEmitter<VendumModel> = new EventEmitter();
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() gerouNotaFiscal: EventEmitter<VendumModel> = new EventEmitter();
  venda: VendumModel = VendaModelInstance.get();
  notaFiscal: NotaFiscalModel = NotaFiscalModelInstance.get();
  subsGeraPromissoriaService: Subscription = null!;
  subsGeraReciboVendaService: Subscription = null!;
  subsVerificaSeExistePromissoria: Subscription = null!;
  subsGerarDeclaracaoDivida: Subscription = null!;
  formatoImpressao: enumFormatoImpressao = enumFormatoImpressao.Cupom80mm;
  Cupom80mm: enumFormatoImpressao = enumFormatoImpressao.Cupom80mm;
  A4: enumFormatoImpressao = enumFormatoImpressao.A4;
  nomeArquivo: string = '';

  public maskCpf = AppSettings.MASK.maskCpf;
  public maskCnpj = AppSettings.MASK.maskCnpj;  

  obsEntrega: string = '';

  tipoVendaUnico: enumTipoProduto = enumTipoProduto.Todos;
  tipoVendaProduto: enumTipoProduto = enumTipoProduto.Produto;
  tipoVendaServico: enumTipoProduto = enumTipoProduto.Servico;

  constructor(
    private apiGeraRelatorioVendaService: ApiGeraRelatorioVendaService,
    private apiGeraPromissoriaCrediarioService: ApiGeraPromissoriaCrediarioService,
    private impressaoCupomFiscalService: ApiImpressaoCupomFiscalService,
    private impressaoReciboService: ApiImpressaoReciboService,
    private apiNotaFiscalConsultaService: ApiNotaFiscalConsultaService,
    private apiVendaService: ApiVendaService,
    private apiFileService: ApiFileService,
    private showMessageError: ShowMessageError,
    private readonly mensagemService: AppMensagemService,
    private router: Router
  ) { }
  ngAfterViewInit(): void {
  }

  passo: number = 1; //passo 1 (informar cpf), passo 2 (imprimir)
  textoPerguntaPasso1: string = '';
  textoPerguntaPasso2: string = '';
  cpfCnpj: string = '';
  tipoPessoa: string = enumTipoPessoa.Fisica;
  
  ngOnInit(): void {
  }
  parametrizacaoPdv: ParametrizacaoPdvModel = null!;
  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13) {
      if (this.parametrizacaoPdv.habilitaNfce && this.parametrizacaoPdv.habilitaNfe){
        
      }
      else if (this.parametrizacaoPdv.habilitaNfce && !this.parametrizacaoPdv.habilitaNfe){
        this.getGerarNfce(this.venda.idVenda);
      }
      else if (!this.parametrizacaoPdv.habilitaNfce && this.parametrizacaoPdv.habilitaNfe){
        this.getGerarNfe(this.venda.idVenda);
      }
    }
  }


  @ViewChild('elmCpf') elmCpf: InputMask  = null!;
  onFocusCpf(){
    setTimeout(()=>{ 
      if (this.elmCpf != null){
        this.elmCpf.el.nativeElement.firstChild.focus();
      }
    },220);    
  }

  @ViewChild('elmCnpj') elmCnpj: InputMask  = null!;
  onFocusCnpj(){
    setTimeout(()=>{ 
      if (this.elmCnpj != null){
        this.elmCnpj.el.nativeElement.firstChild.focus();
      }
    },220);    
  }

  @ViewChild('elmBotaoGerarNfe') elmBotaoGerarNfe: ElementRef  = null!;
  onFocusBotaoGerarNfe(){
    setTimeout(()=>{ 
      if (this.elmBotaoGerarNfe != null){
        this.elmBotaoGerarNfe.nativeElement.focus();
      }
    },120);    
  }
  @ViewChild('elmBotaoGerarNfce') elmBotaoGerarNfce: ElementRef  = null!;
  onFocusBotaoGerarNfce(){
    setTimeout(()=>{ 
      if (this.elmBotaoGerarNfce != null){
        this.elmBotaoGerarNfce.nativeElement.focus();
      }
    },120);    
  }

  @ViewChild('elmBotaoGerarRecibo') elmBotaoGerarRecibo: ElementRef  = null!;
  onFocusBotaoGerarRecibo(){
    setTimeout(()=>{ 
      if (this.elmBotaoGerarRecibo != null){
        this.elmBotaoGerarRecibo.nativeElement.focus();
      }
    },120);    
  }

  handleChangePJ(evt) {
    var target = evt.target;
    if (target.checked) {
      this.tipoPessoa = enumTipoPessoa.Juridica;
      this.onFocusCnpj();
    }
  }

  handleChangePF(evt) {
    var target = evt.target;
    if (target.checked) {
      this.tipoPessoa = enumTipoPessoa.Fisica;
      this.onFocusCpf();
    }
  }  
  
  // handleCupomFiscal(evt) {
  //   var target = evt.target;
  //   if (target.checked) {
  //     this.formatoImpressao = enumFormatoImpressao.Cupom80mm;
  //   }
  // }
  // handleA4(evt) {
  //   var target = evt.target;
  //   if (target.checked) {
  //     this.formatoImpressao = enumFormatoImpressao.A4;
  //   }
  // }

  //jander
  onAbriuModalConfiguracao(event:any){
    //PdvComponent.modalAtiva = true;
  }
  onFechouModalAlteracao(event:any){
    //PdvComponent.modalAtiva = false;
  }

  cliqueConfirmarGerarRecibo(formatoImpressao: enumFormatoImpressao){
    this.formatoImpressao = formatoImpressao;
    this.imprimirReciboWeb();
  }

  // cliqueConfirmarGerarRecibo(){
  //   if (ConstantsService.ParametrizacaoPdv.impressaoDireta && this.formatoImpressao == this.Cupom80mm){
  //     this.imprimirReciboImpressaoDireta();  
  //   }else{
  //     this.imprimirReciboWeb();
  //   }    
  // }

  // cliqueGerarDeclaracao(){
  //   this.imprimirDeclaracaoParcelasWeb();
  // }
  
  //cliqueConfirmarGerarPromissoria(){
    //this.modalCrudFormatoImpressaoPromissoriaComponent.abrirModal(this.venda.idVenda);

    // Swal.fire({
    //   title: `Informativo`,
    //   html: `Olá!<br>Selecione qual tipo de impressão deseja realizar. Declaração de Dívida e/ou Promissória`,
    //   icon: 'info',
    //   showDenyButton: true,
    //   showCancelButton: true,
    //   showConfirmButton: true,
    //   confirmButtonText: 'Declaração',
    //   cancelButtonText: 'Cancelar',
    //   denyButtonText: 'Promissória',
    //   denyButtonColor: 'forestgreen'
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     this.imprimirDeclaracaoParcelasWeb();
    //   } else if(result.isDenied) {
    //     this.imprimirPromissoriaEmA4();
    //   }else{
    //     return;
    //   }
    // });
  //}

  inputDados: FiltroExtratoVenda = FiltroExtratoVendaInstance.get();
  imprimirReciboWeb(){
    this.loading = true;
    this.inputDados.idVenda = this.venda.idVenda
    this.inputDados.customizacao.layout = this.formatoImpressao;
    this.inputDados.observacaoParaEntrega = this.obsEntrega;

    this.subsGeraReciboVendaService = this.apiGeraRelatorioVendaService.gerarReciboVenda(this.inputDados, true)
    .subscribe((fileData: any) => {
      this.subsGeraReciboVendaService?.unsubscribe();           
      // Agora o pai pode chamar uma função no componente filho
      this.modalImpressaoComponent.imprimir("01", fileData, 'application/pdf');
      //
      // const blob: any = new Blob([fileData], { type: 'application/pdf' });
      // var pdfUrl = URL.createObjectURL(blob);
      // printJs(pdfUrl);
      this.loading = false;
      },
      (err) => {
        this.subsGeraReciboVendaService?.unsubscribe();
        this.loading = false;
        var reader = new FileReader();
        reader.onload = function() {
          var event = new CustomEvent("error.file", 
          {
              detail: reader.result,
              bubbles: true,
              cancelable: true
          }
          );
          window.dispatchEvent(event);
        }
        reader.readAsText(err.error);
      });
  }

  // filtroImpressaoPromissoria: FiltroDeclaracao = FiltroDeclaracaoInstance.get();
  // imprimirDeclaracaoParcelasWeb(){
  //   this.loading = true;
  //   this.filtroImpressaoPromissoria.cpfCnpj = this.venda.cpfCnpj;
  //   this.filtroImpressaoPromissoria.tipoImpressao = this.formatoImpressao;
  //   this.filtroImpressaoPromissoria.tipoDeclaracao = 'U'; //único
  //   this.filtroImpressaoPromissoria.idVenda = this.venda.idVenda;
  //   this.subsGeraPromissoriaService = this.apiGeraRelatorioVendaService.gerarPromissoriaVenda(this.filtroImpressaoPromissoria, true)
  //   .subscribe((fileData: any) => {
  //     this.subsGeraPromissoriaService?.unsubscribe();
  //     const blob: any = new Blob([fileData], { type: 'application/pdf' });
  //     var pdfUrl = URL.createObjectURL(blob);
  //     printJs(pdfUrl);
  //     this.loading = false;
  //     },
  //     (err) => {
  //       this.subsGeraPromissoriaService?.unsubscribe();
  //       this.loading = false;
  //       var reader = new FileReader();
  //       reader.onload = function() {
  //         var event = new CustomEvent("error.file", 
  //         {
  //             detail: reader.result,
  //             bubbles: true,
  //             cancelable: true
  //         }
  //         );
  //         window.dispatchEvent(event);
  //       }
  //       reader.readAsText(err.error);
  //     });
  // }

  // imprimirPromissoriaEmA4(){
  //   this.loading = true;
  //   this.subsGeraPromissoriaService = this.apiGeraPromissoriaCrediarioService.gerarPromissoriaCrediario(this.venda.idVenda, true)
  //   .subscribe((fileData: any) => {
  //     this.subsGeraPromissoriaService?.unsubscribe();
  //     const blob: any = new Blob([fileData], { type: 'application/pdf' });
  //     var pdfUrl = URL.createObjectURL(blob);
  //     printJs(pdfUrl);
  //     this.loading = false;
  //     },
  //     (err) => {
  //       this.subsGeraPromissoriaService?.unsubscribe();
  //       this.loading = false;
  //       var reader = new FileReader();
  //       reader.onload = function() {
  //         var event = new CustomEvent("error.file", 
  //         {
  //             detail: reader.result,
  //             bubbles: true,
  //             cancelable: true
  //         }
  //         );
  //         window.dispatchEvent(event);
  //       }
  //       reader.readAsText(err.error);
  //     });
  // }

  @HostListener('window:error.file', ['$event']) 
	onExibirMensagemErro(event): void {
    this.showMessageError.exibirMensagemValidacaoTela(event.detail);
	}

  cliqueConfirmarGerarNfe(tipoVenda: enumTipoProduto){
    if(tipoVenda == enumTipoProduto.Todos){
      swal.fire({
        title: "Alerta",
        html: 'O pagamento desta nota será proporcional ao total do(s) produto(s). Deseja prosseguir?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.getGerarNfe(this.venda.idVenda);
        }
        if (result.isDismissed)
          return;
      });
    }else{
      this.getGerarNfe(this.venda.idVenda);
    }    
  }

  cliqueConfirmarGerarNfse(tipoVenda: enumTipoProduto){
    if(tipoVenda == enumTipoProduto.Todos){
      swal.fire({
        title: "Alerta",
        html: 'O pagamento desta nota será proporcional ao total do(s) serviço(s). Deseja prosseguir?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.getGerarNfse(this.venda.idVenda);
        }
        if (result.isDismissed)
          return;
      });
    }else{
      this.getGerarNfse(this.venda.idVenda);
    }
  }

  cliqueConfirmarGerarNfce(tipoVenda: enumTipoProduto){
    if(tipoVenda == enumTipoProduto.Todos){
      swal.fire({
        title: "Alerta",
        html: 'O pagamento desta nota será proporcional ao total do(s) produto(s). Deseja prosseguir?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonText: 'Sim'
      }).then((result) => {
        if (result.isConfirmed) {
          this.getGerarNfce(this.venda.idVenda);
        }
        if (result.isDismissed)
          return;
      });
    }else{
      this.getGerarNfce(this.venda.idVenda);
    }
  }

  subsConsultarAnexoNotaFiscalFromIdVenda: Subscription = null!;
  subsDownload: Subscription = null!;
  download(tipo: string, tipoNotaFiscal: enumTipoNotaFiscal){ //tipo == 'pdf' ou 'xml'
    this.subsConsultarAnexoNotaFiscalFromIdVenda = this.apiNotaFiscalConsultaService.consultarAnexoNotaFiscalByIdVenda(this.venda.idVenda, tipoNotaFiscal, true)
    .subscribe((anexosNotaFiscal: NotaFiscalAnexoArquivoModel[] ) => {
      this.subsConsultarAnexoNotaFiscalFromIdVenda?.unsubscribe();
      if (anexosNotaFiscal != null){
        if (anexosNotaFiscal.filter(f => f.tag == tipo).length > 0){
          let anexo = anexosNotaFiscal.filter(f => f.tag == tipo)[0].idAnexoArquivoNavigation;
          this.subsDownload = this.apiFileService.download(enumModuloAnexo.NotaFiscal, anexosNotaFiscal[0].idNotaFiscal.toString(), anexo.fileName, true)
            .subscribe((fileData: any) => {
              this.subsDownload?.unsubscribe();
              
              const blob: any = new Blob([fileData], { type: 'application/pdf' });
              var pdfUrl = URL.createObjectURL(blob);
              printJs(pdfUrl);
            },
              (err) => {
                this.subsDownload?.unsubscribe();
                this.showMessageError.exibirMensagemValidacaoTela(err);
              });
        }else{
          //verifica status envio e caso necessário ja baixa o arquivo
          this.subsDownload = this.apiNotaFiscalConsultaService.downloadXmlOuPdfByIdVenda(this.venda.idVenda, tipo, tipoNotaFiscal, true)
          .subscribe((fileData: any) => {
            this.subsDownload?.unsubscribe();
            const blob: any = new Blob([fileData], { type: 'application/pdf' });
            var pdfUrl = URL.createObjectURL(blob);
            printJs(pdfUrl);
          },
            (err) => {
              this.subsDownload?.unsubscribe();
              this.showMessageError.exibirMensagensCustomizadas("Atenção", 
                ["Arquivo ainda em processamento, por favor tente novamente mais tarde, caso o problema persista, entre em contato com o Administrador"],
                TiposMensagem.erro);
            });
        }
      }
    },
      (err) => {
        this.subsConsultarAnexoNotaFiscalFromIdVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  subsGetNotaFiscalByIdVendaParaCupomFiscal: Subscription = null!;
  imprimirCupomFiscalNfce(){

    if (this.notaFiscal == null || this.notaFiscal.idNotaFiscal == 0){
      this.subsGetNotaFiscalByIdVendaParaCupomFiscal = this.apiNotaFiscalConsultaService.getNotaFiscalByIdVendaParaCupomFiscal(this.venda.idVenda, true)
      .subscribe((notaFiscal: NotaFiscalModel ) => {
        this.subsGetNotaFiscalByIdVendaParaCupomFiscal?.unsubscribe();
        if (notaFiscal != null){
          this.notaFiscal = notaFiscal;
          this.enviarNotaFiscalParaImpressoraLocal();
        }else{
          swal.fire({
            title: "Alerta",
            html: 'Nota fiscal não gerada para esta venda?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim'
          }).then((result) => {
            if (result.isConfirmed) {
              
            }
            if (result.isDismissed)
              return;
          });
        }
      },
      (err) => {
        this.subsConsultarAnexoNotaFiscalFromIdVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
    }else{
      this.enviarNotaFiscalParaImpressoraLocal();
    }
  }

  // imprimirReciboImpressaoDireta(){
  //   this.subsGetNotaFiscalByIdVendaParaCupomFiscal = this.apiVendaService.getById(this.venda.idVenda, true)
  //   .subscribe((venda: VendumModel ) => {
  //     this.subsGetNotaFiscalByIdVendaParaCupomFiscal?.unsubscribe();
  //     if (venda != null){
  //       //console.log(venda);
  //       this.enviarReciboParaImpressoraLocal(venda);
  //     }else{
  //       swal.fire({
  //         title: "Alerta",
  //         html: 'Venda não gerada para esta venda!',
  //         icon: 'warning',
  //         showCancelButton: false,
  //         confirmButtonText: 'Ok'
  //       }).then((result) => {          
  //       });
  //     }
  //   },
  //   (err) => {
  //     this.subsConsultarAnexoNotaFiscalFromIdVenda?.unsubscribe();
  //     this.showMessageError.exibirMensagemValidacaoTela(err);
  //   });
  // }
  // enviarReciboParaImpressoraLocal(venda: VendumModel){
  //   let itens: ItensModel[] = [];

  //   this.venda.vendaProdutos.forEach(f => {
  //     itens.push(
  //       {codigo: f.numeroItem!.toString(),
  //       descricao: f.detalhe,
  //       qtde: f.quantidade!,
  //       un: f.unidadeMedidaTributavel,
  //       valorUnitario: f.valorTotal!/f.quantidade!,
  //       valorDesconto: f.valorDesconto! + f.valorDescontoIncondicionado! + f.valorDescontoCondicionado!,
  //       valorOutrasDespesas: f.valorOutrasDespesas! + f.valorTotalFrete!,
  //       valorTotal: f.valorTotal!
  //     }
  //     );
  //   });

  //   let formasPagamento: FormaPagamentoModel[] = [];
  //   venda.formaPagamentos.forEach(f => {
  //     formasPagamento.push(
  //       {
  //         descricao: DescricaoMeioPagamento.get(f.idTabelaMeioPagamento as enumMeioPagamento),
  //         valorPago: f.valorTotal!
  //       }
  //     );
  //   });

  //   let pf: PessoaFisicaModel = null!;
  //   let pj: PessoaJuridicaModel = null!;
  //   let estrangeiro: EstrangeiroModel = null!;
  //   let endereco: EnderecoModel = null!;

  //   if (venda.cpfCnpjNavigation != null){
  //     if (venda.cpfCnpjNavigation.tipoPessoa == enumTipoPessoa.Fisica){
  //       pf = {
  //         cpf: venda.cpfCnpjNavigation.cpfCnpj,
  //         endereco: null!,
  //         nome: venda.cpfCnpjNavigation.razaoSocialNome
  //       }
  //     }else if (venda.cpfCnpjNavigation.tipoPessoa == enumTipoPessoa.Juridica){
  //       pj = {
  //         cnpj: venda.cpfCnpjNavigation.cpfCnpj,
  //         endereco: null!,
  //         razaoSocial: venda.cpfCnpjNavigation.razaoSocialNome
  //       }
  //     }else if (venda.cpfCnpjNavigation.tipoPessoa == enumTipoPessoa.Estrangeiro){
        
  //     }
  //   }

  //   ({ pf, pj } = this.preencherPessoaFisicaJuridicaParaEnvioImpressaoDireta(pf, pj));

  //   if (ConstantsService.PessoaJuridica != null && ConstantsService.PessoaJuridica.idEnderecoNavigation != null){
  //     endereco = {
  //       rua: ConstantsService.PessoaJuridica.idEnderecoNavigation.rua,
  //       numero: ConstantsService.PessoaJuridica.idEnderecoNavigation.numero,
  //       bairro: ConstantsService.PessoaJuridica.idEnderecoNavigation.bairro,
  //       cidade: ConstantsService.PessoaJuridica.idEnderecoNavigation.cidade,
  //       estado: ConstantsService.PessoaJuridica.idEnderecoNavigation.siglaUf,
  //       complemento: ConstantsService.PessoaJuridica.idEnderecoNavigation.complemento,
  //       cep: ConstantsService.PessoaJuridica.idEnderecoNavigation.cep
  //     };
  //   }

  //   let recibo: ReciboModel = {
  //     idVenda: this.venda.idVenda.toString(),
  //     impressora: null!,
  //     empresa: {
  //       cnpj: ConstantsService.Cnpj,
  //       endereco: {
  //         rua: ConstantsService.PessoaJuridica.idEnderecoNavigation.rua,
  //         numero:  ConstantsService.PessoaJuridica.idEnderecoNavigation.numero,
  //         bairro:  ConstantsService.PessoaJuridica.idEnderecoNavigation.bairro,
  //         cidade:  ConstantsService.PessoaJuridica.idEnderecoNavigation.cidade,
  //         estado: ConstantsService.PessoaJuridica.idEnderecoNavigation.siglaUf,
  //         complemento: ConstantsService.PessoaJuridica.idEnderecoNavigation.complemento,
  //         cep: ConstantsService.PessoaJuridica.idEnderecoNavigation.cep
  //       },
  //       ie: ConstantsService.PessoaJuridica.inscricaoEstadual,
  //       logo: '',
  //       razaoSocial: ConstantsService.PessoaJuridica.razaoSocial
  //     },  
  //     itens: itens,
  //     totais: {
  //       qtdeItens: this.venda.vendaProdutos.filter(f => f.situacao == enumSituacaoVendaProduto.Ativo).length,
  //       valorTotal: this.venda.valorTotalProdutoServicoVendaProduto!,
  //       descontoTotal: this.venda.valorTotalDescontoVendaProduto!,
  //       valorFrete: this.venda.valorTotalFreteVendaProduto!,
  //       valorAPagar: this.venda.valorTotal,
  //       valorTroco: 0
  //     },
  //     formaPagamento: formasPagamento,
  //     consumidor: {
  //       pf: pf,
  //       pj: pj,
  //       estrangeiro: estrangeiro
  //     },
  //     //https://portalsped.fazenda.mg.gov.br/portalnfce/sistema/qrcode.xhtml?p=31221141771191000172650010000000061000001016|2|1|1|66787BE2370F528EB61E206B2F664D68A01AFD6B]
  //     //http://nfce.encat.org/consulte-sua-nota-qr-code-versao-2-0/
  //     //padrão:
  //     //O modelo a ser seguido tem esse formato:
  //     //<http://<dominio>/nfce/qrcode?p>=<chave_acesso>|<versao_qrcode>|<tipo_ambiente>|<identificador_csc>|<codigo_hash>
  //     // URL da Sefaz – link de consulta da Sefaz Autorizadora. A lista com todos os estados é sempre atualizada e pode ser encontrada aqui;
  //     // Chave de Acesso – identificador da nota;
  //     // Versão do QR Code – deve ser informado o valor “2”;
  //     // Tipo do Ambiente – informar o ambiente que a NFCe foi emitida (1 – Produção ou 2 – Homologação);
  //     // Identificador CSC – número do código de segurança do contribuinte no banco de dados da Sefaz, sem os zeros não significativos. Não confundir com o próprio CSC;
  //     // Código Hash – código calculado para validar os dados passados.
  //     numeroPDV: (this.venda.idPdvNavigation != null)? this.venda.idPdvNavigation.codigo: ''
  //   }

  //   this.subsImpressaoReciboService = this.impressaoReciboService.imprimirRecibo(recibo)
  //   .subscribe(retorno => {
      
  //   });
  // }

  enviarNotaFiscalParaImpressoraLocal(){
    let itensCupomFiscal: ItensModel[] = [];

    this.notaFiscal.notaFiscalProdServs.forEach(f => {
      itensCupomFiscal.push(
        {codigo: f.codigoProdutoServico!.toString(),
        descricao: f.descricaoProdutoServico,
        qtde: f.quantidadeTributavel!,
        un: f.unidadeTributavel,
        valorUnitario: f.valorUnitarioTributacao!,
        valorDesconto: f.valorDesconto!,
        valorOutrasDespesas: f.outrasDespesasAcessorias! +  f.valorTotalSeguro! + f.valorTotalFrete!,
        valorTotal: f.valorTotalBrutoProdutosServicos!
      }
      );
    });

    let formasPagamento: FormaPagamentoModel[] = [];
    this.notaFiscal.notaFiscalInfPagamentos.forEach(f => {
      formasPagamento.push(
        {
          descricao: DescricaoMeioPagamento.get(f.idTabelaMeioPagamento as enumMeioPagamento),
          valorPago: f.valorPagamento!
        }
      );
    });

    let pf: PessoaFisicaModel = null!;
    let pj: PessoaJuridicaModel = null!;
    let estrangeiro: EstrangeiroModel = null!;
    let endereco: EnderecoModel = null!;
    ({ pf, pj } = this.preencherPessoaFisicaJuridicaParaEnvioImpressaoDireta(pf, pj));

    let protocoloAutorizacao: string = '';
    let dataAutorizacao: Date | null = null!;
    protocoloAutorizacao = this.notaFiscal.protocoloAutorizacao;
    dataAutorizacao = this.notaFiscal.dataAutorizacao;

    if (ConstantsService.PessoaJuridica != null && ConstantsService.PessoaJuridica.idEnderecoNavigation != null){
      endereco = {
        rua: ConstantsService.PessoaJuridica.idEnderecoNavigation.rua,
        numero: ConstantsService.PessoaJuridica.idEnderecoNavigation.numero,
        bairro: ConstantsService.PessoaJuridica.idEnderecoNavigation.bairro,
        cidade: ConstantsService.PessoaJuridica.idEnderecoNavigation.cidade,
        estado: ConstantsService.PessoaJuridica.idEnderecoNavigation.siglaUf,
        complemento: ConstantsService.PessoaJuridica.idEnderecoNavigation.complemento,
        cep: ConstantsService.PessoaJuridica.idEnderecoNavigation.cep
      };
    }

    let cupomFiscal: CupomNaoFiscalModel = {
      idVenda: this.venda.idVenda.toString(),
      impressora: null!,
      empresa: {
        cnpj: ConstantsService.Cnpj,
        endereco: {
          rua: ConstantsService.PessoaJuridica.idEnderecoNavigation.rua,
          numero:  ConstantsService.PessoaJuridica.idEnderecoNavigation.numero,
          bairro:  ConstantsService.PessoaJuridica.idEnderecoNavigation.bairro,
          cidade:  ConstantsService.PessoaJuridica.idEnderecoNavigation.cidade,
          estado: ConstantsService.PessoaJuridica.idEnderecoNavigation.siglaUf,
          complemento: ConstantsService.PessoaJuridica.idEnderecoNavigation.complemento,
          cep: ConstantsService.PessoaJuridica.idEnderecoNavigation.cep
        },
        ie: ConstantsService.PessoaJuridica.inscricaoEstadual,
        logo: '',
        razaoSocial: ConstantsService.PessoaJuridica.razaoSocial
      },  
      emitidaEmContingencia: false,
      itens: itensCupomFiscal,
      totais: {
        qtdeItens: this.venda.vendaProdutos.filter(f => f.situacao == enumSituacaoVendaProduto.Ativo).length,
        valorTotal: this.venda.valorTotalProdutoServicoVendaProduto!,
        descontoTotal: this.venda.valorTotalDescontoVendaProduto!,
        valorFrete: this.venda.valorTotalFreteVendaProduto!,
        valorAPagar: this.venda.valorTotal,
        valorTroco: 0
      },
      formaPagamento: formasPagamento,
      chaveAcesso: this.notaFiscal.id,
      consumidor: {
        pf: pf,
        pj: pj,
        estrangeiro: estrangeiro
      },
      nfce: {
        numero: this.notaFiscal.numero,
        serie: this.notaFiscal.serie,
        dataHora: this.notaFiscal.dataEmissao!,
        protocoloAutorizacao: protocoloAutorizacao,
        dataAutorizacao: dataAutorizacao!
      },
      tributos: `Tributos Totais Incidentes(Lei Federal 12.741/12) ${this.getFormatDecimalTotalImpostos(this.notaFiscal.valorTotalImposto!)}`,
      qrCode: this.notaFiscal.qrCodeCupomFiscal,
      urlSefaz: '',
      //https://portalsped.fazenda.mg.gov.br/portalnfce/sistema/qrcode.xhtml?p=31221141771191000172650010000000061000001016|2|1|1|66787BE2370F528EB61E206B2F664D68A01AFD6B]
      //http://nfce.encat.org/consulte-sua-nota-qr-code-versao-2-0/
      //padrão:
      //O modelo a ser seguido tem esse formato:
      //<http://<dominio>/nfce/qrcode?p>=<chave_acesso>|<versao_qrcode>|<tipo_ambiente>|<identificador_csc>|<codigo_hash>
      // URL da Sefaz – link de consulta da Sefaz Autorizadora. A lista com todos os estados é sempre atualizada e pode ser encontrada aqui;
      // Chave de Acesso – identificador da nota;
      // Versão do QR Code – deve ser informado o valor “2”;
      // Tipo do Ambiente – informar o ambiente que a NFCe foi emitida (1 – Produção ou 2 – Homologação);
      // Identificador CSC – número do código de segurança do contribuinte no banco de dados da Sefaz, sem os zeros não significativos. Não confundir com o próprio CSC;
      // Código Hash – código calculado para validar os dados passados.
      numeroPDV: this.notaFiscal.numeroPdv
    }

    let site : string = "";
    switch (ConstantsService.PessoaJuridica.idEnderecoNavigation.siglaUf)
    {
      case "AC":
        //Acre	www.sefaznet.ac.gov.br/nfce/consulta
        site = "www.sefaznet.ac.gov.br/nfce/consulta";
        break;
    case "AL":
        //Alagoas	www.sefaz.al.gov.br/nfce/consulta
        site = "wwwwww.sefaz.al.gov.br/nfce/consulta";
        break;
    case "AP":
        //Amapá	www.sefaz.ap.gov.br/nfce/consulta
        site = "www.sefaz.ap.gov.br/nfce/consulta";
        break;
    case "AM":
        //Amazonas	www.sefaz.am.gov.br/nfce/consulta
        site = "www.sefaz.am.gov.br/nfce/consulta";
        break;
    case "CE":
        //Ceará	www.sefaz.ce.gov.br/nfce/consulta
        site = "www.sefaz.ce.gov.br/nfce/consulta";
        break;
    case "DF":
        //Distrito Federal	www.fazenda.df.gov.br/nfce/consulta
        site = "www.fazenda.df.gov.br/nfce/consulta";
        break;
    case "ES":
        //Espírito Santo	www.sefaz.es.gov.br/nfce/consulta
        site = "www.sefaz.es.gov.br/nfce/consulta";
        break;
    case "GO":
        //Goiás	www.sefaz.go.gov.br/nfce/consulta
        site = "www.sefaz.go.gov.br/nfce/consulta";
        break;
    case "MA":
        //Maranhão	www.sefaz.ma.gov.br/nfce/consulta
        site = "www.sefaz.ma.gov.br/nfce/consulta";
        break;
    case "MS":
        //Mato Grosso do Sul	www.dfe.ms.gov.br/nfce/consulta
        site = "www.dfe.ms.gov.br/nfce/consulta";
        break;
    case "PA":
        //Pará	www.sefa.pa.gov.br/nfce/consulta
        site = "www.sefa.pa.gov.br/nfce/consulta";
        break;
    case "PR":
        //Paraná	http://www.fazenda.pr.gov.br/nfce/consulta
        site = "http://www.fazenda.pr.gov.br/nfce/consulta";
        break;
    case "PE":
        //Pernambuco	nfce.sefaz.pe.gov.br/nfce/consulta
        site = "sefaz.pe.gov.br/nfce/consulta";
        break;
    case "PI":
        //Piaui	www.sefaz.pi.gov.br/nfce/consulta
        site = "www.sefaz.pi.gov.br/nfce/consulta";
        break;
    case "RJ":
        //Rio de Janeiro	www.fazenda.rj.gov.br/nfce/consulta
        site = "www.fazenda.rj.gov.br/nfce/consulta";
        break;
    case "RN":
        //Rio Grande do Norte	www.set.rn.gov.br/nfce/consulta
        site = "www.set.rn.gov.br/nfce/consulta";
        break;
    case "RS":
        //Rio Grande do Sul	www.sefaz.rs.gov.br/nfce/consulta
        site = "www.sefaz.rs.gov.br/nfce/consulta";
        break;
    case "RO":
        //Rondônia	www.sefin.ro.gov.br/nfce/consulta
        site = "www.sefin.ro.gov.br/nfce/consulta";
        break;
    case "RR":
        //Roraima	www.sefaz.rr.gov.br/nfce/consulta
        site = "www.sefaz.rr.gov.br/nfce/consulta";
        break;
    case "BA":
        //Bahia	www.sefaz.ba.gov.br/nfce/consulta
        site = "www.sefaz.ba.gov.br/nfce/consulta";
        break;
    case "MG":
        //Minas Gerais	http://nfce.fazenda.mg.gov.br/portalnfce
        site = "http://nfce.fazenda.mg.gov.br/portalnfce";
        break;
    case "MT":
        //Mato Grosso	http://www.sefaz.mt.gov.br/nfce/consultanfce	
        site = "http://www.sefaz.mt.gov.br/nfce/consultanfce";
        break;
    case "PB":
        //Paraíba	www.sefaz.pb.gov.br/nfce/consulta
        site = "www.sefaz.pb.gov.br/nfce/consulta";
        break;
    case "SC":
        //Santa Catarina	https://sat.sef.sc.gov.br/nfce/consulta
        site = "https://sat.sef.sc.gov.br/nfce/consulta";
        break;
    case "SP":
        //São Paulo	https://www.nfce.fazenda.sp.gov.br/consulta
        site = "https://www.nfce.fazenda.sp.gov.br/consulta";
        break;
    case "SE":
        //Sergipe	http://www.nfce.se.gov.br/nfce/consulta
        site = "http://www.nfce.se.gov.br/nfce/consulta";
        break;
    case "TO":
        //Tocantins	www.sefaz.to.gov.br/nfce/consulta
        site = "www.sefaz.to.gov.br/nfce/consulta";
        break;
    }
    cupomFiscal.urlSefaz = site;
    this.subsImpressaoCupomFiscalService = this.impressaoCupomFiscalService.imprimirCupomFiscal(cupomFiscal)
    .subscribe(retorno => {
      
    });
  }
  subsImpressaoReciboService: Subscription = null!;
  subsImpressaoCupomFiscalService: Subscription = null!;

  private preencherPessoaFisicaJuridicaParaEnvioImpressaoDireta(pf: PessoaFisicaModel, pj: PessoaJuridicaModel) {
    if (this.cpfCnpj == '') {
      if (this.notaFiscal.notaFiscalDestinatario != null) {
        switch (this.notaFiscal.notaFiscalDestinatario.tipoPessoa) {
          case enumTipoPessoa.Fisica:
            pf = {
              cpf: this.notaFiscal.notaFiscalDestinatario.cpfCnpj,
              nome: this.notaFiscal.notaFiscalDestinatario.razaoSocialNomeDestinatario,
              endereco: null!
            };
            break;
          case enumTipoPessoa.Juridica:
            pj = {
              cnpj: this.notaFiscal.notaFiscalDestinatario.cpfCnpj,
              razaoSocial: this.notaFiscal.notaFiscalDestinatario.razaoSocialNomeDestinatario,
              endereco: null!
            };
            break;
        }
      } else {
        switch (this.tipoPessoa) {
          case enumTipoPessoa.Fisica:
            pf = {
              cpf: '',
              nome: '',
              endereco: null!
            };
            break;
          case enumTipoPessoa.Juridica:
            pj = {
              cnpj: '',
              razaoSocial: '',
              endereco: null!
            };
            break;
        }
      }
    } else {
      if (this.notaFiscal.notaFiscalDestinatario != null && this.notaFiscal.notaFiscalDestinatario.cpfCnpj != this.cpfCnpj) {
        switch (this.tipoPessoa) {
          case enumTipoPessoa.Fisica:
            pf = {
              cpf: this.cpfCnpj,
              nome: '',
              endereco: null!
            };
            break;
          case enumTipoPessoa.Juridica:
            pj = {
              cnpj: this.cpfCnpj,
              razaoSocial: '',
              endereco: null!
            };
            break;
        }
      } else if (this.notaFiscal.notaFiscalDestinatario != null) {
        switch (this.tipoPessoa) {
          case enumTipoPessoa.Fisica:
            pf = {
              cpf: this.notaFiscal.notaFiscalDestinatario.cpfCnpj,
              nome: this.notaFiscal.notaFiscalDestinatario.razaoSocialNomeDestinatario,
              endereco: null!
            };
            break;
          case enumTipoPessoa.Juridica:
            pj = {
              cnpj: this.notaFiscal.notaFiscalDestinatario.cpfCnpj,
              razaoSocial: this.notaFiscal.notaFiscalDestinatario.razaoSocialNomeDestinatario,
              endereco: null!
            };
            break;
        }
      }
    }
    return { pf, pj };
  }

  getFormatDecimalTotalImpostos(number: number): string{
    if (number == null) return '0,00';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

 
  cliqueImprimir2ViaNfce(){
    if (ConstantsService.ParametrizacaoPdv.impressaoDireta){
      this.imprimirCupomFiscalNfce();  
    }else{
      this.download('pdf' , enumTipoNotaFiscal.Nfce);
    }
  }

  cliqueImprimir2ViaNfse(){
    this.download('pdf' , enumTipoNotaFiscal.Nfse);
  }

  cliqueImprimir2ViaNfe(){
    this.download('pdf' , enumTipoNotaFiscal.Nfe);
  }

  cliqueCancelar(){
    this.gerouNotaFiscal.emit(this.venda);
    this.fecharModal();
  }

  cliqueDownloadBoletoByIdBoleto(idBoleto: number){
    let urlBoleto = this.boletos.filter(f => f.idBoleto == idBoleto)[0].urlBoletoExterno;
    window.open(urlBoleto, '_blank');
  }

  boletos: any[] = [
  ];
  habilitaBotaoDeclaracao: boolean = false;
  habilitaBotaoPromissoria: boolean = false;
  tituloModal: string = 'Impressão de Venda';
  abrirModal(venda: VendumModel, imprimirDireto: boolean) {
    this.cpfCnpj = '';
    this.habilitaBotaoPromissoria = false;
    this.habilitaBotaoDeclaracao = false;
    this.disabledBtnEmitirNfse = false;
    this.disabledBtnEmitirNfe = false;
    this.disabledBtnEmitirNfce = false;

    this.venda = venda;

    //verificar se existe forma de pagamento do tipo crédito loja para poder habilitar o botão de promissória
    this.venda.formaPagamentos.filter(f => f.idTabelaMeioPagamento == enumMeioPagamento.CreditoLoja && f.situacao != enumSituacaoFormaPagamento.Removido).forEach(f => {
      this.habilitaBotaoPromissoria = true;
    });
    this.venda.formaPagamentos.filter(f => f.indicadorMeioPagamento == enumIndicadorMeioPagamento.PagamentoAPrazo && f.situacao != enumSituacaoFormaPagamento.Removido).forEach(f => {
      this.habilitaBotaoDeclaracao = true;
    });

    if (this.venda.formaPagamentos.findIndex(f => f.idTabelaMeioPagamento == enumMeioPagamento.BoletoBancario) > -1){
      this.consultarBoletoByIdVenda(venda.idVenda);
    };

    if (this.venda.cpfCnpj != '' && this.venda.cpfCnpj != null){
      if(this.venda.cpfCnpjNavigation != null && this.venda.cpfCnpjNavigation.informouCpfCnpj == enumSimNao.Sim){
        this.cpfCnpj = this.venda.cpfCnpjNavigation.cpfCnpjInformado;  
        this.tipoPessoa = this.venda.cpfCnpjNavigation.tipoPessoa;
      }else if(this.venda.cpfCnpjNavigation != null){
        this.tipoPessoa = this.venda.cpfCnpjNavigation.tipoPessoa;
        this.cpfCnpj = '';
      }else{
        this.cpfCnpj = '';
      }
    }
    let parametrizacaoPdv = ConstantsService.ParametrizacaoPdv;
    this.parametrizacaoPdv = parametrizacaoPdv;

    //verifica se imprime recibo automaticamente
    if (ConstantsService.ParametrizacaoPdv.impressaoDireta && imprimirDireto){
      this.imprimirReciboWeb();
    }

    //verifica se tem que gerar nfce automaticamente
    if (parametrizacaoPdv.habilitaNfce
      && parametrizacaoPdv.geraNfceAutomaticamente)
      //&& parametrizacaoPdv.geraNfceAutomaticamente  && (this.venda.idOrdemServico == null || this.venda.idOrdemServico == 0))
      {
        //gera nota fiscal nfce automaticamente
        this.tituloModal = 'Impressão de Nota Fiscal de Consumidor';
        this.getGerarNfce(venda.idVenda);
      }
    else if (parametrizacaoPdv.habilitaNfe
      && parametrizacaoPdv.geraNfeAutomaticamente)
      //&& parametrizacaoPdv.geraNfeAutomaticamente  && (this.venda.idOrdemServico == null || this.venda.idOrdemServico == 0))
      {
        //gera nota fiscal nfe automaticamente
        this.tituloModal = 'Impressão de Nota Fiscal';
        this.getGerarNfe(venda.idVenda);
      }
    else{
        this.modal.show();
        this.onFocusCpf();
        if (parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe && parametrizacaoPdv.impressaoPadrao && (this.venda.idOrdemServico == null || this.venda.idOrdemServico == 0) 
          && this.venda.emitiuNfce == enumSimNao.Nao 
          && this.venda.emitiuNfe == enumSimNao.Nao 
          && this.venda.emitiuNfse == enumSimNao.Nao ){
          this.textoPerguntaPasso1 = 'Deseja Gerar Recibo, Nfe ou Nfce?';
          this.onFocusBotaoGerarNfce();
        }else if (parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe && !parametrizacaoPdv.impressaoPadrao && (this.venda.idOrdemServico == null || this.venda.idOrdemServico == 0) 
          && this.venda.emitiuNfce == enumSimNao.Nao 
          && this.venda.emitiuNfe == enumSimNao.Nao 
          && this.venda.emitiuNfse == enumSimNao.Nao ){
          this.textoPerguntaPasso1 = 'Deseja Gerar Nfe ou Nfce?';
          this.onFocusBotaoGerarNfce();
        }else if (this.venda.idOrdemServico != null && this.venda.idOrdemServico != 0 && parametrizacaoPdv.habilitaNfse){
            if (parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe){
              this.textoPerguntaPasso1 = 'Deseja Gerar Nota Fiscal Eletrônica, Consumidor ou Nota de Serviço?';
              this.onFocusBotaoGerarNfce();
            }else if (!parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe){
              this.textoPerguntaPasso1 = 'Deseja Gerar Nota Fiscal Eletrônica ou Nota de Serviço?';
              this.onFocusBotaoGerarNfce();
            }else if (parametrizacaoPdv.habilitaNfce && !parametrizacaoPdv.habilitaNfe){
              this.textoPerguntaPasso1 = 'Deseja Gerar Nota Fiscal de Consumidor ou Nota de Serviço?';
              this.onFocusBotaoGerarNfce();
            }
        }else if (parametrizacaoPdv.impressaoPadrao && !parametrizacaoPdv.habilitaNfce && !parametrizacaoPdv.habilitaNfe){
          this.textoPerguntaPasso1 = 'Deseja Gerar Recibo de Venda?';
          this.onFocusBotaoGerarRecibo();
        }else if (!parametrizacaoPdv.impressaoPadrao && parametrizacaoPdv.habilitaNfce && !parametrizacaoPdv.habilitaNfe){
          this.textoPerguntaPasso1 = 'Deseja Gerar Nota Fiscal de Consumidor?';
          this.onFocusBotaoGerarNfce();
        }else if (parametrizacaoPdv.impressaoPadrao && !parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe){
          this.textoPerguntaPasso1 = 'Deseja Gerar Nota Fiscal Eletrônica?';
          this.onFocusBotaoGerarNfe();
        }else if (parametrizacaoPdv.impressaoPadrao && parametrizacaoPdv.habilitaNfce && !parametrizacaoPdv.habilitaNfe){
          this.textoPerguntaPasso1 = 'Deseja Gerar Recibo ou Nota Fiscal de Consumidor?';
          this.onFocusBotaoGerarRecibo();
        }else if (parametrizacaoPdv.impressaoPadrao && !parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe){
          this.textoPerguntaPasso1 = 'Deseja Gerar Recibo ou Nota Fiscal Eletrônica?';
          this.onFocusBotaoGerarRecibo();
        }else if (!parametrizacaoPdv.impressaoPadrao && parametrizacaoPdv.habilitaNfce && parametrizacaoPdv.habilitaNfe){
          this.textoPerguntaPasso1 = 'Deseja Gerar Nota Fiscal de Consumidor ou Eletrônica?';
          this.onFocusBotaoGerarNfce();
        }else if ( 
          this.venda.emitiuNfce == enumSimNao.Sim 
          || this.venda.emitiuNfe == enumSimNao.Sim 
          || this.venda.emitiuNfse == enumSimNao.Sim ){
          this.textoPerguntaPasso1 = 'Deseja imprimir?';
        }else{
          this.textoPerguntaPasso2 = 'Este PDV não esta habilitado para gerar Notas Fiscais ou Recibo de Venda. Para habilitar altere a configuração no PDV no módulo erp.invare.com.br';
          this.passo = 2;
        }
    }
  }

  subsConsultarBoletoByIdVenda: Subscription = null!;
  consultarBoletoByIdVenda(idVenda: number) {
    this.subsConsultarBoletoByIdVenda = this.apiVendaService.consultarBoletoByIdVenda(idVenda, true)
    .subscribe((retorno: BoletoModel[] ) => {
        this.subsConsultarBoletoByIdVenda?.unsubscribe();
        if (retorno != null){
          this.boletos = retorno;
        }
        },
        (err) => {
      this.subsConsultarBoletoByIdVenda?.unsubscribe();
      this.showMessageError.exibirMensagemValidacaoTela(err);
    });
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.disabledBtnEmitirNfe = false;
    this.disabledBtnEmitirNfce = false;
    this.disabledBtnEmitirNfse = false;
    this.loading = false;
    this.modal.hide();
  }

  loading: boolean = false;

  tipoNotaFiscal: enumTipoNotaFiscal = null!;
  getGerarNfse(idVenda: number){
    this.tipoNotaFiscal = enumTipoNotaFiscal.Nfse;
    this.loading = true;
    this.modalVerificaAtualizacaoSefazComponent.enviarNfFromIdVenda(idVenda, this.cpfCnpj, enumTipoNotaFiscal.Nfse);
  }

  getGerarNfe(idVenda: number){
    this.tipoNotaFiscal = enumTipoNotaFiscal.Nfe;
    this.loading = true;
    this.modalVerificaAtualizacaoSefazComponent.enviarNfFromIdVenda(idVenda, this.cpfCnpj, enumTipoNotaFiscal.Nfe);
  }

  getGerarNfce(idVenda: number){
    this.tipoNotaFiscal = enumTipoNotaFiscal.Nfce;
    this.loading = true;
    this.modalVerificaAtualizacaoSefazComponent.enviarNfFromIdVenda(idVenda, this.cpfCnpj, enumTipoNotaFiscal.Nfce);
  }

  cliqueNovaVenda(){
    this.iniciarVenda();
  }
  iniciarVenda(){
    PdvProdutoPagamentoComponent.IdVendaAtiva = 0;
    this.caixaLivre.emit(this.venda);
    this.fecharModal();
    //this.router.navigate([`/pdv/produto-pagamento`]);
    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree([`/pdv/produtos`])
    // );
    // window.open(url, '_self');
  }
  disabledBtnEmitirNfse: boolean = false;
  disabledBtnEmitirNfe: boolean = false;
  disabledBtnEmitirNfce: boolean = false;
  terminoVerificacao(notaFiscal: NotaFiscalModel){
    this.loading = false;
    if (notaFiscal == null){
      switch(this.tipoNotaFiscal){
        case enumTipoNotaFiscal.Nfse:
          this.disabledBtnEmitirNfse = false;
        break;
        case enumTipoNotaFiscal.Nfe:
          this.disabledBtnEmitirNfe = false;
        break;
        case enumTipoNotaFiscal.Nfce:
          this.disabledBtnEmitirNfce = false;
        break;
      }
    }else{
      this.notaFiscal = notaFiscal;

       if (notaFiscal.statusEnvio == enumStatusEnvioNotaFiscal.Concluido){
        swal.fire({
          title: `Parabéns ${ConstantsService.Nome}!`,
          html: `Sua Nota Fiscal foi gerada com sucesso! <br>Deseja imprimir?`,
          showCancelButton: true,
          cancelButtonText: "Não",
          showConfirmButton: true,
          confirmButtonText: "Sim",
          icon: 'success'
        })
          .then((res) => {
            if (res.isConfirmed) {
              
              if (notaFiscal.tipo == enumTipoNotaFiscal.Nfe){
                this.cliqueImprimir2ViaNfe();
                this.venda.emitiuNfe = enumSimNao.Sim;
                this.gerouNotaFiscal.emit(this.venda);
              }
              if (notaFiscal.tipo == enumTipoNotaFiscal.Nfce){
                this.venda.emitiuNfce = enumSimNao.Sim;
                this.cliqueImprimir2ViaNfce();
                this.gerouNotaFiscal.emit(this.venda);
              }
              if (notaFiscal.tipo == enumTipoNotaFiscal.Nfse){
                this.venda.emitiuNfse = enumSimNao.Sim;
                this.cliqueImprimir2ViaNfse();
                this.gerouNotaFiscal.emit(this.venda);
              }
            }else{
              if (notaFiscal.tipo == enumTipoNotaFiscal.Nfe){
                this.venda.emitiuNfe = enumSimNao.Sim;
              }
              if (notaFiscal.tipo == enumTipoNotaFiscal.Nfce){
                this.venda.emitiuNfce = enumSimNao.Sim;
              }
              if (notaFiscal.tipo == enumTipoNotaFiscal.Nfse){
                this.venda.emitiuNfse = enumSimNao.Sim;
              }
              this.fecharModal();
              this.gerouNotaFiscal.emit(this.venda);
            }
          });
      }else if (notaFiscal.statusEnvio == enumStatusEnvioNotaFiscal.Rejeitado
        ||
        notaFiscal.statusEnvio == enumStatusEnvioNotaFiscal.Denegado
        ||
        notaFiscal.statusEnvio == enumStatusEnvioNotaFiscal.Erro){
        swal.fire({
          title: `Que pena ${ConstantsService.Nome}!`,
          html: `Sua nota fiscal foi rejeitada, faz assim<br> Visualize o log corrija a informação que gerou erro e reenvie a nota!`,
          showCancelButton: true,
          cancelButtonText: "Ok",
          showConfirmButton: false,
          icon: 'error'
        })
          .then((res) => {
            if (res.isConfirmed) {  }
          });
      }
      else if (notaFiscal.statusEnvio == enumStatusEnvioNotaFiscal.AguardandoRetorno){
        swal.fire({
          title: `${ConstantsService.Nome}`,
          html: `Sua nota fiscal ainda esta em processamento<br> este processo as vezes demora pois dependemos da Sefaz para processar!<br>
          Após alguns instantes solicite uma atualização para validar o processamento!`,
          showCancelButton: true,
          cancelButtonText: "Ok",
          showConfirmButton: false,
          icon: 'warning'
        })
          .then((res) => {
            if (res.isConfirmed) {  }
          });
      }
    }
  }

  imprimirPromissoriaDeclaracao(tipo: string, formatoImpressao: enumFormatoImpressao){
    //primeiro verifica se existe declaração para a venda gerada
    this.subsVerificaSeExistePromissoria = this.apiGeraPromissoriaCrediarioService.verificaSeExistePromissoria(this.venda.idVenda!, true)
    .subscribe((fileData: any) => {
       this.subsVerificaSeExistePromissoria?.unsubscribe();
       if(tipo == "P"){ //Promissória
        this.gerarPromissoriaDeVenda();
       }else{ //Declaração de dívida
        this.gerarDeclaracaoDeDivida(formatoImpressao);
       }
       this.loading = false;
       this.mensagemService.esconderLock();
      },
      (err) => {
        this.subsVerificaSeExistePromissoria?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        this.mensagemService.esconderLock();
        this.loading = false;
      }
    );
  }

  gerarPromissoriaDeVenda() {
    this.subsGeraPromissoriaService = this.apiGeraPromissoriaCrediarioService.gerarPromissoriaCrediario(this.venda.idVenda!, true)
    .subscribe((fileData: any) => {
      this.subsGeraPromissoriaService?.unsubscribe();
      // Agora o pai pode chamar uma função no componente filho
        this.modalImpressaoComponent.imprimir("04", fileData, 'application/pdf');
      //
      // const blob: any = new Blob([fileData], { type: 'application/pdf' });
      // var pdfUrl = URL.createObjectURL(blob);
      // printJs(pdfUrl);
      },
      (err) => {
        this.subsGeraPromissoriaService?.unsubscribe();
        var reader = new FileReader();
        reader.onload = function() {
          var event = new CustomEvent("error.file", 
          {
              detail: reader.result,
              bubbles: true,
              cancelable: true
          }
          );
          window.dispatchEvent(event);
        }
        reader.readAsText(err.error);
      });
  }

  private gerarDeclaracaoDeDivida(formatoImpressao: enumFormatoImpressao) {    
    this.loading = true;
    var dataAtual = new Date;
    this.nomeArquivo = `declaracao-de-divida-${this.venda.idVenda}-${dataAtual.valueOf()}.pdf`;
    this.mensagemService.exibirLock();
    let filtroImpressaoPromissoria: FiltroDeclaracao = FiltroDeclaracaoInstance.get();
    filtroImpressaoPromissoria.idVenda = this.venda.idVenda;
    filtroImpressaoPromissoria.tipoImpressao = formatoImpressao;

    this.subsGerarDeclaracaoDivida = this.apiGeraRelatorioVendaService.gerarPromissoriaVenda(filtroImpressaoPromissoria, true)
      .subscribe((fileData: any) => {
        this.subsGerarDeclaracaoDivida?.unsubscribe();
        // Agora o pai pode chamar uma função no componente filho
        this.modalImpressaoComponent.imprimir("03", fileData, 'application/pdf');
        //
        // const blob: any = new Blob([fileData], { type: 'application/pdf' });
        // var pdfUrl = URL.createObjectURL(blob);
        // printJs(pdfUrl);
        this.loading = false;
        this.mensagemService.esconderLock();
      },
        (err) => {
          this.subsGerarDeclaracaoDivida?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
          this.mensagemService.esconderLock();
          this.loading = false;
        }
      );
  }
}