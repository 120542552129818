import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { InputMask } from 'primeng/inputmask';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { CnpjModel } from 'src/app/models/auxiliares/cnpj/CnpjModel';
import { ApiAuxiliarConsultaService } from 'src/app/services/notaFiscal/api-auxiliar-consulta.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-crud-pesquisa-consulta-cnpj',
  templateUrl: './modal-crud-pesquisa-consulta-cnpj.component.html',
  styleUrls: ['./modal-crud-pesquisa-consulta-cnpj.component.scss']
})
export class ModalCrudPesquisaConsultaCnpjComponent implements OnInit {

  @ViewChild(UiModalComponent) modalCnpj;
  inputDados: CnpjModel = null!;
  @Output() importar: EventEmitter<CnpjModel> = new EventEmitter<CnpjModel>();
  importarCnpj: boolean = false;
  @Input() modalFrontLayer: boolean = false;

  cpfCnpj: string = '';
  showSocios: boolean = false;
  showEndereco: boolean = false;
  showAtividades: boolean = false;
  showDadosEmpresa: boolean = false;
  loading: boolean = true;
  subsConsultarCnpj: Subscription = null!
  showDadosAMostrar: boolean = false;

  public maskCnpj = AppSettings.MASK.maskCnpj;

  constructor( private showMessageError: ShowMessageError,
    private auxiliarConsultaCnpj: ApiAuxiliarConsultaService) { }

  ngOnInit(): void {
    this.setStartObject();
  }

  // @ViewChild('elmCodigoCnpj') elmCodigoCnpj: InputMask  = null!;
  // onFocusCodigoCnpj(){
  //   setTimeout(()=>{ 
  //     if (this.elmCodigoCnpj != null  && this.elmCodigoCnpj.el != null && this.elmCodigoCnpj.el.nativeElement != null){
  //       this.elmCodigoCnpj.el.nativeElement.focus();
  //     }
  //   },200);    
  // }

  keyPress(event: KeyboardEvent) {
    //alert(event.keyCode);
    if (event.keyCode == 13) {
      this.pesquisar();
    }
  }

  cliqueCancelar(){
    this.modalCnpj.hide();
  }

  cliqueImportar(){
    this.modalCnpj.hide();
    if(this.inputDados.razao_social.length > 60){
      this.inputDados.razao_social = this.inputDados.razao_social.substring(0,60);
    }
    this.importar.emit(this.inputDados);
  }

  changeClassIConShow(show: boolean): string{
    if (show){
      //return 'far fa-angle-down';
      return 'far fa-chevron-down text-c-blue'; //<i class="far fa-chevron-down"></i>
    }
    //return 'far fa-angle-up';
    return 'far fa-chevron-up text-c-blue';
  }

  public abrirModal(cnpj: string, importarCnpj: boolean = false) {
    this.loading = false;
    this.cpfCnpj = cnpj;
    this.setStartObject();
    this.showDadosAMostrar = false;
    this.showAtividades = false;
    this.showEndereco = false;
    this.importarCnpj = importarCnpj;
    this.inputDados.cpf_cnpj = cnpj;
    this.modalCnpj.show();
    if (this.cpfCnpj != ''){
      this.pesquisar();
    }
    //this.onFocusCodigoCnpj();
  }

  pesquisar(){
    this.loading = true;
    this.subsConsultarCnpj = this.auxiliarConsultaCnpj.getEmpresaByCnpj(this.cpfCnpj, true)
      .subscribe( (retorno: CnpjModel) => {
        this.loading = false;
        this.showDadosAMostrar = true;
        this.inputDados = retorno;
        this.subsConsultarCnpj?.unsubscribe();
      },
        (err) => {
          this.subsConsultarCnpj?.unsubscribe();
          this.loading = false;
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  

  setStartObject(){
    this.inputDados = {
      cpf_cnpj: '',
      razao_social: '',
      tipo: '',
      situacao: '',
      data_situacao: '',
      status: '',
      data_abertura: '',
      natureza_juridica: '',
      telefone: '',
      email: '',
      ultima_atualizacao: null,
      capital_social: '',
      inscricao_estadual: '',
      regime_apuracao: '',
      socios: [],
      atividades: [],
      endereco: null!,
      message: ''
    }
  };

}
