<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-xl'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0  ">&nbsp;<i class="far fa-search-dollar"></i>&nbsp;CONSULTAR PREÇO</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-9">
                <input maxlength="60" class="form-control " id="pesquisar" name="pesquisar" type="text" (keypress)="keyPress($event)"
                placeholder="Nome do produto ou EAN" #descricao="ngModel" #elmPesquisar
                [(ngModel)]="filtro">
            </div>
            <div class="col-3 text-end btn-pesquisar-cliente">
                <button class="btn btn-primary btn-md " (click)="pesquisar()">
                    <i class="far fa-file-search"></i>&nbsp;Pesquisar
                </button>
            </div>
        </div>
        <div class="row pt-2">
            <p-table #dtClientes [value]="listarPrecos" dataKey="idPreco" 
                class="p-table nowrap table-hover " [loading]="loadingPesquisar" responsiveLayout="scroll"
                
                [scrollHeight]="heightListaCliente" 
                styleClass=" " [paginator]="false" #elmListaClientes>
                <ng-template pTemplate="header">
                <tr>
                    <th class="text-center ">#</th>
                    <th class="text-center break-word">SKU</th>
                    <th class="text-center ">GTIN(EAN)</th>
                    <th class="text-center ">PRODUTO</th>
                    <th class="text-center ">VALOR</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center ">{{item.idProdutoUnico}}</td>
                        <td class="text-center ">{{item.sku}}</td>
                        <td class="text-center ">{{item.gtin}}</td>
                        <td>{{item.nome}}</td>
                        <td class="text-bold text-center text-c-green">{{item.precoVenda | currency:'BRL'}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">Não foi identificado produtos com o filtro informado</td>
                </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
    <div class="app-modal-footer">
        <button [disabled]="bloquearCampos()" (click)="cliqueLimpar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class=""></i> Limpar
        </button>&nbsp;
        <button type="button" (click)="cliqueCancelar()" 
            class="btn btn-primary inline"><i class="fad fa-window-close"></i> Fechar</button>
    </div>
</app-ui-modal>