import { Component, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppMensagemService } from './theme/shared/components/app-mensagem/app-mensagem.service';
//import { AppModalLockComponent } from './theme/shared/components/app-mensagem/app-modal-lock/app-modal-lock.component';
import { AppCustomToastComponent } from './theme/shared/components/app-mensagem/app-toast/app-custom-toast.component';
import { AppCustomToastService } from './theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { AppModalLoadingComponent } from './theme/shared/components/app-mensagem/app-modal-loading/app-modal-loading.component';
import { AppModalLockComponent } from './theme/shared/components/app-mensagem/app-modal-lock/app-modal-lock.component';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild(AppCustomToastComponent) appCustomToast;
  @ViewChild(AppModalLockComponent) appModalLock;
  @ViewChild(AppModalLoadingComponent) appModalLoading;


  constructor(private readonly mensagemService: AppMensagemService,
    private readonly appCustomToastService: AppCustomToastService,
    private router: Router,
    private config: PrimeNGConfig, private translateService: TranslateService
  ) { 

    // this.router.routeReuseStrategy.shouldReuseRoute = function () {
    //   return false;
    // };

    // this.router.events.subscribe((evt) => {
    //   if (evt instanceof NavigationEnd) {
    //     this.router.navigated = false;
    //     window.scrollTo(0, 0);
    //   }
    // });

  }

  ngOnInit() {
    this.translateService.addLangs(['pt']);
    this.translateService.setDefaultLang('pt');

    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang?.match(/en|fr|pt/) ? browserLang : 'pt');

    this.router.events.subscribe((evt) => {
      this.mensagemService.appmodalLoading = this.appModalLoading;
      this.mensagemService.appmodalLock = this.appModalLock;
      this.appCustomToastService.setAppCustomToast(this.appCustomToast);
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  translate(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
    
  }
}
