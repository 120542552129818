import { ParametrosCancelarVendaModel } from "./ParametrosCancelarVendaModel";

export class ParametrosCancelarVendaModelInstance {
    public static get(): ParametrosCancelarVendaModel {
        return {
            idVenda: 0,
            idCaixa: 0,
            idTabelaCentroCusto: 0,
            observacao: '',
            idMotivoCancelamento: null,
            idsVenda: null!
        };
    }
}