import { NotaFiscalTransReboqueModelInstance } from "../NotaFiscalTransReboque/NotaFiscalTransReboqueModelInstance";
import { NotaFiscalTransVolModelInstance } from "../NotaFiscalTransVol/NotaFiscalTransVolModelInstance";
import { NotaFiscalTransporteModel } from "./notaFiscalTransporteModel";



export class NotaFiscalTransporteModelInstance {
    public static get(): NotaFiscalTransporteModel {
        return {
            idNotaFiscal: 0,
            modalidadeFrete: '9',
            cnpjTransportador: '',
            cpfTransportador: '',
            razaoSocialOuNome: '',
            inscricaoEstadualTransportador: '',
            enderecoCompleto: '',
            nomeMunicipio: '',
            siglaUf: '',
            valorServico: 0,
            valorBcRetencaoIcms: 0,
            aliquotaRetencao: 0,
            valorIcmsRetido: 0,
            cfop: '',
            codigoMunucipioOcorIcmsTransporte: '',
            placaVeiculoTransporte: '',
            siglaUfTransporte: '',
            registroNacionalCargaTransporte: '',
            idNotaFiscalNavigation: null!,
            notaFiscalTransReboques: [NotaFiscalTransReboqueModelInstance.get()],
            notaFiscalTransVols: [NotaFiscalTransVolModelInstance.get()]
        };
    }
}
