<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-xl'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 text-bold ">&nbsp;<i class="far fa-shopping-cart"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body modal-detalhe-produto" *ngIf="inputDados != null">
        <div class="row" >
            <div class="col-12">
                <h4 class="sub-titulo">
                    {{descricaoProdutoServico}}
                </h4>
            </div>
        </div>
        <div class="row ">
            <hr>
        </div>
        <div class="row" *ngIf="preco != null && inputDados != null">
            <div class="col-6">
                <h4 class="sub-titulo">
                    Preço Unitário <b>{{preco.precoVenda | currency:'BRL' }}</b>
                </h4>
            </div>
            <div class="col-6">
                <h4 class="sub-titulo">
                    Sub Total <b>{{(inputDados.quantidade! * preco.precoVenda!) | currency:'BRL' }}</b>
                </h4>
            </div>
        </div>
        <div class="row ">
            <hr>
        </div>
        
        <div class="row" *ngIf="estoqueNestaLoja">
            <div class="col-12 ">
                <p-table [value]="estoquesDestaLoja" dataKey="idEstoque" 
                    [scrollHeight]="heightLista" class="p-table nowrap table-hover " responsiveLayout="scroll"
                    [loading]="loading" 
                    [paginator]="false" #elmListaVendasPendentes
                    currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">Variação</th>
                            <th class="text-center">Contábil</th>
                            <th class="text-center">Virtual</th>
                            <th class="text-center">Reservado</th>
                            <th class="text-center">Condicional</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                            <td class="align-middle text-center">
                                <span class="text-center" *ngIf="item.tipoProduto == 'V'"><label
                                    >{{item.idProduto.nomeVariacao }}</label></span>
                                <span class="text-center" *ngIf="item.tipoProduto != 'V'" ><label
                                    >Não Possui</label></span>
                            </td>
                            <td class="align-middle  text-center">{{ item.quantidadeContabil }}</td>
                            <td class="align-middle  text-center">{{ item.quantidadeVirtual }}</td>
                            <td class="align-middle  text-center">{{ item.quantidadeReservado }}</td>
                            <td class="align-middle  text-center">{{ item.quantidadeCondicional }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="5">Não há estoques para este produto</td>
                        </tr>
                    </ng-template>       
                </p-table>         
            </div>
        </div>

        <div class="row" *ngIf="!estoqueNestaLoja">
            <div class="col-12 ">
                <p-table [value]="estoquesDemaisLojas" dataKey="idEstoque" 
                    [scrollHeight]="heightLista" class="p-table nowrap table-hover " responsiveLayout="scroll"
                    [loading]="loading" 
                    [paginator]="false" #elmListaVendasPendentes
                    currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
                    <ng-template pTemplate="header">
                        <tr>
                            <th class="text-center">Código Loja</th>
                            <th class="text-center">Variação</th>
                            <th class="text-center">Contábil</th>
                            <th class="text-center">Virtual</th>
                            <th class="text-center">Reservado</th>
                            <th class="text-center">Condicional</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                            <td class="align-middle text-center">{{ item.idLoja }}</td>
                            <td class="align-middle text-center">
                                <span class="text-center" *ngIf="item.idProdutoUnicoNavigation.tipoProduto == 'V'"><label
                                    >{{item.idProduto.nomeVariacao }}</label></span>
                                <span class="text-center" *ngIf="item.idProdutoUnicoNavigation.tipoProduto != 'V'" ><label
                                    >Não Possui</label></span>
                            </td>
                            <td class="align-middle  text-center">{{ item.quantidadeContabil }}</td>
                            <td class="align-middle  text-center">{{ item.quantidadeVirtual }}</td>
                            <td class="align-middle  text-center">{{ item.quantidadeReservado }}</td>
                            <td class="align-middle  text-center">{{ item.quantidadeCondicional }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                        <tr>
                            <td colspan="6">Não há estoques em outras lojas</td>
                        </tr>
                    </ng-template>       
                </p-table>         
            </div>
        </div>
        
    </div>
    
    <div class="app-modal-footer">
        <button [disabled]="false" (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class="fad fa-window-close"></i> Fechar
        </button>&nbsp;
        <button [disabled]="false" (click)="visualizarEstoqueOutrasLojas()" type="button" *ngIf="estoqueNestaLoja"
        class="btn btn-primary m-r-5 inline"><i class="fad fa-store"></i> Outras Lojas </button>

        <button [disabled]="false" (click)="visualizarEstoqueNetaLoja()" type="button" *ngIf="!estoqueNestaLoja"
        class="btn btn-primary m-r-5 inline"><i class="fad fa-store"></i> Est. Nesta Loja
        </button>
        
    </div>
</app-ui-modal>