import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, catchError, of, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  constructor(private http: HttpClient) { }

  
  private transform(value: string | null): string {
    if (!value) {
      // Se a URL não existir, define o valor como "http://localhost:3000"
      return 'http://localhost:3000';
    }

    // Se a URL não tiver protocolo (http ou https), adiciona "http://"
    if (!/^https?:\/\//i.test(value)) {
      value = `http://${value}`;
    }

    // Troca "http" por "https" e vice-versa
    if (/^https:\/\//i.test(value)) {
      value = value.replace(/^https:\/\//i, 'http://');
    }


    // Remove o caractere "/" do final da URL, se estiver presente
    if (value.endsWith('/')) {
      value = value.slice(0, -1);
    }

    return value;
  }

  downloadPdf(server: string) {
    server = this.transform(server);
    return this.http.get(`${server}/generate-pdf`, { responseType: 'blob' });
  }

  printPdf(server: string, content: string, printerName: string) {
    server = this.transform(server);
    return this.http.post(`${server}/print`, { content, printerName });
  }

  getPrinters(server: string):Observable<PrinterDevice[]> {
    server = this.transform(server);
    return this.http.get(`${server}/printers`) as Observable<PrinterDevice[]>;
  }

  pingServer(server: string): Observable<boolean> {
    server = this.transform(server);
    let url = `${server}/printers`;
    return new Observable<boolean>(observer => {
      const timer = setTimeout(() => {
        observer.next(false); // Emite false se o timeout for atingido
        observer.complete();
      }, 3000); // Timeout de 3 segundos
  
      this.http.get(url, { responseType: 'text' }).subscribe(
        () => {
          clearTimeout(timer); // Cancela o timeout se a resposta for bem-sucedida
          observer.next(true); // Emite true se a requisição for bem-sucedida
          observer.complete();
        },
        (error: HttpErrorResponse) => {
          clearTimeout(timer); // Cancela o timeout em caso de erro
          observer.next(false); // Emite false se houver erro
          observer.complete();
        }
      );
    });
  }
  
}

export interface PrinterDevice {
  deviceId: string;
  name: string;
  paperSizes: string[];
}
