import { IOption } from "ng-select";

export enum enumCombustivelVeiculo {
    Alcool = 'A',
    AlcooleGasolina = 'Z',
    Diesel = 'D',
    Eletrico = 'E',
    Gasolina = 'G',
    Hibrido = 'H'
}

export class tipoCombustivelVeiculo{
    static getListaTipoCombustivel(): Array<IOption>{
        let itens: Array<IOption> = [
            { label: 'Todos', value: 'T' },
            { label: 'Álcool', value: 'A' }, 
            { label: 'Álcool/Gasolina', value: 'Z' }, 
            { label: 'Diesel', value: 'D' },
            { label: 'Elétrico', value: 'E' },
            { label: 'Gasolina', value: 'G' },
            { label: 'Híbrido', value: 'H' },
          ];
        return itens;
    }
    static getDescricao(status: enumCombustivelVeiculo){
        switch(status){
            case enumCombustivelVeiculo.Alcool:
                return "Alcool";
            case enumCombustivelVeiculo.AlcooleGasolina:
                return "AlcooleGasolina";
            case enumCombustivelVeiculo.Diesel:
                return "Diesel";
            case enumCombustivelVeiculo.Eletrico:
                return "Eletrico";
            case enumCombustivelVeiculo.Gasolina:
                return "Gasolina";
            case enumCombustivelVeiculo.Hibrido:
                return "Hibrido";
        }
        return '';
    }
}