import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { RequestPageModel } from 'src/app/models/cliente/TokenRedirect/RequestPageModel';
import { ResponsePageModel } from 'src/app/models/cliente/TokenRedirect/ResponsePageModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTokenRedirectService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Controle;
    private version = 'api/'
    private controller = 'tokenRedirect';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    transferpage(idTransferToken: string): Observable<ResponsePageModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/transferpage?idTransferToken=${idTransferToken}`, true);
    }

    requestPage(request: RequestPageModel): Observable<string> {
        return this.gc.post(this.api, `${this.version}${this.controller}/requestpage`, request, true);
    }
}