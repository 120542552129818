import { NotaFiscalTotalRetencaoTributoModel } from "./notaFiscalTotalRetencaoTributoModel";

export class NotaFiscalTotalRetencaoTributoModelInstance {
    public static get(): NotaFiscalTotalRetencaoTributoModel {
        return {
            idNotaFiscal: 0,
            valorRetidoPis: null,
            valorRetidoCofins: null,
            valorRetidoCsll: null,
            valorBcIrrf: null,
            valorRetidoIrrf: null,
            valorBcRetencaoPrevidenciaSocial: null,
            valorRetencaoPrevidenciaSocial: null,
            valorRetidoCpp: null,
            valorRetidoInss: null,
            idNotaFiscalNavigation: null!
        };
    }
    public static getArray(): NotaFiscalTotalRetencaoTributoModel[] {
        return [this.get()];
    }
}
