import { NotaFiscalInfAdModel } from "./notaFiscalInfAdModel";


export class NotaFiscalInfAdModelInstance {
    public static get(): NotaFiscalInfAdModel {
        return {
            idNotaFiscal: 0,
            informacoesInteresseFisco: '',
            informacoesCompleInteresseContribuinte: '',
            idNotaFiscalNavigation: null!,
            notaFiscalInfAdContribuintes: null!,
            notaFiscalInfAdFiscos: null!,
            notaFiscalInfAdProcRefers: null!
        };
    }
}
