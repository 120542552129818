import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModalComponent } from './ui-modal/ui-modal.component';
import { UiModalLoadingComponent } from './ui-modal-loading/ui-modal-loading.component';
import { AnimationModalComponent } from './animation-modal/animation-modal.component';

@NgModule({
    imports: [CommonModule],
    declarations: [UiModalComponent, UiModalLoadingComponent, AnimationModalComponent],
    exports: [UiModalComponent, UiModalLoadingComponent, AnimationModalComponent]
})
export class ModalModule {}
