
export interface CustomFormatoImpressao {
    id: number | null;
    layoutImpressao: string;
    tipoComanda: string;
}

export class CustomFormatoImpressaoInstance {
    public static get(): CustomFormatoImpressao {
        return {
            id: null,
            layoutImpressao: 'Cupom80mm',
            tipoComanda: ''
        };
    }
}