import { Component, OnInit, ViewChild, ViewEncapsulation, Input } from '@angular/core';
import { MensagemModal } from 'src/app/models/mensagem-modal';

@Component({
  selector: 'app-modal-confirmacao',
  templateUrl: './app-modal-confirmacao.component.html',
  styleUrls: ['./app-modal-confirmacao.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppModalConfirmacaoComponent implements OnInit {

  @Input() closable: boolean = false;
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() baseZIndex: string = '';
  @Input() modal: boolean = false;

  mensagem: MensagemModal = {
    exibir: false,
    titulo: '',
    mensagem: '',
    textoBtnNao: 'Não',
    textoBtnSim: 'Sim',
    funcaoSim: null,
    funcaoNao: null,
  };

  constructor() { }

  ngOnInit() {
  }

  exibirModalConfirmacao(mensagemModal: MensagemModal) {

    this.mensagem = {
      exibir: mensagemModal.exibir,
      titulo: mensagemModal.titulo,
      mensagem: mensagemModal.mensagem,
      textoBtnNao: mensagemModal.textoBtnNao,
      textoBtnSim: mensagemModal.textoBtnSim,
      funcaoSim: mensagemModal.funcaoSim,
      funcaoNao: mensagemModal.funcaoNao
    };
  }

  buttonCancelar() {
    if (this.mensagem.funcaoNao) {
      this.mensagem.funcaoNao();
    }
  }

  buttonConfirmar() {
    if (this.mensagem.funcaoSim != null){
      this.mensagem.funcaoSim();
    }
  }

  buttonFechar() {
  }
}
