import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "src/app/app-settings";
import { CommunicationManager } from "src/app/core/communication-manager";
import { FiltroDeclaracao } from "src/app/models/relatorios/FiltroDeclaracao";
import { FiltroExtratoVenda } from "src/app/models/relatorios/FiltroExtratoVenda";
import { FiltroRelatorioVenda } from "src/app/models/relatorios/FiltroRelatorioVenda";
import { AuthService } from "src/app/theme/shared/auth/auth.service";
import { AppMensagemService } from "src/app/theme/shared/components/app-mensagem/app-mensagem.service";

@Injectable({
    providedIn: 'root'
})
export class ApiGeraRelatorioVendaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Relatorio;
    private version = 'api/'
    private controller = 'geraRelatorioVenda';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    gerar(filtro: FiltroRelatorioVenda, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/gerar`, filtro, exibeCarregando);
    }

    validar(filtro: FiltroRelatorioVenda, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/validar`, filtro, exibeCarregando);
    }

    gerarReciboVenda(filtro: FiltroExtratoVenda, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/gerarReciboVenda`, filtro, exibeCarregando);
    }

    gerarPromissoriaVenda(filtro: FiltroDeclaracao, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/gerarDeclaracaoPdv`, filtro, exibeCarregando);
    }    
}