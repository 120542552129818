import { enumSimNao } from "src/app/enum/enumSimNao";
import { NotaFiscalDestinatarioModel } from "./notaFiscalDestinatarioModel";



export class NotaFiscalDestinatarioModelInstance {
    public static get(): NotaFiscalDestinatarioModel {
        return {
            idNotaFiscal: 0,
            cpfCnpj: '',
            identificacaoDestinatarioEstrangeiro: '',
            tipoPessoa: '',
            razaoSocialNomeDestinatario: '',
            tipoLogradouro: 'Rua',
            logradouro: '',
            numero: '',
            complemento: '',
            tipoBairro: 'Bairro',
            bairro: '',
            codigoMunicipio: '',
            nomeMunicipio: '',
            siglaUf: '',
            codigoCep: '',
            codigoPais: '',
            nomePais: '',
            telefone: '',
            indicadorIeDestinatario: '9',
            inscricaoEstadualDestinatario: '',
            inscricaoSuframa: '',
            email: '',
            idNotaFiscalNavigation: null!,
            inscricaoMunicipalDestinatario: '',
            nfseTomadorOrgaoPublico: enumSimNao.Nao,
            nomeFantasia: '',
        };
    }
}
