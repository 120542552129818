import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTabelaCidadeService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Dominios;
    private version = 'api/'
    private controller = 'tabelaCidade';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAllQuery(filtro: string, exibeCarregando: boolean) {
        filtro = encodeURIComponent(filtro);
        return this.gc.get(this.api, `api/${this.controller}/query?filtro=${filtro}`, exibeCarregando);
    }

    getCidadeByCodigoMunicipioIbge(codigoMunicipioIbge: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `api/${this.controller}/getCidadeByCodigoMunicipioIbge/${codigoMunicipioIbge}`, exibeCarregando);
    }   

    getAllCidadesByEstado(uf: string) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllCidadesByEstado/${uf}`, true);
    }

    getCodigoMunicipioIbgeByNomeCidade(cidade: string, estado: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getCodigoMunicipioIbgeByNomeCidade?cidade=${cidade}&estado=${estado}`, exibeCarregando);
    }

    getAllTabelaCidade(exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}`, exibeCarregando);
    }
}