import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import swal from 'sweetalert2';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { enumTipoCliente } from 'src/app/enum/enumTipoCliente';
import { enumTipoPessoa } from 'src/app/enum/enumTipoPessoa';
import { CnpjModel } from 'src/app/models/auxiliares/cnpj/CnpjModel';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { PessoaFisicaJuridicaModelInstance } from 'src/app/models/cliente/PessoaFisicaJuridica/PessoaFisicaJuridicaModelInstance';
import { TabelaCidadeModel } from 'src/app/models/dominios/TabelaCidade/tabelaCidadeModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiPessoaFisicaJuridicaService } from 'src/app/services/cliente/api-pessoa-fisica-juridica.service';
import { ApiTabelaCidadeService } from 'src/app/services/dominios/api-tabela-cidade.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Router } from '@angular/router';
import { EnderecoModelInstance } from 'src/app/models/cliente/endereco/EnderecoModelInstance';
import { TabelaEstadoModel } from 'src/app/models/dominios/TabelaEstado/tabelaEstadoModel';
import { CepModel } from 'src/app/models/auxiliares/cep/CepModel';
import { enumTipoBairro } from 'src/app/enum/enumTipoBairro';
import { enumTipoLogradouro } from 'src/app/enum/enumTipoLogradouro';
import { ApiTabelaEstadoService } from 'src/app/services/dominios/api-tabela-estado.service';
import { ApiAuxiliarConsultaService } from 'src/app/services/notaFiscal/api-auxiliar-consulta.service';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { InputMask } from 'primeng/inputmask';
import { enumAplicacao } from 'src/app/enum/enumAplicacao';
import { environment } from 'src/environments/environment';
import { ApiTokenRedirectService } from 'src/app/services/controle/api-token-redirect.service';
import { EnderecoModel } from 'src/app/models/cliente/Endereco/enderecoModel';
import { ConstantsService } from '../../constants/constants.service';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { enumModoTela } from 'src/app/enum/enumModoTela';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { ApiEnderecoService } from 'src/app/services/cliente/api-endereco.service';

enum enumTemplateTelaSelecionarCliente {
  listar,
  cadastrar
}
export interface TipoClienteCustom {
  selecionado: boolean,
  idTabelaTipoCliente: string,
  nome: string
}

@Component({
  selector: 'app-modal-selecionar-cliente',
  templateUrl: './modal-selecionar-cliente.component.html',
  styleUrls: ['./modal-selecionar-cliente.component.css']
})
export class ModalSelecionarClienteComponent implements OnInit {

  public maskCpf = AppSettings.MASK.maskCpf;
  public maskCnpj = AppSettings.MASK.maskCnpj;
  public maskRg = AppSettings.MASK.maskRg;
  public maskDateSlash = AppSettings.MASK.maskDateSlash;
  public maskTeleAreaCel = AppSettings.MASK.maskTeleAreaCel;
  public maskCep = AppSettings.MASK.maskCep;
  @Input() modoTela: enumModoTela = enumModoTela.Cadastrar;

  @ViewChild(UiModalComponent) modal;
  @Output() selecionouCliente: EventEmitter<PessoaFisicaJuridicaModel> = new EventEmitter();
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  inputDados: PessoaFisicaJuridicaModel = PessoaFisicaJuridicaModelInstance.get();
  tipoCliente: enumTipoCliente = enumTipoCliente.Todos;
  filtro: string = '';
  codigoCliente: string = '';
  loadingPesquisar: boolean = false;
  listaClientes: PessoaFisicaJuridicaModel[] = [];
  emptymessage: string = 'Não foi encontrado informações de clientes a partir do filtro informado, digite o Cpf/Cnpj ou Razão social ou Nome/Nome Fantasia';

  templateTela: enumTemplateTelaSelecionarCliente = enumTemplateTelaSelecionarCliente.listar;
  listar = enumTemplateTelaSelecionarCliente.listar;
  cadastrar = enumTemplateTelaSelecionarCliente.cadastrar;
  maxDate: Date = new Date();
  itensCodigoRegimeTributario: Array<IOption> | null = null;
  itensIndicadorInscricaoEstadual: Array<IOption> | null = null;
  subsGetAllEnderecosByCpfCnpj: Subscription = null!;

  permiteCadPF: string = enumSimNao.Sim;
  permiteCadPJ: string = enumSimNao.Sim;
  padraoCadPfOuPj: string = enumSimNao.Sim;

  constructor(private pessoaFisicaJuridicaService: ApiPessoaFisicaJuridicaService,
    private pdvService: ApiPdvService,
    private auth: AuthService,
    private tabelaEstadoService: ApiTabelaEstadoService,
    private apiTokenRedirectService: ApiTokenRedirectService,
    private apiAuxiliarConsultaService: ApiAuxiliarConsultaService,
    private router: Router,
    private appCustomToastService: AppCustomToastService,
    private apiTabelaCidadeService: ApiTabelaCidadeService,
    private apiEnderecoService: ApiEnderecoService,
    private showMessageError: ShowMessageError) {
    this.getScreenSize();
  }

  ngOnInit(): void {

    if (ConstantsService.ParametrizacaoCadastro != null) {
      if (ConstantsService.ParametrizacaoCadastro.permiteCadastroPessoaFisica != null) {
        this.permiteCadPF = ConstantsService.ParametrizacaoCadastro.permiteCadastroPessoaFisica;
      }
      if (ConstantsService.ParametrizacaoCadastro.permiteCadastroPessoaJuridica != null) {
        this.permiteCadPJ = ConstantsService.ParametrizacaoCadastro.permiteCadastroPessoaJuridica;
      }
      if (ConstantsService.ParametrizacaoCadastro.padraoCadastroPessoaFisicaJuridica != null) {
        this.padraoCadPfOuPj = ConstantsService.ParametrizacaoCadastro.padraoCadastroPessoaFisicaJuridica;
      }
    }

    this.setDefaultValues();
  }

  setDefaultValues() {
    this.inputDados.tipoPessoa = enumTipoPessoa.Fisica;

    this.itensCodigoRegimeTributario = [
      { value: '', label: 'Selecione' },
      { value: 'S', label: 'Simples Nacional' },
      { value: 'N', label: 'Regime Normal' }
    ];

    this.itensIndicadorInscricaoEstadual = [
      { value: '', label: 'Selecione' },
      { value: '1', label: 'Contribuinte ICMS' },
      { value: '2', label: 'Contribuinte isento ' },
      { value: '9', label: 'Não Contribuinte' }
    ];
  }

  onChangeCheckboxNaoInformarCpfCnpj(event: any) {
    let checked = event.target.checked;
    this.inputDados.informouCpfCnpj = (checked) ? enumSimNao.Nao : enumSimNao.Sim;
  }

  modoTelaAlteracao: enumModoTela = enumModoTela.Alterar;
  cpfCnpjInformado: string = '';

  subsSalvarEndereco: Subscription = null!;
  salvarEndereco(item: EnderecoModel) {
    item.cpfCnpj = this.inputDados.cpfCnpj;
    this.subsSalvarEndereco = this.pessoaFisicaJuridicaService.salvarEndereco(item, true).subscribe(
      (retorno: EnderecoModel) => {
        this.subsSalvarEndereco?.unsubscribe();
        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Endereço salvo com sucesso'], "Sucesso");
        item = retorno;

        this.idEnderecoSelecionado = item.idEndereco;

        //início ajustes para atualizar o input de endereço no emit, em especial para o cenário de entrega que também chama essa modal.
        this.inputDados.enderecos.filter(f => f.idEndereco != item.idEndereco).forEach(f => {
          f.enderecoEntrega = enumSimNao.Nao;
          f.enderecoPrincipal = enumSimNao.Nao;
        });

        let indexEnderecoAtualizado = this.inputDados.enderecos.findIndex(f => f.idEndereco == retorno.idEndereco);
        if (indexEnderecoAtualizado != -1) {
          this.inputDados.enderecos[indexEnderecoAtualizado] = retorno;
        } else {
          this.inputDados.enderecos.push(retorno);
        }
        //final

        this.selecionouCliente.emit(this.inputDados);
        this.showMessageError.limparMensagensAlerta();
      },
      (err) => {
        this.subsSalvarEndereco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  subsRemoverEndereco: Subscription = null!;
  removerEndereco(item: EnderecoModel) {
    this.showMessageError.limparMensagensAlerta();
    if (item.idEndereco != 0) {
      let index = this.inputDados.enderecos.findIndex(f => f.idEndereco == item.idEndereco);
      if (index == -1) return;

      swal.fire({
        title: "Atenção",
        html: "Tem certeza que deseja remover este endereço?",
        showCancelButton: true,
        cancelButtonText: "Não",
        showConfirmButton: true,
        confirmButtonText: "Sim",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.subsRemoverEndereco = this.pessoaFisicaJuridicaService.removerEndereco(item.idEndereco, true).subscribe(
              (retorno: void) => {
                this.subsRemoverEndereco?.unsubscribe();
                this.appCustomToastService.toast(TiposMensagem.sucesso, ['Endereço removido com sucesso'], "Sucesso");
                this.inputDados.enderecos.splice(index, 1);
                if (this.inputDados.enderecos.length > 0) {
                  this.indexEnderecoSelecionado = 0;
                } else {
                  this.instanceEndereco();
                }
                this.showMessageError.limparMensagensAlerta();
              },
              (err) => {
                this.subsRemoverEndereco?.unsubscribe();
                this.showMessageError.exibirMensagemValidacaoTela(err);
              });

          }
        });
    } else {
      let index = this.inputDados.enderecos.findIndex(f =>
        f.cep == item.cep &&
        f.rua == item.rua &&
        f.numero == item.numero &&
        f.bairro == item.bairro &&
        f.complemento == item.complemento &&
        f.siglaUf == item.siglaUf &&
        f.codigoMunicipioIbge == item.codigoMunicipioIbge
      );
      if (index == -1) return;
      if (this.inputDados.enderecos.length == 1) {
        this.instanceEndereco();
      } else {
        this.indexEnderecoSelecionado = 0;
        this.inputDados.enderecos.splice(index, 1);
      }
    }
  }

  abrirModal(pessoaFisicaJuridica: PessoaFisicaJuridicaModel, permitirNaoInformarCliente: boolean, somenteConsulta: boolean) {
    this.somenteConsulta = somenteConsulta;
    this.permitirNaoInformarCliente = permitirNaoInformarCliente;
    this.pessoaFisicaJuridica = pessoaFisicaJuridica;
    if (this.estados == null || this.estados.length == 0) {
      this.getAllEstados(true);
    } else {
      this.abrirModalPasso2(pessoaFisicaJuridica, permitirNaoInformarCliente, somenteConsulta);
    }
  }

  somenteConsulta: boolean = false;
  permitirNaoInformarCliente: boolean = true;
  pessoaFisicaJuridica: PessoaFisicaJuridicaModel = null!;
  abrirModalPasso2(pessoaFisicaJuridica: PessoaFisicaJuridicaModel, permitirNaoInformarCliente: boolean, somenteConsulta: boolean = false) {
    if (pessoaFisicaJuridica == null) {
      this.cpfCnpjInformado = '';
      this.inputDados = PessoaFisicaJuridicaModelInstance.get();
      this.inputDados.tipoPessoa = enumTipoPessoa.Fisica;
      this.templateTela = enumTemplateTelaSelecionarCliente.listar;
      this.pessoaSelecionada = null!;
      this.listaClientes = [];
      this.onFocusCpfCnpj();
    } else {
      this.inputDados = pessoaFisicaJuridica;
      if(this.inputDados.dataNascimento != null) this.inputDados.dataNascimento = new Date(this.inputDados.dataNascimento);
      this.cpfCnpjInformado = this.inputDados.cpfCnpjInformado;
      this.textoBotaoVoltar = 'Sel. Outro';
      this.templateTela = this.cadastrar;
      this.pessoaSelecionada = pessoaFisicaJuridica;
      this.listaClientes = [pessoaFisicaJuridica];
      if (pessoaFisicaJuridica.enderecos.length > 0 && pessoaFisicaJuridica.enderecos[this.indexEnderecoSelecionado].cep != '') {
        if(this.inputDados.enderecos[this.indexEnderecoSelecionado].idEndereco == 0){
          //verificar se realmente não possui endereço. Caso em que a venda é finalizada, o endereço não vem no retorno.
          this.consultarEnderecoByCpfCnpj(this.inputDados.cpfCnpj);
        }else{
          this.naoInformarEndereco = false;
          let rowIndexEndereco = this.inputDados.enderecos.findIndex(f => f.enderecoPrincipal == enumSimNao.Sim);
          if (rowIndexEndereco == -1) rowIndexEndereco = 0;
          this.indexEnderecoSelecionado = rowIndexEndereco;
          this.idEnderecoSelecionado = this.inputDados.enderecos[this.indexEnderecoSelecionado].idEndereco;
          this.getAllCidadesByEstado(this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf, '', this.inputDados.enderecos[this.indexEnderecoSelecionado].codigoMunicipioIbge);
        }
      } 
      else 
      {
        if (!this.naoInformarEndereco) {
          //verificar se realmente não possui endereço. Caso em que a venda é finalizada, o endereço não vem no retorno.
          this.consultarEnderecoByCpfCnpj(this.inputDados.cpfCnpj);
          if(this.inputDados.enderecos == null || this.inputDados.enderecos.length == 0){
            this.instanceEndereco();
          }
          // else{
          //   //teste jander
          //   this.naoInformarEndereco = false;
          //   let rowIndexEndereco = this.inputDados.enderecos.findIndex(f => f.enderecoPrincipal == enumSimNao.Sim);
          //   if (rowIndexEndereco == -1) rowIndexEndereco = 0;
          //   this.indexEnderecoSelecionado = rowIndexEndereco;
          //   this.idEnderecoSelecionado = this.inputDados.enderecos[this.indexEnderecoSelecionado].idEndereco;
          //   this.getAllCidadesByEstado(this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf, '', this.inputDados.enderecos[this.indexEnderecoSelecionado].codigoMunicipioIbge);
          // }
        } else {
          this.inputDados.enderecos = [];
        }
      }

      switch (this.inputDados.tipoPessoa) {
        case enumTipoPessoa.Fisica:
          this.handleChangePF({ target: { checked: true } });
          break;
        case enumTipoPessoa.Juridica:
          this.handleChangePJ({ target: { checked: true } });
          break;
        case enumTipoPessoa.Estrangeiro:
          this.handleChangePE({ target: { checked: true } });
          break;
      }
      this.onFocusCpfCnpj();
    }
    this.modal.show();
    this.filtro = '';
    this.codigoCliente = '';
    this.onFocusPesquisar();
  }
    
  consultarEnderecoByCpfCnpj(cpfCnpj: string) {
    this.subsGetAllEnderecosByCpfCnpj = this.apiEnderecoService.getAllByIdPessoaFisicaJuridica(cpfCnpj)
      .subscribe(retorno => {
        this.subsGetAllEnderecosByCpfCnpj?.unsubscribe();
        if (retorno != null && retorno.length > 0) {
          // if(this.inputDados.enderecos == null || this.inputDados.enderecos.length == 0){
          //   this.instanceEndereco();
          // }
          this.inputDados.enderecos = retorno as EnderecoModel[];
          if(this.inputDados.enderecos != null){
            this.naoInformarEndereco = false;
            let rowIndexEndereco = this.inputDados.enderecos.findIndex(f => f.enderecoPrincipal == enumSimNao.Sim);
            if (rowIndexEndereco == -1) rowIndexEndereco = 0;
            this.indexEnderecoSelecionado = rowIndexEndereco;
            this.idEnderecoSelecionado = this.inputDados.enderecos[this.indexEnderecoSelecionado].idEndereco;
            this.getAllCidadesByEstado(this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf, '', this.inputDados.enderecos[this.indexEnderecoSelecionado].codigoMunicipioIbge);
          }
        }
      },
        (err) => {
          this.subsGetAllEnderecosByCpfCnpj?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
  }  

  @ViewChild('itemClientePesquisado') itemClientePesquisado: ElementRef = null!;
  onFocusLista() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      if (this.itemClientePesquisado != null) {
        this.itemClientePesquisado.nativeElement.focus();
      }
    }, 0);
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  @ViewChild('elmPesquisarCliente') elmPesquisarCliente: ElementRef = null!;
  onFocusPesquisar() {
    setTimeout(() => { // this will make the execution after the above boolean has changed
      if (this.elmPesquisarCliente != null) {
        this.elmPesquisarCliente.nativeElement.focus();
      }
    }, 0);
  }

  @ViewChild('elmCpfCnpj') elmCpfCnpj: InputMask = null!;
  onFocusCpfCnpj() {
    setTimeout(() => {
      if (this.elmCpfCnpj != null && this.elmCpfCnpj.el != null && this.elmCpfCnpj.el.nativeElement != null) {
        this.elmCpfCnpj.el.nativeElement.focus();
      }
    }, 60);
  }

  cliqueCancelar() {
    this.fecharModal();
  }

  fecharModal() {
    this.fechouModal.emit(null);
    this.idEnderecoSelecionado = 0;
    this.indexEnderecoSelecionado = 0;
    this.modal.hide();
  }


  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    } else if (event.keyCode == 13) {
      if (this.focusLista && this.listaClientes != null && this.listaClientes.length == 1) {
        this.onRowIconSelect(this.listaClientes[this.indexEnderecoSelecionado])
      } else {
        this.pesquisar();
      }
      this.focusLista = false;
    }
  }

  onRowIconSelect(param: PessoaFisicaJuridicaModel) {
    this.inputDados = param;
    this.selecionouCliente.emit(this.inputDados);
    this.fecharModal();
  }

  onRowSelect(param: PessoaFisicaJuridicaModel) {
    if (this.somenteConsulta) return;
    this.inputDados = this.pessoaSelecionada;
    this.selecionouCliente.emit(this.inputDados);
    this.fecharModal();
  }

  onRowUnselect(param: any) {

  }

  // get scroolHeightListaCliente(): string{
  //   return 100vh
  // }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    //this.screenWidth = window.innerWidth;
    //console.log(this.screenHeight, this.screenWidth);
    //alert(this.screenHeight);
  }

  get heightListaCliente(): string {
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  existeEnderecoSalvo(){
    if (this.inputDados.enderecos == null || this.inputDados.enderecos.length == 0){
      return false;
    }
    let index = this.inputDados.enderecos.findIndex(f => f.idEndereco != 0);
    if (index > -1){
      return true;
    }
    return false;
  }

  naoInformarEndereco: boolean = true;
  changeNaoInformarEndereco(event: any) {
    if (event.target.checked) {
      if (this.inputDados.enderecos == null){
        this.inputDados.enderecos = [];
      }else{
        if (this.inputDados.enderecos.length > 0){
          let idsEndereco = this.inputDados.enderecos.filter(f => f.idEndereco == 0).map(m => m.idEndereco);
          idsEndereco.forEach(f => {
            let indexParaRemover = this.inputDados.enderecos.findIndex(f => f.idEndereco == 0);
            this.inputDados.enderecos.splice(indexParaRemover, 1);
          });
          if (this.inputDados.enderecos.length > 0) {
            this.indexEnderecoSelecionado = 0;
          }else{
            this.inputDados.enderecos = [];
          }
        }
      }
    } else {
      if (this.inputDados.enderecos == null || this.inputDados.enderecos.length == 0) {
        this.instanceEndereco();
      }
    }
  }

  cliqueNaoInformarCliente() {
    this.fecharModal();
    this.inputDados = PessoaFisicaJuridicaModelInstance.get();
    this.inputDados.tipoPessoa = enumTipoPessoa.Fisica;
    this.selecionouCliente.emit(null!);
    this.instanceEndereco();
  }

  cliqueNovoCliente() {
    this.templateTela = enumTemplateTelaSelecionarCliente.cadastrar;
    this.inputDados = PessoaFisicaJuridicaModelInstance.get();
    this.instanceEndereco();

    this.inputDados.tipoPessoa = this.padraoCadPfOuPj;
    switch (this.inputDados.tipoPessoa) {
      case enumTipoPessoa.Fisica:
        this.isCollapsedPJ = true;
        this.isCollapsedPF = false;
        this.isCollapsedPE = true;
        break;
      case enumTipoPessoa.Juridica:
        this.isCollapsedPJ = false;
        this.isCollapsedPF = true;
        this.isCollapsedPE = true;
        break;
      case enumTipoPessoa.Estrangeiro:
        this.isCollapsedPJ = true;
        this.isCollapsedPF = true;
        this.isCollapsedPE = false;
        break;
    }
    //this.inputDados.tipoPessoa = enumTipoPessoa.Fisica;
    //this.isCollapsedPE = true;
    //this.isCollapsedPJ = true;
    //this.isCollapsedPF = false;
    this.onFocusCpfCnpj();
  }
  cliqueVoltar() {
    this.abrirModal(null!, this.permitirNaoInformarCliente, this.somenteConsulta);
  }

  subsGetAllEstados: Subscription = null!;
  private instanceEndereco() {
    this.inputDados.enderecos = EnderecoModelInstance.getArray();
    this.indexEnderecoSelecionado = 0;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].cep = "00000000";
    this.inputDados.enderecos[this.indexEnderecoSelecionado].tipoBairro = enumTipoBairro.Bairro;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].tipoLogradouro = enumTipoLogradouro.Rua;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoPrincipal = enumSimNao.Nao;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoEntrega = enumSimNao.Nao;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf = ConstantsService.SiglaUf;
    if (this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf != '') {
      this.getAllCidadesByEstado(this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf, '', this.inputDados.enderecos[this.indexEnderecoSelecionado].codigoMunicipioIbge);
    }
  }

  cadastrarNovoEndereco() {
    let indexEnderecoNaoCadastrado = this.inputDados.enderecos.findIndex(f => f.idEndereco == 0);
    if (indexEnderecoNaoCadastrado > -1) {
      swal.fire({
        title: "Atenção",
        text: 'Já existe um endereço em preenchimento, salve este endereço antes para informar outro!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {

        }
      });
      return;
    }

    for (let contador = 0; contador < this.inputDados.enderecos.length; contador++) {
      this.inputDados.enderecos[contador].enderecoPrincipal = enumSimNao.Nao;
    }

    this.inputDados.enderecos.push(EnderecoModelInstance.get());
    this.indexEnderecoSelecionado = this.inputDados.enderecos.length - 1;
    this.idEnderecoSelecionado = 0;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].cep = "00000000";
    this.inputDados.enderecos[this.indexEnderecoSelecionado].tipoBairro = enumTipoBairro.Bairro;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].tipoLogradouro = enumTipoLogradouro.Rua;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoPrincipal = enumSimNao.Sim;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoEntrega = enumSimNao.Nao;
    this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf = ConstantsService.SiglaUf;
    if (this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf != '') {
      this.getAllCidadesByEstado(this.inputDados.enderecos[this.indexEnderecoSelecionado].siglaUf, '', this.inputDados.enderecos[this.indexEnderecoSelecionado].codigoMunicipioIbge);
    }
  }

  getAllEstados(continuaProcessoAbrirModal: boolean) {
    this.subsGetAllEstados = this.tabelaEstadoService.getAllEstados()
      .subscribe(retorno => {
        this.subsGetAllEstados!.unsubscribe();
        if (retorno != null && retorno.length > 0) {
          this.estados = retorno as TabelaEstadoModel[];
          if (continuaProcessoAbrirModal) {
            this.abrirModalPasso2(this.pessoaFisicaJuridica, this.permitirNaoInformarCliente, this.somenteConsulta);
          }
        }
      },
        (err) => {
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
  }

  subsTransferPage: Subscription = null!;
  cliqueCadastroCompleto() {
    let url = '';
    if (this.inputDados == null || this.inputDados.cpfCnpj == '') {
      url = `${environment.urlRetaguarda}cadastro/comercial/cadastrar`;
    } else {
      url = `${environment.urlRetaguarda}cadastro/comercial/alterar/${this.inputDados.cpfCnpj}`;
    }

    this.subsTransferPage = this.apiTokenRedirectService.requestPage({
      aplicacao: enumAplicacao.BAK,
      url: url
    })
      .subscribe((retorno: any) => {
        this.subsTransferPage!.unsubscribe();
        if (retorno != null) {
          window.open(`${environment.urlRedirectRetaguarda}${retorno.idTransfereToken}`, '_blank');
          this.fecharModal();
        }
      },
        (err) => {
          this.subsTransferPage!.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });

  }

  cpfCnpj: string = '';
  subsCadastroComercial: Subscription = null!;
  cliqueCadastrar() {
    if (this.inputDados != null && (this.inputDados.indicadorInscricaoEstadual == '' || this.inputDados.indicadorInscricaoEstadual == null)) {
      this.inputDados.indicadorInscricaoEstadual = '9';
    }
    if (this.inputDados != null && this.inputDados.cpfCnpj != null && this.inputDados.cpfCnpj != '') {
      this.cpfCnpj = this.inputDados.cpfCnpj;
    }
    if (this.cpfCnpj == '') {
      this.inputDados.pessoaFisicaJuridicaTipoClientes = [
        {
          cpfCnpj: this.inputDados.cpfCnpj,
          idTabelaTipoCliente: enumTipoCliente.Cliente,
          cpfCnpjNavigation: null!,
          idTabelaTipoClienteNavigation: null!
        }
      ];

      if (this.inputDados.enderecos != null && this.inputDados.enderecos.length > 0) {
        this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoPrincipal = enumSimNao.Sim;
        if (this.inputDados.enderecos.length == 1) {
          this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoEntrega = enumSimNao.Sim;
        }
      }

      if (this.naoInformarEndereco) {
        this.inputDados.enderecos = [];
      }

      this.subsCadastroComercial = this.pessoaFisicaJuridicaService.post(this.inputDados, true).subscribe(
        (retorno: PessoaFisicaJuridicaModel) => {
          this.subsCadastroComercial?.unsubscribe();
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Cadastro comercial realizado com sucesso'], "Sucesso");
          this.showMessageError.limparMensagensAlerta();
          this.selecionouCliente.emit(retorno);
          this.fecharModal();
        },
        (err) => {
          this.subsCadastroComercial?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
    } else {
      this.inputDados.pessoaFisicaJuridicaTipoClientes = [
        {
          cpfCnpj: this.cpfCnpj,
          idTabelaTipoCliente: enumTipoCliente.Cliente,
          cpfCnpjNavigation: null!,
          idTabelaTipoClienteNavigation: null!
        }
      ];

      //ajuste realizado para efeito na entrega. Toda vez que a pessoa alterar o endereço através desta modal, o endereço que for selecionado
      //será definido como principal e de entrega.
      if (this.inputDados.enderecos != null && this.inputDados.enderecos.length > 0) {
        if (this.inputDados.enderecos.length > 1) {
          this.inputDados.enderecos.filter(f => f.idEndereco != this.idEnderecoSelecionado).forEach(f => {
            f.enderecoEntrega = enumSimNao.Nao,
              f.enderecoPrincipal = enumSimNao.Nao
          });
        }
        this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoEntrega = enumSimNao.Sim;
        this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoPrincipal = enumSimNao.Sim;
      }

      this.subsCadastroComercial = this.pessoaFisicaJuridicaService.put(this.inputDados, true).subscribe(
        (retorno: PessoaFisicaJuridicaModel) => {
          this.subsCadastroComercial?.unsubscribe();
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Cadastro comercial atualizado com sucesso'], "Sucesso");
          this.showMessageError.limparMensagensAlerta();
          this.selecionouCliente.emit(retorno);
          this.fecharModal();
        },
        (err) => {
          this.subsCadastroComercial?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
    }
  }

  textoBotaoVoltar: string = 'Voltar';

  indexEnderecoSelecionado: number = 0;
  idEnderecoSelecionado: number = 2;

  selecionarEndereco(item: EnderecoModel, rowIndex: number) {
    this.idEnderecoSelecionado = item.idEndereco;
    this.indexEnderecoSelecionado = rowIndex;
    // for(let contador = 0; contador < this.inputDados.enderecos.length; contador++){
    //   this.inputDados.enderecos[contador].enderecoPrincipal = enumSimNao.Nao;
    // }
    // this.inputDados.enderecos[this.indexEnderecoSelecionado].enderecoPrincipal = enumSimNao.Sim;
  }


  focusLista: boolean = false;
  pessoaSelecionada: PessoaFisicaJuridicaModel = null!;
  subPessoaFisicaJuridicaService: Subscription = null!;
  pesquisar() {
    if (this.loadingPesquisar) return;
    this.loadingPesquisar = true;
    this.subPessoaFisicaJuridicaService = this.pdvService.getAllPessoaFisicaJuridicaByCnpjRazaoSocialNomeFantasiaCpfNome(this.filtro, this.codigoCliente, false).subscribe(
      (retorno: Array<PessoaFisicaJuridicaModel>) => {
        if (retorno == null) retorno = [];
        this.listaClientes = retorno;
        //this.listaClientes.push(...this.listaClientes)
        if (retorno.length == 1) {
          this.focusLista = true;
        } else {
          this.onFocusPesquisar();
        }
        this.loadingPesquisar = false;
      },
      (err) => {
        this.loadingPesquisar = false;
        this.subPessoaFisicaJuridicaService?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        this.onFocusPesquisar();
      });
  }

  isCollapsedPJ: boolean = false;
  isCollapsedPF: boolean = false;
  isCollapsedPE: boolean = false;
  handleChangePJ(evt) {
    var target = evt.target;
    if (target.checked) {
      this.isCollapsedPJ = false;
      this.isCollapsedPF = true;
      this.isCollapsedPE = true;
      this.inputDados.tipoPessoa = enumTipoPessoa.Juridica;
    }
  }
  handleChangePF(evt) {
    var target = evt.target;
    if (target.checked) {
      this.isCollapsedPF = false;
      this.isCollapsedPJ = true;
      this.isCollapsedPE = true;
      this.inputDados.tipoPessoa = enumTipoPessoa.Fisica;
    }
  }
  handleChangePE(evt) {
    var target = evt.target;
    if (target.checked) {
      this.isCollapsedPE = false;
      this.isCollapsedPJ = true;
      this.isCollapsedPF = true;
      this.inputDados.tipoPessoa = enumTipoPessoa.Estrangeiro;
    }
  }

  onChangeCheckboxOrgPublico(event) {
    if (event.target.checked) {
      this.inputDados.tomadorOrgaoPublico = enumSimNao.Sim;
      return;
    }
    if (!event.target.checked) {
      this.inputDados.tomadorOrgaoPublico = enumSimNao.Nao;
    }
  }

  importarDadosBaseExterna(dadosEmpresa: CnpjModel) {
    this.inputDados.cpfCnpj = dadosEmpresa.cpf_cnpj;
    if (dadosEmpresa.data_abertura != '') {
      this.inputDados.dataAberturaEmpresa = new Date(dadosEmpresa.data_abertura);
    }
    this.inputDados.razaoSocialNome = dadosEmpresa.razao_social;
    this.inputDados.inscricaoEstadual = dadosEmpresa.inscricao_estadual;
    if (this.inputDados.email == '') this.inputDados.email = dadosEmpresa.email;
    this.inputDados.tipoPessoa = enumTipoPessoa.Juridica;
    if (dadosEmpresa.data_abertura != ''){
      this.inputDados.dataAberturaEmpresa = ApiUtilService.toDate(dadosEmpresa.data_abertura);
    }
    if (dadosEmpresa.endereco != null){
      if (this.inputDados.enderecos == null){
        this.inputDados.enderecos = [];
      }
      let index = 0;
      if (this.inputDados.enderecos.length == 0){
        this.inputDados.enderecos.push(EnderecoModelInstance.get());
      }
      //pegar o index de endereço não preenhido
      if (this.inputDados.enderecos[index].cep == '' || this.inputDados.enderecos[index].cep == '00000000' ){ 
          this.naoInformarEndereco = false;
          this.inputDados.enderecos[index].bairro = dadosEmpresa.endereco.bairro;
          this.inputDados.enderecos[index].cep = dadosEmpresa.endereco.cep;
          this.inputDados.enderecos[index].cidade = dadosEmpresa.endereco.municipio;
          this.inputDados.enderecos[index].codigoMunicipioIbge = '';
          this.inputDados.enderecos[index].complemento= '';
          this.inputDados.enderecos[index].cpfAlteracao= '';
          this.inputDados.enderecos[index].cpfCnpj= dadosEmpresa.cpf_cnpj;
          this.inputDados.enderecos[index].idEndereco= 0;
          this.inputDados.enderecos[index].numero= dadosEmpresa.endereco.numero;
          this.inputDados.enderecos[index].rua= dadosEmpresa.endereco.logradouro;
          this.inputDados.enderecos[index].siglaUf= dadosEmpresa.endereco.uf;
          this.inputDados.enderecos[index].enderecoEntrega= enumSimNao.Sim;
          this.inputDados.enderecos[index].enderecoPrincipal= enumSimNao.Sim;
          this.inputDados.enderecos[index].tipoBairro= 'Bairro';
          this.inputDados.enderecos[index].tipoLogradouro= 'Rua';
          this.inputDados.enderecos[index].cpfRegistro= '';
          this.inputDados.enderecos[index].cpfCnpjNavigation= null!;
          this.inputDados.enderecos[index].dataAlteracao= null;
          this.inputDados.enderecos[index].dataRegistro= null;
          this.inputDados.enderecos[index].localEntregaVenda= null!;
          this.inputDados.enderecos[index].vendaEntregas= null!;
          this.inputDados.enderecos[index].inscricaoEstadual = '';
        };
        this.getAllCidadesByEstado(this.inputDados.enderecos[index].siglaUf, dadosEmpresa.endereco.municipio, '');
      }
    
  }

  
  subsGetCodigoMunicipioIbgeByNomeCidade: Subscription = null!;
  getCodigoMunicipioIbgeByNomeCidade(cidade: string, estado: string) {
    this.subsGetCodigoMunicipioIbgeByNomeCidade = this.apiTabelaCidadeService.getCodigoMunicipioIbgeByNomeCidade(cidade, estado, false).subscribe(
      (retorno: TabelaCidadeModel[]) => {
        this.subsGetCodigoMunicipioIbgeByNomeCidade?.unsubscribe();
        if (retorno.length > 0){
          this.inputDados.enderecos[0].codigoMunicipioIbge = retorno[0].codigoMunicipioIbge;
        }
      },
      (err) => {
        this.subsGetCodigoMunicipioIbgeByNomeCidade?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  changeCpfCnpj(pessoaFisicaJuridica: PessoaFisicaJuridicaModel) {
    this.preencherAutomaticoCpfCnpj(pessoaFisicaJuridica);
  }

  preencherAutomaticoCpfCnpj(pessoaFisicaJuridica: PessoaFisicaJuridicaModel) {
    if (pessoaFisicaJuridica != null && pessoaFisicaJuridica.razaoSocialNome != '') {
      if (pessoaFisicaJuridica.dataNascimento != null) pessoaFisicaJuridica.dataNascimento = new Date(pessoaFisicaJuridica.dataNascimento);
      if (pessoaFisicaJuridica.dataAberturaEmpresa != null) pessoaFisicaJuridica.dataNascimento = new Date(pessoaFisicaJuridica.dataAberturaEmpresa);
      this.inputDados = pessoaFisicaJuridica;
      //preencher a cidade
      if (this.inputDados.enderecos != null && this.inputDados.enderecos.length > 0) {
        let enderecoPrincipal = this.inputDados.enderecos.find(f => f.enderecoPrincipal == enumSimNao.Sim);
        if (enderecoPrincipal == null) {
          enderecoPrincipal = this.inputDados.enderecos.find(f => f.enderecoEntrega == enumSimNao.Sim);
        }
        if (enderecoPrincipal != null) {
          this.getAllCidadesByEstado(enderecoPrincipal.siglaUf, "", enderecoPrincipal.codigoMunicipioIbge);
        }

      }
      this.cpfCnpj = pessoaFisicaJuridica.cpfCnpj;
    }
  }

  //inicio tratamento para endereço
  cidades: TabelaCidadeModel[] = [];
  estados: TabelaEstadoModel[] = [];
  enderecoCep: CepModel | null = null;
  itensTipoLogradouro: Array<IOption> = [
    { value: 'Rua', label: 'Rua' },
    { value: 'Alameda', label: 'Alameda' },
    { value: 'Avenida', label: 'Avenida' },
    { value: 'Chácara', label: 'Chácara' },
    { value: 'Colônia', label: 'Colônia' },
    { value: 'Condomínio', label: 'Condomínio' },
    { value: 'Estância', label: 'Estância' },
    { value: 'Estrada', label: 'Estrada' },
    { value: 'Fazenda', label: 'Fazenda' },
    { value: 'Praça', label: 'Praça' },
    { value: 'Prolongamento', label: 'Prolongamento' },
    { value: 'Rodovia', label: 'Rodovia' },
    { value: 'Sítio"', label: 'Sítio"' },
    { value: 'Travessa', label: 'Travessa' },
    { value: 'Vicinal', label: 'Vicinal' },
    { value: 'Eqnp', label: 'Eqnp' }
  ];

  itensTipoBairro: Array<IOption> = [
    { value: 'Bairro', label: 'Bairro' },
    { value: 'Bosque', label: 'Bosque' },
    { value: 'Chácara', label: 'Chácara' },
    { value: 'Conjunto', label: 'Conjunto' },
    { value: 'Desmembramento', label: 'Desmembramento' },
    { value: 'Distrito', label: 'Distrito' },
    { value: 'Favela', label: 'Favela' },
    { value: 'Fazenda', label: 'Fazenda' },
    { value: 'Gleba"', label: 'Gleba"' },
    { value: 'Horto', label: 'Horto' },
    { value: 'Jardim', label: 'Jardim' },
    { value: 'Loteamento', label: 'Loteamento' },
    { value: 'Núcleo', label: 'Núcleo' },
    { value: 'Parque', label: 'Parque' },
    { value: 'Residencial', label: 'Residencial' },
    { value: 'Sítio', label: 'Sítio' },
    { value: 'Tropical', label: 'Tropical' },
    { value: 'Vila', label: 'Vila' },
    { value: 'Zona"', label: 'Zona"' },
    { value: 'Centro', label: 'Centro' },
    { value: 'Setor', label: 'Setor' }
  ];

  inputDadosCidade: TabelaCidadeModel | null = null;
  onChangeCidade($event) {
    this.nomeCidade = $event.target.options[$event.target.options.selectedIndex].text;
    this.inputDadosCidade = this.cidades.find(c => c.codigoMunicipioIbge == $event.target.options[$event.target.options.selectedIndex].value)!;
  }
  nomeCidade: string = '';
  siglaEstado: string = '';
  nomeEstado: string = '';

  onChangeEstado(event) {
    let estadoBD = this.estados.find(e => e.siglaUf == event.target.value);
    this.getAllCidadesByEstado(estadoBD!.siglaUf as string, "", "");
    this.siglaEstado = estadoBD!.siglaUf;
    this.nomeEstado = estadoBD!.nome;
    this.inputDados.enderecos[this.indexEnderecoSelecionado]!.siglaUf = estadoBD!.siglaUf;
  }

  subsGetAllCidadesByEstado: Subscription = null!;
  getAllCidadesByEstado(siglaUf: string, cidade: string, codigoMunicipioIbge: string) {
    if (siglaUf == null) return;
    if (cidade == null) cidade = '';
    cidade = ApiUtilService.replaceSpecialChars(cidade);
    cidade = cidade.toUpperCase();
    if (this.cidades != undefined && this.cidades.length != 0 && this.cidades[this.indexEnderecoSelecionado].siglaUf == siglaUf
      && codigoMunicipioIbge != ''
    ) {
      this.inputDados.enderecos[this.indexEnderecoSelecionado]!.codigoMunicipioIbge = codigoMunicipioIbge;
    }
    else {
      this.subsGetAllCidadesByEstado = this.apiTabelaCidadeService.getAllCidadesByEstado(siglaUf)
        .subscribe(retorno => {
          this.subsGetAllCidadesByEstado!.unsubscribe();
          if (retorno != null && retorno.length > 0) {
            let cidades = retorno as TabelaCidadeModel[];
            if (codigoMunicipioIbge != '') {
              if (cidades.find(o => o.codigoMunicipioIbge == codigoMunicipioIbge) != undefined) {
                this.inputDados.enderecos[this.indexEnderecoSelecionado]!.codigoMunicipioIbge = cidades.find(o => o.codigoMunicipioIbge == codigoMunicipioIbge)!.codigoMunicipioIbge;
              }

            } else if (cidade != '') {
              this.getCodigoMunicipioIbgeByNomeCidade(cidade, siglaUf);
              // if (cidades.find(o => o.nome == cidade) != undefined) {
              //   this.inputDados.enderecos[this.indexEnderecoSelecionado]!.codigoMunicipioIbge = cidades.find(o => o.nome == cidade)!.codigoMunicipioIbge;
              // }
            }
            this.cidades = cidades;
          }
        });
    }
  }

  subsGetEnderecoCep: Subscription = null!;
  onBlurConsultaCep(event) {
    if (event == null) return;
    if (event.target == null) return;
    if (event.target.value == null) return;
    if (event.target.value != "" && event.target.value.replace("-", "").replace("_", "").length == 8) {
      this.subsGetEnderecoCep = this.apiAuxiliarConsultaService.getEnderecoByCep(event.target.value.replace("-", ""), true)
        .subscribe((retorno: CepModel) => {

          if (retorno == null) {
            swal.fire({
              title: "Alerta",
              html: `Cep <b>${event.target.value}</b> não encontrado`,
              showCancelButton: false,
              icon: 'warning'
            })
              .then((res) => {

              });
          } else {
            this.inputDados.enderecos[this.indexEnderecoSelecionado]!.rua = retorno.logradouro;
            this.inputDados.enderecos[this.indexEnderecoSelecionado]!.bairro = retorno.bairro;
            this.inputDados.enderecos[this.indexEnderecoSelecionado]!.complemento = retorno.complemento;
            let estadoBD = this.estados.find(e => e.siglaUf == retorno.uf);
            if (estadoBD != null) {
              this.inputDados.enderecos[this.indexEnderecoSelecionado]!.siglaUf = estadoBD.siglaUf;
              this.nomeCidade = estadoBD.nome;
              this.siglaEstado = estadoBD.siglaUf;
            }
            if (this.inputDados.enderecos[this.indexEnderecoSelecionado]!.cidade != retorno.municipio) {
              this.inputDados.enderecos[this.indexEnderecoSelecionado]!.cidade = retorno.municipio;
              this.nomeCidade = retorno.municipio;
              this.getAllCidadesByEstado(retorno.uf, retorno.municipio, "")
            }
          }

        });
    }
  }
}
