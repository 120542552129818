import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalImpressaoComponent } from './modal-impressao.component';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NgbCollapseModule, NgbDropdownModule, NgbPopoverModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { SplitButtonModule } from 'primeng/splitbutton'; // Importando o módulo do SplitButton
import { ModalCrudParametrizacaoEnvioImpressoraModule } from 'src/app/pages/parametrizacao-envio-impressora/modal-crud-parametrizacao-envio-impressora/modal-crud-parametrizacao-envio-impressora.module';


@NgModule({
  declarations: [
    ModalImpressaoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbDropdownModule,
    SplitButtonModule,
    ModalCrudParametrizacaoEnvioImpressoraModule
  ],
  exports:[
    ModalImpressaoComponent, 
    
    CommonModule, 
    SharedModule,  
    NgbPopoverModule, 
    NgbTooltipModule,
  ]
})
export class ModalImpressaoModule { }