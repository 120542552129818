import { CustomFinalizarPedidoVendumModel } from "./CustomFinalizarPedidoVendumModel";

export class CustomFinalizarPedidoVendumModelInstance {
    public static get(): CustomFinalizarPedidoVendumModel {
        return {
            pedidoVendumModel: null!,
            permiteEstoqueNegativo: '',
            permiteEstoqueReservado: '',
            permiteEstoqueCondicional: '',
            permiteEstoqueCrossDocking: '',
            permiteEstoqueVirtual: '',
            movEstContabilPedidoVenda: '',
            movEstVirtualPedidoVenda: '',
        };
    }
    public static getArray(): CustomFinalizarPedidoVendumModel[] {
        return [this.get()];
    }
}