import swal from 'sweetalert2';
import { AfterViewInit, Component, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { OrcamentoModel } from 'src/app/models/cliente/Orcamento/orcamentoModel';
import { OrcamentoModelInstance } from 'src/app/models/cliente/Orcamento/OrcamentoModelInstance';
import { OrcamentoProdutoModel } from 'src/app/models/cliente/OrcamentoProduto/orcamentoProdutoModel';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { ProdutoVariacaoModel } from 'src/app/models/cliente/ProdutoVariacao/produtoVariacaoModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiOrcamentoService } from 'src/app/services/pdv/api-orcamento-service';
import { ModalSelecionarClienteComponent } from 'src/app/shared/business-component/modal-selecionar-cliente/modal-selecionar-cliente.component';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ModalAlterarItemOrcamentoComponent } from './modal-alterar-item-orcamento/modal-alterar-item-orcamento.component';
import { ModalInformacoesAdicionaisComponent } from './modal-informacoes-adicionais/modal-informacoes-adicionais.component';
import { ModalOrcamentoEstoqueComponent } from './modal-orcamento-estoque/modal-orcamento-estoque.component';
import { ProdutoPesquisaModel } from 'src/app/shared/business-component/pesquisa-por-produto/ProdutoPesquisaModel';
import { ModalSituacaoClienteComponent } from 'src/app/shared/business-component/modal-situacao-cliente/modal-situacao-cliente.component';
import { ModalListaSimulacoesComponent } from '../modal-lista-simulacoes/modal-lista-simulacoes.component';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { ProdutoModelInstance } from 'src/app/models/cliente/Produto/ProdutoModelInstance';
import { ProdutoVariacaoModelInstance } from 'src/app/models/cliente/ProdutoVariacao/ProdutoVariacaoModelInstance';
import { ApiEstoqueService } from 'src/app/services/cliente/api-estoque.service';
import { EstoqueModel } from 'src/app/models/cliente/estoque/estoqueModel';
import { EstoqueModelInstance } from 'src/app/models/cliente/estoque/EstoqueModelInstance';
import { PrecoModelInstance } from 'src/app/models/cliente/preco/PrecoModelInstance';
import { PdvComponent } from 'src/app/theme/layout/pdv/pdv.component';
import { enumStatus } from 'src/app/enum/enumStatus';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { ItemOrcamentoProdutoPdvModel, ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { PesquisaPorProdutoComponent, ProdutoSelecionado } from 'src/app/shared/business-component/pesquisa-por-produto/pesquisa-por-produto.component';
import { enumTabelaStatusOrcamento } from 'src/app/enum/enumTabelaStatusOrcamento';
import { ModalAbrirFecharCaixaComponent } from 'src/app/shared/business-component/modal-abrir-fechar-caixa/modal-abrir-fechar-caixa.component';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { OrcamentoProdutoModelInstance } from 'src/app/models/cliente/OrcamentoProduto/OrcamentoProdutoModelInstance';
import { AppSettings } from 'src/app/app-settings';
import { environment } from 'src/environments/environment';
import { enumListaAnexoTipoArquivo } from 'src/app/enum/enumListaAnexoTipoArquivo';
import { enumModuloAnexo } from 'src/app/enum/enumModuloAnexo';
import { enumModoTela } from 'src/app/enum/enumModoTela';
import { ListaAnexoComponent } from 'src/app/shared/business-component/lista-anexo/lista-anexo.component';
import { ModalExibirImagensComponent } from 'src/app/shared/business-component/modal-exibir-imagens/modal-exibir-imagens.component';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';
import { ApiTabelaPrecoService } from 'src/app/services/cliente/api-tabela-preco.service';
import { ModalImpressaoComponent } from 'src/app/shared/business-component/modal-impressao/modal-impressao.component';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { CustomFormatoImpressao } from 'src/app/models/relatorios/CustomFormatoImpressao';
import { ApiGeraRelatorioOrcamentoService } from 'src/app/services/relatorio/api-gera-relatorio-orcamento.service';
import { enumFormatoImpressao } from 'src/app/enum/enumFormatoImpressao';

export enum enumAcaoFuncionalidadeOrcamento {
  Nenhuma,
  NovoOrcamento,
  AtualizarTabelaPreco
}

@Component({
  selector: 'app-orcamento-cadastro',
  templateUrl: './orcamento-cadastro.component.html',
  styleUrls: ['./orcamento-cadastro.component.css']
})
export class OrcamentoCadastroComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(PesquisaPorProdutoComponent, { static: false }) pesquisaPorProdutoServico!: PesquisaPorProdutoComponent;
  @ViewChild(ModalListaSimulacoesComponent, { static: false }) modalListaSimulacoesComponent!: ModalListaSimulacoesComponent;
  @ViewChild(ModalSelecionarClienteComponent, { static: false }) modalSelecionarClienteComponent!: ModalSelecionarClienteComponent;
  @ViewChild(ModalInformacoesAdicionaisComponent, { static: false }) modalInformacoesAdicionaisComponent!: ModalInformacoesAdicionaisComponent;
  @ViewChild(ModalSituacaoClienteComponent, { static: false }) modalSituacaoClienteComponent!: ModalSituacaoClienteComponent;
  @ViewChild(ModalAlterarItemOrcamentoComponent, { static: false }) modalAlterarItemOrcamentoComponent!: ModalAlterarItemOrcamentoComponent;
  @ViewChild(ModalOrcamentoEstoqueComponent, { static: false }) modalOrcamentoEstoqueComponent!: ModalOrcamentoEstoqueComponent;
  @ViewChild(ModalAbrirFecharCaixaComponent, { static: false }) modalAbrirFecharCaixaComponent!: ModalAbrirFecharCaixaComponent;
  @ViewChild(ListaAnexoComponent, { static: false }) modalListaAnexoComponent!: ListaAnexoComponent;
  @ViewChild(ModalExibirImagensComponent, { static: false }) modalExibirImagensComponent!: ModalExibirImagensComponent;
  @ViewChild(ModalImpressaoComponent) modalImpressaoComponent!: ModalImpressaoComponent;

  @Output() atualizarTabelaPreco: EventEmitter<TabelaPrecoModel> = new EventEmitter();
  @Output() atualizarTextoCabecalhoSituacaoCaixa: EventEmitter<CaixaControleModel> = new EventEmitter();

  server: string = environment.apiSettings.baseUrl[AppSettings.API.File];

  inputDados: OrcamentoModel = null!;
  orcamentoProduto: OrcamentoProdutoModel = null!;

  unMedMetro: enumUnidadeComercial = enumUnidadeComercial.Metro;
  unMedMetroCubico: enumUnidadeComercial = enumUnidadeComercial.MetroCcbico;
  unMedMetroQuadrado: enumUnidadeComercial = enumUnidadeComercial.MetroQuadrado;
  unTipoMedidaAltura: number | null = null;
  unTipoMedidaComprimento: number | null = null;
  unTipoMedidaLargura: number | null = null;
  unQuantItem: number | null = null;

  nomeArquivo: string = '';
  subsGerarExtratoOrcamento: Subscription = null!;
  Cupom80mm: enumFormatoImpressao = enumFormatoImpressao.Cupom80mm;
  A4: enumFormatoImpressao = enumFormatoImpressao.A4;

  constructor(
    private router: Router,
    private appCustomToastService: AppCustomToastService,
    private showMessageError: ShowMessageError,
    private apiTabelaPrecoService: ApiTabelaPrecoService,
    private apiPdvService: ApiPdvService,
    private readonly apiOrcamentoService: ApiOrcamentoService,
    private readonly apiEstoqueService: ApiEstoqueService,
    private readonly apiVendaService: ApiVendaService,
    private readonly apiGeraRelatorioOrcamentoService: ApiGeraRelatorioOrcamentoService,
    private route: ActivatedRoute,
    private readonly mensagemService: AppMensagemService,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
  ) {
    this.translateService.use("pt");
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  visualizarImagem(item: OrcamentoProdutoModel, index: number){
    this.modalExibirImagensComponent.abrirModal(item.idProdutoUnico);
  }

  ngAfterViewInit(): void {
    this.screenHeight = window.innerHeight;

    if (ConstantsService.IdPdv == 0) {
      //neste caso não selecionou pdv, pois veio do retaguarda, sendo assim é obrigatório
      //selecionar
      PdvComponent.modalAtiva = true;
      this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
      return;
    }

    if (ConstantsService.SituacaoCaixa != enumSituacaoCaixa.Aberto) {
      PdvComponent.modalAtiva = true;
      this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Abrir, false);
    } else {

      if (ConstantsService.ParametrizacaoPdv != null && ConstantsService.ParametrizacaoPdv.obrigaFecharCaixaDiario
        && (ConstantsService.DataAberturaCaixa != null &&
          ApiUtilService.getDateString(ConstantsService.DataAberturaCaixa) < ApiUtilService.getDateString(new Date())
        )
      ) {
        PdvComponent.modalAtiva = true;
        this.modalAbrirFecharCaixaComponent.abrirModal(enumAcaoAbrirFecharCaixa.Fechar, true);
      } else {
        //verifica se existe venda em preenchimento para este pdv, se existir, abrir a venda
        //se existir mais de um apresentar lista para selecionar, caso tenha apenas uma, exibir esta.
        //somente se abrir que irá dar focus no input de produto
        if (this.idOrcamento != 0) {
          this.pesquisarOrcamento(this.idOrcamento);
        } else {
          PdvComponent.modalAtiva = true;
          this.modalSelecionarClienteComponent.abrirModal(null!, false, false);
        }
      }
    }
    this.startTimer();

  }

  private startTimer() {
    this.interval = setInterval(() => {

      if (this.inputDados == null) return;
      if (this.inputDados.idOrcamento == 0) return;

      if (this.inputDados.orcamentoProdutos.length != this.quantidadeProdutoSalvo) {
        //salva o produto
        this.salvarOrcamentoTemporario();
      }
    }, 20000)
  }

  //idOrcamentoAtiva
  private static constIdOrcamentoAtiva: string = "a6505d65";
  public static set IdOrcamentoAtiva(valor: number) {
    localStorage.setItem(this.constIdOrcamentoAtiva, JSON.stringify(valor));
  }
  public static get IdOrcamentoAtiva(): number {
    if (localStorage.getItem(this.constIdOrcamentoAtiva)) {
      return JSON.parse(localStorage.getItem(this.constIdOrcamentoAtiva)!?.toString()) as number;
    }
    return null!;
  }


  idOrcamento: number = 0;
  getParameters() {
    if (this.route.snapshot.params.idOrcamento !== undefined) {
      this.idOrcamento = parseInt(this.route.snapshot.params.idOrcamento);
    } else {
      if (OrcamentoCadastroComponent.IdOrcamentoAtiva != null && OrcamentoCadastroComponent.IdOrcamentoAtiva != 0) {
        this.idOrcamento = OrcamentoCadastroComponent.IdOrcamentoAtiva;
      }
    }
  }
  
  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
  }
  get heightLista(): string {
    let size: number = this.screenHeight - 210;
    return `${size}px`
  }

  loading: boolean = false;

  ngOnInit(): void {
    this.quantidadeProdutoSalvo = this.ListaProdutosPdv.length;
    this.getParameters();
    this.consultarTabelaPrecos();
  }

  /*gestão preço a aprazo e a vista*/
  tabelaPrecoSelecionada: TabelaPrecoModel = ConstantsService.TabelaPreco;
  alterarTabelaPrecoNaVenda(item: TabelaPrecoModel) {
    this.tabelaPrecoSelecionada = item;
    if (ConstantsService.TabelaPreco == null || item.idTabelaPreco == ConstantsService.TabelaPreco.idTabelaPreco) return;

    //let listaProdutos = this.ListaProdutosPdv;
    let listaProdutos = this.inputDados.orcamentoProdutos;
    let indexItemNaoSalvo = listaProdutos.findIndex(f => f.idOrcamentoProduto == 0);

    if (indexItemNaoSalvo > -1) //existe item não salvo, neste caso salva antes de alterar a tabela de preço
    {
      this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.AtualizarTabelaPreco, null!);
    }
    else {
      this.alterarTabelaPrecoNaVendaApi(this.tabelaPrecoSelecionada);
    }
  }

  clickSalvarOrcamento() {
    this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
  }

  produtos: Array<ProdutoSelecionado> = [];
  produtoSelecionado: ProdutoSelecionado = null!;
  limparSelecaoProduto(limparLabelSuperior: boolean) {
    this.produtoSelecionado = null!;
    this.produtos = [];
  }

  ngOnDestroy(): void {
    this.pauseTimer();
    this.LimparListaProdutosPdv();
  }

  /*ajustes manter itens salvo na tela*/
  LimparListaProdutosPdv() {
    localStorage.removeItem(`${this.constListaProdutosPdv}_${this.inputDados.idOrcamento}`);
  }

  constListaProdutosPdv: string = "qe06d0465d5";
  set ListaProdutosPdv(valor: ItemOrcamentoProdutoPdvModel[]) {
    localStorage.setItem(`${this.constListaProdutosPdv}_${this.inputDados.idOrcamento}`, JSON.stringify(valor));
    this._listaProdutosPdv = valor;
  }
  _listaProdutosPdv: ItemOrcamentoProdutoPdvModel[] = [];
  get ListaProdutosPdv(): ItemOrcamentoProdutoPdvModel[] {
    if (this.inputDados == null) {
      this._listaProdutosPdv = [];
      return [];
    }
    if (this.inputDados.idOrcamento == 0) {
      this._listaProdutosPdv = [];
      return [];
    }
    if (this._listaProdutosPdv != null && this._listaProdutosPdv.length > 0) {
      return this._listaProdutosPdv;
    } else {
      let temp = localStorage.getItem(`${this.constListaProdutosPdv}_${this.inputDados.idOrcamento}`);
      if (temp != '' && temp != null) {
        this._listaProdutosPdv = JSON.parse(temp) as ItemOrcamentoProdutoPdvModel[];
        return this._listaProdutosPdv;
      }
    }
    this._listaProdutosPdv = [];
    return [];
  }

  atualizarListaProdutoPdvParaOrcamentoProduto() {
    this.inputDados.orcamentoProdutos = [];
    let listaProdutos = this.ListaProdutosPdv;
    listaProdutos.forEach(f => {
      let orcamentoProduto = OrcamentoProdutoModelInstance.get();
      orcamentoProduto.idOrcamento = this.inputDados.idOrcamento;
      orcamentoProduto.idOrcamentoProduto = f.idOrcamentoProduto;
      orcamentoProduto.idProdutoUnico = f.idProdutoUnico;
      orcamentoProduto.idProdutoVariacao = f.idProdutoVariacao;
      orcamentoProduto.detalhe = f.nome;
      orcamentoProduto.precoUnitario = f.precoVenda!;
      orcamentoProduto.numeroItem = f.item;
      orcamentoProduto.quantidade = f.quantidade;
      orcamentoProduto.valorSubtotal = f.subTotal;
      orcamentoProduto.dataPrevistaEntregaAgendada = f.dataPrevistaEntregaAgendada;
      orcamentoProduto.dataPrevistaParaEntregaCrossdoking = f.dataPrevistaParaEntregaCrossdocking;
      orcamentoProduto.indicaValorItemValorTot = f.indicaValorItemValorTot;
      orcamentoProduto.numeroPedidoCompra = f.numeroPedidoCompra;
      orcamentoProduto.quantidadeEntregueNoAto = f.quantidadeEntregueNoAto;
      orcamentoProduto.quantidadeParaEntregaAgendada = f.quantidadeParaEntregaAgendada;
      orcamentoProduto.quantidadeParaEntregaCrossdoking = f.quantidadeParaEntregaCrossdoking!;
      orcamentoProduto.valorCustoUnitario = f.precoMargemZero!;
      orcamentoProduto.valorCustoTotal = f.quantidade * f.precoMargemZero!;
      orcamentoProduto.valorTotal = f.valorTotal;
      orcamentoProduto.valorDesconto = f.valorDesconto;  
      orcamentoProduto.valorTotalPromocaoConcedido = f.valorTotalPromocaoConcedido!,
      orcamentoProduto.valorOutrasDespesasAcessorias = f.valorOutrasDespesas;
      orcamentoProduto.valorTotalFrete = f.valorTotalFrete;
      orcamentoProduto.valorSeguro = f.valorSeguro!;
      orcamentoProduto.valorTotalSeguro = f.valorTotalSeguro!;      
      orcamentoProduto.detalheImpressao = f.observacao;
      orcamentoProduto.precoUnitarioBase = f.precoUnitarioBase;
      orcamentoProduto.alterouPrecoUnitario = f.alterouPrecoUnitario;
      orcamentoProduto.unidadeTipoMedidaComprimento = f.unidadeTipoMedidaComprimento;
      orcamentoProduto.unidadeTipoMedidaLargura = f.unidadeTipoMedidaLargura;
      orcamentoProduto.unidadeTipoMedidaAltura = f.unidadeTipoMedidaAltura;
      orcamentoProduto.unidadeQuantidadeItem = f.unidadeQuantidadeItem;

      orcamentoProduto.idProduto = ProdutoVariacaoModelInstance.get();
      orcamentoProduto.idProduto.idProdutoUnico = f.idProdutoUnico;
      orcamentoProduto.idProduto.idProdutoVariacao = f.idProdutoVariacao;
      orcamentoProduto.idProduto.estoques = [];
      orcamentoProduto.idProduto.estoques.push(EstoqueModelInstance.get());
      orcamentoProduto.idProduto.estoques[0].quantidadeContabil = f.quantidadeEstoqueContabil;
      orcamentoProduto.idProduto.estoques[0].quantidadeVirtual = f.quantidadeEstoqueVirtual;
      orcamentoProduto.idProduto.estoques[0].quantidadeReservado = f.quantidadeEstoqueReservado;
      orcamentoProduto.idProduto.estoques[0].quantidadeCondicional = f.quantidadeEstoqueCondicional;
      orcamentoProduto.idProduto.idProdutoUnicoNavigation = ProdutoModelInstance.get();
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.percMaxDesconto = f.percMaxDesconto!;
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.precos = [];
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.precos.push(PrecoModelInstance.get());
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.precos[0].precoVenda = f.precoVenda;
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.precos[0].precoMargemZero = f.precoMargemZero;
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.nome = f.nome;
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.gtin = f.gtin;
      orcamentoProduto.idProduto.idProdutoUnicoNavigation.sku = f.sku;
      //orcamentoProduto.idProduto.idProdutoUnicoNavigation.tipoEanBalanca = f.tipoEanBalanca;

      orcamentoProduto.idProdutoUnicoNavigation = ProdutoModelInstance.get();
      orcamentoProduto.idProdutoUnicoNavigation.tipoProduto = f.tipo;
      orcamentoProduto.idProdutoUnicoNavigation.percMaxDesconto = f.percMaxDesconto!;
      orcamentoProduto.idProdutoUnicoNavigation.nome = f.nome;
      orcamentoProduto.idProdutoUnicoNavigation.gtin = f.gtin;
      orcamentoProduto.idProdutoUnicoNavigation.sku = f.sku;
      orcamentoProduto.idProdutoUnicoNavigation.unidadeMedidaComercial = f.unidadeMedida;
      orcamentoProduto.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida = f.habilitaCalculoUnidadeMedida;
      //orcamentoProduto.idProdutoUnicoNavigation.tipoEanBalanca = f.tipoEanBalanca;

      this.inputDados.orcamentoProdutos.push(orcamentoProduto);
    });
  }

  atualizarItemOrcamentoProdutoParaListaProdutoPdv(item: OrcamentoProdutoModel) {

    let produtoPdv: ItemOrcamentoProdutoPdvModel = {
      idOrcamento: item.idOrcamento ?? 0,
      idOrcamentoProduto: item.idOrcamentoProduto,
      idProdutoUnico: item.idProdutoUnico,
      idProdutoVariacao: item.idProdutoVariacao!,
      nome: item.detalhe,
      precoVenda: item.precoUnitario,
      item: item.numeroItem!,
      tipoEanBalanca: item.idProdutoUnicoNavigation.tipoEanBalanca,
      unidadeMedida: item.idProdutoUnicoNavigation.unidadeMedidaComercial,
      quantidade: item.quantidade!,
      subTotal: item.valorSubtotal!,
      valorTotal: item.valorTotal!,
      gtin: item.idProdutoUnicoNavigation.gtin,
      sku: item.idProdutoUnicoNavigation.sku,
      percMaxDesconto: null,
      dataPrevistaEntregaAgendada: item.dataPrevistaEntregaAgendada,
      dataPrevistaParaEntregaCrossdocking: item.dataPrevistaParaEntregaCrossdoking,
      indicaValorItemValorTot: item.indicaValorItemValorTot,
      numeroPedidoCompra: item.numeroPedidoCompra,
      quantidadeEntregueNoAto: item.quantidadeEntregueNoAto,
      quantidadeParaEntregaAgendada: item.quantidadeParaEntregaAgendada,
      quantidadeParaEntregaCrossdoking: item.quantidadeParaEntregaCrossdoking!,
      valorDesconto: item.valorDesconto,
      valorTotalPromocaoConcedido: item.valorTotalPromocaoConcedido!,
      valorOutrasDespesas: item.valorOutrasDespesasAcessorias,
      valorTotalFrete: item.valorTotalFrete,
      precoMargemZero: item.valorCustoUnitario,
      custoUnitario: item.valorCustoUnitario,
      custoTotal: item.quantidade! * item.valorCustoUnitario!,
      valorSeguro: item.valorSeguro!,
      valorTotalSeguro: item.valorTotalSeguro!,
      observacao: item.detalheImpressao,
      alterouPrecoUnitario: item.alterouPrecoUnitario,
      precoUnitarioBase: item.precoUnitarioBase,
      tipo: item.idProdutoUnicoNavigation.tipoProduto,
      quantidadeEstoqueContabil: 0,
      quantidadeEstoqueVirtual: 0,
      quantidadeEstoqueReservado: 0,
      quantidadeEstoqueCondicional: 0,
      detalhe: item.detalhe,
      detalheImpressao: item.detalheImpressao,
      thumbnailImageSrc: '',
      habilitaCalculoUnidadeMedida: item.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida,
      unidadeTipoMedidaAltura: item.unidadeTipoMedidaAltura,
      unidadeTipoMedidaComprimento: item.unidadeTipoMedidaComprimento,
      unidadeTipoMedidaLargura: item.unidadeTipoMedidaLargura,
      unidadeQuantidadeItem: item.unidadeQuantidadeItem,
    };

    if (item.idProduto?.estoques != null && item.idProduto?.estoques.length > 0) {
      produtoPdv.quantidadeEstoqueContabil = item.idProduto?.estoques[0].quantidadeContabil ?? 0;
      produtoPdv.quantidadeEstoqueVirtual = item.idProduto?.estoques[0].quantidadeVirtual ?? 0;
      produtoPdv.quantidadeEstoqueReservado = item.idProduto?.estoques[0].quantidadeReservado ?? 0;
      produtoPdv.quantidadeEstoqueCondicional = item.idProduto?.estoques[0].quantidadeCondicional ?? 0;
    }
    let index = this.ListaProdutosPdv.findIndex(f => f.item == item.numeroItem);
    if (index != -1) {
      let tempListaProduto = this.ListaProdutosPdv;
      tempListaProduto[index] = produtoPdv;
      this.ListaProdutosPdv = tempListaProduto;
    }
  }

  atualizarVendaProdutoParaListaProdutoPdv() {
    let listaProdutosPdv: ItemOrcamentoProdutoPdvModel[] = [];
    this.inputDados.orcamentoProdutos.sort((a, b) => b.numeroItem! - a.numeroItem!).forEach(f => {
      let produtoPdv: ItemOrcamentoProdutoPdvModel = {
        idOrcamento: f.idOrcamento ?? 0,
        idOrcamentoProduto: f.idOrcamentoProduto,
        idProdutoUnico: f.idProdutoUnico,
        idProdutoVariacao: f.idProdutoVariacao!,
        nome: f.detalhe,
        precoVenda: f.precoUnitario,
        item: f.numeroItem!,
        tipoEanBalanca: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.tipoEanBalanca : f.idProduto?.idProdutoUnicoNavigation.tipoEanBalanca,
        unidadeMedida: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.unidadeMedidaComercial : f.idProduto?.idProdutoUnicoNavigation.unidadeMedidaComercial,
        quantidade: f.quantidade!,
        subTotal: f.valorSubtotal!,
        valorTotal: f.valorTotal!,
        gtin: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.gtin : f.idProduto?.idProdutoUnicoNavigation.gtin,
        sku: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.sku : f.idProduto?.idProdutoUnicoNavigation.sku,
        percMaxDesconto: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.percMaxDesconto! : f.idProduto?.idProdutoUnicoNavigation.percMaxDesconto!,
        dataPrevistaEntregaAgendada: f.dataPrevistaEntregaAgendada,
        dataPrevistaParaEntregaCrossdocking: f.dataPrevistaParaEntregaCrossdoking,
        indicaValorItemValorTot: f.indicaValorItemValorTot,
        numeroPedidoCompra: f.numeroPedidoCompra,
        quantidadeEntregueNoAto: f.quantidadeEntregueNoAto,
        quantidadeParaEntregaAgendada: f.quantidadeParaEntregaAgendada,
        quantidadeParaEntregaCrossdoking: f.quantidadeParaEntregaCrossdoking!,
        valorDesconto: f.valorDesconto,
        valorTotalPromocaoConcedido: f.valorTotalPromocaoConcedido!,
        valorOutrasDespesas: f.valorOutrasDespesasAcessorias,
        valorTotalFrete: f.valorTotalFrete,
        precoMargemZero: f.valorCustoUnitario,
        custoUnitario: f.valorCustoUnitario,
        custoTotal: f.quantidade! * f.valorCustoUnitario!,
        valorSeguro: f.valorSeguro!,
        valorTotalSeguro: f.valorTotalSeguro!,
        observacao: f.detalheImpressao,
        alterouPrecoUnitario: f.alterouPrecoUnitario,
        precoUnitarioBase: f.precoUnitarioBase,
        tipo: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.tipoProduto : f.idProduto?.idProdutoUnicoNavigation.tipoProduto,
        quantidadeEstoqueContabil: 0,
        quantidadeEstoqueVirtual: 0,
        quantidadeEstoqueReservado: 0,
        quantidadeEstoqueCondicional: 0,
        detalhe: f.detalhe,
        detalheImpressao: f.detalheImpressao,
        thumbnailImageSrc: '',
        habilitaCalculoUnidadeMedida: (f.idProdutoUnicoNavigation != null) ? f.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida : f.idProduto?.idProdutoUnicoNavigation.habilitaCalculoUnidadeMedida,
        unidadeTipoMedidaAltura: f.unidadeTipoMedidaAltura,
        unidadeTipoMedidaComprimento: f.unidadeTipoMedidaComprimento,
        unidadeTipoMedidaLargura: f.unidadeTipoMedidaLargura,
        unidadeQuantidadeItem: f.unidadeQuantidadeItem,
      };

      if (f.idProduto?.estoques != null && f.idProduto?.estoques.length > 0) {
        produtoPdv.quantidadeEstoqueContabil = f.idProduto?.estoques[0].quantidadeContabil ?? 0;
        produtoPdv.quantidadeEstoqueVirtual = f.idProduto?.estoques[0].quantidadeVirtual ?? 0;
        produtoPdv.quantidadeEstoqueReservado = f.idProduto?.estoques[0].quantidadeReservado ?? 0;
        produtoPdv.quantidadeEstoqueCondicional = f.idProduto?.estoques[0].quantidadeCondicional ?? 0;
      }

      listaProdutosPdv.push(produtoPdv);
    });

    let tempItensSemSerSalvo = this.ListaProdutosPdv.filter(f => f.idOrcamentoProduto == 0
      && f.idOrcamento == this.idOrcamento);

    let itensSemSerSalvo: boolean = false;
    if (tempItensSemSerSalvo != null && tempItensSemSerSalvo.length > 0) {
      tempItensSemSerSalvo.forEach(f => {
        if (listaProdutosPdv.findIndex(fi => fi.item == f.item) == -1) {
          listaProdutosPdv.push(f);
          itensSemSerSalvo = true;
        }
      });
    }
    this.ListaProdutosPdv = listaProdutosPdv.sort(s => s.item);
    if (itensSemSerSalvo) {
      this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
    }
  }

  alterarTabelaPrecoNaVendaApi(item: TabelaPrecoModel) {
    this.loading = true;
    this.subGetAllTabelaPreco = this.apiPdvService.alterarTabelaPrecoNoOrcamento(this.inputDados.idOrcamento, item.idTabelaPreco, true).subscribe(
      (retorno: OrcamentoModel) => {
        if (retorno != undefined) {
          this.inputDados = retorno;
          this.limparSelecaoProduto(true);
          this.atualizarVendaProdutoParaListaProdutoPdv();
          this.quantidadeProdutoSalvo = this.inputDados.orcamentoProdutos.length;
          this.calcularTotais();
          ConstantsService.TabelaPreco = item;
          this.atualizarTabelaPreco.emit(item);
          this.loading = false;
        }
        this.subGetAllTabelaPreco?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAllTabelaPreco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
  subGetAllTabelaPreco: Subscription = null!;
  ListaTabelaDePreco: TabelaPrecoModel[] = [];
  consultarTabelaPrecos() {
    this.ListaTabelaDePreco = ConstantsService.ListaTabelaPreco;
    if (this.ListaTabelaDePreco != null && this.ListaTabelaDePreco.length > 0) return;

    this.loading = true;
    this.subGetAllTabelaPreco = this.apiTabelaPrecoService.getAllByStatus(enumStatus.Ativo, false).subscribe(
      (retorno: Array<TabelaPrecoModel>) => {
        if (retorno != undefined) {
          ConstantsService.ListaTabelaPreco = retorno;
          this.ListaTabelaDePreco = retorno;
          this.loading = false;
        }
        this.subGetAllTabelaPreco?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAllTabelaPreco?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }


  autofocus: boolean = false;
  precoProduto: number = 0;
  subTotal: number = 0;
  total: number = 0;


  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {

    }
    if (event.keyCode == 13) {
      //this.pesquisar();
    }
  }

  situacaoEmPreenchimento: enumTabelaStatusOrcamento = enumTabelaStatusOrcamento.EmPreenchimento;
  situacaoFinalizado: enumTabelaStatusOrcamento = enumTabelaStatusOrcamento.Finalizado;

  iniciarOrcamento(produtoSelecionado: ProdutoParaPdvModel) {
    if (this.inputDados != null && this.inputDados.idOrcamento != 0 && this.inputDados.idOrcamento != null && this.inputDados.idTabelaStatusOrcamento == this.situacaoEmPreenchimento) {
      this.salvarOrcamento(true, enumAcaoFuncionalidadeOrcamento.NovoOrcamento, null!);
    } else {
      this.iniciarOrcamentoApi(produtoSelecionado);
    }
  }

  cliqueVender() {
    if (this.inputDados == null || this.inputDados.idOrcamento == 0) {
      swal.fire({
        title: "Atenção",
        html: `Adicione um produto antes de vender!`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
          }
        });
      return;
    }

    this.atualizarListaProdutoPdvParaOrcamentoProduto();
    if (this.quantidadeProdutoSalvo == this.inputDados.orcamentoProdutos.length) {
      this.cliqueVenderPasso2();
      return;
    }
    this.loading = true;
    
    this.quantidadeProdutoSalvo = this.inputDados.orcamentoProdutos.length;
    this.calcularTotais();
    this.dataUltimaVendaSalva = new Date();
    this.subsSalvarOrcamentoTemporario = this.apiOrcamentoService.post(this.inputDados, false).subscribe(
      (retorno: OrcamentoModel) => {
        this.subsSalvarOrcamentoTemporario?.unsubscribe();
        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Orçamento salvo com sucesso'], "Sucesso");
        this.cliqueVenderPasso2();
      }
      ,
      (err) => {
        this.loading = false;
        this.subsSalvarOrcamentoTemporario?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        this.pauseTimer();
      });
  }

  cliqueVenderPasso2() {
    swal.fire({
      title: "Parabéns",
      html: `Vamos agora gerar uma venda para este orçamento?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'success'
    })
      .then((res) => {
        if (res.isConfirmed) {
          this.loading = true;
          this.subsRemoverOrcamentoProduto = this.apiVendaService.importarOrcamentoParaPagamento(this.inputDados.idOrcamento, 0, ConstantsService.IdCaixa, ConstantsService.IdPdv, true)?.subscribe(
            (retorno: VendumModel) => {
              this.loading = false;
              swal.fire({
                title: `Venda criada com sucesso! Código [${retorno.idVenda}]`,
                html: "Vamos realizar o pagamento?",
                icon: 'success',
                showCancelButton: true,
                // confirmButtonColor: '#3085d6',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Sim, Pagar!',
                cancelButtonText: 'Não'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.router.navigate([`/pdv/produto-pagamento/${retorno.idVenda}`]);
                }
                if (result.isDismissed) {

                }
              });

            },
            (err) => {
              this.loading = false;
              this.subsRemoverOrcamentoProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
          );
        }
      });
  }

  get NomeCliente(): string{
    let nome = this.inputDados.cpfCnpjNavigation.razaoSocialNome;
    if (nome.length > 20){
      nome = nome.substring(0,20) + '...';
    }
    return nome;
  }

  blinkIndexRed: number | null = null;
  onPiscar(index: number): void {
    this.blinkIndexRed = index;
    setTimeout(() => {
      this.blinkIndexRed = null;
    }, 1000); // Remove a classe após 1 segundo
  }
  
  adicionarProduto(produtoSelecionadoProdutoParaPdv: ProdutoPesquisaModel) {
    this.unTipoMedidaComprimento = null;
    this.unTipoMedidaLargura = null;
    this.unTipoMedidaAltura = null;
    this.unQuantItem = null;

    if (produtoSelecionadoProdutoParaPdv == null) return;
    let listaProdutosPdv = this.ListaProdutosPdv;

    let numeroItem = 1;
    if (listaProdutosPdv.length > 0) {
      numeroItem = listaProdutosPdv[0].item + 1;
    }

    if (this.inputDados.orcamentoProdutos == null) this.inputDados.orcamentoProdutos = [];

    //tratar campos quando for metro, metro 3, metro 2
    //se estiver no cadastro do produto para calcular, preencher campos.
    if(produtoSelecionadoProdutoParaPdv.habilitaCalculoUnidadeMedida == enumSimNao.Sim 
      &&
      (produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetro || produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroCubico ||
       produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroQuadrado
      )
    ){
      if(produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetro){
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaComprimento = 1;
      }
      else if(produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroCubico)      
      {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaAltura = 1;
        this.unTipoMedidaComprimento = 1;
        this.unTipoMedidaLargura = 1;
      }
      else if(produtoSelecionadoProdutoParaPdv.unidadeMedida == this.unMedMetroQuadrado)      
      {
        this.unQuantItem = produtoSelecionadoProdutoParaPdv.quantidade;
        this.unTipoMedidaComprimento = 1;
        this.unTipoMedidaLargura = 1;
      }
    }
    //fim tratamento campos metro

    let itemProdutoOrcamentoModel: ItemOrcamentoProdutoPdvModel = {
      idOrcamentoProduto: 0,
      idOrcamento: this.inputDados.idOrcamento,
      detalheImpressao: '',
      idProdutoUnico: produtoSelecionadoProdutoParaPdv.idProdutoUnico,
      idProdutoVariacao: produtoSelecionadoProdutoParaPdv.idProdutoVariacao,
      detalhe: `${produtoSelecionadoProdutoParaPdv.nome}`,
      quantidade: produtoSelecionadoProdutoParaPdv.quantidade,
      precoVenda: produtoSelecionadoProdutoParaPdv.precoVenda ?? 0,
      valorTotalFrete: 0,
      valorDesconto: 0,
      valorTotalPromocaoConcedido: 0,
      valorOutrasDespesas: 0,
      subTotal: produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda ?? 0),
      custoTotal: this.pesquisaPorProdutoServico.quantidade * (produtoSelecionadoProdutoParaPdv.precoMargemZero??0),
      valorTotal: (produtoSelecionadoProdutoParaPdv.quantidade * (produtoSelecionadoProdutoParaPdv.precoVenda??0)) + this.pesquisaPorProdutoServico.quantidade * (produtoSelecionadoProdutoParaPdv.valorSeguro??0),
      custoUnitario: (produtoSelecionadoProdutoParaPdv.precoMargemZero ?? 0),
      numeroPedidoCompra: '',
      indicaValorItemValorTot: enumSimNao.Sim,
      dataPrevistaEntregaAgendada: null,
      quantidadeEntregueNoAto: produtoSelecionadoProdutoParaPdv.quantidade,
      quantidadeParaEntregaAgendada: 0,
      quantidadeParaEntregaCrossdoking: 0,
      item: numeroItem,
      valorSeguro: (produtoSelecionadoProdutoParaPdv.valorSeguro ?? 0),
      valorTotalSeguro: this.pesquisaPorProdutoServico.quantidade * (produtoSelecionadoProdutoParaPdv.valorSeguro??0),
      alterouPrecoUnitario: enumSimNao.Nao,
      precoUnitarioBase: (produtoSelecionadoProdutoParaPdv.precoVenda??0),
      dataPrevistaParaEntregaCrossdocking: null,
      precoMargemZero: (produtoSelecionadoProdutoParaPdv.precoMargemZero ?? 0),
      observacao: '',
      tipo: produtoSelecionadoProdutoParaPdv.tipo,
      quantidadeEstoqueCondicional: 0,
      quantidadeEstoqueContabil: 0,
      quantidadeEstoqueReservado: 0,
      quantidadeEstoqueVirtual: 0,
      gtin: produtoSelecionadoProdutoParaPdv.gtin,
      nome: produtoSelecionadoProdutoParaPdv.nome,
      percMaxDesconto: produtoSelecionadoProdutoParaPdv.percMaxDesconto,
      sku: produtoSelecionadoProdutoParaPdv.sku,
      tipoEanBalanca: produtoSelecionadoProdutoParaPdv.tipoEanBalanca,
      unidadeMedida: produtoSelecionadoProdutoParaPdv.unidadeMedida,
      thumbnailImageSrc: produtoSelecionadoProdutoParaPdv.thumbnailImageSrc,
      habilitaCalculoUnidadeMedida: produtoSelecionadoProdutoParaPdv.habilitaCalculoUnidadeMedida,
      unidadeTipoMedidaAltura: this.unTipoMedidaAltura,
      unidadeTipoMedidaComprimento: this.unTipoMedidaComprimento,
      unidadeTipoMedidaLargura: this.unTipoMedidaLargura,
      unidadeQuantidadeItem: this.unQuantItem,
    };

    listaProdutosPdv.splice(0, 0, itemProdutoOrcamentoModel);

    this.ListaProdutosPdv = listaProdutosPdv;
    this.calcularTotais();
    this.produtoSelecionado = null!;
    this.pesquisaPorProdutoServico.quantidade = 1;

    this.consultarTotalEstoque(produtoSelecionadoProdutoParaPdv.idProdutoVariacao, 0);

    this.produtos = [];
    this.salvarOrcamentoTemporario();
    this.limparSelecaoProduto(false);
    /**/
  }

  subsGetByIdProdutoVariacao: Subscription = null!;
  consultarTotalEstoque(idProdutoVariacao: number, indexRegistro: number) {
    this.subsGetByIdProdutoVariacao = this.apiEstoqueService.getByIdProdutoVariacao(idProdutoVariacao, false).subscribe(
      (retorno: EstoqueModel) => {
        if (retorno != null) {
          let tempListaProdutosPdv = this.ListaProdutosPdv;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueContabil = retorno.quantidadeContabil;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueVirtual = retorno.quantidadeVirtual??0;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueReservado = retorno.quantidadeReservado??0;
          tempListaProdutosPdv[indexRegistro].quantidadeEstoqueCondicional = retorno.quantidadeCondicional??0;
          this.ListaProdutosPdv = tempListaProdutosPdv;
          
          if(tempListaProdutosPdv[indexRegistro].quantidadeEstoqueContabil <= 0){
            this.onPiscar(indexRegistro);
          }
        }
        this.subsGetByIdProdutoVariacao?.unsubscribe();
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.subsGetByIdProdutoVariacao?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  calcularTotais() {
    this.total = 0;
    this.subTotal = 0;
    this.ListaProdutosPdv.forEach(f => {
      this.subTotal += (f.quantidade * f.precoVenda!);
      this.total += (f.quantidade * f.precoVenda!) - f.valorDesconto! + f.valorTotalFrete! + f.valorOutrasDespesas!
      + (f.quantidade * f.valorSeguro!);
    });
  }

  subsIniciarVenda: Subscription = null!;
  iniciarOrcamentoApi(produtoSelecionado) {
    this.loading = true;
    this.quantidadeProdutoSalvo = 0;
    this.limparSelecaoProduto(true);
    this.IntanciarObjetoInputDados();
    OrcamentoCadastroComponent.IdOrcamentoAtiva = 0;
    this.calcularTotais();
    this.subsIniciarVenda = this.apiOrcamentoService.post(this.inputDados, true).subscribe(
      (retorno: OrcamentoModel) => {
        OrcamentoCadastroComponent.IdOrcamentoAtiva = retorno.idOrcamento;
        if (retorno != undefined) {
          this.tratarRetornoConsultaOrcamento(retorno);

          if (produtoSelecionado != null!) {
            this.atualizarVendaProdutoParaListaProdutoPdv();
            this.ListaProdutosPdv = [];
            this.adicionarProduto(produtoSelecionado);
          } else {
            this.router.navigate([`/orcamento/alterar/${this.inputDados.idOrcamento}`]);
          }
        } else {
          this.inputDados = null!;
          this.limparSelecaoProduto(true);
        }
        this.subsIniciarVenda?.unsubscribe();
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.inputDados = null!;
        this.limparSelecaoProduto(true);
        this.subsIniciarVenda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }



  private IntanciarObjetoInputDados() {
    this.inputDados = OrcamentoModelInstance.get();
    this.inputDados.idTabelaPreco = ConstantsService.TabelaPreco.idTabelaPreco;
    this.inputDados.idTabelaStatusOrcamento = enumTabelaStatusOrcamento.EmPreenchimento;
    this.inputDados.idPdv = ConstantsService.IdPdv;
    this.ListaProdutosPdv = [];
  }

  salvarOrcamento(exibirMensagem: boolean, acao: enumAcaoFuncionalidadeOrcamento, produtoSelecionado: ItemOrcamentoProdutoPdvModel) {
    this.loading = true;
    this.quantidadeProdutoSalvo = this.inputDados.orcamentoProdutos.length;
    this.calcularTotais();
    this.atualizarListaProdutoPdvParaOrcamentoProduto();
    this.subsSalvarOrcamentoTemporario = this.apiOrcamentoService.post(this.inputDados, false).subscribe(
      (retorno: OrcamentoModel) => {

        this.subsSalvarOrcamentoTemporario?.unsubscribe();
        if (exibirMensagem) {
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Orçamento salvo com sucesso'], "Sucesso");
        }

        if (this.inputDados.idOrcamento == 0) {
          this.router.navigate([`/orcamento/alterar/${retorno.idOrcamento}`]);
          return;
        }
        if (retorno != undefined) {
          this.tratarRetornoConsultaOrcamento(retorno);
          this.atualizarVendaProdutoParaListaProdutoPdv();

          switch (acao) {
            case enumAcaoFuncionalidadeOrcamento.AtualizarTabelaPreco:
              this.alterarTabelaPrecoNaVendaApi(this.tabelaPrecoSelecionada);
              break;
            case enumAcaoFuncionalidadeOrcamento.NovoOrcamento:
              this.iniciarOrcamentoApi(produtoSelecionado);
              break;
            case enumAcaoFuncionalidadeOrcamento.Nenhuma:
              this.calcularTotais();
              this.loading = false;
              break;
          }
        }



        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Orçamento salvo com sucesso'], "Sucesso");
        if (this.inputDados.idOrcamento == 0) {
          this.router.navigate([`/orcamento/alterar/${retorno.idOrcamento}`]);
          return;
        }
        // if (retorno != undefined) {
        //   this.tratarRetornoConsultaOrcamento(retorno);
        // }
        if (produtoSelecionado != null) {
          this.adicionarProduto(null!);
        } else {
          this.loading = false;
        }
      }
      ,
      (err) => {
        this.loading = false;
        this.subsSalvarOrcamentoTemporario?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        this.pauseTimer();
      });
  }

  quantidadeProdutoSalvo: number = 0;
  dataUltimaVendaSalva: Date = new Date();
  subsSalvarOrcamentoTemporario: Subscription = null!;

  salvarOrcamentoTemporario() {
    if (this.loading) return;

    if (this.inputDados.orcamentoProdutos.length > this.quantidadeProdutoSalvo + 10) {

      this.loading = true;
      this.quantidadeProdutoSalvo = this.inputDados.orcamentoProdutos.length;
      this.calcularTotais();
      this.atualizarListaProdutoPdvParaOrcamentoProduto();
      this.dataUltimaVendaSalva = new Date();
      this.subsSalvarOrcamentoTemporario = this.apiOrcamentoService.post(this.inputDados, false).subscribe(
        (retorno: OrcamentoModel) => {
          this.subsSalvarOrcamentoTemporario?.unsubscribe();
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Orçamento salvo com sucesso'], "Sucesso");
          if (retorno != undefined) {
            this.tratarRetornoConsultaOrcamento(retorno);
          }
          this.loading = false;
        }
        ,
        (err) => {
          this.loading = false;
          this.subsSalvarOrcamentoTemporario?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
          this.pauseTimer();
        });
    }
  }

  private pauseTimer() {
    clearInterval(this.interval);
  }
  interval;

  tratarRetornoConsultaOrcamento(retorno: OrcamentoModel) {
    this.inputDados = retorno;
    this.quantidadeProdutoSalvo = this.inputDados.orcamentoProdutos.length;
    if (this.inputDados.idTabelaStatusOrcamento == null || this.inputDados.idTabelaStatusOrcamento == 0) {
      this.inputDados.idTabelaStatusOrcamento = this.situacaoEmPreenchimento;
    }
    this.inputDados.idPdv = ConstantsService.IdPdv;
  }

  iniciar(item: ProdutoPesquisaModel) {
    this.iniciarOrcamento(item);
  }

  getFormatDecimal(number: number): string {
    if (number == null) return '';
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
  }

  subsRemoverOrcamentoProduto: Subscription = null!;
  removerOrcamento() {
    swal.fire({
      title: "Alerta",
      html: `Tem certeza que deseja remover este orçamento?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        if (res.isConfirmed) {
          this.loading = true;
          this.subsRemoverOrcamentoProduto = this.apiOrcamentoService.removerOrcamento(this.inputDados, true)?.subscribe(
            () => {
              this.loading = false;
              this.subsRemoverOrcamentoProduto?.unsubscribe();
              this.showMessageError.limparMensagensAlerta();
              this.appCustomToastService.toast(TiposMensagem.sucesso, ['Orçamento removido com sucesso'], "Sucesso");
              this.voltar();
            },
            (err) => {
              this.loading = false;
              this.subsRemoverOrcamentoProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
          );
        }
      });
  }



  getProdutoVariacal(item: ProdutoVariacaoModel) {
    if (item.idProdutoUnicoNavigation.tipoProduto == enumTipoProduto.Variacao) {
      return `${item.idProdutoUnicoNavigation.nome} - ${item.nomeVariacao}`
    }
    return `${item.idProdutoUnicoNavigation.nome}`;
  }

  getTipoServico(tipo: string): string {
    if (tipo == enumTipoProduto.Servico) return "Serviço";
    return "Produto";
  }

  subsGetPesquisar: Subscription = null!;
  pesquisarOrcamento(idOrcamento: number) {

    this.loading = true;

    let idTabelaPreco: number = ConstantsService.TabelaPreco.idTabelaPreco;

    this.subsGetPesquisar = this.apiOrcamentoService.getById(idOrcamento, idTabelaPreco, true).subscribe(
      (retorno: OrcamentoModel) => {
        this.loading = false;
        if (retorno != undefined) {
          this.tratarRetornoConsultaOrcamento(retorno);
          this.limparSelecaoProduto(true);
          this.atualizarVendaProdutoParaListaProdutoPdv();
          this.calcularTotais();
        }
        this.loading = false;
        this.subsGetPesquisar?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetPesquisar?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  informacoesAdicionais() {
    PdvComponent.modalAtiva = true;
    this.modalInformacoesAdicionaisComponent.abrirModal(this.inputDados);
  }

  adicionarCliente() {
    this.autofocus = false;
    PdvComponent.modalAtiva = true;
    if (this.inputDados != null){
      this.modalSelecionarClienteComponent.abrirModal(this.inputDados.cpfCnpjNavigation, false, false);
    }else{
      this.modalSelecionarClienteComponent.abrirModal(null!, false, false);
    }
  }

  selecionouCliente(param: PessoaFisicaJuridicaModel) {
    PdvComponent.modalAtiva = false;
  if(this.inputDados == null || this.inputDados == undefined){
      this.IntanciarObjetoInputDados();
    }
    if (param != null) {
      this.inputDados.cpfCnpjNavigation = param;
      this.inputDados.cpfCnpj = param.cpfCnpj;
      this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
    } else {
      this.inputDados.cpfCnpjNavigation = param;
      this.inputDados.cpfCnpj = null!;
      this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
    }
  }

  voltar() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/orcamento'])
    );
    window.open(url, '_self');
  }

  simularPagamento() {
    if (this.inputDados == null || this.inputDados.idOrcamento == 0) {
      swal.fire({
        title: "Atenção",
        html: `Adicione um produto antes de simular um pagamento!`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
          }
        });
      return;
    }

    this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
    this.router.navigate([`/orcamento/simular-pagamento/${this.inputDados.idOrcamento}`]);
  }

  salvouAlteracaoCliente(param: OrcamentoModel) {

  }

  salvouAlteracao(param: OrcamentoModel) {
    this.inputDados = param;
    this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
  }

  exibirInformacoesCliente() {
    PdvComponent.modalAtiva = true;
    this.modalSituacaoClienteComponent.abrirModal(this.inputDados.cpfCnpj);
  }

  alterouItensDeOrcamento(item: OrcamentoProdutoModel) {
    this.inputDados.orcamentoProdutos[this.indexItemSelecionado] = item;
    this.atualizarItemOrcamentoProdutoParaListaProdutoPdv(item);
    this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
  }

  onRowSelectItemOrcamento(item: any) {
    this.alterarItem(item.data, item.index);
  }

  exibirEstoque(produtoSelecionadoProdutoParaPdv: ItemOrcamentoProdutoPdvModel, index: number) {
    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;
    this.atualizarListaProdutoPdvParaOrcamentoProduto();
    let rowIndex = this.inputDados.orcamentoProdutos.findIndex(f => f.numeroItem == produtoSelecionadoProdutoParaPdv.item);
    let orcamentoProduto = this.inputDados.orcamentoProdutos[rowIndex];
    this.modalOrcamentoEstoqueComponent.abrirModal(orcamentoProduto);
  }

  indexItemSelecionado: number = 0;
  alterarItem(produtoSelecionadoProdutoParaPdv: ItemOrcamentoProdutoPdvModel, index: number) {
    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;

    this.atualizarListaProdutoPdvParaOrcamentoProduto();
    let rowIndex = this.inputDados.orcamentoProdutos.findIndex(f => f.numeroItem == produtoSelecionadoProdutoParaPdv.item);
    let orcamentoProduto = this.inputDados.orcamentoProdutos[rowIndex];

    this.modalAlterarItemOrcamentoComponent.abrirModal(orcamentoProduto);
  }
  removerItem(produtoSelecionadoProdutoParaPdv: ItemOrcamentoProdutoPdvModel, index: number) {
    this.indexItemSelecionado = index;
    PdvComponent.modalAtiva = true;

    this.atualizarListaProdutoPdvParaOrcamentoProduto();
    let rowIndex = this.inputDados.orcamentoProdutos.findIndex(f => f.numeroItem == produtoSelecionadoProdutoParaPdv.item);
    let orcamentoProduto = this.inputDados.orcamentoProdutos[rowIndex];

    this.removerItemInfPagamento(orcamentoProduto);
  }

  subsRemoverItemOrcamentoProduto: Subscription = null!;
  removerItemInfPagamento(itemSelecionado: OrcamentoProdutoModel) {
    swal.fire({
      title: "Alerta",
      html: `Tem certeza que deseja excluir este item?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        PdvComponent.modalAtiva = false;
        if (res.isConfirmed) {
          this.loading = true;
          this.subsRemoverItemOrcamentoProduto = this.apiOrcamentoService.removerItemOrcamentoProduto(itemSelecionado, true)?.subscribe(
            () => {
              this.loading = false;
              this.subsRemoverItemOrcamentoProduto?.unsubscribe();
              this.showMessageError.limparMensagensAlerta();
              let tempLista = this.ListaProdutosPdv;
              tempLista.splice(this.indexItemSelecionado, 1);
              this.ListaProdutosPdv = tempLista;
              this.salvarOrcamento(false, enumAcaoFuncionalidadeOrcamento.Nenhuma, null!);
            },
            (err) => {
              this.loading = false;
              this.subsRemoverItemOrcamentoProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
          );
        }
      });
  }

  fechouModal(event: any) {
    PdvComponent.modalAtiva = false;
  }

  recarregarSimulacaoPagamento(idOrcamentoSimulacaoPagamento: number) {
    this.router.navigate([`/orcamento/escolher-pagamento/${this.inputDados.idOrcamento}/${idOrcamentoSimulacaoPagamento}`]);
  }

  listarSimulacoesPendentes() {
    this.modalListaSimulacoesComponent.abrirModal(this.inputDados.idOrcamento, 0);
  }

  onAbriuModalConfiguracao(event:any){
    PdvComponent.modalAtiva = true;
  }
  onFechouModalAlteracao(event:any){
    PdvComponent.modalAtiva = false;
  }

  cliqueImprimir(formatoImpressao: enumFormatoImpressao) {
    if (this.inputDados == null || this.inputDados.idOrcamento == 0) {
      swal.fire({
        title: "Atenção",
        html: `Adicione um produto antes de imprimir!`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
          }
        });
      return;
    }
    //verificar se existe produto sem salvar
    let verificarProds = this.ListaProdutosPdv.filter(f => f.idOrcamentoProduto == 0);
    if(verificarProds != null && verificarProds.length > 0){
      swal.fire({
        title: "Atenção",
        html: `Salve o orçamento antes de imprimir!`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
          }
        });
      return;
    }
    //this.modalFormatoImpressaoComponent.abrirModal(this.inputDados.idOrcamento);
    this.imprimir(this.inputDados.idOrcamento, formatoImpressao);
  }

  imprimir(idOrcamento: number, formatoImpressao: enumFormatoImpressao){
    this.loading = true;
    let parametroImpressao : CustomFormatoImpressao = {
      id: idOrcamento,
      layoutImpressao: formatoImpressao,
      tipoComanda: ''
    };
    var dataAtual = new Date;
    this.nomeArquivo = `extrato-orçamento-${idOrcamento}-${dataAtual.valueOf()}.pdf`;
    this.mensagemService.exibirLock();
    this.subsGerarExtratoOrcamento = this.apiGeraRelatorioOrcamentoService.imprimirExtratoOrcamento(parametroImpressao, true)
      .subscribe((fileData: any) => {
        this.subsGerarExtratoOrcamento?.unsubscribe();
        // Agora o pai pode chamar uma função no componente filho
        this.modalImpressaoComponent.imprimir("09", fileData, 'application/pdf');
        //
        //  const blob: any = new Blob([fileData], { type: 'application/pdf' });
        //  var pdfUrl = URL.createObjectURL(blob);
        //  printJs(pdfUrl);
        this.loading = false;
        this.mensagemService.esconderLock();
      },
      (err) => {
        this.subsGerarExtratoOrcamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
        this.mensagemService.esconderLock();
        this.loading = false;
      }
    );
  }

  abriuFechouCaixa(caixaControle: CaixaControleModel) {
    PdvComponent.modalAtiva = false;
    if (caixaControle != null) {
      this.atualizarTextoCabecalhoSituacaoCaixa.emit(caixaControle);
    } else {
      if (ConstantsService.SituacaoCaixa == enumSituacaoCaixa.Fechado || ConstantsService.SituacaoCaixa == null)
        this.cancelouSelecaoCaixa();
    }
  }

  cancelouSelecaoCaixa() {
    swal.fire({
      title: "Alerta",
      html: 'É obrigatório abrir um caixa para realizar vendas no PDV',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
        this.navegarPaginaMenuPdv();
      }
    });
  }


  navegarPaginaMenuPdv() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/menu/default'])
    );
    window.open(url, '_self');
  }

  /*Lista de Anexo*/
  modoTela: enumModoTela = enumModoTela.Cadastrar;
  moduloListaAnexo: enumModuloAnexo = enumModuloAnexo.Orcamento;
  tipoArquivoAnexo: enumListaAnexoTipoArquivo = enumListaAnexoTipoArquivo.ListaArquivo;

  atualizarListaAnexo($event){

  }

  clickSAnexarDocumentos(){
    PdvComponent.modalAtiva = true;
    this.modalListaAnexoComponent.abrirModal();
  }
}