import { NgModule } from '@angular/core';
import { DebounceClickDirective } from 'src/app/theme/shared/components/directive/debounce-click';

@NgModule({
  declarations: [
    DebounceClickDirective
  ],
  imports: [
  ],
  exports: [
    DebounceClickDirective
  ]
})
export class DebounceClickModule { }