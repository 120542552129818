import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './theme/layout/login/login.component';
import { PdvComponent } from './theme/layout/pdv/pdv.component';
import { AuthGuard } from './theme/shared/auth/auth-guard.service';
import { NoAuthGuard } from './theme/shared/auth/no-auth-guard.service';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule)
      }]
  },
  {
    path: 'pagina',
    component: LoginComponent,
    children: [
        {
          path: 'redirect',
          canActivate: [NoAuthGuard],
          children: [
            {
              path: 'page/:idTransfereToken',
              loadChildren: () => import('./pages/redirect-page/redirect-page.module').then(module => module.RedirectPageModule),
              canActivate: [AuthGuard]
            },
            {
              path: 'post/:page',
              loadChildren: () => import('./pages/redirect-page/redirect-page.module').then(module => module.RedirectPageModule),
              canActivate: [AuthGuard]
            }
          ]
        }
      ]
  },
  {
    path: '',
    component: PdvComponent,
    children: [
      {
        path: '',
        //redirectTo: '/login',
        // pathMatch: 'full',
        // canActivate: [AuthGuard]
        // path: '',
        loadChildren: () => import('./pages/menu-principal/menu-principal.module').then(module => module.MenuPrincipalModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'menu/default',
        loadChildren: () => import('./pages/menu-principal/menu-principal.module').then(module => module.MenuPrincipalModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pdv/produto-pagamento',
        loadChildren: () => import('./pages/pdv-produto-pagamento/pdv-produto-pagamento.module').then(module => module.PdvProdutoPagamentoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'pdv/produto-pagamento/:idVenda',
        loadChildren: () => import('./pages/pdv-produto-pagamento/pdv-produto-pagamento.module').then(module => module.PdvProdutoPagamentoModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'condicional',
        
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/condicional/listar/listar-condicional.module').then(module => module.ListarCondicionalModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'cadastrar',
            loadChildren: () => import('./pages/condicional/cadastrar/cadastrar-condicional.module').then(module => module.CadastrarCondicionalModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'alterar/:idCondicional',
            loadChildren: () => import('./pages/condicional/cadastrar/cadastrar-condicional.module').then(module => module.CadastrarCondicionalModule),
            canActivate: [AuthGuard]
          }
        ],
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'devolucao-venda',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/devolucao-venda/listar/listar-devolucao-venda.module').then(module => module.ListarDevolucaoVendaModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'alterar/:idVenda',
            loadChildren: () => import('./pages/devolucao-venda/cadastrar/cadastrar-devolucao-venda.module').then(module => module.CadastrarDevolucaoVendaModule),
            canActivate: [AuthGuard]
          }
        ],
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'listar-vendas',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/vendas/venda-lista/venda-lista.module').then(module => module.VendaListaModule),
            canActivate: [AuthGuard]
          }
        ],
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'orcamento',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/orcamento/orcamento-lista/orcamento-lista.module').then(module => module.OrcamentoListaModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'cadastrar',
            loadChildren: () => import('./pages/orcamento/orcamento-cadastro/orcamento-cadastro.module').then(module => module.OrcamentoCadastroModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'alterar/:idOrcamento',
            loadChildren: () => import('./pages/orcamento/orcamento-cadastro/orcamento-cadastro.module').then(module => module.OrcamentoCadastroModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'simular-pagamento/:idOrcamento',
            loadChildren: () => import('./pages/orcamento/orcamento-simulacao/orcamento-simulacao.module').then(module => module.OrcamentoSimulacaoModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'escolher-pagamento/:idOrcamento/:idOrcamentoSimulacaoPagamento',
            loadChildren: () => import('./pages/orcamento/orcamento-simulacao-pagamento/orcamento-simulacao-pagamento.module').then(module => module.OrcamentoSimulacaoPagamentoModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'novo-pagamento/:idOrcamento',
            loadChildren: () => import('./pages/orcamento/orcamento-simulacao-pagamento/orcamento-simulacao-pagamento.module').then(module => module.OrcamentoSimulacaoPagamentoModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'escolher-pagamento-modal/:idOrcamento/:idOrcamentoSimulacaoPagamento',
            loadChildren: () => import('./pages/orcamento/orcamento-simulacao-pagamento/orcamento-simulacao-pagamento.module').then(module => module.OrcamentoSimulacaoPagamentoModule),
            canActivate: [AuthGuard]
          }
        ],
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'pedido-venda',
        children: [
          {
            path: '',
            loadChildren: () => import('./pages/pedido-venda/pedido-venda-lista/pedido-venda-lista.module').then(module => module.PedidoVendaListaModule),
            canActivate: [AuthGuard]
          }
          ,
          {
            path: 'cadastrar',
            loadChildren: () => import('./pages/pedido-venda/pedido-venda-cadastro/pedido-venda-cadastro.module').then(module => module.PedidoVendaCadastroModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'alterar/:idPedidoVenda',
            loadChildren: () => import('./pages/pedido-venda/pedido-venda-cadastro/pedido-venda-cadastro.module').then(module => module.PedidoVendaCadastroModule),
            canActivate: [AuthGuard]
          }
        ],
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'cadastro/estoque',
        loadChildren: () => import('./pages/estoque/estoque.module').then(module => module.EstoqueModule),
        canActivate: [AuthGuard]
      }
      ,
      // {
      //   path: 'entregas/cadastro',
      //   loadChildren: () => import('./pages/entrega/entrega-cadastro/entrega-cadastro.module').then(module => module.EntregaCadastroModule),
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'entregas',
        children: [
          {
            path: 'listar',
            loadChildren: () => import('./pages/entrega/entrega-lista/entrega-lista.module').then(module => module.EntregaListaModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'cadastrar',
            loadChildren: () => import('./pages/entrega/entrega-cadastro/entrega-cadastro.module').then(module => module.EntregaCadastroModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'alterar/:idVendaEntrega',
            loadChildren: () => import('./pages/entrega/entrega-cadastro/entrega-cadastro.module').then(module => module.EntregaCadastroModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'disponivel-entrega',
            loadChildren: () => import('./pages/entrega/entrega-disponivel/entrega-disponivel.module').then(module => module.EntregaDisponivelModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'rota-entrega/listar',
            loadChildren: () => import('./pages/entrega/rota-de-entrega-lista/rota-de-entrega-lista.module').then(module => module.RotaDeEntregaListaModule),
            canActivate: [AuthGuard]
          },
          {
            path: 'rota-entrega/alterar/:idRotaEntrega',
            loadChildren: () => import('./pages/entrega/rota-de-entrega-cadastro/rota-de-entrega-cadastro.module').then(module => module.RotaDeEntregaCadastroModule),
            canActivate: [AuthGuard]
          }
        ],
        canActivate: [AuthGuard]
      }
      ,
      {
        path: 'comanda/selecionar',
        loadChildren: () => import('./pages/comanda/comanda.module').then(module => module.ComandaModule),
        canActivate: [AuthGuard]
      }
     
    ]
    
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
