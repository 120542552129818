<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-lg'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 text-bold ">&nbsp;<i class="fad fa-info-circle"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body modal-detalhe-produto" *ngIf="inputDados != null">
        <!-- <div class="row">
            <div class="col-12">
                <h4>
                    {{descricaoModal}}
                </h4>
            </div>
        </div> -->
       
        <div class="row ">
            <div class="col-xl-6 col-md-6 col-sm-12 p-t-5">
                <div class="form-group">
                    <app-combo-profissional [(ngModel)]="inputDados.proCpfCnpj"
                    [label]="'Vendedor'" [style]="'width:100%'" 
                    [toolTip]="'Selecione um profissional caso queira atribuir comissão à ele ou apenas para registrar quem realizou a venda'">
                    </app-combo-profissional>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 p-t-5 ">
                <div class="form-group">
                    
                    <app-combo-prestador-servico [(ngModel)]="inputDados.preCpfCnpj" 
                    [label]="'Prestador de Serviço'" [style]="'width:100%'"
                    [toolTip]="'Selecione um prestador de serviço caso queira atribuir comissão à ele ou apenas para registrar quem executou o serviço'"
                        >
                    </app-combo-prestador-servico>
                </div>
            </div>
            
        </div>
        <div class="row pt-3">
            <div class="col-4 text-right" >
                <label for="dataExecucao" class="form-label ">Data Execução</label>
                <p-calendar [(ngModel)]="inputDados.dataExecucao" name="inputDados.dataExecucao" [readonlyInput]="false" #elmDataExecucao  inputId="elmDataExecucao" disabled="true"
                    dateFormat="dd/mm/yyyy"   dateFormat="dd/mm/yy" [inputStyleClass]="'form-control'" appendTo="body" 
                    id="dataExecucao" [monthNavigator]="true" [yearNavigator]="true" 
                    [showButtonBar]="true" [showIcon]="true"  [showOnFocus]="false"
                    >
                </p-calendar>
            </div>
       
            <div class="col-4 text-right" >
                <label for="dataPrevistaEntrega" class="form-label ">Data Prev. Entrega</label>
                <p-calendar [(ngModel)]="inputDados.dataPrevistaEntrega" name="inputDados.dataPrevistaEntrega" [readonlyInput]="false" #elmDataPrevistaEntrega  inputId="elmDataPrevistaEntrega"
                    dateFormat="dd/mm/yyyy" [minDate]="minDate" dateFormat="dd/mm/yy" [inputStyleClass]="'form-control'" appendTo="body" 
                    id="dataPrevistaEntrega" [monthNavigator]="true" [yearNavigator]="true" 
                    [showButtonBar]="true" [showIcon]="true"  [showOnFocus]="false"
                    >
                </p-calendar>
            </div>
            <div class="col-4 text-right" >
                <label for="dataValidadeOrcamentoPrevenda" class="form-label ">Data Validade</label>
                <p-calendar [(ngModel)]="inputDados.dataValidadeOrcamentoPrevenda" name="inputDados.dataValidadeOrcamentoPrevenda" [readonlyInput]="false" #elmDataValidadeOrcamentoPrevenda  inputId="elmDataValidadeOrcamentoPrevenda"
                    dateFormat="dd/mm/yyyy"  [minDate]="minDate" dateFormat="dd/mm/yy" [inputStyleClass]="'form-control'" appendTo="body" 
                    id="dataValidadeOrcamentoPrevenda" [monthNavigator]="true" [yearNavigator]="true" 
                    [showButtonBar]="true" [showIcon]="true"  [showOnFocus]="false"
                    >
                </p-calendar>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="d-inline form-check-label mouse-pointer form-label" for="complementoOrcamento">Observação / Complemento&nbsp;<i class="fas fa-info-circle"
                    ngbTooltip="Observações que será exibido na impressão, após as simulações."></i></label>
                <textarea maxlength="300" class="form-control input-md complementoOrcamento class-npe" id="complementoOrcamento" name="complementoOrcamento"
                    type="text"  placeholder="" style="height:fit-content"
                    [(ngModel)]="inputDados.complementoOrcamento"></textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="d-inline form-check-label mouse-pointer form-label" for="observacaoInterna">Observação Interna</label>
                <textarea maxlength="300" style="height:fit-content" class="form-control input-md observacaoInterna class-npe" id="observacaoInterna" name="observacaoInterna"
                    type="text"  placeholder=""
                    [(ngModel)]="inputDados.observacaoInterna"></textarea>
            </div>
        </div>
    </div>
    
    <div class="app-modal-footer">
        <button [disabled]="false" (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class="fad fa-window-close"></i> Fechar
        </button>&nbsp;
        <button [disabled]="false" (click)="cliqueSalvar()" type="button"
            class="btn btn-primary m-r-5 inline"><i class="fad fa-save"></i> Salvar
        </button>
    </div>
</app-ui-modal>