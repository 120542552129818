import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { OrcamentoSimulacaoPagamentoModel } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/orcamentoSimulacaoPagamentoModel';
import { ApiOrcamentoService } from 'src/app/services/pdv/api-orcamento-service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ApiPagamentoService } from 'src/app/services/pdv/api-pagamento.service';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { ValoresOrcamentoSimulacaoPagamentoModel } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/ValoresOrcamentoSimulacaoPagamentoModel';
import { OrcamentoSimulacaoPagamentoModelInstance } from 'src/app/models/cliente/OrcamentoSimulacaoPagamento/OrcamentoSimulacaoPagamentoModelInstance';

@Component({
  selector: 'app-modal-lista-simulacoes',
  templateUrl: './modal-lista-simulacoes.component.html',
  styleUrls: ['./modal-lista-simulacoes.component.css']
})
export class ModalListaSimulacoesComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() alterouItens: EventEmitter<number> = new EventEmitter();
  loadingListarItens: boolean = false;

  constructor(
    private readonly apiOrcamentoService: ApiOrcamentoService,
    private readonly apiPagamentoService: ApiPagamentoService,
    private appCustomToastService: AppCustomToastService,
    private route: ActivatedRoute,
    private router: Router,
    private showMessageError: ShowMessageError
    ) { }

  ngOnInit(): void {
  }

  get heightLista(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
  }

  removerItem(item: OrcamentoSimulacaoPagamentoModel, index: number){
    this.indexItemSelecionado = index;
    this.cancelarSimulacao(item);
  }

  subsCancelarOrcamentoSimulacaoPagamento: Subscription = null!;
  cancelarSimulacao(item: OrcamentoSimulacaoPagamentoModel) {
    swal.fire({
      title: "Atenção",
      html: `Tem certeza que deseja cancelar esta simulação?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        if (res.isConfirmed) {
          //chamar api
          if (item.idOrcamentoSimulacaoPagamento == 0) return;
          this.loading = true;
          this.subsCancelarOrcamentoSimulacaoPagamento = this.apiPagamentoService.cancelarOrcamentoSimulacaoPagamento(item.idOrcamentoSimulacaoPagamento, false).subscribe(
            () => {
              this.subsCancelarOrcamentoSimulacaoPagamento?.unsubscribe();
              this.appCustomToastService.toast(TiposMensagem.sucesso, ['Simulação cancelada com sucesso'], "Sucesso");
              this.loading = false;
              if (this.idOrcamentoSimulacaoPagamento == item.idOrcamentoSimulacaoPagamento){
                //a mesma que esta aberta, nest caso faz o oute para a pagina anterior
                this.router.navigate([`/orcamento/simular-pagamento/${this.idOrcamento}`]);
              }else{
                this.pesquisarSimulacoesDePagamento(this.idOrcamento);
              }
            }
            ,
            (err) => {
              this.loading = false;
              this.subsCancelarOrcamentoSimulacaoPagamento?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            });
        }
      });
  }

  getIdRadioSelecaoPagamento(idOrcamentoSimulacaoPagamento: number){
    return 'radioSelecaoFormaPagamento_' + idOrcamentoSimulacaoPagamento;
  }

  selecionarFormaPagamento(item: ValoresOrcamentoSimulacaoPagamentoModel, index: number){
    item.selecionado = enumSimNao.Sim;
    this.selecionarOrcamentoSimulacaoPagamento(item, index);
  }

  subsSalvarOrcamentoSimulacaoPagamento: Subscription = null!;
  selecionarOrcamentoSimulacaoPagamento(item: ValoresOrcamentoSimulacaoPagamentoModel, index: number) {
    this.loading = true;
    
    let param: OrcamentoSimulacaoPagamentoModel = OrcamentoSimulacaoPagamentoModelInstance.get();
    param.idOrcamentoSimulacaoPagamento = item.idOrcamentoSimulacaoPagamento;
    param.idOrcamento = item.idOrcamento;
    param.descricao = item.descricao;
    param.selecionado = item.selecionado;
    param.valorDespesaCondicaoPagamento = item.valorDespesaCondicaoPagamento;
    param.valorJurosCondicaoPagamento = item.valorJurosCondicaoPagamento;
    param.valorDescontoCondicaoPagamento = item.valorDescontoCondicaoPagamento;
    param.valorDescontoVendedor = item.valorDescontoVendedor;

    this.subsSalvarOrcamentoSimulacaoPagamento = this.apiPagamentoService.selecionarOrcamentoSimulacaoPagamento(param, false).subscribe(
      (retorno: OrcamentoSimulacaoPagamentoModel) => {
        this.subsSalvarOrcamentoSimulacaoPagamento?.unsubscribe();
        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Simulação selecionada com sucesso'], "Sucesso");
        this.orcamentosSimulacoesPagamentos[index] = item;
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.subsSalvarOrcamentoSimulacaoPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  idOrcamento: number = 0;
  idOrcamentoSimulacaoPagamento: number = 0;
  abrirModal(idOrcamento: number, idOrcamentoSimulacaoPagamento: number){
    this.idOrcamento = idOrcamento;
    this.idOrcamentoSimulacaoPagamento = idOrcamentoSimulacaoPagamento;
    this.pesquisarSimulacoesDePagamento(idOrcamento);
    this.modal.show();
  }

  loading: boolean = false;
  alterouItemComSucesso: boolean = false;
  cliqueFechar(){
    this.fecharModal();
  }
  fecharModal(){
    this.modal.hide();
  }  

  onRowSelectItemSimulacaoOrcamentoPagamento(item: any) {
    this.alterarItem(item.data, item.index);
  }
  indexItemSelecionado: number = 0;
  alterarItem(item: OrcamentoSimulacaoPagamentoModel, index: number){
    this.alterouItens.emit(item.idOrcamentoSimulacaoPagamento);
    this.fecharModal();
  }

  orcamentoSimulacaoProduto: OrcamentoSimulacaoPagamentoModel = null!;
  orcamentosSimulacoesPagamentos: ValoresOrcamentoSimulacaoPagamentoModel[] = [];
  subsGetPesquisar: Subscription = null!;
  pesquisarSimulacoesDePagamento(idOrcamento: number){
    if (this.idOrcamento == 0) return;
    this.loading = true;
    this.subsGetPesquisar = this.apiOrcamentoService.getSimulacoesPagamentos(idOrcamento, true).subscribe(
      (retorno: ValoresOrcamentoSimulacaoPagamentoModel[]) =>{
        this.loading = false;   
        if (retorno != undefined) {
          this.orcamentosSimulacoesPagamentos = retorno;
        }
        this.loading = false;
        this.subsGetPesquisar?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetPesquisar?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
}
