import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { EnderecoModel } from 'src/app/models/controle/endereco/enderecoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';


@Injectable({
    providedIn: 'root'
})
export class ApiEnderecoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'endereco';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAllByIdPessoaFisicaJuridica(cpfCnpj: string) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdPessoaFisicaJuridica/${cpfCnpj}`, true);
    }

    get(id: number){
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
    }

    post(obj: EnderecoModel){
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj);
    }

    put(obj: EnderecoModel){
        return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idEndereco}`, obj);
    }

    delete(id: number){
        return this.gc.delete(this.api, `${this.version}${this.controller}/${id}`);
    }
}