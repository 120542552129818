import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'app-ui-modal',
    templateUrl: './ui-modal.component.html',
    styleUrls: ['./ui-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class UiModalComponent implements OnInit {
    @Input() dialogClass!: string;
    @Input() hideHeader = false;
    @Input() hideFooter = false;
    @Input() containerClick = true;
    @Input() modalFrontLayer = false;
    @Output() close: EventEmitter<any> = new EventEmitter();
    public visible = false;
    public visibleAnimate = false;

    constructor() {}

    get ClassFrontLayer() : string {
        if (this.modalFrontLayer){
            return 'basic modal-front-layer fade';
        }
        return 'basic modal fade';
    }

    cliqueClose(){
        this.close.emit(null);
    }

    ngOnInit() {}

    public show(): void {
        this.visible = true;
        setTimeout(() => (this.visibleAnimate = true), 100);
        document.querySelector('body')!.classList.add('modal-open');
    }

    public hide(): void {
        this.visibleAnimate = false;
        setTimeout(() => (this.visible = false), 300);
        document.querySelector('body')!.classList.remove('modal-open');
    }

    public onContainerClicked(event: MouseEvent): void {
        if ((event.target as HTMLElement).classList.contains('modal') && this.containerClick === true) {
            this.hide();
        }
    }
}
