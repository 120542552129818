import { CaixaControleModel } from "./caixaControleModel";


export class CaixaControleModelInstance {
    public static get(): CaixaControleModel {
        return {
            idCaixaControle: 0,
            idLoja: 0,
            idCaixa: null,
            idTabelaCentroCusto: null,
            idCaixaControleAnterior: null,
            cpfAberturaCaixa: '',
            cpfFechamentoCaixa: '',
            dataHoraEntrada: null!,
            dataHoraSaida: null,
            situacao: '',
            valorDescontoCondicaoPagamento: null,
            valorDescontoVendaProduto: null,
            valorDescontoVendedor: null,
            valorDespesaCondicaoPagamento: null,
            valorFreteVendaProduto: null,
            valorJurosCondicaoPagamento: null,
            valorOutrasDespesasVendaProduto: null,
            valorTotalSuprimento: null,
            valorTotalVendaBruta: null,
            valorTotalRecebimentoBruto: null,
            valorTotalEntrada: null,
            valorTotalSangrias: null,
            valorTotalSobra: null,
            valorTotalFalta: null,
            valorTotalSaida: null,
            dataConferenciaCaixa: null,
            cpfConferenciaCaixa: '',
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            valorTotalDinheiro: null,
            valorTotalCheque: null,
            idCaixaControleAnteriorNavigation: null!,
            idCaixaNavigation: null!,
            idTabelaCentroCustoNavigation: null!,
            caixaConferencia: null!,
            caixaMovimentos: null!,
            caixaSangriaSuprimentos: null!,
            inverseIdCaixaControleAnteriorNavigation: null!,
            valorSeguroVendaProduto: 0,
            caixaControleLogContagemCedulaMoedum: null!,
            contasAPagRecs: null!
        };
    }

    public static getArray(): CaixaControleModel[] {
        return [this.get()];
    }
}
