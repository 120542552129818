import swal from 'sweetalert2';
import * as printJS from 'print-js';
import { Component, EventEmitter, Input, OnInit, Output, forwardRef, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { enumTipoImpressao } from 'src/app/enum/enumTipoImpressao';
import { ConstantsService } from '../../constants/constants.service';
import { PdfService } from 'src/app/core/util/pdfService';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ListaFixaImpressaoModel } from 'src/app/models/cliente/ParametrizacaoEnvioImpressora/ListaFixaImpressaoModel';
import { enumFormatoImpressaoPadrao } from 'src/app/enum/enumFormatoImpressaoPadrao';
import { Subscription } from 'rxjs';
import { ModalCrudParametrizacaoEnvioImpressoraComponent } from 'src/app/pages/parametrizacao-envio-impressora/modal-crud-parametrizacao-envio-impressora/modal-crud-parametrizacao-envio-impressora.component';

@Component({
  selector: 'app-modal-impressao',
  templateUrl: './modal-impressao.component.html',
  styleUrls: ['./modal-impressao.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ModalImpressaoComponent),
    multi: true
  }]
})
export class ModalImpressaoComponent implements OnInit {

  items: any[] = [];
  @ViewChild(ModalCrudParametrizacaoEnvioImpressoraComponent, { static: false }) modalCrudParametrizacaoEnvioImpressoraComponent!: ModalCrudParametrizacaoEnvioImpressoraComponent;

  // Parâmetros de entrada recebidos do componente pai
  @Input() show: boolean = true; // Código do documento recebido
  @Input() ngbTooltip: string = ''; // Código do documento recebido
  
  private _codigoDocumento: string = '';

  @Input() set codigoDocumento(value: string) {
    this._codigoDocumento = value;
  }

  get codigoDocumento(): string {
    return this._codigoDocumento;
  }
  @Input() idPdv!: number; // Código do documento recebido
  @Input() textButton: string = 'Imprimir'; //Texto do botão recebido
  @Input() icon: string = 'fad fa-print ps-2'; //Icone do botão
  @Input() modalFrontLayer: boolean = true;
  @Input() typeButton: string = 'default'; //Tipo do botão para ser exibido: default ou icon
  //https://primeng.org/splitbutton#api.splitbutton.props.severity

  // O EventEmitter emite um evento para o componente pai
  @Output() onImprimirFormatoA4: EventEmitter<void> = new EventEmitter<void>();
  @Output() onImprimirFormatoA5: EventEmitter<void> = new EventEmitter<void>();
  @Output() onImprimirFormatoCupomFiscal: EventEmitter<void> = new EventEmitter<void>();
  @Output() onImprimirFormatoEtiqueta: EventEmitter<void> = new EventEmitter<void>();

  @Output() onAbriuModalConfiguracao: EventEmitter<void> = new EventEmitter<void>();
  @Output() onFechouModalAlteracao: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild(UiModalComponent) modalTipoImpressao;

  tabImpressaoAlternativa: boolean = false;

  getTipoImpressao(codigoDocumento: string): ListaFixaImpressaoModel{
    let listaDocumentoImpressoes = ConstantsService.ParametrizacoesEnvioImpressora;
    let indexDocumentoAtual = listaDocumentoImpressoes.findIndex(f => f.codigoDocumentoImpresso == codigoDocumento
      && f.idPdv == ConstantsService.IdPdv);
      if (indexDocumentoAtual > -1) {
        return listaDocumentoImpressoes[indexDocumentoAtual];
      }
      return null!;
  }
  nomeImpressora: string = '';
  nomeImpressao: string = '';
  quantidadeImpressao: number = 1;

  permiteImprimirA4: boolean = false;
  permiteImprimirCupomFiscal: boolean = false;
  permiteImprimirA5: boolean = false;
  permiteImprimirEtiqueta: boolean = false;
  formatoImpressaoPadrao: string = '';

  tipoFormatoDisponivel: string = '';
  url: string = '';
  textoAlertaMensagemImpressaoAlternativa: string = '';

  constructor(
    private pdfService: PdfService) { }

  //ngOnInit(): void {}

  // imprimir(fileData: any, arg1: string) {
  //   throw new Error('Method not implemented.');
  // }

  ngOnInit(): void {
    let listaDocumentoImpressoes = ConstantsService.ParametrizacoesEnvioImpressora;

    if (listaDocumentoImpressoes == null) {
      swal.fire({
        title: `Erro na Impressão`,
        html: `Não foi encontrado a lista de impressões. Saia da aplicação e entre novamente.<br>Se o problema ocorrer novamente contate nosso suporte!`,
        icon: 'error',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return;
    }

    this.items = [];


    let indexDocumentoAtual = listaDocumentoImpressoes.findIndex(f => f.codigoDocumentoImpresso == this.codigoDocumento//'05' ---JANDER
      && f.idPdv == ConstantsService.IdPdv);
    if (indexDocumentoAtual > -1) {
      this.atualizarParametrosImpressao(listaDocumentoImpressoes[indexDocumentoAtual]);
      this.definirFormatosDisponiveis(listaDocumentoImpressoes[indexDocumentoAtual].tipoFormatoDisponivel);

      if (this.tipoFormatoDisponivel == 'A') {
        this.items.push({ label: 'Imprimir A4', icon: 'fad fa-file-alt', command: () => this.imprimirA4(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'B') {
        this.items.push({ label: 'Imprimir A5', icon: 'fad fa-file-alt', command: () => this.imprimirA5(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'C') {
        this.items.push({ label: 'Etiqueta', icon: 'fad fa-tag', command: () => this.imprimirEtiqueta(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'D') {
        this.items.push({ label: 'Cupom Fiscal', icon: 'fad fa-receipt', command: () => this.imprimirCupomFiscal(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'E') {
        this.items.push({ label: 'Imprimir A4', icon: 'fad fa-file-alt', command: () => this.imprimirA4(this.codigoDocumento) });
        this.items.push({ label: 'Imprimir A5', icon: 'fad fa-file-alt', command: () => this.imprimirA5(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'F') {
        this.items.push({ label: 'Imprimir A4', icon: 'fad fa-file-alt', command: () => this.imprimirA4(this.codigoDocumento) });
        this.items.push({ label: 'Imprimir A5', icon: 'fad fa-file-alt', command: () => this.imprimirA5(this.codigoDocumento) });
        this.items.push({ label: 'Cupom Fiscal', icon: 'fad fa-receipt', command: () => this.imprimirCupomFiscal(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'G') {
        this.items.push({ label: 'Imprimir A4', icon: 'fad fa-file-alt', command: () => this.imprimirA4(this.codigoDocumento) });
        this.items.push({ label: 'Cupom Fiscal', icon: 'fad fa-receipt', command: () => this.imprimirCupomFiscal(this.codigoDocumento) });
      }
      if (this.tipoFormatoDisponivel == 'H') {
        this.items.push({ label: 'Imprimir A4', icon: 'fad fa-file-alt', command: () => this.imprimirA4(this.codigoDocumento) });
        this.items.push({ label: 'Imprimir A5', icon: 'fad fa-file-alt', command: () => this.imprimirA5(this.codigoDocumento) });
        this.items.push({ label: 'Cupom Fiscal', icon: 'fad fa-receipt', command: () => this.imprimirCupomFiscal(this.codigoDocumento) });
      }
      this.items.push({ label: 'Configurar', icon: 'fad fa-cog', command: () => this.configurar(this.codigoDocumento) });
    } else {
      swal.fire({
        title: `Erro na Impressão`,
        html: `Não foi encontrado o documento ${this.codigoDocumento}`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
    }
  }

  definirFormatosDisponiveis(tipoFormatoDisponivel: string){
    if (tipoFormatoDisponivel == 'A') {
      this.permiteImprimirA4 = true;
    }
    if (tipoFormatoDisponivel == 'B') {
      this.permiteImprimirA5 = true;
    }
    if (tipoFormatoDisponivel == 'C') {
      this.permiteImprimirEtiqueta = true;
    }
    if (tipoFormatoDisponivel == 'D') {
      this.permiteImprimirCupomFiscal = true;
    }
    if (tipoFormatoDisponivel == 'E') {
      this.permiteImprimirA4 = true;
      this.permiteImprimirA5 = true;
    }
    if (tipoFormatoDisponivel == 'F') {
      this.permiteImprimirA4 = true;
      this.permiteImprimirA5 = true;
      this.permiteImprimirCupomFiscal = true;
    }
    if (tipoFormatoDisponivel == 'G') {
      this.permiteImprimirA4 = true;
      this.permiteImprimirCupomFiscal = true;
    }
    if (tipoFormatoDisponivel == 'H') {
      this.permiteImprimirA5 = true;
      this.permiteImprimirCupomFiscal = true;
    }
  }

  private atualizarParametrosImpressao(itemDocumentoImpressoes: ListaFixaImpressaoModel) {
    this.nomeImpressao = itemDocumentoImpressoes.documentoImpresso;
    this.quantidadeImpressao = itemDocumentoImpressoes.quantidadeImpressao ?? 1;
    this.url = itemDocumentoImpressoes.url;
    this.tipoFormatoDisponivel = itemDocumentoImpressoes.tipoFormatoDisponivel;
    this.formatoImpressaoPadrao = itemDocumentoImpressoes.formato;
    this.permiteImprimirA4 = false;
    this.permiteImprimirCupomFiscal = false;
    this.permiteImprimirA5 = false;
    this.permiteImprimirEtiqueta = false;
    this.nomeImpressao = itemDocumentoImpressoes.documentoImpresso;
    this.nomeImpressora = itemDocumentoImpressoes.nomeImpressora;
  }

  cliqueCancelar() {
    this.fecharModal();
  }
  fecharModal() {
    this.onFechouModalAlteracao.emit();
    this.modalTipoImpressao.hide();
  }

  onClick() {
    // Emite o evento quando o botão for clicado
    switch (this.formatoImpressaoPadrao) {
      case enumFormatoImpressaoPadrao.FormatoA4:
        this.onImprimirFormatoA4.emit();
        break;
      case enumFormatoImpressaoPadrao.FormatoA5:
        this.onImprimirFormatoA5.emit();
        break;
      case enumFormatoImpressaoPadrao.FormatoEtiqueta:
        this.onImprimirFormatoEtiqueta.emit();
        break;
      case enumFormatoImpressaoPadrao.FormatoCupomFiscal:
        this.onImprimirFormatoCupomFiscal.emit();
        break;
    }

  }

  atualizarImpressao(event:ListaFixaImpressaoModel){
    let listaDocumentoImpressoes = ConstantsService.ParametrizacoesEnvioImpressora;
    let indexDocumentoAtual = listaDocumentoImpressoes.findIndex(f => f.codigoDocumentoImpresso == this.codigoDocumento //'05' ---JANDER
    && f.idPdv == ConstantsService.IdPdv);
    if (indexDocumentoAtual > -1) {
      event = event as ListaFixaImpressaoModel;
      event.tipoFormatoDisponivel = this.getTipoImpressao(this.codigoDocumento).tipoFormatoDisponivel;
      listaDocumentoImpressoes[indexDocumentoAtual] = event;
      ConstantsService.ParametrizacoesEnvioImpressora = listaDocumentoImpressoes;
      this.atualizarParametrosImpressao(listaDocumentoImpressoes[indexDocumentoAtual]);
    }
  }

  configurar(codigoDocumento: string){
    this.codigoDocumento = codigoDocumento;
    this.onAbriuModalConfiguracao.emit();
    this.modalCrudParametrizacaoEnvioImpressoraComponent.abrirModal(this.getTipoImpressao(codigoDocumento));
  }
  imprimirA4(codigoDocumento: string) {
    this.onImprimirFormatoA4.emit();
  }
  imprimirA5(codigoDocumento: string) {
    this.onImprimirFormatoA5.emit();
  }
  imprimirEtiqueta(codigoDocumento: string) {
    this.onImprimirFormatoEtiqueta.emit();
  }
  imprimirCupomFiscal(codigoDocumento: string) {
    this.onImprimirFormatoCupomFiscal.emit();
  }


  fileData: any = null!;
  type: any = null!;
  subsPingServer: Subscription = null!;
  // Este método será chamado pelo componente pai
  imprimir(codigoDocumento: string, fileData: any, type) {
    this.fileData = fileData;
    this.type = type;
    let itemDocumentoImpressoes = this.getTipoImpressao(codigoDocumento);
    this.atualizarParametrosImpressao(itemDocumentoImpressoes);
    this.definirFormatosDisponiveis(itemDocumentoImpressoes.tipoFormatoDisponivel);
    switch (itemDocumentoImpressoes.tipoImpressao) {
      case enumTipoImpressao.Direta:

        if (this.url == null || this.url == '') {
          this.textoAlertaMensagemImpressaoAlternativa = 'A url da impressão direta não foi informada. Acesse configurações de impressão na página de configurações para maiores informações';
          this.tabImpressaoAlternativa = true;
          this.modalTipoImpressao.show();
          return;
        }
        if (this.nomeImpressora == null || this.nomeImpressora == '') {
          this.textoAlertaMensagemImpressaoAlternativa = 'O nome da impressora não foi informada. Acesse configurações de impressão na página de configurações';
          this.tabImpressaoAlternativa = true;
          this.modalTipoImpressao.show();
          return;
        }
        
          this.subsPingServer = this.pdfService.pingServer(this.url).subscribe(
            (retorno: boolean) => {
              this.subsPingServer?.unsubscribe();
              
              if (retorno) {
                let quantidadeImpressao = this.quantidadeImpressao;
                while (quantidadeImpressao > 0) {
                  quantidadeImpressao -= 1;
                  const reader = new FileReader();
                  reader.onload = () => {
                    const base64data = reader.result as string;
                    this.pdfService.printPdf(this.url, base64data.split(',')[1], this.nomeImpressora).subscribe(response => {
                      console.log(response);
                    });
                  };
                  reader.readAsDataURL(fileData);
                }
              } else {
                this.textoAlertaMensagemImpressaoAlternativa = 'O serviço de impressão direta não está ativo ou não foi instalado. Contate o suporte ou acesse configurações de impressão na página de configurações para maiores informações';
                this.tabImpressaoAlternativa = true;
                if (this.permiteImprimirA4){
                  this.formatoImpressaoPadrao = enumFormatoImpressaoPadrao.FormatoA4;
                }else if (this.permiteImprimirA5){
                  this.formatoImpressaoPadrao = enumFormatoImpressaoPadrao.FormatoA5;
                }else if (this.permiteImprimirCupomFiscal){
                  this.formatoImpressaoPadrao = enumFormatoImpressaoPadrao.FormatoCupomFiscal;
                }
                else if (this.permiteImprimirEtiqueta){
                  this.formatoImpressaoPadrao = enumFormatoImpressaoPadrao.FormatoEtiqueta;
                }
                this.modalTipoImpressao.show();
              }
            },
            (err) => {
              this.subsPingServer?.unsubscribe();
              this.textoAlertaMensagemImpressaoAlternativa = 'O serviço de impressão direta não está ativo ou não foi instalado. Contate o suporte ou acesse configurações de impressão na página de configurações para maiores informações';
              this.tabImpressaoAlternativa = true;
              this.modalTipoImpressao.show();
            }
          );
          

       
        
        break;
      case enumTipoImpressao.Download:
        this.realizarDownload(fileData, type);
        break;
      case enumTipoImpressao.Preview:
        this.visualizarImpressao(fileData, type);

        break;
    }



  }

  visualizarImpressao(fileData: any, type: any) {
    const blob: any = new Blob([fileData], { type: type });
    var pdfUrl = URL.createObjectURL(blob);
    printJS(pdfUrl);
  }

  realizarDownload(fileData: any, type: any) {
    var link = document.createElement('a');
    link.href = window.URL.createObjectURL(fileData);
    let fileExtension = this.getFileExtensionFromMimeType(type);
    link.download = this.formatarNomeImpressao(this.nomeImpressao, fileExtension ?? '.txt');
    link.click();
  }

  getFileExtensionFromMimeType(mimeType: string): string | null {
    // Mapeia os tipos MIME para extensões de arquivos comuns
    const mimeTypesMap: { [key: string]: string } = {
      'application/pdf': 'pdf',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'text/plain': 'txt',
      'text/xml': 'xml',
      'application/xml': 'xml',
      'application/zip': 'zip',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/vnd.oasis.opendocument.text': 'odt',
      'application/vnd.oasis.opendocument.spreadsheet': 'ods',
      // Adicione outros tipos MIME conforme necessário
    };

    // Retorna a extensão correspondente ao MIME type, ou null se não for encontrado
    return mimeTypesMap[mimeType] || null;
  }
  getFileExtensionFromBlob(blob: Blob): string | null {
    // Verifica o tipo MIME do blob
    const mimeType = blob.type;

    // Mapeia os tipos MIME para extensões de arquivo comuns
    const mimeTypesMap: { [key: string]: string } = {
      'application/pdf': 'pdf',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'application/msword': 'doc',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
      'application/vnd.ms-excel': 'xls',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
      'application/zip': 'zip',
      'text/plain': 'txt',
      // Adicione mais mapeamentos conforme necessário
    };

    // Retorna a extensão correspondente ao MIME type, ou null se não encontrar
    return mimeTypesMap[mimeType] || null;
  }
  formatarNomeImpressao(nomeImpressao: string, extensao: string): string {
    // Obter a data atual no formato yyyy-MM-dd-HHmmss
    const dataAtual = new Date();
    const ano = dataAtual.getFullYear();
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Mês começa em 0, então adicionamos 1
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const horas = String(dataAtual.getHours()).padStart(2, '0');
    const minutos = String(dataAtual.getMinutes()).padStart(2, '0');
    const segundos = String(dataAtual.getSeconds()).padStart(2, '0');

    const dataFormatada = `${ano}-${mes}-${dia}-${horas}${minutos}${segundos}`;

    // Tratar o nome da impressão
    let nomeTratado = nomeImpressao
      .normalize('NFD')                    // Normaliza os acentos
      .replace(/[\u0300-\u036f]/g, '')     // Remove os acentos
      .toLowerCase()                       // Converte para minúsculas
      .replace(/[^a-z0-9 ]/g, '')          // Remove caracteres especiais, mantendo letras e números
      .trim()                              // Remove espaços em branco no início e no final
      .replace(/\s+/g, '-');               // Substitui espaços por hífens

    // Concatena a extensão ao nome tratado
    return `${dataFormatada}-${nomeTratado}.${extensao}`;
  }

}