import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cep' })
export class CepPipe implements PipeTransform {
    transform(value: string|number): string {
        if (value == null || value.toString().length == 0)
        {
            return '';
        }
        else if (value.toString().length == 8) //
        {
            return value.toString().substring(0,5) + '-' + value.toString().substring(5,8);
        }else
        {
            return value.toString();
        }
    }
}
