import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "src/app/app-settings";
import { CommunicationManager } from "src/app/core/communication-manager";
import { CustomFormatoImpressao } from "src/app/models/relatorios/CustomFormatoImpressao";
import { AuthService } from "src/app/theme/shared/auth/auth.service";
import { AppMensagemService } from "src/app/theme/shared/components/app-mensagem/app-mensagem.service";

@Injectable({
    providedIn: 'root'
})
export class ApiGeraRelatorioPedidoVendaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Relatorio;
    private version = 'api/'
    private controller = 'geraRelatorioPedidoVenda';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    imprimirExtratoPedidoVenda(inputDados: CustomFormatoImpressao, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/imprimirExtratoPedidoVenda`, inputDados, exibeCarregando);
    }
}