import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiAuxiliarConsultaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.NotaFiscal;
    private version = 'api/'
    private controller = 'auxiliarConsulta';
    private mock = false;

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }
  
    getEnderecoByCep(cep: string, exibeCarregando: boolean){
        cep = encodeURIComponent(cep);
        return this.gc.get(this.api, `${this.version}${this.controller}/getEnderecoByCep?cep=${cep}`, exibeCarregando);
    }

    getEmpresaByCnpj(cnpj: string, exibeCarregando: boolean){
        cnpj = encodeURIComponent(cnpj);
        return this.gc.get(this.api, `${this.version}${this.controller}/getEmpresaByCnpj?cnpj=${cnpj}`, exibeCarregando);
    }
}