import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { ProfissionalModel } from 'src/app/models/cliente/Profissional/profissionalModel';

@Injectable({
  providedIn: 'root'
})
export class ApiProfissionalService {
  unsubscribe: any;

  getById(idProfissional: string): Observable<ProfissionalModel> {
    return this.gc.get(this.api, `api/${this.controller}/${idProfissional}`);
}
private gc: CommunicationManager;
private api = AppSettings.API.Cliente;
private version = 'api/';
private controller = 'profissional';

constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
}

  getAll(exibeCarregando: boolean): Observable<any> {
      return this.gc.get(this.api, `${this.version}${this.controller}`, exibeCarregando);
  }

  get(cpfCnpj: string){
      return this.gc.get(this.api, `${this.version}${this.controller}/${cpfCnpj}`, true)
  }

  post(obj: ProfissionalModel, exibirCarregando: boolean){
    return this.gc.post(this.api, `${this.version}${this.controller}`, obj, exibirCarregando);
  }

  put(obj: ProfissionalModel, exibirCarregando: boolean){
      return this.gc.put(this.api, `${this.version}${this.controller}`, obj, exibirCarregando);
  }

  delete(cpfCnpj: string){
      return this.gc.delete(this.api, `${this.version}${this.controller}/${cpfCnpj}`);
  }
}