import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { CnpjModel } from 'src/app/models/auxiliares/cnpj/CnpjModel';
import { ApiPessoaFisicaJuridicaService } from 'src/app/services/cliente/api-pessoa-fisica-juridica.service';
import { ModalCrudPesquisaConsultaCnpjComponent } from '../modal-crud-pesquisa-consulta-cnpj/modal-crud-pesquisa-consulta-cnpj.component';

@Component({
  selector: 'app-text-box-cnpj',
  templateUrl: './text-box-cnpj.component.html',
  styleUrls: ['./text-box-cnpj.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextBoxCnpjComponent),
    multi: true
  }]
})
export class TextBoxCnpjComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subService: Subscription | null = null;
  public maskCnpj = AppSettings.MASK.maskCnpj;

  @Input() modalFrontLayer: boolean = false;
  @Input() disabled: boolean = false;
  @Input() idCampo: string = 'cnpj';
  @Input() toolTip: string = 'Informe o CNPJ';
  @Input() label: string = 'Cnpj';
  @ViewChild(ModalCrudPesquisaConsultaCnpjComponent, { static: false }) modalCrudPesquisaConsultaCnpjComponent!: ModalCrudPesquisaConsultaCnpjComponent;
  @Output() importarDadosBaseExterna: EventEmitter<CnpjModel> = new EventEmitter<CnpjModel>();

  public inputDados: string = ""; // this is the updated value that the class accesses

  constructor(private cadastroComercialService: ApiPessoaFisicaJuridicaService
    , private showMessageError: ShowMessageError) {
  }

  ngOnInit() {
    this.value = '';
  }

  ngOnDestroy() {
    this.subService?.unsubscribe();
  }

  modalEmpresaCnpj(){
    this.modalCrudPesquisaConsultaCnpjComponent.abrirModal(this.value, true);
  }

  fcImportarDadosBaseExterna(dadosEmpresa: CnpjModel){
    this.value = dadosEmpresa.cpf_cnpj;
    this.importarDadosBaseExterna.emit(dadosEmpresa);
  }

 
  writeValue(obj: any): void {
    this.inputDados = obj;
    if (this.inputDados != undefined && this.inputDados != '') {
      this.inputDados = obj;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.inputDados = val
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.inputDados;
  }

}