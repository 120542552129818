export interface AbrirFecharCaixaModel {
    valorTotalEntrada: number | null;
    valorTotalSaida: number | null;
    valorSangriaFinal: number | null;
    idCaixaControle: number;
    idCaixa: number | null;
    idPdv: number | null;
    observacao: string;
}

export class AbrirFecharCaixaModelInstance {
    public static get(): AbrirFecharCaixaModel {
        return {
            valorTotalEntrada: null,
            valorTotalSaida: null,
            valorSangriaFinal: null,
            idCaixaControle: 0,
            idCaixa: 0,
            idPdv: 0,
            observacao: ''
        };
    }
}