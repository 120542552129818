import swal from 'sweetalert2';
import { Injectable } from '@angular/core';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { EnumTypeError } from 'src/app/enum/enumTypeError';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorResponseCustom } from 'src/app/models/error-response';
import { Router } from '@angular/router';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';


@Injectable({
    providedIn: 'root'
})
export class ShowMessageError {

    constructor(private router: Router, private readonly mensagemService: AppMensagemService,
        private appCustomToastService: AppCustomToastService,
        public auth: AuthService) { }

    limparMensagensAlerta(){
        //remove os elementos para inserir novamente
        let elements = document.getElementsByClassName("invalid-input");
        var labelElements = Array.prototype.filter.call(elements, function(el) {
            return el.nodeName.toUpperCase() === 'LABEL';
        });
        labelElements.map(function(label, i) {
            label.remove();
            //console.log('[forEach]', label, i);
        })

        elements = document.getElementsByClassName("is-invalid");
        var inputElements = Array.prototype.filter.call(elements, function(el) {
            return true;
        });
        inputElements.map(function(input, i) {
            input.className = input.className.replace(" is-invalid", "");
        })
    }

    procurarErroPorChave(err: any, chave: string){
        if (err.error != null && err.error.typeMessage == EnumTypeError.Validation) {
            const errors = err.error.messages;
            for (const fieldError in errors) {
                if (errors[fieldError]) {
                  if (errors[fieldError]['field'].toLowerCase() == chave.toLowerCase())
                  {
                    return true;
                  }
                }
            }
        } 
        return false;
        
    }

    exibirMensagemValidacaoTela(err: any) {
        if (err.error != null && err.error.typeMessage == EnumTypeError.Validation) {
            const errors = err.error.messages;
            this.exibirMensagens(err.error.message, errors);
        } else {
            this.exibirMensagemErro(err);
        }
    }

    marcarCampoParaPreenchimento(idLabel: string, mensagem: string){
        idLabel = idLabel[0].toLowerCase() + idLabel.substr(1);
        if(document.getElementById(idLabel) != null){
            
            //let br = document.createElement("br");
            //let e = document.getElementById("validacao" + idLabel);
            let ids = document.querySelectorAll("[id=validacao" + idLabel);
            ids.forEach(e => {
                if (e != null)
                e.remove();
            });
                
            //let campo = document.getElementById(idLabel);
            let labels = document.querySelectorAll("[id=" + idLabel);
            labels.forEach(campo => {
                if (campo) {
                    let labelValidacao = document.createElement("label");
                    labelValidacao.innerHTML =mensagem;
                    labelValidacao.id = "validacao" + idLabel;
                    labelValidacao.className = "invalid-input";
                    campo.className = campo.className.replace(" is-invalid", "");
                    campo.className += " is-invalid";
                    //campo.parentElement!.appendChild(br);
                    if (campo.parentElement!.className.indexOf("p-inputnumber p-component") > -1){
                        campo.parentElement!.parentElement!.parentElement!.appendChild(labelValidacao);
                    }else
                    if (campo.parentElement!.className.indexOf("p-calendar") > -1){
                        campo.parentElement!.parentElement!.parentElement!.appendChild(labelValidacao);
                    }else{
                        campo.parentElement!.appendChild(labelValidacao);
                    }
                    //campo.focus();
                }
            });
        }
    }

    getMessage(errors: any): string{
        let message = "";
        for (const fieldError in errors) {
            if (errors[fieldError]) {
                // if ((formData != null && formData.form != null)){
                    let input = errors[fieldError]['field'][0].toLowerCase() + errors[fieldError]['field'].substring(1);
                    // if (formData.form.controls[input] != undefined || document.getElementById(input) != null){
                    this.marcarCampoParaPreenchimento(input, errors[fieldError]['message']);
                // }
                message += errors[fieldError]['message'] + "\n";
            }
        }
        return message;
    }

    exibirMensagensCustomizadas(title: string, messages: string[], tipoMensagem:TiposMensagem) {
        this.limparMensagensAlerta();
        let message = "";
        messages.forEach(element => {
            message += `${element}</br>`;
        });
        swal.fire(title, message, tipoMensagem);
    }

    exibirMensagens(title: string, errors: any) {
        this.limparMensagensAlerta();
        let message = "";
        for (const fieldError in errors) {
            if (errors[fieldError]) {
                // if ((formData != null && formData.form != null)){
                    let input = errors[fieldError]['field'][0].toLowerCase() + errors[fieldError]['field'].substring(1);
                    // if (formData.form.controls[input] != undefined || document.getElementById(input) != null){
                    this.marcarCampoParaPreenchimento(input, errors[fieldError]['message']);
                // }
                message += errors[fieldError]['message'] + "</br>";
            }
        }
        if (title == 'Cadastro sem pendências')
        {
            swal.fire(title, message, TiposMensagem.sucesso);
        }else{
            swal.fire(title, message, TiposMensagem.aviso);
        }
    }

    private exibirMensagemErroServidor(erroServidor){
        var title: string = "";
        var message: string = "";
        //verifica se é erro de validação
        if (erroServidor.typeMessage == EnumTypeError.Validation) {
            title = erroServidor.message;
            erroServidor.messages.forEach(function (obj) {
                message += obj.message + "</br>";
                
            });
            for(var contador = 0; contador < erroServidor.messages.length; contador++){
                this.marcarCampoParaPreenchimento(erroServidor.messages[contador].field, erroServidor.messages[contador].message);
            }
            swal.fire(title, message, TiposMensagem.aviso);
        }
        else if (erroServidor.typeMessage == EnumTypeError.Success) // alert
        {
            title = "Success";
            message = erroServidor.message;
            swal.fire(title, message, TiposMensagem.sucesso);
        } else if (erroServidor.typeMessage == EnumTypeError.Alert) // alert
        {
            title = "Alerta";
            message = erroServidor.message;
            swal.fire(title, message, TiposMensagem.aviso);
        }
        else if (erroServidor.typeMessage == EnumTypeError.Error) // erro 
        {
            title = "Ops!!!";
            if (erroServidor.detail == null) erroServidor.detail = "";
            message = erroServidor.message + erroServidor.detail;
            var detail = "";
            let target = "";
            if (erroServidor.target != null){
                target = erroServidor.target;
            }
            let codigo = "";
            if (erroServidor.code != 0){
                codigo = erroServidor.code.toString();
            }
            detail = codigo + "-" + target + " " + erroServidor.detail + '</br>';
            if (detail != '' && detail != '-' && detail != '- </br>'){
                message += " " + detail;
            }
            swal.fire(
                {
                    html: message,
                    title: title,
                    showCancelButton: false,
                    icon: TiposMensagem.erro
                  });
        }
    }

    public exibirMensagemErro(error) {

        if (error != undefined && error.message != undefined && error.message.indexOf('api/file/download') > -1
            && error.status != undefined && error.status == 500){
                this.mensagemService.esconderLock();
                swal.fire({title: "Erro no acesso",html: 'Tempo de sessão expirado, por favor logue novamente',icon: TiposMensagem.erro ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
                    if (result.isConfirmed) {
                this.redirecionarParaTelaLogin();
                    }});
        }
        else if (error != undefined && error.error != undefined && error.error.toString().indexOf(' Tempo de sessão expirado, por favor logue novamente') > -1){

            swal.fire({title: "Erro no acesso",html: 'Tempo de sessão expirado, por favor logue novamente',icon: TiposMensagem.erro ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
                if (result.isConfirmed) {
                    console.log("redirect 2");
             this.redirecionarParaTelaLogin();
                }});
        } else if (error.typeMessage != undefined && error.messages != undefined && error.message != undefined) {
            this.exibirMensagemErroServidor(error);
            return;
        }else 
        if (error instanceof HttpErrorResponse) {
            if (error.error !== null && Object.prototype.hasOwnProperty.call(error.error, 'message')) {
                const erroServidor: ErrorResponseCustom = error.error;
                if (erroServidor.typeMessage === undefined) {
                    let codigo: string = '';
                    if (erroServidor.code != undefined)
                    {
                        codigo = erroServidor.code.toString();
                    }
                    swal.fire('Erro', codigo + ' ' + erroServidor.message, TiposMensagem.erro);
                } else {
                    this.exibirMensagemErroServidor(erroServidor);
                }
            } else if (error.status === 400) {
                const erroServidorObj: any = error.error;
                if (error.error.InnerException) {
                    swal.fire(erroServidorObj.title, JSON.stringify(error.error.InnerException), TiposMensagem.erro);
                } else {
                    if (error.error.errors) {
                        swal.fire(erroServidorObj.title, JSON.stringify(error.error.errors), TiposMensagem.erro);
                    }
                    else {
                        swal.fire(erroServidorObj.title, JSON.stringify(error.error), TiposMensagem.erro);
                    }
                }

            } else if (error.status === 401 || error.status === 0 ||
                error.status === 404) {
                const mensagem = error.status === 404 ? 'Serviço Indisponível' :
                    error.status === 401 ? 'Não Possui Autorização.' :
                        error.status === 0 ? 'Serviço Indisponível' :
                            error.message;
                /* aqui exibir mensagem mais amigavel*/
                if (mensagem == 'Serviço Indisponível' ||
                mensagem == 'Servidor indisponível após múltiplas tentativas'
                ){
                    this.appCustomToastService.toast(TiposMensagem.erro, [mensagem], "Falha");
                }else{
                    swal.fire('A aplicação apresentou um erro', mensagem, TiposMensagem.erro);
                }
                if (error.status === 401){
                    this.redirecionarParaTelaLogin();
                }
            }
            else if (error.error instanceof InstanceString)
            {
                swal.fire('Erro na aplicação', error.error + '', TiposMensagem.erro);
            } else if (error.message) {
                if (error.message.indexOf(": 403") > -1 || error.message.indexOf(": 405") > -1){
                    swal.fire({
                        title: "Permissão negada",
                        html: 'Usuário sem acesso a esta funcionalidade!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            //this.redirecionarParaTelaLogin();
                        }
                      });
                }else{
                    if (error.message.indexOf("Tempo de sessão expirado, por favor logue novamente") > -1){
                        swal.fire({title: "Erro no acesso",html: 'Tempo de sessão expirado, por favor logue novamente',icon: TiposMensagem.erro ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
                            if (result.isConfirmed) {
                                console.log("redirect 4");
                                this.redirecionarParaTelaLogin();
                            }});
                    }else{
                        console.log("redirect 6");
                        if (error.error.indexOf("Tempo de sessão expirado, por favor logue novamente") > -1){
                            swal.fire({title: "Erro no acesso",html: 'Tempo de sessão expirado, por favor logue novamente',icon: TiposMensagem.erro ,showCancelButton: false,confirmButtonText: 'Ok'}).then((result) => {
                                if (result.isConfirmed) {
                                    console.log("redirect 4");
                                    this.redirecionarParaTelaLogin();
                                }});
                        }   else{
                            swal.fire('Erro na aplicação', error.message + error.error, TiposMensagem.erro);
                        }
                    }
                }
            } else if (error.error.error_description) {
                swal.fire(error.error.error, error.error.error_description, TiposMensagem.erro);
            } else {
                const mensagem = 'Ocorreu um erro';
                swal.fire('A aplicação apresentou um erro', mensagem, TiposMensagem.erro);
            }
        } else {
            if (error.rejection != null) {
                if (error.rejection.statusCode === 401) {
                    swal.fire({
                        title: "Permissão negada",
                        html: 'Usuário sem acesso ou tempo de token expirado!',
                        icon: 'error',
                        showCancelButton: false,
                        confirmButtonText: 'Ok'
                      }).then((result) => {
                        if (result.isConfirmed) {
                            console.log("redirect 5");
                            this.redirecionarParaTelaLogin();
                        }
                      });
                    
                } else {
                    swal.fire('A aplicação apresentou um erro', error.rejection.message + error.message, TiposMensagem.erro);
                }
            } else {
                if (error != null && error.typeMessage == EnumTypeError.Validation) {
                    const errors = error.messages;
                    this.exibirMensagens(error.message, errors);
                } else {

                    if (typeof error === 'string'){
                        if (error == 'Serviço Indisponível' ||
                        error == 'Servidor indisponível após múltiplas tentativas'
                        ){
                            this.appCustomToastService.toast(TiposMensagem.erro, [error], "Falha");
                        }else{
                            swal.fire('A aplicação apresentou um erro', error, TiposMensagem.erro);
                        }
                    }else{
                        swal.fire('Erro', error.message || error, TiposMensagem.erro);
                    }
                }
            }
        }
    }

    redirecionarParaTelaLogin(){

        //this.router.navigate(['/login'], {fragment: 'naoAutorizado'});
        //this.auth.removeToken();
         ConstantsService.removeToken();

        const url = this.router.serializeUrl(
          this.router.createUrlTree(['/login'])
        );
        window.open(url, '_self');
    }
}
class InstanceString {
    private noLiterals: undefined;
    constructor(public name: string) { }
}