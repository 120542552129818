// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  versaoAplicativo: '1.0.0.1',
  production: true,
  gravarLog: false,
  autenticar: true,
  funcionalidadeEstoqueDisponivel: false,
  funcionalidadeEntregaDisponivel: true,
  funcionalidadeConsultarProdutosDisponivel: false,
  funcionalidadePedidoVendaDisponivel: true,
  urlRedirectRetaguarda: 'https://erp.invare.com.br/redirect/page/',
  urlRetaguarda: 'https://erp.invare.com.br/',
  apiSettings: {
    baseUrl:{
      webApiNotaFiscal: 'https://erps01.invare.com.br/notafiscal',
      //webApiNotaFiscal: 'https://localhost:44395',
      webApiClie: 'https://erps01.invare.com.br/retaguarda',
      //webApiClie: 'https://localhost:44380',
      webApiFinanceiro: 'https://erps00.invare.com.br/financeiro',
      //webApiFinanceiro: 'https://localhost:44389',
      webApiDomn: 'https://erps01.invare.com.br/domn',
      //webApiDomn: 'https://localhost:44384',
      webApiPdv: 'https://erps01.invare.com.br/pdv',
      //webApiPdv: 'https://localhost:44397',
      webApiCtrl: 'https://erps01.invare.com.br/ctrl',
      //webApiCtrl: 'https://localhost:44323',
      webApiConfig: 'https://localhost',
      file: 'https://erps01.invare.com.br/file',
      //file: 'https://localhost:44390',
      webApiRelatorio: 'https://erps00.invare.com.br/relatorio',
      //webApiRelatorio: 'https://localhost:44398',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
