import { Injectable } from '@angular/core';
import { MensagemModal } from 'src/app/models/mensagem-modal';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ToastConfig } from 'src/app/models/toast-config';
import { AppCustomToastComponent } from './app-custom-toast.component';

@Injectable()
export class AppCustomToastService {
  appcustomToast!: AppCustomToastComponent;
  limparToast = true;

  constructor() {}

  toast(
    tipo : TiposMensagem,
    mensagem : string[],
    titulo? : string | 'Sucesso!',
    duracao? : number | 5000,
    limpar?: boolean | true,

  ) {
    let toastConfig: ToastConfig = {
      tipo : tipo,
      titulo : titulo!,
      mensagem : mensagem,
      duracao : duracao,
      limpar : limpar
    }
    if (this.appcustomToast instanceof AppCustomToastComponent) {
      this.appcustomToast.exibirToast(toastConfig);
    }
  }

  setAppCustomToast(appCustomToast: AppCustomToastComponent){
    this.appcustomToast = appCustomToast;
  }

  limparMensagens() {
    if (this.appcustomToast instanceof AppCustomToastComponent) {
      if (this.limparToast) {
        this.appcustomToast.limparToasts();
      }
    }
  }
}
