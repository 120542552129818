import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumStatus } from 'src/app/enum/enumStatus';
import { OrcamentoModel } from 'src/app/models/cliente/Orcamento/orcamentoModel';
import { PdvModel } from 'src/app/models/cliente/Pdv/pdvModel';
import { PedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/PedidoVendumModel';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiPdvService {
    
    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private controller = 'pdv';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getById(idCaixa: number, exibeCarregando: boolean): Observable<PdvModel> {
        return this.gc.get(this.api, `api/${this.controller}/${idCaixa}`, exibeCarregando);
    }

    getAllByStatus(status: enumStatus, exibeCarregando: boolean ): Observable<PdvModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getAllByStatus/${status}`, exibeCarregando);
    }

    alterarTabelaPrecoNaVenda(idVenda: number, idTabelaPreco: number, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.get(this.api, `api/${this.controller}/alterarTabelaPrecoNaVenda/${idVenda}/${idTabelaPreco}`, exibeCarregando);
    }

    alterarTabelaPrecoNoPedidoVenda(idPedidoVenda: number, idTabelaPreco: number, exibeCarregando: boolean ): Observable<PedidoVendumModel> {
        return this.gc.get(this.api, `api/${this.controller}/alterarTabelaPrecoNoPedidoVenda/${idPedidoVenda}/${idTabelaPreco}`, exibeCarregando);
    }

    alterarTabelaPrecoNoOrcamento(idOrcamento: number, idTabelaPreco: number, exibeCarregando: boolean ): Observable<OrcamentoModel> {
        return this.gc.get(this.api, `api/${this.controller}/alterarTabelaPrecoNoOrcamento/${idOrcamento}/${idTabelaPreco}`, exibeCarregando);
    }

    getAllPessoaFisicaJuridicaByCnpjRazaoSocialNomeFantasiaCpfNome(filtro: string, codigoCliente: string, exibeCarregando: boolean ): Observable<PessoaFisicaJuridicaModel[]> {
        filtro = encodeURIComponent(filtro);
        return this.gc.get(this.api, `api/${this.controller}/getAllPessoaFisicaJuridicaByCnpjRazaoSocialNomeFantasiaCpfNome?filtro=${filtro}&codigoCliente=${codigoCliente}`, exibeCarregando);
    }

}