import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { IOption } from 'ng-select';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumStatusVenda } from 'src/app/enum/enumStatusVenda';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { ConstantsService } from '../../constants/constants.service';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { AppSettings } from 'src/app/app-settings';
import { LoginModelInstance } from 'src/app/models/controle/Autenticacao/LoginModelInstance';
import { TokenModel } from 'src/app/models/controle/Autenticacao/TokenModel';
import { LoginModel } from 'src/app/models/controle/Autenticacao/loginModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { ParametrosCancelarVendaModel } from 'src/app/models/cliente/Vendum/ParametrosCancelarVendaModel';
import { ParametrosCancelarVendaModelInstance } from 'src/app/models/cliente/Vendum/ParametrosCancelarVendaModelInstance';
import { ModalCancelarVendaComponent } from '../modal-cancelar-venda/modal-cancelar-venda.component';

@Component({
  selector: 'app-modal-vendas-pendentes',
  templateUrl: './modal-vendas-pendentes.component.html',
  styleUrls: ['./modal-vendas-pendentes.component.css']
})
export class ModalVendasPendentesComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() selecionouVenda: EventEmitter<number> = new EventEmitter();
  @Output() cancelouVenda: EventEmitter<VendumModel> = new EventEmitter();
  @ViewChild(ModalCancelarVendaComponent, { static: false }) modalCancelarVendaComponent!: ModalCancelarVendaComponent;
  inputDados: VendumModel = null!;
  parametrosCancelarVenda: ParametrosCancelarVendaModel = ParametrosCancelarVendaModelInstance.get();
  emptymessage: string = 'Não hà vendas pendentes para este PDV';
  loading: boolean = false;
  
  itensCodigoRegimeTributario: Array<IOption> | null = null;
  itensIndicadorInscricaoEstadual: Array<IOption> | null = null;

  tipoVendaUnica: enumTipoProduto = enumTipoProduto.Todos;
  tipoVendaProduto: enumTipoProduto = enumTipoProduto.Produto;
  tipoVendaServico: enumTipoProduto = enumTipoProduto.Servico;

  constructor(private showMessageError: ShowMessageError,
    private appCustomToastService: AppCustomToastService,
    private apiVendaService: ApiVendaService,
    private auth: AuthService,
    private router: Router) { 
      this.getScreenSize();
  }

  ngOnInit(): void {
    
  }

  get heightListaVendasPendentes(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  vendasPendentes: VendumModel[] =[];
  subGetAllByStatusEPdv: Subscription = null!;
  consultarVendasPendentes() {
    this.loading = true;
    this.subGetAllByStatusEPdv = this.apiVendaService.getAllVendasEmAndamento(ConstantsService.IdPdv, false).subscribe(
      (retorno: Array<VendumModel>) =>{
        if (retorno != undefined) {
          if (retorno.length == 0){
            this.vendasPendentes = retorno;
            this.loading = false;
            this.modal.show();
          }
          if (retorno.length > 0){
            this.vendasPendentes = retorno;
            this.loading = false;
            this.modal.show();
          }
        }
        this.subGetAllByStatusEPdv?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAllByStatusEPdv?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  //origem: enumOrigemAbrirModal = enumOrigemAbrirModal.Outros;
  selecionarVenda(venda: VendumModel){
    this.selecionouVenda.emit(venda.idVenda);
    this.fecharModal();    
  }

  
  navegarPaginaPagamento(idVenda: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/pdv/produto-pagamento/${idVenda}`])
    );
    window.open(url, '_self');
  }

  navegarPaginaProduto(idVenda: number) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/pdv/produto-pagamento/${idVenda}`])
    );
    window.open(url, '_self');
  }

  abrirModal(vendaAtual: VendumModel) {
    this.modal.show();
    this.inputDados = vendaAtual;
    this.consultarVendasPendentes();
    this.onFocusBotaoCancelar();
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  @ViewChild('elmBotaoCancelar') elmBotaoCancelar: ElementRef  = null!;
  onFocusBotaoCancelar(){
    setTimeout(()=>{ 
      if (this.elmBotaoCancelar != null){
        this.elmBotaoCancelar.nativeElement.focus();
      }
    },120);    
  }

  cliqueCancelar(){
    this.fecharModal();
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.modal.hide();
  }


  subsCancelarVenda: Subscription = null!;
  cliqueCancelarTodasVendas(){

    swal.fire({
      title: "Atenção",
      html: 'Tem certeza que deseja cancelar <b>todas vendas pendentes</b>!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.loading = true;

        let idsVendas = this.vendasPendentes.map((objeto: VendumModel) => {
          return objeto.idVenda
        });
        this.parametrosCancelarVenda.idsVenda = idsVendas;
        this.subsCancelarVenda = this.apiVendaService.cancelarVendas(this.parametrosCancelarVenda, '', true).subscribe(
          () =>{
            this.loading = false;
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Venda(s) canceladas(s)'], "Sucesso");
            this.cancelouVenda.emit(this.inputDados);
            this.fecharModal();
          }
          ,
          (err) => {
            if (err.status == 403)
            {
              this.loading = false;
              this.cancelarVendaComUsurioESenha();
            }else{
              this.loading = false;
              this.subsCancelarVenda?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
            
          });
      }
    });    
  }

  cancelarVenda(item: VendumModel){
    swal.fire({
      title: "Atenção",
      html: `Tem certeza que deseja cancelar a venda <b>${item.idVenda}</b>!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.isConfirmed) {
        this.modalCancelarVendaComponent.abrirModal(item);
      }
    });
  }

  retornoCancelamentoVenda(evt: any){
    this.consultarVendasPendentes();
  }

  usuario: string = '';
  senha: string = '';
  exibirLoginUsuarioESenha: boolean = false;
  cancelarVendaComUsurioESenha(){
    this.exibirLoginUsuarioESenha = true;
    this.onFocusUsuario();
  }

  
  @ViewChild('elmUsuario') elmUsuario: ElementRef  = null!;
  onFocusUsuario(){
    setTimeout(()=>{ 
      if (this.elmUsuario != null){
        this.elmUsuario.nativeElement.focus();
      }
    },60);    
  }

  subsCancelarVendaComUsuarioESenha: Subscription = null!;
  confirmarUsuarioESenha(){
    let sendLogin: LoginModel = LoginModelInstance.get();
    sendLogin.idAplicacao = AppSettings.ID_APLICACAO;
    sendLogin.emailCpf = this.usuario;
    sendLogin.senha =  this.senha;
    this.loading = true;
    this.auth.login(sendLogin).subscribe(
      (token : TokenModel) => {

        let idsVendas = this.vendasPendentes.map((objeto: VendumModel) => {
          return objeto.idVenda
        });
        this.parametrosCancelarVenda.idsVenda = idsVendas;
        this.subsCancelarVendaComUsuarioESenha = this.apiVendaService.cancelarVendas(this.parametrosCancelarVenda, token.token, true).subscribe(
          () =>{
            this.loading = false;
            this.subsCancelarVendaComUsuarioESenha?.unsubscribe();
            swal.fire({
              title: "Sucesso",
              text: 'Venda Cancelada!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                this.cancelouVenda.emit(this.inputDados);
                this.fecharModal();
              }
            });
          }
          ,
          (err) => {
            if (err.status == 403)
            {
              this.loading = false;
              //sem permissão, solicitar usuário e senha com permissão
              swal.fire('Aviso', "Usuário sem permissão para cancelar a venda", TiposMensagem.erro);
            }else{
              this.loading = false;
              this.subsCancelarVendaComUsuarioESenha?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
            
          });
      },
      (err) => {
        this.loading = false;
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  
  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13 && !this.exibirLoginUsuarioESenha) {
      this.cliqueCancelarTodasVendas();
    }
    if (event.keyCode == 13 && this.exibirLoginUsuarioESenha) {
      this.confirmarUsuarioESenha();
    }
  }

  onRowSelectItemVenda(item: any) {
    this.selecionarVenda(item.data);
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
  }

  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }
}