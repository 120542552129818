<app-ui-modal-loading #modalLoading [containerClick]="true" >
    <div class="app-modal-header " >
      <div class="row">
        <div class="col-sm-3 p-r-10" style="text-align:left;">
          <div class="spinner-border text-primary center" role="status">
            <span class="sr-only"></span> 
          </div>
        </div>
        <div class="col-sm-9">
          <h5 class="modal-title">&nbsp;Aguarde</h5>
        </div>
      </div>
    </div>
    <div class="app-modal-body">
      <p>{{mensagem}}</p>
    </div>
</app-ui-modal-loading>

