import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { OrcamentoProdutoModel } from 'src/app/models/cliente/OrcamentoProduto/orcamentoProdutoModel';
import { ProdutoModel } from 'src/app/models/cliente/Produto/produtoModel';
import { Subscription } from 'rxjs';
import { ApiProdutoService } from 'src/app/services/cliente/api-produto.service';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { ApiPrecoService } from 'src/app/services/cliente/api-preco.service';
import { PrecoModel } from 'src/app/models/cliente/Preco/precoModel';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { InputNumber } from 'primeng/inputnumber';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';

@Component({
  selector: 'app-modal-alterar-item-orcamento',
  templateUrl: './modal-alterar-item-orcamento.component.html',
  styleUrls: ['./modal-alterar-item-orcamento.component.css']
})
export class ModalAlterarItemOrcamentoComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() alterouItens: EventEmitter<OrcamentoProdutoModel> = new EventEmitter();
  inputDados: OrcamentoProdutoModel = null!;
  loadingListarItens: boolean = false;
  permiteVendaEntrega: boolean = false;
  alterarPrecoVenda: string = enumSimNao.Nao;
  permiteAlterarPrecoVenda: boolean = false;
  descricaoProdutoServico: string = '';

  permiteValorSeguro: string = enumSimNao.Sim;
  permiteDescProdServ: string = enumSimNao.Sim;

  unMedMetro: enumUnidadeComercial = enumUnidadeComercial.Metro;
  unMedMetroCubico: enumUnidadeComercial = enumUnidadeComercial.MetroCcbico;
  unMedMetroQuadrado: enumUnidadeComercial = enumUnidadeComercial.MetroQuadrado;

  constructor(
    private apiProdutoService: ApiProdutoService,
    private apiPrecoService: ApiPrecoService,
    private showMessageError: ShowMessageError
  ) { }

  ngOnInit(): void {

    if (ConstantsService.ParametrizacaoCadastro != null){
      if (ConstantsService.ParametrizacaoCadastro.permiteSeguroDeProduto != null){
        this.permiteValorSeguro = ConstantsService.ParametrizacaoCadastro.permiteSeguroDeProduto;
      }
      if (ConstantsService.ParametrizacaoCadastro.permiteDescontoNoProduto != null){
        this.permiteDescProdServ = ConstantsService.ParametrizacaoCadastro.permiteDescontoNoProduto;
      }
    }
  }

  precoUnitarioInicial: number = 0;
  abrirModal(item: OrcamentoProdutoModel) {
    this.permiteVendaEntrega = ConstantsService.ParametrizacaoPdv.permiteEntrega;
    this.alterarPrecoVenda = ConstantsService.ParametrizacaoPdv.permiteAlterarPrecoVenda;
    if (this.alterarPrecoVenda == 'S') {
      this.permiteAlterarPrecoVenda = true;
    }
    this.exibirDescontoMaximo = false;
    this.vDescontoMaximo = null;
    this.valorDesconto = 0;
    this.preco = null!;
    this.alterouItemComSucesso = false;
    this.inputDados = JSON.parse(JSON.stringify(item as OrcamentoProdutoModel));
    if (this.inputDados.dataPrevistaEntregaAgendada != null) this.inputDados.dataPrevistaEntregaAgendada = new Date(this.inputDados.dataPrevistaEntregaAgendada);
    this.inputDados.quantidadeEntregueNoAto = item.quantidade!;
    this.exibirCusto = false;
    this.showMargem = false;
    this.showTotais = false;    

    if (this.inputDados.idProduto.idProdutoUnicoNavigation.precos != null && this.inputDados.idProduto.idProdutoUnicoNavigation.precos.length == 1) {
      this.preco = this.inputDados.idProduto.idProdutoUnicoNavigation.precos[0];
      //preencher variavel de preço de venda de cadastro para poder comparar após a alteração
      this.precoUnitarioInicial = this.preco.precoVenda!;
      this.calcularCusto();
    }
    else {
      this.getPreco();
    }
    /*fim calculo de custo*/

    //tratar o nome do item selecionado
    if(this.inputDados.idProduto.idProdutoUnicoNavigation.gtin != '' && this.inputDados.idProduto.idProdutoUnicoNavigation.gtin != null && this.inputDados.idProduto.idProdutoUnicoNavigation.sku != '' && this.inputDados.idProduto.idProdutoUnicoNavigation.sku != null &&
    ConstantsService.DescricaoProdutoImprimeGtin == enumSimNao.Sim && ConstantsService.DescricaoProdutoImprimeSku == enumSimNao.Sim){
      this.descricaoProdutoServico = this.inputDados.idProduto.idProdutoUnicoNavigation.gtin +" - "+ this.inputDados.idProduto.idProdutoUnicoNavigation.sku +" - "+ this.inputDados.idProduto.idProdutoUnicoNavigation.nome;
    }else
    if(ConstantsService.DescricaoProdutoImprimeGtin == enumSimNao.Sim &&
    this.inputDados.idProduto.idProdutoUnicoNavigation.gtin != '' && this.inputDados.idProduto.idProdutoUnicoNavigation.gtin != null)
    {
    this.descricaoProdutoServico = this.inputDados.idProduto.idProdutoUnicoNavigation.gtin +" - "+ this.inputDados.idProduto.idProdutoUnicoNavigation.nome;
    }
    else
    if(ConstantsService.DescricaoProdutoImprimeSku == enumSimNao.Sim &&
    this.inputDados.idProduto.idProdutoUnicoNavigation.sku != '' && this.inputDados.idProduto.idProdutoUnicoNavigation.sku != null)
    {
    this.descricaoProdutoServico = this.inputDados.idProduto.idProdutoUnicoNavigation.sku +" - "+ this.inputDados.idProduto.idProdutoUnicoNavigation.nome;
    }      
    else{
    this.descricaoProdutoServico = this.inputDados.idProduto.idProdutoUnicoNavigation.nome;
    }
    //final tratamento do nome do item

    this.modal.show();
    this.onFocusQuantidade();
  }



  calcularCusto() {
    /*calculo de custo*/
    if (this.preco != null) {

      let totalComDesconto = ((this.inputDados.quantidade! * this.inputDados.precoUnitario!) + this.inputDados.valorOutrasDespesasAcessorias!
        + this.inputDados.valorTotalFrete!
        + this.inputDados.valorTotalSeguro!
        + this.inputDados.valorDesconto!);

      let totalSemDesconto = ((this.inputDados.quantidade! * this.inputDados.precoUnitario!) + this.inputDados.valorOutrasDespesasAcessorias!
        + this.inputDados.valorTotalFrete! + this.inputDados.valorTotalSeguro!);

      let totalCustoComDesconto = (this.inputDados.quantidade! * this.preco.precoMargemZero!) + this.inputDados.valorOutrasDespesasAcessorias!
        + this.inputDados.valorTotalFrete!
        + this.inputDados.valorTotalSeguro!
        + this.inputDados.valorDesconto!;

      let totalCustoSemDesconto = (this.inputDados.quantidade! * this.preco.precoMargemZero!) + this.inputDados.valorOutrasDespesasAcessorias!
        + this.inputDados.valorTotalFrete! + this.inputDados.valorTotalSeguro!;

      // (1 - (total do orçamento dividido Recuperar o total preço custo divido )) e multiplicar por 100 e multiplicar por -1 para gerar positivo
      this.totalMargemSobreCustoSemDesconto = ((totalSemDesconto - totalCustoSemDesconto) / totalCustoSemDesconto) * 100;
      if (this.preco.precoMargemZero != null && this.preco.precoMargemZero != 0) {
        this.vMargemSobreCustoSemDesconto = `${this.totalMargemSobreCustoSemDesconto.toFixed(2)} %`;
      } else {
        this.vMargemSobreCustoSemDesconto = "Não informado";
      }

      //(1 - (Recuperar o total considerando o pagamento divido pelo total preço custo)) e multiplicar por 100 e multiplicar por -1 para gerar positivo
      this.totalMargemSobreCustoComDesconto = ((totalComDesconto - totalCustoComDesconto) / totalCustoComDesconto) * 100;
      if (this.preco.precoMargemZero != null && this.preco.precoMargemZero != 0) {
        this.vMargemSobreCustoComDesconto = `${this.totalMargemSobreCustoComDesconto.toFixed(2)} %`;
      } else {
        this.vMargemSobreCustoComDesconto = "Não informado";
      }
    }
  }

  loading: boolean = false;

  atualizarCalculos() {
    this.calcularDesconto();
    this.calcularCusto();
  }

  changeQtdeItemPorMetro(valor: number){
    if(valor != null){
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetro){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroQuadrado){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeTipoMedidaLargura!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeTipoMedidaAltura!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }

  changeComprimento(valor: number){
    if(valor != null){
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetro){
        this.inputDados.quantidade = valor * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroQuadrado){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeTipoMedidaAltura! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }

  changeLargura(valor: number){
    if(valor != null){
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroQuadrado){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeTipoMedidaAltura! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }
  
  changeAltura(valor: number){
    if(valor != null){
      if(this.inputDados.idProdutoUnicoNavigation?.unidadeMedidaComercial == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }

  changeQuantidadeEntregueAgendada(valor: number) {
    this.inputDados.quantidadeEntregueNoAto = this.inputDados.quantidade! - valor - this.inputDados.quantidadeParaEntregaCrossdoking!;
  }

  changeQuantidade(valor: number) {
    if(valor == null || valor == 0) valor = 1;
    if(this.inputDados.quantidade == null || this.inputDados.quantidade == 0) this.inputDados.quantidade = 1;
    if (valor != null) {
      this.inputDados.quantidadeEntregueNoAto = valor - this.inputDados.quantidadeParaEntregaAgendada! - this.inputDados.quantidadeParaEntregaCrossdoking!;
      this.inputDados.valorTotalSeguro = valor * this.inputDados.valorSeguro!;
      this.calcularDesconto();
    }
  }

  onFoucus(event) {
    setTimeout(() => {
      event.target.select();
    }, 200);
  }

  @ViewChild('elmQuantidade') elmQuantidade: InputNumber = null!;
  onFocusQuantidade() {
    setTimeout(() => {
      if (this.elmQuantidade != null) {
        this.elmQuantidade.input.nativeElement.focus();
      }
    }, 120);
  }

  changeQuantidadeCrossDocking(valor: number) {
    this.inputDados.quantidadeEntregueNoAto = this.inputDados.quantidade! - valor - this.inputDados.quantidadeParaEntregaAgendada!;
  }

  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {

    }
    if (event.keyCode == 13) {
      this.cliqueAlterarItem();
    }
  }

  cliqueFechar() {
    if (this.alterouItemComSucesso) {
      this.inputDados.precoUnitario = (this.preco.precoVenda ?? 0);
      this.inputDados.valorSubtotal = this.inputDados.quantidade! * (this.preco.precoVenda ?? 0);
      this.inputDados.valorTotal = 
        this.inputDados.valorSubtotal + this.inputDados.valorTotalFrete! + this.inputDados.valorOutrasDespesasAcessorias! + this.inputDados.valorTotalSeguro! - this.inputDados.valorDesconto!
        //+ (this.inputDados.quantidade! * this.inputDados.valorSeguro!)
        ;
      if(this.precoUnitarioInicial != this.inputDados.precoUnitario){
        this.inputDados.alterouPrecoUnitario = enumSimNao.Sim;
        this.inputDados.precoUnitarioBase = this.inputDados.precoUnitario;
      }
      this.alterouItens.emit(this.inputDados);
    }
    this.fecharModal();
  }

  mensagemIndicaValorItemValorTot: string = '';
  onChangeIndicaValorItemValorTot(event: any) {
    if (event.target.checked) {
      this.inputDados.indicaValorItemValorTot = enumSimNao.Sim;
      this.mensagemIndicaValorItemValorTot = '';
    } else {
      this.inputDados.indicaValorItemValorTot = enumSimNao.Nao;
      this.mensagemIndicaValorItemValorTot = "Se for emitir [NFC-e ou NFS-e], o campo: Contabilizado Total, é obrigatório estar selecionado, e se for gerar venda o valor total deste item não será contabilizado no pagamento.";
    }
  }

  cliqueAlterarItem() {
    if (
      this.inputDados.quantidadeEntregueNoAto < 0) {
      swal.fire({
        title: "Atenção",
        html: `Distribuição dos itens para entrega não pode ultrapassar a quantidade para venda.<br> <b>${this.inputDados.quantidade}</b> item(ns)!`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
    }
    else {
      if (
        this.inputDados.quantidadeParaEntregaAgendada! > 0 && this.inputDados.dataPrevistaEntregaAgendada == null) {
        swal.fire({
          title: "Atenção",
          html: `Quando há itens para entrega agendada, obrigatório informar a<b> data de entrega</b>!`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          }
        });
      }
      else {
        if (
          this.inputDados.quantidadeParaEntregaAgendada! > 0 &&
          (this.inputDados.dataPrevistaEntregaAgendada != null &&
            ApiUtilService.getDateString(this.inputDados.dataPrevistaEntregaAgendada) < ApiUtilService.getDateString(new Date()))) {
          swal.fire({
            title: "Atenção",
            html: `Informe uma data de entrega maior ou igual a data de hoje!`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              return;
            }
          });
        } else {
          this.alterouItemComSucesso = true;
          this.cliqueFechar();
        }
      }
    }
  }
  alterouItemComSucesso: boolean = false;

  fecharModal() {
    this.precoUnitarioInicial = 0;
    this.exibirDescontoMaximo = false;
    this.vDescontoMaximo = null;
    this.modal.hide();
  }

  exibirDescontoMaximo: boolean = false;
  vDescontoMaximo: string | null = null;
  valorDesconto: number = 0;
  descontoMaximo() {
    this.exibirDescontoMaximo = !this.exibirDescontoMaximo;
    this.calcularDesconto();
  }
  
  calcularDesconto() {
    this.valorDesconto = 0;
    if (this.exibirDescontoMaximo && this.permiteDescProdServ == enumSimNao.Sim) {
      if (this.inputDados.idProdutoUnicoNavigation.percMaxDesconto != null && this.inputDados.idProdutoUnicoNavigation.percMaxDesconto != 0) {
        this.valorDesconto = (this.inputDados.idProdutoUnicoNavigation.percMaxDesconto! / 100) * (this.inputDados.quantidade! * this.preco.precoVenda!);
        this.vDescontoMaximo = this.getFormatDecimal(this.valorDesconto);
        if (this.valorDesconto! < this.inputDados.valorDesconto!) {
          this.inputDados.valorDesconto = this.valorDesconto;
        }
      }
      else {
        this.vDescontoMaximo = this.getFormatDecimal(0)
      }
    } else {
      this.vDescontoMaximo = null;
    }

    //acrescentar o valor de desconto promocional, caso tenha.
    if(this.inputDados.valorTotalPromocaoConcedido != null && this.inputDados.valorTotalPromocaoConcedido > 0){
      this.valorDesconto += this.inputDados.valorTotalPromocaoConcedido;
    }
  }

  getFormatDecimal(number: number): string {
    if (number == null) return 'n/d';
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(number);
  }

  showMargem: boolean = false;
  showTotais: boolean = false;
  subsGetPrecoByIdProdutoUnico: Subscription = null!;
  preco: PrecoModel = null!;
  exibirCusto: boolean = false;
  cliqueExibirCusto() {
    this.exibirCusto = !this.exibirCusto;
  }
  totalMargemSobreCustoSemDesconto: number = 0;
  totalMargemSobreCustoComDesconto: number = 0;
  getPreco() {
    if (this.preco != null) return;
    this.subsGetPrecoByIdProdutoUnico = this.apiPrecoService.getByIdProdutoUnicoETabelaPreco(this.inputDados.idProdutoUnico, ConstantsService.TabelaPreco.idTabelaPreco, true).subscribe(
      (retorno: PrecoModel) => {
        this.subsGetPrecoByIdProdutoUnico?.unsubscribe();
        if (retorno != undefined) {
          this.preco = retorno;
          if (this.inputDados.precoUnitario != null && this.inputDados.precoUnitario != 0) {
            this.preco.precoVenda = this.inputDados.precoUnitario;
          }
          this.calcularCusto();
        }
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetPrecoByIdProdutoUnico?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  vMargemSobreCustoSemDesconto: string | null = null;
  vMargemSobreCustoComDesconto: string | null = null;
}