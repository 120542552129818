
<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header uppercase">&nbsp;<i class="fad fa-print"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row pb-3">
            <!-- <div class="col-xl-3 col-md-3 col-md-3" *ngIf="parametrizacaoPdv != null && parametrizacaoPdv.impressaoPadrao">
                <div class="row pb-3" >
                    <div class="col-12">
                        <label class="form-label text-c-blue" >Formato de Impressão<br>para Recibo</label>
                    </div>
                </div>
                <div class="row pb-3" >
                    <div class="col-12">
                        <div class="form-check  ">
                            <input type="radio" id="Cupom80mm" name="radioFormatoImpressao"
                              class="form-check-input  mouse-pointer" 
                              [checked]="formatoImpressao == Cupom80mm" (change)="handleCupomFiscal($event)"
                              >
                            <label class="form-check-label  mouse-pointer" for="Cupom80mm">Cupom</label>
                          </div>
                          <div class="form-check  ">
                            <input type="radio" id="A4" name="radioFormatoImpressao"
                              class="form-check-input  mouse-pointer" 
                              [checked]="formatoImpressao == A4" (change)="handleA4($event)"
                              >
                            <label class="form-check-label  mouse-pointer" for="A4">A4</label>
                          </div>
                    </div>
                </div>
            </div> -->
            <div class="col-5 col-xl-5 col-md-5 col-sm-12">
                <div class="row pb-3" >
                    <div class="col-12" *ngIf="passo == 1">
                        <label class="form-label text-c-blue" >{{textoPerguntaPasso1}}</label>
                    </div>
                    <div class="col-12" *ngIf="passo == 2">
                        <label class="form-label text-c-blue" >{{textoPerguntaPasso2}}</label>
                    </div>
                </div>
                <div class="row" *ngIf="passo == 1 && (parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfce)">
                    <div class="col-12"  >
                        <div class="form-check  ">
                            <input type="radio" id="radioPessoaFisicaJuridicaPF" name="radioPessoaFisicaJuridica"
                              class="form-check-input  mouse-pointer" 
                              [checked]="tipoPessoa == 'F'" (change)="handleChangePF($event)"
                               aria-controls="collapseImportarPJ">
                            <label class="form-check-label  mouse-pointer" for="radioPessoaFisicaJuridicaPF">Pessoa Física</label>
                          </div>
                          <div class="form-check  ">
                            <input type="radio" id="radioPessoaFisicaJuridicaPj" name="radioPessoaFisicaJuridica"
                              class="form-check-input  mouse-pointer" 
                              [checked]="tipoPessoa == 'J'" (change)="handleChangePJ($event)"
                              aria-controls="collapseImportarPJ">
                            <label class="form-check-label  mouse-pointer" for="radioPessoaFisicaJuridicaPj">Pessoa Jurídica</label>
                          </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-md-4 pt-3"  *ngIf="passo == 1  && (parametrizacaoPdv != null && !parametrizacaoPdv.habilitaNfce)"></div>
            <div class="col-xl-4 col-md-4 col-md-4 pt-3"  *ngIf="passo == 1  && (parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfce)">
                <div class="form-group" *ngIf="tipoPessoa == 'F'">
                    <label class="form-label" for="cpfCnpj">CPF</label>
                    <p-inputMask [mask]="maskCpf" [inputId]="'cpfCnpj'" #elmCpf (keypress)="keyPress($event)" [autoFocus]="true" 
                    styleClass="form-control input-md" name="cpfCnpj" type="text" placeholder="Digite o Cpf"
                    [(ngModel)]="cpfCnpj">
                    </p-inputMask>
                </div>
                <div class="form-group" *ngIf="tipoPessoa == 'J'">
                    <label class="form-label" for="cpfCnpj">Cnpj</label>
                  <p-inputMask [mask]="maskCnpj" #elmCnpj (keypress)="keyPress($event)"
                    styleClass="form-control input-md" [inputId]="'cpfCnpj'" name="cpfCnpj" type="text" placeholder="Digite o Cnpj" [autoFocus]="true" 
                    [(ngModel)]="cpfCnpj">
                  </p-inputMask>
                </div>
            </div>
            <div class="col-5 col-xl-5 col-md-5 col-sm-12">
                <div class="col-12">
                    <label class="form-label uppercase pb-1" for="observacao">Observação Para Entrega 
                    <i class="fas fa-info-circle" ngbTooltip="Informe, caso necessário, as observações para facilitar a entrega. Será impresso no recibo. Máximo 100 caracteres."></i></label>
                    <textarea maxlength="100" style="height:fit-content" class="form-control input-md class-npe" id="obsEntrega" name="obsEntrega"
                        type="text"  placeholder="Digite aqui a(s) observação(ões)..." [(ngModel)]="obsEntrega">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <div class="button-container">
            <span>
                <button  (click)="cliqueCancelar()" type="button" style="min-width: 160px;"
                    class="btn btn-secondary inline p-r-1"><i class="fad fa-arrow-left"></i> Cancelar
                </button>&nbsp;
            </span>
            <span>
                <button  (click)="cliqueNovaVenda()" type="button" style="min-width: 160px;"
                    class="btn btn-laranja inline p-r-1"><i class="fad fa-cash-register"></i> Caixa Livre
                </button>&nbsp;
            </span>
            <!-- <span *ngIf="passo == 1 &&  parametrizacaoPdv != null  && parametrizacaoPdv.impressaoPadrao"> <button type="button" (click)="cliqueConfirmarGerarRecibo()" #elmBotaoGerarRecibo  [disabled]="loading"
                class="btn btn-success inline pr-1" style="min-width: 160px;"><i class="fad fa-print"></i> Recibo</button>
            </span> -->
            <span *ngIf="passo == 1 &&  parametrizacaoPdv != null  && parametrizacaoPdv.impressaoPadrao">
                <app-modal-impressao #modalImpressaoComponent class="text-start"
                    (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                    (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                    [modalFrontLayer]="true"
                    [codigoDocumento]="'01'"
                    [textButton]="'Recibo'"
                    [typeButton]="'default'"
                    [icon]="'fad fa-print ps-2'"
                    (onImprimirFormatoA4)="cliqueConfirmarGerarRecibo(A4)"
                    (onImprimirFormatoCupomFiscal)="cliqueConfirmarGerarRecibo(Cupom80mm)"
                    >
                </app-modal-impressao>&nbsp;
            </span>
            <span *ngIf="passo == 1 && parametrizacaoPdv != null && parametrizacaoPdv.impressaoPadrao && habilitaBotaoPromissoria">
                <app-modal-impressao #modalImpressaoComponent class="text-start"
                    (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                    (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                    [modalFrontLayer]="true"
                    [codigoDocumento]="'04'"
                    [textButton]="'Promissória'"
                    [typeButton]="'default'"
                    [icon]="'fad fa-scroll ps-2'"
                    (onImprimirFormatoA4)="imprimirPromissoriaDeclaracao('P', A4)"
                    >
                </app-modal-impressao>&nbsp;                
            </span>
            <span *ngIf="passo == 1 && parametrizacaoPdv != null && parametrizacaoPdv.impressaoPadrao && (habilitaBotaoDeclaracao || habilitaBotaoPromissoria)">
                <app-modal-impressao #modalImpressaoComponent class="text-start"
                    (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                    (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                    [modalFrontLayer]="true"
                    [codigoDocumento]="'03'"
                    [textButton]="'Declaração'"
                    [typeButton]="'default'"
                    [icon]="'fad fa-scroll ps-2'"
                    (onImprimirFormatoA4)="imprimirPromissoriaDeclaracao('D', A4)"
                    (onImprimirFormatoCupomFiscal)="imprimirPromissoriaDeclaracao('D', Cupom80mm)"
                    >
                </app-modal-impressao>
            </span>
            <!-- <span *ngIf="passo == 1 &&  parametrizacaoPdv != null  && parametrizacaoPdv.impressaoPadrao && habilitaBotaoPromissoria"> <button type="button" (click)="cliqueConfirmarGerarPromissoria()" #elmBotaoGerarRecibo  [disabled]="loading"
                class="btn btn-warning inline pr-1" style="min-width: 160px;"><i class="fad fa-scroll"></i> Promissória</button>
            </span>
            <span *ngIf="passo == 1 &&  parametrizacaoPdv != null  && parametrizacaoPdv.impressaoPadrao && habilitaBotaoDeclaracao && !habilitaBotaoPromissoria"> <button type="button" (click)="cliqueGerarDeclaracao()" #elmBotaoGerarRecibo  [disabled]="loading"
                class="btn btn-warning inline pr-1" style="min-width: 160px;"><i class="fad fa-scroll"></i> Declaração</button>
            </span> -->
            <span *ngIf="passo == 1 && (parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfse && venda.emitiuNfse != 'S') && (venda.tipoVenda == tipoVendaUnico || venda.tipoVenda == tipoVendaServico)">
                <button type="button" (click)="cliqueConfirmarGerarNfse(venda.tipoVenda)" #elmBotaoGerarNfse  [disabled]="loading || disabledBtnEmitirNfse"
                class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-sticky-note"></i> Gerar Nfs-e</button></span>
            <span *ngIf="passo == 1 && (parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfe && venda.emitiuNfe != 'S' && venda.emitiuNfce == 'N') && (venda.tipoVenda == tipoVendaUnico || venda.tipoVenda == tipoVendaProduto)"> 
                <button type="button" (click)="cliqueConfirmarGerarNfe(venda.tipoVenda)" #elmBotaoGerarNfe  [disabled]="loading  || disabledBtnEmitirNfe"
                class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-sticky-note"></i> Gerar Nf-e</button></span>
            <span *ngIf="passo == 1 && parametrizacaoPdv != null && venda.emitiuNfse == 'S'"> 
                <button type="button" (click)="cliqueImprimir2ViaNfse()" #elmBotaoGerarNfse  [disabled]="loading"
                class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-sticky-note"></i> 2º Via Nfs-e</button></span>
            <span *ngIf="passo == 1 && parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfe && venda.emitiuNfe == 'S' "> 
                <button type="button" (click)="cliqueImprimir2ViaNfe()" #elmBotaoGerarNfe  [disabled]="loading"
                class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-sticky-note"></i> 2º Via Nf-e</button></span>
            <span *ngIf="passo == 1 && (parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfce && venda.emitiuNfce != 'S') && (venda.tipoVenda == tipoVendaUnico || venda.tipoVenda == tipoVendaProduto)" > 
                <button type="button" (click)="cliqueConfirmarGerarNfce(venda.tipoVenda)" #elmBotaoGerarNfce [disabled]="loading || disabledBtnEmitirNfce"
                class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-sticky-note"></i> Gerar Nfc-e</button></span>
            <span *ngIf="passo == 1 && parametrizacaoPdv != null && parametrizacaoPdv.habilitaNfce && venda.emitiuNfce == 'S'"> 
                <button type="button" (click)="cliqueImprimir2ViaNfce()" #elmBotaoGerarNfce  [disabled]="loading"
                class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-sticky-note"></i> 2º Via Nfc-e</button></span>

            <span *ngFor="let boleto of boletos;let i = index"> 
                
                    <button
                    
                    type="button" (click)="cliqueDownloadBoletoByIdBoleto(boleto.idBoleto)" #elmBotaoGerarNfce  [disabled]="loading" 
                    class="btn btn-primary inline pr-1" style="min-width: 160px;"><i class="fad fa-file-download"></i> Boleto {{i+1}} </button>
                
                
            </span>
        </div>
    </div>
</app-ui-modal>
<app-modal-verifica-atualizacao-sefaz
    #modalVerificaAtualizacaoSefazComponent
    (terminoVerificacao)="terminoVerificacao($event)">
</app-modal-verifica-atualizacao-sefaz>