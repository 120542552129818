import { enumIndicaOperacaoConsumidorFinalNotaFiscal } from "src/app/enum/enumIndicaOperacaoConsumidorFinalNotaFiscal";
import { enumSimNao } from "src/app/enum/enumSimNao";
import { PessoaFisicaJuridicaModel } from "./pessoaFisicaJuridicaModel";



export class PessoaFisicaJuridicaModelInstance {
    public static get(): PessoaFisicaJuridicaModel {
        return {
            cpfCnpj: '',
            tipoPessoa: '',
            razaoSocialNome: '',
            idPessoaFisicaJuridicaClassificacao: null,
            consumidorFinal: enumIndicaOperacaoConsumidorFinalNotaFiscal.ConsumidorFinal,
            idContaBancaria: null,
            idTabelaBandeiraOperCartCredDeb: '',
            nomeFantasia: '',
            responsavelCadastroCpfCnpj: '',
            clienteDesde: null,
            email: '',
            observacao: '',
            observacaoNfe: '',
            site: '',
            codigoSistemaLegado: '',
            indicadorInscricaoEstadual: '',
            rg: '',
            sexo: '',
            dataNascimento: null,
            telefoneFixo: '',
            celular: '',
            contato: '',
            inscricaoEstadual: '',
            inscricaoSuframa: '',
            inscricaoMunicipal: '',
            codigoRegimeTributario: '',
            dataAberturaEmpresa: null,
            tomadorOrgaoPublico: '',
            codigoPais: '',
            nomePais: '',
            status: '',
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            celularWhatsapp: '',
            multiplaInscricaoEstadual: '',
            contasRecebimentoPagamentos: null!,
            idContaBancariaNavigation: null!,
            idPessoaFisicaJuridicaClassificacaoNavigation: null!,
            prestadorServico: null!,
            profissional: null!,
            compras: null!,
            condicionals: null!,
            contratos: null!,
            controleVales: null!,
            cotacaoPessoaFisicaJuridicas: null!,
            enderecos: null!,
            orcamentos: null!,
            ordemServicos: null!,
            parametrizacaoCondPagamentos: null!,
            pessoaFisicaJuridicaContatos: null!,
            pessoaFisicaJuridicaCurvaAbcs: null!,
            pessoaFisicaJuridicaFinanceiros: null!,
            pessoaFisicaJuridicaProdutos: null!,
            pessoaFisicaJuridicaTipoClientes: null!,
            produtoFornecedors: null!,
            venda: null!,
            vendaEntregas: null!,
            inverseResponsavelCadastroCpfCnpjNavigation: null!,
            responsavelCadastroCpfCnpjNavigation: null!,
            historicoClientes: null!,
            mensagemAlerta: null!,
            mensagemAvisoRecompra: '',
            quantidadeDiasAvisoRecompra: 0,
            estoqueControleCrossDockings: null!,
            whatsappCampanhaContatos: null!,
            enviouMsgWhatsappAniversario: null!,
            enviouMsgWhatsappBoasVindas: '',
            pedidoVendumCpfCnpjNavigations: null!,
            pedidoVendumPreCpfCnpjNavigations: null!,
            pedidoVendumProCpfCnpjNavigations: null!,
            cpfCnpjInformado: '',
            informouCpfCnpj: enumSimNao.Sim,
            nomeDaMae: '',
            rotaEntregaEnvolvidos: null!,
            registroPontoFuncionarios: null!,
            registroPontoHorasTrabalhada: null!,
            cartaoFidelidades: [],
            cartaoFidelidadeTransacaos: [],
            pessoaFisicaJuridicaFamilia: [],
            pessoaFisicaJuridicaReferencia: [],
            vendaProdutoCpfCnpjComissaoPrestadorNavigations: [],
            vendaProdutoCpfCnpjComissaoProfissionalNavigations: [],
            idTabelaCentroCusto: null,
            idTabelaCentroCustoNavigation: null!,
            pessoaFisicaJuridicaAnexoArquivos: [],
            contasAPagRecCpfCnpjNavigations: [],
            contasAPagRecCpfCnpjOperadoraFinanceiraNavigations: [],
            animals: [],
            feedbackResposta: [],
            veiculos: [],
            motivoBloqueio: '',
            calendarios: []
        };
    }

    public static getArray(): PessoaFisicaJuridicaModel[] {
        return [this.get()];
    };
}
