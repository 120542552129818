import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ParametrizacoesInicialSistemaModel } from 'src/app/models/cliente/ParametrizacoesIniciaisSistema/ParametrizacoesInicialSistemaModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiUtilService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'util';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    public static getDateString(data: Date) {
        return `${data.getFullYear().toString()}${data.getMonth().toString().padStart(2, '0')}${data.getDate().toString().padStart(2, '0')}`;
    }

    getIpAddress(): Observable<any> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getIpAddress`);
    }

    getParametricacoesEIrParaTelaLogin(idPdv: number, exibeCarregando: boolean):Observable<ParametrizacoesInicialSistemaModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getParametricacoesEIrParaTelaLogin/${idPdv}`, exibeCarregando);
    }

    public static toDate(dateString): Date | null {
        if (!dateString) {
            return null;
        }

        let date: Date = null!;

        // Verificar o formato YYYY-MM-DD
        const isoFormat = /^\d{4}-\d{2}-\d{2}$/;
        if (isoFormat.test(dateString)) {
            date = new Date(dateString);
        }

        // Verificar o formato DD/MM/YYYY
        const brFormat = /^\d{2}\/\d{2}\/\d{4}$/;
        if (brFormat.test(dateString)) {
            const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
            date = new Date(year, month - 1, day);
        }

        // Retornar null se a data for inválida
        return isNaN(date.getTime()) ? null : date;
    }
    public static replaceSpecialChars(str): string {
        str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
        str = str.replace(/[àáâãäå]/, "a");
        str = str.replace(/[ÈÉÊË]/, "E");
        str = str.replace(/[Ç]/, "C");
        str = str.replace(/[ç]/, "c");
        return str;
        // adicionar mais regras caso necessário
        // OBS: são necessárias regras tanto para caracteres minísculos e maiúsculos
        //return str.replace(/[^a-z]/gi,'');  // remove caracteres que não são letras
    }

    public static getDateFormatString(data: Date) {
        return `${data.getDate().toString().padStart(2, '0')}/${data.getMonth().toString().padStart(2, '0')}/${data.getFullYear().toString()}`;
    }

    public static formatarCampo(campoTexto): string {
        if (!campoTexto) return '';
        if (campoTexto.length <= 11) {
            return this.mascaraCpf(campoTexto);
        } else {
            return this.mascaraCnpj(campoTexto);
        }
    }
    public static retirarFormatacao(campoTexto): string {
        if (!campoTexto) return '';
        return campoTexto.replace(/(\.|\/|\-)/g, "");
    }
    public static mascaraCpf(valor): string {
        if (!valor) return '';
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
    }
    public static mascaraCnpj(valor): string {
        if (!valor) return '';
        return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }
}