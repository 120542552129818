import { enumAcaoAbrirFecharCaixa } from "src/app/enum/enumAcaoAbrirFecharCaixa";
import { enumContasAPagarReceber } from "src/app/enum/enumContasAPagarReceber";
import { ParametrizacaoCondPagamentoModel } from "./parametrizacaoCondPagamentoModel";


export class ParametrizacaoCondPagamentoModelInstance {
    public static get(): ParametrizacaoCondPagamentoModel {
        return {
            idParametrizacaoCondPagamento: 0,
            idTabelaMeioPagamento: '',
            idLoja: 0,
            descricao: '',
            padrao: '',
            aVistaPrazo: '',
            permitirEmBaixaDeConta: '',
            permitirVenda: '',
            permitirVale: '',
            permitirDevolucao: '',
            permitirOrdemServico: '',
            permitirContrato: '',
            pixIntegrado: '',
            tef: '',
            idContaBancaria: 0,
            lancarReceberCartoes: '',
            cpfCnpj: '',
            lancarContasAPagarReceber: '',
            obrigaInformarCliente: '',
            gerarBoletoAutomaticamente: '',
            taxaDespesa: 0,
            quantParcelas: 0,
            intervaloDias: 0,
            percJuros: 0,
            percDescontos: 0,
            valorPercentualMinimoEntrada: 0,
            obrigaInformarNsu: '',
            descontoMaxPercVendedor: 0,
            descontoMaxValorVendedor: 0,
            status: '',
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            permiteExclusao: '',
            pixChave: '',
            pixCelular: '',
            pixCpfCnpj: '',
            pixEmail: '',
            tituloLocalPagamento: '',
            tituloCodigoJuros: '',
            tituloCodDesconto: '',
            tituloDataDescontoQtdeDias: null,
            tituloValorDesconto: null,
            tituloCodDesconto2: '',
            tituloDataDescontoQtdeDias2: null,
            tituloValorDesconto2: null,
            tituloDataJurosDiasCarencia: null,
            tituloValorJuros: null,
            tituloCodigoMulta: '',
            tituloDataMultaDiasCarencia: null,
            tituloValorMultaTaxa: null,
            tituloCodProtesto: '',
            tituloPrazoProtestoQtdeDias: null,
            tituloCodBaixaDevolucao: '',
            tituloPrazoBaixaQtdeDias: null,
            tituloMensagem01: '',
            tituloMensagem02: '',
            tituloMensagem03: '',
            tituloMensagem04: '',
            tituloMensagem05: '',
            tituloTipoFormulario: '',
            informacoesAdicionais: '',
            tituloInstrucoes: '',
            tituloInstrucao1: null!,
            tituloInstrucao2: null!,
            tituloInstrucao3: null!,
            tituloInstrucaoPrazo1: null!,
            tituloInstrucaoPrazo2: null!,
            tituloInstrucaoPrazo3: null!,
            cpfCnpjNavigation: null!,
            idContaBancariaNavigation: null!,
            idTabelaMeioPagamentoNavigation: null!,
            contasRecebimentoPagamentos: null!,
            contratoPeriodos: null!,
            controleVales: null!,
            formaPagamentos: null!,
            despesaOperadoraFixo: 0,
            despesaOperadoraVariaval: 0,
            numeroParcelaAReceberOperCartao: 0,
            exibeContaAPagarOuReceber: enumContasAPagarReceber.Todas,
            idTabelaBandeiraOperCartCredDeb: null!,
            tipoIntegracaoBoleto: null!
        };
    }
    public static getArray(): ParametrizacaoCondPagamentoModel[] {
        return [this.get()];
    }
}
