import swal from 'sweetalert2';
import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { ExtratoCartaoFidelidadeModel } from 'src/app/models/cliente/CartaoFidelidade/ExtratoCartaoFidelidadeModel';
import { ExtratoCartaoFidelidadeRecompensaModel } from 'src/app/models/cliente/CartaoFidelidade/ExtratoCartaoFidelidadeRecompensaModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiCartaoFidelidadeService } from 'src/app/services/pdv/api-cartao-fidelidade.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { enumTipoRecompensaCartaoFidelidade } from 'src/app/enum/enumTipoRecompensaCartaoFidelidade';
import { enumCalculoPontoCartaoFidelidade } from 'src/app/enum/enumCalculoPontoCartaoFidelidade';
import { enumStatus } from 'src/app/enum/enumStatus';

@Component({
  selector: 'app-modal-cartao-fidelidade',
  templateUrl: './modal-cartao-fidelidade.component.html',
  styleUrls: ['./modal-cartao-fidelidade.component.css']
})
export class ModalCartaoFidelidadeComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<VendumModel> = new EventEmitter<VendumModel>();
  @Output() salvouAlteracao: EventEmitter<VendumModel> = new EventEmitter<VendumModel>();
  loading: boolean = false;
  tituloModal: string = '';
  descricaoModal: string = '';
  minDate: Date = new Date();
  subsConsultar: Subscription = null!

  constructor(
    private apiCartaoFidelidadeService: ApiCartaoFidelidadeService,
    private showMessageError: ShowMessageError,
    private appCustomToastService: AppCustomToastService,
    private router: Router) { }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
  }
  get heightListaCliente(): string {
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  cliqueCancelar() {
    this.fecharModal();
  }

  venda: VendumModel = null!;
  public abrirModal(item: VendumModel) {
    this.tituloModal = 'Cartão Fidelidade';
    this.descricaoModal = 'Visualize e Resgate a Recompensa de seu Cliente';
    this.venda = item;
    this.consultarExtratoCartaoFidelidade(item.cpfCnpj);
  }

  enumCalculoPontoCartaoFidelidadeCashback: enumCalculoPontoCartaoFidelidade = enumCalculoPontoCartaoFidelidade.Cashback;
  enumCalculoPontoCartaoFidelidadePorValor: enumCalculoPontoCartaoFidelidade = enumCalculoPontoCartaoFidelidade.PorValor;
  enumCalculoPontoCartaoFidelidadePorQuantidadeDeProduto: enumCalculoPontoCartaoFidelidade = enumCalculoPontoCartaoFidelidade.PorQuantidadeDeProduto;

  enumTipoRecompensaCartaoFidelidadeDescontoEmValorProximaCompra: enumTipoRecompensaCartaoFidelidade = enumTipoRecompensaCartaoFidelidade.DescontoEmValorProximaCompra;
  enumTipoRecompensaCartaoFidelidadeBrinde: enumTipoRecompensaCartaoFidelidade = enumTipoRecompensaCartaoFidelidade.Brinde;
  enumTipoRecompensaCartaoFidelidadeCashback: enumTipoRecompensaCartaoFidelidade = enumTipoRecompensaCartaoFidelidade.Cashback;

  inputDados: ExtratoCartaoFidelidadeModel = null!;
  subsExtratoCartaoFidelidade: Subscription = null!;

  cpfCnpj: string = "";
  totalPontos: number = 0;
  totalEmVale: number = 0;
  status: enumStatus = enumStatus.Ativo;

  consultarExtratoCartaoFidelidade(cpfCnpj: string){
    this.itemSelecionado = null!;
    this.loading = true;
    this.cpfCnpj = "";
    this.totalPontos = 0;
    this.totalEmVale = 0;
    this.status = enumStatus.Ativo;
    this.subsExtratoCartaoFidelidade = this.apiCartaoFidelidadeService.extratoCartaoFidelidade(cpfCnpj, true)
      .subscribe( (retorno: ExtratoCartaoFidelidadeModel) => {
        this.loading = false;
        this.subsExtratoCartaoFidelidade?.unsubscribe();
        if (retorno != null){
          retorno.cartoes.forEach(f => {
            //if (f.dataEmissao != null) f.dataEmissao = new Date(f.dataEmissao);
            this.totalPontos += f.pontosAcumulados!;
            //this.totalEmVale += f.valorEmVale!;
          });
          // retorno.transacoes.forEach(f => {
          //   this.totalPontos += f.pontosGerados!;
          // });
          retorno.recompensas.forEach(f => {
            //this.totalPontos += f.pontosASerUtilizado!;
            this.totalEmVale += f.valorVale!;
          });
          //if (retorno.cartao.dataEmissao != null) retorno.cartao.dataEmissao = new Date(retorno.cartao.dataEmissao);
          this.inputDados = retorno;
          this.cpfCnpj = this.inputDados.cartoes[0].cpfCnpj;
          this.status = this.inputDados.cartoes[0].status as enumStatus;
          this.modal.show();
        }else{
            swal.fire({
              title: "Que Pena!",
              html: `Este cliente não possui cartão fidelidade!`,
              showCancelButton: false,
              showConfirmButton: true,
              confirmButtonText: "Ok",
              icon: 'info'
            })
              .then((res) => {
                this.fecharModal();
              });
            return;
        }
      },
        (err) => {
          this.loading = false;
          this.subsExtratoCartaoFidelidade?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  subsResgateRecompensa: Subscription = null!;
  resgatarRecompensa(item: ExtratoCartaoFidelidadeRecompensaModel){
    this.loading = true;
    this.subsResgateRecompensa = this.apiCartaoFidelidadeService.resgatarRecompensa(
      item.idCartaoFidelidadeAcumuloPontos, 
      item.idCartaoFidelidadeRecompensa, 
      item.idCartaoFidelidade, 
      this.venda.cpfCnpj, true)
      .subscribe( (retorno: void) => {
        this.loading = false;
        this.subsResgateRecompensa?.unsubscribe();
        if(item.tipoCalculo == this.enumCalculoPontoCartaoFidelidadeCashback ||
          item.tipoCalculo == this.enumCalculoPontoCartaoFidelidadePorValor
        ){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Resgate realizado com sucesso. Adicione o VALE nesta venda ou nas próximas!'], "Sucesso");
        }else{ //Por quantidade = Brinde
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Resgate realizado com sucesso. Entrega do brinde disponível!'], "Sucesso");
        }
        this.consultarExtratoCartaoFidelidade(this.venda.cpfCnpj);
      },
        (err) => {
          this.loading = false;
          this.subsResgateRecompensa?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }
  
  fecharModal() {
    this.fechouModal.emit(this.venda);
    this.cpfCnpj = "";
    this.totalPontos = 0;
    this.totalEmVale = 0;
    this.status = enumStatus.Ativo;
    this.modal.hide();
  }

  itemSelecionado: ExtratoCartaoFidelidadeRecompensaModel = null!;
  handleChangeCartaoFidelidade(evt, index) {
    var target = evt.target;
    if (target.checked) {
      this.itemSelecionado = this.inputDados.recompensas[index];
    }
  }

  cliqueResgatar() {
    //this.salvouAlteracao.emit(this.venda);
    //this.fecharModal();

    if (this.itemSelecionado == null){
      swal.fire({
        title: "Atenção",
        html: `Selecione um resgate para continuar!`,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
        });
      return;
    }

    this.resgatarRecompensa(this.itemSelecionado);
  }
}