import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumTipoPessoa } from 'src/app/enum/enumTipoPessoa';
import { EnderecoModel } from 'src/app/models/cliente/Endereco/enderecoModel';
import { HistoricoClienteModel } from 'src/app/models/cliente/HistoricoCliente/HistoricoClienteModel';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiPessoaFisicaJuridicaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private controller = 'PessoaFisicaJuridica';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    registrarHistoricoCliente(obj: HistoricoClienteModel, exibeCarregando: boolean): Observable<HistoricoClienteModel>{
        return this.gc.post(this.api, `api/${this.controller}/registrarHistoricoCliente`, obj, exibeCarregando);
    }

    atualizarHistoricoCliente(obj: HistoricoClienteModel, exibeCarregando: boolean): Observable<HistoricoClienteModel>{
        return this.gc.post(this.api, `api/${this.controller}/atualizarHistoricoCliente`, obj, exibeCarregando);
    }

    getAllHistoricoClienteByCpfCnpj(cpfCnpj: string, exibeCarregando: boolean): Observable<HistoricoClienteModel[]> {
        let filtro = encodeURIComponent(cpfCnpj);
        return this.gc.get(this.api, `api/${this.controller}/getAllHistoricoClienteByCpfCnpj/${filtro}`, exibeCarregando);
   }

    getAll(exibeCarregando: boolean) {
         return this.gc.get(this.api, `api/${this.controller}`, exibeCarregando);
    }

    getAllByTipoPessoa(tipoPessoa: enumTipoPessoa, cadastrosExcluidos: boolean, exibeCarregando: boolean) {
        return this.gc.get(this.api, `api/${this.controller}/getAllByTipoPessoa?tipoPessoa=${tipoPessoa}&cadastrosExcluidos=${cadastrosExcluidos}`, exibeCarregando);
    }

    get(cpfCnpj: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `api/${this.controller}/get/${cpfCnpj}`, exibeCarregando);
    }

    getAllByIdTipoCliente(idTipoCliente: string) {
        return this.gc.get(this.api, `api/${this.controller}/getAllByIdTipoCliente/${idTipoCliente}`, true);
    }

    // getPessoaByCpfNome(filter: string) {
        
    //     return this.gc.get(this.api, `api/${this.controller}/getPessoaByCpfNome/${filter}`, true);
    // }

    getAllByCnpjRazaoSocialNomeFantasiaCpfNome(tipoCliente: string, filtro: string, exibeCarregando: boolean) {
        filtro = encodeURIComponent(filtro);
        //filtro = filtro.replace(/[^a-z0-9]/gi,'');
        return this.gc.get(this.api, `api/${this.controller}/getAllByCnpjRazaoSocialNomeFantasiaCpfNome/${tipoCliente}?filtro=${filtro}`, exibeCarregando);
    }

    getAllByCnpjOuRazaoSocialOuNomeFantasia(tipoCliente: string, filtro: string, cadastrosExcluidos: boolean, exibeCarregando: boolean) {
        //filtro = filtro.replace(/[^a-z0-9]/gi,'');
        filtro = encodeURIComponent(filtro);
        return this.gc.get(this.api, `api/${this.controller}/getAllByCnpjOuRazaoSocialOuNomeFantasia?tipoCliente=${tipoCliente}&filtro=${filtro}&cadastrosExcluidos=${cadastrosExcluidos}`, exibeCarregando);
    }

    getAllByCpfOuNome(tipoCliente: string, filtro: string, cadastrosExcluidos: boolean, exibeCarregando: boolean) {
        filtro = encodeURIComponent(filtro);
        //filtro = filtro.replace(/[^a-z0-9]/gi,'');
        return this.gc.get(this.api, `api/${this.controller}/getAllByCpfOuNome?tipoCliente=${tipoCliente}&filtro=${filtro}&cadastrosExcluidos=${cadastrosExcluidos}`, exibeCarregando);
    }

    getAllByCpf(tipoCliente: string, filtro: string, exibeCarregando: boolean) {
        filtro = encodeURIComponent(filtro);
        return this.gc.get(this.api, `api/${this.controller}/getAllByCpf/${tipoCliente}?filtro=${filtro}`, exibeCarregando);
    }
    
    getAllByEmail(tipoCliente: string, filtro: string, cadastrosExcluidos: boolean, exibeCarregando: boolean) {
        filtro = encodeURIComponent(filtro);
        return this.gc.get(this.api, `api/${this.controller}/getAllByEmail?tipoCliente=${tipoCliente}&filtro=${filtro}&cadastrosExcluidos=${cadastrosExcluidos}`, exibeCarregando);
    }

    getFornecedoresPorTipoCliente(idTipoCliente: string, exibeCarregando: boolean){
        return this.gc.get(this.api, `api/${this.controller}/getFornecedoresPorTipoCliente/${idTipoCliente}`, exibeCarregando);
    }

    getTransportadoraPorIdFornecedor(idFornecedor: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `api/${this.controller}/getTransportadoraPorIdFornecedor/${idFornecedor}`, exibeCarregando);
    }

    removerPessoaFisicaJuridica(cpfCnpj: string, exibeCarregando: boolean){
        cpfCnpj = cpfCnpj.replace(/[^a-z0-9]/gi,'');
        return this.gc.delete(this.api, `api/${this.controller}/removerPessoaFisicaJuridica/${cpfCnpj}`, exibeCarregando);
    }

    post(obj: PessoaFisicaJuridicaModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}`, obj, exibeCarregando);
    }

    put(obj: PessoaFisicaJuridicaModel, exibeCarregando: boolean){
        return this.gc.put(this.api, `api/${this.controller}`, obj, exibeCarregando);
    }

    removerEndereco(idEndereco: number, exibeCarregando: boolean):Observable<void>{
        return this.gc.delete(this.api, `api/${this.controller}/removerEndereco/${idEndereco}`, exibeCarregando);
    }

    salvarEndereco(endereco: EnderecoModel, exibeCarregando: boolean):Observable<EnderecoModel>{
        return this.gc.post(this.api, `api/${this.controller}/salvarEndereco`, endereco, exibeCarregando);
    }

    restaurarPessoaFisicaJuridica(cpfCnpj: string, exibeCarregando: boolean){
        cpfCnpj = cpfCnpj.replace(/[^a-z0-9]/gi,'');
        return this.gc.put(this.api, `api/${this.controller}/restaurarPessoaFisicaJuridica/${cpfCnpj}`, exibeCarregando);
    }
}