import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'cpfCnpj' })
export class CpfCnpjPipe implements PipeTransform {
    transform(value: string|number): string {
        if (value == null || value.toString().length == 0)
        {
            return '';
        }
        else if (value.toString().length == 11) //
        {
            let valorFormatado = value + '';

            valorFormatado = valorFormatado
                .padStart(11, '0')                  // item 1
                .substr(0, 11)                      // item 2
                .replace(/[^0-9]/, '')              // item 3
                .replace(                           // item 4
                    /(\d{3})(\d{3})(\d{3})(\d{2})/,
                    '$1.$2.$3-$4'
                );
    
            return valorFormatado;
        }else
        {
            return value.toString().replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,"\$1.\$2.\$3\/\$4\-\$5");
        }
    }
}
