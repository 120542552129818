import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { PrestadorServicoModel } from 'src/app/models/cliente/PrestadorServico/prestadorServicoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiPrestadorServicoService {
    getById(idPrestadorServico: string): Observable<PrestadorServicoModel> {
        return this.gc.get(this.api, `api/${this.controller}/${idPrestadorServico}`);
    }
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'prestadorServico';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAll(exibeCarregando: boolean): Observable<PrestadorServicoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}`, exibeCarregando);
    }

    get(id: number){
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
    }

    post(obj: PrestadorServicoModel, exibirCarregando: boolean){
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj, exibirCarregando);
    }

    put(obj: PrestadorServicoModel, exibirCarregando: boolean){
        return this.gc.put(this.api, `${this.version}${this.controller}`, obj, exibirCarregando);
    }

    delete(cpfCnpj: string){
        return this.gc.delete(this.api, `${this.version}${this.controller}/${cpfCnpj}`);
    }
}