import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { AppSettings } from 'src/app/app-settings';
import { VendaModelInstance } from 'src/app/models/cliente/Vendum/VendaModelInstance';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ProdutoImagemModel } from 'src/app/models/cliente/ProdutoImagem/produtoImagemModel';
import { ApiProdutoService } from 'src/app/services/cliente/api-produto.service';
import { environment } from 'src/environments/environment';

export interface ImagemParaThumbnailModel {
  thumbnailImageSrc : string;
  previewImageSrc : string;
  titulo: string;
  descricao: string;
  fileName: string;
}

@Component({
  selector: 'app-modal-exibir-imagens',
  templateUrl: './modal-exibir-imagens.component.html',
  styleUrls: ['./modal-exibir-imagens.component.css']
})
export class ModalExibirImagensComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() cancelouVenda: EventEmitter<VendumModel> = new EventEmitter();
  loadingCancelarVenda: boolean = false;
  inputDados: VendumModel = VendaModelInstance.get();
  
  constructor(
    private appCustomToastService: AppCustomToastService,
    private produtoService: ApiProdutoService,
    private showMessageError: ShowMessageError) { }

  ngOnInit(): void {
  }

  images: ImagemParaThumbnailModel[] = [];

  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 12
    },
    {
      breakpoint: '768px',
      numVisible: 8
    },
    {
      breakpoint: '560px',
      numVisible: 4
    }
  ];

  responsiveOptions2: any[] = [
    {
      breakpoint: '1500px',
      numVisible: 5
    },
    {
      breakpoint: '1024px',
      numVisible: 3
    },
    {
      breakpoint: '768px',
      numVisible: 2
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];

  displayBasic: boolean = false;

  displayBasic2: boolean = false;

  displayCustom: boolean = false;

  activeIndex: number = 0;

  // todo: consider preventing default and stopping propagation
  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  
  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
    }
    if (event.keyCode == 13) {
    }
  }

  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
  }

  abrirModal(idProdutoUnico: number) {
    this.getImagens(idProdutoUnico);
    this.modal.show();
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.modal.hide();
  }
  private MODULO: string = "ProdutoImagem";
  produtoImagens: ProdutoImagemModel[] = [];
  subGetProdutoImagem: Subscription = null!;
  getImagens(idProdutoUnico: number){
    if (this.inputDados != null && idProdutoUnico > 0){
      this.subGetProdutoImagem = this.produtoService.getProdutoImagens(idProdutoUnico, true).subscribe(
        (retorno: ProdutoImagemModel[]) => {
          this.subGetProdutoImagem?.unsubscribe();
          this.produtoImagens = retorno;

          

          this.images = [];
          this.produtoImagens.forEach(f => {
            let chave = f.idAnexoArquivoNavigation.chave
            let chaveThumbnail = chave + "_thumbnail";

            //https://storage.cloud.google.com/erp_invare_clie_000003/ProdutoImagem/10531/cocacola_thumbnail.png

            this.images.push({
              thumbnailImageSrc : `${environment.apiSettings.baseUrl[AppSettings.API.File]}/api/file/download/${this.MODULO}/query?chave=${chaveThumbnail}&fileName=${f.idAnexoArquivoNavigation.fileName}`,
              previewImageSrc : `${environment.apiSettings.baseUrl[AppSettings.API.File]}/api/file/download/${this.MODULO}/query?chave=${f.idAnexoArquivoNavigation.chave}&fileName=${f.idAnexoArquivoNavigation.fileName}`,
              titulo: f.idAnexoArquivoNavigation.titulo,
              descricao: f.idAnexoArquivoNavigation.descricao,
              fileName: f.idAnexoArquivoNavigation.fileName
            });
          });
          
        },
        (err) => {
          this.subGetProdutoImagem?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        }
      );
    }
  }
}
