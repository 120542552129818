export class AppSettings {
  public static CODIGO_PAIS = '1058';
  public static DESCRICAO_PAIS = 'Brasil';
  public static TELEFONE_SUPORTE = '(99)9999-9999 / (99)99999-9999'; //utilizar esse mesmo modelo de máscara
  public static WHATSAPP_SUPORTE = '5543999999999';  //código do país+ddd+número

  public static API = {
    NotaFiscal: 'webApiNotaFiscal',
    Relatorio: 'webApiRelatorio',
    Pdv: 'webApiPdv',
    Cliente: 'webApiClie',
    Financeiro: 'webApiFinanceiro',
    Dominios: 'webApiDomn',
    Controle: 'webApiCtrl',
    Config: 'webApiConfig',
    File: 'file'
  }
  public static ID_APLICACAO ='BAK';
  public static MASK = {
    maskInscricaoEstadual : [/\d/, /\d/,/\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/, /\d/,'.', /\d/,/\d/,/\d/], // 000.000.000.000
    maskInscricaoMunicipal : [/\d/, /\d/,/\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/, /\d/,'.', /\d/,/\d/,/\d/], // 000.000.000.000
    //maskCpf : [/\d/, /\d/,/\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/, /\d/,'-', /\d/,/\d/], // 000.000.000-00
    maskCpf : '999.999.999-99',
    //maskCnpj : [/\d/, /\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/, /\d/,'/', /\d/,/\d/,/\d/,/\d/,'-', /\d/,/\d/], // 23.456.977/00001-12
    maskCnpj : '99.999.999/9999-99',
    //maskRg : [ /\d/,/\d/, '.', /\d/, /\d/,/\d/, '.', /\d/, /\d/, /\d/,'-', /[\dxX]/], // 000.000.000-X
    maskRg : '999.999.999-*', // 000.000.000-X
    maskPort : [ /\d/,/\d/, /\d/, /\d/, /\d/], // 0000
    maskNumber9 : [ /\d/,/\d/, /\d/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/], // 000000000
    maskOneNumeric : [ /\d/], // 0
    maskDateSlash : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/], // dd/mm/yyyy
    maskDateDash : [/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // dd-mm-yyyy
    maskHour : [/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/], // 12:59:59
    maskDateHour : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/], // dd-mm-yyyy 12:59:59
    maskMobileNo : [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/], // 9999-999-999
    maskTeleNo : [/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // 9999-9999
    //maskCep : [/\d/, /\d/, /\d/, /\d/,/\d/, '-', /\d/, /\d/, /\d/], // 99999-999
    maskCep : '99999-999', // 99999-999
    //maskTeleAreaCel : ['(', /\d/, /\d/, ')',  /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/], // (99)999999999
    maskTeleAreaCel : '(99)999999999', // (99)999999999
    maskTeleUS : ['(', /\d/, /\d/, /\d/, ')',  /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/], // (999)999-9999
    maskIP : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/], // 255.255.255.255
    maskIPV4 : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/], // 255.255.255.0000
    maskIPV6 : [/\d/, /\d/, /\d/, /\d/, ':', /\d/, /\d/, /\d/,  /\d/, ':', /\d/, /\d/, /\d/, /\d/, ':', /\d/, ':', /\d/, /\d/, /\d/, /\d/, ':', /\d/, /\d/, /\d/, /\d/, ':', /\d/, /\d/, /\d/], // 9999:9999:9999:9:9999:999
    deciamal53: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, ',', /\d/, /\d/, /\d/],
    deciamal92: [/\d/, /\d/, /\d/, '.',/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, ',', /\d/, /\d/],
    numerico11: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/],
    numerico2: [/\d/, /\d/]
  };
}