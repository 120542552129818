<app-ui-modal #modalEnvioNf [containerClick]="false" [hideHeader]="true" [hideFooter]="false">
    <div class="app-modal-body">
      <div class="col-md-12 text-center">
        <img src="assets/images/atualizar.gif" alt="" class=" mr-3">
      </div>
      <div class="col-md-12 texto-aviso-modal-processando-nota-fiscal">
        {{mensagemEnvioNf}}
      </div>
      <!-- <div class="col-md-12 text-center">
        <h4 class="m-0 text-bold">{{contador}} Tentativa(s)</h4>
      </div> -->
    </div>
    <div class="app-modal-footer">
        <button (click)="cliqueCancelar()" type="button" class="btn btn-outline-secondary m-r-5"><i
                class="feather icon-arrow-left"></i> Cancelar </button>
    </div>
  </app-ui-modal>