import { NotaFiscalTransVolModel } from "./notaFiscalTransVolModel";


export class NotaFiscalTransVolModelInstance {
    public static get(): NotaFiscalTransVolModel {
        return {
            idNotaFiscalTransVol: 0,
            idNotaFiscal: 0,
            quantidadeVolumesTransportados: 0,
            especieVolumesTransportados: '',
            marcaVolumesTransportados: '',
            numeracaoVolumesTransportados: '',
            pesoLiquidoEmKg: 0,
            pesoBrutoEmKg: 0,
            idNotaFiscalNavigation: null!,
            notaFiscalTransVolLacres: [
                {
                    idNotaFiscalTransVolLacre: 0,
                    idNotaFiscalTransVol: 0,
                    numeroLacres: '',
                    idNotaFiscalTransVolNavigation: null!
                }
            ]
        };
    }
    public static getArray(): NotaFiscalTransVolModel[] {
        return [this.get()];
    }
}
