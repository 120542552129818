<app-ui-modal #modal [containerClick]="false" (close)="cliqueFechar()" [dialogClass]="'modal-xl'">
  <div class="app-modal-header col-12">
    <div class=" row">
      <div class="col-md-12 text-left ">
        <h4 class="m-0 text-bold ">&nbsp;<i class="fas fa-paperclip"></i>&nbsp;{{titulo}}</h4>
      </div>
    </div>
  </div>
  <div class="app-modal-body">
    <div class="row">
      <div class="col-6">
        <p class="m-b-10">{{descricao}}</p>
      </div>
      <div class="col-6">
        <p class="m-b-10 m-t-5 text-c-red size-80 text-right">* Tamanho máximo por arquivo (30mb)</p>
      </div>
    </div>
    <div *ngIf="uploading == true" class="row">
      <div class="col-12">
        {{mensagemUpload}}
      </div>
      <div class="col-12">
        <ngb-progressbar [showValue]="true" [value]="porcentagemUpload"></ngb-progressbar>
      </div>
    </div>
    <div class="row " *ngIf="showFiles && (modoTela == 'Cadastrar' || modoTela == 'Alterar')">
      <div class="col-12">
        <p-fileUpload name="filesUpload[]" url="" (onBeforeUpload)="onBeforeUpload($event)" #fileUPload [fileLimit]="50"
          (onUpload)="onUpload($event)" (onSelect)="onSelect($event)" (onRemove)="onRemove($event)"
          (onClear)="onClear($event)" (uploadHandler)="uploadHandler($event)" [previewWidth]="100"
          chooseLabel="Selecionar" uploadLabel="Upload" cancelLabel="Cancelar" [customUpload]="true"
          [showUploadButton]="true" [showCancelButton]="true" [mode]="mode" [previewWidth]="0" [multiple]="false"
          styleClass="" [maxFileSize]="30000000" invalidFileSizeMessageSummary="invalidFileSizeMessageSummary"
          invalidFileSizeMessageDetail="invalidFileSizeMessageDetail">
          <ng-template pTemplate="content">
            <ul *ngIf="files.length">
              <li *ngFor="let file of files">{{file.name}} - {{file.size}} bytes</li>
            </ul>
            <ul *ngIf="arquivosJaCadastrados.length">
              <li *ngFor="let item of arquivosJaCadastrados">{{item.idAnexoArquivoNavigation.caminhoCompleto}}</li>
            </ul>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
    <p-table *ngIf="showFiles" #tblArquivos [value]="arquivos" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
      class="p-table table-hover" [rowsPerPageOptions]="[10,25,50]" [loading]="loading" styleClass=""
      [paginator]="false" currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros"
      [globalFilterFields]="['fileName', 'dataRegistro','dataAlteracao']">
      <ng-template pTemplate="caption" *ngIf="exibePesquisa">
        <div class="row">
          <div class="col-12">
            <div class="input-group input-group-sm">
              <span class="input-group-text" id="inputGroup-sizing-sm"><i class="pi pi-search"></i></span>
              <input type="text" class="form-control"
                (input)="tblArquivos.filterGlobal($event.target.value, 'contains')" placeholder="Pesquisar" />
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th style="width:70%" class="text-center">Arquivo</th>
          <!-- <th style="width:10%" class="text-center">Cpf Registro</th> -->
          <th style="width:10%" class="text-center">Data Registro</th>
          <!-- <th style="width:25%" class="text-center">Cpf Atualização</th>
                  <th style="width:15%" class="text-center">Data Atualização</th> -->
          <th style="width:10%" class="text-center">Ação</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item>
        <tr>
          <td class="align-middle " (click)="download(item.fileName, item.contentType)"><span
              class="link text-c-blue mouse-pointer">{{item?.fileName }}</span></td>
          <!-- <td class="align-middle quebra-linha text-center">{{item?.cpfRegistro }}</td> -->
          <td class="align-middle quebra-linha text-center">{{item?.updated}}</td>
          <!-- <td class="align-middle quebra-linha text-center">{{item?.cpfAlteracao }}</td>
                  <td class="align-middle quebra-linha text-center">{{item?.dataAlteracao | date: 'dd/MM/yyyy' }} -->
          <td class="table-action nowrap text-center">
            <a *ngIf="(modoTela == 'Cadastrar' || modoTela == 'Alterar')" ngbTooltip="Download" class=" "
              (click)="download(item.fileName, item.contentType)"><i
                class="mouse-pointer text-c-blue fad fa-download f-16 m-r-5"></i></a>
            <a *ngIf="!(modoTela == 'Cadastrar' || modoTela == 'Alterar')" ngbTooltip="Download" class=" "
              (click)="download(item.fileName, item.contentType)"><i
                class="mouse-pointer text-c-blue fad fa-download f-16 "></i></a>
            <a *ngIf="modoTela == 'Cadastrar' || modoTela == 'Alterar'" ngbTooltip="Excluir" class=""
              (click)="delete(item.fileName)"><i class="mouse-pointer text-c-red fad fa-trash-alt f-16 "></i></a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td colspan="6">Não há arquivos</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="app-modal-footer">
    <button type="button" (click)="cliqueFechar()" #elmBotaoCancelar class="btn btn-secondary inline"><i
        class="fad fa-window-close"></i> Fechar</button>&nbsp;
  </div>
</app-ui-modal>