import { EstoqueModel } from "./estoqueModel";



export class EstoqueModelInstance {
    public static get(): EstoqueModel {
        return {
            idProdutoUnico: 0,
            idLoja: 0,
            idProdutoVariacao: 0,
            quantidadeContabil: 0,
            quantidadeReservado: 0,
            quantidadeCondicional: 0,
            quantidadeCrossDocking: 0,
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            idProduto: null!,
            idProdutoUnicoNavigation: null!,
            estoqueControleValidadeProdutos: null!,
            estoqueMovimentacaos: null!,
            estoqueTabelaLocalizacaoProdutos: null!,
            mensagemAlerta: null!,
            estoqueControleCrossDockings: null!,
            quantidadeVirtual: 0,
            quantidadeMaximaContabil: 0,
            quantidadeMaximaVirtual: 0,
            quantidadeMinimaContabil: 0,
            quantidadeMinimaVirtual: 0
        };
    }

    public static getArray(): EstoqueModel[] {
        return [this.get()];
    };
}
