<div class="bg-sistema">
    <div class="rt ">
        <div>
            <img src="assets/images/clock.png"> <span>{{DataHora}}</span>
        </div>
        <button class="click-menu-direito">
            <img src="assets/images/menu.png">
        </button>
        <nav>
            <a title="Inicio (Home)" class="mouse-pointer " (click)="navegarPaginaInvare()">
                <img src="assets/images/invare.png" class="logo">
            </a>
            <ul>
                <li>
                    <a title="Inicio (Home)" class="mouse-pointer " (click)="navegarPaginaMenuPdv()">
                        <i class="fad fa-home"></i>&nbsp; <span>Inicio (Home)</span>
                    </a>
                </li>
                <li>
                    <a title="Caixa" class="mouse-pointer " (click)="navegarPaginaPdv()">
                        <i class="fad fa-desktop"></i>&nbsp; <span>Pdv</span>
                    </a>
                </li>
                <li>
                    <a title="Abrir comanda" class="mouse-pointer " (click)="navegarPaginaAbrirComanda()">
                        <i class="fad fa-ballot-check"></i>&nbsp; <span>comanda</span>
                    </a>
                </li>
                <li>
                    <a title="Suprimento"  class="mouse-pointer " (click)="abrirModalSuprimento()">
                        <i class="fad fa-sack-dollar"></i>&nbsp; <span>Suprimento</span>
                    </a>
                </li>
                <li >
                    <a title="Sangria"  class="mouse-pointer " (click)="abrirModalSangria()">
                        <i class="fad fa-sack-dollar"></i>&nbsp; <span>Sangria</span>
                    </a>
                </li>
                <li *ngIf="situacaoCaixa == situacaoCaixaAberto">
                    <a title="Fechar Caixa"  class="mouse-pointer " (click)="abrirFecharCaixa('F')">
                        <i class="fad fa-cash-register"></i>&nbsp;<span>Fechar Caixa</span>
                    </a>
                </li>
                <li *ngIf="situacaoCaixa == situacaoCaixaAberto">
                    <a title="Selecionar Tabela de Preço"  class="mouse-pointer " (click)="alterarTabelaDePreco()">
                        <i class="fad fa-usd-square"></i>&nbsp;<span>Tabela de Preço</span>
                    </a>
                </li>
                <li *ngIf="situacaoCaixa == situacaoCaixaFechado">
                    <a title="Abrir Caixa"  class="mouse-pointer " (click)="abrirFecharCaixa('A')">
                        <i class="fad fa-cash-register"></i>&nbsp; <span>Abrir Caixa</span>
                    </a>
                </li>
                <!-- <li>
                    <a title="Suporte"  class="mouse-pointer "  (click)="navegarPaginaSuporte()">
                        <i class="fad fa-user-headset"></i>&nbsp; <span>Suporte</span>
                    </a>
                </li> -->
                <li>
                    <a title="Sistema Retaguarda" class="mouse-pointer " (click)="navegarPaginaRetaguarda()">
                        <i class="fad fa-cog"></i>&nbsp; <span>Retaguarda</span>
                    </a>
                </li>
                <li>
                    <a title="Configuração" class="mouse-pointer " (click)="navegarPaginaLogin()">
                        <i class="fad fa-sign-out"></i>&nbsp; <span>Sair</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
    <aside class="big asideoff">
        <div>
            <div class="top">
                <a href="#" class="logo">
                <!-- <img src="assets/images/pao-de-acucar.png"> -->
                <img class="img-radius" [attr.src]="urlEmpresa | secure | async" alt="Logo da Empresa" *ngIf="isAuthenticated" />
                <img class="img-radius" src="assets/images/semEmpresa.png" alt="Logo da Empresa" *ngIf="!isAuthenticated" />
                </a>
                <h1>
                    <img [src]="urlImage"> {{greetingMessage}}
                </h1>
                <h2>
                    {{nomeUsuario}} <br>
                    <small><span class="undeline">TABELA DE PREÇO</span>&nbsp;<i  class="fad fa-edit mouse-pointer" (click)="alterarTabelaDePreco()"></i> <br>
                        <b>{{tabelaDePreco}}</b></small>
                </h2>
            </div>
            <h3>
                Status Produtos&nbsp;<a ngbTooltip="Atualizar Prdutos" class=""  style="cursor:hand;"
                (click)="atualizarProdutosServicos()"><i class="mouse-pointer fas fa-sync-alt text-c-green f-16 "></i></a>
            </h3>
            <ul>
                 <li class="status on" *ngIf="verificarDadosAtualizados()">
                    <i></i>
                    <span >
                        {{dataUltimaAtualizacao | date: 'dd/MM/yyyy HH:mm:ss' }}  
                    </span>
                </li>
                <li class="status off" *ngIf="!verificarDadosAtualizados()">
                    <i></i>
                    <span >
                        {{dataUltimaAtualizacao | date: 'dd/MM/yyyy HH:mm:ss' }}  
                    </span>
                </li>
                <!-- <li class="status on" >
                    <i></i>
                    <span >
                        {{dataAtual | date: 'dd/MM/yyyy' }}
                    </span>
                </li>  -->
            </ul>
            <h2>
                {{nomeLoja}}
            </h2>

        </div>
        <!-- <a href="#" class="logo-sistema">
            <img src="assets/images/invare-wt.png" alt="Invare">
        </a> -->
    </aside>


    <div class="lf asideoff small ">
        <a title="Página Home" class="back" (click)="navegarPaginaMenuPdv()" class="mouse-pointer"  >
            <img src="assets/images/icons8-página-inicial-50.png" alt="Home">
        </a>
        <a title="Consultar Preço" class="back" (click)="abrirModalConsultarPreco()" class="mouse-pointer"  ngbTooltip="Preço">
            <!-- <img src="assets/images/onoff.png" alt="Desligar"> -->
            <img src="assets/images/icons8-dolar-americano-50.png" alt="Consulatr Preço">
            
            <!-- <i class="fad fa-hand-holding-usd"></i> -->
        </a>
        <a title="Full Screen" class="back" (click)="handleScreen()" class="mouse-pointer"  ngbTooltip="Full Screen" *ngIf="textoMenuFullScreen == 'Full'">
            <img src="assets/images/icons8-ecra-inteiro-50.png" alt="Full Screen">
        </a>
        <a title="Normal Screen" class="back" (click)="handleScreen()" class="mouse-pointer"  ngbTooltip="Normal Screen"  *ngIf="textoMenuFullScreen == 'Normal'">
            <img src="assets/images/icons8-vista-de-página-completa-50.png" alt="Normal Screen">
        </a> 
        <div class="header">
            <div class="row">
                <!-- <div class="col-1 iconScreen">
                        <div class="dropdown drp-user cursor-pointer" ngbDropdown placement="auto" (click)="handleScreen()">
                            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
                                <i [class]="iconScreen" style="font-size: 36px;"></i>
                            </a>
                        </div>
                </div> -->
                <div class="col-4 labelUsuario">
                    <h2><small><strong>{{nomeUsuario}}</strong></small><br>
                        <small>CPF: {{cpf | cpfCnpj}}</small>
                    </h2>
                </div>
                <div class="col-4">
                    <h2><small><strong>PDV: {{codigoPdv}}</strong></small><br>
                        <small>CAIXA: {{idCaixa}} - {{descricaoTurno}}</small>
                    </h2>
                </div>
                <div class="col-4">
                    <ul>
                        <li *ngIf="situacaoCaixa == 'A'">
                            <i class="fad fa-cash-register text-c-green"></i>&nbsp;<span  class="text-c-green">Caixa Aberto</span>
                        </li>
                        <li *ngIf="situacaoCaixa != 'A'">
                            <i class="fad fa-cash-register  text-c-red"></i>&nbsp;<span class="text-c-red">Caixa Fechado</span>
                        </li>
                        <li *ngIf="situacaoCaixa == 'A'">
                            <span>{{dataAberturaCaixa | date: 'dd/MM/yyyy HH:mm:ss' }}</span>
                        </li>
                    </ul>
                </div>
                <div class="col-1">
                    
                </div>
            </div>
        </div>
    </div>
    <aside class="small asideoff">
        <div>
            <div class="top">
                <a href="#" class="logo">
                    <!-- <img src="assets/images/pao-de-acucar.png"> -->
                    <img class="img-radius" [attr.src]="urlEmpresa | secure | async" alt="Logo da Empresa" *ngIf="usuarioLogado" />
                    <img class="img-radius" src="assets/images/semEmpresa.png" alt="Logo da Empresa" *ngIf="!usuarioLogado" />
                </a>
            </div>


            <h3>

            </h3>
            <ul>
                <li class="status on" *ngIf="verificarDadosAtualizados()">
                    <i></i>
                </li>
                <li class="status off" *ngIf="!verificarDadosAtualizados()">
                    <i></i>
                </li>
            </ul>
        </div>
        <a href="#" class="logo-sistema">
            <img src="assets/images/invare-icon-wt.png" alt="Invare">
        </a>
    </aside>
    <div class="router-outlet  small miolo-sistema asideoff">
        <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
    </div>
</div>
<app-modal-sangria-suprimento
    (executouSangriaSuprimento)="executouSangriaSuprimento($event)"
    (fechouModal)="fechouModal($event)"
    #modalSangriaSuprimentoComponent
></app-modal-sangria-suprimento>
<app-modal-abrir-fechar-caixa
    (abriuFechouCaixa)="abriuFechouCaixa($event)"
    (fechouModal)="fechouModal($event)"
    #modalSelecaoCaixaComponent>
</app-modal-abrir-fechar-caixa>
<app-modal-consultar-preco #modalConsultarPrecoComponent
    (fechouModal)="fechouModal($event)"
>
</app-modal-consultar-preco>
<app-modal-selecionar-tabela-preco #modalSelecionarTabelaPrecoComponent
    (fechouModal)="fechouModal($event)"
    (selecionouTabelaPreco)="selecionouTabelaPreco($event)"
>
</app-modal-selecionar-tabela-preco>