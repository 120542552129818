import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { PdfService, PrinterDevice } from 'src/app/core/util/pdfService';
import { enumFomatoImpressao } from 'src/app/enum/enumFomatoImpressao';
import { enumTipoImpressao } from 'src/app/enum/enumTipoImpressao';
import { ListaFixaImpressaoModel } from 'src/app/models/cliente/ParametrizacaoEnvioImpressora/ListaFixaImpressaoModel';
import { ParametrizacaoEnvioImpressoraModel } from 'src/app/models/cliente/ParametrizacaoEnvioImpressora/ParametrizacaoEnvioImpressoraModel';
import { ParametrizacaoEnvioImpressoraModellnstance } from 'src/app/models/cliente/ParametrizacaoEnvioImpressora/ParametrizacaoEnvioImpressoraModellnstance';
import { ApiTiposRelatoriosImpressoesService } from 'src/app/services/cliente/api-tipos-relatorios-impressoes.service';
import { UtilService } from 'src/app/shared/services/util.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-crud-parametrizacao-envio-impressora',
  templateUrl: './modal-crud-parametrizacao-envio-impressora.component.html',
  styleUrls: ['./modal-crud-parametrizacao-envio-impressora.component.scss']
})
export class ModalCrudParametrizacaoEnvioImpressoraComponent implements OnInit, OnDestroy {

  @ViewChild(UiModalComponent) modal;
  @Output() atualizar: EventEmitter<any> = new EventEmitter();
  inputTemporario: ListaFixaImpressaoModel = null!;
  inputDados: ParametrizacaoEnvioImpressoraModel = ParametrizacaoEnvioImpressoraModellnstance.get();
  tiposImpressoes: IOption[] = UtilService.getTiposImpressoes();
  tiposLayout: IOption[] = [];
  subsUpdate: Subscription | null = null!;

  tipoImpressaoDireta: enumTipoImpressao = enumTipoImpressao.Direta;
  mensagemImpressaoDireta: string = "* Para impressão [Direta] é obrigatório ter o serviço de impressão Ativo.";
  exibirInformacoesImpressaoDireta: boolean = false;
  
  constructor(private apiTiposRelatoriosImpressoesService: ApiTiposRelatoriosImpressoesService,
    private showMessageError: ShowMessageError,
    private pdfService: PdfService) { }

  ngOnInit(): void {
    this.setStartObject();
  }

  printerDevices: PrinterDevice[] = []; // Lista de impressoras
  subsPingServer: Subscription = null!;
  subsGetPrinters: Subscription = null!;
  onChangeTipoImpressao(event) {
    let index = event.target.selectedIndex;
    let itemSelecionado = this.tiposImpressoes[index];
    let itemValorSelecionado = itemSelecionado.value;
    let url: string = '';
    url = this.inputTemporario.url;
    this.gerenciarTipoImpressaoDireta(itemValorSelecionado, url);
  }
  serverImpressao: string = '';
  private gerenciarTipoImpressaoDireta(itemValorSelecionado: string, url: string) {
    if (itemValorSelecionado == enumTipoImpressao.Direta) {
      
      this.subsPingServer = this.pdfService.pingServer(url).subscribe(
        (retorno: boolean) => {
          this.subsPingServer?.unsubscribe();

          this.exibirInformacoesImpressaoDireta = retorno;
          if (retorno) {
            this.subsGetPrinters = this.pdfService.getPrinters(url).subscribe(
              (retornoPrinters: PrinterDevice[]) => {
                this.subsGetPrinters?.unsubscribe();

                if (retornoPrinters) {
                  //aqui deve preencher a lista de impressoras
                  // Preencher a lista de impressoras
                  this.printerDevices = retornoPrinters;
                }
              },
              (err) => {
                this.subsGetPrinters?.unsubscribe();
                this.exibirInformacoesImpressaoDireta = true;
                this.mensagemImpressaoDireta = 'O serviço de impressão direta não está ativo ou não foi instalado. Contate o suporte ou acesse configurações de impressão na página de configurações para maiores informações';
              });

          }
          else {
            this.exibirInformacoesImpressaoDireta = true;
            this.mensagemImpressaoDireta = 'O serviço de impressão direta não está ativo ou não foi instalado. Contate o suporte ou acesse configurações de impressão na página de configurações para maiores informações';
          }
        },
        (err) => {
          this.subsPingServer?.unsubscribe();
          this.exibirInformacoesImpressaoDireta = true;
          this.mensagemImpressaoDireta = 'O serviço de impressão direta não está ativo ou não foi instalado. Contate o suporte ou acesse configurações de impressão na página de configurações para maiores informações';
        });

    } else {
      this.exibirInformacoesImpressaoDireta = true;
      this.exibirInformacoesImpressaoDireta = false;
    }
  }


  ngOnDestroy(): void {
    this.subsUpdate?.unsubscribe();
  }

  onFoucus(event){
    setTimeout(()=>{ 
      event.target.select();
    },200);  
  }

  cliqueCancelarCadastrar(){
    this.modal.hide();
    this.showMessageError.limparMensagensAlerta();    
    this.setStartObject();
  }

  public abrirModal(edit: ListaFixaImpressaoModel) {
    if (edit != null) { //alteração
      this.inputTemporario = JSON.parse(JSON.stringify(edit));
      this.tratarCampos();
    }else{
      this.setStartObject();
    }
    this.modal.show();
  }

  private tratarCampos() {
    if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoA4) {
      this.tiposLayout = [{ label: 'A4', value: enumFomatoImpressao.FormatoA4 }];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoA5) {
      this.tiposLayout = [{ label: 'A5', value: enumFomatoImpressao.FormatoA5 }];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoCupomFiscal) {
      this.tiposLayout = [{ label: 'Cupom Fiscal', value: enumFomatoImpressao.FormatoCupomFiscal }];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoEtiqueta) {
      this.tiposLayout = [{ label: 'Etiqueta', value: enumFomatoImpressao.FormatoEtiqueta }];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoA4A5) {
      this.tiposLayout = [{ label: 'A4', value: enumFomatoImpressao.FormatoA4 }, { label: 'A5', value: enumFomatoImpressao.FormatoA5 }];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoA4CupomFiscal) {
      this.tiposLayout = [{ label: 'A4', value: enumFomatoImpressao.FormatoA4 }, { label: 'Cupom Fiscal', value: enumFomatoImpressao.FormatoCupomFiscal }];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoA4A5CupomFiscal) {
      this.tiposLayout = [{ label: 'A4', value: enumFomatoImpressao.FormatoA4 }, { label: 'A5', value: enumFomatoImpressao.FormatoA5 },
      { label: 'Cupom Fiscal', value: enumFomatoImpressao.FormatoCupomFiscal }
      ];
    }
    else if (this.inputTemporario.tipoFormatoDisponivel == enumFomatoImpressao.FormatoA5CupomFiscal) {
      this.tiposLayout = [{ label: 'A5', value: enumFomatoImpressao.FormatoA5 }, { label: 'Cupom Fiscal', value: enumFomatoImpressao.FormatoCupomFiscal }];
    }

    this.gerenciarTipoImpressaoDireta(this.inputTemporario.tipoImpressao, this.inputTemporario.url);
  }

  setStartObject(){
    this.inputTemporario = {
      codigoDocumentoImpresso: '',
      idPdv: null,
      tipoImpressao: '',
      quantidadeImpressao: 1,
      url: '',
      nomeImpressora: '',
      formato: '',
      documentoImpresso: '',
      tipoFormatoDisponivel: '',
    };
  }

  cliqueSalvar(){
    this.preencherObjetoEExecutarAlteracao(this.inputTemporario);
  }

  preencherObjetoEExecutarAlteracao(item: ListaFixaImpressaoModel) {
    this.inputDados.codigoDocumentoImpresso = item.codigoDocumentoImpresso;
    this.inputDados.idPdv = item.idPdv;
    this.inputDados.tipoImpressao = item.tipoImpressao;
    this.inputDados.quantidadeImpressao = item.quantidadeImpressao;
    this.inputDados.url = item.url;
    this.inputDados.nomeImpressora = item.nomeImpressora;
    this.inputDados.formato = item.formato;
    this.inputDados.documentoImpresso = item.documentoImpresso;

    //chamar api
    this.subsUpdate = this.apiTiposRelatoriosImpressoesService.alterarParametrizacaoEnvioImpressora(this.inputDados).subscribe(
      (retorno: ParametrizacaoEnvioImpressoraModel) => {
        this.subsUpdate?.unsubscribe();
        if(retorno != null){
          Swal.fire({
            title: "Sucesso",
            html: "Registro alterado com sucesso!",
            confirmButtonText: `Ok`,
            showCancelButton: false,
            focusConfirm: true,
            timer: 3000,
            icon: 'success'
          })
          .then((result) => {
            this.modal.hide();
            this.atualizar.emit(retorno);
          });
        }
      },
      (err) => {
        this.subsUpdate?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  } 

  

  atualizarImpressao(evt: any){
    
  }
}