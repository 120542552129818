import { NumberSymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { enumStatus } from 'src/app/enum/enumStatus';
import { CaixaModel } from 'src/app/models/cliente/Caixa/caixaModel';
import { VendaPorMeioPagamentoModel } from 'src/app/models/cliente/Caixa/VendaPorMeioPagamentoModel';
import { AbrirFecharCaixaModel } from 'src/app/models/cliente/CaixaControle/AbrirFecharCaixaModel';
import { CaixaControleCedulaModel } from 'src/app/models/cliente/CaixaControle/caixaControleCedulaModel';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { CaixaMovimentoModel } from 'src/app/models/cliente/CaixaMovimento/caixaMovimentoModel';
import { CaixaSangriaSuprimentoModel } from 'src/app/models/cliente/CaixaSangriaSuprimento/caixaSangriaSuprimentoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiCaixaService {

    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private controller = 'caixa';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }


    getById(idCaixa: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `api/${this.controller}/${idCaixa}`, exibeCarregando);
    }

    listarVendasPorMeioPagamento(idCaixaControle: number, exibeCarregando: boolean ): Observable<VendaPorMeioPagamentoModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/listarVendasPorMeioPagamento/${idCaixaControle}`, exibeCarregando);
    }

    getTotalMovimentoByIdCaixaControleEMeioPagamento(idCaixaControle: number, idTabelaMeioPagamento: string, exibeCarregando: boolean): Observable<number> {
        return this.gc.get(this.api, `api/${this.controller}/getTotalMovimentoByIdCaixaControleEMeioPagamento/${idCaixaControle}/${idTabelaMeioPagamento}`, exibeCarregando);
    }

    getCaixaControleById(idCaixaControle: number, exibeCarregando: boolean): Observable<CaixaControleModel> {
        return this.gc.get(this.api, `api/${this.controller}/getCaixaControleById/${idCaixaControle}`, exibeCarregando);
    }

    getCaixaControleAbertoByIdCaixa(idCaixa: number, exibeCarregando: boolean): Observable<CaixaControleModel> {
        return this.gc.get(this.api, `api/${this.controller}/getCaixaControleAbertoByIdCaixa/${idCaixa}`, exibeCarregando);
    }

    getCaixaControleByIdCaixaControle(idCaixaControle: number, exibeCarregando: boolean): Observable<CaixaControleModel> {
        return this.gc.get(this.api, `api/${this.controller}/getCaixaControleByIdCaixaControle/${idCaixaControle}`, exibeCarregando);
    }

    getAllByStatus(status: enumStatus, exibeCarregando: boolean ): Observable<CaixaModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getAllByStatus/${status}`, exibeCarregando);
    }

    abrirCaixa(caixa: AbrirFecharCaixaModel, exibeCarregando: boolean) {
        return this.gc.post(this.api, `api/${this.controller}/abrirCaixa`, caixa, exibeCarregando);
    }

    executarSangriaSuprimento(caixaSangriaSuprimento: CaixaSangriaSuprimentoModel, quitado: boolean, exibeCarregando: boolean) {
        return this.gc.post(this.api, `api/${this.controller}/executarSangriaSuprimento/${quitado}`, caixaSangriaSuprimento, exibeCarregando);
    }

    fecharCaixa(caixa: AbrirFecharCaixaModel, exibeCarregando: boolean) {
        return this.gc.post(this.api, `api/${this.controller}/fecharCaixa`, caixa, exibeCarregando);
    }

    contagemCedulasLog(log: CaixaControleCedulaModel, exibeCarregando: boolean) {
      return this.gc.post(this.api, `api/${this.controller}/contagemCedulasLog`, log, exibeCarregando);
    }

    getAllBySituacao(situacao: enumSituacaoCaixa): Observable<CaixaModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getAllBySituacao/${situacao}`, true);
    }
    // movimentarCaixa(caixaMovimento: CaixaMovimentoModel, exibeCarregando: boolean) {
    //     return this.gc.post(this.api, `api/${this.controller}/movimentarCaixa`, caixaMovimento, exibeCarregando);
    // }

}
