import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { ComboCadastroComercialLazyLoadModule } from '../combo-pessoa-fisica-juridica/combo-pessoa-fisica-juridica-lazyload.module';
import { ModalSelecionarTabelaPrecoComponent } from './modal-selecionar-tabela-preco.component';

@NgModule({
  declarations: [
    ModalSelecionarTabelaPrecoComponent
  ],
  imports: [
    CommonModule,
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    NgbTooltipModule,
    SharedModule,
    NgbCollapseModule
  ],
  exports:[
    ModalSelecionarTabelaPrecoComponent, 
    CommonModule, 
    MultiSelectModule,
    DropdownModule,
    InputTextModule,
    NgbTooltipModule,
    SharedModule
  ]
})
export class ModalSelecionarTabelaPrecoModule { }