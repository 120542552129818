<div class="topo-miolo-pdv" >
    <div class="col-2 ps-2">
        <span class="title-topo-miolo">
            <i class="fad fa-shopping-cart text-c-white icon-topo-miolo"></i> Caixa
        </span>
    </div>
    
    <div class="text-center col-6" *ngIf="inputDados == null">
    
    </div>
    <div class="text-start col-2" *ngIf="inputDados != null">
        <div class="row">
            <div class="col-8">
                <li>Venda#&nbsp;<b>{{inputDados.idVenda}}</b></li>
                <li *ngIf="importouOrcamento == 'S'">Orçamento#&nbsp;<b>{{inputDados.idOrcamento}}</b></li>
                <li *ngIf="inputDados != null && inputDados.idContrato != null">Contrato#&nbsp;<b>{{inputDados.idContrato}}</b></li>
            </div>
            <div class="col-4" style="padding-top: 5px;">
                <label style="height: 33px;border: 1px solid #FFFFFF;"></label>
            </div>
        </div>
    </div>
   
    <div *ngIf="inputDados != null && inputDados.cpfCnpjNavigation != null" class="col-2">
        Cliente<br>
        <a (click)="exibirInformacoesCliente()" class="mouse-pointer text-c-white text-decoration-underline" alt="inputDados.cpfCnpjNavigation.razaoSocialNome">
            {{NomeCliente}}</a>
    </div>
    <div *ngIf="inputDados != null && inputDados.cpfCnpjNavigation == null" class="col-2">
        <li>Cliente não informado</li>
    </div>
    <div class="col-2 text-center" *ngIf="inputDados != null">
        <b>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusVenda == 1"><label
                class="badge bg-warning text-c-white fs-7">Em Preenchimento</label></span>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusVenda == 2"><label
                class="badge bg-secondary text-c-white fs-7">Pagamento</label></span>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusVenda == 3"><label
                class="badge bg-success text-c-white fs-7">Finalizada</label></span>
            <span class="text-center" *ngIf="inputDados.idTabelaStatusVenda == 4"><label
                class="badge bg-danger text-c-white fs-7">Cancelada</label></span>    
            <span class="text-center" *ngIf="inputDados.idTabelaStatusVenda == 5"><label
                class="badge bg-danger text-c-white fs-7">Devolvida</label></span>    
            <span class="text-center" *ngIf="inputDados.idTabelaStatusVenda == 6"><label
                class="badge bg-danger text-c-white fs-7">Pend. Devolução</label></span>    
        </b>
    </div>
    <div class="col-2" *ngIf="inputDados == null">
     
    </div>
    <div class="col-4 actns text-center">
        <div class="row row-icone-atalho-header">
            <div class="col-2">
                <li>
                    <span appDebounceClick (debounceClick)="caixaLivre(inputDados)" class="mouse-pointer   " ngbTooltip="Caixa Livre" *ngIf="!loading">
                        <!-- <img class="icone-atalho-header" src="assets/images/svg/nova-venda.svg">  fa-cash-register-->
                        <i class="fad fa-shopping-cart f-32 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para deixar o Caixa Livre" *ngIf="loading">
                        <!-- <img class="icone-atalho-header" src="assets/images/svg/nova-venda.svg"> -->
                        <i class="fad fa-shopping-cart f-32 text-c-white"></i>
                    </span>
                </li>
            </div>
            <!-- <div class="col-2">
                <li>
                    <span appDebounceClick (debounceClick)="iniciarVenda(null!, tipoServico)" class="mouse-pointer   " ngbTooltip="Nova Venda Serviço" *ngIf="!loading">
                        <img class="icone-atalho-header" src="assets/images/svg/nova-venda-servico.svg">
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Iniciar uma Nova Venda" *ngIf="loading">
                        <img class="icone-atalho-header" src="assets/images/svg/nova-venda-servico.svg">
                    </span>
                </li>
            </div> -->
            <div class="col-2">
                <li>
                    <span appDebounceClick (debounceClick)="listarVendasPendentes()" class="mouse-pointer" ngbTooltip="Vendas Pendentes" *ngIf="!loading">
                        <!-- <img class="icone-atalho-header" src="assets/images/svg/lista-pendente.svg"> -->
                        <i class="fad fa-list f-32 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Visualizar" *ngIf="loading">
                        <!-- <img class="icone-atalho-header" src="assets/images/svg/lista-pendente.svg"> -->
                        <i class="fad fa-list f-32 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null">
                <!-- jander -->
                <li>
                    <span appDebounceClick (debounceClick)="abrirModalInformacoesAdicionais()" class="mouse-pointer" ngbTooltip="Informações Adicionais" *ngIf="!loading">
                        <!-- <img class="icone-atalho-header" src="assets/images/svg/info-quadrado.svg"> -->
                        <i class="fad fa-info-square f-32 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para acessar Inf. Adicionais" *ngIf="loading">
                        <!-- <img class="icone-atalho-header" src="assets/images/svg/info-quadrado.svg"> -->
                        <i class="fad fa-info-square f-32 text-c-white"></i>
                    </span>
                    <!-- <span appDebounceClick (debounceClick)="selecionarVendedor()" class="mouse-pointer   " ngbTooltip="Vendedor" *ngIf="!loading">
                        <img class="icone-atalho-header" src="assets/images/svg/vendedor.svg">
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Selecionar o Vendedor" *ngIf="loading">
                        <img class="icone-atalho-header" src="assets/images/svg/vendedor.svg">
                    </span> -->
                </li>
            </div>
            <!-- <div class="col-2 " *ngIf="inputDados != null">
                <li>
                    <span appDebounceClick (debounceClick)="selecionarPrestador()" class="mouse-pointer   " ngbTooltip="Prestador de Serviço" *ngIf="!loading">
                        <img class="icone-atalho-header" src="assets/images/svg/prestador-serv.svg">
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Selecionar o Prestador de Serviço" *ngIf="loading">
                        <img class="icone-atalho-header" src="assets/images/svg/prestador-serv.svg">
                    </span>
                </li>
            </div> -->
            <div class="col-2" *ngIf="inputDados != null">
                <li>
                    <span appDebounceClick (debounceClick)="cancelarVenda()" class="mouse-pointer" ngbTooltip="Cancelar Venda" *ngIf="!loading
                        && (inputDados.idTabelaStatusVenda == 1 || inputDados.idTabelaStatusVenda == 2)">
                        <!-- <img class="icone-atalho-header-2" src="assets/images/svg/cancelar-venda.svg"> -->
                        <i class="fad fa-ban f-32 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Cancelar" *ngIf="loading
                    && (inputDados.idTabelaStatusVenda == 1 || inputDados.idTabelaStatusVenda == 2)
                    ">
                        <!-- <img class="icone-atalho-header-2" src="assets/images/svg/cancelar-venda.svg"> -->
                        <i class="fad fa-ban f-32 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null">
                <li>
                    <span appDebounceClick (debounceClick)="clickSalvarVenda(true, acaoNenhuma, null!)" class="mouse-pointer" ngbTooltip="Salvar" *ngIf="!loading
                        && (inputDados.idTabelaStatusVenda == 1 || inputDados.idTabelaStatusVenda == 2)">
                        <!-- <img class="icone-atalho-header-2" src="assets/images/svg/save2.svg"> -->
                        <i class="fad fa-save f-32 text-c-white"></i>
                    </span>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Salvar" *ngIf="loading
                    && (inputDados.idTabelaStatusVenda == 1 || inputDados.idTabelaStatusVenda == 2)
                    ">
                        <!-- <img class="icone-atalho-header-2" src="assets/images/svg/save2.svg"> -->
                        <i class="fad fa-save f-32 text-c-white"></i>
                    </span>
                </li>
            </div>
            <div class="col-2" *ngIf="inputDados != null && tabelaPrecoSelecionada != null
                ">
                
                <li>
                    <div *ngIf="!loading
                        && (inputDados.idTabelaStatusVenda == 1 || inputDados.idTabelaStatusVenda == 2)
                        " class="btn-group mouse-pointer " ngbDropdown [placement]="'bottom-right'" >
                        <i class="fal fa-usd-circle f-32 text-c-white pe-1" ngbDropdownToggle></i>
                        <div ngbDropdownMenu>
                            <a  class="dropdown-item mouse-pointer" 
                            href="javascript:" 
                            (click)="alterarTabelaPrecoNaVenda(item)"
                            *ngFor="let item of ListaTabelaDePreco; let i = index">{{item.nome}}
                            <i class="fad fa-check f-32 " *ngIf="item.idTabelaPreco == tabelaPrecoSelecionada.idTabelaPreco"></i>
                        </a>
                            
                        </div>
                    </div>
                    <span class="mouse-pointer" ngbTooltip="Aguarde o Processamento para Alterar a Tabela de Preço" *ngIf="loading
                    && (inputDados.idTabelaStatusVenda == 1 || inputDados.idTabelaStatusVenda == 2)
                    ">
                        <i class="fal fa-usd-circle f-32 text-c-white"></i>
                    </span>
                </li>
            </div>
        </div>
    </div>
</div>
<div class="scroll-miolo btnsmiolo">

    <div class="scroll-interno" *ngIf="inputDados == null">
        <div class="col-12">
            <div class="table-wrap">
                  <!-- caixa livre -->
                  <div class="caixa-livre" *ngIf="loadingVendasPendentes">
                    <span style="font-size:84px;color:#2948a8"><i class="far fa-tasks"></i>&nbsp;AGUARDE!</span>
                  </div>
                  <!-- caixa livre -->
                  <div class="row">
                 
                    <div class="col-12">
                        <app-pesquisa-por-produto 
                            [disabled]="loading"
                            (iniciar)="iniciar($event)"
                            #pesquisaPorProduto
                            (adicionarProduto)="adicionarProduto($event, true)" 
                            [inputDados]="inputDados">
                        </app-pesquisa-por-produto>
                   
                    </div>
                  </div>
                  <div class="caixa-livre" *ngIf="!loading">
                    <span style="font-size:84px;color:#2948a8"><i class="fad fa-shopping-cart"></i>&nbsp;CAIXA LIVRE</span>
                </div>
            </div>
        </div>
    </div>
    <div class="scroll-interno" *ngIf="inputDados != null && ProdutoAtivo">
        <div class="col-12">
            <div class="table-wrap">
                <div class="row">
                  
                    <div class="col-12">
                        <app-pesquisa-por-produto 
                            [disabled]="loading"
                            (iniciar)="iniciar($event)"
                            #pesquisaPorProduto
                            (adicionarProduto)="adicionarProduto($event, true)" 
                            [inputDados]="inputDados">
                        </app-pesquisa-por-produto>
                    
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        

                        <table *ngIf="inputDados != null" class="table table-fixed caixa">
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        Item
                                    </th>
                                    <th>
                                        Descrição
                                    </th>
                                    <th>
                                        Quant.
                                    </th>
                                    <th>
                                        Valor UN.
                                    </th>
                                    <th>
                                        Valor Total
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of ListaProdutosPdv; let i = index" class="linha-lista-produtos mouse-pointer"
                                (click)="alterarItemNaGrid(item, i)"
                                >
                                    <td class="text-center">
                                        {{item.item}}
                                    </td>
                                    <td>
                                        {{item.gtin}}-{{item.sku}}-{{item.nome}}
                                    </td>
                                    <td class="text-center">
                                        {{item.quantidade}}
                                    </td>
                                    <td class="text-end">
                                        {{getFormatDecimal((item.precoVenda??0))}}
                                    </td>
                                    <td class="text-end">
                                        {{getFormatDecimal(item.valorTotal)}}
                                    </td>
                                    <td>
                                        <img src="assets/images/barcode.png" class="code">
                                    </td>
                                    <td class="coluna-imagem-produto">
                                        <figure>
                                            <img *ngIf="item.thumbnailImageSrc == ''" src="assets/images/produto_sem_imagem.png">
                                            <img *ngIf="item.thumbnailImageSrc != ''" [src]="server + item.thumbnailImageSrc  | secure | async">
                                        </figure>
                                    </td>
                                </tr>
        
                            </tbody>
                        </table>
                        <!-- botões -->
                        <div class="row align-items-end justify-content-end" *ngIf="inputDados != null">
                            <div class="col-8">
                                <div class="atalhos active" *ngIf="(inputDados == null) || (inputDados != null && 
                                (inputDados.importouComanda == 'N' && inputDados.importouOrcamento == 'N' && inputDados.importouPedidoVenda == 'N' && inputDados.importouOrdemServico == 'N') ||
                                (inputDados.importouComanda == null && inputDados.importouOrcamento == null && inputDados.importouPedidoVenda == null && inputDados.importouOrdemServico == null))">
                                    <h3>
                                        <span>Atalhos de comandos pelo teclado:</span>
                                        <!-- <button class="btn btn-laranja  ">
                                            <i class="far fa-chevron-down atalho"></i>
                                        </button> -->
                                    </h3>
                                    <ul class="col4">
                                        <!-- <div>[<strong>alt</strong>+<strong>p</strong>] Ler Produto</div>
                                        <div>[<strong>alt</strong>+<strong>q</strong>] Ler Quantidade</div> -->
                                        <div *ngIf="inputDados == null ">[<strong>alt</strong>+<strong>v</strong>] Iniciar</div>
                                        <div *ngIf="inputDados == null ">[<strong>alt</strong>+<strong>m</strong>] Comanda</div>
                                        <!-- <div *ngIf="inputDados == null ">[<strong>alt</strong>+<strong>o</strong>] Orçamento</div> -->
                                        <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>alt</strong>+<strong>a</strong>] Alt. Item</div>
                                        <div *ngIf="inputDados != null">[<strong>alt</strong>+<strong>c</strong>] Ad. Cliente
                                        </div>
                                        <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>alt</strong>+<strong>i</strong>] Canc. Item</div>
                                        <div *ngIf="inputDados != null ">[<strong>alt</strong>+<strong>q</strong>] Canc. Venda
                                        </div>
                                        <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>alt</strong>+<strong>u</strong>] Canc. Ult. Item</div>
                                        <!-- <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>shift</strong>+<strong>$</strong>] Pagamento</div> -->
                                    </ul>
                                </div>
                                <!-- EM CASO QUE A VENDA TENHA UMA ORIGEM -->
                                <div class="atalhos2 active" *ngIf="inputDados != null && (inputDados.importouComanda == 'S' || inputDados.importouOrcamento == 'S' ||
                                    inputDados.importouPedidoVenda == 'S' || inputDados.importouOrdemServico == 'S')">
                                    <h3>
                                        <div *ngIf="inputDados.importouComanda == 'S'">
                                            <span>Comanda(s):</span>
                                            <label class="text-left" *ngFor="let itemComanda of comandasVenda;"> <b>{{itemComanda.tipoComanda}}-{{itemComanda.idComanda}}</b>;</label>&nbsp;
                                        </div>
                                        <div *ngIf="inputDados.importouPedidoVenda == 'S'">
                                            <span>Pedido(s) de Venda(s):</span>
                                            <label class="text-left" *ngFor="let itemPedido of pedidosVenda;"> <b>{{itemPedido.idPedidoVenda}}</b>;</label>&nbsp;
                                        </div>
                                        <div *ngIf="inputDados.importouOrdemServico == 'S'">
                                            <span>Ordem(ns) de Serviço(s):</span>
                                            <label class="text-left" *ngFor="let itemOs of ordensServicoVenda;"> <b>{{itemOs.idOrdemServico}}</b>;</label>
                                        </div>
                                    </h3>
                                    <h3>
                                        <span>Atalhos de comandos pelo teclado:</span>
                                    </h3>
                                    <ul class="col4">
                                        <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>alt</strong>+<strong>a</strong>] Alt. Item</div>
                                        <div *ngIf="inputDados != null">
                                            [<strong>alt</strong>+<strong>c</strong>] Ad. Cliente
                                        </div>
                                        <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>alt</strong>+<strong>i</strong>] Canc. Item</div>
                                        <div *ngIf="inputDados != null ">
                                            [<strong>alt</strong>+<strong>q</strong>] Canc. Venda
                                        </div>
                                        <div *ngIf="inputDados != null && ListaProdutosPdv.length > 0">
                                            [<strong>alt</strong>+<strong>u</strong>] Canc. Ult. Item
                                        </div>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-4">        
                                <div class="valor">
                                    <span >Valor da Venda</span>
                                    <strong>{{getFormatDecimal(total)}}</strong>
                                </div>        
                            </div>                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="scroll-interno" *ngIf="inputDados != null && PagamentoAtivo">
        <div class="col-12">
            <div class="table-wrap">
                <!-- header -->
                <!-- lista -->
                <div class="row">
                    <div class="col-9">
                        <table *ngIf="inputDados != null" class="table table-fixed pgto">
                            <thead>
                                <tr>
                                    <!-- <th class="uppercase text-center" style="padding-right:27px">
                                        #
                                    </th> -->
                                    <th class="uppercase text-center">
                                        
                                    </th>
                                    <th class="uppercase text-center">
                                        Condição
                                    </th>
                                    <!-- <th class="uppercase text-center">
                                        Meio Pgto
                                    </th> -->
                                    <th class="uppercase text-center">
                                        Nº Parcelas
                                    </th>
                                    <th class="uppercase text-center">
                                        Juros
                                    </th>
                                    <th class="uppercase text-center">
                                        Desconto
                                    </th>
                                    <th class="uppercase text-center">
                                        Vlr Pago
                                    </th>
                                    <th class="uppercase text-center">
                                        Total
                                    </th>
                                    <th class="text-center">

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of inputDados.formaPagamentos; let i = index" class="">
                                    <!-- <td class="text-center" *ngIf="item.situacao != situacaoFormaPagamentoRemovido && inputDados != null && inputDados.idTabelaStatusVenda != statusVendaFinalizada">
                                        <div class="form-check form-check-inline" >
                                            <input type="radio" [id]="getIdRadioSelecaoPagamento(item.idFormaPagamento)" name="radioSelecaoFormaPagamento" class="form-check-input"                                  
                                                (click)="selecionarFormaPagamento(item)" [(ngModel)]="idFormaPagamento" [value]="item.idFormaPagamento"
                                                 >
                                        </div>
                                    </td>
                                    <td class="text-center" *ngIf="item.situacao == situacaoFormaPagamentoRemovido ||  (inputDados != null &&  inputDados.idTabelaStatusVenda == statusVendaFinalizada)">
                                    </td> -->
                                    <td class="text-center">
                                        <img [src]="getUrlImage(item.idTabelaMeioPagamento)" class="code">
                                    </td>
                                    <td class="text-center text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido"
                                        >
                                        {{item.id?.descricao}}
                                    </td>
                                    <td class="text-center" *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        <label class="mouse-pointer" (click)="getParcelas(item.idFormaPagamento)">{{item.id?.descricao}}</label>
                                        <!-- <label class="mouse-pointer" [for]="getIdRadioSelecaoPagamento(item.idFormaPagamento)">{{item.id?.descricao}}</label> -->
                                    </td>
                                    <td class="text-center text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido">
                                        {{item.qtdeParcela}}
                                    </td>
                                    <td class="text-center" *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        <!-- <label class="mouse-pointer" [for]="getIdRadioSelecaoPagamento(item.idFormaPagamento)">{{item.qtdeParcela}}</label> -->
                                        <label class="mouse-pointer" ngbTooltip="Visualizar Parcelas" (click)="getParcelas(item.idFormaPagamento)">{{item.qtdeParcela}}</label>
                                    </td>

                                    <td class="text-center    text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido">
                                        {{ (item.valorDespesaCondicaoPagamento! + item.valorJurosCondicaoPagamento!) | currency:'BRL'}}
                                    </td>
                                    <td class="text-center mouse-pointer " *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        <label class="mouse-pointer" (click)="getParcelas(item.idFormaPagamento)">{{ (item.valorDespesaCondicaoPagamento! + item.valorJurosCondicaoPagamento!) | currency:'BRL'}}</label>
                                        <!-- <label class="mouse-pointer" [for]="getIdRadioSelecaoPagamento(item.idFormaPagamento)">{{ (item.valorDespesaCondicaoPagamento! + item.valorJurosCondicaoPagamento!) | currency:'BRL'}}</label> -->
                                    </td>

                                    <td class="text-center   text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido">
                                        {{ (item.valorDescontoCondicaoPagamento! + item.valorDescontoVendedor!) | currency:'BRL'}}
                                    </td>
                                    <td class="text-center  " *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        <label class="mouse-pointer" (click)="getParcelas(item.idFormaPagamento)">{{ (item.valorDescontoCondicaoPagamento! + item.valorDescontoVendedor!) | currency:'BRL'}}</label>
                                        <!-- <label class="mouse-pointer" [for]="getIdRadioSelecaoPagamento(item.idFormaPagamento)">{{ (item.valorDescontoCondicaoPagamento! + item.valorDescontoVendedor!) | currency:'BRL'}}</label> -->
                                    </td>

                                    <td class="text-center   text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido">
                                        {{item.valorPago | currency:'BRL'}}
                                    </td>
                                    <td class="text-center  " *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        <label class="mouse-pointer" (click)="getParcelas(item.idFormaPagamento)">{{item.valorPago | currency:'BRL'}}</label>
                                        <!-- <label class="mouse-pointer" [for]="getIdRadioSelecaoPagamento(item.idFormaPagamento)">{{item.valorPago | currency:'BRL'}}</label> -->
                                    </td>

                                    <td class="text-center text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido">
                                        <b>{{item.valorTotal | currency:'BRL'}}</b>
                                    </td>
                                    <td class="text-center mouse-pointer "  *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        <label class="mouse-pointer" (click)="getParcelas(item.idFormaPagamento)"><b>{{item.valorTotal | currency:'BRL'}}</b></label>
                                        <!-- <label class="mouse-pointer" [for]="getIdRadioSelecaoPagamento(item.idFormaPagamento)"><b>{{item.valorTotal | currency:'BRL'}}</b></label> -->
                                    </td>
                                    <td class="text-center" *ngIf="item.situacao == situacaoFormaPagamentoRemovido ||  (inputDados != null &&  inputDados.idTabelaStatusVenda == statusVendaFinalizada)">
                                    </td>
                                    <td class="text-center" *ngIf="item.situacao != situacaoFormaPagamentoRemovido && inputDados != null && inputDados.idTabelaStatusVenda != statusVendaFinalizada">
                                        <a ngbTooltip="Remover Pagamento" class="pr-1" (click)="selecionarFormaPagamento(item)"><i
                                            class="mouse-pointer text-c-red fad fa-trash-alt f-16"></i>
                                        </a>
                                    </td>
                                    <!-- <td class="text-center text-c-red line-through" *ngIf="item.situacao == situacaoFormaPagamentoRemovido">
                                        {{item.valorTroco | currency:'BRL'}}
                                    </td>
                                    <td class="text-center"  *ngIf="item.situacao != situacaoFormaPagamentoRemovido">
                                        {{item.valorTroco | currency:'BRL'}}
                                    </td> -->
                                </tr>
                                
                            </tbody>
                        </table>
                    </div>
                    <!-- <div class="col-3 " style="padding-left:25px" *ngIf="loadingMeioPagamento || loading">
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-6"><p-progressSpinner></p-progressSpinner></div>
                            <div class="col-3"></div>
                        </div>
                    </div> -->
                    
                    <div class="col-3 " style="padding-left:25px;
                        max-height: calc(100vh - 380px);
    overflow-y: auto;
    overflow-x: hidden;" *ngIf="!loadingMeioPagamento && !loading && totalPendente > 0">
                            <div class="row row-pgts" *ngIf=" inputDados != null && inputDados.idTabelaStatusVenda != statusVendaFinalizada">
                                <div class="col-6">
                                    <div *ngFor="let item of meiosPagamentos; let i = index">
                                        <div class="form-check p-2" *ngIf="i % 2 == 0">
                                            <input class="form-check-input mouse-pointer" type="radio" name="inputMeioPagamento" [id]="getIdIconeMeioPagamento(item.idTabelaMeioPagamento)" (click)="abrirModalPorMeioPagamento(item)"
                                                
                                            >
                                            <label class="form-check-label mouse-pointer" [for]="getIdIconeMeioPagamento(item.idTabelaMeioPagamento)" >
                                                <img [src]="getUrlImage(item.idTabelaMeioPagamento)" class="imageFormaPagamento"
                                                (click)="abrirModalPorMeioPagamento(item)"> <a >{{item.descricao}}</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div *ngFor="let item of meiosPagamentos; let i = index">
                                        <div class="form-check p-2"  *ngIf="i % 2 != 0">
                                            <input class="form-check-input mouse-pointer" type="radio" name="inputMeioPagamento" [id]="getIdIconeMeioPagamento(item.idTabelaMeioPagamento)" (click)="abrirModalPorMeioPagamento(item)"
                                            
                                            >
                                            <label class="form-check-label mouse-pointer" [for]="getIdIconeMeioPagamento(item.idTabelaMeioPagamento)">
                                                <img [src]="getUrlImage(item.idTabelaMeioPagamento)" class="imageFormaPagamento"
                                                (click)="abrirModalPorMeioPagamento(item)"> <a >{{item.descricao}}</a>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                    </div>
                </div>
                <!-- botões e atalhos-->
                
                <div  class="row align-items-end justify-content-end" *ngIf="inputDados != null">
                    <div class="col-8">
                        <!-- <div class="row" style="padding-left:0px" *ngIf="!(!loadingMeioPagamento && !loading && totalPendente > 0)">
                            <div class="col-12 text-center pt-5">
                                <p-message severity="success" text="Pagamento(s) efetuado(s) com sucesso, finalize sua compra!"></p-message>
                            </div>
                        </div> -->
                        <div class="atalhos active ps-1">
                            <h3>
                                <span>Atalhos de comandos pelo teclado:</span>
                            </h3>
                            <ul class="col4">
                                <div>[<strong>alt</strong>+<strong>c</strong>] Cliente</div>
                                <div>[<strong>alt</strong>+<strong>4</strong>] Dinheiro</div>
                                <div>[<strong>alt</strong>+<strong>5</strong>] Cartão de Crédito</div>
                                <div>[<strong>alt</strong>+<strong>i</strong>] Cartão de Débito</div>
                                <div>[<strong>alt</strong>+<strong>b</strong>] Boleto</div>
                                <div>[<strong>alt</strong>+<strong>h</strong>] Cheque</div>
                                <div>[<strong>alt</strong>+<strong>p</strong>] Pix</div>
                                <div>[<strong>alt</strong>+<strong>v</strong>] Vale</div>
                                <div>[<strong>alt</strong>+<strong>t</strong>] Transferência</div>
                                <div>[<strong>alt</strong>+<strong>l</strong>] Crédito Loja</div>
                                <div>[<strong>alt</strong>+<strong>w</strong>] Vale Alimentação</div>
                                <div>[<strong>alt</strong>+<strong>x</strong>] Vale Refeição</div>
                                <div>[<strong>alt</strong>+<strong>y</strong>] Vale Combustível</div>
                                <div>[<strong>alt</strong>+<strong>z</strong>] Vale Presente</div>
                                <div>[<strong>alt</strong>+<strong>r</strong>] Remover Pgto</div>
                                <div>[<strong>alt</strong>+<strong>n</strong>] Cancelar</div>
                                <div>[<strong>shift</strong>+<strong>$</strong>] Finalizar</div>
                            </ul>
                        </div>
                        
                    </div>
                    <div class="col-4">
                        <div class="valor flex-column">
                            <div class="d-flex align-items-center justify-content-between mb-1">
                                <span >Valor da Venda</span>
                                <strong>{{getFormatDecimal(valorTotalCompra)}}</strong>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-1" >
                                <span class="small">Juros/Despesas</span>
                                <strong class="small">{{getFormatDecimal(valorTotalJurosDespesas)}}</strong>                                
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-1" >
                                <span class="small">Descontos</span>
                                <strong class="small">{{getFormatDecimal(valorTotalDescontos)}}</strong>                                
                            </div>
                            <div class="d-flex align-items-center justify-content-between mb-1">
                                <span class="small">Total Recebido</span>
                                <strong class="small">{{getFormatDecimal(totalRecebido)}}</strong>                                
                            </div>
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="small">Pendente</span>
                                <strong class="small">{{getFormatDecimal(totalPendente)}}</strong>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- REMOVER A BTNS WRAP DE LA DE CIMA E COLOCAR AQUI -->
    <div class="btns-wrap" *ngIf="inputDados == null">
        <div class="grupo-btn-rodape">
            <button class="btn btn-success  btn-md label-btn-nowrap" (click)="iniciarVenda(null!)" [disabled]="loading">
                <i class="fad fa-cart-plus"></i>&nbsp;Iniciar
            </button>
        </div>
        <div class="grupo-btn-rodape">
            <button class="btn btn-primary  btn-md label-btn-nowrap" (click)="importarComanda()" [disabled]="loading">
                <i class="fad fa-sharp fa-solid fa-scanner-touchscreen"></i>&nbsp;Comanda
            </button>
        </div>
        <div class="grupo-btn-rodape">
            <button class="btn btn-primary  btn-md label-btn-nowrap" (click)="importarOrcamento()" [disabled]="loading">
                <i class="fad fa-search-dollar"></i>&nbsp;Orçamento
            </button>
        </div>
        <div class="grupo-btn-rodape" *ngIf="permiteCadServico == 'S'">
            <button class="btn btn-primary  btn-md label-btn-nowrap" (click)="importarOrdemServico()"
                [disabled]="loading">
                <i class="fad fa-file-invoice-dollar"></i>&nbsp;OS
            </button>
        </div>
        <div class="grupo-btn-rodape">
            <button class="btn btn-primary btn-md label-btn-nowrap" (click)="importarPedidoDeVenda()"
                [disabled]="loading">
                <i class="fad fa-tag"></i>&nbsp;Ped. de Venda
            </button>
        </div>
    </div>
    <div class="btns-wrap" *ngIf="inputDados != null">
        <div class="grupo-btn-rodape" *ngIf="PagamentoAtivo" >
            <button class="btn btn-secondary  btn-md" (click)="voltar()"  [disabled]="loading" *ngIf=" inputDados != null &&  inputDados.idTabelaStatusVenda != statusVendaFinalizada">
                <i class="fad fa-step-backward"></i>&nbsp;Voltar
            </button>
        </div>
        <!-- <div class="grupo-btn-rodape">
            <button class="btn btn-success  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="iniciarVenda(null!)" [disabled]="loading">
                <i class="fad fa-cart-plus"></i>&nbsp;Nova Venda
            </button>
        </div> -->
        <div class="grupo-btn-rodape">
            <button class="btn btn-success  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="caixaLivre(null!)" [disabled]="loading">
                <i class="fad fa-cash-register"></i>&nbsp;Caixa Livre
            </button>
        </div>
        <div class="grupo-btn-rodape" *ngIf="inputDados.idOrdemServico == 0 || inputDados.idOrdemServico == null  && ProdutoAtivo">
            <button class="btn btn-primary  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="importarComanda()" [disabled]="loading">
                <i class="fad fa-sharp fa-solid fa-scanner-touchscreen"></i>&nbsp;Comanda
            </button>
        </div>
        <div class="grupo-btn-rodape"
            *ngIf="ProdutoAtivo && (permiteCadServico == 'S') && (inputDados.idOrdemServico == 0 || inputDados.idOrdemServico == null) && (ListaProdutosPdv.length == 0)">
            <button class="btn btn-primary  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="importarOrdemServico()"
                [disabled]="loading">
                <i class="fad fa-file-invoice-dollar"></i>&nbsp;OS
            </button>
        </div>
        <div class="grupo-btn-rodape" *ngIf="inputDados != null">
            <button class="btn btn-primary  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="selecionarCliente()" [disabled]="loading">
                <i class="fad fa-user"></i>&nbsp;Cliente
            </button>
        </div>
        <div class="grupo-btn-rodape"
            *ngIf="ListaProdutosPdv.length > 0 && ProdutoAtivo ">
            <button class="btn btn-primary  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="alterarItem()" [disabled]="loading">
                <i class="fad fa-edit"></i>&nbsp;Alt. Item
            </button>
        </div>

        <div class="grupo-btn-rodape"
            *ngIf="ListaProdutosPdv.length > 0 && (inputDados.idOrdemServico == 0 || inputDados.idOrdemServico == null) && ProdutoAtivo">
            <button class="btn btn-warning  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="cancelarItem()" title="Cancelar Item"
                [disabled]="loading">
                <i class="fad fa-ban"></i>&nbsp;Canc. Item
            </button>
        </div>

        <!-- <div class="grupo-btn-rodape"
            *ngIf="ListaProdutosPdv.length > 0 && (inputDados.idOrdemServico == 0 || inputDados.idOrdemServico == null)">
            <button class="btn btn-warning  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="cancelarUltimoItem()"
                [disabled]="loading">
                <i class="far fa-minus-circle"></i>&nbsp;Canc. Ult. Item
            </button>
        </div> -->
        <!-- <div class="grupo-btn-rodape" *ngIf="inputDados != null">
            <button class="btn btn-danger  btn-md label-btn-nowrap" (click)="cancelarVenda()" [disabled]="loading">
                <i class="far fa-trash-alt"></i>&nbsp;Canc. Venda
            </button> 
        </div> -->
        <div class="grupo-btn-rodape" *ngIf=" inputDados != null && inputDados.cpfCnpjNavigation != null">
            <button class="btn btn-info  btn-md"   [disabled]="loading" appDebounceClick (debounceClick)="cartaoFidelidade()">
                <i class="fad fa-smile"></i>&nbsp;Fidelidade
            </button>
        </div>
        <div class="grupo-btn-rodape" *ngIf="ListaProdutosPdv.length > 0 && ProdutoAtivo">
            <button class="btn btn-success  btn-md label-btn-nowrap" appDebounceClick (debounceClick)="realizarPagamento()" [disabled]="loading">
                <i class="fad fa-dollar-sign"></i>&nbsp;Pagamento
            </button>
        </div>
        <!-- <div class="grupo-btn-rodape && PagamentoAtivo">
            <button class="btn btn-danger  btn-md" (click)="removerPagamento()"  [disabled]="loading" *ngIf=" inputDados != null &&  inputDados.idTabelaStatusVenda != statusVendaFinalizada && PagamentoAtivo && inputDados.formaPagamentos.length > 0">
                <i class="fad fa-file-invoice-dollar"></i>&nbsp;Rem. Pgto
            </button>
        </div> -->
        <!-- <div class="grupo-btn-rodape" >
            <button class="btn btn-danger btn-md" (click)="cancelarVenda()"  >
                <i class="far fa-ban"></i>&nbsp;Cancelar
            </button>
        </div> -->
        
        <div class="grupo-btn-rodape" *ngIf=" inputDados != null &&  inputDados.idTabelaStatusVenda != statusVendaFinalizada && PagamentoAtivo">
            <button class="btn btn-success  btn-md"   [disabled]="loading" appDebounceClick (debounceClick)="finalizarPagamento()">
                <i class="fad fa-dollar-sign"></i>&nbsp;Finalizar
            </button>
        </div>
        <!-- <div class="grupo-btn-rodape" *ngIf=" inputDados != null && inputDados.idTabelaStatusVenda == statusVendaFinalizada && PagamentoAtivo"> -->
        <div class="grupo-btn-rodape" *ngIf=" inputDados != null && (inputDados.idTabelaStatusVenda == statusVendaFinalizada || inputDados.idTabelaStatusVenda == statusVendaPendentePagto)">
            <button class="btn btn-success  btn-md" (click)="gerarNotaFiscal(false)" [disabled]="loading">
                <i class="fad fa-print"></i>&nbsp;Impressão
            </button>
        </div>
        <div class="grupo-btn-rodape" style="text-align:center;" *ngIf="loading">
            <div class="spinner-border text-primary center" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
    </div>
</div>
<app-modal-vendas-pendentes (cancelouVenda)="cancelouVenda($event)" (selecionouVenda)="retornoSelecionarVendaPendente($event)" (fechouModal)="fechouModal($event)" #modalVendasPendentesComponent></app-modal-vendas-pendentes>
<app-modal-selecionar-cliente (selecionouCliente)="selecionouCliente($event)" (fechouModal)="fechouModal($event)" #modalSelecionarClienteComponent></app-modal-selecionar-cliente>
<app-modal-alterar-item (alterouItens)="alterouItens($event)" (fechouModal)="fechouModal($event)"  #modalAlterarItemComponent></app-modal-alterar-item>
<app-modal-cancelar-item (removeuItem)="removeuItem($event)" (fechouModal)="fechouModal($event)" #modalCancelarItemComponent></app-modal-cancelar-item>
<app-modal-cancelar-venda (cancelouVenda)="cancelouVenda($event)" (fechouModal)="fechouModal($event)" #modalCancelarVendaComponent></app-modal-cancelar-venda>
<app-modal-abrir-fechar-caixa (abriuFechouCaixa)="abriuFechouCaixa($event)" (fechouModal)="fechouModal($event)" #modalSelecaoCaixaComponent></app-modal-abrir-fechar-caixa>
<app-modal-selecionar-comanda-para-venda (selecionouComanda)="selecionouComanda($event)" (fechouModal)="fechouModal($event)"  (cancelouSelecao)="cancelouSelecaoComanda($event)" #modalSelecionarComandaParaVendaComponent></app-modal-selecionar-comanda-para-venda>
<app-modal-selecionar-ordem-servico-para-venda (selecionouOrdemServico)="selecionouOrdemServico($event)" (fechouModal)="fechouModal($event)" (cancelouSelecaoOrdemServico)="cancelouSelecaoOrdemServico($event)" #modalSelecionarOrdemServicoParaVendaComponent></app-modal-selecionar-ordem-servico-para-venda>
<app-modal-selecionar-orcamento-para-venda (selecionouOrcamento)="selecionouOrcamento($event)" (fechouModal)="fechouModal($event)" (cancelouSelecaoOrcamento)="cancelouSelecaoOrcamento($event)" #modalSelecionarOrcamentoParaVendaComponent></app-modal-selecionar-orcamento-para-venda>
<app-modal-selecionar-pedido-venda-para-venda (selecionouPedidoVenda)="selecionouPedidoVenda($event)" (fechouModal)="fechouModal($event)" #modalSelecionarPedidoVendaParaVendaComponent></app-modal-selecionar-pedido-venda-para-venda>
<app-modal-situacao-cliente></app-modal-situacao-cliente>
<app-modal-informacoes-adicionais-venda
    (salvouAlteracao)="salvarInformacoesAdicionais($event)" 
    (fechouModal)="fechouModal($event)"
></app-modal-informacoes-adicionais-venda>
<modal-gera-nota-fiscal
    (gerouNotaFiscal)="gerouNotaFiscal($event)"
    (fechouModal)="fechouModal($event)"
    (caixaLivre)="caixaLivre($event)"
    #modalGeraNotaFiscalComponent
></modal-gera-nota-fiscal>
<app-modal-meio-pagamento
    (informouPagamento)="fecharModalMeioPagamento($event)"
    (fechouModal)="fechouModal($event)"
    #modalMeioPagamentoComponent
></app-modal-meio-pagamento>
<app-modal-cartao-fidelidade
    (salvouAlteracao)="salvouCartaoFidelidade($event)"
    (fechouModal)="fechouModal($event)"
    #modalCartaoFidelidadeComponent
></app-modal-cartao-fidelidade>
<app-modal-forma-pagamento-parcela>
</app-modal-forma-pagamento-parcela>