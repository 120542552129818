import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'tipoPessoa' })
export class TipoPessoa implements PipeTransform {
    transform(value: string): string {
        if (value == 'F') { return 'Física' }
        else if (value == 'J') { return 'Jurídica' }
        else if (value == 'E'){ return 'Estrangeiro' }
        else{ return ''; }
    }
}
