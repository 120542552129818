import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { ParametrizacaoComandumModel } from 'src/app/models/cliente/ParametrizacaoComandum/parametrizacaoComandumModel';
import { GerarComandaModel } from 'src/app/models/pdv/GerarComandaModel';
import { ApiParametrizacaoComandaService } from 'src/app/services/cliente/api-parametrizacao-comanda.service';
import { ApiComandaService } from 'src/app/services/pdv/api-comanda.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ComandumModel } from 'src/app/models/cliente/Comandum/comandumModel';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { enumTipoComanda } from 'src/app/enum/enumTipoComanda';
import { ResumoComandaModel } from 'src/app/models/pdv/ResumoComandaModel';
import { ConstantsService } from '../../constants/constants.service';
import { AutoComplete } from 'primeng/autocomplete';
import { ProdutoComandaModel } from 'src/app/models/pdv/ProdutoComandaModel';
import { LancarProdutoComandaModel } from 'src/app/models/pdv/LancarProdutoComandaModel';
import { enumSituacaoComanda } from 'src/app/enum/enumSituacaoComanda';
import { enumStatus } from 'src/app/enum/enumStatus';
import { AppSettings } from 'src/app/app-settings';
import { InputMask } from 'primeng/inputmask';
import { Calendar } from 'primeng/calendar';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { Router } from '@angular/router';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { ProdutoPesquisaModel } from '../pesquisa-por-produto/ProdutoPesquisaModel';
import { ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { PesquisaPorProdutoComponent, ProdutoSelecionado } from '../pesquisa-por-produto/pesquisa-por-produto.component';
import { enumFormatoImpressao } from 'src/app/enum/enumFormatoImpressao';
import { CustomFormatoImpressao, CustomFormatoImpressaoInstance } from 'src/app/models/relatorios/CustomFormatoImpressao';
import * as printJS from 'print-js';
import { ApiGeraRelatorioComandaService } from 'src/app/services/relatorio/api-gera-relatorio-comanda.service';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { enumSituacaoComandaProduto } from 'src/app/enum/enumSituacaoComandaProduto';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { PdvComponent } from 'src/app/theme/layout/pdv/pdv.component';
import { ProdutosParaPreparacaoModel } from 'src/app/models/pdv/ProdutosParaPreparacaoModel';
import { enumTipoImpressao } from 'src/app/enum/enumTipoImpressao';
import { ModalImpressaoComponent } from '../modal-impressao/modal-impressao.component';

enum enumAcaoComanda{
  AbrirComanda,
  AdicionarProduto,
  ConsultarComanda,
  VisualizarProdutos
}

@Component({
  selector: 'app-modal-selecionar-comanda',
  templateUrl: './modal-selecionar-comanda.component.html',
  styleUrls: ['./modal-selecionar-comanda.component.css']
})
export class ModalSelecionarComandaComponent implements OnInit, OnDestroy {
  
  public maskCpf = AppSettings.MASK.maskCpf;
  public maskRg = AppSettings.MASK.maskRg;
  public maskTeleAreaCel = AppSettings.MASK.maskTeleAreaCel;
  maxDate: Date = new Date();
  @ViewChild(PesquisaPorProdutoComponent, { static: false }) pesquisaPorProduto!: PesquisaPorProdutoComponent;
  @ViewChild(ModalImpressaoComponent) modalImpressaoComponent!: ModalImpressaoComponent;
  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  //@Output() AbriuComanda: EventEmitter<ComandumModel> = new EventEmitter();
  //@Output() LiberouComanda: EventEmitter<ComandumModel> = new EventEmitter();
  //@Output() AbriuComandaSequencial: EventEmitter<ComandumModel> = new EventEmitter();
  //@Output() AdicionouProduto: EventEmitter<LancarProdutoComandaModel[]> = new EventEmitter();
  @Output() atualizarComandas: EventEmitter<ComandumModel> = new EventEmitter();

  titulo: string = '';
  acao: enumAcaoComanda = enumAcaoComanda.AbrirComanda;

  subsLancarProdutosComanda: Subscription = null!;
  subsRemoverProdutoComanda: Subscription = null!;
  subsAbrir: Subscription = null!;
  subsByIdLojaUsuarioLogado: Subscription = null!;
  subsImportarComandaParaPagamento: Subscription = null!;
  subsSalvarObservacaoProd: Subscription = null!;
  subsConsultarResumoComanda: Subscription = null!;
  subsLiberarComanda: Subscription = null!;
  tipoComanda: string = enumTipoComanda.Mesa;
  loading: boolean = true;
  resumoComanda: ResumoComandaModel = null!;
  disabledCodigoComanda: boolean = false;
  habilitaTipoComanda: boolean = false;
  adicionarProdutoAposAbrirComanda: boolean = false;
  habilitaProdutoServico: enumTipoProduto = enumTipoProduto.Produto;
  permiteCadProduto: string = enumSimNao.Sim;
  permiteCadServico: string = enumSimNao.Sim;
  gerarComanda: GerarComandaModel = null!;
  AbrirComanda: enumAcaoComanda = enumAcaoComanda.AbrirComanda;
  AdicionarProduto: enumAcaoComanda = enumAcaoComanda.AdicionarProduto;
  VisualizarProdutos: enumAcaoComanda = enumAcaoComanda.VisualizarProdutos;
  ConsultarComanda: enumAcaoComanda = enumAcaoComanda.ConsultarComanda;
  codigoComanda: string = '';
  parametrizacaoComanda: ParametrizacaoComandumModel = null!;
  comanda: ComandumModel = null!;

  produtos: Array<ProdutoSelecionado> = [];
  precoProduto: number = 0;
  subTotal: number = 0;
  exibeBotaoPagamento: boolean = true;

  inputDadosImpressao: CustomFormatoImpressao = CustomFormatoImpressaoInstance.get();
  nomeArquivo: string = '';
  subsGerarExtratoComanda: Subscription = null!;
  subsEnviarParaPreparacao: Subscription = null!;
  subsValidarParaPreparacao: Subscription = null!;
  formatoCupom80mm: enumFormatoImpressao = enumFormatoImpressao.Cupom80mm;
  formatoA4: enumFormatoImpressao = enumFormatoImpressao.A4;
  quantidadeProdutos: number = 0;  

  @ViewChild('elmCodigoComanda') elmCodigoComanda: ElementRef  = null!;
  onFocusCodigoComanda(){
    setTimeout(()=>{ 
      if (this.elmCodigoComanda != null && this.elmCodigoComanda.nativeElement != null){
        this.elmCodigoComanda.nativeElement!.focus();
      }
    },200);    
  }

  @ViewChild('elmCpf') elmCpf: InputMask  = null!;
  onFocusCpf(){
    setTimeout(()=>{ 
      if (this.elmCpf != null){
        this.elmCpf.focus();
      }
    },60);    
  }

  @ViewChild('elmRazaoSocialNome') elmRazaoSocialNome: ElementRef  = null!;
  onFocusRazaoSocialNome(){
    setTimeout(()=>{ 
      if (this.elmRazaoSocialNome != null && this.elmRazaoSocialNome.nativeElement != null){
        this.elmRazaoSocialNome.nativeElement.focus();
      }
    },60);    
  }

  @ViewChild('elmRg') elmRg: InputMask  = null!;
  onFocusRg(){
    setTimeout(()=>{ 
      if (this.elmRg != null){
        this.elmRg.focus();
      }
    },60);    
  }

  @ViewChild('elmDataNascimento') elmDataNascimento: Calendar  = null!;
  onFocusDataNascimento(){
    setTimeout(()=>{ 
      if (this.elmDataNascimento != null && this.elmDataNascimento.el != null &&  this.elmDataNascimento.el.nativeElement != null){
        this.elmDataNascimento.el.nativeElement.getElementsByTagName("input").dataNascimento.focus();
      }
    },60);    
  }

  @ViewChild('elmTelefoneCelular') elmTelefoneCelular: InputMask  = null!;
  onFocusTelefoneCelular(){
    setTimeout(()=>{ 
      if (this.elmTelefoneCelular != null){
        this.elmTelefoneCelular.focus();
      }
    },60);    
  }

  @ViewChild('elmTelefoneContato') elmTelefoneContato: InputMask  = null!;
  onFocusTelefoneContato(){
    setTimeout(()=>{ 
      if (this.elmTelefoneContato != null){
        this.elmTelefoneContato.focus();
      }
    },60);    
  }

  getTipoComanda(tipoComanda: string): string{
    switch(tipoComanda){
      case enumTipoComanda.Fixa: return 'Fixa';
      case enumTipoComanda.Sequencial: return 'Sequencial';
      case enumTipoComanda.Mesa: return 'Mesa';
    }
    return 'Não definido';
  }
  usuarioComanda: string = '';
  getUsuarioComanda(comanda: ComandumModel | null): string{
    if (comanda?.comandaGerada != null && comanda.comandaGerada.length > 0){
      let retorno: string = '';
      if (comanda?.comandaGerada[0].cpfCnpj != ''){
        retorno += `CPF: ${comanda?.comandaGerada[0].cpfCnpj}\n`;
      }
      if (comanda?.comandaGerada[0].razaoSocialNome != ''){
        retorno += ` Nome: ${comanda?.comandaGerada[0].razaoSocialNome}\n`;
      }
      if (comanda?.comandaGerada[0].rg != ''){
        retorno += ` Rg: ${comanda?.comandaGerada[0].rg}\n`;
      }
      if (comanda?.comandaGerada[0].dataNascimento != null){
        retorno += ` Data Nascimento:  ${ApiUtilService.getDateFormatString(new Date(comanda?.comandaGerada[0].dataNascimento))}`;
      }
      if (comanda?.comandaGerada[0].telefoneCelular != ''){
        retorno += ` Celular: ${comanda?.comandaGerada[0].telefoneCelular}\n`;
      }
      if (comanda?.comandaGerada[0].telefoneContato != ''){
        retorno += ` Tel. Contato: ${comanda?.comandaGerada[0].telefoneContato}\n`;
      }
      return retorno;
    }
    return '';
  }

  constructor(private apiParametrizacaoComandaService: ApiParametrizacaoComandaService,
    private apiGeraRelatorioComandaService: ApiGeraRelatorioComandaService,
    private router: Router,
    private apiComandaService: ApiComandaService,
    private readonly mensagemService: AppMensagemService,
    private showMessageError: ShowMessageError,
    private appCustomToastService: AppCustomToastService
    ) { }

  ngOnInit(): void {
    this.instanceInputDados();
    if(ConstantsService.ParametrizacaoCadastro.permiteCadastroProduto != null){
      this.permiteCadProduto = ConstantsService.ParametrizacaoCadastro.permiteCadastroProduto;
    }
    if(ConstantsService.ParametrizacaoCadastro.permiteCadastroServico != null){
      this.permiteCadServico = ConstantsService.ParametrizacaoCadastro.permiteCadastroServico;
    }

    if(this.permiteCadProduto == enumSimNao.Sim && this.permiteCadServico == enumSimNao.Sim){
        this.habilitaProdutoServico = enumTipoProduto.Todos;
    }
    else if(this.permiteCadProduto == enumSimNao.Sim){
      this.habilitaProdutoServico = enumTipoProduto.Produto;
    }
    else if(this.permiteCadServico == enumSimNao.Sim){
      this.habilitaProdutoServico = enumTipoProduto.Servico;
    }
  }

  ngOnDestroy(): void {
    this.subsAbrir?.unsubscribe();
    this.subsByIdLojaUsuarioLogado?.unsubscribe();
    this.subsConsultarResumoComanda?.unsubscribe();
    this.subsLancarProdutosComanda?.unsubscribe();
    this.subsImportarComandaParaPagamento?.unsubscribe();
    this.subsLiberarComanda?.unsubscribe();
    this.subsRemoverProdutoComanda?.unsubscribe();
    this.subsGerarExtratoComanda?.unsubscribe();
    this.subsSalvarObservacaoProd?.unsubscribe();
    this.subsEnviarParaPreparacao?.unsubscribe();
    this.subsValidarParaPreparacao?.unsubscribe();
  }

  cliqueCancelar(){
    this.fecharModal();
  }

  instanceInputDados(){
    this.gerarComanda = {
      idComandaGerada: 0,
      tipoComanda: '',
      idComanda: 0,
      idVenda: null,
      cpfCnpj: '',
      razaoSocialNome: '',
      rg: '',
      dataNascimento: null,
      telefoneCelular: '',
      telefoneContato: '',
      produtos: [],
    }
  }

  abrir(){
    this.modal.show();
  }

  handleTipoComanda(tpoComanda: string){
    this.tipoComanda = tpoComanda as enumTipoComanda;
    if (this.tipoComanda == enumTipoComanda.Sequencial){

    }
  }
  
  abrirComanda(parametrizacaoComanda: ParametrizacaoComandumModel, comanda: ComandumModel | null) {
    this.loading = false;
    this.acao = enumAcaoComanda.AbrirComanda;
    this.comanda = comanda!;
    this.parametrizacaoComanda = parametrizacaoComanda;
    this.instanceInputDados();
    if (parametrizacaoComanda != null && (comanda != null && comanda.tipoComanda != enumTipoComanda.Sequencial)){
      this.onFocusCamposComanda();
    }else{
      this.onFocusCodigoComanda();
      if (this.parametrizacaoComanda == null){
        this.getParametrizacaoComanda();
      }
    }

    if (comanda != null){
      this.codigoComanda = comanda.idComanda.toString();
      this.tipoComanda = comanda.tipoComanda as enumTipoComanda;
      this.habilitaTipoComanda = false;
      this.disabledCodigoComanda = true;
    }else{
      this.disabledCodigoComanda = false;
      this.codigoComanda = '';
      this.habilitaTipoComanda = true;
    }
    
    this.titulo = 'Abrir Comanda';
    this.abrir();
  }

  abrirComandaParaGerarSequencial(parametrizacaoComanda: ParametrizacaoComandumModel){
    if (parametrizacaoComanda != null){
      this.onFocusCamposComanda();
    }else{
      if (this.parametrizacaoComanda != null){
        this.getParametrizacaoComanda();
      }
    }

    this.disabledCodigoComanda = true;
    this.codigoComanda = '';
    this.habilitaTipoComanda = false;
    this.tipoComanda = enumTipoComanda.Sequencial;
    this.comanda = null!;

    this.acao = enumAcaoComanda.AbrirComanda;
    this.titulo = 'Abrir Comanda Sequencial';
    this.abrir();
  }

  onFocusCamposComanda() {
    if(this.parametrizacaoComanda != null && this.parametrizacaoComanda.exigeCpfcnpj == 'S'){
      this.onFocusCpf();
    }else if(this.parametrizacaoComanda != null && this.parametrizacaoComanda.exigeRazaoSocialNome == 'S'){
      this.onFocusRazaoSocialNome();
    }else if(this.parametrizacaoComanda != null && this.parametrizacaoComanda.exibeRg == 'S'){
      this.onFocusRg();
    }else if(this.parametrizacaoComanda != null && this.parametrizacaoComanda.exigeDataNascimento == 'S'){
      this.onFocusDataNascimento();
    }else if(this.parametrizacaoComanda != null && this.parametrizacaoComanda.exibeTelefoneCelular == 'S'){
      this.onFocusTelefoneCelular();
    }else if(this.parametrizacaoComanda != null && this.parametrizacaoComanda.exibeTelefoneContato == 'S'){
      this.onFocusTelefoneContato();
    }
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.cliqueCancelar();
  }

  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13) {
      switch(this.acao){
        case enumAcaoComanda.AbrirComanda:
          this.cliqueAbrirComandaEAdicionarProduto();
          break;
      // case enumAcaoComanda.AdicionarProduto:
      //   this.lancarProdutosComanda();
      //   break;
      case enumAcaoComanda.ConsultarComanda:
        this.cliqueConsultarResumoComanda();
        break;
      case enumAcaoComanda.VisualizarProdutos:
        
        break;
      }
    }
  }

  cliqueAbrirComandaEAdicionarProduto(){
    this.adicionarProdutoAposAbrirComanda = true;
    this.cliqueAbrirComanda()
  }
    
  cliqueAbrirComanda(){
    if (this.codigoComanda == ''){
      swal.fire({
        title: "Atenção",
        html: `Informe o número da comanda!`,
        showCancelButton: false,
        cancelButtonText: "Não",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
          }
        });
      return;
    }

    this.loading = true;
    let comanda: GerarComandaModel = {
      idComandaGerada: 0,
      tipoComanda: this.tipoComanda,
      idComanda: Number.parseInt(this.codigoComanda),
      idVenda: null,
      cpfCnpj: this.gerarComanda.cpfCnpj,
      razaoSocialNome: this.gerarComanda.razaoSocialNome,
      rg: this.gerarComanda.rg,
      dataNascimento: this.gerarComanda.dataNascimento,
      telefoneCelular: this.gerarComanda.telefoneCelular,
      telefoneContato: this.gerarComanda.telefoneContato,
      produtos: []
    };
    this.subsAbrir = this.apiComandaService.abrir(comanda, true)
      .subscribe((retorno: ComandumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Comanda aberta'], "Sucesso");
          this.comanda = retorno;
          if (this.adicionarProdutoAposAbrirComanda){
            this.atualizarComandas.emit(retorno);
            this.adicionarProduto(this.parametrizacaoComanda, this.comanda);
          }else{
            this.atualizarComandas.emit(retorno);
            this.fecharModal();
          }
        }
        this.subsAbrir?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsAbrir?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });   
  }

  gerarSequencial(){
    this.loading = true;
    let idComanda: number = 0;
    if (this.comanda != null){
      idComanda = this.comanda.idComanda;
    }
    let comanda: GerarComandaModel = {
      idComandaGerada: 0,
      tipoComanda: this.tipoComanda,
      idComanda: idComanda!,
      idVenda: null,
      cpfCnpj: this.gerarComanda.cpfCnpj,
      razaoSocialNome: this.gerarComanda.razaoSocialNome,
      rg: this.gerarComanda.rg,
      dataNascimento: this.gerarComanda.dataNascimento,
      telefoneCelular: this.gerarComanda.telefoneCelular,
      telefoneContato: this.gerarComanda.telefoneContato,
      produtos: []
    };
    this.subsAbrir = this.apiComandaService.abrir(comanda, true)
      .subscribe((retorno: ComandumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Comanda aberta'], "Sucesso");
          if (idComanda != 0){
            this.fecharModal();
            //this.AbriuComanda.emit(retorno);
          }else{
            this.codigoComanda = retorno.idComanda.toString();
            //this.AbriuComandaSequencial.emit(retorno);
          }
          this.atualizarComandas.emit(retorno);
        }
        this.subsAbrir?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsAbrir?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });   
  }
    
  adicionarProduto(parametrizacaoComanda: ParametrizacaoComandumModel, comanda: ComandumModel | null) {
    this.quantidadeProdutos = 0;
    this.adicionarProdutoAposAbrirComanda = false;
    this.resumoComanda = null!;

    if (parametrizacaoComanda != null){
      this.parametrizacaoComanda = parametrizacaoComanda;
    }else{
      if (this.parametrizacaoComanda != null){
        this.getParametrizacaoComanda();
      }
    }
    
    if (comanda != null){
      if (comanda.situacao == enumSituacaoComanda.Ocupada){
        this.acao = enumAcaoComanda.AdicionarProduto;
        this.comanda = comanda;
        this.titulo = `Comanda ${this.getTipoComanda(comanda?.tipoComanda)} [${comanda?.idComanda}] - Adicionar Produtos`;
        this.abrir();
        this.onFocusProduto();
        if (comanda.comandaGerada != null && comanda.comandaGerada.length > 0){
          this.consultarResumoComanda( comanda.comandaGerada[0].idComandaGerada, false);
        }
      }else{
        this.adicionarProdutoAposAbrirComanda = true;
        this.abrirComanda(parametrizacaoComanda, comanda);
      }
    }else{
      this.acao = enumAcaoComanda.ConsultarComanda;
      this.comanda = null!;
      this.codigoComanda = '';
      this.habilitaTipoComanda = true;
      this.titulo = `Adicionar Produtos - Informe a Comanda`;
      this.abrir();
      this.onFocusCodigoComanda();
    }
  }
  
  visualizarProdutos(comanda: ComandumModel | null, exibeBotaoPagamento: boolean) {
    this.exibeBotaoPagamento = exibeBotaoPagamento;
    this.acao = enumAcaoComanda.VisualizarProdutos;
    this.titulo = `Comanda [${comanda?.idComanda}] - Resumo`;
    this.consultarResumoComandaByIdComanda(comanda?.idComanda!, comanda?.tipoComanda!, true);
    
  }

  instanceResumoComanda(comanda: ComandumModel | null){
    this.resumoComanda = {
      tipoComanda: comanda?.tipoComanda!,
      idComanda: comanda?.idComanda!,
      idsVenda: null!,
      cpfCnpj: '',
      razaoSocialNome: '',
      rg: '',
      telefoneCelular: '',
      telefoneContato: '',
      dataNascimento: null!,
      dataRegistro: null!,
      cpfRegistro: '',
      subTotal: 0,
      valorPago: 0,
      valorRestante: 0,
      idComandaGerada: 0,
      produtos: []
    }
  }  

  cliqueConsultarResumoComanda(){
    if (this.codigoComanda == ''){
      swal.fire({
        title: "Atenção",
        html: `Informe o número da comanda!`,
        showCancelButton: false,
        cancelButtonText: "Não",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
          if (res.isConfirmed) {
          }
        });
      return;
    }

    this.consultarResumoComandaByIdComanda( Number.parseInt(this.codigoComanda), this.tipoComanda, false);
  }

  consultarResumoComandaByIdComanda(idComanda: number, tipoComanda: string, abrir: boolean){
    this.subsConsultarResumoComanda = this.apiComandaService.consultarResumoComandaByIdComanda(idComanda, tipoComanda, true)
      .subscribe((retorno: ResumoComandaModel) => {
        this.loading = false;
          this.tratarRetornoConsultarResumoComanda(retorno, abrir);
      },
      (err) => {

        this.loading = false;
        this.subsConsultarResumoComanda?.unsubscribe();
        if (this.showMessageError.procurarErroPorChave(err, "Finalizada")
        || this.showMessageError.procurarErroPorChave(err, "Cancelada")
        || this.showMessageError.procurarErroPorChave(err, "Livre")
        )
        {
          this.atualizarComandas.emit(this.comanda);
          
          this.comanda = {
            idComanda: idComanda,
            tipoComanda: tipoComanda,
            idLoja: 0,
            situacao: enumSituacaoComanda.Livre,
            dataAlteracao: null!,
            cpfAlteracao: '',
            comandaGerada: [],
            cpfRegistro: '',
            dataRegistro: null!,
            status: enumStatus.Ativo
          };
          
          this.abrirComanda(this.parametrizacaoComanda, this.comanda);
        }else{



          this.showMessageError.exibirMensagemValidacaoTela(err);
        }
      });   
  }

  consultarResumoComanda(idComandaGerada: number, abrir: boolean){
    this.subsConsultarResumoComanda = this.apiComandaService.consultarResumoComanda(idComandaGerada, true)
      .subscribe((retorno: ResumoComandaModel) => {
        this.loading = false;
          this.tratarRetornoConsultarResumoComanda(retorno, abrir);          
          this.exibeBotaoPagamento = true;
      },
      (err) => {
        this.loading = false;

        if (this.showMessageError.procurarErroPorChave(err, "Finalizada")
        || this.showMessageError.procurarErroPorChave(err, "Cancelada")
        || this.showMessageError.procurarErroPorChave(err, "Livre")
        )
        {
          this.atualizarComandas.emit(this.comanda);
          this.abrirComanda(this.parametrizacaoComanda, this.comanda);
        }
        this.subsConsultarResumoComanda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });   
  }

  tratarRetornoConsultarResumoComanda(retorno: ResumoComandaModel, abrir: boolean) {
    if (retorno != null){
      this.comanda = {
        idComanda: retorno.idComanda,
        tipoComanda: retorno.tipoComanda,
        idLoja: 0,
        situacao: enumSituacaoComanda.Ocupada,
        dataAlteracao: null!,
        cpfAlteracao: '',
        comandaGerada: [{
          idComandaGerada: retorno.idComandaGerada!,
          tipoComanda: retorno.tipoComanda,
          cpfCnpj: retorno.cpfCnpj,
          razaoSocialNome: retorno.razaoSocialNome,
          rg: retorno.rg,
          dataNascimento: null!,
          status: enumStatus.Ativo,
          dataRegistro: null!,
          cpfRegistro: '',
          telefoneCelular: retorno.telefoneCelular,
          telefoneContato: retorno.telefoneContato,
          comandaProdutos: [],
          comandum: null!,
          cpfAlteracao: '',
          dataAlteracao: null!,
          idComanda: 0,
          idLoja: 0,
          situacao: '',
          comandaVenda: []
        }],
        cpfRegistro: '',
        dataRegistro: null!,
        status: enumStatus.Ativo
      };
      this.resumoComanda = retorno;
      this.usuarioComanda = this.getUsuarioComanda(this.comanda);
      this.onFocusProduto();
    }
    this.subsConsultarResumoComanda?.unsubscribe();
    
    if(this.resumoComanda != null && this.resumoComanda.produtos != null){
      this.quantidadeProdutos = this.resumoComanda.produtos.length;
    }
    
    if (abrir) {
      this.abrir();
    }else{
      this.acao = enumAcaoComanda.AdicionarProduto;
    }
  }

  @ViewChild('elmPesquisarProduto') elmPesquisarProduto!: AutoComplete ;
  onFocusProduto(){
    setTimeout(()=>{ 

        //this.autofocus = true;
        if (this.elmPesquisarProduto != null){
          this.elmPesquisarProduto.focusInput();
        }
      },200);    
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.codigoComanda = '';
    this.precoProduto = 0;
    this.subTotal = 0;
    this.produtos = [];
    this.exibeBotaoPagamento = false;
    this.quantidadeProdutos = 0;
    this.cancelarAlterarObservacaoProd();
    if(this.acao != enumAcaoComanda.AbrirComanda && this.acao != enumAcaoComanda.ConsultarComanda){
      this.pesquisaPorProduto.limparInformacoesProdutoVendaParaEsc();
    }
    this.modal.hide();
  }
  
  getParametrizacaoComanda() {
    this.loading = true;
    this.subsByIdLojaUsuarioLogado = this.apiParametrizacaoComandaService.getByLojaUsuariLogado(true)
      .subscribe((retorno: ParametrizacaoComandumModel) => {
        this.loading = false;
        if (retorno != null){
          this.parametrizacaoComanda = retorno;
          if (this.comanda != null && this.comanda.tipoComanda == enumTipoComanda.Sequencial){
            this.onFocusCamposComanda();
          }else{
            this.onFocusCodigoComanda();
          }
        }
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });      
  }
  
  realizarPagamento(){
    this.confirmouPagamento();

    // if(this.quantidadeProdutos >= 0 && this.quantidadeProdutos != this.resumoComanda.produtos.length){
    //   swal.fire({
    //     title: "Atenção",
    //     html: `Existe(em) produto(s) que não foi(ram) lançado(s). Deseja lançar?`,
    //     showCancelButton: true,
    //     cancelButtonText: "Não",
    //     showConfirmButton: true,
    //     confirmButtonText: "Sim",
    //     icon: 'warning'
    //   })
    //     .then((res) => {
    //       if (res.isConfirmed) {
    //         this.lancarProdutosComanda();
    //       }else{
    //         this.confirmouPagamento();
    //       }
    //     });
    // }else{
    //   this.confirmouPagamento();
    // }
  }

  confirmouPagamento(){
    if (ConstantsService.SituacaoCaixa != enumSituacaoCaixa.Aberto){
      return this.exibirMensagemParaAbrirCaixa();
    }
    this.subsImportarComandaParaPagamento = this.apiComandaService.importarComandaParaPagamento(this.comanda.idComanda, this.comanda.tipoComanda, 0, ConstantsService.IdCaixa,ConstantsService.IdPdv, true)
      .subscribe((retorno: VendumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Venda Iniciada'], "Sucesso");
          this.router.navigate([`/pdv/produto-pagamento/${retorno.idVenda}`]);
        }
        this.subsImportarComandaParaPagamento?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsImportarComandaParaPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
  
  private exibirMensagemParaAbrirCaixa() {
    swal.fire({
      title: "Alerta",
      html: 'É obrigatório abrir um caixa para realizar vendas no PDV',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return;
  }

  onAbriuModalConfiguracao(event:any){
    PdvComponent.modalAtiva = true;
  }
  onFechouModalAlteracao(event:any){
    PdvComponent.modalAtiva = false;
  }

  onImprimirformatoCupom80mm() {
    this.onImprimir(this.formatoCupom80mm);
  }
  onImprimirformatoA4() {
    this.onImprimir(this.formatoA4);
  }
  
  // Esta função será executada quando o botão no componente filho for clicado
  onImprimir(formatoImpressao: string) {
    this.loading = true;
    this.inputDadosImpressao.id = this.resumoComanda.idComanda;//this.gerarComanda.idComanda;
    this.inputDadosImpressao.layoutImpressao = formatoImpressao;
    this.inputDadosImpressao.tipoComanda = this.resumoComanda.tipoComanda;

    var dataAtual = new Date;
    this.nomeArquivo = `extrato-comanda-${this.inputDadosImpressao.id}-${dataAtual.valueOf()}.pdf`;
    this.mensagemService.exibirLock();
    this.subsGerarExtratoComanda = this.apiGeraRelatorioComandaService.imprimirExtratoComanda(this.inputDadosImpressao, true)
      .subscribe((fileData: any) => {
         this.subsGerarExtratoComanda?.unsubscribe();

         // Agora o pai pode chamar uma função no componente filho
          this.modalImpressaoComponent.imprimir("05", fileData, 'application/pdf');
        //

        //inicio alteracao

        //fim alteração

        //  const url = window.URL.createObjectURL(blob);
        //  const iframe = document.createElement('iframe');
        //  iframe.style.display = 'none';
        //  iframe.src = url;
        //  document.body.appendChild(iframe);
        //  iframe.contentWindow?.print();

         //printJS(pdfUrl);
         this.loading = false;
         this.mensagemService.esconderLock();
      },
      (err) => {
         this.subsGerarExtratoComanda?.unsubscribe();
         this.showMessageError.exibirMensagemValidacaoTela(err);
         this.mensagemService.esconderLock();
         this.loading = false;
      }
    );
  }

  prepararItem(item: ProdutoComandaModel, solicitacaoIndividual: boolean){    
    let produtoParaPreparo: ProdutosParaPreparacaoModel = {
      idComanda: item.idComanda,
      idComandaProduto: item.idComandaProduto,
      individual: solicitacaoIndividual,
      produtos: null!
    };
    this.enviarParaPreparar(produtoParaPreparo, true);
  }

  enviarParaPreparar(produtosParaPreparo: ProdutosParaPreparacaoModel, solicitacaoIndividual: boolean){
    if(!solicitacaoIndividual){
      produtosParaPreparo = {
        idComanda: this.resumoComanda.idComanda,
        idComandaProduto: null,
        individual: solicitacaoIndividual,
        produtos: this.resumoComanda.produtos
      };
    }
    this.loading = true;
    var dataAtual = new Date;
    this.nomeArquivo = `extrato-preparacao-comanda-${produtosParaPreparo.idComanda}-${dataAtual.valueOf()}.pdf`;

    this.subsValidarParaPreparacao = this.apiGeraRelatorioComandaService.validarEnvioPreparacao(produtosParaPreparo, true)
      .subscribe(() => {
          this.subsValidarParaPreparacao?.unsubscribe();
          //sucesso
          this.confirmouEnviarParaPreparacao(produtosParaPreparo);
          //fim
      },
      (err) => {
        this.subsValidarParaPreparacao?.unsubscribe();
        this.loading = false;
        if (this.showMessageError.procurarErroPorChave(err, "solicitacao_preparo_reenviar")){
          //aqui exibe o confirm jander
          swal.fire({
            title: "Alerta",
            html: `A Solicitação de preparo já foi realizado.<br>Deseja reenviar?`,
            showCancelButton: true,
            cancelButtonText: "Não",
            showConfirmButton: true,
            confirmButtonText: "Sim",
            icon: 'warning'
          })
          .then((res) => {
              if (res.isConfirmed) {
                this.confirmouEnviarParaPreparacao(produtosParaPreparo);
              }
            }
          );
        }
        else{
          this.showMessageError.exibirMensagemValidacaoTela(err);
        }        
      });
  }

  private confirmouEnviarParaPreparacao(produtosParaPreparo: ProdutosParaPreparacaoModel) {
    this.mensagemService.exibirLock();
    this.subsEnviarParaPreparacao = this.apiGeraRelatorioComandaService.enviarComandaParaPreparacao(produtosParaPreparo, true)
      .subscribe((fileData: any) => {
        this.subsEnviarParaPreparacao?.unsubscribe();
        // Agora o pai pode chamar uma função no componente filho
        this.modalImpressaoComponent.imprimir("06",fileData, 'application/pdf');
        //
        // const blob: any = new Blob([fileData], { type: 'application/pdf' });
        // var pdfUrl = URL.createObjectURL(blob);
        // printJS(pdfUrl);
        this.loading = false;
        this.mensagemService.esconderLock();
      },
        (err) => {
          this.subsEnviarParaPreparacao?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
          this.mensagemService.esconderLock();
          this.loading = false;
        }
      );
  }
  
  imprimir(formatoImpressao: enumFormatoImpressao){    
    this.loading = true;
    this.inputDadosImpressao.id = this.resumoComanda.idComanda;//this.gerarComanda.idComanda;
    this.inputDadosImpressao.layoutImpressao = formatoImpressao;
    this.inputDadosImpressao.tipoComanda = this.resumoComanda.tipoComanda;

    var dataAtual = new Date;
    this.nomeArquivo = `extrato-comanda-${this.inputDadosImpressao.id}-${dataAtual.valueOf()}.pdf`;
    this.mensagemService.exibirLock();
    this.subsGerarExtratoComanda = this.apiGeraRelatorioComandaService.imprimirExtratoComanda(this.inputDadosImpressao, true)
      .subscribe((fileData: any) => {
         this.subsGerarExtratoComanda?.unsubscribe();
         const blob: any = new Blob([fileData], { type: 'application/pdf' });
         var pdfUrl = URL.createObjectURL(blob);
        //

        //inicio alteracao

        //fim alteração

        //  const url = window.URL.createObjectURL(blob);
        //  const iframe = document.createElement('iframe');
        //  iframe.style.display = 'none';
        //  iframe.src = url;
        //  document.body.appendChild(iframe);
        //  iframe.contentWindow?.print();

         printJS(pdfUrl);
         this.loading = false;
         this.mensagemService.esconderLock();
      },
      (err) => {
         this.subsGerarExtratoComanda?.unsubscribe();
         this.showMessageError.exibirMensagemValidacaoTela(err);
         this.mensagemService.esconderLock();
         this.loading = false;
      }
    );
  }

  getFormatDecimal(number: number): string{
    if (number == null) return '';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }  
  enumSituacaoComandaProdutoLancada: enumSituacaoComandaProduto = enumSituacaoComandaProduto.Lancada;
  enumSituacaoComandaProdutoImportada: enumSituacaoComandaProduto = enumSituacaoComandaProduto.Importada;
  enumSituacaoComandaProdutoFinalizada: enumSituacaoComandaProduto = enumSituacaoComandaProduto.Finalizada;
  enumSituacaoComandaProdutoCancelada: enumSituacaoComandaProduto = enumSituacaoComandaProduto.Cancelada;
  

  selecionouProduto(produtoSelecionadoProdutoParaPdv: ProdutoParaPdvModel){
    if (produtoSelecionadoProdutoParaPdv == null) return;
    let produtoSelecionado: ProdutoParaPdvModel = null!;
    if(produtoSelecionadoProdutoParaPdv.tipo == enumTipoProduto.Servico){
      produtoSelecionado = ConstantsService.ListaServicosCadastrados.find(f => f.idProdutoVariacao == produtoSelecionadoProdutoParaPdv.idProdutoVariacao)!;
    }else{
      produtoSelecionado = ConstantsService.ListaProdutosCadastrados.find(f => f.idProdutoVariacao == produtoSelecionadoProdutoParaPdv.idProdutoVariacao)!;
    }

    if (produtoSelecionado != null){
      this.precoProduto = (produtoSelecionado.precoVenda??0);
      this.subTotal = this.pesquisaPorProduto.quantidade * (produtoSelecionado.precoVenda??0);

      if (this.resumoComanda == null){
        this.instanceResumoComanda(this.comanda);
      }

     
      let numeroItem = 1;
      if (this.resumoComanda.produtos != null){
        numeroItem = this.resumoComanda.produtos.length + 1;
      }
      
      this.lancarProdutosComanda({
        idComandaProduto: 0,
        idComandaGerada: this.resumoComanda.idComandaGerada,
        idComanda: this.resumoComanda.idComanda,
        tipoComanda: this.resumoComanda.tipoComanda,
        idProdutoVariacao: produtoSelecionado.idProdutoVariacao,
        idProdutoUnico: produtoSelecionado.idProdutoUnico,
        quantidade: this.pesquisaPorProduto.quantidade,
        detalhe: produtoSelecionadoProdutoParaPdv.nome,
        numeroItem: numeroItem,
        observacao: '',
        idVenda: null,
        cpfCnpj: '',
        razaoSocialNome: '',
        rg: '',
        dataNascimento: null,
        precoUnitario: this.precoProduto,
        idTabelaPreco: ConstantsService.TabelaPreco.idTabelaPreco, //definido no back por segurança
        impresso: enumSimNao.Nao, //definido no back por segurança
        pago:  enumSimNao.Nao, //definido no back por segurança
        situacao: enumSituacaoComandaProduto.Lancada,//definido no back por segurança
        custoUnitario: produtoSelecionado.precoMargemZero!,
      });
    }
  }
  
  lancarProdutosComanda(produto:LancarProdutoComandaModel){
    this.loading = true;
    // let lancarProdutoComanda: LancarProdutoComandaModel[] = [];
    // this.resumoComanda.produtos.forEach(f => {
    //   if (f.idComandaProduto == 0 || f.idComandaProduto == null){
    //     lancarProdutoComanda.push(
    //       {
    //         idComandaGerada: f.idComandaGerada,
    //         idComandaProduto: f.idComandaProduto,
    //         idComanda: this.comanda.idComanda,
    //         tipoComanda: this.comanda.tipoComanda,
    //         idProdutoVariacao: f.idProdutoVariacao,
    //         idProdutoUnico: f.idProdutoUnico,
    //         quantidade: f.quantidade,
    //         idVenda: null,
    //         cpfCnpj: '',
    //         razaoSocialNome: '',
    //         rg: '',
    //         dataNascimento: null,
    //         precoUnitario: f.valorUnitario!,
    //         idTabelaPreco: ConstantsService.TabelaPreco.idTabelaPreco,
    //       }
    //     );
    //   }
    // });

    // if (lancarProdutoComanda.length == 0){
    //   this.appCustomToastService.toast(TiposMensagem.info, ['Produto(s) já foram lançado(s)'], "Informação");
    //   return;
    // }

    this.quantidadeProdutos = this.resumoComanda.produtos.length;

    this.subsLancarProdutosComanda = this.apiComandaService.lancarProdutoComanda(produto, true)
      .subscribe((retorno: LancarProdutoComandaModel) => {
        this.loading = false;
        if (retorno != null){

          let produtoComanda: ProdutoComandaModel = {
            idComandaGerada: retorno.idComandaGerada!,
            status: enumStatus.Ativo,
            idComandaProduto: 0,
            idComanda: this.resumoComanda.idComanda,
            cpfRegistro: '',
            dataRegistro: null!,
            idProdutoUnico: retorno.idProdutoUnico,
            idProdutoVariacao: retorno.idProdutoVariacao,
            quantidade: retorno.quantidade,
            valorUnitario: produto.precoUnitario,
            valorTotal: produto.precoUnitario! * retorno.quantidade!,
            idTabelaPreco: ConstantsService.TabelaPreco.idTabelaPreco,
            detalhe: retorno.detalhe,
            impresso: retorno.impresso,
            numeroItem: retorno.numeroItem,
            observacao: retorno.observacao,
            pago: retorno.pago,
            situacao: retorno.situacao,
          };

          

          produtoComanda.idComandaProduto = retorno.idComandaProduto!;
          this.resumoComanda.produtos.splice(0, 0, produtoComanda);
          this.pesquisaPorProduto.quantidade = 1;
          this.produtos = [];

          //this.appCustomToastService.toast(TiposMensagem.sucesso, ['Produto(s) lançado(s)'], "Sucesso");
          //this.AdicionouProduto.emit(retorno);
          //this.fecharModal();
        }
        this.subsLancarProdutosComanda?.unsubscribe();
      },
      (err) => {
        this.loading = false;

        if (this.showMessageError.procurarErroPorChave(err, "Finalizada")
        || this.showMessageError.procurarErroPorChave(err, "Cancelada")
        || this.showMessageError.procurarErroPorChave(err, "Livre")
        )
        {
          this.atualizarComandas.emit(this.comanda);
          this.abrirComanda(this.parametrizacaoComanda, this.comanda);
        }
        
        this.subsLancarProdutosComanda?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });      
  }  

  getTotal(){
    let listaProdutos =  this.resumoComanda.produtos.filter(f => f.status == enumStatus.Ativo);
    return listaProdutos.reduce((sum, current) => sum + current.valorTotal!, 0)
    //return this.resumoComanda.produtos.reduce((sum, current) => sum + current.valorTotal!, 0)
  }

  habilitaObservacaoProd: boolean = false;
  indexObservacaoProd: number = 0;
  exibirObservacao(item: ProdutoComandaModel, index: any){
    this.habilitaObservacaoProd = true;
    this.indexObservacaoProd = index;
    PdvComponent.modalAtiva = true;
  }

  cancelarAlterarObservacaoProd(){
    this.habilitaObservacaoProd = false;
    PdvComponent.modalAtiva = false;
  }

  salvarObservacaoProd(item: ProdutoComandaModel){
    this.loading = true;
    if(item.dataRegistro == null) item.dataRegistro = new Date();
    this.subsSalvarObservacaoProd = this.apiComandaService.salvarObservacaoItemComanda(item, item.idComandaProduto, true)
    .subscribe((retorno: ProdutoComandaModel) => {
      this.loading = false;
      if (retorno != null){
        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Observação salva'], "Sucesso");
      }
      this.subsSalvarObservacaoProd?.unsubscribe();
    },
    (err) => {
      this.loading = false;
      this.subsSalvarObservacaoProd?.unsubscribe();
      this.showMessageError.exibirMensagemValidacaoTela(err);
    });
  }
  
  removerComandaProduto(item: ProdutoComandaModel){
    if (item.idComandaProduto == 0){
      this.loading = false;
      this.appCustomToastService.toast(TiposMensagem.sucesso, ['Produto removido'], "Sucesso");
      this.consultarResumoComanda(this.comanda.comandaGerada[0].idComandaGerada, false);
      return;
    }
    
    swal.fire({
      title: "Alerta",
      html: `Tem certeza que deseja remover o item [${item.detalhe}]?`,
      showCancelButton: true,
      cancelButtonText: "Não",
      showConfirmButton: true,
      confirmButtonText: "Sim",
      icon: 'warning'
    })
      .then((res) => {
        if (res.isConfirmed) {
          this.loading = true;
          this.subsRemoverProdutoComanda = this.apiComandaService.removerProdutoComanda(item.idComandaProduto!, true).subscribe(
            (retorno: void) =>{
              this.subsRemoverProdutoComanda?.unsubscribe();
              this.loading = false;
              this.appCustomToastService.toast(TiposMensagem.sucesso, ['Produto removido'], "Sucesso");
              this.consultarResumoComanda(this.comanda.comandaGerada[0].idComandaGerada, false);
            },
            (err) => {
              this.subsRemoverProdutoComanda?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
              this.loading = false;
            });
        }
      });
  }
  
  cliqueLiberarComanda(){
    this.loading = true;
    let idComandaGerada: number | null = 0;
    if (this.comanda?.comandaGerada != null && this.comanda?.comandaGerada.length > 0){
      idComandaGerada = this.comanda?.comandaGerada[0].idComandaGerada;
    }
   
    this.subsLiberarComanda = this.apiComandaService.liberarComanda(this.comanda?.idComanda!, this.comanda?.tipoComanda!, idComandaGerada, true)
      .subscribe((retorno: ComandumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Comanda liberada'], "Sucesso");
          //this.LiberouComanda.emit(retorno);
          this.atualizarComandas.emit(retorno);
          this.fecharModal();
        }
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      }); 
  }

  getClassProdutoRemovido(item: ProdutoComandaModel): string{
    if (item.status == enumStatus.Cancelado){
      return 'line text-c-red';
    }else{
      return '';
    }
  }

  fecharModalAdicicaoProdutos(){
    this.cliqueCancelar();

    // if(this.quantidadeProdutos >= 0 && this.quantidadeProdutos != this.resumoComanda.produtos.length){
    //   swal.fire({
    //     title: "Atenção",
    //     html: `Existe(em) produto(s) que não foi(ram) lançado(s). Deseja lançar?`,
    //     showCancelButton: true,
    //     cancelButtonText: "Não",
    //     showConfirmButton: true,
    //     confirmButtonText: "Sim",
    //     icon: 'warning'
    //   })
    //     .then((res) => {
    //       if (res.isConfirmed) {
    //         this.lancarProdutosComanda();
    //       }else{
    //         this.cliqueCancelar();
    //       }
    //     });
    // }else{
    //   this.cliqueCancelar();
    // }
  }

  iniciar(item: ProdutoPesquisaModel){}

  adicionarProdutoSelecionado(produtoSelecionadoProdutoParaPdv: ProdutoParaPdvModel) {}
}