import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumTabelaStatusOrdemServico } from 'src/app/enum/enumTabelaStatusOrdemServico';
import { FiltroOrdemServicoModel } from 'src/app/models/cliente/OrdemServico/filtroOrdemServicoModel';
import { OrdemServicoModel } from 'src/app/models/cliente/OrdemServico/ordemServicoModel';
import { VendaOrdemServicoModel } from 'src/app/models/cliente/VendaOrdemServico/VendaOrdemServicoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiOrdemServicoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Financeiro;
    private version = 'api/'
    private controller = 'ordemServico';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }


    getById(id: number) {
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true);
    }

    getAllByStatus(statusOrdemServico: number) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatus/${statusOrdemServico}`, true);
    }

    getQuantidadeTotalItensPorStatus(exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getQuantidadeTotalItensPorStatus`, exibeCarregando);
    }

    get(id: number) {
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
    }

    getByFiltroPesquisaVendas(filtro: FiltroOrdemServicoModel, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/getByFiltroPesquisaVendas`, filtro, exibeCarregando);
    }

    post(obj: OrdemServicoModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj, exibeCarregando);
    }

    put(obj: OrdemServicoModel){
        return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idOrdemServico}`, obj);
    }

    reabrirOS(ordemModel: OrdemServicoModel, exibeCarregando: boolean): Observable<OrdemServicoModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/reabrirOS`, ordemModel, exibeCarregando);
    }

    cancelarReprovar(ordemModel: OrdemServicoModel, exibeCarregando: boolean): Observable<OrdemServicoModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/cancelarReprovarOS`, ordemModel, exibeCarregando);
    }

    gerarOrdemServico(ordemModel: OrdemServicoModel, exibeCarregando: boolean): Observable<OrdemServicoModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/gerarOrdemServico`, ordemModel, exibeCarregando);
    }

    salvarOrdem(ordem: OrdemServicoModel, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}`, ordem, exibeCarregando);
    }

    getAllByIdContratoOrdemServico(idContrato: number) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByIdOrdemServicoContrato/${idContrato}`, true);
    }

    importarOrcamentoAOrdemDeServico(idOrcamento: number, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/importarOrcamentoAOrdemDeServico`, idOrcamento, exibeCarregando);
    }

    getAllOSPendentePagamento(statusOrdemServico: enumTabelaStatusOrdemServico, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllOSPendentePagamentoParaPdv/${statusOrdemServico}`, exibeCarregando);
    }

    consultarOrdensServicoVendaByIdVenda(idVenda: number, exibeCarregando: boolean | false): Observable<VendaOrdemServicoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarOrdensServicoVendaByIdVenda/${idVenda}`, exibeCarregando);
    }
}