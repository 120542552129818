import { NumberSymbol } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ProdutoModel } from 'src/app/models/cliente/Produto/produtoModel';
import { VincularEstruturaMercadologicaModel } from 'src/app/models/cliente/Produto/vincularEstruturaMercadologicaModel';
import { ProdutoFornecedorModel } from 'src/app/models/cliente/ProdutoFornecedor/produtoFornecedorModel';
import { ProdutoVariacaoModel } from 'src/app/models/cliente/ProdutoVariacao/produtoVariacaoModel';
import { TabelaNveModel } from 'src/app/models/cliente/TabelaNve/tabelaNveModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { ProdutoImagemModel } from 'src/app/models/cliente/ProdutoImagem/produtoImagemModel';
import { ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';

@Injectable({
    providedIn: 'root'
})
export class ApiProdutoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private controller = 'produto';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    
    getListaFake() {
        return this.http.get<any>('assets/data/clientes.json')
          .toPromise()
          .then(res => <any[]>res.data)
          .then(data => { return data; });
      }

      gerarGtinBalancaParaTodosProdutos(exibeCarregando: boolean) {
        return this.gc.post(this.api, `api/${this.controller}/gerarGtinBalancaParaTodosProdutos`, null, exibeCarregando);
    }

    getAll(tipo: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `api/${this.controller}/getAll/${tipo}`, exibeCarregando);
    }

    getByIdLista(id: number): Observable<ProdutoModel[]>{
        return this.gc.get(this.api, `api/${this.controller}/getByIdLista/${id}`);
    }

    
    
    getByGtin(gtin: string, carregando: boolean){
        return this.gc.get(this.api, `api/${this.controller}/getByGtin?gtin=${gtin}`, carregando);
    }

    validarGtin(gtin: string, carregando: boolean){
        //return this.gc.get(this.api, `api/${this.controller}/validarGtin?gtin=${gtin}`, carregando);
        return this.gc.get(this.api, `api/${this.controller}/validarGtin/${gtin}`, carregando);
    }

    gerarGtin(carregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/gerarGtin/`, true);
    }

    gerarGtinBalanca(codigoIntegracao: number, carregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/gerarGtinBalanca/${codigoIntegracao}`, carregando);
    }

    getByIdProdutoUnico(idProdutoUnico: number, carregando: boolean){
        return this.gc.get(this.api, `${this.controller}/getByIdProdutoUnico/${idProdutoUnico}`, carregando);
    }

    GetByFilter(filtro: string, tipo: string, status: string, exibeCarregando: boolean): Observable<ProdutoVariacaoModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/query?filtro=${filtro}&status=${status}&tipo=${tipo}`, exibeCarregando);
    }

    getAllProdutoParaPdv(idTabelaPreco: number, exibeCarregando: boolean): Observable<ProdutoParaPdvModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getAllProdutoParaPdv/${idTabelaPreco}`, exibeCarregando);
    }

    getAllServicoParaPdv(idTabelaPreco: number, exibeCarregando: boolean): Observable<ProdutoParaPdvModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getAllServicoParaPdv/${idTabelaPreco}`, exibeCarregando);
    }

    
    getProdutoFornecedorParaNotaFiscal(cpfCnpj: string, codigoProdutoServico: string, ean: string, descricao: string) : Observable<ProdutoFornecedorModel>{
        return this.gc.get(this.api, `api/${this.controller}/getProdutoFornecedorParaNotaFiscal?cpfCnpj=${cpfCnpj}&codigoProdutoServico=${codigoProdutoServico}&ean=${ean}&descricao=${descricao}`, true);
    }

    getAllFornecedorProdutoByIdProdutoUnico(idProdutoUnico: number, exibeCarregando: boolean) : Observable<ProdutoFornecedorModel[]>{
        return this.gc.get(this.api, `api/${this.controller}/getAllFornecedorProdutoByIdProdutoUnico/${idProdutoUnico}`, exibeCarregando);
    }    
    vincularProdutoFornecedor(prodFornecedor: ProdutoFornecedorModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/vincularProdutoFornecedor/`, prodFornecedor, exibeCarregando);
    }
    removerVinculoProdutoFornecedor(cpfCnpj: string, idProdutoUnico: number, exibeCarregando: boolean){
        cpfCnpj = cpfCnpj.replace(/[^a-z0-9]/gi,'');
        return this.gc.delete(this.api, `api/${this.controller}/removerVinculoProdutoFornecedor?cpfCnpj=${cpfCnpj}&idProdutoUnico=${idProdutoUnico}`, exibeCarregando);
    }
    

    get(id: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `api/${this.controller}/get/${id}`, exibeCarregando)
    }

    getByCpfCnpj(cpfCnpj: string, exibeCarregando: boolean){
        return this.gc.get(this.api, `api/${this.controller}/getByCpfCnpj/${cpfCnpj}`, exibeCarregando)
    }


    verificarPendencias(idProdutoUnico: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `api/${this.controller}/verificarPendencias/${idProdutoUnico}`, exibeCarregando);
    }

    salvar(obj: ProdutoModel, exibeCarregando: boolean){
        if(obj.idProdutoUnico === 0)
            return this.gc.post(this.api, `api/${this.controller}`, obj, exibeCarregando);
        else
            return this.gc.put(this.api, `api/${this.controller}/${obj.idProdutoUnico}`, obj, exibeCarregando);
    }


    vincularEstruturaMercadologica(vinculo: VincularEstruturaMercadologicaModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/vincularEstruturaMercadologica/`, vinculo, exibeCarregando);
    }

    cadastrarNve(nve: TabelaNveModel, carregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/cadastrarNve`, nve, carregando);
    }

    removerNve(idTabelaNve: number, carregando: boolean){
        return this.gc.delete(this.api, `api/${this.controller}/removerNve/${idTabelaNve}`, carregando);
    }

    getNvesByIdProdutoUnico(idProdutoUnico: NumberSymbol, carregando: boolean): Observable<TabelaNveModel[]> {
        return this.gc.get(this.api, `api/${this.controller}/getNvesByIdProdutoUnico/${idProdutoUnico}`, carregando);
    }

    removerProdutoVariacao(idProdutoVariacao: number, exibeCarregando: boolean){
        return this.gc.delete(this.api, `api/${this.controller}/removerProdutoVariacao/${idProdutoVariacao}`, exibeCarregando);
    }
    removerProduto(idProdutoVariacao: number, exibeCarregando: boolean){
        return this.gc.delete(this.api, `api/${this.controller}/removerProduto/${idProdutoVariacao}`, exibeCarregando);
    }
    registrarAlertaDeProduto(obj: ProdutoModel, replicarNasLojas: boolean, exibeCarregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/registrarAlertaDeProduto/${replicarNasLojas}`, obj, exibeCarregando);
    }

    importarImagemProduto(idProdutoUnico: number, fileLimit: number, files: File[]){
        const formData: FormData = new FormData();
        formData.append("idProdutoUnico", JSON.stringify(idProdutoUnico));
        for (let i = 0; i < files.length; i++) {
            formData.append("name", files[i], files[i].name);
        }
        return this.gc.postFile(this.api, `api/${this.controller}/importarImagemProduto`, formData, fileLimit, files, false);
    }

    getProdutoImagens(idProdutoUnico: number, exibeCarregando: boolean) : Observable<ProdutoImagemModel[]>{
        return this.gc.get(this.api, `api/${this.controller}/getProdutoImagens/${idProdutoUnico}`, exibeCarregando);
    }

    removerImagemProduto(idProdutoUnico: number, fileName: string, exibeCarregando: boolean) : Observable<ProdutoImagemModel[]>{
        return this.gc.post(this.api, `api/${this.controller}/removerImagemProduto/${idProdutoUnico}/query?fileName=${fileName}`, null, exibeCarregando);
    }

    /**/
    getAllByStatus(status: string, tipo: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `api/${this.controller}/getAllByStatus/${status}/${tipo}`, exibeCarregando);
    }

    getAllByNomeOuDescricaoOuEan(filtro: string, tipo: string, status: string) : Observable<ProdutoVariacaoModel[]>{
        return this.gc.get(this.api, `api/${this.controller}/getAllByNomeOuDescricaoOuEan?filtro=${filtro}&status=${status}&tipo=${tipo}`, true);
    }

    getAllByEstruturaMercadologica(idItemEstruturaMercadologica: number, tipo: string) : Observable<ProdutoVariacaoModel[]>{
        return this.gc.get(this.api, `api/${this.controller}/getAllByEstruturaMercadologica/${idItemEstruturaMercadologica}/${tipo}`, true);
    }   
    
    getAllByTag(idsTags: number[],  tipo: string): Observable<ProdutoVariacaoModel[]>{
        return this.gc.post(this.api, `api/${this.controller}/getAllByTag/${tipo}`, idsTags, true);
    }

    getAllByMarca(idMarca: number, tipo: string) : Observable<ProdutoVariacaoModel[]>{
        return this.gc.get(this.api, `api/${this.controller}/getAllByMarca/${idMarca}/${tipo}`, true);
    }

    getByIdProdutoUnicoELoja(id: number){
        return this.gc.get(this.api, `api/${this.controller}/get/${id}`, true)
    }

    cadastrarAtributosEssenciais(obj: ProdutoModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `api/${this.controller}/cadastrarAtributosEssenciais`, obj, exibeCarregando);
    }

    delete(id: number){
        return this.gc.delete(this.api, `api/${this.controller}/${id}`, true);
    }

}