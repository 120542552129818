<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-lg'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 text-bold ">&nbsp;<i class="fad fa-cash-register"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row">
            <div class="col-12">
                <label class="form-label" >{{descricaoModal}}</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
            </div>
        </div>

        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoAbrirCaixa">
            <div class="col-md-2 text-right"  >
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1 pb-1" >
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalEntrada">PDV</label>
                    <app-combo-pdv #comboPdvComponent [(ngModel)]="idPdv" (change)="changePdv($event)"
                    [label]="''" [style]="'width:90%'"
                    [toolTip]="'Informe o Pdv'"
                        >
                    </app-combo-pdv>
                </div>
            </div>
            <div class="col-xl-4 col-md-4 col-sm-12 pt-1" >
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalEntrada">TROCO INICIAL</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalEntrada" suffix="" prefix="R$ " #elmTrocoInicial [disabled]="idPdv == 0"
                    currency="BRL" locale="pt-BR" inputId="valorTotalEntrada" mode="decimal" [minFractionDigits]="2" (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                    [maxFractionDigits]="2">
                </p-inputNumber>
                </div>
            </div>
            <div class="col-md-2 text-right"  >
            </div>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa">
            <div class="col-3">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalEntrada">VALOR ABERTURA</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalEntrada" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalEntrada" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                    <!-- <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotalEntrada!)}}
                    </label> -->

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalEntrada" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSaida" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-3">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalTrocoCalculado">VALOR DINHEIRO</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorTotalTrocoCalculado" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalTrocoCalculado" mode="decimal" [minFractionDigits]="2"  (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalSaida">TROCO PRÓX. CAIXA</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalSaida" suffix="" prefix="R$ " #elmTrocoFinal
                        (onFocus)="onFoucus($event)"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSaida" mode="decimal" [minFractionDigits]="2"  (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        [maxFractionDigits]="2" (ngModelChange)="changeTrocoProxCaixa($event)" [min]="0">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorSangriaFinal">SANGRIA FINAL</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorSangriaFinal" suffix="" prefix="R$ "
                        currency="BRL" locale="pt-BR" inputId="valorSangriaFinal" mode="decimal" [minFractionDigits]="2"  (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        (onFocus)="onFoucus($event)" [min]="0"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa != tipoAcaoCedula">
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label-bold" for="observacao">OBSERVAÇÃO <i class="fas fa-info-circle"
                        ngbTooltip="Informa uma observação caso necessário"></i></label>

                    <textarea maxlength="30" class="form-control input-md" id="observacao" name="observacao" type="text" style="height: 30px" (keydown)="keypress($event)" (onKeyDown)="onKeyDown($event)"
                        placeholder="Digite a observação" [(ngModel)]="abrirFecharCaixa.observacao"></textarea>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa">


            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSaida">DESC. PRODUTO&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor reduzido no preço de venda de um produto ou serviço"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDescontoVendaProduto!)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalEntrada" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSaida" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>

            <!-- <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalRecebimentoBruto">RECEBIMENTO</label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotalRecebimentoBruto!)}}
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalRecebimentoBruto" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalRecebimentoBruto" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div> -->
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSuprimento">SUPRIMENTOS&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor em dinheiro reposto ao caixa"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotalSuprimento!)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalSuprimento" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSuprimento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorDescontoCondicaoPagamento">DESCONTO PGTO&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total dos descontos concedido pela forma de pagamento informada"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDescontoCondicaoPagamento!)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDescontoCondicaoPagamento" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorDescontoCondicaoPagamento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorDescontoVendedor">DESCONTO VENDEDOR&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total dos descontos concedido aos clientes pelo vendedor no momento de pagamento"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDescontoVendedor!)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDescontoVendedor" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorDescontoVendedor" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSangrias">SANGRIAS&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total das retiradas de dinheiro do caixa para fins de segurança, controle ou gestão do fluxo de caixa"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotalSangrias!)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalSangrias" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSangrias" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorJurosCondicaoPagamento">JUROS&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total dos juros imposto pela forma de pagamento informada"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorJurosCondicaoPagamento!)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorJurosCondicaoPagamento" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorJurosCondicaoPagamento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorDespesaCondicaoPagamento">DESPESA&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total das despesas imposto pela forma de pagamento informada"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorDespesaCondicaoPagamento!)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDespesaCondicaoPagamento" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorDespesaCondicaoPagamento" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorOutrasDespesasVendaProduto">OUTRAS DESPESAS&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total de outras despesas informada na selecão de produtos no PDV"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorOutrasDespesasVendaProduto!)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorOutrasDespesasVendaProduto" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorOutrasDespesasVendaProduto" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorFreteVendaProduto">FRETE&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total do frente informada na seleção do produto no PDV"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorFreteVendaProduto!)}}
                    </label>

                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorFreteVendaProduto" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorFreteVendaProduto" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalVendaBruta">VENDA BRUTA<i class="fas fa-info-circle"
                        ngbTooltip="Valor total das vendas realizadas antes de qualquer dedução, como descontos, devoluções ou cancelamentos. É o montante total que os clientes pagaram pelos produtos ou serviços adquiridos no PDV"></i></label>
                    <label class="form-label-valor-meio-pagamento">
                        {{getFormatDecimal(inputDados.valorTotalVendaBruta!)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalVendaBruta" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalVendaBruta" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalLiquido">VALOR LÍQUIDO&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor Líquido = (Vendas Bruta/Juros) - (Descontos Produtos e Vendedor/Despesas de Pagamento e Produto/Frete)"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(valorTotalLiquido!)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorTotalLiquido" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalLiquido" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalSomado">VLR CONTADO&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor total da contagem de cédulas e moedas!"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{getFormatDecimal(inputCedula.valorTotal)}}
                    </label>
                    <!-- <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="valorTotalLiquido" suffix="" prefix="R$ " [disabled]="true"
                        currency="BRL" locale="pt-BR" inputId="valorTotalLiquido" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2">
                    </p-inputNumber> -->
                </div>
            </div>
        </div>
        <div class="row pt-2" *ngIf="tipoAcaoAbrirFecharCaixa != tipoAcaoAbrirCaixa && tipoAcaoAbrirFecharCaixa != tipoAcaoCedula">
            <p-table #dtClientes [value]="vendasPorMeioPagamento" dataKey="idTabelaMeioPagamento"
                class="p-table nowrap table-hover " [loading]="loadingListarVendaPorMeioPagamento"  responsiveLayout="scroll"

                [scrollHeight]="heightListaCliente"
                styleClass="p-datatable-cadastro-comercial " [paginator]="false" >
                <ng-template pTemplate="header">
                <tr>
                    <th class="text-center p-r-10">Meio Pagamento</th>
                    <th class="text-center p-r-10">Valor</th>
                </tr>
                </ng-template>
                <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                    <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                        <td class="text-center">
                            <span class="text-center" >{{ getDescricaoMeioPagamento(item.idTabelaMeioPagamento) }}</span>
                        </td>
                        <td class="text-center">
                            <span class="text-right" >{{ item.valor | currency:'BRL' }}</span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="2">Não há pagamentos registrados para este caixa</td>
                </tr>
                </ng-template>
            </p-table>
        </div>
        <div class="row" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoCedula">
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalEntrada">CÉDULAS</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalSaida">QUANTIDADE</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalSaida">SUBTOTAL</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalEntrada">MOEDAS</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalSaida">QUANTIDADE</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold" for="valorTotalSaida">SUBTOTAL</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label"  for="valorTotalEntrada">R$ 2,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota2" locale="pt-BR" [tabindex]="'1'"
                    (ngModelChange)="atualizarSaldo()" #elmQuantidadeNota2>
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeNota2 * 2 | currency: 'BRL'}}</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">R$ 0,01</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeMoeda1Centavo" locale="pt-BR" [tabindex]="'8'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeMoeda1Centavo * 0.01 | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">R$ 5,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota5" locale="pt-BR"  [tabindex]="'2'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeNota5 * 5 | currency: 'BRL'}}</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">R$ 0,05</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeMoeda5Centavos" locale="pt-BR" [tabindex]="'9'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeMoeda5Centavos * 0.05 | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 10,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota10" locale="pt-BR" [tabindex]="'3'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeNota10 * 10 | currency: 'BRL'}}</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">R$ 0,10</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeMoeda10Centavos" locale="pt-BR" [tabindex]="'10'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeMoeda10Centavos * 0.10 | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 20,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota20" locale="pt-BR" [tabindex]="'4'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeNota20 * 20 | currency: 'BRL'}}</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalEntrada">R$ 0,25</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeMoeda25Centavos" locale="pt-BR" [tabindex]="'11'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeMoeda25Centavos * 0.25 | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 50,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota50" locale="pt-BR" [tabindex]="'5'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeNota50 * 50 | currency: 'BRL'}}</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 0,50</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeMoeda50Centavos" locale="pt-BR" [tabindex]="'12'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeMoeda50Centavos * 0.50 | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 100,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota100" locale="pt-BR" [tabindex]="'6'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeNota100 * 100 | currency: 'BRL'}}</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 1,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeMoeda1Real" locale="pt-BR" [tabindex]="'13'"
                  (ngModelChange)="atualizarSaldo()">
                  </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                  <label class="form-label-bold">{{inputCedula.quantidadeMoeda1Real | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label">R$ 200,00</label>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="5" [(ngModel)]="inputCedula.quantidadeNota200" locale="pt-BR" [tabindex]="'7'"
                    (ngModelChange)="atualizarSaldo()">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group text-center">
                    <label class="form-label-bold">{{inputCedula.quantidadeNota200 * 200 | currency: 'BRL'}}</label>
                </div>
            </div>
          </div>
          <div class="row">
            
            <div class="col-md-4" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalCedula">TOTAL CÉDULA&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor do somatório de cédulas informadas"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{inputCedula.valorTotalDinheiro | currency: 'BRL'}}
                    </label>
                </div>
            </div>
            <div class="col-md-4" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotalMoeda">TOTAL MOEDA&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor do somatório de moedas informadas"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{inputCedula.valorTotalMoeda | currency: 'BRL'}}
                    </label>
                </div>
            </div>
            <div class="col-md-4" >
                <div class="form-group text-center">
                    <label class="form-label" for="valorTotal">TOTAL&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Valor do somatório de cédulas mais móedas informadas"></i></label>
                    <label class="form-label-valor-light-meio-pagamento">
                        {{inputCedula.valorTotal | currency: 'BRL'}}
                    </label>
                </div>
            </div>
          </div>
      </div>
    </div>
    <div class="app-modal-footer">
      
      <button [disabled]="bloquearCampos()" (click)="cliqueCancelar()" type="button" *ngIf="tipoAcaoAbrirFecharCaixa != tipoAcaoCedula"
            class="btn-min-width btn btn-secondary m-r-5 inline"><i class="fad fa-window-close"></i> Cancelar
        </button>&nbsp;
        <button type="button" (click)="contarCedula()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa"
        class="btn-min-width btn btn-primary inline"><i><img src="assets/images/preco.png" width="5"></i> Contar</button>&nbsp;
        <button type="button" (click)="abrirCaixa()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoAbrirCaixa"
            class="btn-min-width btn btn-success inline"><i class="fad fa-check"></i> Abrir</button>
        <button type="button" (click)="fecharCaixa()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoFecharCaixa"
            class="btn-min-width btn btn-success inline"><i class="fad fa-check"></i> Fechar</button>
        <button type="button" (click)="voltarCedula()" *ngIf="tipoAcaoAbrirFecharCaixa == tipoAcaoCedula"
            class="btn-min-width btn btn-primary inline"><i class="fad fa-check"></i> Voltar</button>
    </div>
</app-ui-modal>
