import { PessoaJuridicaModel } from 'src/app/models/controle/PessoaJuridica/pessoaJuridicaModel';
import { LojaUsuarioLogadoModel } from 'src/app/models/controle/loja/lojaUsuarioLogadoModel';
import { ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { ParametrizacaoCadastroModel } from 'src/app/models/cliente/ParametrizacaoCadastro/ParametrizacaoCadastroModel';
import { ParametrizacaoEnvioImpressoraModel } from 'src/app/models/cliente/ParametrizacaoEnvioImpressora/ParametrizacaoEnvioImpressoraModel';
import { ListaFixaImpressaoModel } from 'src/app/models/cliente/ParametrizacaoEnvioImpressora/ListaFixaImpressaoModel';

export interface ParametrizacaoPdvModel {
  habilitaNfce: boolean;
  habilitaNfe: boolean;
  habilitaNfse: boolean;
  dataUltAbertura: Date;
  geraNfceAutomaticamente: boolean;
  geraNfeAutomaticamente: boolean;
  impressaoDireta: boolean;
  impressaoPadrao: boolean;
  serieDocumentoFiscalNfse: string;
  serieDocumentoFiscalNfce: number;
  serieDocumentoFiscalNfe: number;
  permiteCaixaNegativo: boolean;
  permiteMultiUsuario: boolean;
  permiteEntrega: boolean;
  obrigaFecharCaixaDiario: boolean;
  idContaBancaria: number;
  transmitirNfAutomaticamente: string;
  permiteAlterarPrecoVenda: string;
}

export class ConstantsService {
  
  constructor() { }

  public static removeToken() {
    localStorage.removeItem(ConstantsService.constLojas);
    localStorage.removeItem(ConstantsService.constIdLoja);
    localStorage.removeItem(ConstantsService.constIdContaSistema);
    localStorage.removeItem(ConstantsService.constPessoaJuridica);
    localStorage.removeItem(ConstantsService.constParametrizacaoPdv);
    localStorage.removeItem(ConstantsService.constIdCaixa);
    localStorage.removeItem(ConstantsService.constIdCaixaControle);
    localStorage.removeItem(ConstantsService.constDescricaoTurno);
    localStorage.removeItem(ConstantsService.constIdPdv);
    localStorage.removeItem(ConstantsService.constDataHoraLogin);
    localStorage.removeItem(ConstantsService.constCodigoPdv);
    localStorage.removeItem(ConstantsService.constSituacaoCaixa);
    localStorage.removeItem(ConstantsService.constDataAberturaCaixa);
    localStorage.removeItem(ConstantsService.constDataAtualizacaoProduto);
    localStorage.removeItem(ConstantsService.constListaProdutosCadastrados);
    localStorage.removeItem(ConstantsService.constListaServicosCadastrados);
    localStorage.removeItem(ConstantsService.constToken);
    localStorage.removeItem(ConstantsService.constCpf);
    localStorage.removeItem(ConstantsService.constCnpj);
    localStorage.removeItem(ConstantsService.constNome);
    localStorage.removeItem(ConstantsService.constIdLoja);
    localStorage.removeItem(ConstantsService.constSiglaLoja);
    localStorage.removeItem(ConstantsService.constSiglaUf);
    localStorage.removeItem(ConstantsService.constNomeLoja);
    localStorage.removeItem(ConstantsService.constEmail);
    localStorage.removeItem(ConstantsService.constDataExpiraToken);
    localStorage.removeItem(ConstantsService.constPerfil);
    localStorage.removeItem(ConstantsService.constFuncionalidades);
    localStorage.removeItem(ConstantsService.constTabelaPreco);
    localStorage.removeItem(ConstantsService.constListaTabelaPreco);
    localStorage.removeItem(ConstantsService.constUniqueIdTelaPdv);
    localStorage.clear();
  }

    //Unique Id Produto/Pagamento PDV
    private static constUniqueIdTelaPdv: string = "app-pagamento-pdv";
    public static set UniqueIdTelaPdv(valor: string[]) {
      localStorage.setItem(this.constUniqueIdTelaPdv, JSON.stringify(valor));
    }
    public static get UniqueIdTelaPdv() {
      if (localStorage.getItem(this.constUniqueIdTelaPdv) !== '' && localStorage.getItem(this.constUniqueIdTelaPdv) !== null)
       return JSON.parse(localStorage.getItem(this.constUniqueIdTelaPdv)!?.toString()) as string[];
     return [];
    }
  

   //Funcionalidades
   private static constFuncionalidades: string = "f04a65406sd5d65"
   public static set Funcionalidades(valor: number[]) {
     localStorage.setItem(this.constFuncionalidades, JSON.stringify(valor));
   }
   public static get Funcionalidades(): number[] {
     if (localStorage.getItem(this.constFuncionalidades) !== '' && localStorage.getItem(this.constFuncionalidades) !== null)
       return JSON.parse(localStorage.getItem(this.constFuncionalidades)!?.toString()) as number[];
     return [];
   }

  //Funcionalidades
  private static constExisteOS: string = "ad5sd55s5s515ds5"
  public static set ExisteOS(valor: boolean) {
    localStorage.setItem(this.constExisteOS, JSON.stringify(valor));
  }
  public static get ExisteOS(): boolean {
    if (localStorage.getItem(this.constExisteOS) !== '' && localStorage.getItem(this.constExisteOS) !== null)
      return JSON.parse(localStorage.getItem(this.constExisteOS)!?.toString()) as boolean;
    return false;
  }

  //Retorna true se o usuário trabalha com orçamento
  private static constExisteOrcamento: string = "a808s01c80d85150d"
  public static set ExisteOrcamento(valor: boolean) {
    localStorage.setItem(this.constExisteOrcamento, JSON.stringify(valor));
  }
  public static get ExisteOrcamento(): boolean {
    if (localStorage.getItem(this.constExisteOrcamento) !== '' && localStorage.getItem(this.constExisteOrcamento) !== null)
      return JSON.parse(localStorage.getItem(this.constExisteOrcamento)!?.toString()) as boolean;
    return false;
  }

  //Retorna true se o usuário trabalha com comanda
  private static constExisteComanda: string = "a0c49s4d980ds0490"
  public static set ExisteComanda(valor: boolean) {
    localStorage.setItem(this.constExisteComanda, JSON.stringify(valor));
  }
  public static get ExisteComanda(): boolean {
    if (localStorage.getItem(this.constExisteComanda) !== '' && localStorage.getItem(this.constExisteComanda) !== null)
      return JSON.parse(localStorage.getItem(this.constExisteComanda)!?.toString()) as boolean;
    return false;
  }

  //Retorna true se o usuário trabalha com entrega
  private static constExisteEntrega: string = "as40c9590s189d8"
  public static set ExisteEntrega(valor: boolean) {
    localStorage.setItem(this.constExisteEntrega, JSON.stringify(valor));
  }
  public static get ExisteEntrega(): boolean {
    if (localStorage.getItem(this.constExisteEntrega) !== '' && localStorage.getItem(this.constExisteEntrega) !== null)
      return JSON.parse(localStorage.getItem(this.constExisteEntrega)!?.toString()) as boolean;
    return false;
  }

  //Retorna true se o usuário trabalha com condicional
  private static constExisteCondicional: string = "ae90c80d808d8515d"
  public static set ExisteCondicional(valor: boolean) {
    localStorage.setItem(this.constExisteCondicional, JSON.stringify(valor));
  }
  public static get ExisteCondicional(): boolean {
    if (localStorage.getItem(this.constExisteCondicional) !== '' && localStorage.getItem(this.constExisteCondicional) !== null)
      return JSON.parse(localStorage.getItem(this.constExisteCondicional)!?.toString()) as boolean;
    return false;
  }

    //Retorna true se o usuário trabalha com pedido de venda
    private static constExistePedidoVenda: string = "qas8408sd498d809sd8"
    public static set ExistePedidoVenda(valor: boolean) {
      localStorage.setItem(this.constExistePedidoVenda, JSON.stringify(valor));
    }
    public static get ExistePedidoVenda(): boolean {
      if (localStorage.getItem(this.constExistePedidoVenda) !== '' && localStorage.getItem(this.constExistePedidoVenda) !== null)
        return JSON.parse(localStorage.getItem(this.constExistePedidoVenda)!?.toString()) as boolean;
      return false;
    }

  //Token
  private static constToken: string = "asdfoack";
  public static set Token(token: string) {
    localStorage.setItem(this.constToken, token);
  }
  public static get Token():string {
    if (localStorage.getItem(this.constToken)) {
      return localStorage.getItem(this.constToken) as string;
    }
   return '';
  }

  //Cpf
  private static constCpf: string = "ad5c5d6";
  public static set Cpf(cpf: string) {
    localStorage.setItem(this.constCpf, cpf);
  }
  public static get Cpf() {
    if (localStorage.getItem(this.constCpf)) {
      return localStorage.getItem(this.constCpf) as string;
    }
    return '';
  }

  
  //Lista Tabela de Preco
  private static constListaTabelaPreco: string = "pdv_lasc509d5"
  public static set ListaTabelaPreco(valor: TabelaPrecoModel[]) {
    localStorage.setItem(this.constListaTabelaPreco, JSON.stringify(valor));
  }
  public static get ListaTabelaPreco(): TabelaPrecoModel[] {
    if (localStorage.getItem(this.constListaTabelaPreco) !== '' && localStorage.getItem(this.constListaTabelaPreco) !== null)
      return JSON.parse(localStorage.getItem(this.constListaTabelaPreco)!?.toString()) as TabelaPrecoModel[];
    return null!;
  }

  //Tabela de Preco
  private static constTabelaPreco: string = "pdv_casc509d5"
  public static set TabelaPreco(valor: TabelaPrecoModel) {
    localStorage.setItem(this.constTabelaPreco, JSON.stringify(valor));
  }
  public static get TabelaPreco(): TabelaPrecoModel {
    if (localStorage.getItem(this.constTabelaPreco) !== '' && localStorage.getItem(this.constTabelaPreco) !== null)
      return JSON.parse(localStorage.getItem(this.constTabelaPreco)!?.toString()) as TabelaPrecoModel;
    return null!;
  }

  //Parametrização de cadastro
  private static constParametrizacaoCadastro: string = "pdv_89fj3b"
  public static set ParametrizacaoCadastro(valor: ParametrizacaoCadastroModel) {
    localStorage.setItem(this.constParametrizacaoCadastro, JSON.stringify(valor));
  }
  public static get ParametrizacaoCadastro(): ParametrizacaoCadastroModel {
    if (localStorage.getItem(this.constParametrizacaoCadastro) !== '' && localStorage.getItem(this.constParametrizacaoCadastro) !== null)
      return JSON.parse(localStorage.getItem(this.constParametrizacaoCadastro)!?.toString()) as ParametrizacaoCadastroModel;
    return null!;
  }

  private static constParametrizacoesEnvioImpressora: string = "pdv_tjprwq"
  public static set ParametrizacoesEnvioImpressora(valor: ListaFixaImpressaoModel[]) {
    localStorage.setItem(this.constParametrizacoesEnvioImpressora, JSON.stringify(valor));
  }
  public static get ParametrizacoesEnvioImpressora(): ListaFixaImpressaoModel[] {
    if (localStorage.getItem(this.constParametrizacoesEnvioImpressora) !== '' && 
    localStorage.getItem(this.constParametrizacoesEnvioImpressora) !== null
    && localStorage.getItem(this.constParametrizacoesEnvioImpressora) !== 'undefined'
    )
      return JSON.parse(localStorage.getItem(this.constParametrizacoesEnvioImpressora)!?.toString()) as ListaFixaImpressaoModel[];
    return null!;
  }

  //Cnpj
  private static constCnpj: string = "ac5da35";
  public static set Cnpj(cnpj: string) {
    localStorage.setItem(this.constCnpj, cnpj);
  }
  public static get Cnpj() {
    if (localStorage.getItem(this.constCnpj)) {
      return localStorage.getItem(this.constCnpj) as string;
    }
    return '';
  }

  //Nome
  private static constNome: string = "abed56s";
  public static set Nome(nome: string) {
    localStorage.setItem(this.constNome, nome);
  }
  public static get Nome() {
    if (localStorage.getItem(this.constNome)) {
      return localStorage.getItem(this.constNome) as string;
    }
    return '';
  }

  //SiglaLoja
  private static constSiglaLoja: string = "aet58hh";
  public static set SiglaLoja(siglaLoja: string) {
    localStorage.setItem(this.constSiglaLoja, siglaLoja);
  }
  public static get SiglaLoja() {
    if (localStorage.getItem(this.constSiglaLoja)) {
      return localStorage.getItem(this.constSiglaLoja) as string;
    }
    return '';
  }

  //SiglaUf
  private static constSiglaUf: string = "adv5046ds";
  public static set SiglaUf(siglaUf: string) {
    localStorage.setItem(this.constSiglaUf, siglaUf);
  }
  public static get SiglaUf() {
    if (localStorage.getItem(this.constSiglaUf)) {
      return localStorage.getItem(this.constSiglaUf) as string;
    }
    return '';
  }

  //NomeLoja
  private static constNomeLoja: string = "aet44d3";
  public static set NomeLoja(nomeLoja: string) {
    localStorage.setItem(this.constNomeLoja, nomeLoja);
  }
  public static get NomeLoja() {
    if (localStorage.getItem(this.constNomeLoja)) {
      return localStorage.getItem(this.constNomeLoja) as string;
    }
    return '';
  }

  //Email
  private static constEmail: string = "asd56f8e";
  public static set Email(email: string) {
    localStorage.setItem(this.constEmail, email);
  }
  public static get Email() {
    if (localStorage.getItem(this.constEmail)) {
      return localStorage.getItem(this.constEmail) as string;
    }
    return '';
  }

  //DataExpiraToken
  private static constDataExpiraToken: string = "as456f8e";

  public static set DataExpiraToken(dataExpiraToken: Date) {
    localStorage.setItem(this.constDataExpiraToken, dataExpiraToken.toString());
  }
  public static get DataExpiraToken(): Date  {
    if (localStorage.getItem(this.constDataExpiraToken)) 
      return new Date(localStorage.getItem(this.constDataExpiraToken) as string);
    return null!;
  }

  //Perfil
  private static constPerfil: string = "sdkfoa5d9";
  public static set Perfil(perfil: string) {
    localStorage.setItem(this.constPerfil, perfil);
  }
  public static get Perfil(): string {
    if (localStorage.getItem(this.constPerfil)) {
      return localStorage.getItem(this.constPerfil) as string;
    }
    return '';
  }

  
  //Perfil
  private static constSegmento: string = "segas0846d5";
  public static set Segmento(segmento: string) {
    localStorage.setItem(this.constSegmento, segmento);
  }
  public static get Segmento(): string {
    if (localStorage.getItem(this.constSegmento)) {
      return localStorage.getItem(this.constSegmento) as string;
    }
    return '';
  }

  //Lojas
  private static constLojas: string = "bc0d50650d5655"
  public static set Lojas(valor: LojaUsuarioLogadoModel[]) {
    localStorage.setItem(this.constLojas, JSON.stringify(valor));
  }
  public static get Lojas(): LojaUsuarioLogadoModel[] {
    if (localStorage.getItem(this.constLojas) !== '' && localStorage.getItem(this.constLojas) !== null)
      return JSON.parse(localStorage.getItem(this.constLojas)!?.toString()) as LojaUsuarioLogadoModel[];
    return [];
  }

   //IdLoja
   private static constIdLoja: string = "ac05c505d65d2"
   public static set IdLoja(valor: Number) {
     localStorage.setItem(this.constIdLoja, JSON.stringify(valor));
   }
   public static get IdLoja(): Number {
     if (localStorage.getItem(this.constIdLoja) !== '' && localStorage.getItem(this.constIdLoja) !== null)
       return JSON.parse(localStorage.getItem(this.constIdLoja)!?.toString()) as Number;
     return null!;
   }

  //ParametrizacaoPdv
  private static constIdContaSistema: string = "adc52dsçskdf0d0"
  public static set IdContaSistema(valor: Number) {
    localStorage.setItem(this.constIdContaSistema, JSON.stringify(valor));
  }
  public static get IdContaSistema(): Number {
    if (localStorage.getItem(this.constIdContaSistema) !== '' && localStorage.getItem(this.constIdContaSistema) !== null)
      return JSON.parse(localStorage.getItem(this.constIdContaSistema)!?.toString()) as Number;
    return null!;
  }


  //PessoaFisicaJuridica
  private static constPessoaJuridica: string = "asdf502d8dsad"
  public static set PessoaJuridica(valor: PessoaJuridicaModel) {
    localStorage.setItem(this.constPessoaJuridica, JSON.stringify(valor));
  }
  public static get PessoaJuridica(): PessoaJuridicaModel {
    if (localStorage.getItem(this.constPessoaJuridica) !== '' && localStorage.getItem(this.constPessoaJuridica) !== null)
      return JSON.parse(localStorage.getItem(this.constPessoaJuridica)!?.toString()) as PessoaJuridicaModel;
    return null!;
  }

  //ParametrizacaoPdv
  private static constParametrizacaoPdv: string = "aloeci893jkdk"
  public static set ParametrizacaoPdv(valor: ParametrizacaoPdvModel) {
    localStorage.setItem(this.constParametrizacaoPdv, JSON.stringify(valor));
  }
  public static get ParametrizacaoPdv(): ParametrizacaoPdvModel {
    if (localStorage.getItem(this.constParametrizacaoPdv) !== '' && localStorage.getItem(this.constParametrizacaoPdv) !== null)
      return JSON.parse(localStorage.getItem(this.constParametrizacaoPdv)!?.toString()) as ParametrizacaoPdvModel;
    return null!;
  }
     
  //PermiteEstoqueNegativo
  private static constPermiteEstoqueNegativo: string = "kbjsabgdasij"
  public static set PermiteEstoqueNegativo(valor: string) {
    localStorage.setItem(this.constPermiteEstoqueNegativo, valor);
  }
  public static get PermiteEstoqueNegativo(): string {
    if (localStorage.getItem(this.constPermiteEstoqueNegativo) !== '' && localStorage.getItem(this.constPermiteEstoqueNegativo) !== null)
      return localStorage.getItem(this.constPermiteEstoqueNegativo)!?.toString();
    return "";
  }
  //PermiteEstoqueReservado
  private static constPermiteEstoqueReservado: string = "oodhasohoishda"
  public static set PermiteEstoqueReservado(valor: string) {
    localStorage.setItem(this.constPermiteEstoqueReservado, valor);
  }
  public static get PermiteEstoqueReservado(): string {
    if (localStorage.getItem(this.constPermiteEstoqueReservado) !== '' && localStorage.getItem(this.constPermiteEstoqueReservado) !== null)
      return localStorage.getItem(this.constPermiteEstoqueReservado)!?.toString();
    return "";
  }
  //PermiteEstoqueCondicional
  private static constPermiteEstoqueCondicional: string = "iisodoasodann"
  public static set PermiteEstoqueCondicional(valor: string) {
    localStorage.setItem(this.constPermiteEstoqueCondicional, valor);
  }
  public static get PermiteEstoqueCondicional(): string {
    if (localStorage.getItem(this.constPermiteEstoqueCondicional) !== '' && localStorage.getItem(this.constPermiteEstoqueCondicional) !== null)
      return localStorage.getItem(this.constPermiteEstoqueCondicional)!?.toString();
    return "";
  }
  //PermiteEstoqueCrossDocking
  private static constPermiteEstoqueCrossDocking: string = "çlksdçaksmn"
  public static set PermiteEstoqueCrossDocking(valor: string) {
    localStorage.setItem(this.constPermiteEstoqueCrossDocking, valor);
  }
  public static get PermiteEstoqueCrossDocking(): string {
    if (localStorage.getItem(this.constPermiteEstoqueCrossDocking) !== '' && localStorage.getItem(this.constPermiteEstoqueCrossDocking) !== null)
      return localStorage.getItem(this.constPermiteEstoqueCrossDocking)!?.toString();
    return "";
  }
  //PermiteEstoqueVirtual
  private static constPermiteEstoqueVirtual: string = "nnbvasvduagsy"
  public static set PermiteEstoqueVirtual(valor: string) {
    localStorage.setItem(this.constPermiteEstoqueVirtual, valor);
  }
  public static get PermiteEstoqueVirtual(): string {
    if (localStorage.getItem(this.constPermiteEstoqueVirtual) !== '' && localStorage.getItem(this.constPermiteEstoqueVirtual) !== null)
      return localStorage.getItem(this.constPermiteEstoqueVirtual)!?.toString();
    return "";
  }
  //Parâmetros estoque Contabil
  //Movimenta estoque Contabil entrada manual
  private static constMovEstContabilEntradaManual: string = "opwiqoepms223"
  public static set MovEstContabilEntradaManual(valor: string) {
    localStorage.setItem(this.constMovEstContabilEntradaManual, valor);
  }
  public static get MovEstContabilEntradaManual(): string {
    if (localStorage.getItem(this.constMovEstContabilEntradaManual) !== '' && localStorage.getItem(this.constMovEstContabilEntradaManual) !== null)
      return localStorage.getItem(this.constMovEstContabilEntradaManual)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil entrada nota Fiscal
  private static constMovEstContabilEntradaNotaFiscal: string = "545sdadsfasjv"
  public static set MovEstContabilEntradaNotaFiscal(valor: string) {
    localStorage.setItem(this.constMovEstContabilEntradaNotaFiscal, valor);
  }
  public static get MovEstContabilEntradaNotaFiscal(): string {
    if (localStorage.getItem(this.constMovEstContabilEntradaNotaFiscal) !== '' && localStorage.getItem(this.constMovEstContabilEntradaNotaFiscal) !== null)
      return localStorage.getItem(this.constMovEstContabilEntradaNotaFiscal)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil Pedido de Venda
  private static constMovEstContabilPedidoVenda: string = "çsdnansdnn542"
  public static set MovEstContabilPedidoVenda(valor: string) {
    localStorage.setItem(this.constMovEstContabilPedidoVenda, valor);
  }
  public static get MovEstContabilPedidoVenda(): string {
    if (localStorage.getItem(this.constMovEstContabilPedidoVenda) !== '' && localStorage.getItem(this.constMovEstContabilPedidoVenda) !== null)
      return localStorage.getItem(this.constMovEstContabilPedidoVenda)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil Ordem de Serviço
  private static constMovEstContabilOrdemServico: string = "pptasnnnnisrt"
  public static set MovEstContabilOrdemServico(valor: string) {
    localStorage.setItem(this.constMovEstContabilOrdemServico, valor);
  }
  public static get MovEstContabilOrdemServico(): string {
    if (localStorage.getItem(this.constMovEstContabilOrdemServico) !== '' && localStorage.getItem(this.constMovEstContabilOrdemServico) !== null)
      return localStorage.getItem(this.constMovEstContabilOrdemServico)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil emissão nota fiscal
  private static constMovEstContabilNotaFiscal: string = "ywhaifasbbsj6"
  public static set MovEstContabilNotaFiscal(valor: string) {
    localStorage.setItem(this.constMovEstContabilNotaFiscal, valor);
  }
  public static get MovEstContabilNotaFiscal(): string {
    if (localStorage.getItem(this.constMovEstContabilNotaFiscal) !== '' && localStorage.getItem(this.constMovEstContabilNotaFiscal) !== null)
      return localStorage.getItem(this.constMovEstContabilNotaFiscal)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil em Condicional
  private static constMovEstContabilCondicional: string = "plxzlasndnua4"
  public static set MovEstContabilCondicional(valor: string) {
    localStorage.setItem(this.constMovEstContabilCondicional, valor);
  }
  public static get MovEstContabilCondicional(): string {
    if (localStorage.getItem(this.constMovEstContabilCondicional) !== '' && localStorage.getItem(this.constMovEstContabilCondicional) !== null)
      return localStorage.getItem(this.constMovEstContabilCondicional)!?.toString();
    return "";
  }
  //Parâmetros estoque virtual
  //Movimenta estoque Virtual entrada manual
  private static constMovEstVirtualEntradaManual: string = "8sdadda565dsr"
  public static set MovEstVirtualEntradaManual(valor: string) {
    localStorage.setItem(this.constMovEstVirtualEntradaManual, valor);
  }
  public static get MovEstVirtualEntradaManual(): string {
    if (localStorage.getItem(this.constMovEstVirtualEntradaManual) !== '' && localStorage.getItem(this.constMovEstVirtualEntradaManual) !== null)
      return localStorage.getItem(this.constMovEstVirtualEntradaManual)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual entrada nota Fiscal
  private static constMovEstVirtualEntradaNotaFiscal: string = "lllsdnasgbu7f"
  public static set MovEstVirtualEntradaNotaFiscal(valor: string) {
    localStorage.setItem(this.constMovEstVirtualEntradaNotaFiscal, valor);
  }
  public static get MovEstVirtualEntradaNotaFiscal(): string {
    if (localStorage.getItem(this.constMovEstVirtualEntradaNotaFiscal) !== '' && localStorage.getItem(this.constMovEstVirtualEntradaNotaFiscal) !== null)
      return localStorage.getItem(this.constMovEstVirtualEntradaNotaFiscal)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual Pedido de Venda
  private static constMovEstVirtualPedidoVenda: string = "oopasndasdba9"
  public static set MovEstVirtualPedidoVenda(valor: string) {
    localStorage.setItem(this.constMovEstVirtualPedidoVenda, valor);
  }
  public static get MovEstVirtualPedidoVenda(): string {
    if (localStorage.getItem(this.constMovEstVirtualPedidoVenda) !== '' && localStorage.getItem(this.constMovEstVirtualPedidoVenda) !== null)
      return localStorage.getItem(this.constMovEstVirtualPedidoVenda)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual Ordem de Serviço
  private static constMovEstVirtualOrdemServico: string = "3mxagabbut558"
  public static set MovEstVirtualOrdemServico(valor: string) {
    localStorage.setItem(this.constMovEstVirtualOrdemServico, valor);
  }
  public static get MovEstVirtualOrdemServico(): string {
    if (localStorage.getItem(this.constMovEstVirtualOrdemServico) !== '' && localStorage.getItem(this.constMovEstVirtualOrdemServico) !== null)
      return localStorage.getItem(this.constMovEstVirtualOrdemServico)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual emissão nota fiscal
  private static constMovEstVirtualNotaFiscal: string = "kksjjasbbd592"
  public static set MovEstVirtualNotaFiscal(valor: string) {
    localStorage.setItem(this.constMovEstVirtualNotaFiscal, valor);
  }
  public static get MovEstVirtualNotaFiscal(): string {
    if (localStorage.getItem(this.constMovEstVirtualNotaFiscal) !== '' && localStorage.getItem(this.constMovEstVirtualNotaFiscal) !== null)
      return localStorage.getItem(this.constMovEstVirtualNotaFiscal)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual em Condicional
  private static constMovEstVirtualCondicional: string = "hgasrg5u9uaus"
  public static set MovEstVirtualCondicional(valor: string) {
    localStorage.setItem(this.constMovEstVirtualCondicional, valor);
  }
  public static get MovEstVirtualCondicional(): string {
    if (localStorage.getItem(this.constMovEstVirtualCondicional) !== '' && localStorage.getItem(this.constMovEstVirtualCondicional) !== null)
      return localStorage.getItem(this.constMovEstVirtualCondicional)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil em Venda PDV
  private static constMovEstContabilVendaPdv: string = "psdonasbdvv55"
  public static set MovEstContabilVendaPdv(valor: string) {
    localStorage.setItem(this.constMovEstContabilVendaPdv, valor);
  }
  public static get MovEstContabilVendaPdv(): string {
    if (localStorage.getItem(this.constMovEstContabilVendaPdv) !== '' && localStorage.getItem(this.constMovEstContabilVendaPdv) !== null)
      return localStorage.getItem(this.constMovEstContabilVendaPdv)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual em Venda PDV
  private static constMovEstVirtualVendaPdv: string = "llksdasnbnoas"
  public static set MovEstVirtualVendaPdv(valor: string) {
    localStorage.setItem(this.constMovEstVirtualVendaPdv, valor);
  }
  public static get MovEstVirtualVendaPdv(): string {
    if (localStorage.getItem(this.constMovEstVirtualVendaPdv) !== '' && localStorage.getItem(this.constMovEstVirtualVendaPdv) !== null)
      return localStorage.getItem(this.constMovEstVirtualVendaPdv)!?.toString();
    return "";
  }
  //Movimenta estoque Contabil em Nota Fiscal com origem de Venda
  private static constMovEstContabilNotaFiscalOrigVenda: string = "xzmlsdhaxzssd"
  public static set MovEstContabilNotaFiscalOrigVenda(valor: string) {
    localStorage.setItem(this.constMovEstContabilNotaFiscalOrigVenda, valor);
  }
  public static get MovEstContabilNotaFiscalOrigVenda(): string {
    if (localStorage.getItem(this.constMovEstContabilNotaFiscalOrigVenda) !== '' && localStorage.getItem(this.constMovEstContabilNotaFiscalOrigVenda) !== null)
      return localStorage.getItem(this.constMovEstContabilNotaFiscalOrigVenda)!?.toString();
    return "";
  }
  //Movimenta estoque Virtual em Nota Fiscal com origem de Venda
  private static constMovEstVirtualNotaFiscalOrigVenda: string = "77as8dsabdbyh"
  public static set MovEstVirtualNotaFiscalOrigVenda(valor: string) {
    localStorage.setItem(this.constMovEstVirtualNotaFiscalOrigVenda, valor);
  }
  public static get MovEstVirtualNotaFiscalOrigVenda(): string {
    if (localStorage.getItem(this.constMovEstVirtualNotaFiscalOrigVenda) !== '' && localStorage.getItem(this.constMovEstVirtualNotaFiscalOrigVenda) !== null)
      return localStorage.getItem(this.constMovEstVirtualNotaFiscalOrigVenda)!?.toString();
    return "";
  }
  //descrição do produto com GTIN
  private static constDescricaoProdutoImprimeGtin: string = "59093nnsiiins"
  public static set DescricaoProdutoImprimeGtin(valor: string) {
    localStorage.setItem(this.constDescricaoProdutoImprimeGtin, valor);
  }
  public static get DescricaoProdutoImprimeGtin(): string {
    if (localStorage.getItem(this.constDescricaoProdutoImprimeGtin) !== '' && localStorage.getItem(this.constDescricaoProdutoImprimeGtin) !== null)
      return localStorage.getItem(this.constDescricaoProdutoImprimeGtin)!?.toString();
    return "";
  }
  //descrição do produto com SKU
  private static constDescricaoProdutoImprimeSku: string = "7d4rg45dnslkh"
  public static set DescricaoProdutoImprimeSku(valor: string) {
    localStorage.setItem(this.constDescricaoProdutoImprimeSku, valor);
  }
  public static get DescricaoProdutoImprimeSku(): string {
    if (localStorage.getItem(this.constDescricaoProdutoImprimeSku) !== '' && localStorage.getItem(this.constDescricaoProdutoImprimeSku) !== null)
      return localStorage.getItem(this.constDescricaoProdutoImprimeSku)!?.toString();
    return "";
  }
  //Imprime o nome do produto
  private static constDescricaoProdutoImprimeNome: string = "rw99206jjdnwq"
  public static set DescricaoProdutoImprimeNome(valor: string) {
    localStorage.setItem(this.constDescricaoProdutoImprimeNome, valor);
  }
  public static get DescricaoProdutoImprimeNome(): string {
    if (localStorage.getItem(this.constDescricaoProdutoImprimeNome) !== '' && localStorage.getItem(this.constDescricaoProdutoImprimeNome) !== null)
      return localStorage.getItem(this.constDescricaoProdutoImprimeNome)!?.toString();
    return "";
  }
  //Imprime o observação do produto
  private static constDadosClienteImprimeObservacaoItem: string = "sxzasqpojsye5"
  public static set DadosClienteImprimeObservacaoItem(valor: string) {
    localStorage.setItem(this.constDadosClienteImprimeObservacaoItem, valor);
  }
  public static get DadosClienteImprimeObservacaoItem(): string {
    if (localStorage.getItem(this.constDadosClienteImprimeObservacaoItem) !== '' && localStorage.getItem(this.constDadosClienteImprimeObservacaoItem) !== null)
      return localStorage.getItem(this.constDadosClienteImprimeObservacaoItem)!?.toString();
    return "";
  }
  //trabalha com Boleto
  private static constTrabalhaComBoleto: string = "pdv-1s55s4trb"
  public static set TrabalhaComBoleto(valor: string) {
    localStorage.setItem(this.constTrabalhaComBoleto, valor);
  }
  public static get TrabalhaComBoleto(): string {
    if (localStorage.getItem(this.constTrabalhaComBoleto) !== '' && localStorage.getItem(this.constTrabalhaComBoleto) !== null)
      return localStorage.getItem(this.constTrabalhaComBoleto)!?.toString();
    return "";
  }

  //IdCaixa
  private static constIdCaixa: string = "aff65045d5"
  public static set IdCaixa(valor: number) {
    localStorage.setItem(this.constIdCaixa, valor.toString());
  }
  public static get IdCaixa(): number {
    if (localStorage.getItem(this.constIdCaixa) !== '' && localStorage.getItem(this.constIdCaixa) !== null)
      return Number.parseInt(localStorage.getItem(this.constIdCaixa)!);
    return 0;
  }

  //IdCaixaControle
  private static constIdCaixaControle: string = "qerec205d5"
  public static set IdCaixaControle(valor: number) {
    localStorage.setItem(this.constIdCaixaControle, valor.toString());
  }
  public static get IdCaixaControle(): number {
    if (localStorage.getItem(this.constIdCaixaControle) !== '' && localStorage.getItem(this.constIdCaixaControle) !== null)
      return Number.parseInt(localStorage.getItem(this.constIdCaixaControle)!);
    return 0;
  }


   //Turno
   private static constDescricaoTurno: string = "asdfs5605d55"
   public static set DescricaoTurno(valor: string) {
     localStorage.setItem(this.constDescricaoTurno, valor);
   }
   public static get DescricaoTurno(): string {
     if (localStorage.getItem(this.constDescricaoTurno) !== '' && localStorage.getItem(this.constDescricaoTurno) !== null)
       return localStorage.getItem(this.constDescricaoTurno)!;
     return '';
   }

  //IdPdv
  private static constIdPdv: string = "asdfa005d55d"
  public static set IdPdv(valor: number) {
    localStorage.setItem(this.constIdPdv, valor.toString());
  }
  public static get IdPdv(): number {
    if (localStorage.getItem(this.constIdPdv) !== '' && localStorage.getItem(this.constIdPdv) !== null)
      return Number.parseInt(localStorage.getItem(this.constIdPdv)!);
    return 0;
  }

  //TamanhoCampoCodigoLeituraCodBarra
  private static constTamanhoCampoCodigoLeituraCodBarra: string = "adc05150d65d"
  public static set TamanhoCampoCodigoLeituraCodBarra(valor: number) {
    localStorage.setItem(this.constTamanhoCampoCodigoLeituraCodBarra, valor.toString());
  }
  public static get TamanhoCampoCodigoLeituraCodBarra(): number {
    if (localStorage.getItem(this.constTamanhoCampoCodigoLeituraCodBarra) !== '' && localStorage.getItem(this.constTamanhoCampoCodigoLeituraCodBarra) !== null)
      return Number.parseInt(localStorage.getItem(this.constTamanhoCampoCodigoLeituraCodBarra)!);
    return 0;
  }

  //TamanhoCampoValorPesoLeituraCodBarra
  private static constTamanhoCampoValorPesoLeituraCodBarra: string = "wers405d565d"
  public static set TamanhoCampoValorPesoLeituraCodBarra(valor: number) {
    localStorage.setItem(this.constTamanhoCampoValorPesoLeituraCodBarra, valor.toString());
  }
  public static get TamanhoCampoValorPesoLeituraCodBarra(): number {
    if (localStorage.getItem(this.constTamanhoCampoValorPesoLeituraCodBarra) !== '' && localStorage.getItem(this.constTamanhoCampoValorPesoLeituraCodBarra) !== null)
      return Number.parseInt(localStorage.getItem(this.constTamanhoCampoValorPesoLeituraCodBarra)!);
    return 0;
  }

  //DataHoraLogin
  private static constDataHoraLogin: string = "ax5d08cd5d8"
  public static set DataHoraLogin(valor: Date | null) {
    localStorage.setItem(this.constDataHoraLogin, valor!.toString());
  }
  public static get DataHoraLogin(): Date | null {
    if (localStorage.getItem(this.constDataHoraLogin) !== '' && localStorage.getItem(this.constDataHoraLogin) !== null)
      return new Date(localStorage.getItem(this.constDataHoraLogin)!);
    return null;
  }

  public static QuantidadeHorasPermanenciaLogin: number = 16;

  //CodigoPdv
  private static constCodigoPdv: string = "yfhv0s005d55d"
  public static set CodigoPdv(valor: string) {
    localStorage.setItem(this.constCodigoPdv, valor.toString());
  }
  public static get CodigoPdv(): string {
    if (localStorage.getItem(this.constCodigoPdv) !== '' && localStorage.getItem(this.constCodigoPdv) !== null)
      return localStorage.getItem(this.constCodigoPdv)!;
    return '';
  }

  //SituacaoCaixa
  private static constSituacaoCaixa: string = "afacasf08d5590"
  public static set SituacaoCaixa(valor: string) {
    localStorage.setItem(this.constSituacaoCaixa, valor.toString());
  }
  public static get SituacaoCaixa(): string {
    if (localStorage.getItem(this.constSituacaoCaixa) !== '' && localStorage.getItem(this.constSituacaoCaixa) !== null)
      return localStorage.getItem(this.constSituacaoCaixa)!;
    return '';
  }

  //DataAberturaCaixa
  private static constDataAberturaCaixa: string = "rwerw05c0c55"
  public static set DataAberturaCaixa(valor: Date) {
    localStorage.setItem(this.constDataAberturaCaixa, valor.toString());
  }
  public static get DataAberturaCaixa(): Date {
    if (localStorage.getItem(this.constDataAberturaCaixa) !== '' && localStorage.getItem(this.constDataAberturaCaixa) !== null)
      return new Date(localStorage.getItem(this.constDataAberturaCaixa)!);
    return null!;
  }


  static constDataAtualizacaoProduto: string = "d505d55d"
  static set DataAtualizacaoProduto(param: Date){
    let json = {data: param};
    localStorage.setItem(this.constDataAtualizacaoProduto, JSON.stringify(json));
  }
  static get DataAtualizacaoProduto(): Date{
    if (localStorage.getItem(this.constDataAtualizacaoProduto) != null && localStorage.getItem(this.constDataAtualizacaoProduto) != ''){
      let json = JSON.parse(localStorage.getItem(this.constDataAtualizacaoProduto)!);
      let dataRetorno = new Date(json.data);
      return dataRetorno;
    }
    return null!;
  }

  static constListaProdutosCadastrados: string = "asdf0456"
  static set ListaProdutosCadastrados(valor: ProdutoParaPdvModel[]) {
    //localStorage.setItem(this.constListaProdutosCadastrados + "_" + ConstantsService.IdContaSistema, JSON.stringify(valor));
    localStorage.setItem(this.constListaProdutosCadastrados, JSON.stringify(valor));
    this._listaProdutosCadastrados = valor;
  }
  static _listaProdutosCadastrados: ProdutoParaPdvModel[] = [];
  static get ListaProdutosCadastrados(): ProdutoParaPdvModel[] {
    if (this._listaProdutosCadastrados != null && this._listaProdutosCadastrados.length > 0){
      return this._listaProdutosCadastrados;
    }else{
      //let temp = localStorage.getItem(this.constListaProdutosCadastrados + "_" + ConstantsService.IdContaSistema);
      let temp = localStorage.getItem(this.constListaProdutosCadastrados);
      if (temp != '' && temp != null){
        this._listaProdutosCadastrados = JSON.parse(temp) as ProdutoParaPdvModel[];
        return this._listaProdutosCadastrados;
      }
    }
    return [];
  }

  static constListaServicosCadastrados: string = "okd9asl3"
  static set ListaServicosCadastrados(valor: ProdutoParaPdvModel[]) {
    localStorage.setItem(this.constListaServicosCadastrados, JSON.stringify(valor));
    this._listaServicosCadastrados = valor;
  }
  static _listaServicosCadastrados: ProdutoParaPdvModel[] = [];
  static get ListaServicosCadastrados(): ProdutoParaPdvModel[] {
    if (this._listaServicosCadastrados != null && this._listaServicosCadastrados.length > 0){
      return this._listaServicosCadastrados;
    }else{
      let temp = localStorage.getItem(this.constListaServicosCadastrados);
      if (temp != '' && temp != null){
        this._listaServicosCadastrados = JSON.parse(temp) as ProdutoParaPdvModel[];
        return this._listaServicosCadastrados;
      }
    }
    return [];
  }

  private static constProdutoAtivo: string = "a00d650s80d556";
  public static set ProdutoAtivo(valor: boolean) {
    sessionStorage.setItem(this.constProdutoAtivo, JSON.stringify(valor));
  }
  public static get ProdutoAtivo(): boolean {
    if (sessionStorage.getItem(this.constProdutoAtivo) !== '' && sessionStorage.getItem(this.constProdutoAtivo) !== null)
      return JSON.parse(sessionStorage.getItem(this.constProdutoAtivo)!?.toString()) as boolean;
    return false;
  }



  private static constPagamentoAtivo: string = "sd05610s80855";
  static set PagamentoAtivo(valor: boolean) {
    sessionStorage.setItem(this.constPagamentoAtivo, JSON.stringify(valor));
  }
  static get PagamentoAtivo(): boolean {
    if (sessionStorage.getItem(this.constPagamentoAtivo) !== '' && sessionStorage.getItem(this.constPagamentoAtivo) !== null)
      return JSON.parse(sessionStorage.getItem(this.constPagamentoAtivo)!?.toString()) as boolean;
    return false;
  }
}