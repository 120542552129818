import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { enumStatus } from 'src/app/enum/enumStatus';
import { PdvModel } from 'src/app/models/cliente/Pdv/pdvModel';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';

@Component({
  selector: 'app-combo-pdv',
  templateUrl: './combo-pdv.component.html',
  styleUrls: ['./combo-pdv.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboPdvComponent),
    multi: true
  }]
})
export class ComboPdvComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subsListaService: Subscription | null = null;
  subService: Subscription | null = null;

  @Input() style: string = "";
  @Input() disabled: boolean = false;
  @Input() label: string = "Pdv";
  @Input() toolTip: string = "";
  @Output() change: EventEmitter<PdvModel> = new EventEmitter();
  private val: string = '' // this is the updated value that the class accesses

  @Input() pdvs: Array<PdvModel> = [];
  @Input() itens: Array<IOption> = [];
  @Input() idCampo: string = 'idPdv';

  constructor(private pdvService: ApiPdvService) { }

  ngOnInit(): void {
    this.getLista(null!);
  }

  changeValue() {
    if(this.value == '' || this.value == undefined){
      this.change.emit(null!);
    }else{
      if (this.pdvs.length > 0){
        let pdv = this.pdvs.find(f => f.idPdv == this.value);
        if (pdv != null && pdv  != undefined){
          this.change.emit(pdv);
        }else{
          this.change.emit(null!);
        }
      }else{
        this.change.emit(null!);
      }
    }
    
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }
  loading: boolean = false;
  getLista(item: PdvModel) {
      this.pdvs = [];
      this.loading = true;
      this.subsListaService = this.pdvService.getAllByStatus(enumStatus.Ativo, true).subscribe(
        (retorno: Array<PdvModel>) => {
          this.pdvs = retorno;
          this.itens = retorno.map((objeto) => {
            return {
              value: objeto.idPdv.toString(),
              label: `${objeto.idPdv?.toString()} - ${objeto.descricao}`,
              checked: false
            }
          })
          if (item  != null){
            this.value = item.idPdv;
            this.change.emit(item);
          }else if (this.itens.length == 1){
            this.value = this.itens[0].value;
            this.change.emit(this.pdvs[0]);
          }else if (item == null && this.itens.length > 1){
            this.value = this.itens[0].value;
            this.change.emit(this.pdvs[0]);
          }
          this.loading = false;
        });
   
  }
  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }
}
