<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelarCadastrar()" [dialogClass]="'modal-lg'">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header uppercase">&nbsp;<i class="far fa-print-search"></i>&nbsp;Impressão x Impressora</h4>
                <!-- <span>Informe os dados para impressão</span> -->
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label class="form-label" for="documentoImpresso">Nome Impressão</label>
                    <input  maxlength="60" class="form-control input-md" id="documentoImpresso"
                        name="documentoImpresso" type="text" placeholder="Informe o nome da impressão" [disabled]="true"
                        [(ngModel)]="inputTemporario.documentoImpresso">
                </div>
            </div>
            <div class="col-12 col-md-2 text-left">
                <div class="form-group">
                    <label class="form-label" for="formato">Layout</label>
                    <select id="formato"  class="form-control-select" autocomplete="off"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="inputTemporario.formato">
                        <option *ngFor="let item of tiposLayout;" [ngValue]="item.value">{{item.label}}
                        </option>
                    </select>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <app-combo-pdv idCampo="idPdv" [disabled]="true"
                    [(ngModel)]="inputTemporario.idPdv">
                    </app-combo-pdv>
                </div>
            </div>
            <div class="col-12 col-md-2 text-left">
                <div class="form-group">
                    <label class="form-label" for="tipoImpressao">Tipo Impressão</label>
                    <select id="tipoImpressao" class="form-control-select"
                        (change)="onChangeTipoImpressao($event)" autocomplete="off"
                        [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="inputTemporario.tipoImpressao">
                        <option *ngFor="let item of tiposImpressoes;" value="{{item.value}}">{{item.label}}
                        </option>
                    </select>
                </div>               
            </div>
            <div class="col-12 col-md-3" *ngIf="exibirInformacoesImpressaoDireta">
                <div class="form-group">
                    <label class="form-label" for="url">Url</label>
                    <input  maxlength="500" class="form-control input-md" id="url"
                        name="url" type="text" placeholder="Informe a URL"
                        [(ngModel)]="inputTemporario.url">
                </div>
            </div>
            <div class="col-12 col-md-2"  *ngIf="exibirInformacoesImpressaoDireta">
                <div class="form-group">
                    <label class="form-label" for="quantidadeImpressao">Quantidade Vias</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="2" [(ngModel)]="inputTemporario.quantidadeImpressao" prefix="" suffix="" 
                        locale="pt-BR" inputId="potencia" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" (onFocus)="onFoucus($event)"
                    >
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-12 col-md-3"  *ngIf="exibirInformacoesImpressaoDireta">
                <div class="form-group">
                    <label class="form-label" for="nomeImpressora">Nome Impressora</label>
                    <input  maxlength="150" class="form-control input-md" id="nomeImpressora"
                        name="nomeImpressora" type="text" placeholder="Informe o Nome da Impressora"
                        [(ngModel)]="inputTemporario.nomeImpressora">
                </div>
               
            </div>
            <!-- Radio buttons inline para seleção de impressora -->
            <div class="col-12 col-md-12" *ngIf="exibirInformacoesImpressaoDireta && printerDevices.length > 0">
                <label class="form-label mb-2">Selecione a Impressora:</label>
                <div class="form-check form-check-inline" *ngFor="let printer of printerDevices; let i = index">
                  <input class="form-check-input" type="radio" 
                         [value]="printer.name" 
                         name="selectedPrinter"
                         [id]="'printer' + i" 
                         (change)="inputTemporario.nomeImpressora = printer.name">
                  <label class="form-check-label mouse-pointer" 
                         [for]="'printer' + i"> <!-- Usando 'for' para associar ao input -->
                    {{ printer.name }}
                  </label>
                </div>
            </div>        </div>
        <div class="row pt-3" *ngIf="exibirInformacoesImpressaoDireta">
            <span class="text-c-red">{{mensagemImpressaoDireta}}</span>
        </div>
    </div>
    <div class="app-modal-footer">
        <button (click)="cliqueCancelarCadastrar()" type="button"
            class="btn btn-modal btn-secondary btn-modal inline mouse-pointer me-1"><i class="fad fa-arrow-left"></i> Cancelar
        </button>
        <button type="button" (click)="cliqueSalvar()"
            class="btn btn-primary btn-modal me-1 inline mouse-pointer"><i class="fad fa-save"></i> Salvar
        </button>
    </div>
</app-ui-modal>