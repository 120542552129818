<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-xl'"
    [modalFrontLayer]="modalFrontLayer"
>
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-10 text-left">
                <h4 class="m-0 text-bold">Consultar dados do CNPJ em Base Externa</h4>
            </div>
            <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2 text-right">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="cliqueCancelar()"><span aria-hidden="true">&times;</span></button>
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <div class="row">
            <div class="col-sm-12 col-md-4 col-lg-4 col-xl-4  p-t-5">
                <div class="form-group">
                    <label class="form-label" for="codigoCep">Pesquisar CNPJ</label>
                    <p-inputMask [mask]="maskCnpj" [styleClass]="'form-control input-md'" id="codigoCnpj" name="codigoCnpj" #elmCodigoCnpj type="text" placeholder="Digite o CNPJ"
                        [(ngModel)]="cpfCnpj" (keypress)="keyPress($event)"
                        >
                      </p-inputMask>
                </div>
            </div>
            <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-right p-t-15">
                <div class="form-group pt-3">
                    <button type="button" class="btn btn-sm btn-primary  "
                    [disabled]="loading"
                    (click)="pesquisar()"><i class="far fa-filter"></i> Pesquisar</button>&nbsp;
                </div>
            </div>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 pt-3" *ngIf="showDadosEmpresa != null && inputDados.message != ''">
            <label class="text-c-red">{{inputDados.message}}</label>
        </div>
        <div class="row" *ngIf="showDadosAMostrar">
            <div class="col-12">
                <div class="row p-t-15" >
                    <div class="col-xl-12 col-md-12 col-md-12 ">
                        <h6 (click)="showDadosEmpresa = !showDadosEmpresa" class="mouse-pointer underline text-c-blue" >Informações da Empresa&nbsp;<i [class]="changeClassIConShow(showDadosEmpresa)" ></i></h6> 
                    </div>
                </div>
                <div class="row p-t-10" >
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group">
                            <label class="form-label"><b>Razão Social</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Razão Social"></i>
                            <br><label class="form-label-value">{{ inputDados.razao_social }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>Data de Situação</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Data da Situação"></i>
                            <label class="form-label-value">{{ inputDados.data_situacao }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>Situação</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Situação"></i>
                            <br><label class="form-label-value">{{ inputDados.situacao }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>Atualização</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="ùltima Atualização"></i>
                            <br><label class="form-label-value">{{ inputDados.ultima_atualizacao | date: 'dd/MM/yyyy' }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>Telefone</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Telefone"></i>
                            <br><label class="form-label-value">{{ inputDados.telefone }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group">
                            <label class="form-label"><b>Regime Apuração</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Regime Apuração"></i>
                            <br><label class="form-label-value">{{ inputDados.regime_apuracao }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>CPF/CNPJ</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Cpf/Cnpj do cliente"></i>
                            <br><label class="form-label-value">{{ inputDados.cpf_cnpj | cpfCnpj }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>Inscrição Estadual</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Inscrição Estadual"></i>
                            <br><label class="form-label-value">{{ inputDados.inscricao_estadual }}</label>
                        </div>
                    </div>
                    
                    <div class="col-sm-12 col-md-5 col-lg-5 col-xl-5">
                        <div class="form-group">
                            <label class="form-label"><b>Natureza</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Natureza Jurídica"></i>
                            <br><label class="form-label-value">{{ inputDados.natureza_juridica }}</label>
                        </div>
                    </div>
                    
                   
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Email</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="E-mail da empresa"></i>
                            <br><label class="form-label-value">{{ inputDados.email }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Tipo</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Situação"></i>
                            <br><label class="form-label-value">{{ inputDados.tipo }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Capital Social</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Capital Social"></i>
                            <br><label class="form-label-value">{{ inputDados.capital_social | currency:'BRL' }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2">
                        <div class="form-group">
                            <label class="form-label"><b>Status</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Status"></i>
                            <br><label class="form-label-value">{{ inputDados.status }}</label>
                        </div>
                    </div>
                </div>
                <div class="row p-t-15" >
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-md-12 ">
                            <h6 (click)="showSocios = !showSocios" class="mouse-pointer underline text-c-blue" >Sócios&nbsp;<i [class]="changeClassIConShow(showSocios)" ></i></h6> 
                        </div>
                    </div>
                </div>
                <div *ngIf="showSocios">
                    <div class="row" *ngFor="let item of inputDados.socios" >
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                            <div class="form-group form-primary mb-0">
                                <label class="form-label"><b>Nome</b></label>
                                &nbsp;<i 
                                triggers="click:blur"
                                class="fas fa-info-circle" ngbTooltip="Nome do Sócio"></i>&nbsp;
                                <label class="form-label-value">{{ item.nome }}</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6" >
                            <div class="form-group form-primary mb-0">
                                <label class="form-label"><b>Qualificação</b></label>
                                &nbsp;<i class="fas fa-info-circle" ngbTooltip="Qualificação do Sócio"></i>&nbsp;
                                <label class="form-label-value">{{ item.qualificacao }}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-t-15" >
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-md-12 ">
                            <h6 (click)="showEndereco = !showEndereco" class="mouse-pointer underline text-c-blue" >Endereço&nbsp;<i [class]="changeClassIConShow(showEndereco)" ></i></h6> 
                        </div>
                    </div>
                    <!-- <label class="form-label"><b>Endereço</b></label> -->
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2" *ngIf="showEndereco">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>UF</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Sigla do estado"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.uf }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" *ngIf="showEndereco">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Complemento</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Complemento endereço da empresa"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.complemento }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-2 col-lg-2 col-xl-2" *ngIf="showEndereco">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Número</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Número"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.numero }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" *ngIf="showEndereco">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Logradouro</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Rua da Empresa"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.logradouro }}</label>
                        </div>
                    </div>
                </div>
                <div class="row p-t-15" *ngIf="showEndereco">
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Bairro</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Bairro"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.bairro }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>Município</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="Município"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.municipio }}</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <div class="form-group form-primary mb-0">
                            <label class="form-label"><b>CEP</b></label>
                            &nbsp;<i class="fas fa-info-circle" ngbTooltip="CEP"></i>
                            <br><label class="form-label-value">{{ inputDados.endereco?.cep }}</label>
                        </div>
                    </div>
                </div>
                <div class="row p-t-15" >
                    <div class="row">
                        <div class="col-xl-12 col-md-12 col-md-12 ">
                            <h6 (click)="showAtividades = !showAtividades" class="mouse-pointer underline text-c-blue" >Atividades&nbsp;<i [class]="changeClassIConShow(showAtividades)" ></i></h6> 
                        </div>
                    </div>
                </div>
                <div *ngIf="showAtividades">
                    <div class="row" *ngFor="let item of inputDados.atividades" >
                        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" >
                            <div class="form-group form-primary mb-0">
                                <label class="form-label"><b>CNAE</b></label>
                                &nbsp;<i class="fas fa-info-circle" ngbTooltip="CNAE"></i>
                                <br><label class="form-label-value">{{ item.codigo }}</label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" >
                            <div class="form-group form-primary mb-0">
                                <label class="form-label"><b>Tipo</b></label>
                                &nbsp;<i class="fas fa-info-circle" ngbTooltip="Tipo da atividade"></i>
                                <br><label class="form-label-value">{{ item.tipo }}</label>
                            </div>
                        </div>
                        <div class="col-md-5" >
                            <div class="form-group form-primary mb-0">
                                <label class="form-label"><b>Descrição</b></label>
                                &nbsp;<i class="fas fa-info-circle" ngbTooltip="Descrição"></i>
                                <br><label class="form-label-value">{{ item.descricao }}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="app-modal-footer">
        <button [disabled]="loading" (click)="cliqueCancelar()" type="button"
        class="btn btn-secondary inline m-r-5"><i class="fad fa-window-close"></i> Fechar
        </button>&nbsp;
        <button type="button" (click)="cliqueImportar()" *ngIf="importarCnpj && showDadosAMostrar"
            class="btn btn-primary  inline"><i class="fad fa-edit"></i> Importar</button>
    </div>
</app-ui-modal>