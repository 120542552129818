import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { SituacaoClienteModel } from 'src/app/models/cliente/Orcamento/SituacaoClienteModel';
import { PrecoModel } from 'src/app/models/cliente/Preco/precoModel';
import { ApiSituacaoClienteService } from 'src/app/services/pdv/api-situacao-cliente-service';
import { SelectOptionService } from 'src/app/shared/services/select/select-option.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-situacao-cliente',
  templateUrl: './modal-situacao-cliente.component.html',
  styleUrls: ['./modal-situacao-cliente.component.css']
})
export class ModalSituacaoClienteComponent implements OnInit {

 
  @ViewChild(UiModalComponent) modal;
  inputDados: SituacaoClienteModel = null!;
  @Output() fechouModal: EventEmitter<SituacaoClienteModel> = new EventEmitter<SituacaoClienteModel>();

  tituloModal: string = '';
  descricaoModal: string = '';
  minDate: Date = new Date();
  subsConsultar: Subscription = null!

  constructor( 
    private selectOptions: SelectOptionService,
    private showMessageError: ShowMessageError,
    private apiSituacaoClienteService: ApiSituacaoClienteService,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
    ) { 
      this.translateService.use("pt");
      this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));

    }

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  situacaoFinanceiraTemp: string[] = [];
  subsGetSituacaoCliente: Subscription = null!;
  getDetalheSituacaoFinanceiraCliente(cpfCnpj: string){
    this.subsGetSituacaoCliente = this.apiSituacaoClienteService.getSituacaoCliente(cpfCnpj, false).subscribe(
      (retorno: SituacaoClienteModel) =>{
        this.subsGetSituacaoCliente?.unsubscribe();
        if (retorno != undefined) {
            this.inputDados = retorno;
            this.situacaoFinanceiraTemp = [];
            this.inputDados.situacaoFinanceira.forEach(element => {
              this.situacaoFinanceiraTemp.push(null!);
            });
        }
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetSituacaoCliente?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  cliqueCancelar(){
    this.fechouModal.emit(this.inputDados);
    this.fecharModal();
  }

  public abrirModal(cpfCnpj: string) {
    this.situacaoFinanceiraTemp = [];
    this.exibirLimiteCredito = false;
    this.exibirSaldoDevedor = false;
    this.vCreditoRestante = null;
    this.vSaldoDevedor = null;
    this.exibirCreditoRestante = false;
    this.vLimiteCredito = null;
    this.exibirSituacao = false;
    this.exibirClassificacao = false;
    this.vClassificacao = null;

    this.tituloModal = 'Informações do Cliente';
    this.descricaoModal = 'Informe detalhes para este Orçamento';
    this.getDetalheSituacaoFinanceiraCliente(cpfCnpj);
    this.modal.show();
  }
  getFormatDecimal(number: number): string{
    if (number == null) return 'n/d';
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  fecharModal() {
    this.modal.hide();
  }

  loading: boolean = false;

  exibirLimiteCredito: boolean = false;
  vLimiteCredito: string | null = null;
  limiteCredito(){
    this.exibirLimiteCredito = !this.exibirLimiteCredito;
    if (this.exibirLimiteCredito){
      this.vLimiteCredito = this.getFormatDecimal(this.inputDados.limiteCredito!);
    }else{
      this.vLimiteCredito = null;
    }
  }

  exibirSaldoDevedor: boolean = false;
  vSaldoDevedor: string | null = null;
  saldoDevedor(){
    this.exibirSaldoDevedor = !this.exibirSaldoDevedor;
    if (this.exibirSaldoDevedor){
      this.vSaldoDevedor = this.getFormatDecimal(this.inputDados.saldoDevedor!);
    }else{
      this.vSaldoDevedor = null;
    }
  }

  exibirCreditoRestante: boolean = false;
  vCreditoRestante: string | null = null!;
  creditoRestante(){
    this.exibirCreditoRestante = !this.exibirCreditoRestante;
    if (this.exibirCreditoRestante){
      this.vCreditoRestante = this.getFormatDecimal(this.inputDados.creditoRestante!);
    }else{
      this.vCreditoRestante = null;
    }
  }

  exibirSituacao: boolean = false;
  situacao(){
    this.exibirSituacao = !this.exibirSituacao;
    if (this.exibirSituacao){
      this.situacaoFinanceiraTemp = [];
      this.inputDados.situacaoFinanceira.forEach(element => {
        this.situacaoFinanceiraTemp.push(element);
      });
    }else{
      this.situacaoFinanceiraTemp = [];
      this.inputDados.situacaoFinanceira.forEach(element => {
        this.situacaoFinanceiraTemp.push(null!);
      });
      
    }
  }

  exibirClassificacao: boolean = false;
  vClassificacao: string | null = null;
  classificacao(){
    this.exibirClassificacao = !this.exibirClassificacao;
    if (this.exibirClassificacao){
      this.vClassificacao = this.inputDados.classificacaoCliente;
      if (this.vClassificacao == ''){
        this.vClassificacao = 'Não informado';
      }
    }else{
      this.vClassificacao = null;
    }
  }

  


}
