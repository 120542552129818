declare var $: any;

import { DatePipe, DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { enumAcaoSangriaSuprimento } from 'src/app/enum/enumAcaoSangriaSuprimento';
import { enumAplicacao } from 'src/app/enum/enumAplicacao';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { CaixaControleModel } from 'src/app/models/cliente/CaixaControle/caixaControleModel';
import { CaixaSangriaSuprimentoModel } from 'src/app/models/cliente/CaixaSangriaSuprimento/caixaSangriaSuprimentoModel';
import { ProdutoParaPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { ApiProdutoService } from 'src/app/services/cliente/api-produto.service';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { ModalAbrirFecharCaixaComponent } from 'src/app/shared/business-component/modal-abrir-fechar-caixa/modal-abrir-fechar-caixa.component';
import { ModalConsultarPrecoComponent } from 'src/app/shared/business-component/modal-consultar-preco/modal-consultar-preco.component';
import { ModalSangriaSuprimentoComponent } from 'src/app/shared/business-component/modal-sangria-suprimento/modal-sangria-suprimento.component';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../shared/auth/auth.service';
import { ApiTokenRedirectService } from 'src/app/services/controle/api-token-redirect.service';
import { ModalSelecionarTabelaPrecoComponent } from 'src/app/shared/business-component/modal-selecionar-tabela-preco/modal-selecionar-tabela-preco.component';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { PedidoVendaCadastroComponent } from 'src/app/pages/pedido-venda/pedido-venda-cadastro/pedido-venda-cadastro.component';
import { OrcamentoCadastroComponent } from 'src/app/pages/orcamento/orcamento-cadastro/orcamento-cadastro.component';
import { PdvProdutoPagamentoComponent } from 'src/app/pages/pdv-produto-pagamento/pdv-produto-pagamento.component';

@Component({
  selector: 'app-pdv',
  templateUrl: './pdv.component.html',
  styleUrls: ['./pdv.component.css']
})
export class PdvComponent implements OnInit , AfterViewInit, OnDestroy{

  @ViewChild(ModalSangriaSuprimentoComponent, { static: false }) modalSangriaSuprimentoComponent!: ModalSangriaSuprimentoComponent;
  @ViewChild(ModalAbrirFecharCaixaComponent, { static: false }) modalAbrirFecharCaixaComponent!: ModalAbrirFecharCaixaComponent;
  @ViewChild(ModalConsultarPrecoComponent, { static: false }) modalConsultarPrecoComponent!: ModalConsultarPrecoComponent;
  @ViewChild(ModalSelecionarTabelaPrecoComponent, { static: false }) modalSelecionarTabelaPrecoComponent!: ModalSelecionarTabelaPrecoComponent;

  public static modalAtiva: boolean = false;

  public isAuthenticated: boolean = false;
  public usuarioLogado: boolean = false;
  public cpf: string = '';
  public nomeUsuario: string = '';
  public urlEmpresa: string = '';
  public nomeLoja: string = ConstantsService.NomeLoja;
  
  constDataAtualizacaoProduto: string = "d505d55d"
  set DataAtualizacaoProduto(param: Date){
    let json = {data: param};
    localStorage.setItem(this.constDataAtualizacaoProduto, JSON.stringify(json));
  }
  get DataAtualizacaoProduto(): any{
    if (localStorage.getItem(this.constDataAtualizacaoProduto) != null && localStorage.getItem(this.constDataAtualizacaoProduto) != ''){
      let json = JSON.parse(localStorage.getItem(this.constDataAtualizacaoProduto)!);
      let dataRetorno = new Date(json.data);
      return dataRetorno;
    }
    return null!;
  }
  tabelaDePreco: string = '';
  urlImage: string = 'assets/images/sun.png'; //assets/images/icons8-símbolo-da-lua-100.png
  get greetingMessage(){
    let h = new Date().toLocaleTimeString('pt-BR', {hour: 'numeric', hour12: false}); // formato 24 horas (0-23)
    if (parseInt(h) >= 0 && parseInt(h) <= 5) { // entre meia noite (0h) e 5 da madrugada
      {
        this.urlImage = 'assets/images/sun.png';
        return 'Boa Noite';
      }
    } else if (parseInt(h) >= 6 && parseInt(h) < 12) { // entre 6 e 11 da manhã
      {
        this.urlImage = 'assets/images/sun.png';
        return 'Bom dia';
      }
    } else if (parseInt(h) >= 12 && parseInt(h) < 18) { // entre meio dia (12h) e 17 (5h) da tarde
      {
        this.urlImage = 'assets/images/sun.png';
        return 'Boa tarde';
      }
    } else if (parseInt(h) >= 18 && parseInt(h) <= 23) { // entre 18 (6h) e 23 (11h) da noite
      {
        this.urlImage = 'assets/images/sun.png';
        return 'Boa noite';
      }
    }
    return 'Olá';
  }

  idPdv: string = ConstantsService.IdPdv.toString();
  codigoPdv: string = ConstantsService.CodigoPdv;
  idCaixa: string = ConstantsService.IdCaixa.toString();
  descricaoTurno: string = ConstantsService.DescricaoTurno;
  situacaoCaixa: string = ConstantsService.SituacaoCaixa;
  dataAberturaCaixa: Date = ConstantsService.DataAberturaCaixa;
  situacaoCaixaAberto: enumSituacaoCaixa = enumSituacaoCaixa.Aberto;
  situacaoCaixaFechado: enumSituacaoCaixa = enumSituacaoCaixa.Fechado;
  dataUltimaAtualizacao: Date = null!;

  dataAtual: Date = new Date();
  get TextoDataAtualizacao(): string
  {
    //return this.datepipe.transform(new Date(), 'dd/MM/yyyy')!;
    return 'teste';
  }

  ngAfterViewInit() {
    if (this.isAuthenticated && ConstantsService.IdContaSistema != 0 && ConstantsService.IdContaSistema != null){
        this.urlEmpresa = `${environment.apiSettings.baseUrl[AppSettings.API.File]}/api/file/downloadLogoEmpresa/Empresa/query?chave=${ConstantsService.IdContaSistema}&fileName=logo_empresa.png`;
    }else{

    }
    if (ConstantsService.TabelaPreco != null){
      this.tabelaDePreco = ConstantsService.TabelaPreco.nome;
    }
   
  }

  

  //someProperty: string = "teste";
  onOutletLoaded(component: PedidoVendaCadastroComponent | OrcamentoCadastroComponent | PdvProdutoPagamentoComponent) {
    if (component instanceof PdvProdutoPagamentoComponent) {
      //this.someProperty = component.getInformacaoCabecalho();
      // component.atualizarCabecalho.subscribe((data) => {
      //   this.someProperty = data;
      // });
      component.atualizarTextoCabecalhoSituacaoCaixa.subscribe((data) => {
        this.situacaoCaixa = data.situacao;
        this.dataAberturaCaixa = data.dataHoraEntrada!;
      });
      component.atualizarTabelaPreco.subscribe((data) => {
        if (data != null){
          this.tabelaDePreco = data.nome;
          this.atualizarProdutosServicos();
        }
      });
    }
    if (component instanceof PedidoVendaCadastroComponent) {
      component.atualizarTextoCabecalhoSituacaoCaixa.subscribe((data) => {
        this.situacaoCaixa = data.situacao;
        this.dataAberturaCaixa = data.dataHoraEntrada!;
      });
      component.atualizarTabelaPreco.subscribe((data) => {
        if (data != null){
          this.tabelaDePreco = data.nome;
          this.atualizarProdutosServicos();
        }
      });
    }
    if (component instanceof OrcamentoCadastroComponent) {
      component.atualizarTextoCabecalhoSituacaoCaixa.subscribe((data) => {
        this.situacaoCaixa = data.situacao;
        this.dataAberturaCaixa = data.dataHoraEntrada!;
      });
      component.atualizarTabelaPreco.subscribe((data) => {
        if (data != null){
          this.tabelaDePreco = data.nome;
          this.atualizarProdutosServicos();
        }
      });
    }
  }

  constructor(public auth: AuthService,
    private apiTokenRedirectService: ApiTokenRedirectService,
    private apiProdutoService: ApiProdutoService,
    public datepipe: DatePipe,
    private apiUtilService: ApiUtilService,
    private showMessageError: ShowMessageError,
    private http:HttpClient,
    private router: Router,
    @Inject(DOCUMENT) private document: any) { 

      this.isAuthenticated = auth.isAuthenticated();

    }
  ngOnDestroy(): void {
    this.pauseTimer();
  }


  private elem: any;
  public textoMenuFullScreen: string = 'Full';
  handleScreen() {
      if (this.textoMenuFullScreen == 'Full') {
          this.openFullscreen();
          this.textoMenuFullScreen = 'Normal';
      } else if (this.textoMenuFullScreen == 'Normal') {
          this.closeFullscreen();
          this.textoMenuFullScreen = 'Full';
      }
  }
  openFullscreen() {
      if (this.elem.requestFullscreen) {
          this.elem.requestFullscreen();
      } else if (this.elem.mozRequestFullScreen) {
          /* Firefox */
          this.elem.mozRequestFullScreen();
      } else if (this.elem.webkitRequestFullscreen) {
          /* Chrome, Safari and Opera */
          this.elem.webkitRequestFullscreen();
      } else if (this.elem.msRequestFullscreen) {
          /* IE/Edge */
          this.elem.msRequestFullscreen();
      }
  }

  /* Close fullscreen */
  closeFullscreen() {
      if (this.document.exitFullscreen) {
          this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
          /* Firefox */
          this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
          /* Chrome, Safari and Opera */
          this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
          /* IE/Edge */
          this.document.msExitFullscreen();
      }
  }

  onResize(event) {
      event.target.innerWidth; // window width
  }
  
  ngOnInit(): void {
    this.elem = document.documentElement;
    this.usuarioLogado = ConstantsService.Token != null;
    this.cpf = ConstantsService.Cpf;
    this.nomeUsuario = ConstantsService.Nome;
    this.dataUltimaAtualizacao = ConstantsService.DataAtualizacaoProduto!;

    $(document).delegate('aside .logo', 'click', function (event: any) {
      event.preventDefault();
      $('aside').toggleClass('asideoff');
      $('.router-outlet').toggleClass('asideoff'); //quando clica aumenta o miolo
      $('div.lf').toggleClass('asideoff'); //quando clica apresenta o menu superior com o botão desligar
    });

    $(document).delegate('.click-menu-direito', 'click', function (event: any) {
      event.preventDefault();
      $('div nav').toggleClass('active');
    });


    $(document).mouseup(function (e: any) {
      var menu = $("div nav");

      // if the target of the click isn't the container nor a descendant of the container
      if (!menu.is(e.target) && menu.has(e.target).length === 0) {
        $('div nav').removeClass('active');
      }
    });

    this.startTimer();
    //this.getIPAddress();
  }

  atualizarProdutosServicos(){
    this.atualizarProdutos();
    this.atualizarServicos();
  }

  subProdutoService: Subscription = null!;
  atualizarProdutos(){
    this.subProdutoService = this.apiProdutoService.getAllProdutoParaPdv(ConstantsService.TabelaPreco.idTabelaPreco, true ).subscribe(
      (retorno: Array<ProdutoParaPdvModel>) =>{
        if (retorno != undefined) {
          ConstantsService.ListaProdutosCadastrados = retorno;
          ConstantsService.DataAtualizacaoProduto = new Date();
          this.dataUltimaAtualizacao = ConstantsService.DataAtualizacaoProduto;
        }
      },
      (err) => {
        this.subProdutoService?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  subServicoService: Subscription = null!;
  atualizarServicos(){
    this.subServicoService = this.apiProdutoService.getAllServicoParaPdv(ConstantsService.TabelaPreco.idTabelaPreco, true).subscribe(
      (retorno: Array<ProdutoParaPdvModel>) =>{
        this.subServicoService?.unsubscribe();
        if (retorno != undefined) {
          ConstantsService.ListaServicosCadastrados = retorno;
          ConstantsService.DataAtualizacaoProduto = new Date();
          this.dataUltimaAtualizacao = ConstantsService.DataAtualizacaoProduto;
        }
      },
      (err) => {
        this.subServicoService?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  verificarDadosAtualizados(): boolean{
    if (ConstantsService.DataAtualizacaoProduto == null) return false;
    const date1 = new Date as any;
    const date2 = ConstantsService.DataAtualizacaoProduto  as any;

    const diffInMs = date1 - date2;
    const diffInHours = diffInMs / 1000 / 60 / 60;
    if (diffInHours > 1) return false;
    return true;
  }

  navegarPaginaMenuPdv() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/menu/default'])
    );
    window.open(url, '_self');
  }

  navegarPaginaPdv() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/pdv/produto-pagamento'])
    );
    window.open(url, '_self');
  }

  navegarPaginaAbrirComanda() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/comanda/selecionar'])
    );
    window.open(url, '_self');
  }

  abrirModalConsultarPreco(){
    PdvComponent.modalAtiva = true;
    this.modalConsultarPrecoComponent.abrirModal();
  }

  abrirModalSuprimento() {
    PdvComponent.modalAtiva = true;
    this.modalSangriaSuprimentoComponent.abrirModal(enumAcaoSangriaSuprimento.Suprimento);
  }

  abrirModalSangria() {
    PdvComponent.modalAtiva = true;
    this.modalSangriaSuprimentoComponent.abrirModal(enumAcaoSangriaSuprimento.Sangria);
  }

  abrirFecharCaixa(acao: string){
    PdvComponent.modalAtiva = true;
    this.modalAbrirFecharCaixaComponent.abrirModal(acao as enumAcaoAbrirFecharCaixa, false);
  }

  executouSangriaSuprimento(item:CaixaSangriaSuprimentoModel){
    
  }
  abriuFechouCaixa(caixaControle: CaixaControleModel){
    if (caixaControle != null){
      this.situacaoCaixa = caixaControle.situacao;
      this.dataAberturaCaixa = caixaControle.dataHoraEntrada!;
    }
  }
  fechouModal(param: any){
    PdvComponent.modalAtiva = false;
  }
  // navegarPaginaSuporte() {
  //   const url = this.router.serializeUrl(
  //     this.router.createUrlTree(['/suporte/contato'])
  //   );
  //   window.open(url, '_self');
  // }

  navegarPaginaInvare() {
    window.open("https://www.invare.com.br", '_blank');
  }

  subsTransferPage: Subscription = null!;
  navegarPaginaRetaguarda(){
    this.subsTransferPage = this.apiTokenRedirectService.requestPage({
      aplicacao: enumAplicacao.BAK,
      url: `${environment.urlRetaguarda}home/default`
    })
    .subscribe((retorno: any) => {
      this.subsTransferPage!.unsubscribe();
      if (retorno != null) {
        
        window.open(`${environment.urlRedirectRetaguarda}${retorno.idTransfereToken}`, '_blank');
      }
    } ,
    (err) => {
      this.subsTransferPage!.unsubscribe();
      this.showMessageError.exibirMensagemValidacaoTela(err);
    });
  }

  alterarTabelaDePreco(){
    this.modalSelecionarTabelaPrecoComponent.abrirModal();
  }

  selecionouTabelaPreco(event: TabelaPrecoModel){
    if (event != null){
      this.tabelaDePreco = event.nome;
      this.atualizarProdutosServicos();
    }
  }

  navegarPaginaLogin(){

    this.auth.logout().subscribe(
      (retorno: any) =>{
       this.confirmarNavegarPaginaLogin();
      }
      ,
      (err) => {
       this.confirmarNavegarPaginaLogin();
      });

    
  }
  confirmarNavegarPaginaLogin(){

    ConstantsService.removeToken();

    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/login'])
    );
    window.open(url, '_self');
  }

  date: Date = new Date();
  timeLeft: number = 0;
  interval;
  private pauseTimer() {
    clearInterval(this.interval);
  }
  private startTimer() {
    this.interval = setInterval(() => {
     //atualizar hora
     this.date = new Date();
    //  if (ConstantsService.DataHoraLogin == null) ConstantsService.DataHoraLogin = new Date();
    //  var time = this.date.getTime() - ConstantsService.DataHoraLogin!.getTime(); 
    //  var horas = time / (1000 * 3600); //Diference in hours

    //  if (horas >= ConstantsService.QuantidadeHorasPermanenciaLogin){
    //   this.navegarPaginaLogin();
    //  }
    }, 60000)
  }

  get DataHora(): string{
    return `${this.date.getHours().toString().padStart(2, '0')}:${this.date.getMinutes().toString().padStart(2, '0')} ${this.date.getDate().toString().padStart(2, '0')}/${(this.date.getMonth()+1).toString().padStart(2, '0')}`;
  }

  //subsIpAddress: Subscription = null!;
  //ipAddress: string = '';
  // getIPAddress()
  // {
  //   this.subsIpAddress = this.apiUtilService.getIpAddress()
  //   .subscribe(retorno => {
  //     this.ipAddress = retorno.ip;
  //   },
  //   (err) => {
  //     this.subsIpAddress?.unsubscribe();
  //   });
  // }
}
