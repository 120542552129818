import swal from 'sweetalert2';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { NotaFiscalModel } from 'src/app/models/cliente/notaFiscal/notaFiscalModel';
import { enumStatusEnvioNotaFiscal } from 'src/app/enum/enumStatusEnvioNotaFiscal';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { enumTipoNotaFiscal } from 'src/app/enum/enumTipoNotaFiscal';
import { ApiNotaFiscalConsultaService } from 'src/app/services/notaFiscal/api-nota-fiscal-consulta.service';
import { ApiNotaFiscalEnvioSistemicoService } from 'src/app/services/notaFiscal/api-nota-fiscal-envio-sistemico';
import { ConstantsService } from '../../constants/constants.service';
import { enumSimNao } from 'src/app/enum/enumSimNao';

@Component({
  selector: 'app-modal-verifica-atualizacao-sefaz',
  templateUrl: './modal-verifica-atualizacao-sefaz.component.html',
  styleUrls: ['./modal-verifica-atualizacao-sefaz.component.scss']
})
export class ModalVerificaAtualizacaoSefazComponent implements OnInit {

  @ViewChild(UiModalComponent) modalEnvioNf;

  form: any;
  @Output() terminoVerificacao: EventEmitter<NotaFiscalModel> = new EventEmitter();
  @Input() IdExterno: string = '';
  @Input() tipoNotaFiscal: enumTipoNotaFiscal = null!;
  notaFiscal: NotaFiscalModel = null!;
  subsApiNfceService: Subscription | null = null;

  constructor(private apiNotaFiscalConsultaService: ApiNotaFiscalConsultaService,
    private apiNotaFiscalEnvioSistemicoService: ApiNotaFiscalEnvioSistemicoService,
    private appCustomToastService: AppCustomToastService,
    private showMessageError: ShowMessageError) { 
  }


  
  timeLeft: number = 0;
  interval;
  intervalVerificarStatusEnvio;
  mensagemEnvioNf: string = `Nota enviada com sucesso, aguarde retorno\nTempo de processamento ${this.timeLeft} segundo(s)`;
  emProcessamentoVerificarStatusEnvio: boolean = false;

 totalVerificacoes: number = 20;
 contador: number = 1;
  private verificarStatusEnvio() {
    if (this.contador == this.totalVerificacoes)
    {
      this.contador = 1;
      this.esconderModalEnvioNf();
      this.pauseTimer();
      return;
    }
    if (!this.emProcessamentoVerificarStatusEnvio){
      this.emProcessamentoVerificarStatusEnvio = true;
      this.contador += 1;
      this.subsApiNfceService = this.apiNotaFiscalConsultaService.verificarStatusEnvio(this.IdExterno, this.tipoNotaFiscal, false)?.subscribe(
        (retorno: NotaFiscalModel) => {
          this.subsApiNfceService?.unsubscribe();
          if (retorno != null) {
            this.pauseTimer();
            this.contador = 0;
            

            if (retorno.statusEnvio == enumStatusEnvioNotaFiscal.Concluido)
            {
              this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal processada com sucesso'], "Sucesso");
              this.emProcessamentoVerificarStatusEnvio = true;
              this.terminoVerificacao.emit(retorno);
              this.esconderModalEnvioNf();
            }else  if (retorno.statusEnvio == enumStatusEnvioNotaFiscal.Rejeitado)
            {
              swal.fire({
                title: "Nota Fiscal Rejeitada",
                html: `Nota Fiscal rejeitada. Mensagem: <b>${retorno.mensagem}</b>. Chave: <b>${retorno.id}</b>`,
                showCancelButton: false,
                icon: 'warning'
              })
                .then((res) => {
                  this.emProcessamentoVerificarStatusEnvio = true;
                  this.terminoVerificacao.emit(retorno);
                  this.esconderModalEnvioNf();
                });
            } else  if (retorno.statusEnvio == enumStatusEnvioNotaFiscal.Denegado)
            {
              swal.fire({
                title: "Nota Fiscal Denegada",
                html: `Nota Fiscal denegada, visualize a log de retorno para maiores informações. Chave <b>${retorno.id}</b>`,
                showCancelButton: false,
                icon: 'error'
              })
                .then((res) => {
                  this.emProcessamentoVerificarStatusEnvio = true;
                  this.terminoVerificacao.emit(retorno);
                  this.esconderModalEnvioNf();
                });
            } else  if (retorno.statusEnvio == enumStatusEnvioNotaFiscal.Cancelado)
            {
              swal.fire({
                title: "Nota Fiscal Cancelada",
                html: `Nota Fiscal cancelada, visualize a log de retorno para maiores informações. Chave <b>${retorno.id}</b>`,
                showCancelButton: false,
                icon: 'success'
              })
                .then((res) => {
                  this.emProcessamentoVerificarStatusEnvio = true;
                  this.terminoVerificacao.emit(retorno);
                  this.esconderModalEnvioNf();
                });
            }
          } else {
            this.emProcessamentoVerificarStatusEnvio = false;
          }
        },
        (err) => {
          this.terminoVerificacao.emit(null!);
          this.subsApiNfceService?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
          this.esconderModalEnvioNf();
        }
      );
      }
  }

  private startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft < 60) {
        this.timeLeft++;
        this.mensagemEnvioNf = `Nota enviada com sucesso, aguarde retorno.\r\nTempo de processamento ${this.timeLeft} segundo(s)`;
        
      } else {
        this.esconderModalEnvioNf();
        this.pauseTimer();
        swal.fire({
          title: "Alerta",
          html: `Nota emitida, porém não estamos tendo retorno da Receita Federal. Se o problema persistir, contate o adminsitrador do sistema`,
          showCancelButton: false,
          icon: 'warning'
        })
          .then((res) => {
            this.timeLeft = 0;
          });
      }
      if ((this.timeLeft%5 == 0 || this.timeLeft < 5) && !this.emProcessamentoVerificarStatusEnvio) {
        this.verificarStatusEnvio();
      }
    }, 3000)
  }

  private pauseTimer() {
    clearInterval(this.interval);
    
  }

  private exibirModalEnvioNf() {
    this.modalEnvioNf.show();
    this.contador = 0;
    this.startTimer();
  }

  private esconderModalEnvioNf() {
    this.pauseTimer();
    this.timeLeft = 0;
    this.contador = 0;
    this.modalEnvioNf.hide();
  }

  cliqueCancelar(){
    this.esconderModalEnvioNf();
    this.terminoVerificacao.emit(this.notaFiscal);
  }

  public abrirModal(idExterno: string, tipoNota: enumTipoNotaFiscal) {
    this.emProcessamentoVerificarStatusEnvio = false;
   this.IdExterno = idExterno;
   this.tipoNotaFiscal = tipoNota;
   this.exibirModalEnvioNf();
  }

  public fecharModal(idExterno: string) {
    this.IdExterno = idExterno;
    this.esconderModalEnvioNf();
    this.terminoVerificacao.emit(this.notaFiscal);
   }

  ngOnInit() {
    
  }

  ngOnDestroy(){
    this.subsApiNfceService?.unsubscribe();
  }
  
  movEstContabilNotaFiscalOrigVenda: string = "N";
  permiteEstoqueVirtual: string = "N";
  movEstVirtualNotaFiscalOrigVenda: string = "N";
  permiteEstoqueNegativo: string = "N";

  subsApiEnviarNf: Subscription = null!;
  enviarNfFromIdVenda(idVenda: number, cpfCnpj: string, tipoNotaFiscal: enumTipoNotaFiscal) {
    var transmitirNfAutomaticamente: boolean = false;
    if (ConstantsService.ParametrizacaoPdv.transmitirNfAutomaticamente == enumSimNao.Sim){
      transmitirNfAutomaticamente = true;
    }
    //verificar os parâmetros de estoque
    this.movEstContabilNotaFiscalOrigVenda = ConstantsService.MovEstContabilNotaFiscalOrigVenda;
    this.permiteEstoqueVirtual = ConstantsService.PermiteEstoqueVirtual;
    this.movEstVirtualNotaFiscalOrigVenda = ConstantsService.MovEstVirtualNotaFiscalOrigVenda;
    this.permiteEstoqueNegativo = ConstantsService.PermiteEstoqueNegativo;
    //final parâmetros estoque
    this.tipoNotaFiscal = tipoNotaFiscal;
    if (tipoNotaFiscal == enumTipoNotaFiscal.Nfce){
      this.subsApiEnviarNf = this.apiNotaFiscalEnvioSistemicoService.emitirNfceComDiferenteCpf(idVenda, cpfCnpj, tipoNotaFiscal, transmitirNfAutomaticamente, this.movEstContabilNotaFiscalOrigVenda,
        this.permiteEstoqueVirtual, this.movEstVirtualNotaFiscalOrigVenda, this.permiteEstoqueNegativo, true)?.subscribe(
        (retorno: NotaFiscalModel) => {
          this.notaFiscal = retorno;
          this.subsApiEnviarNf?.unsubscribe();
          if (transmitirNfAutomaticamente){ 
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal enviada, aguarde retorno'], "Sucesso");
            this.abrirModal(retorno.idExterno, this.tipoNotaFiscal);
          }else{
              swal.fire({
              title: "Sucesso",
              html: `Nota fiscal gerada. Acesse o Retaguarda para concluir e enviar para SEFAZ.`,
              showCancelButton: false,
              icon: 'success'
            })
              .then((res) => {
                
              });
            //this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal gerada. Acesse o Retaguarda para concluir e enviar para SEFAZ.'], "Sucesso");
          }
          this.showMessageError.limparMensagensAlerta();
        },
        (err) => {
          this.terminoVerificacao.emit(null!);
          this.subsApiEnviarNf?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        }
      );
    }else if (tipoNotaFiscal == enumTipoNotaFiscal.Nfe){
      this.subsApiEnviarNf = this.apiNotaFiscalEnvioSistemicoService.emitirNfeComDiferenteCpf(idVenda, cpfCnpj, tipoNotaFiscal, transmitirNfAutomaticamente, this.movEstContabilNotaFiscalOrigVenda,
        this.permiteEstoqueVirtual, this.movEstVirtualNotaFiscalOrigVenda, this.permiteEstoqueNegativo, true)?.subscribe(
        (retorno: NotaFiscalModel) => {
          this.notaFiscal = retorno;
          this.subsApiEnviarNf?.unsubscribe();
          if (transmitirNfAutomaticamente){
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal enviada, aguarde retorno'], "Sucesso");
            this.abrirModal(retorno.idExterno, this.tipoNotaFiscal);
          }else{
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal gerada. Acesse o Retaguarda para concluir e enviar para SEFAZ.'], "Sucesso");
          }
          this.showMessageError.limparMensagensAlerta();
        },
        (err) => {
          this.terminoVerificacao.emit(null!);
          this.subsApiEnviarNf?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        }
      );
    }else if (tipoNotaFiscal == enumTipoNotaFiscal.Nfse){
      this.subsApiEnviarNf = this.apiNotaFiscalEnvioSistemicoService.emitirNfseComDiferenteCpf(idVenda, cpfCnpj, tipoNotaFiscal, transmitirNfAutomaticamente, this.movEstContabilNotaFiscalOrigVenda,
        this.permiteEstoqueVirtual, this.movEstVirtualNotaFiscalOrigVenda, this.permiteEstoqueNegativo, true)?.subscribe(
        (retorno: NotaFiscalModel) => {
          this.notaFiscal = retorno;
          this.subsApiEnviarNf?.unsubscribe();
          if (transmitirNfAutomaticamente){
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal enviada, aguarde retorno'], "Sucesso");
            this.abrirModal(retorno.idExterno, this.tipoNotaFiscal);
          }else{
            this.appCustomToastService.toast(TiposMensagem.sucesso, ['Nota fiscal gerada. Acesse o Retaguarda para concluir e enviar para SEFAZ.'], "Sucesso");
          }
          this.showMessageError.limparMensagensAlerta();
        },
        (err) => {
          this.terminoVerificacao.emit(null!);
          this.subsApiEnviarNf?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        }
      );
    }
  }
}