import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppSettings } from "src/app/app-settings";
import { CommunicationManager } from "src/app/core/communication-manager";
import { FiltroExtratoConferenciaCaixa } from "src/app/models/relatorios/FiltroExtratoConferenciaCaixa";
import { FiltroRelatorioConferenciaCaixa } from "src/app/models/relatorios/FiltroRelatorioConferenciaCaixa";
import { AuthService } from "src/app/theme/shared/auth/auth.service";
import { AppMensagemService } from "src/app/theme/shared/components/app-mensagem/app-mensagem.service";

@Injectable({
    providedIn: 'root'
})
export class ApiGeraRelatorioConferenciaCaixaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Relatorio;
    private version = 'api/'
    private controller = 'geraRelatorioConferenciaCaixa';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    gerarExtrato(filtro: FiltroExtratoConferenciaCaixa, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/gerarExtrato`, filtro, exibeCarregando);
    }

    gerarRelatorio(filtro: FiltroRelatorioConferenciaCaixa, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/gerarRelatorio`, filtro, exibeCarregando);
    }

    validarParametrosRelatorio(filtro: FiltroRelatorioConferenciaCaixa, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/validarParametrosRelatorio`, filtro, exibeCarregando);
    }

    gerarExtratoSangriaSuprimento(filtro: FiltroRelatorioConferenciaCaixa, exibeCarregando: boolean) {
        return this.gc.getFileByPost(this.api, `${this.version}${this.controller}/gerarExtratoSangriaSuprimento`, filtro, exibeCarregando);
    }
}