<p-splitButton *ngIf="typeButton == 'default' && show"
  [showTransitionOptions]="'0ms'" 
  [hideTransitionOptions]="'0ms'" 
  [label]="textButton" 
  [icon]="icon" 
  (onClick)="onClick()" 
  [model]="items"
  >
</p-splitButton>
<a *ngIf="typeButton == 'icon' && show" [ngbTooltip]="ngbTooltip"  (click)="onClick()"  >
    <i [class]="'mouse-pointer text-c-blue f-16 ' + icon"></i>
</a>

<app-ui-modal #modalTipoImpressao [containerClick]="false" [dialogClass]="'modal-lg'" (close)="cliqueCancelar()" 
    [modalFrontLayer]="modalFrontLayer"
>
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-10 text-left">
                <h4 class="m-0 modal-title-header uppercase">&nbsp;<i class="fad fa-print"></i>&nbsp;{{nomeImpressao}}</h4>
            </div>
          
        </div>
    </div>
    <div class="app-modal-body">
        <div *ngIf="tabImpressaoAlternativa">
            <div class="row pb-3" >
                <div class="col-12">
                    <label class="form-label text-c-red" >{{textoAlertaMensagemImpressaoAlternativa}}</label>
                </div>
            </div>
            <div class="row pb-3" >
                <div class="col-12">
                    <label class="form-label text-c-blue" >Informe uma opção diferente para imprimir </label>
                </div>
            </div>
        </div>
        <!-- <div class="row">
            <div class="col-12 pt-2 pb-2">
                Deseja imprimir em outro formato?
            </div>
        </div>
        <div class="radio-group">
            <label *ngIf="permiteImprimirA4" class="mouse-pointer" for="impressaoA">
              <input type="radio" name="impressao" id="impressaoA" value="A" (click)="formatoImpressaoPadrao = 'A'"  [checked]="formatoImpressaoPadrao=='A'"/> Formato A4
            </label>
          
            <label *ngIf="permiteImprimirA5" class="mouse-pointer" for="impressaoB">
              <input type="radio" name="impressao" id="impressaoB" value="B" (click)="formatoImpressaoPadrao = 'B'"  [checked]="formatoImpressaoPadrao=='B'"/> Formato A5
            </label>
          
            <label *ngIf="permiteImprimirCupomFiscal" class="mouse-pointer" for="impressaoC">
              <input type="radio" name="impressao" id="impressaoC" value="C" (click)="formatoImpressaoPadrao = 'C'" [checked]="formatoImpressaoPadrao=='C'"/> Cupom Fiscal
            </label>
          
            <label *ngIf="permiteImprimirEtiqueta" class="mouse-pointer" for="impressaoD">
              <input type="radio" name="impressao" id="impressaoD" value="D" (click)="formatoImpressaoPadrao = 'D'"  [checked]="formatoImpressaoPadrao=='D'"/> Etiqueta
            </label>
          </div> -->
          
    </div>
    <div class="app-modal-footer">
        <button (click)="cliqueCancelar()" type="button" 
                class="btn btn-secondary inline me-3">
                <i class="fad fa-arrow-left"></i> Fechar
            </button>
        <button type="button" (click)="visualizarImpressao(fileData, type)" 
            class="btn btn-primary  inline me-3"><i class="fad fa-eye"></i> Visualizar</button>
        <button type="button" (click)="realizarDownload(fileData, type)" 
            class="btn btn-primary  inline  me-3"><i class="fad fa-download"></i> Download</button>
    </div>
</app-ui-modal>
<app-modal-crud-parametrizacao-envio-impressora
    (atualizar)="atualizarImpressao($event)">
</app-modal-crud-parametrizacao-envio-impressora>