import { LoginModel } from "./loginModel";


export class LoginModelInstance {
    public static get(): LoginModel {
        return {
            email: '',
            cpf: '',
            emailCpf: '',
            senha: '',
            idAplicacao: '',
            idLoja: null,
            idContaSistema: null,
            idContaSistemaTipoPlano: null
        };
    }

    public static getArray(): LoginModel[] {
        return [this.get()];
    };
}
