export enum enumUnidadeComercial {
        Grama=  'G',
        //Jogo=  'Jogo',
        Litro= 'LT',
        //MegawattHora= 'Mwhora',
        Metro= 'METRO',
        MetroCcbico= 'M3',
        MetroQuadrado= 'M2',
        //MilUnidades= '1000un',
        //Pares= 'Pares',
        //Quilate= 'Quilat',
        Quilograma= 'KG',
        Unidade= 'UN',
        Pacote= 'PC',
        Fardo= 'FARDO',
        Saco= 'SACO',
        Tonelada= 'TON',
        Caixa= 'CX'
}