import { Pipe, PipeTransform } from '@angular/core';
import { enumStatus } from 'src/app/enum/enumStatus';

@Pipe({ name: 'status' })
export class StatusPipe implements PipeTransform {
    transform(value: string): string {
       if (value == enumStatus.Ativo) return "Ativo";
       if (value == enumStatus.Inativo) return "Inativo";
       if (value == enumStatus.Desativado) return "Desativado";
       if (value == enumStatus.Pendente) return "Pendente";
       if (value == enumStatus.Erro) return "Erro";
       if (value == enumStatus.Cancelado) return "Cancelado";
       if (value == enumStatus.Entregue) return "Entregue";
       if (value == enumStatus.Todos) return "Todos";
       return "Não identificado";
    }
}
