import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NgbPopoverModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/theme/shared/shared.module';

import { ComboTabelaMeioPagamentoComponent } from './combo-tabela-meio-pagamento.component';

@NgModule({
  declarations: [ComboTabelaMeioPagamentoComponent],
  imports: [
    CommonModule,
   
    SharedModule
  ],
  exports:[
    ComboTabelaMeioPagamentoComponent, CommonModule, SharedModule,  NgbPopoverModule, NgbTooltipModule
  ]
})

export class ComboTabelaMeioPagamentoModule { }
