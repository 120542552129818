import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { FiltroPesquisaOrcamentoModel } from 'src/app/models/cliente/Orcamento/FiltroPesquisaOrcamentoModel';
import { OrcamentoModel } from 'src/app/models/cliente/Orcamento/orcamentoModel';
import { SituacaoClienteModel } from 'src/app/models/cliente/Orcamento/SituacaoClienteModel';
import { OrcamentoProdutoModel } from 'src/app/models/cliente/OrcamentoProduto/orcamentoProdutoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
  providedIn: 'root'
})
export class ApiSituacaoClienteService {

  private gc: CommunicationManager;
  private api = AppSettings.API.Pdv;
  private version = 'api/'
  private controller = 'situacaoCliente';

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
  }

  getSituacaoCliente(cpfCnpj: string, exibeCarregando: boolean | false): Observable<SituacaoClienteModel> {
    return this.gc.get(this.api, `${this.version}${this.controller}/getSituacaoCliente/${cpfCnpj}`, exibeCarregando);
  }

}