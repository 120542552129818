<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header"> <i class="fad fa-user-plus icon-header-modal"></i>&nbsp;Selecione a Tabela de Preço utilizada no PDV</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row pt-2">
          <p-table #dtSelecionarPrestador [value]="tabelaDePrecos" dataKey="idTabelaPreco" selectionMode="single"
          [scrollHeight]="heightListaSelecionarTabelaPreco" class="p-table nowrap table-hover " 
          [loading]="loading" [(selection)]="inputDados" 
          (onRowSelect)="onRowSelectItem($event)" [paginator]="false" #elmListaSelecionarPrestador>
          <ng-template pTemplate="header">
              <tr>
                  <th class="text-center "  >Tabela</th>
                  <th class="text-center " >Selecionar</th>
              </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
              <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                  <td class="text-center" >
                    <span class="text-center" >{{
                        item.nome }}</span>
                  </td>
                  <td class="table-action nowrap text-center"  >
                      <a ngbTooltip="Selecionar" class=" " (click)="cliqueSelecionarTabelaPreco(item)">
                          <i class="mouse-pointer fad fa-check text-c-blue f-16 ms-3"></i></a>

                  </td>
              </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
              <tr>
                  <td colspan="2">Não há tabela de preço cadastrada</td>
              </tr>
          </ng-template>
      </p-table>
        </div>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button" #elmBotaoCancelar
            class="btn btn-secondary inline"><i class="feather icon-arrow-left"></i> Cancelar
        </button>
    </div>
</app-ui-modal>
