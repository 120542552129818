import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-ui-modal-loading',
  templateUrl: './ui-modal-loading.component.html',
  styleUrls: ['./ui-modal-loading.component.scss'],
  encapsulation: ViewEncapsulation.None
})
//https://getbootstrap.com/docs/4.0/components/modal/
export class UiModalLoadingComponent implements OnInit {
  @Input() dialogClass: string = '';
  @Input() hideHeader = false;
  @Input() hideFooter = false;
  @Input() containerClick = true;
  public visible = false;
  public visibleAnimate = false;

  constructor() { }

  ngOnInit() {
  }

  contador: number = 0;

  public show(): void {
    this.contador +=1;
    this.visible = true;
    setTimeout(() => this.visibleAnimate = true, 100);
    if (this.contador == 1){
      document.querySelector('body')!.classList.add('modal-open');
    }
  }

  public hide(): void {
    this.contador -=1;
    this.visibleAnimate = false;
    setTimeout(() => this.visible = false, 300);
    if (this.contador == 1){
      document.querySelector('body')!.classList.remove('modal-open');
    }
  }

  public onContainerClicked(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains('modal') && this.containerClick === true) {
      this.hide();
    }
  }

}
