import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { enumTipoConsultaMeioPagamento } from 'src/app/enum/enumTipoConsultaMeioPagamento';
import { enumCanalAtendimento } from 'src/app/enum/enumCanalAtendimento';
import { TabelaMeioPagamentoModel } from 'src/app/models/cliente/TabelaMeioPagamento/tabelaMeioPagamentoModel';
import { enumIndicadorMeioPagamento } from 'src/app/enum/enumIndicadorMeioPagamento';

@Injectable({
  providedIn: 'root'
})
export class ApiTabelaMeioPagamentoService {

  private gc: CommunicationManager;
  private api = AppSettings.API.Cliente;
  private version = 'api/'
  private controller = 'tabelaMeioPagamento';

  constructor(
    private readonly http: HttpClient,
    private readonly auth: AuthService,
    private readonly mensagemService: AppMensagemService
  ) {
    this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
  }

  getAll(tipoConsultaMeioPagamento: enumTipoConsultaMeioPagamento, canalAtendimento: enumCanalAtendimento, indicadorMeioPagamento: enumIndicadorMeioPagamento, exibeCarregando: boolean) {
    return this.gc.get(this.api, `${this.version}${this.controller}/tipoConsulta/${tipoConsultaMeioPagamento}/${canalAtendimento}/${indicadorMeioPagamento}`, exibeCarregando);
  }

  get(id: string) {
    return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
  }

  put(obj: TabelaMeioPagamentoModel) {
    return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idTabelaMeioPagamento}`, obj);
  }
}