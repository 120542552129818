import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumTabelaStatusOrcamento } from 'src/app/enum/enumTabelaStatusOrcamento';
import { ComandumModel } from 'src/app/models/cliente/Comandum/comandumModel';
import { OrcamentoModel } from 'src/app/models/cliente/Orcamento/orcamentoModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiOrcamentoService } from 'src/app/services/pdv/api-orcamento-service';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-selecionar-orcamento-para-venda',
  templateUrl: './modal-selecionar-orcamento-para-venda.component.html',
  styleUrls: ['./modal-selecionar-orcamento-para-venda.component.css']
})
export class ModalSelecionarOrcamentoParaVendaComponent implements OnInit {

  loading: boolean = false;
  @ViewChild(UiModalComponent) modal;
  @Output() selecionouOrcamento: EventEmitter<VendumModel> = new EventEmitter();
  @Output() cancelouSelecao: EventEmitter<ComandumModel> = new EventEmitter();
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private router: Router,
    private appCustomToastService: AppCustomToastService,
    private showMessageError: ShowMessageError,
    private apiVendaServico: ApiVendaService,
    private apiOrcamentoService: ApiOrcamentoService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild('elmCodigoOrcamento') elmCodigoOrcamento: ElementRef  = null!;
  onFocusCodigoOrcamento(){
    setTimeout(()=>{ 
      if (this.elmCodigoOrcamento != null && this.elmCodigoOrcamento.nativeElement != null){
        this.elmCodigoOrcamento.nativeElement!.focus();
      }
    },200);    
  }

  onRowSelectItemOrcamento(item: any) {
    this.realizarPagamento(item.data);
  }

  inputDados: OrcamentoModel = null!;
  codigoOrcamento: string = '';
  keyPressCodigoOrcamento(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13) {
      let number = !isNaN(Number.parseInt(this.codigoOrcamento));
      if (this.codigoOrcamento != '' && number){
        let listaOrcamentos = this.orcamentos.filter(f => f.idOrcamento == Number.parseInt(this.codigoOrcamento));
        if (listaOrcamentos.length == 0){
          this.appCustomToastService.toast(TiposMensagem.info, ['Ordem de Serviço não encontrada'], "Atenção");
        }else{
          this.orcamentosFilter = listaOrcamentos;
          if (listaOrcamentos.length == 1){
            this.realizarPagamento(listaOrcamentos[0]!);
          }
        }
      }else{
        this.orcamentosFilter = this.orcamentos
      }
      this.codigoOrcamento = '';
    }
  }

  cliqueCancelar(){
    this.fecharModal();
    this.cancelouSelecao.emit(null!);
  }
  
  venda: VendumModel = null!;
  abrirModal(venda: VendumModel) {
    this.venda = venda;
    this.modal.show();
    this.listarOrcamentoByStatus();
    this.onFocusCodigoOrcamento();
  }

  fecharModal(){
    this.fechouModal.emit(null!);
    this.modal.hide();
  }

  mensagemPesquisa: string = '';
  
  orcamentos: OrcamentoModel[] = [];
  orcamentosFilter: OrcamentoModel[] = [];
  subsListarOrcamentoByStatus: Subscription = null!;
  listarOrcamentoByStatus(){
    this.loading = true;
    this.subsListarOrcamentoByStatus = this.apiOrcamentoService.getAllByStatus(enumTabelaStatusOrcamento.EmPreenchimento, false)
      .subscribe( (retorno: OrcamentoModel[]) => {
        this.loading = false;
        this.subsListarOrcamentoByStatus?.unsubscribe();
        if (retorno != null){
          this.orcamentos = retorno;
        }else{
          this.orcamentos = [];
        }
        this.orcamentosFilter = this.orcamentos;
      },
        (err) => {
          this.loading = false;
          this.subsListarOrcamentoByStatus?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  subsImportarOrcamentoParaPagamento: Subscription = null!;
  realizarPagamento(orcamento: OrcamentoModel | null){
    let idVenda: number = 0;
    if (this.venda != null){
      idVenda = this.venda.idVenda;
    }
    this.subsImportarOrcamentoParaPagamento = this.apiVendaServico.importarOrcamentoParaPagamento(orcamento!.idOrcamento, idVenda, ConstantsService.IdCaixa, ConstantsService.IdPdv, true)
      .subscribe((retorno: VendumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Ordem de Serviço Importada'], "Sucesso");
          this.selecionouOrcamento.emit(retorno);
          this.fecharModal();
        }
        this.subsImportarOrcamentoParaPagamento?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsImportarOrcamentoParaPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });  
  }
}
