import { enumSimNao } from "src/app/enum/enumSimNao";
import { enumStatus } from "src/app/enum/enumStatus";
import { enumTipoProduto } from "src/app/enum/enumTipoProduto";
import { ProdutoModel } from "./produtoModel";

export class ProdutoModelInstance {
    public static get(): ProdutoModel {
        return {
            produtoPesadoEmBalanca: enumSimNao.Nao,
            tipoEanBalanca: '',
            idProdutoUnico: 0,
            idMarca: 0,
            idTabelaClassificacaoProduto: 0,
            idItemEstruturaMercadologica: 0,
            idProdutoAnterior: '',
            tipoProduto: enumTipoProduto.Produto,
            codigoProdutoBalanca: null,
            sku: '',
            nome: '',
            descricao: '',
            descricaoCompleta: '',
            movimentaEstoque: enumSimNao.Sim,
            linkExterno: '',
            linkVideo: '',
            unidadeMedidaComercial: '',
            possuiSeguro: '',
            gtin: '',
            gtinTributavel: '',
            status: enumStatus.Todos,
            idTabelaLocalizacaoProduto: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            percComissaoPrestadorServico: 0,
            percComissaoProfissional: 0,
            percMaxDesconto: 0,
            obraArt: '',
            obraCei: '',
            obraCodigo: '',
            dataRegistro: null!,
            idItemEstruturaMercadologicaNavigation: null!,
            idMarcaNavigation: null!,
            idTabelaClassificacaoProdutoNavigation: null!,
            produtoArmazenamento: null!,
            produtoDimensao: null!,
            produtoTributacaos: null!,
            compraProdutos: null!,
            cotacaoProdutos: null!,
            estoques: null!,
            listaProdutos: null!,
            precos: null!,
            precoProdutoConcorrentes: null!,
            produtoAtributos: null!,
            produtoImagems: null!,
            produtoProducaos: null!,
            produtoVariacaos: null!,
            vendaProdutos: null!,
            condicionalProdutos: null!,
            orcamentoProdutos: null!,
            vendaEntregaProdutos: null!,
            tabelaNves: null!,
            produtoAnexos: null!,
            contratoProdutoServicos: null!,
            ordemServicoProdutoServicos: null!,
            produtoCurvaAbcs: null!,
            idItemEstruturaMercadologicaNivel1: null!,
            idItemEstruturaMercadologicaNivel2: null,
            idItemEstruturaMercadologicaNivel3: null,
            idItemEstruturaMercadologicaNivel4: null,
            pessoaFisicaJuridicaProdutos: null!,
            idTabelaLocalizacaoProdutoNavigation: null!,
            avisoDeRecompra: enumSimNao.Nao,
            controlarEstoqueMinimo:  enumSimNao.Nao,
            quantidadeDiasAvisoRecompra: 0,
            solicitaLotePdv:  enumSimNao.Nao,
            validarDataVencimento:  enumSimNao.Nao,
            produtoNaoVendidoInsumo: enumSimNao.Nao,
            produtoPrecoVariados: null!,
            tipoPreco: '',
            pedidoVendaProdutos: null!,
            quantidadeInicialContabil: 0,
            quantidadeInicialVirtual: 0,
            quantidadeMinimaContabil: 0,
            quantidadeMinimaVirtual: 0,
            habilitaCalculoUnidadeMedida: enumSimNao.Nao,
            tipoCalculoUnidadeMedida: '',
            pesoTaraProdutoPesado: 0,
            qtdeDiasValidadeProduto: 1,
            veiculoModeloProdutos: [],
            disponivelNoCalendario: enumSimNao.Nao,
            tempoPrevEmMinuto: 0
        };
    }

    public static getArray(): ProdutoModel[] {
        return [this.get()];
    };
}
