import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IOption } from 'ng-select';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumModoTela } from 'src/app/enum/enumModoTela';
import { enumStatusMovimentacaoCheque } from 'src/app/enum/enumStatusMovimentacaoCheque';
import { ChequeModel } from 'src/app/models/cliente/Cheque/ChequeModel';
import { ChequeModelInstance } from 'src/app/models/cliente/Cheque/ChequeModelInstance';
import { ApiChequeService } from 'src/app/services/financeiro/api-cheque.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-crud-cheque',
  templateUrl: './modal-crud-cheque.component.html',
  styleUrls: ['./modal-crud-cheque.component.css']
})
export class ModalCrudChequeComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  inputDados: ChequeModel = null!;
  @Input() modoTela: enumModoTela = enumModoTela.Cadastrar;
  modoTelaAlterar: enumModoTela = enumModoTela.Alterar;
  modoTelaVisualizar: enumModoTela = enumModoTela.Visualizar;
  @Output() salvarParcelaCheque: EventEmitter<ChequeModel> = new EventEmitter();
  origemTelaCheque: boolean = true;
  public maskTeleAreaCel = AppSettings.MASK.maskTeleAreaCel;
  salvarAlteracoes: any;
  subsRegister: Subscription | null = null;
  subsUpdate: Subscription | null = null;
  idContaBancaria: number | null = null;
  enumStatusCompensado: enumStatusMovimentacaoCheque = enumStatusMovimentacaoCheque.Compensado;

  constructor(private apiChequeService: ApiChequeService,
    private showMessageError: ShowMessageError,
    private translateService: TranslateService,
    private config: PrimeNGConfig,
  ) {
    this.translateService.use("pt");
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  ngOnInit(): void {
    this.setStartObject();
  }

  ngOnDestroy(): void {
    this.subsRegister?.unsubscribe();
    this.subsUpdate?.unsubscribe();
  }

  onFoucus(event) {
    setTimeout(() => {
      event.target.select();
    }, 200);
  }

  public abrirModal(cheque: ChequeModel | null, salvarAlteracoes: boolean) {
    this.salvarAlteracoes = salvarAlteracoes;
    if (cheque != null) {//alteração
      if(cheque.statusMovimentacao == this.enumStatusCompensado && salvarAlteracoes){
        this.modoTela = enumModoTela.Visualizar;
      }else{
        this.modoTela = enumModoTela.Alterar;
      }
      this.inputDados = cheque;
      if(this.inputDados.dataVencimento != null) this.inputDados.dataVencimento = new Date(this.inputDados.dataVencimento);
    }
    else
    {//cadastrar
      this.modoTela = enumModoTela.Cadastrar;
      this.setStartObject();
      if(cheque != null){
        this.inputDados = cheque!;
        if(this.inputDados.dataVencimento != null) this.inputDados.dataVencimento = new Date(this.inputDados.dataVencimento);
      }
    }
    this.modal.show();
  }

  cliqueCancelar(){
    this.showMessageError.limparMensagensAlerta();   
    this.idContaBancaria = null;
    this.modal.hide();
  }

  voltar(){    
    this.showMessageError.limparMensagensAlerta();   
    this.idContaBancaria = null;
    this.modal.hide();
  }

  setStartObject(){
    this.inputDados = ChequeModelInstance.get();
  }

  statusMovimentacaoCheque: Array<IOption> = [
    {
      label: 'Selecione',
      value: ''
    },{
      label: 'Recebido',
      value: enumStatusMovimentacaoCheque.Recebido
    },{
      label: 'Depositado',
      value: enumStatusMovimentacaoCheque.Depositado
    },{
      label: 'Compensado',
      value: enumStatusMovimentacaoCheque.Compensado
    },{
      label: 'Devolvido',
      value: enumStatusMovimentacaoCheque.Devolvido
    },{
      label: 'Cancelado',
      value: enumStatusMovimentacaoCheque.Cancelado
    },{
      label: 'Endosso',
      value: enumStatusMovimentacaoCheque.Endosso
    },{
      label: 'Baixado',
      value: enumStatusMovimentacaoCheque.Baixado
    },{
      label: 'Consulta',
      value: enumStatusMovimentacaoCheque.Consulta
    }
  ];

  salvarCheque(){
    if (!this.salvarAlteracoes){
      this.finalizarSalvarCheque();
      return;
    }
    if(this.modoTela == enumModoTela.Cadastrar){
      this.cadastrar();
    }else{
      this.atualizar();
    }
  }

  finalizarSalvarCheque(){
    this.showMessageError.limparMensagensAlerta();   
    this.modal.hide();
    this.idContaBancaria = null;
    this.salvarParcelaCheque.emit(this.inputDados);
  }

  cadastrar(){
    this.subsRegister = this.apiChequeService.cadastrarCheque(this.inputDados, true).subscribe(
      (retorno: ChequeModel) => {
        this.subsRegister?.unsubscribe();
        if(retorno != null){          
          this.inputDados = retorno;
          Swal.fire({
            title: "Sucesso",
            text: "Cheque cadastrado com sucesso!",
            confirmButtonText: `Ok`,
            cancelButtonText: `Não`,
            showCancelButton: false,
            focusConfirm: true,
            icon: 'success'
          })
          .then((result) => {
            this.cliqueCancelar();
          });
        }
      },
      (err) => {
        this.subsRegister?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  atualizar(){
    this.subsUpdate = this.apiChequeService.alterarCheque(this.inputDados, this.idContaBancaria, true).subscribe(
      (retorno: ChequeModel) => {
        this.subsUpdate?.unsubscribe();
        if(retorno != null){
          Swal.fire({
            title: "Sucesso",
            text: "Cheque alterado com sucesso!",
            confirmButtonText: `Ok`,
            showCancelButton: false,
            focusConfirm: true,
            icon: 'success'
          })
          .then((result) => {
            this.cliqueCancelar();
          });
        }
      },
      (err) => {
        this.subsUpdate?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
}