<app-ui-modal #modal [containerClick]="false" [dialogClass]="'modal-lg'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 text-bold ">&nbsp;<i class="far fa-hand-holding-usd"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="inputDados != null" >
        <div class="row">
            <div class="col-12" *ngIf="tipoAcao == sangria">
                <label class="form-label" >Preencha os campos abaixo para realizar a Sangria</label>
            </div>
            <div class="col-12" *ngIf="tipoAcao == suprimento">
                <label class="form-label" >Preencha os campos abaixo para realizar o Suprimento</label>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-6 col-sm-12 ">
                <div class="form-group text-start">
                    <label class="form-label-bold" for="idTabelaMeioPagamento">MEIO DE PAGAMENTO</label>
                    <app-combo-tabela-meio-pagamento idCampo="idTabelaMeioPagamento" #comboTabelaMeioPagamentoComponent [disabled]="false"
                        (change)="changeTabelaMeioPagamento($event)" [(ngModel)]="inputDados.idTabelaMeioPagamento" [label]="''" [preCarregar]="true"
                        [tipoConsultaMeioPagamento]="tipoConsultaMeioPagamento" >
                    </app-combo-tabela-meio-pagamento>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 ">
                <div class="form-group ">
                    <label class="form-label-bold" for="idTabelaCentroCusto">CENTRO DE CUSTO</label>
                    <app-combo-tabela-centro-custo  #comboTabelaCentroCustoComponent               
                        [(ngModel)]="inputDados.idTabelaCentroCusto"  [label]="''" [preCarregar]="true" ></app-combo-tabela-centro-custo>                    
                </div>                
            </div>
        </div>
        <div class="row pt-3">
            <div class="col-12 col-md-2 p-t-10" *ngIf="inputDados.idTabelaMeioPagamento == enumMeioPagCheque">
                <div class="form-check">
                    <input type="checkbox" class="form-check-input mouse-pointer"
                        (change)="onChangeQuitado($event)" [checked]="quitado == true"
                        id="quitado">
                    <label class="form-check-label mouse-pointer" for="quitado">Quitado&nbsp;<i class="fas fa-info-circle"
                        ngbTooltip="Se for quitado, não será realizado a movimentação de conta bancária, pois entendemos que já foi realizado a movimentação anteriormente."></i></label>
                </div>
            </div>

            <div class="col-xl-3 col-md-3 col-sm-12 p-t-5">
                <div class="form-group text-left">
                    <label class="form-label-bold" for="saldoAtual">SALDO ATUAL</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="saldoAtual" suffix="" prefix="R$ " [disabled]="true" 
                    currency="BRL" locale="pt-BR" inputId="saldoAtual" mode="decimal" [minFractionDigits]="2"
                    [maxFractionDigits]="2">
                </p-inputNumber>
                </div>
            </div>
            <div class="col-12 col-md-4 p-t-5">
                <div class="form-group ">
                    <label class="form-label-bold" for="novoSaldoAtual" *ngIf="tipoAcao == suprimento">SALDO APÓS SUPRIMENTO</label>
                    <label class="form-label-bold" for="novoSaldoAtual" *ngIf="tipoAcao == sangria">SALDO APÓS SANGRIA</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="novoSaldoAtual" suffix="" prefix="R$ " [disabled]="true"
                    currency="BRL" locale="pt-BR" inputId="novoSaldoAtual" mode="decimal" [minFractionDigits]="2"
                    [maxFractionDigits]="2">
                </p-inputNumber>
                </div>
            </div>
            <div class="col-12 col-md-3 p-t-5">
                <div class="form-group ">
                    <label class="form-label-bold" for="valor">VALOR</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valor" suffix="" prefix="R$ " #valorSuprimento (keydown)="keypress($event)"
                    currency="BRL" locale="pt-BR" inputId="valor" mode="decimal" [minFractionDigits]="2" (ngModelChange)="ngModelChangeValor($event)"
                    [maxFractionDigits]="2">
                </p-inputNumber>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label class="form-label-bold" for="observacao">OBSERVAÇÃO <i class="fas fa-info-circle"
                        ngbTooltip="Informa uma observação caso necessário"></i></label>
                   
                    <textarea maxlength="30" class="form-control input-md" id="observacao" name="observacao" type="text" style="height: 30px"
                        placeholder="Digite a observação" [(ngModel)]="inputDados.observacao"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button [disabled]="bloquearCampos()" (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class=""></i> CANCELAR
        </button>&nbsp;
        <button *ngIf="!habilitaBotaoImpressao" type="button" appDebounceClick (debounceClick)="realizarSangriaSuprimento()" 
            class="btn btn-primary inline"><i class="far fa-check"></i> OK
        </button>
        <div *ngIf="habilitaBotaoImpressao" class="inline">           
            <app-modal-impressao #modalImpressaoComponent class="text-start"
                (onAbriuModalConfiguracao)="onAbriuModalConfiguracao($event)"
                (onFechouModalAlteracao)="onFechouModalAlteracao($event)"
                [modalFrontLayer]="true"
                [codigoDocumento]="'29'"
                [textButton]="'Imprimir'"
                [typeButton]="'default'"
                [icon]="'fad fa-print ps-2'"
                (onImprimirFormatoCupomFiscal)="imprimir(Cupom80mm)"
                >
            </app-modal-impressao>
        </div>
    </div>
</app-ui-modal>