import { Component, OnInit, ViewChild, Input } from '@angular/core';
//import { Dialog } from 'primeng/dialog';
//import {DialogModule} from 'primeng/dialog';
import { UiModalLoadingComponent } from '../../modal/ui-modal-loading/ui-modal-loading.component';

@Component({
  selector: 'app-modal-loading',
  templateUrl: './app-modal-loading.component.html',
  styleUrls: ['./app-modal-loading.component.scss']
})
export class AppModalLoadingComponent implements OnInit {

  @ViewChild('modalLoading') modalLoading!: UiModalLoadingComponent;

  @Input() closable: boolean = false;

  mensagem = 'Por favor, aguarde enquanto sua solicitação é executada!';

  constructor() { }

  ngOnInit() {
  }

  exibirModalLoading(mensagemModal?: string) {
    if (mensagemModal !== '' && mensagemModal != null) {
      this.mensagem = mensagemModal;
    }
    this.modalLoading.show();
    
  }

  esconderModalLoading() {
     this.modalLoading.hide();
  }
}
