import { ParametrizacaoEnvioImpressoraModel } from "./ParametrizacaoEnvioImpressoraModel";

export class ParametrizacaoEnvioImpressoraModellnstance {
    public static get(): ParametrizacaoEnvioImpressoraModel {
        return {
            codigoDocumentoImpresso: '',
            idPdv: null,
            tipoImpressao: '',
            quantidadeImpressao: 1,
            url: '',
            nomeImpressora: '',
            formato: '',
            documentoImpresso: '',
            idPdvNavigation: null!,
            idParametrizacaoEnvioImpressora: 0,
            sempreImprimirNestaConfiguracao: ''
        };
    }
}