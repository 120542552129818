import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumTabelaStatusOrdemServico } from 'src/app/enum/enumTabelaStatusOrdemServico';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { ComandumModel } from 'src/app/models/cliente/Comandum/comandumModel';
import { OrdemServicoModel } from 'src/app/models/cliente/OrdemServico/ordemServicoModel';
import { ParametrosConsultaOSModel } from 'src/app/models/cliente/OrdemServico/ParametrosConsultaOSModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiOrdemServicoService } from 'src/app/services/financeiro/api-ordem-servico.service';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-modal-selecionar-ordem-servico-para-venda',
  templateUrl: './modal-selecionar-ordem-servico-para-venda.component.html',
  styleUrls: ['./modal-selecionar-ordem-servico-para-venda.component.css']
})
export class ModalSelecionarOrdemServicoParaVendaComponent implements OnInit {

  loading: boolean = false;
  @ViewChild(UiModalComponent) modal;
  @Output() selecionouOrdemServico: EventEmitter<VendumModel> = new EventEmitter();
  @Output() cancelouSelecao: EventEmitter<ComandumModel> = new EventEmitter();
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();

  inputDados: OrdemServicoModel = null!;
  codigoOrdemServico: string = '';
  ordensServicos: ParametrosConsultaOSModel[] = [];
  ordensServicosFilter: ParametrosConsultaOSModel[] = [];
  subsListarOrdemServicoByStatus: Subscription = null!;
  subsImportarOrdemServicoParaPagamento: Subscription = null!;

  tipoProduto: enumTipoProduto = enumTipoProduto.Produto;
  tipoServico: enumTipoProduto = enumTipoProduto.Servico;
  tipoProdutoEServico: enumTipoProduto = enumTipoProduto.Todos;
  
  constructor(
    private appCustomToastService: AppCustomToastService,
    private showMessageError: ShowMessageError,
    private apiVendaServico: ApiVendaService,
    private apiOrdemServicoService: ApiOrdemServicoService
  ) { }

  ngOnInit(): void {}

  @ViewChild('elmCodigoOrdemServico') elmCodigoOrdemServico: ElementRef  = null!;
  onFocusCodigoOrdemServico(){
    setTimeout(()=>{ 
      if (this.elmCodigoOrdemServico != null && this.elmCodigoOrdemServico.nativeElement != null){
        this.elmCodigoOrdemServico.nativeElement!.focus();
      }
    },200);    
  }

  // onRowSelectItemOrdemServico(item: any) {
  //   this.realizarPagamento(item.data);
  // }
  
  keyPressCodigoOrdemServico(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13) {
      let number = !isNaN(Number.parseInt(this.codigoOrdemServico));
      if (this.codigoOrdemServico != '' && number){
        let ordensServicos = this.ordensServicos.filter(f => f.idOrdemServico == Number.parseInt(this.codigoOrdemServico));
        if (ordensServicos.length == 0){
          this.appCustomToastService.toast(TiposMensagem.info, ['Ordem de Serviço não encontrada'], "Atenção");
        }else{
          //this.ordensServicosFilter = ordensServicos;
          
          if (this.idsOrdensServicoSelecionado.findIndex(f => f == ordensServicos[0].idOrdemServico) == -1){
            this.idsOrdensServicoSelecionado.push(ordensServicos[0].idOrdemServico!);
          }
          // if (ordensServicos.length == 1){
          //   this.realizarPagamento(ordensServicos[0].idOrdemServico!);
          //   // this.realizarPagamento(ordensServicos[0]!);
          // }
        }
      }else{
        this.ordensServicosFilter = this.ordensServicos
      }
      this.codigoOrdemServico = '';
    }
  }

  cliqueCancelar(){
    this.fecharModal();
    this.cancelouSelecao.emit(null!);
  }
  
  venda: VendumModel = null!;
  abrirModal(venda: VendumModel) {
    this.idsOrdensServicoSelecionado = [];
    this.venda = venda;
    this.modal.show();
    this.listarOrdemServicoByStatus();
    this.onFocusCodigoOrdemServico();
  }

  fecharModal(){
    this.fechouModal.emit(null!);
    this.modal.hide();
  }

  listarOrdemServicoByStatus(){
    this.loading = true;
    this.subsListarOrdemServicoByStatus = this.apiOrdemServicoService.getAllOSPendentePagamento(enumTabelaStatusOrdemServico.PendentePagamento, false)
      .subscribe( (retorno: ParametrosConsultaOSModel[]) => {
        this.loading = false;
        this.subsListarOrdemServicoByStatus?.unsubscribe();
        if (retorno != null){
          this.ordensServicos = retorno;
        }else{
          this.ordensServicos = [];
        }
        this.ordensServicosFilter = this.ordensServicos;
      },
        (err) => {
          this.loading = false;
          this.subsListarOrdemServicoByStatus?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }
  changeSelecionarTodasOrdensDeServico(event){
    let selecionado = event.target.checked;
    if (selecionado && this.ordensServicosFilter.length >= 1){
      this.ordensServicosFilter.forEach(f =>{
        this.idsOrdensServicoSelecionado.push(f.idOrdemServico!);
      })
    }else{
      this.idsOrdensServicoSelecionado = [];
    }
  }
  onChangeSelecionarOrdemServico(event: any, idOrdemServico: number){
    let selecionado = event.target.checked;
    if (selecionado && this.idsOrdensServicoSelecionado.indexOf(idOrdemServico) == -1){
      this.idsOrdensServicoSelecionado.push(idOrdemServico);
    }else if(this.idsOrdensServicoSelecionado.indexOf(idOrdemServico) > -1){
      let index = this.idsOrdensServicoSelecionado.indexOf(idOrdemServico);
      this.idsOrdensServicoSelecionado.splice(index, 1);
    }
  }
  idsOrdensServicoSelecionado: number[] = [];
  getOrdemServicoSelecionado(idOrdemServico: number): boolean{
    if (this.idsOrdensServicoSelecionado.indexOf(idOrdemServico) > -1) return true;
    return false;
  }
  situacaoPendentePagamento: enumTabelaStatusOrdemServico = enumTabelaStatusOrdemServico.PendentePagamento;
  realizarPagamento(tipoVenda: enumTipoProduto){
    if (this.idsOrdensServicoSelecionado.length == 0){
      Swal.fire({
        title: "Atenção",
        html: "Selecione uma Ordem de Serviço antes de continuar!",
        showConfirmButton: true,
        confirmButtonText: "Ok",
        icon: 'warning'
      })
        .then((res) => {
        });
      return;
    }
    if(tipoVenda == this.tipoProduto || tipoVenda == this.tipoServico){
      Swal.fire({
        title: "Informação",
        html: "Caso esta OS possua forma de pagamento cadastrada não será possível vincular à venda, pois a forma permitida para isso é através da importação <b>venda única</b>. Deseja continuar?",
        showCancelButton: true,
        cancelButtonText: "Não",
        showConfirmButton: true,
        confirmButtonText: "Sim",
        icon: 'info'
      })
        .then((res) => {
          if (res.isConfirmed) {
            this.importar(this.idsOrdensServicoSelecionado, tipoVenda);
          }
          return;          
        });
    }else{
      this.importar(this.idsOrdensServicoSelecionado, tipoVenda);
    }      
  }

  importar(idsOrdensServicos: number[], tipoVenda: enumTipoProduto){
    let idVenda: number = 0;
    if (this.venda != null){
      idVenda = this.venda.idVenda;
    }

    this.subsImportarOrdemServicoParaPagamento = this.apiVendaServico.importarOrdemServicoParaPagamento(idsOrdensServicos!, idVenda, ConstantsService.IdCaixa, ConstantsService.IdPdv, tipoVenda, true)
      .subscribe((retorno: VendumModel) => {
        this.loading = false;
        this.subsImportarOrdemServicoParaPagamento?.unsubscribe();
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Ordem de Serviço Importada'], "Sucesso");
          this.selecionouOrdemServico.emit(retorno);
          this.fecharModal();
        }
      },
      (err) => {
        this.loading = false;
        this.subsImportarOrdemServicoParaPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
}