import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class Convert{
    constructor(
      ) {}

    convertToDate(date: string){
        if (date)
            return date.substr(3,2) + "/" + date.substr(0,2) + "/" + date.substr(6,4);
        return null;
    }
    formatCurrency(event)
    {
        let value = event.target.value;
        value = value.replace(/[^0-9,']/g, "");
        value = value.replace(',','.');
        return new Intl.NumberFormat('pr-BR',{style: 'currency', currency:'BRL'}).format(value);
    }
    
    formatCurrencyFromBD(value)
    {
        return new Intl.NumberFormat('pr-BR',{style: 'currency', currency:'BRL'}).format(value);
    }
    returnOnlyValue(event)
    {
        let value = event.target.value;
        value = value.replace(/[^0-9,']/g, "");
        return parseFloat(value.replace(',','.'));
    }
}