<app-ui-modal #modal [containerClick]="false" (close)="fecharModal()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-images icon-header-modal"></i>&nbsp;Visualizar Imagens</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row" *ngIf="images.length == 0">
            <div class="col-12">
                <h6>Não há imagens cadastrada para este item!</h6>
            </div>
        </div>
        <div class="row " *ngIf="images != null && images.length > 0">
    
            <p-galleria [value]="images" [(visible)]="displayCustom" [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions" [containerStyle]="{'max-width': '850px'}" [numVisible]="7"
              [circular]="true" [fullScreen]="true" [showItemNavigators]="true" [showThumbnails]="false" [baseZIndex]="100000">
                <ng-template pTemplate="item" let-item>
                    <img [src]="item.previewImageSrc  | secure | async" style="width: 100%; display: block;"/>
                </ng-template>
                <ng-template pTemplate="thumbnail" let-item>
                    <div class="grid grid-nogutter justify-content-center">
                        <img [src]="item.thumbnailImageSrc  | secure | async" style="display: block;"/>
                    </div>
                </ng-template>
            </p-galleria>
            <div *ngIf="images" class="row" >
              <div *ngFor="let image of images; let index = index;" class="col-2" key="index">
                  
                  <img [src]="image.previewImageSrc  | secure | async" [alt]="image.titulo" style="cursor: pointer;max-height: 160px;" (click)="imageClick(index)"/><br>
                  <hr>
                  <span ><b>{{image.titulo}}</b></span><br>
                  <span>{{image.descricao}}</span>
              </div>
            </div>
          
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" (click)="fecharModal()" 
            class="btn btn-secondary inline"><i class="fad fa-window-close"></i> Fechar</button>&nbsp;
    </div>
</app-ui-modal>
