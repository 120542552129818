import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumStatus } from 'src/app/enum/enumStatus';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiTabelaPrecoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'tabelaPreco';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAllByStatus(status: enumStatus, exibeCarregando: boolean):Observable<TabelaPrecoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/status/${status}`, exibeCarregando);
    }

    getAll() {
        return this.gc.get(this.api, `${this.version}${this.controller}`, true);
    }

    getAllByFilter(status: string, exibeCarregando: boolean): Observable<TabelaPrecoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/status/${status}`, exibeCarregando);
    }

    get(id: number): Observable<TabelaPrecoModel>{
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`)
    }

    post(obj: TabelaPrecoModel): Observable<TabelaPrecoModel>{
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj);
    }

    put(obj: TabelaPrecoModel): Observable<TabelaPrecoModel>{
        return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idTabelaPreco}`, obj);
    }
}