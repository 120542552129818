import { NotaFiscalTransReboqueModel } from "./notaFiscalTransReboqueModel";



export class NotaFiscalTransReboqueModelInstance {
    public static get(): NotaFiscalTransReboqueModel {
        return {
            idNotaFiscalTransReboque: 0,
            idNotaFiscal: 0,
            placaVeiculoReboque: '',
            siflaUfReboque: '',
            registroNacionalCargaReboque: '',
            identificacaoVagaoReboque: '',
            identificacaoBalsaReboque: '',
            idNotaFiscalNavigation: null!
        };
    }
    public static getArray(): NotaFiscalTransReboqueModel[] {
        return [this.get()];
    }
}
