<app-ui-modal #modalCnpj [containerClick]="false" [dialogClass]="'modal-lg'" (close)="cliqueCancelar()">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left ">
                <h4 class="m-0 text-bold ">&nbsp;<i class="far fa-shopping-cart"></i>&nbsp;{{tituloModal}}</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body modal-detalhe-produto" *ngIf="inputDados != null">
        <div class="row">
            <div class="col-12">
                <h4 class="sub-titulo">
                    {{inputDados.cpfCnpj | cpfCnpj}} - {{inputDados.razaoSocialNome}}
                </h4>
            </div>
        </div>
       
        <div class="row ">
            <hr>
           
        </div>
        
        <div class="row">
            <div class="col-4 ">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" disabled="true" placeholder="Limite de Crédito"  
                        [(ngModel)]="vLimiteCredito">
                    <span class="input-group-text" id="basic-addon2">
                        <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirLimiteCredito" (click)="limiteCredito()"></i>
                        <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirLimiteCredito" (click)="limiteCredito()"></i>
                    </span>
              </div>
            </div>
            <div class="col-4 ">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" disabled="true" placeholder="Saldo Devedor"  
                        [(ngModel)]="vSaldoDevedor">
                    <span class="input-group-text" id="basic-addon2">
                        <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirSaldoDevedor" (click)="saldoDevedor()"></i>
                        <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirSaldoDevedor" (click)="saldoDevedor()"></i>
                    </span>
              </div>
            </div>
            <div class="col-4 ">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" disabled="true" placeholder="Crédito Restante"  
                        [(ngModel)]="vCreditoRestante">
                    <span class="input-group-text" id="basic-addon2">
                        <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirCreditoRestante" (click)="creditoRestante()"></i>
                        <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirCreditoRestante" (click)="creditoRestante()"></i>
                    </span>
              </div>
            </div>
            
        </div>
        <div class="row">
            <div class="col-12" *ngFor="let item of situacaoFinanceiraTemp; let i = index">
                <div class="input-group mb-3">
                    <input type="text" class="form-control" disabled="true" placeholder="Situação"  
                        [value]="item">
                    <span class="input-group-text" id="basic-addon2">
                        <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirSituacao" (click)="situacao()"></i>
                        <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirSituacao" (click)="situacao()"></i>
                    </span>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" >
                <div class="input-group mb-3">
                    <input type="text" class="form-control" disabled="true" placeholder="Classificação"  
                        [(ngModel)]="vClassificacao">
                    <span class="input-group-text" id="basic-addon2">
                        <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirClassificacao" (click)="classificacao()"></i>
                        <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirClassificacao" (click)="classificacao()"></i>
                    </span>
              </div>
            </div>
        </div>
    </div>
    
    <div class="app-modal-footer">
        <button [disabled]="false" (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary m-r-5 inline"><i class="fad fa-window-close"></i> Fechar
        </button>
       
    </div>
</app-ui-modal>