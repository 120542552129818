import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { enumStatus } from 'src/app/enum/enumStatus';
import { enumTipoCancelamento } from 'src/app/enum/enumTipoCancelamento';
import { MotivoCancelamentoModel } from 'src/app/models/cliente/MotivoCancelamento/motivocancelamentoModel';
import { ApiMotivoCancelamentoService } from 'src/app/services/cliente/api-parametrizacao-motivo-cancelamento.service';

@Component({
  selector: 'app-combo-motivo-cancelamento',
  templateUrl: './combo-motivo-cancelamento.component.html',
  styleUrls: ['./combo-motivo-cancelamento.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboMotivoCancelamentoComponent),
    multi: true
  }]
})
export class ComboMotivoCancelamentoComponent implements OnInit, OnDestroy, ControlValueAccessor {

  subsListaService: Subscription | null = null;
  subService: Subscription | null = null;

  @Input() disabled: boolean = false;
  @Input() label: string = "Motivo de Cancelamento";
  @Output() change: EventEmitter<any> = new EventEmitter();
  private val: string = '' // this is the updated value that the class accesses
  @Input() tipoCancelamento: enumTipoCancelamento = enumTipoCancelamento.Todos;
  @Input() itens: Array<IOption> = [];
  @Input() idCampo: string = 'idMotivoCancelamento';

  constructor(private motivocancelamentoService: ApiMotivoCancelamentoService) { }

  ngOnInit(): void {
    this.getLista(null!);
  }

  changeValue() {
    this.change.emit(parseInt(this.value));
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }
  loading: boolean = false;
  getLista(item: MotivoCancelamentoModel) {
    this.loading = true;
    //this.subsListaService = this.motivocancelamentoService.getAll().subscribe(
    this.subsListaService = this.motivocancelamentoService.getAllByTipoCancelamento(this.tipoCancelamento).subscribe(
      (retorno: Array<MotivoCancelamentoModel>) => {
        this.itens = retorno.map((objeto) => {
          return {
            value: objeto.idMotivoCancelamento.toString(),
            label: `${objeto.idMotivoCancelamento?.toString()} - ${objeto.motivo}`,
            checked: false
          }
        })
        if (item  != null){
          this.value = item.idMotivoCancelamento;
        }
        this.loading = false;
      });
  }
  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }
}
