import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { enumSituacaoComanda } from 'src/app/enum/enumSituacaoComanda';
import { enumTipoComanda } from 'src/app/enum/enumTipoComanda';
import { ComandumModel } from 'src/app/models/cliente/Comandum/comandumModel';
import { ParametrizacaoComandumModel } from 'src/app/models/cliente/ParametrizacaoComandum/parametrizacaoComandumModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiParametrizacaoComandaService } from 'src/app/services/cliente/api-parametrizacao-comanda.service';
import { ApiComandaService } from 'src/app/services/pdv/api-comanda.service';
import { ModalSelecionarComandaComponent } from 'src/app/shared/business-component/modal-selecionar-comanda/modal-selecionar-comanda.component';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';

@Component({
  selector: 'app-modal-selecionar-comanda-para-venda',
  templateUrl: './modal-selecionar-comanda-para-venda.component.html',
  styleUrls: ['./modal-selecionar-comanda-para-venda.component.css']
})
export class ModalSelecionarComandaParaVendaComponent implements OnInit {

  @ViewChild(ModalSelecionarComandaComponent, { static: false }) modalSelecionarComandaComponent!: ModalSelecionarComandaComponent;
  loading: boolean = false;
  naoExisteParametrizacaoComanda: boolean = true;
  @ViewChild(UiModalComponent) modal;
  @Output() selecionouComanda: EventEmitter<VendumModel> = new EventEmitter();
  @Output() cancelouSelecao: EventEmitter<ComandumModel> = new EventEmitter();
  
  constructor(
    private router: Router,
    private appCustomToastService: AppCustomToastService,
    private apiParametrizacaoComandaService: ApiParametrizacaoComandaService,
    private showMessageError: ShowMessageError,
    private apiComandaService: ApiComandaService
  ) { }

  ngOnInit(): void {
  }

  @ViewChild('elmCodigoComanda') elmCodigoComanda: ElementRef  = null!;
  onFocusCodigoComanda(){
    setTimeout(()=>{ 
      if (this.elmCodigoComanda != null && this.elmCodigoComanda.nativeElement != null){
        this.elmCodigoComanda.nativeElement!.focus();
      }
    },200);    
  }

  keyPressCodigoComanda(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13) {
      let number = !isNaN(Number.parseInt(this.codigoComanda));
      if (this.codigoComanda != '' && number){
        let comandas = this.comandas.filter(f => f.idComanda == Number.parseInt(this.codigoComanda));
        if (comandas.length == 0){
          this.appCustomToastService.toast(TiposMensagem.info, ['Comanda não encontrada'], "Sucesso");
        }else{
          this.comandasFilter = comandas;
          if (comandas.length == 1){
            this.realizarPagamento(comandas[0]!);
          }
        }
      }else{
        this.comandasFilter = this.comandas
      }
      this.codigoComanda = '';
    }
  }

  cliqueCancelar(){
    this.fecharModal();
    this.cancelouSelecao.emit(null!);
  }

  getClassComanda(comanda: ComandumModel): string{
    if (comanda.situacao == enumSituacaoComanda.Livre)
      return 'single-comanda verde bgc bg-comanda';
    if (comanda.situacao == enumSituacaoComanda.Ocupada)
      return 'single-comanda vermelho bg-comanda';
    else return 'single-comanda verde bg-comanda';
  }
  getSituacaoComanda(comanda: ComandumModel): string{
    if (comanda.situacao == enumSituacaoComanda.Livre)
      return 'Livre';
    if (comanda.situacao == enumSituacaoComanda.Ocupada)
      return 'Livre';
    else return 'Indisponível';
  }

  getUsuarioComanda(comanda: ComandumModel | null): string{
    if (comanda?.comandaGerada != null && comanda.comandaGerada.length > 0){
      let retorno: string = '';
      if (comanda?.comandaGerada[0].cpfCnpj != ''){
        retorno += `CPF: ${comanda?.comandaGerada[0].cpfCnpj}\n`;
      }
      if (comanda?.comandaGerada[0].razaoSocialNome != ''){
        retorno += ` Nome: ${comanda?.comandaGerada[0].razaoSocialNome}\n`;
      }
      if (comanda?.comandaGerada[0].rg != ''){
        retorno += ` Rg: ${comanda?.comandaGerada[0].rg}\n`;
      }
      if (comanda?.comandaGerada[0].dataNascimento != null){
        retorno += ` Data Nascimento:  ${ApiUtilService.getDateFormatString(new Date(comanda?.comandaGerada[0].dataNascimento))}`;
      }
      if (comanda?.comandaGerada[0].telefoneCelular != ''){
        retorno += ` Celular: ${comanda?.comandaGerada[0].telefoneCelular}\n`;
      }
      if (comanda?.comandaGerada[0].telefoneContato != ''){
        retorno += ` Tel. Contato: ${comanda?.comandaGerada[0].telefoneContato}\n`;
      }
      return retorno;
    }
    return '';
  }

  
  venda: VendumModel = null!;
  abrirModal(venda: VendumModel) {
    this.venda = venda;
    this.modal.show();
    this.selecionarComanda(enumTipoComanda.Todas, enumSituacaoComanda.Ocupada);
    if (this.naoExisteParametrizacaoComanda){
      this.getParametrizacaoComanda();
    }
    this.onFocusCodigoComanda();
  }

  cliqueSelecionarComanda(){

  }

  fecharModal(){
    this.modal.hide();
  }

  mensagemPesquisa: string = '';
  selecionarComandaMesa(){
    this.mensagemPesquisa = 'Não há comandas de mesa cadastrada';
    this.selecionarComanda(enumTipoComanda.Mesa, enumSituacaoComanda.Ocupada);
  }
  selecionarComandaFixa(){
    this.mensagemPesquisa = 'Não há comandas fixa cadastrada';
    this.selecionarComanda(enumTipoComanda.Fixa, enumSituacaoComanda.Ocupada);
  }
  selecionarComandaSequencial(){
    this.mensagemPesquisa = 'Não há comandas sequenciais cadastrada';
    this.selecionarComanda(enumTipoComanda.Sequencial, enumSituacaoComanda.Ocupada);
  }
  selecionarComandasOcupadas(){
    this.mensagemPesquisa = 'Não há comandas ocupadas';
    this.selecionarComanda(enumTipoComanda.Todas, enumSituacaoComanda.Ocupada);
  }

  getTextoComanda(comanda: ComandumModel): string{
    let index = comanda.comandaGerada.findIndex(f => f.idComanda == comanda.idComanda &&
      f.tipoComanda == comanda.tipoComanda);
      if (index != -1){
        let comandaGerada = comanda.comandaGerada[index];
        if (comandaGerada.razaoSocialNome != '' && comandaGerada.razaoSocialNome != null){
          let nome = comandaGerada.razaoSocialNome;
          if (nome.length > 25) nome = nome.substring(0,25)
          return nome;
        }
        if (comandaGerada.cpfCnpj != '' && comandaGerada.cpfCnpj != null){
          return comandaGerada.cpfCnpj;
        }
        if (comandaGerada.rg != '' && comandaGerada.rg != null){
          return comandaGerada.rg;
        }
        if (comandaGerada.telefoneCelular != '' && comandaGerada.telefoneCelular != null){
          return comandaGerada.telefoneCelular;
        }
        if (comandaGerada.telefoneContato != '' && comandaGerada.telefoneContato != null){
          return comandaGerada.telefoneContato;
        }
        if (comandaGerada.dataNascimento != null){
          return  ApiUtilService.getDateFormatString(new Date(comandaGerada.dataNascimento));
        }
      }
      if (comanda.situacao == 'L'){
        return 'Comanda Livre'
      }
      return 'Comanda Ocupada';
  }
  
  comandas: ComandumModel[] = [];
  comandasFilter: ComandumModel[] = [];
  subsListarComandasMesa: Subscription = null!;
  selecionarComanda(tipoComanda: enumTipoComanda, situacaoComanda: enumSituacaoComanda){
    this.loading = true;
    this.subsListarComandasMesa = this.apiComandaService.listarComandas(tipoComanda, situacaoComanda, true)
      .subscribe( (retorno: ComandumModel[]) => {
        this.loading = false;
        this.subsListarComandasMesa?.unsubscribe();
        if (retorno != null){
          this.comandas = retorno;
          this.comandasFilter = this.comandas;
        }else{
          this.comandas = [];
          this.comandasFilter = [];
        }
      },
        (err) => {
          this.loading = false;
          this.subsListarComandasMesa?.unsubscribe();
          this.showMessageError.exibirMensagemErro(err);
        });
  }

  codigoComanda: string = '';
  tipoComanda: string = enumTipoComanda.Mesa;
  subsByIdLojaUsuarioLogado: Subscription = null!;
  parametrizacaoComanda: ParametrizacaoComandumModel = null!;
  loadingParametrizacaoComanda: boolean = false;
  getParametrizacaoComanda() {
    this.loadingParametrizacaoComanda = true;
    this.subsByIdLojaUsuarioLogado = this.apiParametrizacaoComandaService.getByLojaUsuariLogado(true)
      .subscribe((retorno: ParametrizacaoComandumModel) => {
        this.loadingParametrizacaoComanda = false;
        if (retorno != null){
          this.parametrizacaoComanda = retorno;
          this.naoExisteParametrizacaoComanda = false;
        }
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
      },
      (err) => {
        this.loadingParametrizacaoComanda = false;
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });      
  }

  subsImportarComandaParaPagamento: Subscription = null!;
  realizarPagamento(comanda: ComandumModel | null){
    //importarComandaParaPagamento
    let idVenda: number = 0;
    if (this.venda != null){
      idVenda = this.venda.idVenda;
    }
    if (ConstantsService.SituacaoCaixa != enumSituacaoCaixa.Aberto){
      return this.exibirMensagemParaAbrirCaixa();
    }
    this.subsImportarComandaParaPagamento = this.apiComandaService.importarComandaParaPagamento(comanda!.idComanda, comanda!.tipoComanda, idVenda, ConstantsService.IdCaixa, ConstantsService.IdPdv, true)
      .subscribe((retorno: VendumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Comanda Importada'], "Sucesso");
          this.selecionouComanda.emit(retorno);
          this.fecharModal();
        }
        this.subsImportarComandaParaPagamento?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsImportarComandaParaPagamento?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });  
  }
  indexComandaSelecionada: number = -1;
  subsLiberarComanda: Subscription = null!;
  private exibirMensagemParaAbrirCaixa() {
    swal.fire({
      title: "Alerta",
      html: 'É obrigatório abrir um caixa para realizar vendas no PDV',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
    return;
  }

  cliqueLiberarComanda(comanda: ComandumModel, index: number){
    this.indexComandaSelecionada = index;
    this.loading = true;
    let idComandaGerada: number | null = 0;
    if (comanda?.comandaGerada != null && comanda?.comandaGerada.length > 0){
      idComandaGerada = comanda?.comandaGerada[0].idComandaGerada;
    }
   
    this.subsLiberarComanda = this.apiComandaService.liberarComanda(comanda?.idComanda!, comanda?.tipoComanda!, idComandaGerada, true)
      .subscribe((retorno: ComandumModel) => {
        this.loading = false;
        if (retorno != null){
          this.appCustomToastService.toast(TiposMensagem.sucesso, ['Comanda liberada'], "Sucesso");
          if (index != -1){
            this.comandasFilter[index] = retorno;
          }
        }
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
      },
      (err) => {
        this.loading = false;
        this.subsByIdLojaUsuarioLogado?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });    
  }
  visualizarProdutos(comanda: ComandumModel | null){
    this.modalSelecionarComandaComponent.visualizarProdutos(comanda, true);
  }
  liberouComanda(retorno: ComandumModel){
    if (this.indexComandaSelecionada != -1){
      this.comandasFilter[this.indexComandaSelecionada] = retorno;
    }
  }
}
