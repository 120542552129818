import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { GerarComandaModel } from 'src/app/models/cliente/Comandum/gerarComandaModel';
import { ParametrizacaoComandumModel } from 'src/app/models/cliente/ParametrizacaoComandum/parametrizacaoComandumModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiParametrizacaoComandaService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'parametrizacaoComanda';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAll(exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}`, exibeCarregando);
    }

    get(id: number, exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, exibeCarregando)
    }

    getByLojaUsuariLogado(exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/getByLojaUsuariLogado`, exibeCarregando)
    }

    getComandaByTipo(tipo: string, exibeCarregando: boolean){
        return this.gc.get(this.api, `${this.version}${this.controller}/getComandaByTipo/${tipo}`, exibeCarregando)
    }

    gerarComandaPorTipoComanda(obj: GerarComandaModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `${this.version}${this.controller}/gerarComandaPorTipoComanda`, obj, exibeCarregando);
    }

    post(obj: ParametrizacaoComandumModel, exibeCarregando: boolean){
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj, exibeCarregando);
    }

    put(obj: ParametrizacaoComandumModel, exibeCarregando: boolean){
        return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idParametrizacaoComanda}`, obj, exibeCarregando);
    }

    delete(idComanda: number, tipoComanda: string, exibeCarregando: boolean){
        return this.gc.delete(this.api, `${this.version}${this.controller}/${idComanda}/${tipoComanda}`, exibeCarregando);
    }
}