import { enumStatusMovimentacaoCheque } from "src/app/enum/enumStatusMovimentacaoCheque";
import { ChequeModel } from "./ChequeModel";

export class ChequeModelInstance {
    public static get(): ChequeModel {
        return {
            idCheque: 0,
            nomeEmitente: '',
            telefone: '',
            dataVencimento: null,
            banco: '',
            agencia: '',
            conta: '',
            dac: '',
            numeroCheque: '',
            valor: null,
            observacao: '',
            statusMovimentacao: enumStatusMovimentacaoCheque.Recebido,
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            idFormaPagamentoParcela: null,
            idFormaPagamentoParcelaNavigation: null!,
            chequeContaPagRecs: [],
            chequeStatusMovimentacaos: []
        };
    }

    public static getArray(): ChequeModel[] {
        return [this.get()];
    };
}