import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ItemProdutoPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { Subscription } from 'rxjs';
import { VendaProdutoModel } from 'src/app/models/cliente/VendaProduto/vendaProdutoModel';
import { VendaProdutoModelInstance } from 'src/app/models/cliente/vendaProduto/vendaProdutoModelnstance';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { ApiUtilService } from 'src/app/services/cliente/api-util.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { InputNumber } from 'primeng/inputnumber';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';

@Component({
  selector: 'app-modal-alterar-item',
  templateUrl: './modal-alterar-item.component.html',
  styleUrls: ['./modal-alterar-item.component.css']
})
export class ModalAlterarItemComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() alterouItens: EventEmitter<ItemProdutoPdvModel[]> = new EventEmitter();
  inputDados: ItemProdutoPdvModel = null!;
  loadingListarItens: boolean = false;
  itens: ItemProdutoPdvModel[] = [];
  selecionouItem: boolean = false;
  loading: boolean = false;
  alterarPrecoVenda: string = enumSimNao.Nao;
  permiteAlterarPrecoVenda: boolean = false;
  permiteVendaEntrega: boolean = false;
  numeroItem: number | null = null;
  descricaoProdutoServico: string = '';

  permiteValorSeguro: string = enumSimNao.Sim;
  permiteDescProdServ: string = enumSimNao.Sim;

  unMedMetro: enumUnidadeComercial = enumUnidadeComercial.Metro;
  unMedMetroCubico: enumUnidadeComercial = enumUnidadeComercial.MetroCcbico;
  unMedMetroQuadrado: enumUnidadeComercial = enumUnidadeComercial.MetroQuadrado;

  constructor(private apiVendaService: ApiVendaService,
    private showMessageError: ShowMessageError,
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ) {
    this.translateService.use("pt");
    this.translateService.get('primeng').subscribe(res => this.config.setTranslation(res));
  }

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;

    if (ConstantsService.ParametrizacaoCadastro != null){
      if (ConstantsService.ParametrizacaoCadastro.permiteSeguroDeProduto != null){
        this.permiteValorSeguro = ConstantsService.ParametrizacaoCadastro.permiteSeguroDeProduto;
      }
      if (ConstantsService.ParametrizacaoCadastro.permiteDescontoNoProduto != null){
        this.permiteDescProdServ = ConstantsService.ParametrizacaoCadastro.permiteDescontoNoProduto;
      }
    }

  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  @ViewChild('elmNumeroItem') elmNumeroItem: InputNumber  = null!;
  onFocusItem(){
    setTimeout(()=>{ 
      if (this.elmNumeroItem != null){
        this.elmNumeroItem.input.nativeElement.focus();
      }
    },60);    
  }

  @ViewChild('elmQuantidade') elmQuantidade: InputNumber  = null!;
  onFocusQuantidade(){
    setTimeout(()=>{ 
      if (this.elmQuantidade != null){
        this.elmQuantidade.input.nativeElement.focus();
      }
    },120);    
  }

  @ViewChild('elmBotaoCancelar') elmBotaoCancelar: ElementRef  = null!;
  onFocusBotaoCancelar(){
    setTimeout(()=>{ 
      if (this.elmBotaoCancelar != null){
        this.elmBotaoCancelar.nativeElement.focus();
      }
    },120);    
  }

  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      
    }
    if (event.keyCode == 13) {
      this.cliqueAlterarItem();
    }
  }

  keyPressNumeroItem(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      
    }
    if (event.keyCode == 13) {

      
      if (this.numeroItem == null){
        swal.fire({
          title: "Atenção",
          text: `Informe o item para alterar!`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
              return;
          }
        });
        return;
      }
      //verifica se o item existe
      let indexItemSelecionado = this.itens.findIndex(f => f.item == this.numeroItem);
      if (indexItemSelecionado == -1){
        swal.fire({
          title: "Atenção",
          text: `Item não encontrado!`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
              return;
          }
        });
        return;
      }
      this.indexItemSelecionado = indexItemSelecionado;
      this.selecionarItem(this.itens[indexItemSelecionado]);
    }
  }

  onRowIconSelect(item: ItemProdutoPdvModel, index: number){
    this.indexItemSelecionado = index;
   this.selecionarItem(item);
  }
  getIndexGrid(item: number){
    return this.itens.findIndex(f => f.item == item);
  }
  
  indexItemSelecionado: number = 0;
  selecionarItem(item:ItemProdutoPdvModel){
    this.permiteAlterarPrecoVenda = false;
    this.inputDados = JSON.parse(JSON.stringify(item as ItemProdutoPdvModel));
    this.permiteVendaEntrega = ConstantsService.ParametrizacaoPdv.permiteEntrega;
    this.alterarPrecoVenda = ConstantsService.ParametrizacaoPdv.permiteAlterarPrecoVenda;
    if(this.alterarPrecoVenda == 'S'){
      this.permiteAlterarPrecoVenda = true;
    }
    if (this.inputDados.dataPrevistaEntregaAgendada != null) this.inputDados.dataPrevistaEntregaAgendada = new Date(this.inputDados.dataPrevistaEntregaAgendada);
    if(this.inputDados.quantidadeEntregueNoAto == 0 && this.inputDados.quantidadeParaEntregaAgendada == 0 &&
      this.inputDados.quantidadeParaEntregaCrossdoking == 0) this.inputDados.quantidadeEntregueNoAto = item.quantidade;
    if (this.inputDados.dataPrevistaParaEntregaCrossdocking != null) this.inputDados.dataPrevistaParaEntregaCrossdocking = new Date(this.inputDados.dataPrevistaParaEntregaCrossdocking);
    //consultar o valor permitido para desconto no item.
    let prod = ConstantsService.ListaProdutosCadastrados.filter(f => f.idProdutoVariacao == item.idProdutoVariacao);
    let serv = ConstantsService.ListaServicosCadastrados.filter(f => f.idProdutoVariacao == item.idProdutoVariacao);
    if(prod != null && prod.length > 0){
      this.inputDados.percMaxDesconto = prod[0].percMaxDesconto;
    }else if(serv != null && serv.length > 0){
      this.inputDados.percMaxDesconto = serv[0].percMaxDesconto;
    }
    //final da consulta
    //tratar o nome do item selecionado
    if(this.inputDados.gtin != '' && this.inputDados.gtin != null && this.inputDados.sku != '' && this.inputDados.sku != null &&
      ConstantsService.DescricaoProdutoImprimeGtin == enumSimNao.Sim && ConstantsService.DescricaoProdutoImprimeSku == enumSimNao.Sim){
        this.descricaoProdutoServico = this.inputDados.gtin +" - "+ this.inputDados.sku +" - "+ this.inputDados.nome;
    }else
    if(ConstantsService.DescricaoProdutoImprimeGtin == enumSimNao.Sim &&
      this.inputDados.gtin != '' && this.inputDados.gtin != null)
    {
      this.descricaoProdutoServico = this.inputDados.gtin +" - "+ this.inputDados.nome;
    }
    else
    if(ConstantsService.DescricaoProdutoImprimeSku == enumSimNao.Sim &&
      this.inputDados.sku != '' && this.inputDados.sku != null)
    {
      this.descricaoProdutoServico = this.inputDados.sku +" - "+ this.inputDados.nome;
    }      
    else{
      this.descricaoProdutoServico = this.inputDados.nome;
    }
    //final tratamento do nome do item
    this.selecionouItem = true;
    this.onFocusQuantidade();
  }

  exibirDescontoMaximo: boolean = false;
  vDescontoMaximo: string | null = null;
  valorDesconto: number = 0;
  descontoMaximo(){
    this.exibirDescontoMaximo = !this.exibirDescontoMaximo;
    this.calcularDesconto();
  }

  calcularDesconto(){
    //this.valorDesconto = 0;
    if (this.exibirDescontoMaximo && this.permiteDescProdServ == enumSimNao.Sim){
      if (this.inputDados.percMaxDesconto != null && this.inputDados.percMaxDesconto != 0){
        this.valorDesconto = (this.inputDados.percMaxDesconto!/100) * (this.inputDados.quantidade! * this.inputDados.precoVenda!);
        this.vDescontoMaximo = this.getFormatDecimal(this.valorDesconto);
        if (this.valorDesconto! < this.inputDados.valorDesconto!){
          this.inputDados.valorDesconto = this.valorDesconto;
        }
      }
      else{
        this.vDescontoMaximo = this.getFormatDecimal(0)
      }
    }else{
      this.vDescontoMaximo = null;
    }

    // //acrescentar o valor de desconto promocional, caso tenha.
    // if(this.inputDados.valorTotalPromocaoConcedido != null && this.inputDados.valorTotalPromocaoConcedido > 0){
    //   this.valorDesconto += this.inputDados.valorTotalPromocaoConcedido;
    // }
  }

  atualizarCalculos(){
    this.calcularDesconto();
    this.calcularCusto();
  }

  showMargem: boolean = false;
  showTotais: boolean = false;
  exibirCusto: boolean = false;
  totalMargemSobreCustoSemDesconto: number = 0;
  totalMargemSobreCustoComDesconto: number = 0;
  vMargemSobreCustoSemDesconto: string | null= null;
  vMargemSobreCustoComDesconto: string | null= null;

  cliqueExibirCusto(){
    this.exibirCusto = !this.exibirCusto;
  }

  calcularCusto(){
    /*calculo de custo*/
    if (this.inputDados.precoVenda != null){
    
     let totalComDesconto = ((this.inputDados.quantidade! * this.inputDados.precoVenda!) + this.inputDados.valorOutrasDespesas! 
     + this.inputDados.valorTotalFrete!
     + this.inputDados.valorTotalSeguro!
     - this.inputDados.valorDesconto!);

     let totalSemDesconto = ((this.inputDados.quantidade! * this.inputDados.precoVenda!) + this.inputDados.valorOutrasDespesas! 
     + this.inputDados.valorTotalFrete! + this.inputDados.valorTotalSeguro!);

     let totalCustoComDesconto = (this.inputDados.quantidade! * this.inputDados.precoMargemZero!) + this.inputDados.valorOutrasDespesas! 
     + this.inputDados.valorTotalFrete!
     + this.inputDados.valorTotalSeguro!
     - this.inputDados.valorDesconto!;

     let totalCustoSemDesconto = (this.inputDados.quantidade! * this.inputDados.precoMargemZero!) + this.inputDados.valorOutrasDespesas! 
     + this.inputDados.valorTotalFrete! + this.inputDados.valorTotalSeguro!;

     // (1 - (total do orçamento dividido Recuperar o total preço custo divido )) e multiplicar por 100 e multiplicar por -1 para gerar positivo
     this.totalMargemSobreCustoSemDesconto = ((totalSemDesconto - totalCustoSemDesconto) / totalCustoSemDesconto) * 100;
     if (this.inputDados.precoMargemZero != null && this.inputDados.precoMargemZero != 0){
       this.vMargemSobreCustoSemDesconto = `${this.totalMargemSobreCustoSemDesconto.toFixed(2)} %`;
     }else{
       this.vMargemSobreCustoSemDesconto = "Não informado";
     }

     //(1 - (Recuperar o total considerando o pagamento divido pelo total preço custo)) e multiplicar por 100 e multiplicar por -1 para gerar positivo
     this.totalMargemSobreCustoComDesconto = ((totalComDesconto - totalCustoComDesconto) / totalCustoComDesconto) * 100;
     if (this.inputDados.precoMargemZero != null && this.inputDados.precoMargemZero != 0){
       this.vMargemSobreCustoComDesconto = `${this.totalMargemSobreCustoComDesconto.toFixed(2)} %`;
     }else{
       this.vMargemSobreCustoComDesconto = "Não informado";
     }
   }
 }

  getFormatDecimal(number: number): string{
    if (number == null) return 'n/d';
    return new Intl.NumberFormat('pt-BR',{ style: 'currency', currency:'BRL' }).format(number);
  }
  
  onRowSelect(param: any) {
    this.indexItemSelecionado = param.index;
    this.selecionarItem(param.data as ItemProdutoPdvModel);
  }

  changeQtdeItemPorMetro(valor: number){   
    if(valor != null){
      if(this.inputDados.unidadeMedida == this.unMedMetro){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.unidadeMedida == this.unMedMetroQuadrado){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeTipoMedidaLargura!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.unidadeMedida == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeTipoMedidaAltura!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }

  changeComprimento(valor: number){
    if(valor != null){
      if(this.inputDados.unidadeMedida == this.unMedMetro){
        this.inputDados.quantidade = valor * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.unidadeMedida == this.unMedMetroQuadrado){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.unidadeMedida == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeTipoMedidaAltura! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }

  changeLargura(valor: number){
    if(valor != null){
      if(this.inputDados.unidadeMedida == this.unMedMetroQuadrado){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
      else
      if(this.inputDados.unidadeMedida == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeTipoMedidaAltura! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }
  
  changeAltura(valor: number){
    if(valor != null){
      if(this.inputDados.unidadeMedida == this.unMedMetroCubico){
        this.inputDados.quantidade = valor * this.inputDados.unidadeTipoMedidaLargura! * this.inputDados.unidadeTipoMedidaComprimento! * this.inputDados.unidadeQuantidadeItem!;
        this.changeQuantidade(this.inputDados.quantidade);
      }
    }
  }

  changeQuantidadeEntregueAgendada(valor:number){
    if(valor != null){
      if(valor > this.inputDados.quantidade) {
        valor = this.inputDados.quantidade;
        this.inputDados.quantidadeParaEntregaAgendada = valor;
      }
      this.inputDados.quantidadeEntregueNoAto = this.inputDados.quantidade - valor - this.inputDados.quantidadeParaEntregaCrossdoking;
      if(valor <= 0){
        this.inputDados.dataPrevistaEntregaAgendada = null;
      }else{
        this.inputDados.dataPrevistaEntregaAgendada = new Date();
      }
    }
  }

  changeQuantidade(valor: number){
    if(valor == null || valor == 0) valor = 1;
    if(this.inputDados.quantidade == null || this.inputDados.quantidade == 0) this.inputDados.quantidade = 1;
    if(valor != null){
      this.inputDados.quantidadeEntregueNoAto = valor - this.inputDados.quantidadeParaEntregaCrossdoking! - this.inputDados.quantidadeParaEntregaAgendada!;
      this.inputDados.valorTotalSeguro = valor * this.inputDados.valorSeguro!;
      this.calcularDesconto();
    }
  }
  // changeQuantidadeCrossDocking(valor:number){
  //   if(valor != null){
  //     this.inputDados.quantidadeEntregueNoAto = this.inputDados.quantidade - valor - this.inputDados.quantidadeParaEntregaAgendada!;
  //     if(valor <= 0){
  //       this.inputDados.dataPrevistaParaEntregaCrossdocking = null;
  //     }
  //   }else{
  //     this.inputDados.dataPrevistaParaEntregaCrossdocking = null;
  //   }
  // }
  cliqueFechar(){
    if (this.alterouItemComSucesso)
    {
      this.alterouItens.emit(this.itens);
    }
    this.fecharModal();
  }

  onFoucus(event){
    console.log(event);
    setTimeout(()=>{ 
      event.target.select();
    },200);  
    
  }

  cliqueVoltar(){
    this.descricaoProdutoServico = '';
    this.selecionouItem = false;
    this.exibirDescontoMaximo = false;
    this.vDescontoMaximo = null;
    this.showTotais = false;
    this.showMargem = false;
    this.onFocusItem();
  }

  precoUnitarioInicial: number = 0;
  selecionouApenasUmItem: boolean = false;
  abrirModal(itens: ItemProdutoPdvModel[], editarUmItem: Boolean, apenasUmItem: ItemProdutoPdvModel, indexItemSelecionado: number) {
    this.descricaoProdutoServico = '';
    this.indexItemSelecionado = indexItemSelecionado;
    this.exibirDescontoMaximo = false;
    this.alterouItemComSucesso = false;
    this.selecionouItem = false;
    this.itens = itens;
    this.modal.show();
    this.selecionouApenasUmItem = apenasUmItem != null;
    this.onFocusItem();
    if(editarUmItem){
      this.precoUnitarioInicial = apenasUmItem.precoVenda!;
      this.selecionarItem(apenasUmItem)
    }
  }
  calcularTotais(){
    this.itens.forEach(f => {
      f.subTotal = (f.quantidade * f.precoVenda!);
      f.valorTotal = (f.quantidade * f.precoVenda!) - f.valorDesconto! + f.valorTotalFrete! + f.valorOutrasDespesas! + f.valorTotalSeguro!;
    }); 
  }

  mensagemIndicaValorItemValorTot: string = '';
  onChangeIndicaValorItemValorTot(event:any){
    if(event.target.checked){
      this.inputDados.indicaValorItemValorTot = enumSimNao.Sim;
      this.mensagemIndicaValorItemValorTot = '';
    }else{
      this.inputDados.indicaValorItemValorTot = enumSimNao.Nao;
      this.mensagemIndicaValorItemValorTot = "Se for emitir [NFC-e ou NFS-e], o campo: Contabilizado Total, é obrigatório estar selecionado. Outra Obs.: O valor total deste item não será contabilizado no pagamento desta venda.";
    }
    //this.inputDados.indicaValorItemValorTot = (event?.target.checked)?enumSimNao.Sim:enumSimNao.Nao;
  }

  cliqueAlterarItem(){
    if (
      this.inputDados.quantidadeEntregueNoAto < 0){
          swal.fire({
            title: "Atenção",
            html: `Distribuição dos itens para entrega não pode ultrapassar a quantidade para venda. <b>${this.inputDados.quantidade}</b> item(ns)!`,
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
                return;
            }
          });
        }
    else{
      if (
          this.inputDados.quantidadeParaEntregaAgendada! > 0 && this.inputDados.dataPrevistaEntregaAgendada == null){
            swal.fire({
              title: "Atenção",
              html: `Quando há itens para entrega agendada, obrigatório informar a data de entrega!`,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                  return;
              }
            });
          }
          else{
            if (
              this.inputDados.quantidadeParaEntregaAgendada! > 0 &&
            (this.inputDados.dataPrevistaEntregaAgendada != null && 
              ApiUtilService.getDateString(this.inputDados.dataPrevistaEntregaAgendada) < ApiUtilService.getDateString(new Date()))){
                swal.fire({
                  title: "Atenção",
                  html: `Informe uma data de entrega maior ou igual a data de hoje!`,
                  icon: 'warning',
                  showCancelButton: false,
                  confirmButtonText: 'Ok'
                }).then((result) => {
                  if (result.isConfirmed) {
                      return;
                  }
                });
              }else{

                //validar item alterado
                if (this.inputDados.valorDesconto! > 0){
                  this.validarItemAlteradoApi();
                }else{
                  this.exibirMensageSucessoItemAlterado();
                }
              }
          }
    }
  }
  alterouItemComSucesso: boolean = false;
  subsValidarItemAltearado: Subscription = null!;
  private validarItemAlteradoApi(){
    this.loading = true;
    let vendaProduto: VendaProdutoModel = VendaProdutoModelInstance.get();
    vendaProduto.valorDesconto = this.inputDados.valorDesconto;
    vendaProduto.idProdutoUnico = this.inputDados.idProdutoUnico;
    vendaProduto.quantidade = this.inputDados.quantidade;
    vendaProduto.precoUnitario = this.inputDados.precoVenda!;
    vendaProduto.valorSeguro = this.inputDados.valorSeguro!;
    vendaProduto.valorTotalSeguro = this.inputDados.valorTotalSeguro!;
    vendaProduto.valorTotalFrete = this.inputDados.valorTotalFrete!;
    vendaProduto.valorOutrasDespesas = this.inputDados.valorOutrasDespesas!;
    this.subsValidarItemAltearado = this.apiVendaService.validarItemAlterado(vendaProduto, true).subscribe(
      (retorno: VendaProdutoModel) =>{
        this.loading = false;
        this.subsValidarItemAltearado?.unsubscribe();
         this.exibirMensageSucessoItemAlterado();
      }
      ,
      (err) => {
        this.inputDados.valorDesconto = 0;
        this.loading = false;
        this.subsValidarItemAltearado?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  private exibirMensageSucessoItemAlterado() {
    swal.fire({
      title: "Sucesso",
      html: 'Item Alterado!',
      icon: 'success',
      showCancelButton: false,
      timer: 500,
      confirmButtonText: 'Ok'
    }).then((result) => {
      this.selecionouItem = false;
      this.alterouItemComSucesso = true;
      if(this.precoUnitarioInicial != this.inputDados.precoVenda){
        this.inputDados.alterouPrecoUnitario = enumSimNao.Sim;

        //consultar o preço de venda
        var produtoSelecionado = ConstantsService.ListaProdutosCadastrados.find(f => 
          f.idProdutoUnico == this.inputDados.idProdutoUnico && f.idProdutoVariacao == this.inputDados.idProdutoVariacao)!;

        var precoVenda = this.inputDados.precoVenda;
        if (produtoSelecionado != null){
          //recupera o preço venda original e atribui no produto base
          precoVenda = produtoSelecionado.precoVenda;
        }
        this.inputDados.precoUnitarioBase = precoVenda;
      }
      this.itens[this.indexItemSelecionado] = this.inputDados;
      this.calcularTotais();
      if (this.selecionouApenasUmItem){
        this.cliqueFechar();
      }else{
        this.numeroItem = null;
        this.onFocusItem();
      }
    });
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.exibirDescontoMaximo = false;
    this.vDescontoMaximo = null;
    this.showTotais = false;
    this.showMargem = false;
    this.modal.hide();
  }

}
