import { enumSimNao } from "src/app/enum/enumSimNao";
import { OrcamentoProdutoModel } from "./orcamentoProdutoModel";


export class OrcamentoProdutoModelInstance {
    public static get(): OrcamentoProdutoModel {
        return {
            idOrcamentoProduto: 0,
            idOrcamento: null!,
            idProdutoUnico: 0,
            idProdutoVariacao: null!,
            detalhe: '',
            quantidade: null!,
            precoUnitario: null!,
            valorTotalFrete: null!,
            valorDesconto: null!,
            numeroItem: 0,
            detalheImpressao: '',
            valorOutrasDespesasAcessorias: null!,
            valorSubtotal: 0,
            dataRegistro: null!,
            cpfRegistro: '',
            dataAlteracao: null!,
            cpfAlteracao: '',
            quantidadeEntregueNoAto: 0,
            quantidadeParaEntregaAgendada: null!,
            quantidadeParaEntregaCrossdoking: null,
            dataPrevistaEntregaAgendada: null!,
            dataPrevistaParaEntregaCrossdoking: null!,
            valorTotal: null!,
            indicaValorItemValorTot: '',
            numeroPedidoCompra: '',
            idOrcamentoNavigation: null!,
            idProduto: null!,
            idProdutoUnicoNavigation: null!,
            valorSeguro: null!,
            valorCustoTotal: null!,
            valorCustoUnitario: null!,
            valorTotalSeguro: 0,
            alterouPrecoUnitario: enumSimNao.Nao,
            precoUnitarioBase: 0,
            valorTotalPromocaoConcedido: 0,
            idProdutoPrecoVariado: null,
            unidadeTipoMedidaAltura: 0,
            unidadeTipoMedidaComprimento: 0,
            unidadeTipoMedidaLargura: 0,
            unidadeQuantidadeItem: 0
        };
    }
}
