<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelarCadastrar()" [dialogClass]="'modal-lg'">
    <div class="app-modal-header col-12">
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-money-check-alt icon-header-modal"></i>&nbsp;Detalhes de Parcelas</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body">
        <p-table 
            #dt2 [value]="formaPagamentoParcelas" dataKey="idFormaPagamentoParcela" [rows]="5" [showCurrentPageReport]="true"
            [showCurrentPageReport]="false" [rowsPerPageOptions]="[5,15,30]" class="p-table table-hover"
            [loading]="loadingLista" currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros"
            styleClass=" " [paginator]="false">
            <ng-template pTemplate="header">
                <tr>
                    <th class="text-center">#</th>
                    <th class="text-center">Parcela</th>
                    <th class="text-center">Data Vencimento</th>
                    <th class="text-center">Valor</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                <tr>
                    <td class="text-center">
                        {{ item.idFormaPagamentoParcela }}
                    </td>
                    <td class="text-center">
                        {{ item.numeroParcela }}
                    </td>
                    <td class="text-center">
                        {{ item.dataVencimento  | date: 'dd/MM/yyyy'}}
                    </td>
                    <td class="text-center">
                        {{ item.valorParcela | currency:'BRL' }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="4">Não há parcela(s) cadastrada(s)</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelarCadastrar()" type="button"
            class="btn btn-secondary btn-modal inline mouse-pointer me-1"><i class="fad fa-arrow-left"></i> Voltar
        </button>
    </div>
</app-ui-modal>