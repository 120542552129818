import { Component, OnInit, Input, Output, EventEmitter, forwardRef, OnDestroy, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumTipoCentroCusto } from 'src/app/enum/enumTipoCentroCusto';
import { TabelaCentroCustoModel } from 'src/app/models/cliente/TabelaCentroCusto/tabelaCentroCustoModel';
import { ApiTabelaCentroCustoService } from 'src/app/services/cliente/api-tabela-centro-custo.service';

@Component({
  selector: 'app-combo-tabela-centro-custo',
  templateUrl: './combo-tabela-centro-custo.component.html',
  styleUrls: ['./combo-tabela-centro-custo.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboTabelaCentroCustoComponent),
    multi: true
  }]
})
export class ComboTabelaCentroCustoComponent implements OnInit, OnDestroy, ControlValueAccessor {
  subService: Subscription | null = null;
  public numeroMinimoLetras = 1;

  @Input() tipoCentroCusto: enumTipoCentroCusto = enumTipoCentroCusto.Todos;
  @Input() toolTip: string = 'Selecione um Centro de Custo/Departamento para associar lançamentos como Contas a pagar/receber';
  @Input() idCampo: string = 'idTabelaCentroCusto';
  @Input() itens: Array<IOption> = [];  
  @Input() preCarregar: boolean = true;
  @Input() itensFixo: Array<IOption> = [];
  @Input() label: string = 'Centro de Custo';
  @Input() disabled: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();  
  private val: number = 0 // this is the updated value that the class accesses

  constructor(private tabelaCentroCustoService: ApiTabelaCentroCustoService,
    private showMessageError: ShowMessageError) {
  }

  ngOnInit(): void {
    if (this.preCarregar){
      this.getLista(null!);
    }
  }

  changeValue() {    
    this.change.emit(parseInt(this.value));
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }

  atualizarLista(item: TabelaCentroCustoModel) {
    this.getLista(item);
  }  
  
  loading: boolean = false;
  getLista(item: TabelaCentroCustoModel) {
      this.loading = true;
      this.subService = this.tabelaCentroCustoService.getAll(this.tipoCentroCusto, true).subscribe(
        (retorno: Array<TabelaCentroCustoModel>) => {
          this.itens = retorno.map((objeto) => {
            return {
              value: objeto.idTabelaCentroCusto.toString(),
              label: objeto.nome,
              checked: false
            }
          })
          if (this.itens.length > 1){
            this.itens.splice(0, 0, this.optionDefault());
          }
          if (this.itens.length == 1){
            this.value = this.itens[0].value.toString();
          }
          if (item != null) {
            this.value = item.idTabelaCentroCusto.toString();
          }
          this.loading = false;
        });
  }

  private optionDefault() {
    return {
      value: "0",
      label: "Selecione"
    };
  }

  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj?.toString();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }


}