import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ReciboModel } from 'src/app/models/config/reciboModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiImpressaoReciboService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Config;
    private controller = 'impressaoRecibo';
    private version = 'api/';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    imprimirRecibo(cupomFiscal: ReciboModel, exibeCarregando?: boolean | true) {
        return this.gc.post(this.api, `${this.version}${this.controller}/imprimir`, cupomFiscal, exibeCarregando);
    }

}