import { Injectable } from '@angular/core';
import { catchError , mergeMap, retryWhen, delay  } from 'rxjs/operators';
import { Observable, throwError, of} from 'rxjs';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService
  ) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    //const timeoutValue = 30000; // 30 segundos
    const retryAttempts = 3; // Número de tentativas de retry
    const timeDelay = 4000; //3 segundos

    if (ConstantsService.Token != '') {

      let anyRequest = request as any;
      if (anyRequest.headers == null 
        || anyRequest.headers.lazyUpdate == null || 
        anyRequest.headers.lazyUpdate.findIndex(f => f.name == 'Authorization') == -1 )
      {
        request = request.clone({
          setHeaders: {
            'Authorization': `Bearer ${ConstantsService.Token}`,
          },
        });
      }
    }

    const isPrintRequest = request.url.includes('/print');

    return next.handle(request).pipe(
      //timeout(timeoutValue),
      retryWhen(errors =>
        errors.pipe(
          mergeMap((error, index) => {
            if (isPrintRequest || index >= retryAttempts || error.status === 400 || error.status === 401) {
              return throwError(() => error); // Não retry em caso de erro 400 ou 401 ou se o número de tentativas foi atingido
            }
            return of(error).pipe(delay(timeDelay)); // Retry após 3 segundos de atraso
          })

          // delayWhen(() => of(true).pipe(delay(timeDelay))), // Atraso de 3 segundos nas tentativas de retry
          // take(retryAttempts),
          // concat(throwError('Servidor indisponível após múltiplas tentativas'))
        )
      ),
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
        }
        //const error = err.error.message || err.message || err.statusText;
        return throwError(err);
      })
    );
  }
}