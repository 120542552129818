import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { ProfissionalModel } from 'src/app/models/cliente/Profissional/profissionalModel';
import { ApiProfissionalService } from 'src/app/services/cliente/api-profissional.service';

@Component({
  selector: 'app-combo-profissional',
  templateUrl: './combo-profissional.component.html',
  styleUrls: ['./combo-profissional.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboProfissionalComponent),
    multi: true
  }]
})
export class ComboProfissionalComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() style: string = "";
  @Input() label: string = "";
  @Input() toolTip: string = "";
  @Input() disabled: boolean = false;
  @Output() change: EventEmitter<any> = new EventEmitter();
  val: string = '' // this is the updated value that the class accesses
  @Input() selecionado: number = 0;
  subService: Subscription | null = null;
  @Input() itens: Array<IOption> = [];
  @Input() idCampo: string = 'proCpfCnpj';
  lista: Array<ProfissionalModel> = [];
  notFoundText: string = 'Sem registro';

  constructor(private apiProfissional: ApiProfissionalService,
    private showMessageError: ShowMessageError) { }

  ngOnInit(): void {
    this.getLista(null!);
  }

  changeValue() {    
    this.change.emit(parseInt(this.value));
  }

  clear(event: any) {
    this.value = "";
    this.change.emit(null!);
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }

  atualizarLista(item: ProfissionalModel) {
    this.getLista(item);
  } 
  
  loading: boolean = false;
  getLista(item: ProfissionalModel) {
    
      this.loading = true;
      this.subService = this.apiProfissional.getAll(false).subscribe(
        (retorno: Array<ProfissionalModel>) => {
          this.lista = retorno;
          this.itens = retorno.map((objeto) => {
            return {
              value: `${objeto.cpfCnpj}`,
              label: objeto.cpfCnpjNavigation.razaoSocialNome,
              checked: false
            }
          })
          if (this.itens.length == 1) {
            this.value = this.itens[0].value;
            this.notFoundText = `${this.itens.length} registro(s) encontrado(s)`;
          }
          else {
            this.itens.splice(0, 0, this.optionDefault());
            this.notFoundText = `${this.itens.length} registro(s) encontrado(s)`;
          }
          this.loading = false;
        },
        (err) => {
          this.loading = false;
          this.showMessageError.exibirMensagemValidacaoTela(err);
        });
  }  

  
  private optionDefault() {
    return {
      value: "",
      label: "Selecione"
    };
  }

  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj?.toString();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }
}