import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { LoginModel } from 'src/app/models/controle/Autenticacao/loginModel';
import { LoginModelInstance } from 'src/app/models/controle/Autenticacao/LoginModelInstance';
import { AppSettings } from 'src/app/app-settings';
import { TokenModel } from 'src/app/models/controle/Autenticacao/TokenModel';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { VendaModelInstance } from 'src/app/models/cliente/Vendum/VendaModelInstance';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { AppCustomToastService } from 'src/app/theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { ParametrosCancelarVendaModel } from 'src/app/models/cliente/Vendum/ParametrosCancelarVendaModel';
import { ParametrosCancelarVendaModelInstance } from 'src/app/models/cliente/Vendum/ParametrosCancelarVendaModelInstance';
import { enumTipoCentroCusto } from 'src/app/enum/enumTipoCentroCusto';
import { enumSituacaoCaixa } from 'src/app/enum/enumSituacaoCaixa';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { enumStatusVenda } from 'src/app/enum/enumStatusVenda';
import { enumTipoCancelamento } from 'src/app/enum/enumTipoCancelamento';

@Component({
  selector: 'app-modal-cancelar-venda',
  templateUrl: './modal-cancelar-venda.component.html',
  styleUrls: ['./modal-cancelar-venda.component.css']
})
export class ModalCancelarVendaComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() cancelouVenda: EventEmitter<VendumModel> = new EventEmitter();
  loadingCancelarVenda: boolean = false;
  inputDados: VendumModel = VendaModelInstance.get();
  parametrosCancelarVenda: ParametrosCancelarVendaModel = ParametrosCancelarVendaModelInstance.get();
  loading: boolean = false;
  subsCancelarVenda: Subscription = null!;
  tipoCentroCusto: enumTipoCentroCusto = enumTipoCentroCusto.Todos;
  situacaoCaixa: enumSituacaoCaixa = enumSituacaoCaixa.Aberto;
  tipoCancelamento: enumTipoCancelamento = enumTipoCancelamento.Venda;
  
  constructor(
    private appCustomToastService: AppCustomToastService,
    private apiVendaService: ApiVendaService,
    private auth: AuthService,
    private showMessageError: ShowMessageError) { }

  ngOnInit(): void {
  }

  // todo: consider preventing default and stopping propagation
  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  @ViewChild('elmCancelarVenda') elmCancelarVenda: ElementRef  = null!;
  onFocusCancelarVenda(){
    setTimeout(()=>{ 
      if (this.elmCancelarVenda != null){
        this.elmCancelarVenda.nativeElement.focus();
      }
    },60);    
  }

  @ViewChild('elmUsuario') elmUsuario: ElementRef  = null!;
  onFocusUsuario(){
    setTimeout(()=>{ 
      if (this.elmUsuario != null){
        this.elmUsuario.nativeElement.focus();
      }
    },60);    
  }

  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13 && !this.exibirLoginUsuarioESenha) {
      this.cliqueCancelarVenda();
    }
    if (event.keyCode == 13 && this.exibirLoginUsuarioESenha) {
      this.confirmarUsuarioESenha();
    }
  }

  cliqueCancelar(){
    this.fecharModal();
  }

  usuario: string = '';
  senha: string = '';
  exibirLoginUsuarioESenha: boolean = false;

  abrirModal(venda: VendumModel) {
    this.inputDados = venda;
    this.exibirLoginUsuarioESenha = false;
    this.usuario = '';
    this.senha = '';
    this.modal.show();
    this.onFocusCancelarVenda();
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.parametrosCancelarVenda = ParametrosCancelarVendaModelInstance.get();
    this.modal.hide();
  }

  cliqueCancelarVenda(){
    this.loading = true;
    this.parametrosCancelarVenda.idVenda = this.inputDados.idVenda;
    this.parametrosCancelarVenda.idMotivoCancelamento = this.inputDados.idMotivoCancelamento;
    this.subsCancelarVenda = this.apiVendaService.cancelarVenda(this.parametrosCancelarVenda, '', true).subscribe(
      () =>{
        this.subsCancelarVenda?.unsubscribe();
        this.loading = false;
        this.appCustomToastService.toast(TiposMensagem.sucesso, ['Venda cancelada'], "Sucesso");
        this.inputDados.idTabelaStatusVenda = enumStatusVenda.Cancelada;
        this.cancelouVenda.emit(this.inputDados);
        this.fecharModal();
      },
      (err) => {
        if (err.status == 403)
        {
          this.loading = false;
          this.cancelarVendaComUsurioESenha();
        }else{
          this.loading = false;
          this.subsCancelarVenda?.unsubscribe();
          this.showMessageError.exibirMensagemValidacaoTela(err);
        }
      });
  }

  cancelarVendaComUsurioESenha(){
    this.exibirLoginUsuarioESenha = true;
    this.onFocusUsuario();
  }

  subsCancelarVendaComUsuarioESenha: Subscription = null!;
  confirmarUsuarioESenha(){
    let sendLogin: LoginModel = LoginModelInstance.get();
    sendLogin.idAplicacao = AppSettings.ID_APLICACAO;
    sendLogin.emailCpf = this.usuario;
    sendLogin.senha =  this.senha;
    this.loading = true;
    this.parametrosCancelarVenda.idVenda = this.inputDados.idVenda;
    this.parametrosCancelarVenda.idMotivoCancelamento = this.inputDados.idMotivoCancelamento;
    this.auth.login(sendLogin).subscribe(
      (token : TokenModel) => {
        this.subsCancelarVendaComUsuarioESenha = this.apiVendaService.cancelarVenda(this.parametrosCancelarVenda, token.token, true).subscribe(
          () =>{
            this.loading = false;
            this.subsCancelarVendaComUsuarioESenha?.unsubscribe();
            swal.fire({
              title: "Sucesso",
              text: 'Venda Cancelada!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              if (result.isConfirmed) {
                this.cancelouVenda.emit(this.inputDados);
                this.fecharModal();
              }
            });
          },
          (err) => {
            if (err.status == 403)
            {
              this.loading = false;
              //sem permissão, solicitar usuário e senha com permissão
              swal.fire('Aviso', "Usuário sem permissão para cancelar a venda", TiposMensagem.erro);
            }else{
              this.loading = false;
              this.subsCancelarVendaComUsuarioESenha?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }
            
          });
      },
      (err) => {
        this.loading = false;
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  esconderInformacoesModal: boolean = false;
  abriuModalCaixa(acao: enumAcaoAbrirFecharCaixa){
    //this.esconderInformacoesModal = true;
  }
}