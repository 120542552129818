import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ErrorsModule } from './core/errors/errors.module';
import { ShowMessageError } from './core/errors/ShowMessageError';
import { PdvComponent } from './theme/layout/pdv/pdv.component';
import { AuthGuard } from './theme/shared/auth/auth-guard.service';
import { AuthService } from './theme/shared/auth/auth.service';
import { LoginComponent } from './theme/layout/login/login.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppCustomToastComponent } from './theme/shared/components/app-mensagem/app-toast/app-custom-toast.component';
import { SharingService } from './shared/services/sharing.service';
import { ToastService } from './theme/shared/components/toast/toast.service';
import { AppCustomToastService } from './theme/shared/components/app-mensagem/app-toast/app-custom-toast.service';
import { SharedModule } from './theme/shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastModule } from 'primeng/toast';
import { AppMensagemService } from './theme/shared/components/app-mensagem/app-mensagem.service';
import localePt from '@angular/common/locales/pt';
import { ModalSangriaModule } from './shared/business-component/modal-sangria-suprimento/modal-sangria-suprimento.module';
import { ModalAbrirFecharCaixaModule } from './shared/business-component/modal-abrir-fechar-caixa/modal-abrir-fechar-caixa.module';
import { ModalConsultarPrecoModule } from './shared/business-component/modal-consultar-preco/modal-consultar-preco.module';
import { SelectOptionService } from './shared/services/select/select-option.service';
import { NoAuthGuard } from './theme/shared/auth/no-auth-guard.service';
import { ApiTokenRedirectService } from './services/controle/api-token-redirect.service';
import { RedirectComponent } from './theme/layout/redirect/redirect.component';
import { DebounceClickDirective } from './theme/shared/components/directive/debounce-click';
import { DebounceClickModule } from './theme/shared/components/directive/debounce-click.module';
import { ModalSelecionarTabelaPrecoModule } from './shared/business-component/modal-selecionar-tabela-preco/modal-selecionar-tabela-preco.module';
import { HttpInterceptorService } from './theme/shared/auth/HttpInterceptorService';

registerLocaleData(localePt, 'pt');


@NgModule({
  declarations: [
    AppComponent,
    PdvComponent,
    LoginComponent,
    RedirectComponent,
    AppCustomToastComponent
  ],
  imports: [
    NgbModule,
	  HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ErrorsModule,
    
    
    ModalSelecionarTabelaPrecoModule,
    ModalAbrirFecharCaixaModule,
    ModalConsultarPrecoModule,
    ModalSangriaModule,
    ToastModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    AppCustomToastComponent
  ],
  providers: [ShowMessageError,
    SelectOptionService,
    AppMensagemService,
    ApiTokenRedirectService,
    SharingService,
    NoAuthGuard,
    AuthService,
    AuthGuard,
    MessageService,
    ToastService,
    AppCustomToastService,
    DatePipe,
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,

    }],
      bootstrap: [AppComponent]
})
export class AppModule { }
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

