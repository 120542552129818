<label *ngIf="label !=  ''" class="form-label pb-1">{{label}}&nbsp;<i *ngIf="toolTip != ''" class="fas fa-info-circle inline" 
    [ngbTooltip]="toolTip"></i></label>
 <div class="input-group">
    <ng-select  
    [style]="style"
    [id]="idCampo"
    [name]="idCampo"
    [ngModelOptions]="{standalone: true}" 
    [items]="itens"
    bindLabel="label" 
    [clearable]="false" 
    bindValue="value" 
    (clear)="clear($event)"
    loadingText="Carregando..."
    notFoundText="Sem registros"
    [loading]="loading"
    [readonly]="disabled"
    (change)="changeValue()"
    [(ngModel)]="value"></ng-select>
</div>
