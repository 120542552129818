import { NotaFiscalDadosCobrancaModel } from "./notaFiscalDadosCobrancaModel";


export class NotaFiscalDadosCobrancaModelInstance {
    public static get(): NotaFiscalDadosCobrancaModel {
        return {
            idNotaFiscal: 0,
            numeroFatura: '',
            valorOriginalFatura: null,
            valorDesconto: null,
            valorLiquidoFatura: null,
            idNotaFiscalNavigation: null!,
            notaFiscalDadosCobrancaParcelas: []
        };
    }
}
