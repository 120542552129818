import swal from 'sweetalert2';
import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { ItemProdutoPdvModel } from 'src/app/models/pdv/produtoParaPdvModel';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Subscription } from 'rxjs';
import { ApiPdvService } from 'src/app/services/pdv/api-pdv.service';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { VendaProdutoModelInstance } from 'src/app/models/cliente/vendaProduto/vendaProdutoModelnstance';
import { InputNumber } from 'primeng/inputnumber';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { TokenModel } from 'src/app/models/controle/Autenticacao/TokenModel';
import { AppSettings } from 'src/app/app-settings';
import { LoginModel } from 'src/app/models/controle/Autenticacao/loginModel';
import { LoginModelInstance } from 'src/app/models/controle/Autenticacao/LoginModelInstance';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';
import { TiposMensagem } from 'src/app/models/tipos-mensagem';
import { ApiVendaService } from 'src/app/services/pdv/api-venda.service';
import { enumTipoCancelamento } from 'src/app/enum/enumTipoCancelamento';

@Component({
  selector: 'app-modal-cancelar-item',
  templateUrl: './modal-cancelar-item.component.html',
  styleUrls: ['./modal-cancelar-item.component.css']
})
export class ModalCancelarItemComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() removeuItem: EventEmitter<ItemProdutoPdvModel[]> = new EventEmitter();
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  loadingListarItens: boolean = false;
  tipoCancelamento: enumTipoCancelamento = enumTipoCancelamento.Produto;

  numeroItem: number | null = null;
  idMotivoCancelamento: number | null = null;
  itens: ItemProdutoPdvModel[] = [];

  constructor(private apiPdvService: ApiPdvService,
    private apiVendaService: ApiVendaService,
    private readonly mensagemService: AppMensagemService,
    private auth: AuthService,
    private showMessageError: ShowMessageError) { }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
  }
  get heightListaCliente(): string {
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  @ViewChild('elmNumeroItem') elmNumeroItem: InputNumber = null!;
  onFocusItem() {
    setTimeout(() => {
      if (this.elmNumeroItem != null) {
        this.elmNumeroItem.input.nativeElement.focus();
      }
    }, 60);
  }

  @ViewChild('elmUsuario') elmUsuario: ElementRef = null!;
  onFocusUsuario() {
    setTimeout(() => {
      if (this.elmUsuario != null) {
        this.elmUsuario.nativeElement.focus();
      }
    }, 60);
  }

  @ViewChild('elmCancelarItem') elmCancelarItem: ElementRef = null!;
  onFocusCancelarItem() {
    setTimeout(() => {
      if (this.elmCancelarItem != null) {
        this.elmCancelarItem.nativeElement.focus();
      }
    }, 60);
  }

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;
  }

  cliqueCancelar() {
    this.fecharModal();
  }

  tituloModal: string = '';
  ultimoItem: boolean = false;
  itensGrid: ItemProdutoPdvModel[] = [];
  abrirModal(itens: ItemProdutoPdvModel[], ultimoItem: boolean) {
    this.itemSelecionado = null!;
    this.numeroItem = null;
    if (itens == null || itens.length == 0) {
      swal.fire({
        title: "Atenção",
        text: `Não há itens para remover!`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          return;
          this.fecharModal();
        }
      });
      return;
    }

    this.itensGrid = [];
    this.ultimoItem = ultimoItem;
    if (ultimoItem) {
      this.tituloModal = 'Cancelar Último Item';
      this.numeroItem = itens[0].item;
      this.itensGrid.push(itens[0]);
    } else {
      this.itensGrid = itens;
      this.tituloModal = 'Cancelar Item';
    }

    this.exibirLoginUsuarioESenha = false;
    this.usuario = '';
    this.senha = '';
    this.indexItemInformado = 0;
    this.itens = itens;
    this.modal.show();

    if (ultimoItem) {
      this.onFocusCancelarItem();
    } else {
      this.onFocusItem();
    }
  }

  subsRemoverItemVendaProduto: Subscription = null!;
  indexItemInformado: number = 0;

  cliqueCancelarItem() {
    if (
      this.numeroItem == 0) {
      swal.fire({
        title: "Atenção",
        html: `Informe o número do item a ser cancelado!`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonText: 'Ok'
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        }
      });
    }
    else {

      this.indexItemInformado = this.itens.findIndex(f => f.item == this.numeroItem);
      if (this.indexItemInformado == -1) {
        swal.fire({
          title: "Atenção",
          html: `Item não encontrado!`,
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          }
        });
      } else {

        let vendaProduto = this.getItemVendaProduto();
        this.subsRemoverItemVendaProduto = this.apiVendaService.removerItemVendaProduto(vendaProduto, '', true).subscribe(
          () => {
            this.subsRemoverItemVendaProduto?.unsubscribe();
            swal.fire({
              title: "Sucesso",
              timer: 500,
              html: 'Item Removido!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              this.itens.splice(this.indexItemInformado, 1);
              this.removeuItem.emit(this.itens);
              this.fecharModal();
            });
          }
          ,
          (err) => {
            if (err.status == 403) {
              this.cancelarItemUsurioESenha();
            } else {
              this.subsRemoverItemVendaProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }

          });
      }
    }
  }

  getItemVendaProduto() {
    let indexItemInformado = this.indexItemInformado;
    let vendaProduto = VendaProdutoModelInstance.get();
    vendaProduto.idVenda = this.itens[indexItemInformado].idVenda;
    vendaProduto.idVendaProduto = this.itens[indexItemInformado].idVendaProduto;
    vendaProduto.idProdutoUnico = this.itens[indexItemInformado].idProdutoUnico;
    vendaProduto.idProdutoVariacao = this.itens[indexItemInformado].idProdutoVariacao;
    vendaProduto.detalhe = this.itens[indexItemInformado].nome;
    vendaProduto.precoUnitario = this.itens[indexItemInformado].precoVenda!;
    vendaProduto.numeroItem = this.itens[indexItemInformado].item;
    vendaProduto.quantidade = this.itens[indexItemInformado].quantidade;
    vendaProduto.valorProdutoServico = this.itens[indexItemInformado].subTotal;
    vendaProduto.dataPrevistaEntregaAgendada = this.itens[indexItemInformado].dataPrevistaEntregaAgendada;
    vendaProduto.indicaValorItemValorTot = this.itens[indexItemInformado].indicaValorItemValorTot;
    vendaProduto.numeroPedidoCompra = this.itens[indexItemInformado].numeroPedidoCompra;
    vendaProduto.quantidadeEntregueNoAto = this.itens[indexItemInformado].quantidadeEntregueNoAto;
    vendaProduto.quantidadeParaEntregaAgendada = this.itens[indexItemInformado].quantidadeParaEntregaAgendada;
    vendaProduto.quantidadeParaEntregaCrossdoking = this.itens[indexItemInformado].quantidadeParaEntregaCrossdoking!;
    vendaProduto.valorDesconto = this.itens[indexItemInformado].valorDesconto;
    vendaProduto.valorOutrasDespesas = this.itens[indexItemInformado].valorOutrasDespesas;
    vendaProduto.valorTotalFrete = this.itens[indexItemInformado].valorTotalFrete;
    vendaProduto.idMotivoCancelamento = this.idMotivoCancelamento;
    return vendaProduto;
  }

  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
    if (event.keyCode == 13 && !this.exibirLoginUsuarioESenha) {
      this.cliqueCancelarItem();
    }
    if (event.keyCode == 13 && this.exibirLoginUsuarioESenha) {
      this.confirmarUsuarioESenha();
    }
  }

  // onKeyDown(event: KeyboardEvent) {
  //   if (event.keyCode == 43 || event.keyCode == 27) {
  //     this.cliqueCancelar();
  //   }
  //   if (event.keyCode == 13 && !this.exibirLoginUsuarioESenha) {
  //     this.cliqueCancelarItem();
  //   }
  //   if (event.keyCode == 13 && this.exibirLoginUsuarioESenha) {
  //     this.confirmarUsuarioESenha();
  //   }
  // }

  itemSelecionado: ItemProdutoPdvModel = null!;  
  onRowSelecionarItem(event: any){
    let item = event.data as ItemProdutoPdvModel;
    this.numeroItem = item.item;
  }

  cancelarItemUsurioESenha() {
    this.exibirLoginUsuarioESenha = true;
    this.onFocusUsuario();
  }
  exibirLoginUsuarioESenha: boolean = false;
  usuario: string = '';
  senha: string = '';
  confirmarUsuarioESenha() {
    let sendLogin: LoginModel = LoginModelInstance.get();
    sendLogin.idAplicacao = AppSettings.ID_APLICACAO;
    sendLogin.emailCpf = this.usuario;
    sendLogin.senha = this.senha;

    this.auth.login(sendLogin).subscribe(
      (token: TokenModel) => {

        let vendaProduto = this.getItemVendaProduto();
        this.subsRemoverItemVendaProduto = this.apiVendaService.removerItemVendaProduto(vendaProduto, token.token, true).subscribe(
          () => {
            this.subsRemoverItemVendaProduto?.unsubscribe();
            swal.fire({
              title: "Sucesso",
              timer: 500,
              html: 'Item Removido!',
              icon: 'success',
              showCancelButton: false,
              confirmButtonText: 'Ok'
            }).then((result) => {
              this.itens.splice(this.indexItemInformado, 1);
              this.removeuItem.emit(this.itens);
              this.fecharModal();
            });
          }
          ,
          (err) => {
            if (err.status == 403) {

              //sem permissão, solicitar usuário e senha com permissão
              swal.fire('Aviso', "Usuário sem permissão para cancelar o item de compra", TiposMensagem.erro);
            } else {
              this.subsRemoverItemVendaProduto?.unsubscribe();
              this.showMessageError.exibirMensagemValidacaoTela(err);
            }

          });
      },
      (err) => {
        this.mensagemService.esconderLock();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  fecharModal() {
    this.fechouModal.emit(null);
    this.idMotivoCancelamento = null;
    this.numeroItem = null;
    this.modal.hide();
  }

}
