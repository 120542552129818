import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { OrcamentoProdutoModel } from 'src/app/models/cliente/OrcamentoProduto/orcamentoProdutoModel';
import { PrecoModel } from 'src/app/models/cliente/Preco/precoModel';
import { ProdutoModel } from 'src/app/models/cliente/Produto/produtoModel';
import { EstoqueModel } from 'src/app/models/cliente/estoque/estoqueModel';
import { ApiEstoqueService } from 'src/app/services/cliente/api-estoque.service';
import { ApiProdutoService } from 'src/app/services/cliente/api-produto.service';
import { ApiOrcamentoService } from 'src/app/services/pdv/api-orcamento-service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { SelectOptionService } from 'src/app/shared/services/select/select-option.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-orcamento-estoque',
  templateUrl: './modal-orcamento-estoque.component.html',
  styleUrls: ['./modal-orcamento-estoque.component.css']
})
export class ModalOrcamentoEstoqueComponent implements OnInit {

 
  @ViewChild(UiModalComponent) modal;
  inputDados: OrcamentoProdutoModel = null!;

  tituloModal: string = '';
  minDate: Date = new Date();
  subsConsultar: Subscription = null!

  constructor( 
    private showMessageError: ShowMessageError,
    private apiEstoqueService: ApiEstoqueService
    ) { }

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightLista(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  preco: PrecoModel = null!;
  subsGetProdutoById: Subscription = null!;
  estoqueNestaLoja: boolean = true;
  estoquesDestaLoja: EstoqueModel[]  = [];
  estoquesDemaisLojas: EstoqueModel[]  = [];
  getEstoqueByIdProdutoUnico(idProdutoUnico: number){
    this.subsGetProdutoById = this.apiEstoqueService.getByIdProdutoUnico(idProdutoUnico, true).subscribe(
      (retorno: EstoqueModel[]) =>{
        this.subsGetProdutoById?.unsubscribe();
        if (retorno != undefined) {
            this.estoquesDestaLoja = retorno.filter(f => f.idLoja == ConstantsService.IdLoja);
            this.estoquesDemaisLojas = retorno.filter(f => f.idLoja != ConstantsService.IdLoja);
        }
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetProdutoById?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  cliqueCancelar(){
    this.fecharModal();
  }
  inputDadosPreco: PrecoModel = null!;
  ehProduto: Boolean = true;
  tipoAcaoAbrirFecharCaixa: enumAcaoAbrirFecharCaixa = null!;
  tipoAcaoAbrirCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Abrir;
  tipoAcaoFecharCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Fechar;

  public abrirModal(item: OrcamentoProdutoModel) {
    this.preco = null!;
    this.estoqueNestaLoja = true;
    this.tituloModal = 'Estoque de Produto';
    this.inputDados = item;
    if (this.inputDados.idProduto.idProdutoUnicoNavigation.precos != null && this.inputDados.idProduto.idProdutoUnicoNavigation.precos.length == 1){
      this.preco = this.inputDados.idProduto.idProdutoUnicoNavigation.precos[0];
    }
    this.getEstoqueByIdProdutoUnico(item.idProdutoUnico);
    this.modal.show();
  }

  getFormatDecimal(number: number): string{
    if (number == null) return 'n/d';
    return new Intl.NumberFormat('pr-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  fecharModal() {
    this.modal.hide();
  }

  visualizarEstoqueOutrasLojas(){
    this.estoqueNestaLoja = false;
  }
  visualizarEstoqueNetaLoja(){
    this.estoqueNestaLoja = true;
  }

  loading: boolean = false;
}
