<div #modalAlerta >
    <div>
        <div style="text-align: right">
            <a (click)="buttonCancelar()"><i class="pi pi-times"></i></a>
        </div>
    </div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 text-center" style="padding-top:15%">
                <h4 class="text-center"><b>{{mensagem.titulo}}</b></h4>
            </div>
        </div>
        <div class="row" style="padding-top:15% ;padding-bottom: 30%">
            <div class="col-12 col-sm-12 col-md-12 text-center">
                <button type="button" pButton class="ui-button-success" style="margin-left: 5%"
                    value="{{mensagem.textoBtnSim}}" (click)="buttonOk()">
                </button>
            </div>
        </div>
    </div>
</div>