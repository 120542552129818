import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { MotivoCancelamentoModel } from 'src/app/models/cliente/MotivoCancelamento/motivoCancelamentoModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiMotivoCancelamentoService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Cliente;
    private version = 'api/'
    private controller = 'motivoCancelamento';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    getAll() {
        return this.gc.get(this.api, `${this.version}${this.controller}`, true);
    }

    getAllByTipoCancelamento(tipo: string){
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByTipoCancelamento/${tipo}`, true)
    }

    get(id: number){
        return this.gc.get(this.api, `${this.version}${this.controller}/${id}`, true)
    }

    // getByLojaUsuariLogado(exibeCarregando: boolean){
    //     return this.gc.get(this.api, `${this.version}${this.controller}/getByLojaUsuariLogado`, exibeCarregando)
    // }

    post(obj: MotivoCancelamentoModel){
        return this.gc.post(this.api, `${this.version}${this.controller}`, obj);
    }

    put(obj: MotivoCancelamentoModel){
        return this.gc.put(this.api, `${this.version}${this.controller}/${obj.idMotivoCancelamento}`, obj);
    }

    delete(id: number){
        return this.gc.delete(this.api, `${this.version}${this.controller}/${id}`);
    }
}