import { ProdutoModelInstance } from "../Produto/ProdutoModelInstance";
import { ProdutoVariacaoModel } from "./produtoVariacaoModel";


export class ProdutoVariacaoModelInstance {
    public static get(): ProdutoVariacaoModel {
        return {
            status: '',
            idProdutoVariacao: 0,
            idProdutoUnico: 0,
            nomeVariacao: '',
            sku: '',
            produtoProducaos: null!,
            idProdutoUnicoNavigation: ProdutoModelInstance.get(),
            estoques: null!,
            compraProdutos: null!,
            condicionalProdutos: null!,
            contratoProdutoServicos: null!,
            cotacaoProdutos: null!,
            cpfAlteracao: '',
            cpfRegistro: '',
            dataAlteracao: null,
            dataRegistro: null!,
            orcamentoProdutos: null!,
            ordemServicoProdutoServicos: null!,
            vendaEntregaProdutos: null!,
            comandaProdutos: null!,
            vendaProdutos: null!,
            notaFiscalProdServs: null!,
            produtoFornecedors: null!,
            pedidoVendaProdutos: null!,
            estoqueTransferenciaEntreLojaItems: [],
            produtoPacote: null!,
            produtoPacoteServicos: [],
            calendarioServicos: []
        };
    }

    
    public static getArray(): ProdutoVariacaoModel[] {
        return [this.get()];
    };
}
