import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { ChequeModel } from 'src/app/models/cliente/Cheque/ChequeModel';
import { FiltroControleChequeModel } from 'src/app/models/cliente/Cheque/FiltroControleChequeModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiChequeService {
    private gc: CommunicationManager;
    private api = AppSettings.API.Financeiro;
    private version = 'api/'
    private controller = 'cheque';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }   

    getByFiltroPesquisaCheques(filtro: FiltroControleChequeModel, exibeCarregando: boolean): Observable<ChequeModel[]> {
        return this.gc.post(this.api, `${this.version}${this.controller}/getByFiltroPesquisaCheques`, filtro, exibeCarregando);
    }

    cadastrarCheque(obj: ChequeModel, exibeCarregando: boolean) {
        return this.gc.post(this.api, `${this.version}${this.controller}/cadastrarCheque`, obj, exibeCarregando);
    }

    alterarCheque(obj: ChequeModel, idContaBancaria: number | null, exibeCarregando: boolean){
        return this.gc.put(this.api, `${this.version}${this.controller}/alterarCheque/${obj.idCheque}/${idContaBancaria}`, obj, exibeCarregando);
    }

    visualizarMovimentacoes(idCheque: number, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.version}${this.controller}/visualizarMovimentacoes/${idCheque}`, exibeCarregando);
    }
}