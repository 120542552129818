import { enumSimNao } from "src/app/enum/enumSimNao";
import { VendaProdutoModel } from "./vendaProdutoModel";


export class VendaProdutoModelInstance {
    public static get(): VendaProdutoModel {
        return {
            idVendaProduto: 0,
            idProdutoUnico: 0,
            idLoja: 0,
            idVenda: 0,
            idMotivoCancelamento: 0,
            idProdutoVariacao: 0,
            numeroItem: 0,
            detalhe: '',
            quantidade: 0,
            quantidadeEntregueNoAto: 0,
            dataPrevistaEntregaAgendada: null,
            quantidadeParaEntregaAgendada: 0,
            precoUnitario: 0,
            valorTotalFrete: 0,
            valorDesconto: 0,
            dataPrevistaParaEntregaCrossdoking: null,
            quantidadeParaEntregaCrossdoking: 0,
            valorOutrasDespesas: 0,
            valorProdutoServico: 0,
            valorTotal: 0,
            situacao: '',
            cpfRegistro: '',
            cpfAlteracao: '',
            idTabelaCfop: '',
            valorDescontoCondicionado: null,
            valorDescontoIncondicionado: null,
            idTabelaBTributacaoIcmsCstCson: '',
            idTabelaOrigemMercadoria: 0,
            movimentouEstoqueSaida: enumSimNao.Nao,
            custoUnitario: 0,
            unidadeMedidaComercial: '',
            unidadeMedidaTributavel: '',
            indicaValorItemValorTot: enumSimNao.Sim,
            itemPedidoCompra: 0,
            numeroPedidoCompra: '',
            dataRegistro: null!,
            dataAlteracao: null,
            idMotivoCancelamentoNavigation: null!,
            idProduto: null!,
            idProdutoUnicoNavigation: null!,
            idVendaNavigation: null!,
            idVendaVale: null!,
            idVendaValeNavigation: null!,
            quantidadeASerDevolvido: 0,
            quantidadeJaDevolvido: 0,
            emitiuNf: enumSimNao.Nao,
            custoTotal: 0,
            vendaCrossDockings: null!,
            numeroItemOrigem: null,
            valorSeguro: 0,
            vendaEntregaProdutos: null!,
            movimentouEstoqueVirtualSaida: enumSimNao.Nao,
            valorTotalSeguro: 0,
            observacao: '',
            alterouPrecoUnitario: enumSimNao.Nao,
            precoUnitarioBase: 0,
            valorTotalPromocaoConcedido: 0,
            idProdutoPrecoVariado: null,
            cpfCnpjComissaoPrestador:null!,
            cpfCnpjComissaoPrestadorNavigation: null!,
            cpfCnpjComissaoProfissional: null!,
            cpfCnpjComissaoProfissionalNavigation: null!,
            promocaoVendaProdutos: null!,
            valorComissaoPrestador: null,
            valorComissaoProfissional: null,
            unidadeTipoMedidaAltura: 0,
            unidadeTipoMedidaComprimento: 0,
            unidadeTipoMedidaLargura: 0,
            unidadeQuantidadeItem: 0,
            idComandaProduto: null,
            idComandaProdutoNavigation: null!
        };
    }
}
