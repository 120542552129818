import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalCrudParametrizacaoEnvioImpressoraComponent } from './modal-crud-parametrizacao-envio-impressora.component';
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { ComboPdvModule } from 'src/app/shared/business-component/combo-pdv/combo-pdv.module';

@NgModule({
  declarations: [
    ModalCrudParametrizacaoEnvioImpressoraComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ComboPdvModule
  ],
  exports: [
    ModalCrudParametrizacaoEnvioImpressoraComponent,
    CommonModule,
    SharedModule
  ]
})
export class ModalCrudParametrizacaoEnvioImpressoraModule { }
