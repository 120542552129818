import { Component, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { PedidoVendaProdutoModel } from 'src/app/models/cliente/PedidoVendaProduto/pedidovendaProdutoModel';
import { PrecoModel } from 'src/app/models/cliente/Preco/precoModel';
import { EstoqueModel } from 'src/app/models/cliente/estoque/estoqueModel';
import { ApiEstoqueService } from 'src/app/services/cliente/api-estoque.service';
import { ConstantsService } from 'src/app/shared/constants/constants.service';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';

@Component({
  selector: 'app-modal-pedido-venda-estoque',
  templateUrl: './modal-pedido-venda-estoque.component.html',
  styleUrls: ['./modal-pedido-venda-estoque.component.css']
})
export class ModalPedidoVendaEstoqueComponent implements OnInit {

 
  @ViewChild(UiModalComponent) modal;
  inputDados: PedidoVendaProdutoModel = null!;

  tituloModal: string = '';
  minDate: Date = new Date();
  subsConsultar: Subscription = null!

  constructor( 
    private showMessageError: ShowMessageError,
    private apiEstoqueService: ApiEstoqueService
    ) { }

  ngOnInit(): void {
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightLista(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  preco: PrecoModel = null!;
  subsGetProdutoById: Subscription = null!;
  estoqueNestaLoja: boolean = true;
  estoquesDestaLoja: EstoqueModel[]  = [];
  estoquesDemaisLojas: EstoqueModel[]  = [];
  getEstoqueByIdProdutoUnico(idProdutoUnico: number){
    this.subsGetProdutoById = this.apiEstoqueService.getByIdProdutoUnico(idProdutoUnico, true).subscribe(
      (retorno: EstoqueModel[]) =>{
        this.subsGetProdutoById?.unsubscribe();
        if (retorno != undefined) {
            this.estoquesDestaLoja = retorno.filter(f => f.idLoja == ConstantsService.IdLoja);
            this.estoquesDemaisLojas = retorno.filter(f => f.idLoja != ConstantsService.IdLoja);
        }
        this.loading = false;
      }
      ,
      (err) => {
        this.loading = false;
        this.subsGetProdutoById?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }
  descricaoProdutoServico: string = '';
  cliqueCancelar(){
    this.fecharModal();
  }
  inputDadosPreco: PrecoModel = null!;
  ehProduto: Boolean = true;
  tipoAcaoAbrirFecharCaixa: enumAcaoAbrirFecharCaixa = null!;
  tipoAcaoAbrirCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Abrir;
  tipoAcaoFecharCaixa: enumAcaoAbrirFecharCaixa = enumAcaoAbrirFecharCaixa.Fechar;

  public abrirModal(item: PedidoVendaProdutoModel) {
    this.preco = null!;
    this.estoqueNestaLoja = true;
    this.tituloModal = 'Estoque de Produto';
    this.inputDados = item;
    // if (this.inputDados.idProduto.idProdutoUnicoNavigation.precos != null && this.inputDados.idProduto.idProdutoUnicoNavigation.precos.length == 1){
    //   this.preco = this.inputDados.idProduto.idProdutoUnicoNavigation.precos[0];
    // }
    this.getEstoqueByIdProdutoUnico(item.idProdutoUnico);

    //tratar o nome do produto
    this.descricaoProdutoServico = '';
    if(this.inputDados.idProdutoUnicoNavigation.gtin != '' && this.inputDados.idProdutoUnicoNavigation.gtin != null && 
      this.inputDados.idProdutoUnicoNavigation.sku != '' && this.inputDados.idProdutoUnicoNavigation.sku != null && 
      ConstantsService.DescricaoProdutoImprimeGtin == enumSimNao.Sim && ConstantsService.DescricaoProdutoImprimeSku == enumSimNao.Sim)
    {
        this.descricaoProdutoServico = this.inputDados.idProdutoUnicoNavigation.gtin +" - "+ this.inputDados.idProdutoUnicoNavigation.sku +" - "+ this.inputDados.detalhe;
    }
    else
    if(ConstantsService.DescricaoProdutoImprimeGtin == enumSimNao.Sim &&
      this.inputDados.idProdutoUnicoNavigation.gtin != '' && this.inputDados.idProdutoUnicoNavigation.gtin != null)
    {
      this.descricaoProdutoServico = this.inputDados.idProdutoUnicoNavigation.gtin +" - "+ this.inputDados.detalhe;
    }
    else
    if(ConstantsService.DescricaoProdutoImprimeSku == enumSimNao.Sim &&
      this.inputDados.idProdutoUnicoNavigation.sku != '' && this.inputDados.idProdutoUnicoNavigation.sku != null)
    {
      this.descricaoProdutoServico = this.inputDados.idProdutoUnicoNavigation.sku +" - "+ this.inputDados.detalhe;
    }
    else
    {
      this.descricaoProdutoServico = this.inputDados.detalhe;
    }
    //final tratamento nome
    
    this.modal.show();
  }

  getFormatDecimal(number: number): string{
    if (number == null) return 'n/d';
    return new Intl.NumberFormat('pr-BR',{style: 'currency', currency:'BRL'}).format(number);
  }

  fecharModal() {
    this.modal.hide();
  }

  visualizarEstoqueOutrasLojas(){
    this.estoqueNestaLoja = false;
  }
  visualizarEstoqueNetaLoja(){
    this.estoqueNestaLoja = true;
  }

  loading: boolean = false;
}
