export enum enumModuloAnexo {
  Contrato = 'Contrato',
  ContratoPlano = 'ContratoPlano',
  Cotacao = 'Cotacao',
  PedidoCompra = 'PedidoCompra',
  ProdutoImagem = 'ProdutoImagem',
  ProdutoAnexo = 'ProdutoAnexo',
  NotaFiscal = 'NotaFiscal',
  Boleto = 'Boleto',
  EstoqueEntradaSaida = 'EstoqueEntradaSaida',
  TransfEntreContas = 'TransfEntreContas',
  OrdemServico = 'OrdemServico',
  WhatsAppMensagemAutomatica = "WhatsAppMensagemAutomatica",
  WhatsAppTemplateMensagem = "WhatsAppTemplateMensagem",
  Calendario = "Calendario",
  Orcamento = "Orcamento",
  PedidoVenda = "PedidoVenda",
  RotaEntrega = "RotaEntrega",
  VendaEntrega = "VendaEntrega"
  }