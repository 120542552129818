<app-ui-modal #modal [containerClick]="false" (close)="cliqueFechar()" [dialogClass]="'modal-xl'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="far fa-edit icon-header-modal"></i>&nbsp;Alterar Item [{{descricaoProdutoServico}}]</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" *ngIf="inputDados != null" >
        <div class="row" *ngIf="inputDados.idProdutoUnicoNavigation != null">
            <div class="col-9">
                <!-- <div class="form-group"> -->
                    <input maxlength="120" class="form-control" [disabled]="bloquearCampos"
                    id="detalhe" name="detalhe" type="text"
                    placeholder="Digite o Nome do Item" [(ngModel)]="inputDados.detalhe">                    
                    <!-- &nbsp;<i class="fas fa-info-circle inline" ngbTooltip="Se ajustado o nome, esse será o impresso no recibo, na venda, nota fiscal(caso for emitir). Não será alterado no cadastro. Máximo 120 caracteres."></i> -->
                <!-- </div> -->
            </div>
        </div>
        <div class="row ">
            <hr>
        </div>
        <div class="row" *ngIf="preco != null && (inputDados.idProdutoUnicoNavigation?.habilitaCalculoUnidadeMedida == 'N' || inputDados.idProdutoUnicoNavigation?.habilitaCalculoUnidadeMedida == null)">
            <div class="col-3">
                <div class="form-group">
                    <label *ngIf="inputDados.movimentouEstoque == jaMovimentouEstoque  || inputDados.movimentouEstoqueVirtualSaida == jaMovimentouEstoque" class="form-label uppercase" for="quantidadeEntregueNoAto"><b>Quantidade</b>&nbsp;
                        <i class="fas fa-info-circle" ngbTooltip="Este item já movimentou o estoque. Se deseja alterar a quantidade, remova e adicione novamente com quantidade correta."></i>
                    </label>
                    <label *ngIf="inputDados.movimentouEstoque != jaMovimentouEstoque  && inputDados.movimentouEstoqueVirtualSaida != jaMovimentouEstoque" class="form-label uppercase" for="quantidadeEntregueNoAto"><b>Quantidade</b>&nbsp;
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i>
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidade" [min]="1" #elmQuantidade
                    (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="quantidade" mode="decimal" [minFractionDigits]="2"  (ngModelChange)="changeQuantidade($event)" (onBlur)="atualizarCalculos()" (onFocus)="onFoucus($event)"
                      (keypress)="keyPress($event)"
                      [maxFractionDigits]="3" [disabled]="bloquearCampos || inputDados.movimentouEstoque == jaMovimentouEstoque  || inputDados.movimentouEstoqueVirtualSaida == jaMovimentouEstoque">
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label class="form-label uppercase" for="precoVenda">Valor Unitário
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="preco.precoVenda" suffix="" prefix="R$ " (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="precoVenda" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2" [disabled]="!permiteAlterarPrecoVenda || bloquearCampos">
                    </p-inputNumber>
                </div>
                <!-- <h4 class="sub-titulo">
                    Preço Unitário <b>{{preco.precoVenda | currency:'BRL' }}</b>
                </h4> -->
            </div>
            <div class="col-3">
                <h4 class="sub-titulo">
                    Sub Total <b>{{(inputDados.quantidade! * preco.precoVenda!) | currency:'BRL' }}</b>
                </h4>
            </div>
            <div class="col-3">
                <h4 class="sub-titulo">
                    Total <strong>R$ {{ ((inputDados.quantidade! * preco.precoVenda!) + inputDados.valorOutrasDespesas! 
                        + inputDados.valorTotalFrete! + inputDados.valorTotalSeguro!
                        - inputDados.valorDesconto!)  | number:'1.2-5' }}</strong>
                </h4>
            </div>
        </div>
        <div class="row" *ngIf="preco != null && inputDados.idProdutoUnicoNavigation?.habilitaCalculoUnidadeMedida == 'S'">
            <div class="col-2" *ngIf="inputDados.unidadeMedidaComercial == unMedMetro || inputDados.unidadeMedidaComercial == unMedMetroQuadrado || inputDados.unidadeMedidaComercial == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeQuantidadeItem">Quantidade
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeQuantidadeItem"
                      (ngModelChange)="changeQtdeItemPorMetro($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeQuantidadeItem" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2" *ngIf="inputDados.unidadeMedidaComercial == unMedMetro || inputDados.unidadeMedidaComercial == unMedMetroQuadrado || inputDados.unidadeMedidaComercial == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeTipoMedidaComprimento">Comprimento
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeTipoMedidaComprimento"
                      (ngModelChange)="changeComprimento($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeTipoMedidaComprimento" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2" *ngIf="inputDados.unidadeMedidaComercial == unMedMetroQuadrado || inputDados.unidadeMedidaComercial == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeTipoMedidaLargura">Largura
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeTipoMedidaLargura"
                      (ngModelChange)="changeLargura($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeTipoMedidaLargura" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2" *ngIf="inputDados.unidadeMedidaComercial == unMedMetroCubico">
                <div class="form-group">
                    <label class="form-label uppercase" for="unidadeTipoMedidaAltura">Altura
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.unidadeTipoMedidaAltura"
                      (ngModelChange)="changeAltura($event)" (onFocus)="onFoucus($event)" (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="unidadeTipoMedidaAltura" mode="decimal" [minFractionDigits]="2" 
                      [maxFractionDigits]="3" >
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label *ngIf="inputDados.movimentouEstoque == jaMovimentouEstoque  || inputDados.movimentouEstoqueVirtualSaida == jaMovimentouEstoque" class="form-label uppercase" for="quantidadeEntregueNoAto"><b>Total em Metros</b>&nbsp;
                        <i class="fas fa-info-circle" ngbTooltip="Este item já movimentou o estoque. Se deseja alterar a quantidade, remova e adicione novamente com quantidade correta."></i>
                    </label>
                    <label *ngIf="inputDados.movimentouEstoque != jaMovimentouEstoque  && inputDados.movimentouEstoqueVirtualSaida != jaMovimentouEstoque" class="form-label uppercase" for="quantidadeEntregueNoAto">Total em Metros&nbsp;
                        <!-- <i class="fas fa-info-circle" ngbTooltip="Quantidade Total"></i> -->
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidade" [min]="1" #elmQuantidade
                    (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="quantidade" mode="decimal" [minFractionDigits]="2"  (ngModelChange)="changeQuantidade($event)" (onBlur)="atualizarCalculos()" (onFocus)="onFoucus($event)"
                      (keypress)="keyPress($event)"
                      [maxFractionDigits]="3" [disabled]="bloquearCampos || inputDados.movimentouEstoque == jaMovimentouEstoque  || inputDados.movimentouEstoqueVirtualSaida == jaMovimentouEstoque">
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="precoVenda">Valor Unitário
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="preco.precoVenda" suffix="" prefix="R$ " (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="precoVenda" mode="decimal" [minFractionDigits]="2"
                        [maxFractionDigits]="2" [disabled]="!permiteAlterarPrecoVenda || bloquearCampos">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <h4 class="sub-titulo">
                    Sub Total <b>{{(inputDados.quantidade! * preco.precoVenda!) | currency:'BRL' }}</b>
                </h4>
            </div>
            <div class="col-2">
                <h4 class="sub-titulo">
                    Total <strong>R$ {{ ((inputDados.quantidade! * preco.precoVenda!) + inputDados.valorOutrasDespesas! 
                        + inputDados.valorTotalFrete! + inputDados.valorTotalSeguro!
                        - inputDados.valorDesconto!)  | number:'1.2-5' }}</strong>
                </h4>
            </div>
        </div>
        <div class="row ">
            <hr>
        </div>
        <div class="row" >            
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidadeEntregueNoAto">Qtde. Entr. no Ato
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade Entregue no Ato"></i>
                    </label>
                    
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidadeEntregueNoAto"  (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="quantidadeEntregueNoAto" mode="decimal" [minFractionDigits]="2"  [disabled]="true"
                      [maxFractionDigits]="2" [disabled]="bloquearCampos">
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidadeParaEntregaAgendada">Qtde. Agendada
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade agendada para ser entregue posteriormente"></i>
                    </label>
                    <!-- || inputDados.movimentouEstoque == jaMovimentouEstoque  || inputDados.movimentouEstoqueVirtualSaida == jaMovimentouEstoque -->
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidadeParaEntregaAgendada" (ngModelChange)="changeQuantidadeEntregueAgendada($event)"
                    (keypress)="keyPress($event)"
                      locale="pt-BR" inputId="quantidadeParaEntregaAgendada" mode="decimal" [minFractionDigits]="2"  (onFocus)="onFoucus($event)"
                      [maxFractionDigits]="2" [disabled]="!permiteVendaEntrega || bloquearCampos">
                    </p-inputNumber>
                  </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="dataPrevistaEntregaAgendada">Data Entr. Agendada
                        <i class="fas fa-info-circle" ngbTooltip="Data previsto para a Entrega do Produto"></i>
                    </label>
                    <p-calendar [(ngModel)]="inputDados.dataPrevistaEntregaAgendada" name="dataPrevistaEntregaAgendada" [readonlyInput]="false"
                    dateFormat="dd/mm/yyyy" [showButtonBar]="true" dateFormat="dd/mm/yy"
                    id="dataPrevistaEntregaAgendada" [monthNavigator]="true" [yearNavigator]="true" [disabled]="bloquearCampos || inputDados.quantidadeParaEntregaAgendada <= 0">
                    </p-calendar>
                </div>
            </div>
            <!-- <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="quantidadeParaEntregaCrossdoking">Qtde. Crossdocking
                        <i class="fas fa-info-circle" ngbTooltip="Quantidade fornecida sem estar no estoque físico"></i>
                    </label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.quantidadeParaEntregaCrossdoking"  (ngModelChange)="changeQuantidadeCrossDocking($event)"
                      locale="pt-BR" inputId="quantidadeParaEntregaCrossdoking" mode="decimal" [minFractionDigits]="2"
                      [maxFractionDigits]="2" >
                    </p-inputNumber>
                  </div>
            </div> -->
            <div class="col-md-2" *ngIf="permiteValorSeguro == 'S'">
                <div class="form-group">
                    <label class="form-label uppercase" for="valorSeguro">Valor Seguro</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorSeguro" suffix="" prefix="R$ "  (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorSeguro" mode="decimal" [minFractionDigits]="2" (onBlur)="atualizarCalculos()"
                        [maxFractionDigits]="2" [disabled]="true">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-md-2" *ngIf="permiteValorSeguro == 'S'">
                <div class="form-group text-left">
                    <label class="form-label uppercase" for="valorTotalSeguro">Valor Total Seguro</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalSeguro" suffix="" prefix="R$ "  (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)" [disabled]="bloquearCampos"
                        currency="BRL" locale="pt-BR" inputId="valorTotalSeguro" mode="decimal" [minFractionDigits]="2" (onBlur)="atualizarCalculos()"
                        [maxFractionDigits]="2">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-md-2" >
                <div class="form-group">
                    <label class="form-label uppercase" for="valorTotalFrete">Valor Frete</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorTotalFrete" suffix="" prefix="R$ "  (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorTotalFrete" mode="decimal" [minFractionDigits]="2" (onBlur)="atualizarCalculos()"
                        [maxFractionDigits]="2" [disabled]="bloquearCampos">
                    </p-inputNumber>
                </div>
            </div>
        <!-- </div>
        <div class="row" > -->
            <div class="col-md-2" >
                <div class="form-group">
                    <label class="form-label uppercase" for="valorOutrasDespesas">Outras Despesas</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorOutrasDespesas" suffix="" prefix="R$ "  (onFocus)="onFoucus($event)"
                        (keypress)="keyPress($event)"
                        currency="BRL" locale="pt-BR" inputId="valorOutrasDespesas" mode="decimal" [minFractionDigits]="2" (onBlur)="atualizarCalculos()"
                        [maxFractionDigits]="2" [disabled]="bloquearCampos">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="valorTotalFrete">Desconto Máximo</label>
                    <div class="input-group ">
                        <input type="text" class="form-control" disabled="true" placeholder="?"  
                            [(ngModel)]="vDescontoMaximo">
                        <span class="input-group-text" id="basic-addon2">
                            <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirDescontoMaximo" (click)="descontoMaximo()"></i>
                            <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirDescontoMaximo" (click)="descontoMaximo()"></i>
                        </span>
                  </div>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <label class="form-label uppercase" for="valorDesconto">Desconto</label>
                    <p-inputNumber inputStyleClass="form-control" [maxlength]="15" [(ngModel)]="inputDados.valorDesconto" suffix="" prefix="R$ "  (onFocus)="onFoucus($event)"
                    (keypress)="keyPress($event)"
                         [max]="valorDesconto" 
                        currency="BRL" locale="pt-BR" inputId="valorDesconto" mode="decimal" [minFractionDigits]="2" (onBlur)="atualizarCalculos()"
                        [maxFractionDigits]="2" [disabled]="bloquearCampos">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-12 ">
                <div class="form-group">
                    <label for="dataExecucao" class="form-label uppercase">Nº Pedido de Compra</label>
                    <input maxlength="15" class="form-control input-md numeroPedidoCompra " id="numeroPedidoCompra" name="numeroPedidoCompra"  (onFocus)="onFoucus($event)"
                        type="text" placeholder="Ped. de Compra"
                        [(ngModel)]="inputDados.numeroPedidoCompra" [disabled]="bloquearCampos">
                </div>
            </div>
            <div class="col-md-2 pt-3">
                <div class="form-group">
                    <div class="form-check">
                        <input type="checkbox" class="form-check-input mouse-pointer"
                        (change)="onChangeIndicaValorItemValorTot($event)" [checked]="inputDados.indicaValorItemValorTot == 'S'"
                        id="indicaValorItemValorTot" [disabled]="bloquearCampos">
                        <label class="form-check-label  mouse-pointer" for="indicaValorItemValorTot">Contabilizado Total&nbsp;<i class="fas fa-info-circle"
                            ngbTooltip="Contabilizado no Total da Nota Fiscal? Obs.: é obrigatório estar selecionado caso for emitir notas do tipo NFC-e e NFS-e, e se for gerar venda o valor total deste item não será contabilizado no pagamento."></i></label>
                    </div>
                    <div *ngIf="mensagemIndicaValorItemValorTot != ''">
                        <label class="form-label uppercase text-c-red">{{mensagemIndicaValorItemValorTot}}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label class="d-inline form-check-label mouse-pointer uppercase form-label" for="detalheImpressao">Observação</label>&nbsp;
                <i class="fas fa-info-circle"
                ngbTooltip="Alguma observação que deseja inserir para este item. Poderá ser impresso caso deseja, basta permitir na configuração de impressão."></i>
                <textarea maxlength="120" style="height:fit-content" class="form-control input-md detalheImpressao class-npe" id="detalheImpressao" name="detalheImpressao"
                    type="text"  placeholder="Observação..." [rows]="1"
                    [(ngModel)]="inputDados.detalheImpressao"></textarea>
            </div>
        </div>
      
        <div class="row pt-2" >
            <div class="col-5 " >
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Custos</span>
                        <button class="btn" (click)="showTotais = !showTotais">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showTotais"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showTotais"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showTotais">
                        <li>
                            Custo Unitário <strong>R$ {{ preco.precoMargemZero! | number:'1.2-5' }}</strong>
                        </li>
                        <li>
                            Custo Unitário com Frete/Despesas/Descontos <strong>R$ {{ preco.precoMargemZero! + inputDados.valorTotalFrete!/inputDados.quantidade! + inputDados.valorTotalSeguro!/inputDados.quantidade! + inputDados.valorOutrasDespesas!/inputDados.quantidade! + inputDados.valorDesconto!/inputDados.quantidade! | number:'1.2-5' }}</strong>
                        </li>
                        <li>
                            Custo Total <strong>R$ {{ inputDados.quantidade! * preco.precoMargemZero! | number:'1.2-5' }}</strong>
                        </li>
                        <li>
                            Custo Total com Frete/Despesas/Descontos <strong>R$ {{ ((inputDados.quantidade! * preco.precoMargemZero!) + inputDados.valorOutrasDespesas! 
                                + inputDados.valorTotalFrete! + inputDados.valorTotalSeguro!
                                - inputDados.valorDesconto!)  | number:'1.2-5' }} </strong>
                        </li>
                        <!-- <li>
                            Custo 
                                <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirCusto" (click)="cliqueExibirCusto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirCusto" (click)="cliqueExibirCusto()"></i>
                                &nbsp; <strong *ngIf="exibirCusto">{{ vCusto }} </strong> -->
                                
                                <!-- <div class="form-group text-center">
                                    <label class="form-label uppercase" for="valorTotalFrete">Custo</label>
                                    <div class="input-group ">
                                        <input type="text" class="form-control" disabled="true" placeholder="?"  
                                            [(ngModel)]="vCusto">
                                        <span class="input-group-text" id="basic-addon2">
                                            <i class="mouse-pointer feather text-c-blue icon-eye f-20 " *ngIf="!exibirCusto" (click)="cliqueExibirCusto()"></i>
                                            <i class="mouse-pointer feather text-c-blue icon-eye-off f-20" *ngIf="exibirCusto" (click)="cliqueExibirCusto()"></i>
                                        </span>
                                </div>
                                </div> -->
                        <!-- </li> -->
                    </ul>
                </div>
            </div>
            <div class="col-4 " >
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Margem</span>
                        <button class="btn" (click)="showMargem = !showMargem">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showMargem"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showMargem"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showMargem">
                        <li>
                            Margem Sobre o Custo Sem Desconto 
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i> -->
                                &nbsp; <strong >{{ vMargemSobreCustoSemDesconto }} </strong>
                        </li>
                        <li>
                            Margem Sobre o Custo Com Desconto 
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i> -->
                                &nbsp; <strong >{{ vMargemSobreCustoComDesconto }} </strong>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-3 " >
                <div class="atalhos">
                    <h3>
                        <span class="uppercase">Log</span>
                        <button class="btn" (click)="showLog = !showLog">
                            <i class="far fa-chevron-up mouse-pointer" *ngIf="showLog"></i>
                            <i class="far fa-chevron-down mouse-pointer" *ngIf="!showLog"></i>
                        </button>
                    </h3>
                    <ul class="" *ngIf="showLog && inputDados != null">
                        <li>
                            Data Registro
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i> -->
                                &nbsp; <strong >{{ inputDados.dataRegistro  }} </strong>
                        </li>
                        <li>
                            Cpf Registro
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i> -->
                                &nbsp; <strong >{{ inputDados.cpfRegistro | cpfCnpj }} </strong>
                        </li>
                        <li>
                            Data Alteração
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoSemDesconto" (click)="cliqueExibirMargemSobreCustoSemDesconto()"></i> -->
                                &nbsp; <strong >{{ inputDados.dataAlteracao  }} </strong>
                        </li>
                        <li>
                            Cpf Alteração
                                <!-- <i class="mouse-pointer feather text-c-blue icon-eye f-20 pt-1 " *ngIf="!exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i>
                                <i class="mouse-pointer feather text-c-blue icon-eye-off  pt-1 f-20" *ngIf="exibirMargemSobreCustoComDesconto" (click)="cliqueExibirMargemSobreCustoComDesconto()"></i> -->
                                &nbsp; <strong >{{ inputDados.cpfAlteracao | cpfCnpj }} </strong>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button type="button" (click)="cliqueFechar()" 
            class="btn btn-secondary inline"><i class="fad fa-window-close"></i> Fechar</button>&nbsp;
        <button *ngIf="!bloquearCampos" type="button" (click)="cliqueAlterarItem()"
            class="btn btn-success inline"><i class="fad fa-edit"></i> Alterar</button>&nbsp;
    </div>
</app-ui-modal>
