import { Component, OnInit, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Toast } from 'primeng/toast';
import { ToastConfig } from 'src/app/models/toast-config';
import { NgZone } from '@angular/core';

@Component({
  selector: 'app-custom-toast',
  templateUrl: './app-custom-toast.component.html',
  styleUrls: ['./app-custom-toast.component.scss']
})
export class AppCustomToastComponent implements OnInit {

  @ViewChild('toast') toast!: Toast;
  listErrors = Array<string>();

  constructor(
    private messageService: MessageService,
    private ngZone: NgZone
  ) { }

  ngOnInit() {

  }

  exibirToast(config: ToastConfig) {
    this.ngZone.run(() => {

      if (config.duracao == null)
        config.duracao = 2000;
      if (config.limpar && this.messageService != null)
        this.messageService.clear();
        for (let i = 0; i < config.mensagem.length; i++) {
          this.messageService.add({sticky:false, severity: config.tipo, summary:config.titulo, detail:config.mensagem[i],
            life: config.duracao
            });
        }

      // config.mensagem.forEach(function(mensagem){
        
      // });

      // this.messageService.add({severity: config.tipo, summary:config.titulo, detail:"config.mensagem",
      //      life: config.duracao
      //      });

      // this.messageService.add({severity:'success', summary:'Service Message', detail:'Via MessageService'});
      
  });

    setTimeout(() => {
      
    }, 1000);
    
    // this.ngZone.run(() => {
    //   if (!config.limpar) {
    //     this.messageService.clear();
    //   }
    //   this.listErrors = config.mensagem;
    //   this.messageService.add({
    //     key: 'apptoastKey1',
    //     severity: config.tipo,
    //     summary: config.titulo,
    //     detail: '',
    //     life: config.duracao
    //   });
    // });
  }

  limparToasts() {
    this.messageService.clear('appCustomToast');
  }
}
