<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header uppercase">&nbsp;<i class="far fa-money-bill-alt"></i>&nbsp;Informações do Cheque</h4>
            </div>
        </div>      
    </div>

    <div class="app-modal-body">
        <div class="row">
            <div class="col-12 col-md-4">
                <div class="form-group">
                    <label class="form-label" for="valor">Número do Cheque</label>
                    <input maxlength="50" class="form-control input-md" id="numeroCheque" [disabled]="modoTela == modoTelaVisualizar"
                        name="numeroCheque" type="text" placeholder="" [(ngModel)]="inputDados.numeroCheque"
                    >
                    <!-- <p-inputNumber inputStyleClass="form-control p-t-10" [maxlength]="50" [(ngModel)]="inputDados.numeroCheque" [min]="0" 
                        locale="pt-BR" [inputId]="'numeroCheque'">
                    </p-inputNumber> -->                    
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label class="form-label" for="valor">Valor</label>
                    <p-inputNumber inputStyleClass="form-control p-t-10" [maxlength]="15" [(ngModel)]="inputDados.valor" [min]="0" prefix="R$ " 
                        currency="BRL" locale="pt-BR" [minFractionDigits]="2" [maxFractionDigits]="2" [inputId]="'valor'" (onFocus)="onFoucus($event)"
                        [disabled]="modoTela == modoTelaVisualizar">
                    </p-inputNumber>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label class="form-label" for="dataVencimento">Data de Vencimento</label>
                    <p-calendar 
                        appendTo="body" [baseZIndex]="1050" [disabled]="modoTela == modoTelaVisualizar"
                        [(ngModel)]="inputDados.dataVencimento" name="dataVencimento"
                        [readonlyInput]="false" dateFormat="dd/mm/yy" [id]="'dataVencimento'"
                        [monthNavigator]="true" [yearNavigator]="true">
                    </p-calendar>
                </div>
            </div>
            <div class="col-12 col-md-3">
                <div class="form-group">
                    <label class="form-label">Status</label>
                    <select id="statusMovimentacao" class="form-control form-control-warning" [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="inputDados.statusMovimentacao" [disabled]="modoTela != modoTelaAlterar || !salvarAlteracoes">
                        <option *ngFor="let item of statusMovimentacaoCheque;" [ngValue]="item.value">{{item.label }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <!-- <div class="col-12 col-md-4" *ngIf="inputDados.statusMovimentacao == enumStatusCompensado && modoTela == modoTelaAlterar">
                <div class="form-group">
                    <app-combo-conta-bancaria [(ngModel)]="idContaBancaria"
                    [disabled]="modoTela == modoTelaVisualizar"
                    [toolTip]="'Conta Bancária para realizar movimentação financeira'">
                    </app-combo-conta-bancaria>
                </div>
            </div> -->
            <div class="col-12 col-md-6">
                <div class="form-group">
                    <label class="form-label" for="nomeEmitente">Nome Emitente</label>
                    <input maxlength="60" class="form-control input-md" id="nomeEmitente"
                        name="nomeEmitente" type="text" placeholder="Digite o Nome"
                        [disabled]="modoTela == modoTelaVisualizar"
                        [(ngModel)]="inputDados.nomeEmitente">
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label class="form-label" for="telefone">Telefone</label>
                    <p-inputMask [mask]="maskTeleAreaCel" [autoClear]="false" [styleClass]="'form-control input-md'"
                        [disabled]="modoTela == modoTelaVisualizar" [inputId]="'telefone'" name="telefone" placeholder="Digite o Telefone" 
                        [(ngModel)]="inputDados.telefone">
                    </p-inputMask>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6 p-t-5">
                <div class="form-group">
                    <app-combo-banco-financeiro [(ngModel)]="inputDados.banco"
                        [disabled]="modoTela == modoTelaVisualizar">
                    </app-combo-banco-financeiro>
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label class="form-label" for="agencia">Agência</label>
                    <!-- <i class="fas fa-info-circle" ngbTooltip="Informe o número da agência com o dígito verificador"></i> -->
                    <input maxlength="20" class="form-control input-md agencia" id="agencia" name="agencia"
                        type="text" [(ngModel)]="inputDados.agencia" [disabled]="modoTela == modoTelaVisualizar">
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label class="form-label" for="conta">Conta</label>
                    <input maxlength="20" class="form-control input-md " id="conta" name="conta" type="text"
                        [(ngModel)]="inputDados.conta" [disabled]="modoTela == modoTelaVisualizar">
                </div>
            </div>
            <div class="col-12 col-md-2">
                <div class="form-group">
                    <label class="form-label" for="contaDac">Dig. Verificador</label>
                    <!-- <i class="fas fa-info-circle" ngbTooltip="Informe o dígito verificador da conta"></i> -->
                    <input maxlength="2" class="form-control input-md" id="dac" name="dac"
                        type="text" [(ngModel)]="inputDados.dac" [disabled]="modoTela == modoTelaVisualizar">
                </div>
            </div>
        </div>
        <div class="row">            
            <div class="col-12 col-md-8">
                <div class="form-group">
                    <label class="form-label" for="observacao">Observação</label>
                    <textarea maxlength="60" class="form-control input-md" id="observacao" name="observacao"
                        type="text" rows="1" [disabled]="modoTela == modoTelaVisualizar"
                        placeholder="Digite a observação..."
                        [(ngModel)]="inputDados.observacao">
                    </textarea>
                </div>
            </div>
        </div>

    </div>
    
    <div class="app-modal-footer">
        <div style="display: FLEX;">
            <div class="grupo-btn-rodape">
                <button  (click)="voltar()" type="button" 
                    class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Voltar
                </button>&nbsp;
            </div>
            <div class="grupo-btn-rodape" *ngIf="modoTela != modoTelaVisualizar">
                <button  (click)="salvarCheque()" type="button"
                    class="btn btn-success inline"><i class="fad fa-save"></i> Salvar
                </button>
            </div>
        </div>
    </div>
</app-ui-modal>