import { PedidoVendumModel } from "./PedidoVendumModel";

export class PedidoVendumModelInstance {
    public static get(): PedidoVendumModel {
        return {
            idPedidoVenda: 0,
            idTabelaStatusPedidoVenda: null,
            cpfCnpj: '',
            idLoja: 0,
            idPdv: null,
            valorDespesaCondicaoPagamento: null,
            valorJurosCondicaoPagamento: null,
            valorDescontoCondicaoPagamento: null,
            valorDescontoVendedor: null,
            valorTotalDescontoVendaProduto: 0,
            valorTotalFreteVendaProduto: 0,
            valorTotalOutrasDespesasVendaProduto: 0,
            valorTotalProdutoServicoVendaProduto: 0,
            valorTotal: 0,
            valorCustoTotal: null,
            proCpfCnpj: '',
            preCpfCnpj: '',
            dataExecucao: null,
            observacaoInterna: '',
            movimentouEstoque: '',
            dataRegistro: null,
            cpfRegistro: '',
            dataAlteracao: null,
            cpfAlteracao: '',
            idTabelaStatusPedidoVendaNavigation: null!,
            formaPagamentos: [],
            pedidoVendaProdutos: [],
            valorTotalSeguroVendaProduto: null,
            cpfCnpjNavigation: null!,
            documentoReferenciadoNf: '',
            emitiuCte: '',
            emitiuNfce: '',
            emitiuNfe: '',
            emitiuNfse: '',
            idAtividadeAgendada: null,
            idCaixa: null,
            idCaixaNavigation: null!,
            idContrato: null,
            idMotivoCancelamento: null,
            idMotivoDevolucao: null,
            idNaturezaOperacao: null,
            idOrcamento: null,
            idOrdemServico: null,
            idParametrizacaoVenda: null,
            idPdvNavigation: null!,
            idVenda: null,
            idVendaNavigation: null!,
            informacaoDadosComplementares: '',
            lancouContasAPagReceber: '',
            tipoVenda: '',
            preCpfCnpjNavigation: null!,
            proCpfCnpjNavigation: null!,
            estoqueMovimentacaos: null!,
            vendaEntregas: null!,
            pedidoVendaAnexos: null!,
            idTabelaPreco: 0,
            idTabelaPrecoNavigation: null!
        };
    }
    public static getArray(): PedidoVendumModel[] {
        return [this.get()];
    }
}
