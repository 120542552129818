import swal from 'sweetalert2';
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { ApiTabelaMeioPagamentoService } from 'src/app/services/cliente/api-tabela-meio-pagamento.service';
import { enumTipoConsultaMeioPagamento } from 'src/app/enum/enumTipoConsultaMeioPagamento';
import { enumCanalAtendimento } from 'src/app/enum/enumCanalAtendimento';
import { TabelaMeioPagamentoModel } from 'src/app/models/cliente/TabelaMeioPagamento/tabelaMeioPagamentoModel';
import { enumIndicadorMeioPagamento } from 'src/app/enum/enumIndicadorMeioPagamento';
import { enumSimNao } from 'src/app/enum/enumSimNao';
import { enumMeioPagamento } from 'src/app/enum/enumMeioPagamento';

@Component({
  selector: 'app-combo-tabela-meio-pagamento',
  templateUrl: './combo-tabela-meio-pagamento.component.html',
  styleUrls: ['./combo-tabela-meio-pagamento.component.scss'],
  providers:[{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboTabelaMeioPagamentoComponent),
    multi: true
  }]
})

export class ComboTabelaMeioPagamentoComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() disabled: boolean = false;
  @Input() label: string = "Meio de Pagamento";
  @Input() preCarregar: boolean = true;
  @Input() tipoConsultaMeioPagamento: enumTipoConsultaMeioPagamento = enumTipoConsultaMeioPagamento.Parametrizados;
  @Input() indicadorMeioPagamento: enumIndicadorMeioPagamento = enumIndicadorMeioPagamento.Ambos;
  @Input() canalAtendimento: enumCanalAtendimento = enumCanalAtendimento.Todos;
  @Output() change: EventEmitter<TabelaMeioPagamentoModel> = new EventEmitter();
  @Input() idCampo: string = 'idTabelaMeioPagamento';
  @Input() carregarItemPadrao: boolean = true;
  val: string = ''; // this is the updated value that the class accesses
  subService: Subscription | null = null;
  itens: Array<IOption> = [];
  public descricaoMeioPagamento: string = '';
  
  constructor(private apiTabelaMeioPagamentoService: ApiTabelaMeioPagamentoService) { }

  ngOnInit(): void {
    if (this.preCarregar){
      this.getItens();
    }
  }

  meiosPagamentos: TabelaMeioPagamentoModel[] = [];

  changeValue(event) {
    if (this.meiosPagamentos != null && event != null) {
      if (this.meiosPagamentos.find(f => f.idTabelaMeioPagamento.toString() == event.value) != null){
        let itemSelecionado = this.meiosPagamentos.find(f => f.idTabelaMeioPagamento.toString() == event.value);
        this.change.emit(itemSelecionado);
      }
    }
  }

  getLabelByValue(value: string): string{
    let find = this.itens.find(f => f.value == value);
    if(find)
      return this.itens.find(f => f.value == value)!.label;
    return '';
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }

  loading: boolean = false;
  tipoConsultaSangriaSuprimento: enumTipoConsultaMeioPagamento = null!;

  getItens(): void {
    //apoio para retornar apenas as opções permitidas para sangria e suprimento
    this.tipoConsultaSangriaSuprimento = this.tipoConsultaMeioPagamento;
    if(this.tipoConsultaMeioPagamento == enumTipoConsultaMeioPagamento.ParamSangriaSuprimento) this.tipoConsultaMeioPagamento = enumTipoConsultaMeioPagamento.Parametrizados;
    //
    this.loading = true;
      this.subService = this.apiTabelaMeioPagamentoService.getAll(this.tipoConsultaMeioPagamento, this.canalAtendimento, this.indicadorMeioPagamento, false).subscribe(
        (retorno: Array<TabelaMeioPagamentoModel>) => {
          if (retorno.length == 0){
            let mensagem = '';
            switch(this.tipoConsultaMeioPagamento){
              case enumTipoConsultaMeioPagamento.Parametrizados:
                mensagem = 'Não existem condições de pagamentos cadastrados, para cadastrar siga na rota configuração';
                break;
              case enumTipoConsultaMeioPagamento.Todos:
                mensagem = 'Não existem meios de pagamentos cadastrados, para cadastrar siga na rota configuração';
                break;
            }

            swal.fire({
              title: "Aviso",
              text: mensagem,
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Ok'
              }).then((result) => {
              });
            return;
          }
          this.meiosPagamentos = retorno;

          if(this.tipoConsultaSangriaSuprimento == enumTipoConsultaMeioPagamento.ParamSangriaSuprimento){
            let selecionarMeiosPagSangriaSupri = retorno.filter(f => f.idTabelaMeioPagamento == enumMeioPagamento.Dinheiro || f.idTabelaMeioPagamento == enumMeioPagamento.Cheque);
            this.meiosPagamentos = selecionarMeiosPagSangriaSupri;
          }

          // this.itens = retorno.map((objeto) => { return {
          this.itens = this.meiosPagamentos.map((objeto) => { return {
            value: objeto.idTabelaMeioPagamento,
            label: objeto.descricao
          } });
          this.itens.splice(0, 0, this.optionDefault());
          if (this.value == '' && this.carregarItemPadrao){
            let itemSelecionado = this.meiosPagamentos.find(f => f.padrao == enumSimNao.Sim);
            if (itemSelecionado != null)
            {
              this.value = itemSelecionado.idTabelaMeioPagamento;
            }
          }
          this.loading = false;
        });
  }

  clear(event: any) {
    this.change.emit(null!);
  }

  private optionDefault(){
    return {
        value: '',
        label: "Selecione"
    };
  }

  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    if (obj != undefined){
      this.val = obj;
      this.value = this.val;
    }else{
      this.val = null!;
      this.value = this.val;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  
  onChange: any = () => {}
  onTouch: any = () => {}
  set value(val){  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val;
  }
}