import { FinalizarPagamentoVendaPdvModel } from "./FinalizarPagamentoVendaPdvModel";

export class FinalizarPagamentoVendaPdvModelInstance {
    public static get(): FinalizarPagamentoVendaPdvModel {
        return {
            idVenda: 0,
            idPdv: 0,
            idCaixaControle: 0,
            permiteEstoqueNegativo: '',
            permiteEstoqueReservado: '',
            permiteEstoqueCondicional: '',
            permiteEstoqueCrossDocking: '',
            permiteEstoqueVirtual: '',
            movEstContabilEntradaManual: '',
            movEstContabilEntradaNotaFiscal: '',
            movEstContabilPedidoVenda: '',
            movEstContabilOrdemServico: '',
            movEstContabilNotaFiscal: '',
            movEstContabilCondicional: '',
            movEstVirtualEntradaManual: '',
            movEstVirtualEntradaNotaFiscal: '',
            movEstVirtualPedidoVenda: '',
            movEstVirtualOrdemServico: '',
            movEstVirtualNotaFiscal: '',
            movEstVirtualCondicional: '',
            movEstContabilVendaPdv: '',
            movEstVirtualVendaPdv: '',
            movEstContabilNotaFiscalOrigVenda: '',
            movEstVirtualNotaFiscalOrigVenda: ''
        };
    }
}