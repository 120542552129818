import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from "@angular/router";
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { ModalModule } from "./components";
import { AppModalAlertaComponent } from "./components/app-mensagem/app-modal-alerta/app-modal-alerta.component";
import { AppModalConfirmacaoComponent } from "./components/app-mensagem/app-modal-confirmacao/app-modal-confirmacao.component";
import { AppModalLoadingComponent } from "./components/app-mensagem/app-modal-loading/app-modal-loading.component";
import { AppModalLockComponent } from "./components/app-mensagem/app-modal-lock/app-modal-lock.component";
import { AppNotificacaoComponent } from "./components/app-mensagem/app-notificacao/app-notificacao.component";
import { CnpjPipe } from "./components/pipe/cnpj.pipe";
import { CpfPipe } from "./components/pipe/cpf.pipe";
import { CpfCnpjPipe } from "./components/pipe/cpfCnpj.pipe";
import { TableModule} from 'primeng/table';
import { TelefonePipe } from "./components/pipe/telefone.pipe";
import { TipoPessoa } from "./components/pipe/tipoPessoa.pipe";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ToastComponent } from "./components/toast/toast.component";
import { ToastService } from "./components/toast/toast.service";
import { SecurePipe } from './components/pipe/secure.pipe';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgbTooltipModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { AutoFocusModule } from 'primeng/autofocus';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { InputTextModule } from 'primeng/inputtext';
import { CepPipe } from './components/pipe/cep.pipe';
import { StatusPipe } from './components/pipe/status.pipe';



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true
};

@NgModule({
    imports: [
        CommonModule,
        PerfectScrollbarModule,
        FormsModule,

        TableModule,
        MultiSelectModule,
        DropdownModule,
        NgbTooltipModule,
        NgbPopoverModule,
        CalendarModule,
        NgSelectModule,
        InputMaskModule,
        InputNumberModule,
        AutoFocusModule,
        AutoCompleteModule,

        ReactiveFormsModule,
        ModalModule,
        RouterModule
    ],
    exports: [
        AppModalConfirmacaoComponent,
        AppModalAlertaComponent,
        AppNotificacaoComponent,
        AppModalLockComponent,
        AppModalLoadingComponent,
        SpinnerComponent,
        ToastComponent,
        InputMaskModule,
        InputNumberModule,
        AutoFocusModule,
        AutoCompleteModule,
        InputTextModule,

        CommonModule,
        PerfectScrollbarModule,
        FormsModule,
        ReactiveFormsModule,
        ModalModule,
        CnpjPipe,
        CpfPipe,
        CpfCnpjPipe,
        StatusPipe,
        CepPipe,
        TipoPessoa,
        TelefonePipe,
        SecurePipe,
        RouterModule,

        TableModule,
        MultiSelectModule,
        DropdownModule,
        NgbTooltipModule,
        NgbPopoverModule,
        CalendarModule,
        NgSelectModule
    ],
    declarations: [
        AppModalConfirmacaoComponent,
        AppModalAlertaComponent,
        AppNotificacaoComponent,
        AppModalLockComponent,
        AppModalLoadingComponent,
        SpinnerComponent,
        ToastComponent,
        CnpjPipe,
        CpfPipe,
        CpfCnpjPipe,
        StatusPipe,
        CepPipe,
        TelefonePipe,
        TipoPessoa,
        SecurePipe,
    ],
    providers: [
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        ToastService
    ]
})
export class SharedModule { }
