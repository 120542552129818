<label class="form-label" [for]="idCampo">{{label}}</label>
<ng-select  
    [id]="idCampo"
    [name]="idCampo"
    [ngModelOptions]="{standalone: true}" 
    class="ng-select" 
    [items]="dadosCombo()"
    bindLabel="label" 
    bindValue="value" 
    loadingText="Carregando..."
    notFoundText="Sem registro"
    [loading]="loading"
    [readonly]="disabled"
    (change)="changeValue()"
    [(ngModel)]="value">
</ng-select>