import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOption } from 'ng-select';
import { enumUnidadeComercial } from 'src/app/enum/enumUnidadeComercial';

@Injectable()
export class SelectOptionService {
    public static readonly UNIDADES_MEDIDAS: Array<IOption> = [
        { value: enumUnidadeComercial.Grama, label: 'Grama' },
        { value: enumUnidadeComercial.Litro, label: 'Litro' },
        { value: enumUnidadeComercial.Metro, label: 'Métro' },
        { value: enumUnidadeComercial.MetroCcbico, label: 'Metro Cúbico' },
        { value: enumUnidadeComercial.MetroQuadrado, label: 'Métro Quadrado' },
        { value: enumUnidadeComercial.Quilograma, label: 'Quilograma' },
        { value: enumUnidadeComercial.Unidade, label: 'Unidade' },
        { value: enumUnidadeComercial.Pacote, label: 'Pacote' },
        { value: enumUnidadeComercial.Fardo, label: 'Fardo' },
        { value: enumUnidadeComercial.Saco, label: 'Saco' },
        { value: enumUnidadeComercial.Tonelada, label: 'Tonelada' },
        { value: enumUnidadeComercial.Caixa, label: 'Caixa' }];
    
    getUnidadeMedidas(): Array<IOption> {
        return this.cloneOptions(SelectOptionService.UNIDADES_MEDIDAS);
    }

    loadUnidadeMedidas(): Observable<Array<IOption>> {
        return this.loadOptions(SelectOptionService.UNIDADES_MEDIDAS);
    }

    private loadOptions(options: Array<IOption>): Observable<Array<IOption>> {
        return new Observable((obs) => {
            setTimeout(() => {
                obs.next(this.cloneOptions(options));
                obs.complete();
            }, 5000);
        });
    }

    public cloneOptions(options: Array<IOption>): Array<IOption> {
        return options.map((option) => ({ value: option.value, label: option.label }));
    }
}
