import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { enumAcaoAbrirFecharCaixa } from 'src/app/enum/enumAcaoAbrirFecharCaixa';
import { PrecoModel } from 'src/app/models/cliente/Preco/precoModel';
import { PedidoVendumModel } from 'src/app/models/cliente/PedidoVenda/PedidoVendumModel';
import { enumTabelaStatusPedidoVenda } from 'src/app/enum/enumTabelaStatusPedidoVenda';

@Component({
  selector: 'app-modal-informacoes-adicionais',
  templateUrl: './modal-informacoes-adicionais.component.html',
  styleUrls: ['./modal-informacoes-adicionais.component.scss']
})
export class ModalInformacoesAdicionaisComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  inputDados: PedidoVendumModel = null!;
  @Output() fechouModal: EventEmitter<PedidoVendumModel> = new EventEmitter<PedidoVendumModel>();
  @Output() salvouAlteracao: EventEmitter<PedidoVendumModel> = new EventEmitter<PedidoVendumModel>();

  tituloModal: string = '';
  bloquearCampos: boolean = false;

  situacaoEmPreenchimento: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.EmPreenchimento;
  situacaoFinalizado: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.Finalizado;
  situacaoCancelado: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.Cancelado;
  situacaoVendido: enumTabelaStatusPedidoVenda = enumTabelaStatusPedidoVenda.Vendido;

  constructor() { }

  ngOnInit(): void {
    this.setStartObject();
    this.screenHeight = window.innerHeight;
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
    }
  get heightListaCliente(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  cliqueCancelar(){
    this.fecharModal();
  }
  
  public abrirModal(item: PedidoVendumModel) {
    this.bloquearCampos = false;
    this.tituloModal = 'Informações Adicionais';
    this.inputDados = item;
    if(this.inputDados.idTabelaStatusPedidoVenda != null && (this.inputDados.idTabelaStatusPedidoVenda == this.situacaoCancelado 
      || this.inputDados.idTabelaStatusPedidoVenda == this.situacaoVendido)){
        this.bloquearCampos = true;
      }
    this.modal.show();
  }

  fecharModal() {
    this.fechouModal.emit(this.inputDados);
    this.modal.hide();
  }

  setStartObject(){
    this.inputDados = null!;
  }  

  cliqueSalvar(){
    this.salvouAlteracao.emit(this.inputDados);
    this.modal.hide();
  }
}