import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumSituacaoComanda } from 'src/app/enum/enumSituacaoComanda';
import { enumTipoComanda } from 'src/app/enum/enumTipoComanda';
import { ComandaVendumModel } from 'src/app/models/cliente/ComandaVendum/comandaVendumModel';
import { ComandumModel } from 'src/app/models/cliente/Comandum/comandumModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { GerarComandaModel } from 'src/app/models/pdv/GerarComandaModel';
import { LancarProdutoComandaModel } from 'src/app/models/pdv/LancarProdutoComandaModel';
import { ProdutoComandaModel } from 'src/app/models/pdv/ProdutoComandaModel';
import { ResumoComandaModel } from 'src/app/models/pdv/ResumoComandaModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiComandaService {
    
    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private controller = 'comanda';
    private version = 'api/';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    consultarResumoComanda(idComandaGerada: number, exibeCarregando: boolean): Observable<ResumoComandaModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarResumoComanda/${idComandaGerada}`, exibeCarregando);
    }

    consultarResumoComandaByIdComanda(idComanda: number, tipoComanda: string, exibeCarregando: boolean): Observable<ResumoComandaModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarResumoComandaByIdComanda/${idComanda}/${tipoComanda}`, exibeCarregando);
    }

    abrir(comanda: GerarComandaModel, exibeCarregando: boolean ): Observable<ComandumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/abrir`, comanda, exibeCarregando);
    }

    liberarComanda(idComanda: number, tipoComanda: string, idComandaGerada: number, exibeCarregando: boolean ): Observable<ComandumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/liberarComanda/${idComanda}/${tipoComanda}/${idComandaGerada}`, null, exibeCarregando);
    }

    importarComandaParaPagamento(idComanda: number, tipoComanda: string, idVenda: number, idCaixa: number, idPdv: number, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/importarComandaParaPagamento/${idComanda}/${tipoComanda}/${idVenda}/${idCaixa}/${idPdv}`, null, exibeCarregando);
    }

    lancarProdutosComanda(idComanda: number, produtosComanda: LancarProdutoComandaModel[], exibeCarregando: boolean ): Observable<LancarProdutoComandaModel[]> {
        return this.gc.post(this.api, `${this.version}${this.controller}/lancarProdutosComanda/${idComanda}`, produtosComanda, exibeCarregando);
    }

    lancarProdutoComanda(produtosComanda: LancarProdutoComandaModel, exibeCarregando: boolean ): Observable<LancarProdutoComandaModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/lancarProdutoComanda`, produtosComanda, exibeCarregando);
    }

    removerProdutoComanda(idComandaProduto: number, exibeCarregando: boolean): Observable<void> {
        return this.gc.delete(this.api, `${this.version}${this.controller}/removerProdutoComanda/${idComandaProduto}`, exibeCarregando);
    }

    listarComandas(tipoComanda: enumTipoComanda, situacaoComanda: enumSituacaoComanda, exibeCarregando: boolean): Observable<ComandumModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/listarComandas/${tipoComanda}/${situacaoComanda}`, exibeCarregando);
    }

    consultarComandasVendaByIdVenda(idVenda: number, exibeCarregando: boolean | false): Observable<ComandaVendumModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarComandasVendaByIdVenda/${idVenda}`, exibeCarregando);
    }

    salvarObservacaoItemComanda(produtoComanda: ProdutoComandaModel, idComandaProduto: number, exibeCarregando: boolean ): Observable<ProdutoComandaModel> {
        return this.gc.put(this.api, `${this.version}${this.controller}/salvarObservacaoItemComanda/${idComandaProduto}`, produtoComanda, exibeCarregando);
    }
}