import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';


import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumModuloAnexo } from 'src/app/enum/enumModuloAnexo';
import { ItemUploadFileModel } from 'src/app/models/file/itemUploadFileModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiFileService {
    private gc: CommunicationManager;
    private api = AppSettings.API.File;
    private controller = 'api/file';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    upload(name: string, fileLimit: number, files: File[], 
        itemUploadFile: ItemUploadFileModel){
        const formData: FormData = new FormData();
        formData.append("itemUploadFile", JSON.stringify(itemUploadFile));
        for (let i = 0; i < files.length; i++) {
            formData.append(name, files[i], files[i].name);
        }
        return this.gc.postFile(this.api, `${this.controller}/upload`, formData, fileLimit, files, false);
    }

    download(modulo: enumModuloAnexo, chave: string, fileName: string, exibeCarregando: boolean) {
        return this.gc.getFile(this.api, `${this.controller}/download/${modulo}/query?chave=${chave}&fileName=${encodeURIComponent(fileName)}`, exibeCarregando);
    }

    delete(modulo: enumModuloAnexo, chave: string, fileName: string, exibeCarregando: boolean) {
        return this.gc.delete(this.api, `${this.controller}/delete/${modulo}/query?chave=${chave}&fileName=${encodeURIComponent(fileName)}`, exibeCarregando);
    }

    getFilesByChave(modulo: enumModuloAnexo, chave: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.controller}/getFilesByChave/${modulo}/query?chave=${chave}`, exibeCarregando);
    }
    getFilesByFileName(modulo: enumModuloAnexo, chave: string, fileName: string, exibeCarregando: boolean) {
        return this.gc.get(this.api, `${this.controller}/getFilesByFileName/${modulo}/query?chave=${chave}&fileName=${encodeURIComponent(fileName)}`, exibeCarregando);
    }
}