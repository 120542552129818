<div #modalConfirmacao  >
    
        <div style="text-align: right">
            <a (click)="buttonFechar()"><i class="pi pi-times"></i></a>
        </div>
    

    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 text-center" style="padding-top:15%">
                <h4 class="text-center"><b>{{mensagem.titulo}}</b></h4>
            </div>
        </div>
        <div class="row" style="padding-top:10%">
            <div class="col-12 col-sm-12 col-md-12 text-center">
                <p>{{mensagem.mensagem}}</p>
            </div>
        </div>
        <div class="row" style="padding-top:15% ;padding-bottom: 30%">
            <div class="col-12 col-sm-12 col-md-12 text-center">
                <button type="button" pButton class="ui-button-secondary"
                    value="{{mensagem.textoBtnNao}}" (click)="buttonCancelar()">
                </button>
                <button type="button" pButton class="ui-button-success" style="margin-left: 5%"
                    value="{{mensagem.textoBtnSim}}" (click)="buttonConfirmar()">
                </button>
            </div>
        </div>
    </div>
</div>
