export enum enumNotaFiscalOrigem {
    Todos = '',
    ImportacaoArquivo = 'A',
    Venda = 'V',
    NotaDestinada = 'I',
    DevolucaoCompra = 'D',
    DevolucaoVenda = 'E'
}

export class OrigemNotaFiscal{
    static getDescricao(origem: enumNotaFiscalOrigem){
        switch(origem){
            case enumNotaFiscalOrigem.ImportacaoArquivo:
                return "Importação Arq.";
            case enumNotaFiscalOrigem.Venda:
                return "Venda";
            case enumNotaFiscalOrigem.NotaDestinada:
                return "Destinada";
            case enumNotaFiscalOrigem.DevolucaoCompra:
                return "Devol. Compra";
            case enumNotaFiscalOrigem.DevolucaoVenda:
                return "Devol. Venda";
        }
        return '';
    }
}