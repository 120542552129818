import { Injectable } from '@angular/core';
import { AppModalConfirmacaoComponent } from './app-modal-confirmacao/app-modal-confirmacao.component';
import { AppModalAlertaComponent } from './app-modal-alerta/app-modal-alerta.component';
import { AppNotificacaoComponent } from './app-notificacao/app-notificacao.component';
import { MensagemModal } from 'src/app/models/mensagem-modal';
import { AppModalLoadingComponent } from './app-modal-loading/app-modal-loading.component';
import { AppModalLockComponent } from './app-modal-lock/app-modal-lock.component';

@Injectable()
export class AppMensagemService {
  appmodalConfirmacao: AppModalConfirmacaoComponent | null = null;
  appmodalAlerta: AppModalAlertaComponent | null = null;
  appnotificacao: AppNotificacaoComponent | null = null;
  appmodalLoading: AppModalLoadingComponent | null = null;
  appmodalLock: AppModalLockComponent | null = null;
  limparToast = true;

  constructor() {}

  confirmacao(messageModal: MensagemModal) {
    if (this.appmodalConfirmacao != null){
      this.appmodalConfirmacao.exibirModalConfirmacao(messageModal);
    }
  }

  timeLeft: number = 30;
  interval;

  startTimer() {
    this.timeLeft = 20;
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        AppMensagemService.contadorLoading = 0;
        if (this.appmodalLoading != undefined){
          this.appmodalLoading.esconderModalLoading();
        }
        this.pauseTimer();
      }
    },5000)
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  alerta(messageModal: MensagemModal) {
    if (this.appmodalAlerta != null){
      this.appmodalAlerta.exibirModalAlerta(messageModal);
      
    }
  }

  notificacao(config: any) {
    if (this.appnotificacao != null){
      this.appnotificacao.exibirNotifiacao(config);
    }
  }

  static contadorLoading: number = 0;

  exibirLock(mensagem?: string) {
    AppMensagemService.contadorLoading += 1;
    if (AppMensagemService.contadorLoading == 1){
      if (this.appmodalLoading instanceof AppModalLoadingComponent) {
        this.appmodalLoading.exibirModalLoading(mensagem);
        this.startTimer();
      }
    }
  }

  esconderLock() {
    AppMensagemService.contadorLoading -= 1;
    if (AppMensagemService.contadorLoading <= 0){
      AppMensagemService.contadorLoading = 0;
      if (this.appmodalLoading instanceof AppModalLoadingComponent) {
        this.appmodalLoading.esconderModalLoading();
        this.pauseTimer();
      }
    }
  }
}
