import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UiModalComponent } from 'src/app/theme/shared/components/modal/ui-modal/ui-modal.component';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ShowMessageError } from 'src/app/core/errors/ShowMessageError';
import { TabelaPrecoModel } from 'src/app/models/cliente/TabelaPreco/TabelaPrecoModel';
import { enumStatus } from 'src/app/enum/enumStatus';
import { ConstantsService } from '../../constants/constants.service';
import { ApiTabelaPrecoService } from 'src/app/services/cliente/api-tabela-preco.service';

export enum enumOrigemAbrirModal {
  Produto,
  Pagamento,
  Outros
}

@Component({
  selector: 'app-modal-selecionar-tabela-preco',
  templateUrl: './modal-selecionar-tabela-preco.component.html',
  styleUrls: ['./modal-selecionar-tabela-preco.component.css']
})
export class ModalSelecionarTabelaPrecoComponent implements OnInit {

  @ViewChild(UiModalComponent) modal;
  @Output() fechouModal: EventEmitter<any> = new EventEmitter();
  @Output() selecionouTabelaPreco: EventEmitter<TabelaPrecoModel> = new EventEmitter();
  inputDados: TabelaPrecoModel = null!;
  loading: boolean = false;
  
  constructor(
    private showMessageError: ShowMessageError,
    private apiTabelaPrecoService: ApiTabelaPrecoService,
    private router: Router) { 
      this.getScreenSize();
  }

  ngOnInit(): void {
    
  }

  get heightListaSelecionarTabelaPreco(): string{
    let size: number = this.screenHeight - 250;
    return `${size}px`
  }

  tabelaDePrecos: TabelaPrecoModel[] =[];
  subGetAll: Subscription = null!;
  consultarTabelaPrecoes() {
    this.loading = true;
    this.subGetAll = this.apiTabelaPrecoService.getAllByStatus(enumStatus.Ativo, false).subscribe(
      (retorno: Array<TabelaPrecoModel>) =>{
        if (retorno != undefined) {
          this.tabelaDePrecos = retorno;
          this.loading = false;
        }
        this.subGetAll?.unsubscribe();
      }
      ,
      (err) => {
        this.loading = false;
        this.subGetAll?.unsubscribe();
        this.showMessageError.exibirMensagemValidacaoTela(err);
      });
  }

  cliqueSelecionarTabelaPreco(tabelaDePreco: TabelaPrecoModel){
    ConstantsService.TabelaPreco = tabelaDePreco;

    /*atualizar lista de produtos*/ 
    

    this.selecionouTabelaPreco.emit(tabelaDePreco);
    this.fecharModal();
  }

  abrirModal() {
    this.modal.show();
    this.inputDados = ConstantsService.TabelaPreco;
    this.consultarTabelaPrecoes();
    this.onFocusBotaoCancelar();
  }

  @HostListener('keydown.esc')
  public onEsc(): void {
    this.fecharModal();
  }

  @ViewChild('elmBotaoCancelar') elmBotaoCancelar: ElementRef  = null!;
  onFocusBotaoCancelar(){
    setTimeout(()=>{ 
      if (this.elmBotaoCancelar != null){
        this.elmBotaoCancelar.nativeElement.focus();
      }
    },120);    
  }

  cliqueCancelar(){
    this.fecharModal();
  }

  fecharModal(){
    this.fechouModal.emit(null);
    this.modal.hide();
  }
  
  keyPress(event: KeyboardEvent) {
    if (event.keyCode == 43) {
      this.cliqueCancelar();
    }
  }

  onRowSelectItem(item: any) {
    this.cliqueSelecionarTabelaPreco(item.data);
  }

  screenHeight: number = 0;
  @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
          this.screenHeight = window.innerHeight;
  }



}
