import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IOption } from 'ng-select';
import { Subscription } from 'rxjs';
import { PrestadorServicoModel } from 'src/app/models/cliente/PrestadorServico/prestadorServicoModel';
import { ApiPrestadorServicoService } from 'src/app/services/cliente/api-prestador-servico.service';

@Component({
  selector: 'app-combo-prestador-servico',
  templateUrl: './combo-prestador-servico.component.html',
  styleUrls: ['./combo-prestador-servico.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ComboPrestadorServicoComponent),
    multi: true
  }]
})
export class ComboPrestadorServicoComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() style: string = "";
  @Input() disabled: boolean = false;
  @Input() label: string = "";
  @Input() toolTip: string = "";
  @Output() change: EventEmitter<any> = new EventEmitter();
  val: string = '' // this is the updated value that the class accesses
  @Input() itens: Array<IOption> = []; 
  subService: Subscription | null = null;
  @Input() selecionado: number = 0;
  lista: Array<PrestadorServicoModel> = [];
  @Input() idCampo: string = 'preCpfCnpj';
  
  constructor(private apiPrestadorServico: ApiPrestadorServicoService) { }

  ngOnInit(): void {
    this.getLista();  
  }

  changeValue() {
    this.change.emit(parseInt(this.value));
  }

  ngOnDestroy(): void {
    this.subService?.unsubscribe();
  }

  clear(event: any) {
    this.value = "";
    this.change.emit(null!);
  }

  loading: boolean = false;
  getLista() {
      this.loading = true;
      this.subService = this.apiPrestadorServico.getAll(false).subscribe(
        (retorno: Array<PrestadorServicoModel>) => {
          this.lista = retorno;
          this.itens = retorno.map((objeto) => {
            return {
              value: `${objeto.cpfCnpj}`,
              label: objeto.cpfCnpjNavigation.razaoSocialNome,
              checked: false
            }
          })
          this.itens.splice(0, 0, this.optionDefault());
          this.loading = false;
        });
  }


  private optionDefault() {
    return {
      value: "",
      label: "Selecione"
    };
  }

  //Método implementados para utilizar o ngModel
  writeValue(obj: any): void {
    this.val = obj?.toString();
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onChange: any = () => { }
  onTouch: any = () => { }
  set value(val) {  // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.val = val?.toString();
    this.onChange(val)
    this.onTouch(val)
  }

  get value(): any {
    return this.val?.toString();
  }
}