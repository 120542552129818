import { enumSimNao } from "src/app/enum/enumSimNao";
import { enumStatus } from "src/app/enum/enumStatus";
import { PrecoModel } from "./precoModel";




export class PrecoModelInstance {
    public static get(): PrecoModel {
        return {
            idPreco: 0,
            idProdutoUnico: 0,
            idLoja: 0,
            precoCompra: 0,
            precoVenda: 0,
            status: enumStatus.Ativo,
            dataRegistro: null!,
            cpfRegistro: '',
            idProdutoUnicoNavigation: null!,
            percMargem: 0,
            precoMargemZero: 0,
            precoVendaMedio: 0,
            precoVendaSugerido: 0,
            valorSeguro: 0,
            imprimiuEtiqueta: enumSimNao.Nao,
            tabelaIbptProdutos: null!,
            idTabelaPreco: null,
            idTabelaPrecoNavigation: null!,
            precoCompraMedioEmEstoque: 0
        };
    }

    public static getArray(): PrecoModel[] {
        return [this.get()];
    };
}
