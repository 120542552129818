import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from 'src/app/app-settings';
import { CommunicationManager } from 'src/app/core/communication-manager';
import { enumStatusVenda } from 'src/app/enum/enumStatusVenda';
import { enumTipoProduto } from 'src/app/enum/enumTipoProduto';
import { BoletoModel } from 'src/app/models/cliente/Boleto/boletoModel';
import { PessoaFisicaJuridicaModel } from 'src/app/models/cliente/PessoaFisicaJuridica/pessoaFisicaJuridicaModel';
import { VendaProdutoModel } from 'src/app/models/cliente/VendaProduto/vendaProdutoModel';
import { FiltroPesquisaVendaParaPdvModel } from 'src/app/models/cliente/Vendum/FiltroPesquisaVendaPdvModel';
import { ParametrosCancelarVendaModel } from 'src/app/models/cliente/Vendum/ParametrosCancelarVendaModel';
import { VendaParaListarModel } from 'src/app/models/cliente/Vendum/VendaParaListarModel';
import { VendumModel } from 'src/app/models/cliente/Vendum/vendumModel';
import { AuthService } from 'src/app/theme/shared/auth/auth.service';
import { AppMensagemService } from 'src/app/theme/shared/components/app-mensagem/app-mensagem.service';

@Injectable({
    providedIn: 'root'
})
export class ApiVendaService {
    
    private gc: CommunicationManager;
    private api = AppSettings.API.Pdv;
    private version = 'api/';
    private controller = 'venda';

    constructor(
        private readonly http: HttpClient,
        private readonly auth: AuthService,
        private readonly mensagemService: AppMensagemService
    ) {
        this.gc = new CommunicationManager(this.http, this.auth, this.mensagemService);
    }

    removerItemVendaProduto(itemVendaProduto: VendaProdutoModel, token: string, exibeCarregando: boolean ): Observable<VendaProdutoModel> {
        if (token == '' || token == null){
            return this.gc.post(this.api, `${this.version}${this.controller}/removerItemVendaProduto`, itemVendaProduto, exibeCarregando);
        }else{
            return this.gc.postComToken(this.api, `${this.version}${this.controller}/removerItemVendaProduto`, itemVendaProduto, token, exibeCarregando);
        }
    }

    consultarBoletoByIdVenda(idVenda: number, exibeCarregando: boolean | false): Observable<BoletoModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/consultarBoletoByIdVenda/${idVenda}`, exibeCarregando);
    }

    getAllByFiltroEData(filtro: FiltroPesquisaVendaParaPdvModel, exibeCarregando: boolean | false): Observable<VendaParaListarModel[]> {
        return this.gc.post(this.api, `${this.version}${this.controller}/getAllByFiltroEData`, filtro, exibeCarregando);
      }

    validarItemAlterado(vendaProduto: VendaProdutoModel, exibeCarregando: boolean ): Observable<VendaProdutoModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/validarItemAlterado`, vendaProduto, exibeCarregando);
    }

    voltarParaSelecaoProdutoDeTelaPagamento(venda: VendumModel, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/voltarParaSelecaoProdutoDeTelaPagamento`, venda, exibeCarregando);
    }

    salvarVenda(venda: VendumModel, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/salvarVenda`, venda, exibeCarregando);
    }

    alterarClienteDaVenda(idVenda: number, cpfCnpj: string, exibeCarregando: boolean ): Observable<PessoaFisicaJuridicaModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/alterarClienteDaVenda/${idVenda}/${cpfCnpj}`, null, exibeCarregando);
    }

    // cancelarVenda(idVenda: number, token: string, exibeCarregando: boolean ): Observable<void> {
    //     if (token == '' || token == null){
    //         return this.gc.post(this.api, `${this.version}${this.controller}/cancelarVenda`, idVenda, exibeCarregando);
    //     }else{
    //         return this.gc.postComToken(this.api, `${this.version}${this.controller}/cancelarVenda`, idVenda, token, exibeCarregando);
    //     }
    // }
    cancelarVenda(parametrosCancelarVenda: ParametrosCancelarVendaModel, token: string, exibeCarregando: boolean ): Observable<void> {
        if (token == '' || token == null){
            return this.gc.post(this.api, `${this.version}${this.controller}/cancelarVenda`, parametrosCancelarVenda, exibeCarregando);
        }else{
            return this.gc.postComToken(this.api, `${this.version}${this.controller}/cancelarVenda`, parametrosCancelarVenda, token, exibeCarregando);
        }
    }

    // cancelarVendas(idsVenda: number[], token: string, exibeCarregando: boolean ): Observable<void> {
    //     if (token == '' || token == null){
    //         return this.gc.post(this.api, `${this.version}${this.controller}/cancelarVendas`, idsVenda, exibeCarregando);
    //     }else{
    //         return this.gc.postComToken(this.api, `${this.version}${this.controller}/cancelarVendas`, idsVenda, token, exibeCarregando);
    //     }
    // }
    cancelarVendas(parametrosCancelarVenda: ParametrosCancelarVendaModel, token: string, exibeCarregando: boolean ): Observable<void> {
        if (token == '' || token == null){
            return this.gc.post(this.api, `${this.version}${this.controller}/cancelarVendas`, parametrosCancelarVenda, exibeCarregando);
        }else{
            return this.gc.postComToken(this.api, `${this.version}${this.controller}/cancelarVendas`, parametrosCancelarVenda, token, exibeCarregando);
        }
    }

    iniciarVenda(venda: VendumModel, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/iniciarVenda`, venda, exibeCarregando);
    }
    iniciarVendaOrigemOrcamento(idOrcamento: number, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/iniciarVendaOrigemOrcamento/${idOrcamento}`, null, exibeCarregando);
    }

    getById(idVenda: number, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/${idVenda}`, exibeCarregando);
    }

    getCanceladaByIdBenda(idVenda: number, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getCanceladaByIdBenda/${idVenda}`, exibeCarregando);
    }

    getAllByStatus(status: enumStatusVenda, exibeCarregando: boolean ): Observable<VendumModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatus/${status}`, exibeCarregando);
    }

    getAllByStatusEPdv(status: enumStatusVenda, idPdv: number, exibeCarregando: boolean ): Observable<VendumModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllByStatusEPdv/${status}/${idPdv}`, exibeCarregando);
    }

    getAllVendasEmAndamento(idPdv: number, exibeCarregando: boolean ): Observable<VendumModel[]> {
        return this.gc.get(this.api, `${this.version}${this.controller}/getAllVendasEmAndamento/${idPdv}`, exibeCarregando);
    }

    importarOrdemServicoParaPagamento(idsOrdensServicos: number[], idVenda: number, idCaixa: number, idPdv: number, tipoVenda: enumTipoProduto, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/importarOrdemServicoParaPagamento/${idVenda}/${idCaixa}/${idPdv}/${tipoVenda}`, idsOrdensServicos, exibeCarregando);
    }

    importarOrcamentoParaPagamento(idOrcamento: number, idVenda: number, idCaixa: number, idPdv: number, exibeCarregando: boolean ): Observable<VendumModel> {
        return this.gc.post(this.api, `${this.version}${this.controller}/importarOrcamentoParaPagamento/${idOrcamento}/${idVenda}/${idCaixa}/${idPdv}`, null, exibeCarregando);
    }
    importarPedidoVendaParaPagamento(idsPedidoVenda: number[], idCaixa: number, idPdv: number, exibeCarregando: boolean ): Observable<number> {
        return this.gc.post(this.api, `${this.version}${this.controller}/importarPedidoVendaParaPagamento/${idCaixa}/${idPdv}`, idsPedidoVenda, exibeCarregando);
    }    
}