<app-ui-modal #modal [containerClick]="false" (close)="cliqueCancelar()" [dialogClass]="'modal-lg'" >
    <div class="app-modal-header col-12" >
        <div class=" row">
        
            <div class="col-md-12 text-left">
                <h4 class="m-0 modal-title-header">&nbsp;<i class="fad fa-file-invoice-dollar icon-header-modal"></i>&nbsp;Selecionar Ordem de Serviço</h4>
            </div>
        </div>
    </div>
    <div class="app-modal-body" >
        <div class="row justify-content-center ">
            <div class="col-4"></div>
            <div class="col-4 wrap-form big-input">
                <input (keypress)="keyPressCodigoOrdemServico($event)" class="form-control" placeholder="" [(ngModel)]="codigoOrdemServico" #elmCodigoOrdemServico>
            </div>
            <div class="col-4"></div>
        </div>
        <div class="row scroll-miolo" >
            <div class="col-12 text-center " *ngIf="ordensServicosFilter.length == 0  && !loading">
                Não há Ordens de Serviço pendentes de pagamento, para cadastrar acesse este link <b><a href="https://erp.invare.com.br/vendas/ordem-servico" target="_blank">Ordens de Serviço</a></b>
            </div>
            <div class="col-12 " *ngIf="ordensServicosFilter.length > 0">
                <p-table  #dtVendasPendentes [value]="ordensServicosFilter" dataKey="cpfCnpj" 
                    selectionMode="single"
                    class="p-table nowrap table-hover " [loading]="loading"  
                    [paginator]="false" #elmListaOrdemServico
                    currentPageReportTemplate="Exibindo {first} à {last} de {totalRecords} registros">
                    <ng-template pTemplate="header">
                    <tr>
                        <th class="text-center "> <div class="chk-option">
                            <input placeholder="Todos" type="checkbox" class="form-check-input mouse-pointer"
                            (change)="changeSelecionarTodasOrdensDeServico($event)" id="chkTodasOrdensServico">
                            <label ngbTooltip="Selecionar todas" for="chkTodasOrdensServico" class="form-check-label  mouse-pointer">
                            </label>
                        </div></th>
                        <th class="text-center " >Cpf/Cnpj</th>
                        <th class="text-center " >Nome</th>
                        <th class="text-center " >Valor</th>
                        <!-- <th class="text-center " style="width:15%">Ações</th> -->
                    </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
                        <!-- <tr [pSelectableRow]="item">  [(selection)]="inputDados" (onRowSelect)="onRowSelectItemOrdemServico($event)"-->
                        <tr [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex">
                            <td  class="text-center">
                                <input type="checkbox" class="form-check-input mouse-pointer"
                                (change)="onChangeSelecionarOrdemServico($event, item.idOrdemServico)" 
                                    [checked]="getOrdemServicoSelecionado(item.idOrdemServico)"
                                    [id]="'chkIdOrdemServico_'+item.idOrdemServico">
                                <label class="form-check-label  mouse-pointer" [for]="'chkIdOrdemServico_'+item.idOrdemServico">&nbsp;{{ item.idOrdemServico }}&nbsp;<i class="fas fa-info-circle"
                                    ngbTooltip="Selecione um ou mais Ordem(ns) de Serviço para gerar uma Venda"></i></label>
                            </td> 
                            <td class="text-center"  >
                                <span class="text-center " *ngIf="item.tipoPessoa == 'J'">{{ item.cpfCnpj | cnpj }}</span>
                                <span class="text-center" *ngIf="item.tipoPessoa == 'F'">{{ item.cpfCnpj | cpf }}</span>
                                <span class="text-center" *ngIf="item.tipoPessoa == 'E'">{{ item.cpfCnpj }}</span>
                                <span class="text-center" *ngIf="item.cpfCnpj == null"></span>
                            </td>
                            <td >
                                <span *ngIf="item.razaoSocialNome != null">{{item.razaoSocialNome}}</span>
                                <span *ngIf="item.razaoSocialNome == null">Cliente não informado</span>
                            </td>
                            <td class="text-right" >
                                {{item.valorTotal | currency:'BRL'}}
                            </td>
                            <!-- <td class="table-action nowrap text-center" >
                                <a *ngIf="item.contemServico == 'S' && item.finalizouVendaServico == 'N'" ngbTooltip="Vender Serviço" class=" " (click)="realizarPagamento(item.idOrdemServico, tipoServico)">
                                    <i class="mouse-pointer fad fa-tools text-success f-16 ms-3"></i></a>
                                <a *ngIf="item.contemProduto == 'S' && item.finalizouVendaProduto == 'N'" ngbTooltip="Vender Produto" class=" " (click)="realizarPagamento(item.idOrdemServico, tipoProduto)">
                                    <i class="mouse-pointer fad fa-shopping-bag text-warning f-16 ms-3"></i></a>
                                <a *ngIf="(item.contemProduto == 'S' || item.contemServico == 'S') && (item.finalizouVendaProduto == 'N' || item.finalizouVendaServico == 'N')" ngbTooltip="Venda Única" class=" " (click)="realizarPagamento(item.idOrdemServico, tipoProdutoEServico)">
                                    <i class="mouse-pointer fad fa-shopping-basket text-primary f-16 ms-3"></i></a>
                            </td> -->
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="5">Não há Ordens de Serviço pendente de pagamento</td>
                    </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div class="app-modal-footer">
        <button  (click)="cliqueCancelar()" type="button"
            class="btn btn-secondary inline"><i class="fad fa-arrow-left"></i> Cancelar
        </button>&nbsp;
        <button  (click)="realizarPagamento(tipoProduto)" type="button"
            class="btn btn-primary inline"><i class="fad fa-shopping-bag"></i> Vender Produto
        </button>&nbsp;
        <button  (click)="realizarPagamento(tipoServico)" type="button"
            class="btn btn-primary inline"><i class="fad fa-tools "></i> Vender Serviço
        </button>&nbsp;
        <button  (click)="realizarPagamento(tipoProdutoEServico)" type="button"
        class="btn btn-primary inline"><i class="fad fa-shopping-basket"></i> Venda Única
    </button>
    </div>
</app-ui-modal>